export default {
	init: function() {
		return {
			model: {
				id: null,
				offer_type: 1,
				discount_type: null,
				name: "",
				value: "",
				relation: [],			
				rules: [],
				status: 1,
				store_id: 0,
				categorias_selecionadas: [],
				marcas_selecionadas: [],
				produtos_selecionados: []
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "name",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
			// rules
			rules: [],
			categorias: []
		};
	}
};
