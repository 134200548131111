<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais da Página
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.title" :slug_function=true />
							</div>			
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1, label: 'Mundo da Carabina'}, {id: 2,label: 'Aventura & Cia'}, {id: 3,label: 'Lexo'}]" v-model="model.store_id"  type="number" />	
							</div>						
							<div class="col-12">
								<FormTextarea state="paginas_adicionais" upload_type="editor_pages" :required=false label="Descrição" v-model="model.content" :rows=3 placeholder="Digite aqui um texto sobre essa página" info="" type="editor" />
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Slug" placeholder="" info="" position="left" text="https://*.com.br" v-model="model.slug" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Metatags -->
				<div class="hide card card-base">
					<div class="card-header">
						MetaTags
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Metatags v-model="model.metatags"></Metatags>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import url_prefix from "./../../../assets/json/urls.json";
import Metatags from "./../../common/MetaTags";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";
import FormTextarea from "./../../common/form/Textarea";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		FormTextarea,
		Metatags,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.paginas_adicionais.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Páginas Adicionais",
				chamada: "Gerencie as páginas adicionais da sua loja",
				links: {
					back: "paginas.adicionais.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Páginas Adicionais",
					link: "/paginas/adicionais"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"MetaTags"
			],
			disabled_items: [],
			hidden_items: [],
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				// salva
				this.$store.dispatch("paginas_adicionais/save", this.model);
			}
		},
		toSlug: function(){
			if(this.model.slug == ""){
				this.model.slug = url_prefix.page + Slug(this.model.title);
			}			
			if(this.model.metatags && this.model.metatags.title == null){
				this.model.metatags.title = this.model.title;
			}
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug
		};
	},
	created() {
		this.$store.dispatch("paginas_adicionais/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("paginas_adicionais/load", this.$route.params.id);
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	
</style>
