<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro" v-if="!blocked">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="card card-base">
					<div class="card-header">
						Informações Gerais da Senha
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<FormInput :required=true name="name" rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>
							<div class="col-12">
								<FormInput :required=true name="name" rules="string" label="Link" placeholder="" info="" v-model="model.link" :slug_function=false />
							</div>
							<div class="col-6">
								<FormInput :required=true name="name" rules="string" label="Login" placeholder="" info="" v-model="model.username" :slug_function=false />
							</div>
							<div class="col-6">
								<FormInput :required=true name="name" rules="string" label="Senha" placeholder="" info="" v-model="model.password" :slug_function=false />
							</div>
							<div class="col-12">
								<FormTextarea :required=false label="Descrição" v-model="model.description" :rows=3 placeholder="Digite aqui um texto sobre essa página" info="" type="textarea" />
							</div>
							
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import alerts from "./../../../common/alerts";
import {getCookie} from "./../../../common/utils";

// Inputs
import FormInput from "./../../common/form/Input";
import FormTextarea from "./../../common/form/Textarea";

export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormTextarea
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.senhas.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		phones() {
			return this.$store.state.senhas.phones;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		passValidate() {
			return this.$store.state.app.passValidate;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Senhas",
				chamada: "Gerencie as senhas da sua loja",
				links: {
					back: "senhas.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Senhas",
					link: "/senhas"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
			],
			disabled_items: [],
			hidden_items: [],
			blocked: true,
			passInputed: "",
		};
	},  
	methods: {		
		save: function(){			

			if(validation.validation().valid){			
				let model = Object.assign({},this.model);
				this.$store.dispatch("senhas/save", model);
			}
		},
		confirmaAcesso() {
			
			const self = this;
			let event_confirm = async () => {

				if(self.passInputed === self.passValidate){
					this.blocked = false;	
					const d = new Date();
					d.setTime(d.getTime() + (5*60*1000));
					// cria o cookie
					document.cookie = "PsGo="+self.usuario.id+"; expires="+ d.toUTCString();
					window.location.reload();
				}else{
					alerts.alertTop("error","Senha incorreta");
					self.confirmaAcesso();
				}						
				return true;
			};
			let dados = [];
			dados = {
				title: "Informe a senha de segurança:",
				body: [
					{
						type: "input_password",
						event: (valor) => {
							self.passInputed = valor;
						}
					},
				]
			};
			
			alerts.alertConfirm(dados,event_confirm);
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug
		};
	},
	created() {
		// let self = this;
		this.$store.dispatch("senhas/reset");

		if(getCookie("PsGo") && getCookie("PsGo") === this.usuario.id){
			this.blocked = false;
		}else{						
			this.blocked = true;
			this.confirmaAcesso();
		}

		if(!this.blocked){
			if(this.$route.params.id != undefined && this.$route.params.id != "") {
				this.$store.dispatch("senhas/load", this.$route.params.id).then(response => {
					if(response.return_code == 1){
						// popula com os dados vindo do banco
						// self.$store.commit("senhas/addOnUpdateAll",{ tipo: "phones", itens: response.data.phones});
						// faz com que o component atualize o state com os dados já tratados
						// self.$refs.phones.updateComponent();
					}					
				});
			}
		}
		
	},
	watch: {
		
	}
};
</script>

<style scoped lang="scss">	
	
</style>
