export default {
	init: function() {
		return {
			periods: [
				{
					id: "24-hours",
					label: "Últimas 24 Horas"
				},
				{
					id: "today",
					label: "Hoje"
				},                
				{
					id: "yesterday",
					label: "Ontem"
				},
				{
					id: "7-days",
					label: "Últimos 7 Dias"
				},
				{
					id: "15-days",
					label: "Últimos 15 Dias"
				},
				{
					id: "current-month",
					label: "Mês Atual"
				},
				{
					id: "last-month",
					label: "Mês Anterior"
				}				
			],
			box_geral: {
				period: {
					type: "24-hours",
					refresh: 0,
					date_init: null,	
					date_end: null,
					group: null,
				},
				box_orders: {
					label: "Pedidos",
					class: "dash-green",
					icon: "fa fa-trophy",
					action: "orders",
					number: 0,
					price: 0
				},
				box_cart: {
					label: "Abandonos",
					class: "dash-yellow",
					icon: "fa fa-shopping-cart",
					action: "carts",
					number: 0,
					price: 0
				},
				box_customers: {
					label: "Clientes",
					class: "dash-blue",
					icon: "fa fa-users",
					action: "customers",
					number: 0,
					price: 0
				},
				box_payment_problem: {
					label: "Problemas",
					class: "dash-red",
					icon: "fa fa-exclamation-triangle",
					action: "payment_problem",
					number: 0,
					price: 0
				}
			},
			lines: {
				carts: {
					period: {
						type: "24-hours",
						refresh: 0,
						date_init: null,	
						date_end: null,
						group: null,
					},
					data: [],					
				},
				orders: {
					period: {
						type: "24-hours",
						refresh: 0,
						date_init: null,	
						date_end: null,
						group: null,
					},
					data: [],					
				}
			},
			bars: {
				products_best_sellers: {
					period: {
						type: "24-hours",
						refresh: 0,
						date_init: null,	
						date_end: null,
						group: null,
					},
					data: [],					
				},
				products_abandoned: {
					period: {
						type: "24-hours",
						refresh: 0,
						date_init: null,	
						date_end: null,
						group: null,
					},
					data: [],					
				},
				states_best_sellers: {
					period: {
						type: "24-hours",
						refresh: 0,
						date_init: null,	
						date_end: null,
						group: null,
					},
					data: [],					
				},
			}
		};
	}
};