<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Dúvidas sobre Produtos
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>		
							<div class="col-6">
								<FormInput :required=true name="email" rules="string" label="E-mail" placeholder="" info="" v-model="model.email" />
							</div>
							<div class="col-4">
								<FormInput :required=true name="phone" rules="cel" label="Celular" placeholder="" info="" v-model="model.phone" type="cellphone"/>
							</div>

							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>	

							<div class="col-4">
								<FormSelect :required=true rules="option_store" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>		
							<div class="col-12">
								<div class="form-group required"> 
									<label class="mb-1">Produto <i class="fas fa-asterisk"></i></label>
									<MultiSelect state="produtos_duvidas" tipo="product" :dynamic=true dynamic_action="produtos/filter_products_data" :dados="[]" :field_required=false :multiple=false :required=true :excluir_validation=false></MultiSelect>
								</div>
							</div>	
							
							<div class="col-12">
								<FormTextarea :required=true label="Dúvida" v-model="model.question" :rows=3 placeholder="Digite aqui um texto sobre a dúvida do produto" info="" type="textarea" />
							</div>
							<div class="col-12">
								<FormTextarea :required=false label="Resposta" v-model="model.answer" :rows=3 placeholder="Digite aqui uma resposta para a dúvida do produto" info="" type="textarea" />
							</div>

						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import url_prefix from "./../../../assets/json/urls.json";
import MultiSelect from "./../../common/MultiSelect";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormTextarea from "./../../common/form/Textarea";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormTextarea,
		MultiSelect
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.produtos_duvidas.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		}	
	},	
	data () {
		return {
			header_info: {
				title: "Dúvidas de Produtos",
				chamada: "Gerencie as perguntas sobre os produtos da sua loja",
				links: {
					back: "produtos.duvidas.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Dúvidas de Produtos",
					link: "/produtos/duvidas"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral"
			],
			disabled_items: [],
			hidden_items: [],
			products: []
		};
	},  
	methods: {		
		save: function(){						
			if(validation.validation().valid){			
				let model = Object.assign({}, this.model);
				model.user_id = this.usuario.id;
				// grava como string o id do produto
				model.product_id = model.product.id;
				// delete o campo utilizado no multiselect
				delete model.product;

				// injeta uma propriedade que informa que é preciso mandar o email da resposta para o cliente
				model.send_mail = true;

				// salva
				this.$store.dispatch("produtos_duvidas/save", model);
			}
		},
		toSlug: function(){
			if(this.model.slug == ""){
				this.model.slug = url_prefix.brand + Slug(this.model.name);
			}			
			if(this.model.metatags && this.model.metatags.title == null){
				this.model.metatags.title = this.model.name;
			}
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			updateDadosMultiselect: this.updateDadosMultiselect,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("produtos_duvidas/reset");

		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("produtos_duvidas/load", this.$route.params.id).then(response => {
				if(response.body.return_code == 1){
					self.$store.dispatch("produtos/load", response.body.data.product.id).then(retorno => {
						if(retorno.body.return_code == 1){
							self.$store.commit("produtos_duvidas/multiselect_push", {item: {
								id: retorno.body.data.id,	
								name: retorno.body.data.title,
							}, tipo: "product", multiple: false});
						}						
					});
				}
				
			});
		}		
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
