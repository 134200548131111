<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info modulo_permissao="" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />	

		<div class=" notfound row justify-content-md-center" >
			<div class="col-6 text-center">
				<h1><i class="fas fa-radiation"></i> Oops!</h1>
				<p>Você não tem permissão para acessar este módulo.<br />Entre em contato com o administrador da loja e solicite seu acesso.</p>
			</div>
		</div>

	</div>
</template>

<script>

import Header from "./../common/Header";
import Breadcrumb from "./../common/Breadcrumb";

export default {
	name: "notFound",
	components: {
		Header,
		Breadcrumb,
	},
	props: {
		
	},
	data () {
		return {
			header_info: {
				title: "Página Sem Permissão",
				chamada: "Você acessou uma página que não tem permissão.",
				links: {
					
				}
			},
			breadcrumb_info: [
				{
					name: "404",
					link: "/404"
				}
			],
		};
	},  
	created() {
	},
	mounted() {
	},
	methods: {		
		
	}
};
</script>

<style scoped lang="scss">	
	.notfound{
		margin-top: 6em;
		h1{
			font-size: 6em;
			font-weight: bold;
			i{
				font-size: 0.9em;
				color:$color_red;
			}
		}
		p{
			margin-top: 1em;
			line-height: 1.2em;
			font-size: 1.2em;
		}
	}
	
</style>
