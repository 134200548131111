<template>
	<div class="row" ref="produtosPedidos"> 
		<div class="col-12">
			<div class="form-group mb-0">
				<input type="text" class="form-control" placeholder="Busque pelo produto" v-model="search" v-on:keyup="pegaProdutos($event)" v-on:focusin="exibir_produtos=true;" v-on:blur="exibir_produtos=false;" v-on:keyup.enter="seleciona()" v-on:keyup.38="navigateActive('up')" v-on:keyup.40="navigateActive('down')" />
			</div>			
			<div class="dados" v-show="exibir_produtos">
				<ul class="list-group" :class="'filtros-component-produtos_'+tipo" >
					<li class="list-group-item" v-for="dado in dadosFiltered()" :key=dado.id v-on:mousedown="seleciona(dado)" :sku="dado.sku" >
						<strong>{{ dado.title }}</strong>
						<span v-if="dado.variation != ''">{{ dado.variation }} | </span>SKU: {{ dado.sku }} | Qtd: {{ dado.stock }}<br />
						{{ dado.price | money }} ou {{ dado.cash_price | money }} à vista
					</li>
					<li class="list-group-item" v-show="dadosFiltered().length == 0" v-html="msg_search"></li>
				</ul>
			</div>
			<div class="produtos mt-3">
				<table class="table table-bordered pedido-cart-produtos" v-if="cart.products">
					<thead v-if="cart.products.length > 0">
						<tr>
							<th width="40%">Produto</th>
							<th width="20%">Qtd</th>
							<th width="25%">Valor</th>
							<th width="15%">Ação</th>
						</tr>
					</thead>
					<tbody  v-if="cart.products.length > 0">
						<tr v-for="(product,i) in cart.products" :key=i :class="{opacity: product.deleted}">
							<td>
								{{ product.name }}<br />
								SKU: {{ product.sku }}<br />
								<span v-for="(attribute,j) in product.attributes" :key=j v-html="attribute.name+': ' + (attribute.value.name ? attribute.value.name : attribute.value)"></span>
							</td>
							<td>
								<input class="form-control input-sm text-center" :disabled="product.deleted" v-model="product.quantity" v-on:change.prevent="updateQuantidade(product,i)" />
								<span class="quantidade_max">Máx: {{ getStock(product) }} un <template v-if="order">extras</template></span>
							</td>
							<td>
								<button v-if="order && product.unit_cash_price != product.unit_price" title="Remover desconto à vista" class="btn btn-sm bg-danger text-white mx-1 my-1 float-right" v-on:click.prevent="removeCashDiscount(product,i)"><i class="fas fa-dollar-sign"></i></button>
								<span class="avista" v-if="order && getPriceOf(product)"><s>{{ getPriceOf(product) | money }}</s></span>
								{{ getSubtotalPrice(product) | money }}
								<span class="avista" v-if="product.subtotal_cash_price">{{ product.subtotal_cash_price | money }} à vista</span>
								<span class="unity_discount" v-if="product.discounts.applied > 0">- {{ product.discounts.applied | money }} (unit.)</span>								
							</td>
							<td class="text-center">
								<template v-if="!product.deleted">									
									<button v-if="product.discounts.applied > 0" :disabled="product.deleted" class="btn btn-sm bg-danger text-white mx-1 my-1" v-on:click.prevent="removeDesconto(product,i)"><i class="fas fa-tag"></i></button>
									<button v-else class="btn btn-sm bg-info text-white mx-1 my-1" :disabled="product.deleted" v-on:click.prevent="showAplicaDesconto(product, i)"><i class="fas fa-tag"></i></button>
									<button class="btn btn-sm btn-danger my-1" :disabled="product.deleted" v-on:click="removeProdutoCarrinho(product,i)"><i class="fas fa-times"></i></button>
								</template>
								<template v-else>
									<button class="btn btn-sm btn-success my-1 btn-reverse-delete" v-on:click="reverseProductDelete(product,i)"><i class="fas fa-history"></i></button>
								</template>
							</td>
						</tr>
					</tbody>
				</table>
			</div>						
		</div>
	</div>	
</template>

<script>

import Filters from "./../../../../common/filters";
import Alerts from "./../../../../common/alerts";

export default {
	name: "ProdutosPedido",
	props: {
		tipo: {
			type: String,
			required: true			
		},
		store_id: {
			type: Number,
			default: 0
		},
		needs_store: {
			type: Boolean,
			default: false
		},
		state: {
			type: String,
			required: true
		},
		extra_fields: {
			type: Array,
			required: false,
			default: () => {
				return [];
			}
		},
		cart: {
			type: Object,
			required: false,
			default: () => {
				return {};
			}
		},
		in_stock: {
			type: Boolean,
			default: false
		},
		order: {
			type: Boolean,
			default: false
		}
	},
	components: {
	},
	computed: {		
		usuario() {
			return this.$store.state.app.usuario;
		}			
	},	
	data () {
		return {
			exibir_produtos: false,			
			search: "",			
			dados: [],
			searchTime: null,
			msg_search: "Digite no mínimo 4 letras para pesquisar",
			modal: {
				sku: null,
				type: 1,
				value: 0.00
			},
			products_reference: []
		};
	},  
	created() {
		const self = this;
		if(this.order){
			// se for utilizado na edição de um pedido
			const skus = [];
			this.cart.products.forEach(item => {
				skus.push(item.sku);
			});
			this.$store.dispatch("produtos/getBySku", skus).then(response => {
				response.forEach(item => {
					item.variations.forEach(variation => {
						self.products_reference.push({
							sku: variation.sku,
							stock: variation.stock,
							stock_min: variation.stock_min,
						});
					});					
				});
			});
		}
		
	},
	methods: {		
		filterJson: function(item){
			return item.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
		},
		navigateActive: function(direction){

			this.$nextTick(function () {
				let lista = document.getElementsByClassName("filtros-component-produtos_"+this.tipo)[0].children;
				let indice_maximo = lista.length-1;
				let indice_lista = 0;
				if(direction == "up"){
					for(let li=indice_maximo;li>=0;li--){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li > 0){
								indice_lista = li-1;	
							}
							break;
						}
					}
				}else{
					for(let li=0;li<=indice_maximo;li++){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li < indice_maximo){
								indice_lista = li+1;	
							}						
							break;
						}
					}
				}
				
				// aplica a classe
				lista[indice_lista].classList.add("active");				
			});			
		},
		pegaProdutos: function(ev){	
			let self = this;			

			// ESC - Limpa pesquisa
			if(ev.keyCode == 27){
				self.search = "";
			}

			// validando algumas teclas
			if([40,38,37,39,13,36,35,16,17].includes(ev.keyCode)){
				return;
			}			

			self.dados = [];
			if(self.search == ""){
				self.msg_search = "Digite no mínimo 4 letras para pesquisar";
			}else{
				self.msg_search = "Procurando ...";
			}

			if(this.needs_store && !this.store_id > 0){
				self.msg_search = "Selecione uma loja para continuar a pesquisa.";				
				return;
			}
			
			let data = {
				text: self.search, 
				combo: false,
				in_stock: this.in_stock
			};
			if(this.needs_store){
				data.store_id = this.store_id;
			}
			// se for order não retorna combos
			if(this.order){
				data.combo = true;
			}

			clearTimeout(self.searchTime);
			self.searchTime = setTimeout(function(){ 
				if(self.search.length > 3){									
					self.$store.dispatch("produtos/filter_products_data",data).then(response => {
						self.dados = response;
						if(self.dados.length == 0){
							self.msg_search = "Nenhum produto encontrado para a sua pesquisa.";		
						}
					});
				}else if(self.search.length <= 3){
					self.dados = [];
					self.msg_search = "Digite no mínimo 4 letras para pesquisar";
				}
			}, 650);
		},
		dadosFiltered: function(){			
			let self = this;						
			let dados = Object.assign([],self.dados);					
			if(dados.length == 0 && self.search.length == 0){
				self.msg_search = "Digite no mínimo 4 letras para pesquisar";
			}

			// pegando o slug
			let slug_store;
			switch(this.store_id){
			case 1:
				slug_store = "mundo";
				break;
			case 2:
				slug_store = "aventura";
				break;
			case 3:
				slug_store = "lexo";
				break;
			}

			// trata os dados
			let dados_tratados = [];
			if(dados.length > 0){
				dados.forEach(produto => {
					produto.variations.forEach(item => {
						// validando se tem obrigatoriedade de estoque
						if(!this.in_stock || (this.in_stock && item.stock > 0)){
							dados_tratados.push({
								title: produto.title,
								id: item.id,
								variation: self.pegaValorAtributo(item.attributes),
								sku: item.sku,
								price: item.prices[slug_store].price,
								cash_price: item.prices[slug_store].cash_price,
								stock: item.stock,
							});
						}						
					});
				});				
			}
			// tira os já selecionados
			dados_tratados = dados_tratados.filter(item => {																				
				return self.cart.products.find(dado => { return item.id == dado.id; }) == undefined;
			});	

			if(dados.length > 0 && dados_tratados.length == 0){
				self.msg_search = "Nenhum produto encontrado para a sua pesquisa.";
			}

			return dados_tratados;			
		},
		pegaValorAtributo: function(dado){
			if(dado.length == 0){
				return "";
			}
			let attributes = "";
			dado.forEach(item => {
				if (attributes != "") attributes += ", ";
				// attributes += item.name + ": " +item.values.find(obj => { return obj.id == item.value.id; }).name;
				attributes += item.name + ": " +item.value.name;
			});

			return attributes;
		},
		seleciona: function(dado){
			let self = this;
			let sku;
			if(dado == undefined){
				let lista = document.getElementsByClassName("filtros-component-produtos_"+this.tipo)[0];									
				if(lista.children.length == 0){
					return;
				}				
				let ativo = lista.getElementsByClassName("active");				
				if(ativo.length > 0){
					sku = ativo[0].getAttribute("sku");
				}else{
					sku = lista.children[0].getAttribute("sku");
				}	
				// se pegar um li informativo ele ignora
				if(sku == undefined){
					return;
				}						
			}else{
				sku = dado.sku;
			}

			if(!this.order){
				this.$store.dispatch("pedidos/addProduto",{
					cart_id: self.cart.id,
					sku: sku,
					customer_id: self.cart.customer_id,
				});
			}else{
				this.manipulaPedido({
					type: "new_product",
					data: sku
				});
			}
			

		},
		removeProdutoCarrinho: function(produto, index){			
			let self = this;
			if(!this.order){
				this.$store.dispatch("pedidos/removeProduto",{
					cart_id: self.cart.id,
					sku: produto.sku
				});
			}else{
				this.manipulaPedido({
					type: "delete_product",
					data: { index, product: produto }
				});
			}
		},
		removeCashDiscount: function(produto, index){
			this.manipulaPedido({
				type: "product_cash_discount_remove",
				data: { index, product: produto }
			});
		},
		reverseProductDelete: function(produto, index){			
			this.manipulaPedido({
				type: "product_reverse",
				data: { index, product: produto }
			});
		},
		updateQuantidade: function(product, index){
			let self = this;
			if(!this.order){
				if(product.quantity > 0 && product.quantity >= product.quantity_min){
					if(product.quantity > product.stock){
						Alerts.alertTop("error","A quantidade informada é superior a quantidade disponível do produto");
					}else{
						this.$store.dispatch("pedidos/update_quantidade", { cart_id: self.cart.id, sku: product.sku, quantity: product.quantity });
					}
				}else{
					if(product.quantity < product.quantity_min){
						Alerts.alertTop("error","A quantidade informada é inferior a quantidade mínima de venda deste produto");
					}else{
						Alerts.alertTop("error","A quantidade deve ser maior que zero");
					}
				}
			}else{
				// chama um método da página de edição do pedido
				product.stock = this.getStock(product);
				this.manipulaPedido({
					type: "quantity",					
					data: { index, product },
				});
			}
		},
		showAplicaDesconto: function(product, index){			

			let self = this;
			self.modal = {
				sku: product.sku,
				type: 1,
				value: 0,
				index: index,
			};			
			if(this.order){
				if(!product.applied_discount){
					product.applied_discount = {
						type: 1,
						value: product.discounts.applied,
					};
				}
			}
			if(product.discounts.applied > 0){
				self.modal.type = product.applied_discount.type;
				self.modal.value = product.applied_discount.value;
			}

			let event_confirm = () => {		
				return this.updateDesconto();
			};

			let dados = [];
			dados = {
				title: "Aplicar desconto ao produto: "+product.sku+" ? ",
				body: [
					{
						type: "label",
						text: "Selecione o tipo de desconto"
					},
					{
						type: "select",
						options: [{id: 1, name: "Reais"}, {id: 2, name: "Porcentagem"}],
						value: self.modal.type,
						event: (valor) => {
							self.modal.type = parseInt(valor);
						}
					},
					{
						type: "label",
						text: "Informe o valor do desconto UNITÁRIO"
					},
					{
						type: "input_price",
						value: self.modal.value,
						event: (valor) => {
							self.modal.value = valor;
						}
					},
				] 
			};
			
			Alerts.alertConfirm(dados,event_confirm);

			
		},
		removeDesconto: function(product, index){
			let self = this;
			
			const event_confirm = () => {
				if(!self.order){
					self.$store.dispatch("pedidos/remove_desconto",{
						cart_id: self.cart.id,
						skus: [
							product.sku
						]
					});
				}else{
					self.manipulaPedido({
						type: "removeAppliedDiscount",
						data: {
							index: index						
						}
					});
				}				
				return true;
			};

			let dados = {
				title: "Remover o desconto do produto: "+product.sku+" ? ",
				body: null
			};

			Alerts.alertConfirm(dados,event_confirm);
			
		},
		updateDesconto: function(){
			let self = this;
			if(self.modal.value <= 0){
				Alerts.alertTop("error","Digite um valor maior que 0,00");
				return false;
			}else if(![1,2].includes(self.modal.type)){
				Alerts.alertTop("error","Selecione o tipo de desconto");
				return false;
			}else{				
				if(!this.order){
					this.$store.dispatch("pedidos/aplica_desconto",{
						cart_id: self.cart.id,
						discounts: [
							{
								sku: self.modal.sku,
								type: self.modal.type,
								user_id: self.usuario.id,
								value: self.modal.value,
							}
						]
					});
				}else{
					this.manipulaPedido({
						type: "appliedDiscount",
						data: {
							index: self.modal.index,
							discount: {
								sku: self.modal.sku,
								type: self.modal.type,
								user_id: self.usuario.id,
								value: self.modal.value,
							}
						}
					});
				}				
				return true;
			}
		},
		getStock(product){
			return product.stock || (this.products_reference.find(item => item.sku === product.sku ) || {}).stock;
		},
		getSubtotalPrice(product){	
			return (this.order) ? product.unit_price * product.quantity : product.subtotal_sale_price;
		},
		getPriceOf(product){
			if(product.discounts.applied > 0){
				let retorno = product.full_price - Object.values(product.discounts).reduce((t, n) => t + n);
				return (retorno + product.discounts.applied) * product.quantity;
			}else{
				return null;
			}			
		}
	},
	filters: Filters,
	mounted() {
		
	},
	inject: {
		manipulaPedido: {
			default: () => {}
		},
	},
};
</script>

<style scoped lang="scss">	
	.row{
		.dados{
			position: relative;
			.list-group{
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				z-index: 10;
				li{
					font-size: 0.9em;
					line-height: 1.2em;
					&:hover, &.active, &.selected{
						background: $bg_destaque;
						color: #FFF;
						cursor: pointer;
						border:none;
					}
					strong{
						display: block;
						font-size: 1.2em;
						margin-bottom: 0.3em;
					}
				}
			}			
		}		
		.produtos{
			.pedido-cart-produtos{
				.opacity {
					opacity: .25;
					.btn-reverse-delete {
						opacity: 1;
					}
				}
				td{
					font-size: 0.95em;
					line-height: 1.2em;
					vertical-align: middle;
					input{
						min-height: auto; 
					}
					.avista{
						display: block;
						font-size: 0.85em;
						color: $color_red;
						font-style: italic;
					}
					.unity_discount{
						display: block;
						font-size: 0.85em;
						color: $color_red;
						font-style: italic;
					}
					.quantidade_max{
						display: block;
						font-size: 0.8em;
						color: $color_red;
						font-style: italic;
						margin-top: 5px;
					}
					.aplica_desconto{
						color: $color_red;
						margin-top: -0.2em;
						font-size: 1.3em;
						float: right;
					}
				}        
			}
		}	
	}
	
</style>
