import api from "./../../common/api";
import model from "./../models/reports-pedidos";
import config from "./../../assets/json/filters";
import alerts from "./../../common/alerts";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.reports_pedidos);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state) => { 
		state.commit("clear_valores");
		return state.dispatch("lista_filter_update", state.state.search_data);
	},
	lista_filter_update: function(state, data) {    
		let elem_match = [];
		let new_filters = data.filtros.filter( filtros => {
			switch(filtros.field){
			case "match_updated_at":
				elem_match.push({
					field: filtros.field.replace("match_",""),
					operator: filtros.operator,
					value: filtros.value,
					type: "datetime"
				});
				return false;
			case "match_status_type":
				elem_match.push({
					field: filtros.field.replace("match_",""),
					operator: filtros.operator,
					value: filtros.value
				});
				return false;
			default:
				return true;
			}
		});

		if(elem_match.length > 0){
			new_filters.push({
				field: "status_log",
				operator: "elem_match",
				value: elem_match
			});
		}
		
		// se for um usuário comum só tem acesso aos pedidos que ele está como vendedor
		const usuario = state.rootState.app.usuario;
		if(!usuario.manager){
			new_filters.push({
				field: "seller._id",
				operator: "=",
				value: usuario.id
			});
		}
                
		if(data.opcoes.resume){            
			// se for para ver o resumo dos dados			
			data.opcoes.resume = false;
			// pegando os fields que é para trazer
			const fields = [
				"total_order",
				"total_shipping",
				"total_discount",
				"total_discounts",
				"marketplace",
				"products.quantity",
				"products.unit_cost",
				"products.cost",
				"store_id"
			];
			if(new_filters.length > 0){
				api.request("reports_pedidos/data_resume_loaded", "admin/orders", "post", { fields: fields, "filters": new_filters, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": 99999999, "page": 1 });
			}else{
				alerts.alertTop("error", "Selecione ao menos 1 filtro para exibir o resumo");
			}
            
		}else{
			// listagem normal
			let fields = [];
			config.reports_pedidos.campos.forEach(item => {
				if(item.hasOwnProperty("field_object")){
					fields.push(item.field+"."+item.field_object);
				}else{
					fields.push(item.field);
				}
			});
			state.commit("data_resume_loaded", {});
			api.request("reports_pedidos/data_valores_loaded", "admin/orders", "post", { fields:fields, "filters": new_filters, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
		}
        
	},
	load_status_data: function() {		
		return api.request("reports_pedidos/data_status_loaded", "admin/order/statuses", "post", { "filters": [{field:"status",operator: "=",value: 1}], "sort": "name,asc", "limit": 0, "page": 0 });
	},	
	load_motivos_data: function() {
		return api.request("reports_pedidos/no_action", "admin/order/cancel/reasons", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	load_vendedores_data: function() {
		return api.request("reports_pedidos/no_action", "admin/users", "post", { "filters": [{field:"seller",operator: "=",value: true}], "sort": "name,asc", "limit": 10, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
        
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("reports_pedidos/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("reports_pedidos/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("reports_pedidos/delete_filter", "admin/filter/"+id, "delete", id);
	},	
};