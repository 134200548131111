export default {
	data_resume_loaded(){        
		// window.console.log(payload);
		// let resume = {};
		// let relatorio = {};
		// if(payload.return_code == 1) {
		// 	const lojas = ["mundo","aventura","lexo","marketplace","all"];
		// 	for(const loja in lojas){
		// 		resume[lojas[loja]] = {
		// 			total_orders: 0,
		// 			total_revenue: 0,
		// 			total_shipping: 0,
		// 			total_discounts: 0,
		// 			total_cost: 0,
		// 		};
		// 	}				
		// 	payload.data.items.forEach( item => {				
		// 		let tipo;
		// 		if (item.marketplace.marketplace_id) {
		// 			tipo = "marketplace";
		// 		}else{
		// 			switch(item.store_id){
		// 			case 1:
		// 				tipo = "mundo";
		// 				break;
		// 			case 2:
		// 				tipo = "aventura";
		// 				break;
		// 			case 3:
		// 				tipo = "lexo";
		// 				break;					
		// 			}
		// 		}				
		// 		let total_cost = 0;
		// 		item.products.forEach( product => {	
		// 			const unit_cost = product.unit_cost || product.cost || 0;
		// 			total_cost += product.quantity * unit_cost;
		// 		});
		// 		// somando da loja específica
		// 		resume[tipo].total_orders += 1;
		// 		resume[tipo].total_revenue += item.total_order - item.total_shipping;
		// 		resume[tipo].total_shipping += item.total_shipping;
		// 		resume[tipo].total_discounts += item.total_discounts ?  item.total_discounts : item.total_discount;
		// 		resume[tipo].total_cost += total_cost;
		// 		// somando o geral
		// 		resume.all.total_orders += 1;
		// 		resume.all.total_revenue += item.total_order - item.total_shipping;
		// 		resume.all.total_shipping += item.total_shipping;
		// 		resume.all.total_discounts += item.total_discounts ?  item.total_discounts : item.total_discount;
		// 		resume.all.total_cost += total_cost;



		// 		// pegando dados de faturamento separados
		// 		// if(tipo == "marketplace"){
		// 		// 	if(!relatorio.marketplace){
		// 		// 		relatorio.marketplace = {};
		// 		// 	}
		// 		// 	const channel = (item.marketplace.marketplace_channel == "MERCADO_LIVRE_AV") ? "MERCADO_LIVRE" : item.marketplace.marketplace_channel;
		// 		// 	if(!relatorio["marketplace"][channel]){
		// 		// 		relatorio["marketplace"][channel] = {
		// 		// 			orders: 0,
		// 		// 			revenue: 0
		// 		// 		};
		// 		// 	}

		// 		// 	relatorio["marketplace"][channel].orders += 1;
		// 		// 	relatorio["marketplace"][channel].revenue += item.total_order;
		// 		// }else{
		// 		// 	if(!relatorio[tipo]){
		// 		// 		relatorio[tipo] = {
		// 		// 			orders: 0,
		// 		// 			revenue: 0
		// 		// 		};
		// 		// 	}
		// 		// 	relatorio[tipo].orders += 1;
		// 		// 	relatorio[tipo].revenue += item.total_order;
		// 		// }
				
		// 	});			
			
		// }	
		// state.resume_data = resume;
	},
	load_report_filters(state, filters) {
		state.filterData = filters;
	},
	multiselect_push: function(state,data){
		if(data.multiple){
			state.model[data.tipo].push(data.item);
		}else{
			state.model[data.tipo] = data.item;
		}		
	},
	multiselect_update: function(state,data){
		state.model[data.tipo] = data.item;
	},
};