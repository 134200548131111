<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row dashboard">
			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header ">
						Filtros Principais						
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-4">
								<FormInput :required=false rules="string" label="SKU" placeholder="" info="" v-model="d_filters.sku" type="text" />
							</div>
							<div class="col-4">
								<FormInput :required=false rules="string" label="Nome" placeholder="" info="" v-model="d_filters.name" type="text" />
							</div>
							<div class="col-3">
								<FormSelect :required=false rules="option" label="Tipo de Produto" info="" :options_none="{id: '', label: ''}" :options="product_types" v-model="d_filters.product_type"  type="text" />
							</div>													
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Meses em estoque acima de 6 meses?" info="" :options="[{id: false, label: 'Não'}, {id: true, label: 'Sim'}]" v-model="d_filters.months_6"  type="boolean" />
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Meses em estoque acima de 12 meses?" info="" :options="[{id: false, label: 'Não'}, {id: true, label: 'Sim'}]" v-model="d_filters.months_12"  type="boolean" />
							</div>							
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Quantidade em estoque maior que zero?" info="" :options="[{id: false, label: 'Não'}, {id: true, label: 'Sim'}]" v-model="d_filters.stock"  type="boolean" />
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Preço Loja Referência" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1, label: 'Mundo da Carabina'}, {id: 2,label: 'Aventura & Cia'}, {id: 3,label: 'Lexo'}]" v-model="d_filters.store_id"  type="number" />
							</div>	
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Desconto" placeholder="" info="" position="right" text="%" v-model="d_filters.discount" type="money" />
							</div>
							<div class="col-4">
								<label> </label>
								<button class="btn btn-primary btn-block" v-on:click="aplicaFiltro()">FILTRAR</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Tabela -->
		<Tabela ref="tabela" :config="config" :modulo_permissao=modulo_permissao :show_filters="false" />
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Tabela from "./../../common/Tabela";
import Filters from "../../../common/filters";

import FormInput from "../../common/form/Input";
import FormSelect from "../../common/form/Select";
import FormInputGroup from "../../common/form/InputGroup";

export default {
	name: "ReportsPedidos",
	components: {
		Header,
		Breadcrumb,
		Tabela,
		FormInput,
		FormSelect,
		FormInputGroup
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		d_filters() {
			return this.$store.state.reports_inventory.d_filters;
		},
		product_types() {
			return [
				{
					id: "AI",
					label: "AIRSOFT"
				},
				{
					id: "AR",
					label: "ARQUEARIA"
				},
				{
					id: "BE",
					label: "BEBIDA (COPO, CANECO, BALDE)"
				},
				{
					id: "CA",
					label: "CARABINA"
				},
				{
					id: "CH",
					label: "CHUMBINHO"
				},
				{
					id: "CL",
					label: "CAMPING E LAZER"
				},
				{
					id: "CN",
					label: "COZINHA (BANDEJA, MEDIDOR, COLHERES)"
				},
				{
					id: "CM",
					label: "CHIMARRÃO"
				},
				{
					id: "CO",
					label: "COMBOS"
				},
				{
					id: "CR",
					label: "CHURRASCO (GRELHA, CONJ FACA)"
				},
				{
					id: "CS",
					label: "CALÇADOS"
				},
				{
					id: "CT",
					label: "CUTELARIA"
				},
				{
					id: "DE",
					label: "DECORAÇÃO (QUADRO, RELOGIO)"
				},
				{
					id: "DI",
					label: "DIVERSOS"
				},
				{
					id: "EL",
					label: "ELETRONICO (MOUSE, HEADPHONE, MONITOR)"
				},
				{
					id: "ES",
					label: "ESPORTE"
				},
				{
					id: "FR",
					label: "FERRAMENTAS"
				},
				{
					id: "OL",
					label: "OUTLET"
				},
				{
					id: "PN",
					label: "PESCA E NAUTICA"
				},
				{
					id: "PP",
					label: "PISTOLA DE PRESSÃO"
				},
				{
					id: "PR",
					label: "PRAIA"
				},
				{
					id: "PS",
					label: "PEÇAS REPOSIÇÃO"
				},
				{
					id: "PT",
					label: "PET"
				},
				{
					id: "SE",
					label: "SEGURANÇA"
				},
				{
					id: "UD",
					label: "ULTILIDADE DOMESTICA"
				},
				{
					id: "VE",
					label: "VESTUARIO"
				},
			];
		}
	},
	filters: Filters,
	data () {
		return {
			header_info: {
				title: "Controle de Estoque e Obsolescência",
				chamada: "Controle de Estoque e Obsolescência",
				links: {
					criar: "",
				}
			},
			breadcrumb_info: [
				{
					name: "Controle de Estoque e Obsolescências",
					link: ""
				}
			],
			filtros: null,
			valores: null,
			permissao: [],
			config: {
				state: "reports_inventory",
				tipo: "reports_inventory",
				modulo_permissao: this.modulo_permissao,
				url_path: "reports.inventory",
				flags: {
					multi: {
						enable: true,
						delete: false,
						export: true,
						print: false,
						transfer: false,
						activate: false,
						inactivate: false,						
					},
					look: false,
					edit: false,
					delete: false,
				}
			}			
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	mounted() {
	},
	provide: function () {
		return {
		};
	},
	methods: {		
		novo() {
			this.$store.dispatch(this.config.state + "/reset");
		},
		aplicaFiltro() {
			const _store = this.$store.state[this.config.state];
			this.$store.dispatch(this.config.state + "/lista_filter_update", _store.search_data);
		}		
	},	
	watch: {
		d_filters:{
			handler: function(item){
				this.$store.commit(this.config.state + "/update_d_filters", item);
				this.$store.commit(this.config.state + "/data_valores_loaded", {return_code: 1, data: this.$store.state[this.config.state].valores});
			},		
			deep: true
		},
	}
};
</script>

<style scoped lang="scss">	
	
</style>
