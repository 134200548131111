<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<div class="row">
					<div class="col-8">
						<div class="card card-base" :class="itens_tab.length > 0">
							<div class="card-header">
								Informações do Atributo
							</div>
							<div class="card-body">
								<!-- Conteudo -->
								<div class="row">
									<div class="col-12">
										<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" />
									</div>
									<div class="col-12">
										<FormSelect :required=true rules="option" label="Tipo" info="" :options="[{id: 'option',label: 'Opções'}, {id: 'text', label: 'Texto'}]" v-model="model.type" />		
									</div>
									<div class="col-12">
										<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
									</div>
								</div>						
								<!-- Fim Conteudo -->
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="card card-base" :class="itens_tab.length > 0">
							<div class="card-header">
								Opções
							</div>
							<div class="card-body">
								<!-- Conteudo -->
								<div class="row">
									<div class="col-12" v-if="model.type == 'text'">
										<div class="alert alert-warning" role="alert">
											Não é necessário informar opções para o tipo texto.
										</div>
									</div>
									<div class="col-12 options" v-if="model.type == 'option'">
										<draggable 
											handle=".order"
											:group="{ name: 'people', pull: 'clone', put: false }"
											ghost-class="ghost"
											:sort="true"
											:tag="'div'"
											@end="sortable_update"					
											v-bind="dragOptions"
											>
											<div class="input-group mb-2" v-for="(opcao,i) in model.values" :key="opcao.key">
												<div class="input-group-prepend order">
													<button class="btn btn-secondary rounded-left" type="button"><i class="fas fa-sort "></i></button>
												</div>
												<input type="text" class="form-control" placeholder="Digite o nome da opção" v-model="opcao.name" v-on:keyup.enter="addOpcao()" />
												<div class="input-group-prepend">
													<button class="btn btn-danger rounded-right" type="button" v-on:click="deletaOpcao(i)"><i class="fas fa-times"></i></button>
												</div>
											</div>
										</draggable>
										<button class="btn btn-success btn-sm btn-block font-weight-bold" v-on:click="addOpcao()"><i class="fas fa-plus"></i> Nova Opção</button>										
									</div>									
								</div>						
								<!-- Fim Conteudo -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import draggable from "vuedraggable";
import Alerts from "./../../../common/alerts";
import {Slug} from "./../../../common/utils";

// Inputs
import FormInput from "./../../common/form/Input";
// import FormInputGroup from "./../../common/form/InputGroup";
import FormSelect from "./../../common/form/Select";
// import FormTextarea from "./../../common/form/Textarea";

export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		// FormInputGroup,
		FormSelect,
		// FormTextarea
		draggable,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			let model = this.$store.state.atributos.model;
			if(model.values.length > 0){
				model.values.sort((a, b) => {
					if (a.order > b.order) return 1;
					if (b.order > a.order) return -1;
					return 0;
				});
			}
			return model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false, // se existir marca como false, se não existir marca como true
				ghostClass: "ghost"
			};
		}
	},	
	data () {
		return {
			header_info: {
				title: "Atributos de Produtos",
				chamada: "Gerencie os atributos da sua loja",
				links: {
					back: "atributos.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Atributos",
					link: "/atributos"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[],
			disabled_items: [],
			hidden_items: []
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){				
				if(this.model.type == "option"){
					if(this.model.values.length > 0) {
						let valid = true;
						this.model.values.forEach(item => {
							if(item.name == ""){								
								valid = false;
							}
						});
						if(!valid){
							Alerts.alertTop("error","Todas as opções devem ser preenchidas.");
							return false;
						}						
					}else{
						Alerts.alertTop("error","É necessário inserir ao menos uma opção.");
						return false;
					}
				}else{
					this.model.values = [];
				}

				//cria o slug
				this.model.slug = Slug(this.model.name);

				// salva
				this.$store.dispatch("atributos/save", this.model);
			}
		},
		addOpcao: function(){
			// pega a maior ordem
			let maior_ordem = 0;
			let maior_key = 0;
			this.model.values.forEach((data)=>{
				if(data.order > maior_ordem){
					maior_ordem = data.order;
				}
				if(data.key > maior_key){
					maior_key = data.key;
				}				
			});
			// inclui
			this.model.values.push({
				name: "",
				slug: "",
				order: maior_ordem + 1, 
				key: maior_key + 1,
				extra: {}
			});
			this.$store.commit("atributos/update_order_options",this.model.values);
			// dá o focus no último input
			this.$nextTick(() => {
				let inputs = document.getElementsByClassName("options")[0].getElementsByTagName("input");
				inputs[inputs.length - 1].focus();
			});			
		},
		deletaOpcao: function(index){
			let item = this.model.values[index];
			if(item.id != undefined){
				// envia para api para ver se pode deletar, caso positivo a api já deleta
				this.$store.dispatch("atributos/deleteOpcao",item);
			}else{
				this.model.values.splice(index,1);
			}
		},
		sortable_update: function(obj){
			let values = this.model.values;
			values.splice(obj.newIndex, 0, values.splice(obj.oldIndex, 1)[0]);

			let order = 1;
			values.forEach(function(item){
				item.order = order;
				order++;
			});

			this.$store.commit("atributos/update_order_options",values);
		}
	},
	provide: function () {
		return {
			save: this.save
		};
	},
	created() {
		this.$store.dispatch("atributos/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("atributos/load", this.$route.params.id);		
		}
	},
	watch: {
		
	}
};
</script>

<style scoped lang="scss">	
	
</style>
