<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">

			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Desconto
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>			
							<div class="col-6">
								<FormSelect :required=true rules="option_store" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>																											
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Tipo de Desconto" info="" :options="[{id: 1,label: 'Desconto Reais'}, {id: 2, label: 'Desconto Porcentagem'}]" v-model="model.discount_type" type="number"/>		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Tipo de Promoção" info="" :options="[{id: 1,label: 'Todos os Produtos'}, {id: 2, label: 'Marcas'}, {id: 3, label: 'Categorias'}, {id: 4, label: 'Produtos'}]" v-model="model.offer_type" type="number" @input="alteraTipo()" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status"  type="number" />
							</div>
							<div class="col-12">
								<AddOn ref="rules" state="descontos_progressivos" title="Especificar regras de descontos na promoção" type="rules" />
							</div>	
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Categorias -->
				<div class="hide card card-base">
					<div class="card-header">
						Categorias
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Categorias :permissao=permissao state="descontos_progressivos" :selecionados="categorias_selecionadas" :selecionavel=true :store_enabled=model.store_id />
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Marcas -->
				<div class="hide card card-base">
					<div class="card-header">
						Marcas
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="descontos_progressivos" tipo="marcas_selecionadas" :dados="marcas" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Produtos -->
				<div class="hide card card-base">
					<div class="card-header">
						Produtos
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelectProdutos  state="descontos_progressivos" tipo="produtos_selecionados" :selectVariations=true :needs_store=true :store_id=model.store_id></MultiSelectProdutos>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import MultiSelect from "./../../common/MultiSelect";
import AddOn from "./../../common/AddOn";
import MultiSelectProdutos from "./../../common/MultiSelectProdutos";
import Categorias from "./../../common/Categorias";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import Alerts from "../../../common/alerts";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		MultiSelect,
		AddOn,
		MultiSelectProdutos,
		Categorias
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.descontos_progressivos.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		rules() {
			return this.$store.state.descontos_progressivos.rules;
		},
		categorias_selecionadas() {
			return this.$store.state.descontos_progressivos.model.categorias_selecionadas;
		},
		marcas_selecionadas() {
			return this.$store.state.descontos_progressivos.model.marcas_selecionadas;
		},
		categorias() {
			return this.$store.state.descontos_progressivos.categorias;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Descontos Progressivos",
				chamada: "Gerencie os descontos progressivos da sua loja",
				links: {
					back: "promocoes.progressivos.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Descontos Progressivos",
					link: "/promocoes/progressivos"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Categorias",
				"Marcas",
				"Produtos"
			],
			disabled_items: [],
			hidden_items: [
				"Categorias",
				"Marcas",
				"Produtos"
			],
			permissao: {},
			marcas: [],
			isActive: false
		};
	},  
	methods: {		
		save: function(){			

			if(validation.validation().valid){			
				let valid = true;
				let model = Object.assign({},this.model);
				
				// tratando a faixa de cep
				model.rules = [];
				this.rules.forEach(item => {
					if(typeof item == "object"){
						if(item.min.value != "" && item.value.value != ""){
							model.rules.push({ min: item.min.value, value: item.value.value });
						}else{
							if(item.min.value != "" || item.value.value != ""){
								Alerts.alertTop("error","Preencha corretamente as faixas de desconto");
								valid = false;
							}
						}
					}					
				});

				model.relation = [];				

				// tratando os relacionamentos
				switch(model.offer_type){								
				case 2:
					// marca
					model.marcas_selecionadas.forEach(item => {
						model.relation.push(item.id);
					});
					break;
				case 3:
					// categoria
					model.categorias_selecionadas.forEach(item => {
						model.relation.push(item.id);
					});
					break;								
				case 4:
					// produtos
					model.produtos_selecionados.forEach(produtos => {
						produtos.skus.forEach(item => {
							if(item.use){
								model.relation.push(item.sku);
							}							
						});						
					});
					break;
				}
				// apaga os campos
				delete model.marcas_selecionadas;
				delete model.categorias_selecionadas;
				delete model.produtos_selecionados;

				// salva
				if(valid){
					this.$store.dispatch("descontos_progressivos/save", model);
				}
				
			}
		},
		alteraTipo: function(){
			let type = this.model.offer_type;			
			switch(type){						
			case 2:
				// Marcas 
				this.hidden_items = [
					"Categorias",
					"Produtos"	
				];
				break;
			case 3:
				// Categorias
				this.hidden_items = [
					"Marcas",
					"Produtos"	
				];
				break;
			case 4:
				// Produtos 
				this.hidden_items = [
					"Categorias",
					"Marcas",
				];
				break;
			default:
				this.hidden_items = [
					"Categorias",
					"Marcas",
					"Produtos"				
				];
				break;
			}		
		},	
	},
	provide: function () {
		return {
			save: this.save,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("descontos_progressivos/reset");
		let startingPromises = [
			// pega as categorias
			this.$store.dispatch("descontos_progressivos/load_categorias"),
			// pega as marcas
			this.$store.dispatch("descontos_progressivos/load_marcas").then(response => {
				self.marcas = response;
			}),
		];
		
		Promise.all(startingPromises).then(() => {
			if(self.$route.params.id != undefined && self.$route.params.id != "") {
				self.$store.dispatch("descontos_progressivos/load", self.$route.params.id).then(response => {
					if(response.return_code == 1){
						// popula com os dados vindo do banco
						self.$store.commit("descontos_progressivos/addOnUpdateAll",{ tipo: "rules", itens: response.data.rules});
						// faz com que o component atualize o state com os dados já tratados
						this.$refs.rules.updateComponent();
						
						let data = response.data;

						// verifica se a promoção está ativa
						self.isActive = (data.status == 1 && data.id != null);

						// executa a função que vai exibir a aba correta de acordo com o tipo
						self.alteraTipo();

						switch(data.offer_type){													
						case 2:
							// marcas
							self.$store.commit("descontos_progressivos/update_marcas_selecionadas",data.relation.map(item => {
								let marca = self.marcas.find(dado => {
									return item == dado.id;
								});
								if(marca){
									return marca;
								}
							}));
							break;
						case 3:
							// categoria
							var categorias = [];
							data.relation.forEach(item => {
								categorias.push({id: item});
							});
							self.$store.commit("descontos_progressivos/update_categorias_selecionadas",categorias);
							break;						
						case 4:
							// produtos
							self.$store.dispatch("descontos_progressivos/load_produtos_selecionados_data",data.relation);
							break;
						}
					}					
				});
			}
		}, () => {
			
		});

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
