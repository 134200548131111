<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao :btn_preview="setPreview" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Produto
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<FormInput :required=true rules="string" label="Título" placeholder="" info="" v-model="model.title" :slug_function=true />
							</div>
							<div class="col-12" v-if="model.is_marketplace">
								<FormInput :required=false rules="string" label="Título - Marketplace" :maxlength=60 placeholder="" info="Máximo 60 caracteres" v-model="model.title_marketplace" />
							</div>
							<div class="col-6">
								<div class="form-group required"> 
									<label class="mb-1">Tipo de Produto <i class="fas fa-asterisk"></i></label>
									<MultiSelect state="produtos" tipo="product_type" :dados="tipos_produtos" :field_required=false :multiple=false :required=true :excluir_validation=true></MultiSelect>								
								</div>
							</div>
							<div class="col-6">
								<div class="form-group required"> 
									<label class="mb-1">Marca <i class="fas fa-asterisk"></i></label>
									<MultiSelect state="produtos" tipo="brand" :dados="brands" :field_required=false :multiple=false  :required=true></MultiSelect>								
								</div>
							</div>
							<div class="col-6">
								<div class="form-group required"> 
									<label class="mb-1">Fornecedor <i class="fas fa-asterisk" v-if="!model.is_kit"></i></label>
									<MultiSelect state="produtos" tipo="provider" :dados="providers" :field_required=false :multiple=false  :required="model.is_kit ? false : true"></MultiSelect>								
								</div>
							</div>							
							<div class="col-6"> 
								<FormSelect :required=true rules="option" label="Integrar com Marketplace?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.is_marketplace" type="boolean" />		
							</div>
							<div class="col-4"> 
								<FormSelect :required=true rules="option" label="Mundo da Carabina?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="store_mundo" type="boolean" />
							</div>
							<div class="col-4"> 
								<FormSelect :required=true rules="option" label="Aventura & Cia?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="store_aventura" type="boolean" />
							</div>
							<div class="col-4"> 
								<FormSelect :required=true rules="option" label="Lexo?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="store_lexo" type="boolean" />
							</div>
							<div class="col-4"> 
								<FormSelect :required=true rules="option" label="Combo?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.is_kit" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Precisa de CR?" info="" :options="[{id: true, label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.need_cr" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Outlet?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.is_outlet" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Google Shopping?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.google_shopping" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Facebook Shopping?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.facebook_shopping" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Maiores de 18 anos?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.under_18" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Envia Correio?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.allows_correios" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Permite Promoção" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.allows_offer" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Exibir na Listagem" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.display_in_list" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Frete Grátis" info="" :options="[{id: 1,label: 'Somente em Promoções'}, {id: 2, label: 'Nunca Permitir'}, {id: 3, label: 'Sempre Permitir'}]" v-model="model.shipping_rules"  type="number" />		
							</div>																																		
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Preço Sob Consulta" info="" :options="[{id: false, label: 'Não'}, {id: true, label: 'Sim'}]" v-model="model.on_request" type="boolean" />		
							</div>																																		
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="Slug" placeholder="" info="" position="left" text="https://*.com.br" v-model="model.slug" />
							</div>	
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data de Cadastro" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.published_at" :index=0 />
							</div>
							<div class="col-4">
								<FormInputGroup :required=false rules="string" label="Data de Lançamento" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.release_until" :index=1 />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}, {id: 2, label: 'Incompleto'}]" v-model="model.status"  type="number" />		
							</div>								
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Metatags -->
				<div class="hide card card-base">
					<div class="card-header">
						MetaTags
					</div>
					<div class="card-body" v-if="model.id != null">
						<!-- Conteudo -->
						<Metatags v-model="model.metatags"></Metatags>
						<div class="row">
							<div class="col-12">
								<FormInput :required=false rules="string" label="Canonical Link" placeholder="" info="Informe o link completo com https" v-model="model.canonical" />
							</div>	
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Informações -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações
					</div>
					<div class="card-body" v-if="model.id != null">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<FormTextarea :required=true label="Chamada" v-model="model.short_description" :rows=3 placeholder="" info="Informe um texto curto com os principais destaques do produto" type="textarea" />
							</div>
							<div class="col-12">
								<FormTextarea state="produtos" upload_type="editor_products" :required=true label="Descrição Completa" v-model="model.description" :rows=3 placeholder="" info="Informe um texto completo sobre o produto" type="editor" />
							</div>	
							<div class="col-12 options my-3">
								<div class="form-group">
									<label class="mb-1">Vídeos do Produto</label>
									<small class="form-text text-muted mt-2">Adicione ao seu produto quantos vídeos achar necessário</small>
								</div>								

								<draggable 
									handle=".order"
									:group="{ name: 'people', pull: 'clone', put: false }"
									ghost-class="ghost"
									:sort="true"
									:tag="'div'"
									@end="sortable_update_videos"					
									v-bind="dragOptions"
									>
									<div class="input-group mb-2" v-for="(opcao,i) in model.videos" :key="opcao.key">
										<div class="input-group-prepend order">
											<button class="btn btn-secondary rounded-left" type="button"><i class="fas fa-sort "></i></button>
										</div>
										<input type="text" class="form-control" placeholder="Digite a url completa do vídeo no youtube" v-model="opcao.url" v-on:keyup.enter="addVideo()" />
										<div class="input-group-prepend">
											<button class="btn btn-danger rounded-right" type="button" v-on:click="deletaVideo(i)"><i class="fas fa-times"></i></button>
										</div>
									</div>
								</draggable>
								<button class="btn btn-success btn-sm btn-block font-weight-bold mb-3" v-on:click="addVideo()"><i class="fas fa-plus"></i> Novo vídeo</button>										
							</div>						
							<div class="col-12">
								<div class="alert alert-info" role="alert">
									Para utilizar algum desses atributos nas variações dos produtos clique em "usar como variação".
									<strong>Importante:</strong> Após incluir ao menos duas variações não será possível incluir ou remover atributos nas variações.
									Prencha apenas os atributos que você deseja que seja exibido na página do produto.
								</div>
							</div>
							<div class="col-12" v-for="item in attributes" :key=item.id>
								<FormInput v-if="item.type == 'text'" :required=item.required rules="string" :label=item.name placeholder="" info="" v-model="item.value" />
								<FormSelect v-if="item.type == 'option'" :required=item.required rules="option" :label=item.name info="" :options=item.values :checkbox=true :as_variation=item.as_variation :disabled="(model.variations.length > 1)" :id=item.id v-model="item.value.id" />
							</div>														
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Fotos -->
				<div class="hide card card-base">
					<div class="card-header">
						Fotos
					</div>
					<div class="card-body" v-if="model.id != null">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<div class="alert alert-info" role="alert">
									Cadastre abaixo quantas galerias de fotos forem necessárias para este produto. Você pode optar por criar uma galeria para cada variação ou criar uma galeria apenas e utilizá-la em todas as variações.
								</div>
							</div>
							<div class="col-12">
								<div class="input-group mb-3">
									<input type="text" class="form-control" placeholder="Digite o nome da nova galeria" v-model="galeria_name" v-on:keyup.enter="novaGaleria()" />
									<div class="input-group-append">
										<button class="btn btn-success" v-on:click="novaGaleria()">Criar Galeria</button>
									</div>
								</div>
							</div>
							<div class="col-12 my-2" v-for="galeria in model.galleries" :key="galeria.id">
								<div class="card">
									<div class="card-header">
										<input type="text" v-model="galeria.name" v-on:change="updateNameGaleria(galeria)" v-on:keyup.enter="updateNameGaleria(galeria)" class="input_galeria_name" />
									</div>
									<div class="card-body position-relative">
										<div class="edit-legenda hide">
											<div class="legenda-background"></div>
											<div class="row justify-content-md-center align-items-center">
												<div class="col-5">													
													<div class="form-group">
														<label class="mb-1">Legenda da Foto</label>
														<div class="input-group mb-3">
															<input type="text" class="form-control legenda_editavel" value=""  v-on:keyup.enter="saveLegenda($event)" />
															<div class="input-group-append">
																<button class="btn btn-success" type="button" v-on:click="saveLegenda($event)"><i class="fas fa-check"></i></button>
																<button class="btn btn-danger" type="button" v-on:click="fechaLegenda($event)"><i class="fas fa-times"></i></button>
															</div>
															<small class="form-text text-muted">Descreva do que se trata essa foto</small>
														</div>														
													</div>
												</div>
											</div>
										</div>
										<div class="alert alert-info" role="alert" v-if="galeria.pictures.length == 0">
											Nenhuma foto cadastrada até o momento
										</div>
										<draggable 
											handle=".order"
											:group="{ name: 'people', pull: 'clone', put: false }"
											ghost-class="ghost"
											:class="'row'"
											:sort="true"
											:tag="'div'"
											@end="sortable_update"					
											v-bind="dragOptions"
											>
											<div v-for="picture in galeria.pictures" :key=picture.id class="picture col-3" :gallery="picture.gallery_id">
												<img :src="getThumb(galeria, picture)" class="img-thumbnail" />
												<label class="my-2">
													<button class="btn btn-secondary btn-sm mx-1 order"><i class="fas fa-sort"></i></button>
													<button class="btn btn-primary btn-sm mx-1" v-on:click="editLegenda($event,picture)"><i class="fas fa-pencil-alt"></i></button>
													<button class="btn btn-danger btn-sm mx-1" v-on:click="removeImagem(picture)"><i class="fas fa-times"></i></button>
												</label>
											</div>
										</draggable>										
									</div>
									<div class="card-footer">
										<input type="file" name="imagem" :id="'fileSimple'+galeria.id" class="inputFileSimple" v-on:change="onFileChange($event,galeria)" multiple />
										<label class="btn btn-primary btn-sm fileSimple" :for="'fileSimple'+galeria.id" >Adicionar Fotos</label>										
										<button class="btn btn-danger btn-sm float-right" v-on:click="removeGaleria(galeria)">Apagar Galeria</button>
										<button v-if="model.is_marketplace" class="btn btn-warning btn-sm float-right mr-2" v-on:click="forceUpdateImageMarketplace()">Sincronizar Mktplace</button>
									</div>
								</div>
								
							</div>
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Vínculos -->
				<div class="hide card card-base">
					<div class="card-header">
						Vínculos
					</div>
					<div class="card-body" v-if="model.id != null">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<div class="form-group mb-0"> 
									<label class="mb-1">Tags de Busca</label>
									<MultiSelect state="produtos" tipo="tags" :dados="tags" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true></MultiSelect>
								</div>
							</div>
						</div>

						<hr />

						<div class="row">
							<div class="col-12">
								<div class="form-group required"> 
									<label class="mb-2">Categorias do produto <i class="fas fa-asterisk"></i></label>
									<!-- Categorias -->
									<Categorias :permissao=permissao state="produtos" :selecionados="model.categories" :selecionavel=true />
								</div>
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Combo -->
				<div class="hide card card-base">
					<div class="card-header">
						Combo
					</div>
					<div class="card-body" v-if="model.id != null">
						<!-- Conteudo -->
						<Combos v-if="model.is_kit"></Combos>
						<div class="row" v-if="!model.is_kit">
							<div class="col-12">
								<div class="alert alert-info" role="alert">
									Para cadastrar os produtos que formam esse combo marque o produto como Combo na aba "Geral".
								</div>
							</div>
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Variações -->
				<div class="hide card card-base">
					<div class="card-header">
						Variações
					</div>
					<div class="card-body" v-if="model.id != null">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12 mb-3">
								<div class="form-group mb-0">
									<div class="input-group ">
										<input type="text" :disabled="model.is_kit" class="form-control" placeholder="Digite o SKU do Produto" v-model="new_sku" v-on:focusin="exibir_skus=true;" v-on:blur="exibir_skus=false;" v-on:keyup.enter="novaVariacao()"  v-on:keyup="waitingRegisterFiltered()" v-on:keyup.38="navigateActive('up')" v-on:keyup.40="navigateActive('down')" />
										<div class="input-group-append">
											<button class="btn btn-success" v-on:click="novaVariacao()"><i class="fas fa-plus"></i> Nova Variação</button>
										</div>
									</div>
									<small class="form-text text-muted mt-2" v-if="model.is_kit">Para Combos não é necessário digitar um SKU</small>
								</div>								
								<div class="dados" v-show="exibir_skus">
									<ul class="list-group filtros-selecionar-waiting-register">
										<li class="list-group-item" v-for="dado in waitingRegisterFiltered()" :key="dado.sku" v-on:mousedown="novaVariacao(dado)" :sku=dado.sku>
											({{ dado.created_at | date }}) {{ dado.name }} | {{ dado.sku }} / {{ dado.supplier_code }}
										</li>
									</ul>
								</div>
							</div>

							<draggable 
								handle=".card-order"
								:group="{ name: 'people', pull: 'clone', put: false }"
								ghost-class="ghost"
								:class="'col-12'"
								:sort="true"
								:tag="'div'"
								@end="sortable_update_variation"					
								v-bind="dragOptions"
								>
								<div class="my-2 card-order" v-for="variation in model.variations" :key="variation.id">
									<div class="card card-variation">
										<div class="card-header position-relative pl-5">
											<strong v-on:click="copySku(variation.sku)">{{ variation.sku }}</strong>
											<div v-for="attribute in variation.attributes" :key="attribute.id"  class="d-inline-block"><span v-if="attribute.value.id != null && attribute.value.id != ''" class="pl-1">- {{ getAttributeName(attribute) }}</span></div>											
											<span class="max-mini-variation position-absolute" v-on:click="toogleVariation($event)"><i :class="(variation.id != null) ? 'far fa-plus-square' : 'far fa-minus-square'"></i></span>
											<button class="btn btn-sm btn-danger delete-variation position-absolute" v-on:click="removeVariacao(variation)"><i class="fas fa-times"></i></button>
										</div>
										<div class="card-body" :class="(variation.id != null) ? 'hide' : ''">
											<div class="row" v-if="variation.attributes.length > 0">
												<div class="col" v-for="attribute in variation.attributes" :key="attribute.id"> 												
													<FormSelect :required=true rules="option" :label="attribute.name" info="" :options="getAttributesValues(attribute.id)" v-model="attribute.value.id" />
												</div>
											</div>
											<div class="row">
												<div class="col-6">
													<FormInput :required="model.is_kit ? false : true" rules="string" label="Descrição da Nota" placeholder="" info="" v-model="variation.invoice_description" />
												</div>
												<div class="col-6">
													<FormInput :required="model.is_kit ? false : true" rules="string" label="Código Fornecedor" placeholder="" info="" v-model="variation.supplier_code" />
												</div>
												<div class="col-4">
													<FormInputGroup :required=true rules="string" type="money" label="Desconto Venda Casada" placeholder="" info="" position="right" text="%" v-model="variation.discount_joint_sale" />
												</div>
												<div class="col-4">
													<FormSelect :required=true rules="option" label="Desconto à vista" info="válido somente para combos" :options="[ {id: 'automatic', name: 'Automático'},{ id: 'manual',name: 'Manual'}]" v-model="variation.combo_rules.type" />
												</div>
												<div class="col-4">
													<FormInputGroup :required=true :disabled="(variation.combo_rules.type == 'automatic')" rules="string" type="money" label="Valor do Desconto" placeholder="" info="válido somente para combos" position="right" text="%" v-model="variation.combo_rules.value" />
												</div>
												<div class="col-3">
													<FormInputGroup :required=true rules="string" type="money" label="Peso" placeholder="" info="Informações de Frete" position="right" text="Kg" v-model="variation.weight" />
												</div>
												<div class="col-3">
													<FormInputGroup :required=true rules="string" type="money" label="Comprimento" placeholder="" info="Informações de Frete" position="right" text="Cm" v-model="variation.dimension.length" />
												</div>
												<div class="col-3">
													<FormInputGroup :required=true rules="string" type="money" label="Altura" placeholder="" info="Informações de Frete" position="right" text="Cm" v-model="variation.dimension.height" />
												</div>
												<div class="col-3">
													<FormInputGroup :required=true rules="string" type="money" label="Largura" placeholder="" info="Informações de Frete" position="right" text="Cm" v-model="variation.dimension.width" />
												</div>
												<div class="col-6">
													<FormSelect :required=true rules="option" label="Galeria de Fotos" info="" :options="model.galleries.map(it => { return { id: it.id, name: it.name }; })" v-model="variation.gallery_id" />
												</div>
												<div class="col-6">
													<FormInputGroup :required=true rules="string" label="Prazo extra" placeholder="" info="" position="right" text="dias" v-model="variation.extra_time" />
												</div>
												<div class="col-6">
													<FormInput :required=true rules="string" label="Quantidade mínima para venda" placeholder="" info="Obrigado a comprar no mínimo x itens" v-model="variation.stock_min" />
												</div>
												<div class="col-6">
													<FormInput :required=false rules="string" label="Código EAN" placeholder="" info="" v-model="variation.ean_code" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</draggable>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug, deepCopy} from "./../../../common/utils";
import url_prefix from "./../../../assets/json/urls.json";
import Metatags from "./../../common/MetaTags";
import MultiSelect from "./../../common/MultiSelect";
import Alerts from "./../../../common/alerts";
import Categorias from "./../../common/Categorias";
import draggable from "vuedraggable";
import Filters from "./../../../common/filters";
import Combos from "./Combos";
import moment from "moment";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";
import FormTextarea from "./../../common/form/Textarea";

export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		Metatags,
		MultiSelect,
		FormTextarea,
		Categorias,
		draggable,
		Combos
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			let self = this;
			// trabalhando a ordenação das fotos das galerias
			let model = this.$store.state.produtos.model;

			// tratando o stores
			self.store_mundo = false;
			self.store_aventura = false;
			self.store_lexo = false;
			model.stores.forEach(item => {
				switch(item){
				case 1:
					self.store_mundo = true;
					break;
				case 2:
					self.store_aventura = true;
					break;
				case 3:
					self.store_lexo = true;
					break;
				}
			});

			// ordenando as fotos das galerias
			model.galleries.forEach(item => {
				item.pictures.sort(function (a, b) {
					if (a.order > b.order) {
						return 1;
					}
					if (a.order < b.order) {
						return -1;
					}					
					return 0;
				});
			});

			// ordenando as variacoes
			if(model.variations){
				model.variations.sort(function (a, b) {
					if (a.order > b.order) {
						return 1;
					}
					if (a.order < b.order) {
						return -1;
					}					
					return 0;
				});
			}else{
				model.variations = [];
			}			

			return model;
		},
		attributes() {
			let attributes = [];	
			if(this.model && this.model.product_type.id != null && this.tipos_produtos.length > 0){
				let type = this.model.product_type;
				attributes = this.tipos_produtos.find(item => {
					return item.id == type.id;
				});
				attributes = Object.assign([],attributes.attributes);
				attributes.forEach(item => {
					// buscando se já tem valor desse atributo no model
					let model_attributes = this.model.attributes;
					let valor = model_attributes.find(dado => {
						return dado.id == item.id;
					});

					if(valor != undefined){						
						if(item.as_variation){
							item.value = {
								id: null,
								name: null
							};
						}else{
							item.value = valor.value;
						}
						
					}else{						
						if(item["value"] == undefined){
							switch(item.type){
							case "text":							
								item["value"] = null;
								break;
							case "option":
								item["value"] = {
									id: null,
									name: null
								};
								break;
							}					
						}						
					}

					// verificando se é utilizado como variacao
					if(item.type == "option"){						
						if(this.model.variations.length > 0){							
							let exists = this.model.variations[0].attributes.find(attr => {
								return attr.id == item.id;
							});
							if(exists){
								item["as_variation"] = true;
							}
						}						
					}					
				});
			}			
			return attributes;
		},
		variation_model() {
			return this.$store.state.produtos.variation_model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		dragOptions() {			
			return {
				animation: 200,
				group: "description",
				disabled: false, // se existir marca como false, se não existir marca como true
				ghostClass: "ghost"
			};
		},
		setPreview() {
			if(this.model.status === 2){
				return this.model && this.model.preview ?
					{ use: true, disabled: false } :
					{ use: true, disabled: true };
			}else{
				return { use: false, disabled: false };
			}
			
		}
	},	
	data () {
		return {
			header_info: {
				title: "Produtos",
				chamada: "Gerencie as produtos da sua loja",
				links: {
					back: "produtos.lista",
					save: ".",
					sale_validation: true, 
					content_validation: true,
				}
			},
			breadcrumb_info: [				
				{
					name: "Produtos",
					link: "/produtos"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Básico",
				"MetaTags",
				"Informações",
				"Fotos",
				"Vínculos",
				"Combo",
				"Variações"
			],
			disabled_items: [],
			hidden_items: [],
			store_mundo: false,
			store_aventura: false,
			store_lexo: false,
			tipos_produtos: [],
			brands: [],
			providers: [],
			tags: [],
			galeria_name: "",
			permissao: {},
			new_sku: "",
			waiting_register: [],
			exibir_skus: false
		};
	},  
	created() {		
		let self = this;
		this.$store.dispatch("produtos/reset");		
		let startingPromises = [
			// pega os tipos de produtos
			this.$store.dispatch("produto_tipos/load_tipos").then((response) => {
				self.tipos_produtos = response;				
			}),
			// pega as marcas
			this.$store.dispatch("marcas/load_brands").then((response) => {
				self.brands = response;
			}),
			// pega os providers
			this.$store.dispatch("produtos/load_providers").then((response) => {
				self.providers = response;
			}),
			// pega as categorias
			this.$store.dispatch("produtos/load_categorias"),
			// pega as tags
			this.$store.dispatch("produtos/load_tags_data").then((response) => {
				self.tags = response;
			}),
			// pega os produtos aguardando cadastro
			this.$store.dispatch("produtos/load_produtos_aguardando_cadastro").then((response) => {
				// ordenando pela data decrescnte 
				if(response && typeof response == "object"){
					response.sort(function (a, b) {
						if (a.created_at < b.created_at) {
							return 1;
						}
						if (a.created_at > b.created_at) {
							return -1;
						}					
						return 0;
					});
				}
				self.waiting_register = response;
			}),
		];
		Promise.all(startingPromises).then(() => {
			// load
			if(this.$route.params.id != undefined && this.$route.params.id != "") {
				this.$store.dispatch("produtos/load", this.$route.params.id);
			}else{
				this.disabled_items = [
					"MetaTags",
					"Informações",
					"Fotos",
					"Vínculos",
					"Combo",
					"Variações"
				];
				// se for um pré-cadastro
				if(this.$route.params.title){
					const waiting_params = this.$route.params;
					waiting_params["created_at"] = `${moment().format("YYYY-MM-DD")} 00:00:00`;
					this.$store.commit("produtos/pre_cadastro", waiting_params);
					this.novaVariacao({sku: waiting_params.sku});
					this.toSlug();					
					
				}
			}			
		}, () => {
			Alerts.alertTop("error","O sistema está com problemas, contate o administrador.");			
		});

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	methods: {		
		getThumb: function (galeria, picture) {
			return this.$store.state.app.img_url_base+picture.url + "?size=80";
		},
		async save() {
			let self = this;
			if(validation.validation().valid){

				const msgsValidacao = [];

				if(this.model.id){
					const categories = this.model.categories;
					const stores = [];
					if (eval(self.store_mundo))  stores.push(1);
					if (eval(self.store_aventura))  stores.push(2);
					if (eval(self.store_lexo))  stores.push(3);	
					// validando se está marcado corretamente a relação loja x categorias
					const stores_categories = await this.$store.dispatch("categorias/getStoresByCategoriesIds", categories);

					for(let i=1; i<=3; i++){
						if(stores_categories.includes(i) && !stores.includes(i)){
							msgsValidacao.push(`Existem categorias da loja ${Filters.store(i)} mas o produto não está habilitado para esta loja`);
						}else if(!stores_categories.includes(i) && stores.includes(i)){
							msgsValidacao.push(`O produto está habilitado para a loja ${Filters.store(i)} mas não tem categorias vinculadas`);
						}
					}
				}

				// tratando os vídeos
				this.model.videos.forEach(item => {
					if(item.url == ""){
						msgsValidacao.push("Preencha corretamente a URL do vídeo.");
					}else if(!item.url.startsWith("http")){
						msgsValidacao.push(`A url do vídeo "${item.url}" está incorreta.`);
					}
				});	

				if(msgsValidacao.length === 0){
					this.save_confirm();
				}else{
					let event_confirm = () => {
						self.save_confirm();
						return true;
					};
					const body = [];
					msgsValidacao.forEach(item => {
						body.push({
							type: "p",
							text: item
						});
					});
					const dados = {
						title: "Deseja salvar o produto mesmo assim?",
						body: body
					};
					Alerts.alertConfirm(dados,event_confirm);
				}
			}
		},
		save_confirm: function(){		
			let self = this;				
						
			let model = this.model;		
			
			// tratando os atributos antes de salvar				
			model.attributes = [];
			let _attributes = deepCopy(this.attributes);
			_attributes.forEach(item => {
				// se não estiver sendo usado como variação e também não estiver vazio
				if(item.as_variation || (item.type == "option" && item.value.id != "" && item.value.id != null) || (item.type != "option" && item.value != "" && item.value != null)){
					if(!item.as_variation){
						if(item.type == "option"){							
							item.value = item.values.find(dado => {
								return dado.id == item.value.id;
							});
						}					
						item = this.deleteFieldsAttributes(item);
						// joga nos atributos do model
						model.attributes.push(item);
					}
				}
			});

			// tratando o campo name da variação e os variations dentro da variação				
			model.variations.forEach(item => {
				item.name = model.title;
				if(item.attributes.length > 0){
					item.name += " - " + item.attributes.map((data) => {
						if(data.type == "option"){
							return data.value.name;
						}
					}).join(" - ");
					// limpa os campos e trata o value do attribute
					item.attributes.forEach( field => {								
						let _value = this.getAttributesValues(field.id).find(it => {
							return field.value.id == it.id;
						});
						field.value.id = _value.id;
						field.value.name = _value.name;
						field.value.order = _value.order;
						self.deleteFieldsAttributes(field);
					});
				}
			});

			// tratamento o campo de store
			model.stores = [];
			if (eval(self.store_mundo))  model.stores.push(1);
			if (eval(self.store_aventura))  model.stores.push(2);
			if (eval(self.store_lexo))  model.stores.push(3);				

			// apaga os campos de valores
			if(model.kit_products.length > 0){
				model.kit_products.forEach(item => {
					delete item.cost;
					delete item.margin;
					delete item.price;
					delete item.stock;
					delete item.show_prices;
				});
			}

			if(model.description){
				model.description = model.description.replace(/<img src="https:\/\/imagens.mundodacarabina.com.br\/editor/g,"<img src=\"/editor");
			}
			
			// salva
			this.$store.dispatch("produtos/save", model).then(() => {
				self.disabled_items = [];
			});
		},
		deleteFieldsAttributes(obj){
			// apaga campos desnecessários
			delete(obj.value.extra);
			delete(obj.value.metadata);
			delete(obj.values);
			delete(obj.required);
			delete(obj.status);
			delete(obj.as_variation);
			delete(obj.metadata);
			return obj;
		},
		toSlug: function(force){
			if(force) {
				this.model.slug = url_prefix.product + Slug(this.model.title);
			} else{
				if(this.model.slug == null){
					this.model.slug = url_prefix.product + Slug(this.model.title);
				}			
				if(this.model.metatags && this.model.metatags.title == null){
					this.model.metatags.title = this.model.title;
				}
			}
			
		},
		checkIt: function(ev){
			let el = ev.currentTarget;			
			let id = el.value;
			let attributes = Object.assign([],this.attributes);
			// marca no objeto se é variation ou não
			attributes.forEach(item => {
				if(item.id == id){
					item.as_variation = el.checked;
					item.value = {
						id: null,
						name: null
					};
					if(!item.as_variation){						
						// limpa das variações o atributo que foi desmarcado
						if(this.model.variations.length > 0){
							this.model.variations.forEach(item => {
								let idx = item.attributes.findIndex(attr => {
									return attr.id === item.id;
								});
								item.attributes.splice(idx,1);
							});
						}
					}
				}				
			});			
			let type = this.model.product_type;
			this.tipos_produtos.forEach(item => {
				if(item.id == type.id){
					item.attributes = attributes;
				}
			});
			// verificando se tem alguma variação já criada
			if(this.model.variations.length > 0){				
				// pega os atributos atualizados
				let attributes = this.pegaAttributesAsVariacao();
				// jogando na variação
				this.model.variations.forEach(item => {
					item.attributes = attributes;
				});
			}
		},
		novaGaleria: function(){
			let self = this;
			if(this.galeria_name == ""){
				Alerts.alertTop("error","Informe um nome para a galeria.");
				return;
			}else{
				this.$store.dispatch("produtos/save_galeria", {id: null, product_id: this.model.id, name: this.galeria_name}).then(() => {
					self.galeria_name = "";
				});
			}
		},
		updateNameGaleria: function(galeria){
			if(galeria.name == ""){
				Alerts.alertTop("error","Informe um nome para a galeria.");
				return;
			}else{
				this.$store.dispatch("produtos/save_galeria", {id: galeria.id, product_id: this.model.id, name: galeria.name}).then( () => {
					Alerts.alertTop("success","Nome da Galeria Atualizado com Sucesso.");
				});
			}
		},
		onFileChange(e,galeria) {			
			let self = this;
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length)
				return;

			// pegando a maior ordem das imagens atuais
			let maior_ordem = 0;
			galeria.pictures.forEach( item => {
				item.order = parseInt(item.order);
				if(item.order >= maior_ordem){
					maior_ordem = item.order + 1;
				}
			});	
			
			for (const file of files) {
				let data = {
					gallery_id: galeria.id,
					order: maior_ordem,
					file : file,
					alt: self.model.title,
					type: "product_picture"
				};

				this.$store.dispatch("produtos/upload_foto_galeria", data);

				maior_ordem++;
			}
		},
		removeGaleria(galeria) {
			let self = this;
			let event_confirm = () => {
				self.$store.dispatch("produtos/galeria_delete", galeria.id).then( response => {
					if(response.return_code == 1){
						if(response.data.ok){
							self.$store.commit("produtos/remove_galeria",galeria);
						}					
					}				
				});
				return true;
			};
			Alerts.alertConfirm("Tem certeza que deseja excluir a galeria "+galeria.name+"?",event_confirm);
		},
		sortable_update: function(obj){
			// pega o id da galeria
			let galeria_id = obj.item.getAttribute("gallery");
			// pega a galeria
			let pictures = this.model.galleries.find(item => {
				return item.id == galeria_id;
			}).pictures;									

			pictures.splice(obj.newIndex, 0, pictures.splice(obj.oldIndex, 1)[0]);

			let picturesOrdem = [];
			let order = 0;
			pictures.forEach(item => {
				item.order = order;
				picturesOrdem.push(item);
				order++;
			});

			this.$store.dispatch("produtos/update_pictures_order", {
				gallery_id: galeria_id,
				pictures: picturesOrdem
			});			
		},
		addVideo: function(){
			// pega a maior ordem
			let maior_ordem = 0;
			let maior_key = 0;
			this.model.videos.forEach((data)=>{
				if(data.order > maior_ordem){
					maior_ordem = data.order;
				}
				if(data.key > maior_key){
					maior_key = data.key;
				}				
			});
			// inclui
			this.model.videos.push({
				url: "",
				order: maior_ordem + 1, 
				key: maior_key + 1,
			});
			this.$store.commit("produtos/update_order_videos",this.model.videos);
			// dá o focus no último input
			this.$nextTick(() => {
				let inputs = document.getElementsByClassName("options")[0].getElementsByTagName("input");
				inputs[inputs.length - 1].focus();
			});			
		},
		deletaVideo: function(index){
			let item = this.model.videos[index];
			if(item.id != undefined){
				// envia para api para ver se pode deletar, caso positivo a api já deleta
				this.$store.dispatch("produtos/deleteVideo",item);
			}else{
				this.model.videos.splice(index,1);
			}
		},
		sortable_update_videos: function(obj){
			let values = this.model.videos;
			values.splice(obj.newIndex, 0, values.splice(obj.oldIndex, 1)[0]);

			let order = 1;
			values.forEach(function(item){
				item.order = order;
				order++;
			});

			this.$store.commit("produtos/update_order_videos",values);
		},
		removeImagem: function(picture){		
			let self = this;
			let event_confirm = () => {
				self.$store.dispatch("produtos/delete_picture", picture);
				return true;
			};
			Alerts.alertConfirm("Tem certeza que deseja excluir a foto?",event_confirm);
		},
		editLegenda: function(e, picture){
			let elem = e.currentTarget;
			elem = elem.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("edit-legenda")[0];
			elem.id = picture.id;
			elem.gallery_id = picture.gallery_id;
			elem.classList.remove("hide");
			let input = elem.getElementsByClassName("legenda_editavel")[0];
			input.value = (picture.alt == undefined) ? "" : picture.alt;
		},
		saveLegenda: function(e){
			let elem = e.currentTarget;			
			if(elem.classList.contains("legenda_editavel")){
				elem = elem.parentNode.parentNode.parentNode.parentNode.parentNode;			
			}else{
				elem = elem.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;			
			}
			let picture_id = elem.id;
			let gallery_id = elem.gallery_id;
			let input = elem.getElementsByClassName("legenda_editavel")[0];
			let value = input.value;
			elem.classList.add("hide");			
			elem.id = "";
			elem.gallery_id = "";

			this.$store.dispatch("produtos/update_picture", {
				id: picture_id,
				alt: value,
				gallery_id: gallery_id
			});

		},
		fechaLegenda: function(e){
			let elem = e.currentTarget;
			elem = elem.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
			elem.classList.add("hide");
			let input = elem.getElementsByClassName("legenda_editavel")[0];
			input.value = "";
			elem.id = "";
			elem.gallery_id = "";
		},
		updateDadosMultiselect: function(objeto,tipo){
			this[tipo].push(objeto);
		},
		pegaAttributesAsVariacao: function(){
			let attributes = JSON.parse(JSON.stringify(this.attributes)); // fiz dessa forma pois com o Assign ele está mantendo os getters e setters do objeto
			let attributes_as_variation = attributes.filter(item => {
				return item.as_variation;
			});
			// zerando os valores de attributes
			attributes_as_variation.forEach(item => {
				item.value = {
					id: null,
					name: null
				};
			});
			return attributes_as_variation;
		},
		novaVariacao: function(selecionado){
			let sku;			
			let self = this;
			let variacao = Object.assign({},this.variation_model);			
			if(selecionado != undefined){								
				sku = selecionado.sku;
			}else{
				// verifica se tem algum selecionado com o teclado
				let lista = document.getElementsByClassName("filtros-selecionar-waiting-register")[0];
				let selected = lista.getElementsByClassName("active");
				if(selected.length > 0){
					sku = selected[0].getAttribute("sku");
				}else{
					// pega o que está sendo digitado mesmo
					sku = this.new_sku;
				}				
			}
			
			let attributes_as_variation = this.pegaAttributesAsVariacao();			

			if(!sku && !this.model.is_kit){
				Alerts.alertTop("error","Informe um SKU para continuar!");
				return;
			}else if(this.model.is_kit && !sku){
				// se for um combo, ele gera o sku automaticamente
				this.$store.dispatch("produtos/geraSkuCombo").then(response => {
					self.new_sku = response;
					self.novaVariacao();
				});
				return;
			}

			//  se já tiver uma variação e não foi selecionado nenhum atributo para variar, ele não permite
			if(this.model.variations.length >= 1 && attributes_as_variation.length == 0){
				Alerts.alertTop("error","Para criar mais de uma variação por produto, selecione ao menos um atributo: \"Usar como Variação?\".");
				return;
			}

			// verificando se já tem algum produto cadastrado com esse sku
			if(this.model.variations.find(item => { return item.sku == sku; }) == undefined){
				this.$store.dispatch("produtos/check_sku", sku).then( response => {
					// não existe
					if(!response){						
						let maior_ordem = 0;

						// se já tiver alguma variação, usa a primeira como base para copiar alguns dados já cadastrados						
						if(self.model.variations.length > 0){							
							// pega a maior ordem
							self.model.variations.forEach((data)=>{
								data.order = parseInt(data.order);
								if(data.order >= maior_ordem){
									maior_ordem = data.order + 1;
								}
							});

							// pega a primeira
							let first_variation = self.model.variations[0];

							// clonando algumas informações
							variacao.discount_joint_sale = first_variation.discount_joint_sale;
							variacao.combo_rules = first_variation.combo_rules;
							variacao.shipment_weight = first_variation.shipment_weight;
							variacao.dimension.height = first_variation.dimension.height;
							variacao.dimension.width = first_variation.dimension.width;
							variacao.dimension.length = first_variation.dimension.length;
							variacao.gallery_id = first_variation.gallery_id;
							variacao.stock_min = first_variation.stock_min;
							variacao.extra_time = first_variation.extra_time;
						}

						// verificando se é um sku novo ou sku importado do ERP
						let importado = self.waiting_register.find( item => {
							return item.sku == sku;
						});
						if(importado != undefined){
							variacao.invoice_description = importado.name;
							variacao.supplier_code = importado.supplier_code;
						}else{
							variacao.invoice_description = "";
							variacao.supplier_code = "";
						}
						
						// atualizando as informações desta variação
						variacao.product_id = self.model.id;
						variacao.sku = sku;
						variacao.attributes = attributes_as_variation;
						variacao.name = "";
						variacao.order = maior_ordem;
						variacao.prices = {
							mundo: {},
							lexo: {},
							aventura: {},
							marketplace: {}
						};

						// se for um kit
						if(self.model.is_kit){
							self.$store.dispatch("produtos/pegaMedidasCombo", self.model.kit_products).then( response => {
								variacao.shipment_weight = response.shipment_weight;
								variacao.dimension.height = response.dimension.height;
								variacao.dimension.width = response.dimension.width;
								variacao.dimension.length = response.dimension.length;
								variacao.extra_time = response.extra_time;
								variacao.invoice_description = self.model.title;
								variacao.supplier_code = "combo";
								self.$store.commit("produtos/novaVariacao", variacao);
								self.new_sku = "";
							});
						}else{
							self.$store.commit("produtos/novaVariacao", variacao);
							self.new_sku = "";

							// pegando as informações completas do produto no viasoft
							if(!this.$route.params.sku || sku !== this.$route.params.sku){
								const waiting_selected = this.waiting_register.find(res => res.sku === sku);
								if(waiting_selected !== undefined){
									this.getViasoftData(waiting_selected.id);
								}								
							}
						}		
						
					}else{
						Alerts.alertTop("error","Já existe um produto com esse SKU");		
					}
				});
			}else{
				Alerts.alertTop("error","Já existe uma variação com esse SKU neste produto");
				return;
			}			
		},
		async getViasoftData(id) {
			const product = await this.$store.dispatch("waiting/get_full_product", id);
			const variation_index = this.model.variations.findIndex(item => item.sku === product.sku);
			if(variation_index >= 0){
				this.model.variations[variation_index].cost = product.cost;
				this.model.variations[variation_index].ean_code = product.ean_code;
				this.model.variations[variation_index].invoice_description = product.title;
				this.model.variations[variation_index].shipment_weight = product.weight;
				this.model.variations[variation_index].stock = product.stock;
				this.model.variations[variation_index].supplier_code = product.supplier_code;
				this.model.variations[variation_index].weight = product.weight;
			}
		},
		validaExclusao: function(tipo){
			let valido = true;
			// valida se pode remover o tipo de produto selecionado			
			if(tipo == "product_type"){
				// tem alguma variação utilizando atributos?				
				this.model.variations.forEach(item => {
					if(item.attributes.length > 0){
						valido = false;
					}
				});
				if(!valido){
					Alerts.alertTop("error","Não é possível remover o tipo de produto pois existem variações utilizando os atributos.");
				}
			}

			return valido;
		},
		removeVariacao: function(variacao){
			let self = this;
			let event_confirm = () => {
				if (variacao.id != null) {					
					self.$store.dispatch("produtos/variation_delete", {id: variacao.id}).then(response => {
						if(response.body.return_code == 1 && response.body.data.ok) {
							self.$store.commit("produtos/removeVariacao",variacao);							
						}else{
							Alerts.alertTop("error","Houve um problema ao remover a variação, atualize a página e tente novamente.");
						}
					});					
				}else{					
					self.$store.commit("produtos/removeVariacao",variacao);
				}
				return true;
			};
			Alerts.alertConfirm("Tem certeza que deseja excluir a variação? Esta ação é irreversível.",event_confirm);
		},
		waitingRegisterFiltered: function(){
			let self = this;			
			let dados = Object.assign([], this.waiting_register);	
			// tira os já selecionados		
			dados = dados.filter(item => {																				
				return self.model.variations.find(dado => { return item.sku == dado.sku; }) == undefined;
			});			
			// faz o filtro
			if(this.new_sku != ""){
				// faz o filtro
				let new_data = [];
				dados.forEach(function(item){
					if(self.filterJsonProduct(item.sku) || self.filterJsonProduct(item.name) || self.filterJsonProduct(item.supplier_code)){
						new_data.push(Object.assign({}, item));
					}
				});		
				dados = new_data;
			}
			
			return dados;
		},
		filterJsonProduct: function(item){
			return item.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(this.new_sku.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
		},
		navigateActive: function(direction){

			this.$nextTick(function () {
				let lista = document.getElementsByClassName("filtros-selecionar-waiting-register")[0].children;
				let indice_maximo = lista.length-1;
				let indice_lista = 0;
				if(direction == "up"){
					for(let li=indice_maximo;li>=0;li--){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li > 0){
								indice_lista = li-1;	
							}
							break;
						}
					}
				}else{
					for(let li=0;li<=indice_maximo;li++){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li < indice_maximo){
								indice_lista = li+1;	
							}						
							break;
						}
					}
				}
				
				// aplica a classe
				lista[indice_lista].classList.add("active");				
			});			
		},
		toogleVariation: function(ev){
			let elem = ev.currentTarget;
			let icone = elem.getElementsByClassName("far")[0];
			let body = elem.parentNode.parentNode.getElementsByClassName("card-body")[0];
			if(body.classList.contains("hide")){
				body.classList.remove("hide");
				icone.classList.remove("fa-plus-square");
				icone.classList.add("fa-minus-square");
			}else{
				body.classList.add("hide");
				icone.classList.remove("fa-minus-square");
				icone.classList.add("fa-plus-square");
			}
		},
		sortable_update_variation: function(obj){
			let self = this;

			let variacoes = this.model.variations;
			variacoes.splice(obj.newIndex, 0, variacoes.splice(obj.oldIndex, 1)[0]);

			let order = 0;
			let variationOrder = [];
			variacoes.forEach(item => {
				item.order = order;
				variationOrder.push({
					id: item.id,
					order: order
				});
				order++;
			});

			this.$store.dispatch("produtos/update_variations_order", variationOrder).then(response => {
				if(response.return_code == 1){
					self.$store.commit("produtos/updateVariacoes",variacoes);
				}
			});			
		},		
		getAttributesValues: function(id){			
			if(this.attributes){
				let attributes = this.attributes.find(attr => {
					return attr.id == id;
				});
				if(attributes.values){
					return attributes.values.map(it => { return { id: it.id, name: it.name, order: it.order }; });				
				}			
			}
			return [];
		},
		getAttributeName: function(attribute) {
			if(attribute.value.name != null){
				return attribute.value.name;
			}
			const value = attribute.values.find(item => {
				return item.id === attribute.value.id;
			});
			if(value){
				return value.name;
			}
			return "";			
		},
		previewAction: function(){
			if(!this.model.preview){
				this.$store.dispatch("produtos/set_preview", {
					id: this.model.id,
					preview: true,
				});
			}else{
				this.$store.dispatch("produtos/set_preview", {
					id: this.model.id,
					preview: false,
				});
			}
		},
		copySku: function(sku){
			let input = document.createElement("input");
			document.getElementsByTagName("body")[0].append(input);
			input.value = sku;
			input.select();
			document.execCommand("copy");
			input.remove();

			Alerts.alertTop("success", "SKU copiado com sucesso");
		},
		finish(type) {
			if(type == "content"){
				this.model.content_validation = true;
			}else if(type == "back-content"){
				this.model.content_validation = false;
			}else{
				this.model.sale_validation = true;
			}
			
		}, 
		forceUpdateImageMarketplace(){
			const product_id = this.model.id;
			const self = this;
			let event_confirm = () => {
				self.$store.dispatch("produtos/sync_image_marketplace", product_id);
				return true;
			};
			const dados = {
				title: "Deseja forçar a sincronização das imagens com o Marketplace?",
				body: []
			};
			Alerts.alertConfirm(dados,event_confirm);
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			checkIt: this.checkIt,
			updateDadosMultiselect: this.updateDadosMultiselect,
			validaExclusao: this.validaExclusao,
			previewAction: this.previewAction,
			finish: this.finish
		};
	},
	filters: Filters,
	mounted() {
		
	},
	watch: {
		"model.slug": function(slug){
			let self = this;
			if(slug === ""){
				let event_confirm = () => {
					self.toSlug(true);	
					return true;
				};
				Alerts.alertConfirm("Gostaria de gerar uma nova URL?",event_confirm);
				
			}
		},
		"model.variations": function(variations) {
			// utilizado para cadastro utilizando o módulo de produtos aguardando cadastro
			const waiting_params = this.$route.params;
			if(variations.length === 1 && waiting_params.title){
				this.model.variations[0].invoice_description = waiting_params.title;
				this.model.variations[0].supplier_code = waiting_params.supplier_code;
				this.model.variations[0].weight = waiting_params.weight;
				this.model.variations[0].ean_code = waiting_params.ean_code;
				this.model.variations[0].cost = waiting_params.cost;
				this.model.variations[0].stock = waiting_params.stock;	
			}
		},
		"model.sale_validation": function(dados) {
			this.header_info.links.sale_validation = !dados;
		},
		"model.content_validation": function(dados) {
			this.header_info.links.content_validation = !dados;
		}
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";

	.input_galeria_name{
		background: none;
		border: none;
		width: 60%;
		color:#FFF;
	}
	.inputFileSimple {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.inputFileSimple+label.fileSimple {
		color: #FFF;
	}

	.inputFileSimple+label.fileSimple:after {
		content: '';
	}
	.picture{
		text-align: center;	
		label{
			display: block;
			text-align: center;
		}
	}
	.edit-legenda{
		.legenda-background{
			background: #444444;
			opacity: 0.7;
			min-width: 100%;
			min-height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
		}
		.row{
			min-width: 100%;
			min-height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 6;
			label, small{
				color: #FFF!important;
			}
		}		
	}
	.card{
		.card-header{
			.delete-variation{
				top:0.65em;
				right: 0.65em;
				font-size: 0.7em;
			}
			.max-mini-variation{
				top:0.55em;
				left: 0.75em;
				font-size: 1.3em;
				cursor: pointer;
			}
			&.header-error{
				background: $color_red;
				color:#FFF;
			}
		}
	}
	.filtros-selecionar-waiting-register{
		.list-group-item{
			cursor: pointer;
			&:hover,&.active{
				background: $bg_destaque;
				border-color: $bg_destaque;
				color:#FFF;
			}
		}
	}
</style>
