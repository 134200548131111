import api from "./../../common/api";
import model from "./../models/reports-marketplace";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {		
		state.commit("data_filtros_loaded", config.reports_marketplace);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state) => { 		
		state.commit("clear_valores");
		return state.dispatch("lista_filter_update", state.state.search_data);
	},
	lista_filter_update: function(state, data) {                
		let elem_match = [];
		let new_filters = data.filtros.filter( filtros => {
			switch(filtros.field){
			case "match_updated_at":
				elem_match.push({
					field: filtros.field.replace("match_",""),
					operator: filtros.operator,
					value: filtros.value,
					type: "datetime"
				});
				return false;
			case "match_status_type":
				elem_match.push({
					field: filtros.field.replace("match_",""),
					operator: filtros.operator,
					value: filtros.value
				});
				return false;
			default:
				return true;
			}
		});

		if(elem_match.length > 0){
			new_filters.push({
				field: "status_log",
				operator: "elem_match",
				value: elem_match
			});
		}		

		new_filters.push({
			field: "marketplace.marketplace_id",
			operator: "exists",
			value: true
		});
                
		// listagem normal
		let fields = [];
		config.reports_marketplace.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});
		state.commit("data_resume_loaded", {});
		api.request("reports_marketplace/data_valores_loaded", "admin/orders", "post", { fields:fields, "filters": new_filters, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
        
	},
	get_dashboard: function(state, data) {
		state.state.initial_date = data.initial_date;
		state.state.final_date = data.final_date;

		const filters = [
			{
				field: "created_at",
				operator: ">=",
				type: "date",
				value: data.initial_date
			},
			{
				field: "created_at",
				operator: "<=",
				type: "date",
				value: data.final_date
			},
			{
				field: "marketplace.marketplace_id",
				operator: "exists",
				value: true
			}
		];
		// pegando os fields que é para trazer
		const fields = [
			"total_order",
			"marketplace",
			"created_at"
		];
		api.request("reports_marketplace/data_resume_loaded", "admin/orders", "post", { fields: fields, "filters": filters, "sort": "created_at,desc", "limit": 99999999, "page": 1 });
	},
	load_status_data: function() {		
		return api.request("reports_marketplace/data_status_loaded", "admin/order/statuses", "post", { "filters": [{field:"status",operator: "=",value: 1}], "sort": "name,asc", "limit": 0, "page": 0 });
	},	
	load_motivos_data: function() {
		return api.request("reports_marketplace/no_action", "admin/order/cancel/reasons", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	load_vendedores_data: function() {
		return api.request("reports_marketplace/no_action", "admin/users", "post", { "filters": [{field:"seller",operator: "=",value: true}], "sort": "name,asc", "limit": 10, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
        
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("reports_marketplace/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("reports_marketplace/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("reports_marketplace/delete_filter", "admin/filter/"+id, "delete", id);
	},	
};