import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/raffle";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		return api.request("raffle/loaded", "admin/raffle/" + id, "get").then(response => {
			return response.body;
		});
	},
	save: function(context, data) {
		api.request("raffle/loaded", "admin/raffle", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Sorteio cadastrado com sucesso." : "Sorteio atualizado com sucesso.");
				router.push({
					name: "raffle.ver",
					params: { id: data.id }
				});
			}
		});
	},
	update_ordem: function(state, data) {
		return api.request("raffle/no_action", "admin/raffle/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.raffle);
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => {
		let fields = [];
		config.raffle.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		state.commit("clear_valores");
		api.request("raffle/data_valores_loaded", "admin/raffles", "post", { fields: fields, "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);
	},
	// altera o limit da listagem
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);
	},
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {
		let data = {};
		data[dados.field] = dados.value;
		return api.request("raffle/no_action", "admin/raffle", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("raffle/acao_massa", "admin/raffle", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{
		return api.request("raffle/acao_massa", "admin/raffle", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez
	excluir_registro: (state,id) => {
		return api.request("raffle/no_action", "admin/raffle/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("raffle/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}
		return api.request("raffle/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("raffle/delete_filter", "admin/filter/"+id, "delete", id);
	},
	load_tipos: function() {
		return api.request("raffle/no_action", "admin/raffle/types", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	load_categorias(){
		return api.request("raffle/load_categorias", "admin/categories", "get");
	},
	load_marcas(){
		return api.request("raffle/no_action", "admin/brands", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	load_paginas_especiais(){
		return api.request("raffle/no_action", "admin/special/pages", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	sortear(state, data){
		const {orders, store_id} = data;
		const filters = [];
		// loja
		filters.push({
			field:"store_id",
			operator:"=",
			value: store_id
		});
		// ignorando orders já selecionados
		if(orders.length > 0){
			filters.push({
				field:"order_number",
				operator:"not_in",
				value: orders
			});
		}
		return api.request("raffle/no_action", "admin/raffle/numbers", "post", { "filters": filters, "sort": "name,asc", "limit": 1, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},
	aplicaSorteio(state, raffle) {
		api.request("raffle/loaded", "admin/raffle", "put", raffle);
	}
};
