<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="card card-base">
					<div class="card-header">
						Informações Gerais do Status
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Tipo de Status" info="" :options="status_types" v-model="model.type" type="string" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Avançar para próxima etapa?" info="" :options="[{id: 'manual',label: 'Manual'}, {id: 'automatic', label: 'Automático'}]" v-model="model.action" type="string" />		
							</div>							
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Exibir ao cliente" info="" :options="[{id: 'yes',label: 'Sim'}, {id: 'no', label: 'Não'}]" v-model="model.display" type="string" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Precisa estar pago?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.need_paid" type="boolean" />		
							</div>							
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Template de E-mail Mundo" info="Só selecione um template se desejar enviar um e-mail ao cliente" :options="templates.mundo" v-model="model.templates.mundo" type="string" />		
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Template de E-mail Aventura" info="Só selecione um template se desejar enviar um e-mail ao cliente" :options="templates.aventura" v-model="model.templates.aventura" type="string" />		
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Template de E-mail Lexo" info="Só selecione um template se desejar enviar um e-mail ao cliente" :options="templates.lexo" v-model="model.templates.lexo" type="string" />		
							</div>
							<div class="col-12">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.pedidos_status.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Status de Pedido",
				chamada: "Gerencie os status de pedidos da sua loja",
				links: {
					back: "pedidos.status.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Status de Pedido",
					link: "/pedidos/status"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[],
			disabled_items: [],
			hidden_items: [],
			templates: [],
			status_types: [
				{
					id: "custom",
					label: "Personalizado"
				}, 
				{
					id: "new", 
					label: "Pedido Novo"
				}, 
				{
					id: "processing",
					label: "Pagamento Processado"
				}, 
				{
					id: "payment-problem", 
					label: "Pagamento com Problema"
				}, 
				{
					id: "paid", 
					label: "Pagamento confirmado"
				}, 
				{
					id: "in-separation", 
					label: "Em separação"
				}, 
				{
					id: "sent", 
					label: "Enviado"
				}, 
				{
					id: "delivered", 
					label: "Entregue"
				}, 
				{
					id: "canceled", 
					label: "Cancelado"
				}, 
				{
					id: "problem", 
					label: "Pedido com Problema"
				}, 
				{
					id: "review", 
					label: "Em Análise"
				},
				{
					id: "awaiting-withdrawal", 
					label: "Aguardando Retirada"
				}
			]
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				// salva
				this.$store.dispatch("pedidos_status/save", this.model);
			}
		}		
	},
	provide: function () {
		return {
			save: this.save,
		};
	},
	created() {
		let self = this; 
		this.$store.dispatch("pedidos_status/reset");
		let startingPromises = [
			this.$store.dispatch("pedidos_status/load_templates").then(response => {
				let dados = {
					mundo: [],
					aventura: [],
					lexo: []
				};			
				response.forEach(template => {
					switch(template.store_id){
					case 1:
						dados.mundo.push({
							label: template.subject,
							id: template.id
						});
						break;
					case 2:
						dados.aventura.push({
							label: template.subject,
							id: template.id
						});
						break;
					case 3:
						dados.lexo.push({
							label: template.subject,
							id: template.id
						});
						break;
					}
				});
				self.templates = dados;
			})
		];

		Promise.all(startingPromises).then(() => {
			if(self.$route.params.id != undefined && self.$route.params.id != "") {
				self.$store.dispatch("pedidos_status/load", self.$route.params.id);
			}
		});
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	
</style>
