export default {
	init: function() {
		return {
			model: {
				id: null,
				name: null,
				description: null,
				store_id: 0,
				slug: "",
				status: 1,
				canonical: null,
				filters: {
					in: [],
					notin: []
				},
				metatags: {
					title: null,
					description: null,
					keywords: null
				},
				// campos para o MultiSelect
				filters_in: [],
				filters_notin: []
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "name",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: []
		};
	}
};
