import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/pedidos";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		return api.request("pedidos/loaded", "admin/order/" + id, "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return {};
			}
			
		});
	},
	save: function(context, data) {
		api.request("pedidos/loaded", "admin/order", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Pedido cadastrado com sucesso." : "Pedido atualizado com sucesso.");
				router.push({
					name: "pedidos.lista"
				});
			}
		});
	},
	update_ordem: function(state, data) {
		return api.request("pedidos/no_action", "admin/orders/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.pedidos);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 		
		let fields = [];
		config.pedidos.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});
		
		// tratando o filtro de nota fiscal para ser valor exato
		data.filtros.forEach(item => {
			if(item.field == "invoices.number"){
				item.operator = "=";
			}
		});
		state.commit("clear_valores");
		api.request("pedidos/data_valores_loaded", "admin/orders", "post", { fields: fields, "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {				
		let data = {};
		data[dados.field] = dados.value;
		return api.request("pedidos/no_action", "admin/orders", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("pedidos/acao_massa", "admin/orders", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{		
		return api.request("pedidos/acao_massa", "admin/orders", "delete", { ids: dados.id });
	},
	imprimir_massa: (state,dados) =>{		
		let pedidos_gateway = [];
		dados.id.forEach(item => {
			pedidos_gateway.push(
				{
					type: "GET",
					name: "/admin/order/"+item,
					body: {}
				}
			);
		});

		return api.request("pedidos/no_action", "", "post", pedidos_gateway, null, true).then((response) => {
			// validando se algum dos clientes tem mais de 1 pedido para ser enviado
			state.dispatch("valida_pedidos_agrupados_por_cliente", response).then(res => {
				router.push({
					path: "/pedidos/print"
				});
				return res;
			});			
		});
	},	
	valida_pedidos_agrupados_por_cliente: (state, response) => {
		let pedidos = response.body.return_code === 1 ? response.body : [];
		if (pedidos.data.length > 0){
			const customer_ids = [];

			pedidos.data.forEach(order => {
				if(order.data.customer_id){
					customer_ids.push(order.data.customer_id);
				}
			});			

			const filtros = [];
			if(customer_ids.length > 0){
				filtros.push({
					field: "customer._id",
					operator: "in",
					value: customer_ids
				});
				filtros.push({
					field: "status.type",
					operator: "in",
					value: ["paid","in-separation"]
				});
				filtros.push({
					field: "read_to_ship",
					operator: "=",
					value: true
				});
			}

			return api.request("pedidos/no_action", "admin/orders", "post", { fields: ["id", "customer_id", "order_number"], "filters": filtros, "sort": "created_at,desc", "limit": 9999999, "page": 1 }).then( res => {

				if(res.body.return_code == 1){
					const all_orders = res.body.data.items;
					pedidos.data.forEach(it => {
						if(it.data.store_id){
							const total_pedidos = all_orders.filter(or => { return or.customer_id == it.data.customer_id && or.order_number != it.data.order_number; });
							if(total_pedidos.length > 0 ){
								it.data.otherOrders = total_pedidos.length;
								it.data.otherOrdersList = total_pedidos;
							}else{
								it.data.otherOrders = 0;
								it.data.otherOrdersList = [];
							}
						}
					});
				}

				state.commit("pedidos_imprimir", pedidos);

				return pedidos;
			});
		}else{
			return [];
		}
		
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("pedidos/no_action", "admin/order/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("pedidos/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("pedidos/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("pedidos/delete_filter", "admin/filter/"+id, "delete", id);
	},
	// novo pedido
	create_cart: function(state,data){
		return api.request("pedidos/no_action", "cart", "post", {source: "admin"}, data.store_id).then((response) => {            
			//tratar a resposta
			if (response.body.return_code == 1) {                
				router.push({
					path: "/pedidos/create/"+response.body.data.id
				});
			}
			return response;
		});
	},
	load_cart: function(state, data){
		return api.request("pedidos/atualizaCart", "cart/"+data.id, "get");
	},
	addProduto: function(context,data){
		api.request("pedidos/atualizaCart", "cart/product", "post", data);
	},
	removeProduto: function(context,data){
		api.request("pedidos/atualizaCart", "cart/product", "delete", data);
	},
	update_quantidade: function(context,data){
		api.request("pedidos/atualizaCart", "cart/product", "put", data);
	},
	remove_desconto: function(context,data){
		api.request("pedidos/atualizaCart", "cart/product/discount", "delete",data);
	},
	aplica_desconto: function(context,data){
		api.request("pedidos/atualizaCart", "cart/product/discount", "post",data);
	},
	addCliente: function(context,data){
		return api.request("pedidos/no_action", "cart/customer", "post", data).then(response => {
			if(response.body.return_code == 1){                
				if(response.body.data.ok == true){
					let cart = context.state.cart;
					cart.customer_id = data.customer_id;
					context.commit("atualizaCart",cart);
				}
			}
			return response;
		});
	},   
	calculaFrete: function(context,data){
		return api.request("pedidos/atualizaCart", "cart/shipment/calculate", "post", data);
	},
	busca_endereco: function(context,data){
		return api.request("pedidos/no_action", "admin/address", "post",data);
	},
	pega_cidades: function(context, data){
		api.request("pedidos/cidades", "address/cities/"+data, "get");
	},
	selecionaFrete: function(context,data){
		api.request("pedidos/atualizaCart", "cart/shipment", "post",data);
	},
	installments: function(context,data){
		return api.request("pedidos/no_action", "payments/installments", "post",data);
	},
	gravaPedido: function(context,data){
		api.request("pedidos/no_action", "order", "post", data.pedido, data.store_id).then(response => {
			if(response.body.return_code == 1){                    
				if(response.body.data.errors){
					if(response.body.data.errors.error_message){
						alerts.alertTop("error","Não foi possível finalizar o pedido<br />"+response.body.data.errors.error_message);
					}else{
						var errors = response.body.data.errors;
						let msg = "";
						for (var key in errors) {
							msg += errors[key] + "<br />";							
						}
						alerts.alertTop("error","Não foi possível finalizar o pedido<br />"+msg);
					}                        
				}else{                   
					// redireciona para a página do pedido
					router.push({
						path: "/pedidos/view/"+response.body.data.id
					});
				}                    
			}else{
				let errors = response.body.data.errors;
				let msg = "";
				for (let key in errors) {
					msg += errors[key] + "<br />";					
				}
				alerts.alertTop("error","Não foi possível finalizar o pedido<br />"+msg);
			}
		});
	},
	load_status_data: function() {
		return api.request("pedidos/data_status_loaded", "admin/order/statuses", "post", { "filters": [{field:"status",operator: "=",value: 1}], "sort": "name,asc", "limit": 0, "page": 0 });
	},	
	load_motivos_data: function() {
		return api.request("pedidos/data_motivos_loaded", "admin/order/cancel/reasons", "get");
	},
	aprovarPagamento: function(context,data){
		api.request("pedidos/no_action", "admin/order/payment/status", "put", data).then(response => {
			if(response.body.return_code == 1){
				alerts.alertTop("success","Pagamento Atualizado com Sucesso");
				context.dispatch("load", response.body.data.id);
			}
		});
	},
	aprovarRecebimento: function(context, data){
		return api.request("pedidos/no_action", "admin/order/payment/withdrawal", "put", data).then(response => {
			return response.body;
		});
	},
	atualizarBoleto: function(context, data){        
		api.request("pedidos/no_action", "order/payment", "put", {payment_id: data.id}).then(response => {
			if(response.body.return_code == 1){
				alerts.alertTop("success","Pagamento Atualizado com Sucesso");
				context.dispatch("load", response.body.data.id);
			}
		});
	},
	atualizaEndereco: function(context,data){
		return api.request("pedidos/no_action", "admin/order/address", "put", data).then(response => {
			if(response.body.return_code == 1){
				alerts.alertTop("success","Endereço Atualizado com Sucesso");
			}
			return response.body;
		});
	},
	atualizaInformacao: function(context,data){
		return api.request("pedidos/no_action", "order", "put", data).then(response => {
			return response.body;
		});
	},	
	atualizaPedido: function(context,data){
		return api.request("pedidos/no_action", "order", "put", data).then(response => {
			return response.body;
		});
	},	
	update_status_order: function(context, data){		
		try {
			api.request("pedidos/no_action", "admin/order/status/update", "put", data).then(function(response){
				if(response != undefined){					
					if (response.body.return_code == 1) {
						if(response.body.data.ok){							
							alerts.alertTop("success", "Status atualizado com sucesso.");
							context.dispatch("load", data.order_id);					 
						}
					}
				}else{
					alerts.alertTop("error", "Houve um problema ao alterar o status.");
				}
			});
		} catch(e) {			
			alerts.alertTop("error", "Houve um problema ao alterar o status.");
		}
		
	},
	update_status_shipment: function(context, data){		
		try {
			return api.request("pedidos/no_action", "admin/order/status/update", "put", data).then(function(response){
				if(response != undefined){					
					if (response.body.return_code == 1) {
						return response.body.data;
					}else{
						return null;
					}
				}else{
					return null;
				}
			});
		} catch(e) {			
			return null;
		}
		
	},
	search_multiselect: function(state,dados){
		let search = dados.text.trim();
		let filters_default = [];
		if(dados.store_id){
			filters_default.push(
				{
					field: "stores", 
					operator: "in", 
					value: [dados.store_id]
				}
			);
		}		
			
		let filters = {};
		// busca somente titulo
		filters_default.push(
			{
				field: "order_number", 
				operator: "like", 
				type: "string", 
				value: search
			}
		);
		// monta o filtro
		filters = filters_default;
		return api.request("pedidos/no_action", "admin/orders", "post", { "filters": filters, "sort": "created_at,desc", "limit": 10, "page": 1 }).then(response => {
			response = response.body;
			let _dados = [];
			if(response.return_code == 1){
				response.data.items.forEach(item => {
					_dados.push({ id: item.id, name: item.order_number });
				});
			}
			return _dados;
		});
	},
	informacoesPagamento: function(state,dados){
		let {store_id, sa} = dados;		
		return api.request("pedidos/no_action", "admin/payments/info/" + sa, "get", null, store_id).then(response => {			
			return response.body;
		});
	},	
	load_pedidos_by_customer: (state,data) => { 
		const filters = [
			{ 
				field: "customer_id", 
				value: data,
				operator: "=",
			}
		];
		const fields = [
			"id",
			"order_number", 
			"store_id", 
			"created_at", 
			"total_order", 
			"status.name"
		];

		return api.request("pedidos/no_action", "admin/orders", "post", { "fields": fields, "filters": filters, "sort": "created_at,desc", "limit": 999999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},
	getOrdersByCustomer: function(state,customer_id){		
		let filter = [
			{
				"field": "customer_id",
				"value": customer_id,
				"operator": "="
			}
		];
		return api.request("pedidos/no_action", "admin/orders", "post", { "fields": ["id", "order_number"], "filters": filter, "sort": "created_at,desc", "limit": 999999, "page": 1 }).then(response => {
			response = response.body;
			let _dados = [];
			if(response.return_code == 1){
				response.data.items.forEach(item => {
					_dados.push({ id: item.id, name: item.order_number.toString() });
				});
			}
			return _dados;
		});
	},
	async update_status_pedidos_massa(state, dados){	
		const user_id = state.rootState.app.usuario.id;
		const orders = (dados.orders) ? dados.orders : dados.id; 
		const status_new = (dados.status_id) ? dados.status_id : dados.status_update_data.novo_status;
		const motivo_cancelamento = (dados.status_update_data) ? dados.status_update_data.motivo_cancelamento : null;
		const extra_data = [];
		if(motivo_cancelamento != null){
			extra_data.push({
				name: "cancel_reason",
				value: motivo_cancelamento
			});
		}
		let pedidos_gateway = [];
		orders.forEach(item => {
			pedidos_gateway.push(
				{
					type: "PUT",
					name: "/admin/order/status/update",
					body: {
						order_id: item,
						status_id: status_new,
						user_id: user_id,
						data: extra_data
					}
				}
			);
		});

		let errors = [];
		for (const order of orders) {
			const data = {
				order_id: order,
				status_id: status_new,
				user_id: user_id,
				data: extra_data
			};
			const retorno = await api.request("pedidos/no_action", "admin/order/status/update", "put", data).then((response) => {
				return response.body;
			});
			
			if(retorno.return_code == 0){
				// erro
				let pedidos = state.state.valores.items || [];
				let _pedido;
				if(pedidos.length > 0){
					_pedido = pedidos.find(pedido => {
						return pedido.id === order;
					});
				}
				let error_msg;
				if(retorno.data.errors){
					error_msg = (retorno.data.errors.error_message) ? retorno.data.errors.error_message : retorno.data.errors[0].error_message;
				}else if(retorno.data.message){
					const order_number = (_pedido.order_number) ? _pedido.order_number : order;
					error_msg = `Pedido ${order_number}: ${retorno.data.message}`;
				}else{
					const order_number = (_pedido.order_number) ? _pedido.order_number : order;
					error_msg = `O status do Pedido ${order_number} não foi atualizado.`;
				}
				
				errors.push({
					msg: error_msg,
					order_number: (_pedido.order_number) ? _pedido.order_number : null
				});	
			}
		}

		if(errors.length > 0){
			let event_confirm = () => {				
				return true;
			};
			alerts.alertConfirm({
				title: "Alteração de Status de Pedidos em Massa",
				body: errors.map(item => {
					return {
						type: "p",
						text: item.msg
					};
				}),
				close: true
			}, event_confirm);
		}else{
			alerts.alertTop("success", "Pedidos atualizados com sucesso.");
		}
		
		return errors;
	},	
	load_notes: function(state, order_id){
		return api.request("pedidos/notes_loaded", "admin/notes/order/" + order_id, "get");
	},
	add_note: function(state, dados){
		const { note, id } = dados;
		return api.request("pedidos/notes_created", "admin/note", "post", {
			type: "order",
			document_id: id,
			user_id: state.rootState.app.usuario.id,
			message: note
		});
	},
	emails_enviados: function(state, id){		
		return api.request("pedidos/no_action", "admin/emails", "post", {
			fields: ["id","subject","sended","created_at","sended_at"],
			filters: [
				{
					"field": "document_type",
					"value": ["order","documents"],
					"operator": "in"
				},
				{
					"field": "document_id",
					"value": id,
					"operator": "=",
					"type": "string"
				}
			],			
			limit: 999,
			page: 1,
			order: "created_at, asc"
		}).then(response => {
			return response.body;
		});
	},
	reenviar_email: function(state,id){
		return api.request("pedidos/no_action", "admin/email", "post", {			
			email_id: id
		}).then(response => {
			return response.body;
		});
	},
	async get_plp(state, id) {
		const response = await api.request("pedidos/no_action", "admin/printtag/pdf/" + id, "get").then((response) => {
			return response.body;
		});		 
		if (response.return_code === 1 && response.data.ok && response.data.file) {
			return response.data.file;
		} else {
			return null;
		}
	},
	order_shipment: function(state,id){
		if(typeof id == "string"){
			id = parseInt(id);
		}		
		return api.request("pedidos/no_action", "admin/order/shipment/"+id, "get").then(response => {
			response = response.body;
			if(response.return_code == 1){
				return response.data;
			}else{
				return null;
			}
			
		});
	},
	sync_erp: function(state, id){
		return api.request("pedidos/no_action", "admin/order/queue", "post", {
			worker: "viasoft",
			type: "update",
			order_id: id
		}).then(response => {
			return response.body;
		});
	},
	sync_anymarket: function(state, {id, type}){
		// type: to ou from
		return api.request("pedidos/no_action", "admin/order/queue", "post", {
			worker: "anymarket",
			type: type,
			order_id: id
		}).then(response => {
			return response.body;
		});
	},
	sync_fraud: function(state,id){
		return api.request("pedidos/no_action", "admin/konduto/"+id, "get").then(response => {
			return response.body;
		});
	},
	load_orders_fraud: (state,data) => { 
		const filters = [
			{ 
				field: "order_number", 
				value: data,
				operator: "in",
			}
		];
		const fields = [
			"id",
			"order_number", 
			"store_id", 
			"created_at", 
			"total_order", 
			"status.name"
		];

		return api.request("pedidos/no_action", "admin/orders", "post", { "fields": fields, "filters": filters, "sort": "created_at,desc", "limit": 999999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},
	load_orders_by_data: (state,data) => { 
		const filters = [
			{ 
				field: "customer_id", 
				value: data.customer_id,
				operator: "=",
			},
			{ 
				field: "created_at", 
				value: data.created_at,
				operator: ">=",
				type: "datetime"
			},
			{ 
				field: "order_number", 
				value: data.ignore,
				operator: "!=",
			},			
		];
		const fields = [
			"id",
			"order_number", 
			"store_id", 
			"created_at", 
			"total_order", 
			"status.name"
		];

		return api.request("pedidos/no_action", "admin/orders", "post", { "fields": fields, "filters": filters, "sort": "created_at,desc", "limit": 999999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},	
	shipment_monitoring() {
		api.request("pedidos/shipment_monitoring", "admin/reports/expedition", "get");
	},
	get_report_marketplaces(state, data) {
		return api.request("pedidos/no_action", "admin/reports/orders/marketplaces/products", "post", data).then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	get_report_products_sales(state, data) {
		return api.request("pedidos/no_action", "admin/reports/orders/stores/products", "post", data).then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},

	load_vendedores_data: function() {
		return api.request("carts/no_action", "admin/users", "post", { "filters": [{field:"seller",operator: "=",value: true}], "sort": "name,asc", "limit": 10, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
        
	},

};