import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// app
import app_state from "./states/app";
import app_mutations from "./mutations/app";
import app_actions from "./actions/app";

// atributos
import atributos_state from "./states/atributos";
import atributos_mutations from "./mutations/atributos";
import atributos_actions from "./actions/atributos";

// atributos
import produto_tipos_state from "./states/produto-tipos";
import produto_tipos_mutations from "./mutations/produto-tipos";
import produto_tipos_actions from "./actions/produto-tipos";

// categorias
import categorias_state from "./states/categorias";
import categorias_mutations from "./mutations/categorias";
import categorias_actions from "./actions/categorias";

// marcas
import marcas_state from "./states/marcas";
import marcas_mutations from "./mutations/marcas";
import marcas_actions from "./actions/marcas";

// produtos
import produtos_state from "./states/produtos";
import produtos_mutations from "./mutations/produtos";
import produtos_actions from "./actions/produtos";

// preços
import precos_state from "./states/precos";
import precos_mutations from "./mutations/precos";
import precos_actions from "./actions/precos";

// grupos de usuários
import usuarios_grupos_state from "./states/grupos";
import usuarios_grupos_mutations from "./mutations/grupos";
import usuarios_grupos_actions from "./actions/grupos";

// usuários
import usuarios_state from "./states/usuarios";
import usuarios_mutations from "./mutations/usuarios";
import usuarios_actions from "./actions/usuarios";

// rotas
import rotas_state from "./states/rotas";
import rotas_mutations from "./mutations/rotas";
import rotas_actions from "./actions/rotas";

// vitrines
import vitrines_state from "./states/vitrines";
import vitrines_mutations from "./mutations/vitrines";
import vitrines_actions from "./actions/vitrines";

// banners
import banners_state from "./states/banners";
import banners_mutations from "./mutations/banners";
import banners_actions from "./actions/banners";

// carts
import carts_state from "./states/carts";
import carts_mutations from "./mutations/carts";
import carts_actions from "./actions/carts";

// adicionais
import paginas_adicionais_state from "./states/paginas-adicionais";
import paginas_adicionais_mutations from "./mutations/paginas-adicionais";
import paginas_adicionais_actions from "./actions/paginas-adicionais";

// especiais
import paginas_especiais_state from "./states/paginas-especiais";
import paginas_especiais_mutations from "./mutations/paginas-especiais";
import paginas_especiais_actions from "./actions/paginas-especiais";

// menus
import menus_state from "./states/menus";
import menus_mutations from "./mutations/menus";
import menus_actions from "./actions/menus";

// cupons
import cupons_state from "./states/cupons";
import cupons_mutations from "./mutations/cupons";
import cupons_actions from "./actions/cupons";

// descontos progressivos
import descontos_progressivos_state from "./states/descontos-progressivos";
import descontos_progressivos_mutations from "./mutations/descontos-progressivos";
import descontos_progressivos_actions from "./actions/descontos-progressivos";

// promoções frete
import promocoes_frete_state from "./states/promocoes-frete";
import promocoes_frete_mutations from "./mutations/promocoes-frete";
import promocoes_frete_actions from "./actions/promocoes-frete";

// promoções produtos
import promocoes_produtos_state from "./states/promocoes-produtos";
import promocoes_produtos_mutations from "./mutations/promocoes-produtos";
import promocoes_produtos_actions from "./actions/promocoes-produtos";

// brindes
import brindes_state from "./states/brindes";
import brindes_mutations from "./mutations/brindes";
import brindes_actions from "./actions/brindes";

// layout
import layout_state from "./states/layout";
import layout_mutations from "./mutations/layout";
import layout_actions from "./actions/layout";

// template email
import templates_email_state from "./states/templates-email";
import templates_email_mutations from "./mutations/templates-email";
import templates_email_actions from "./actions/templates-email";

// pedidos status
import pedidos_status_state from "./states/pedidos-status";
import pedidos_status_mutations from "./mutations/pedidos-status";
import pedidos_status_actions from "./actions/pedidos-status";

// clientes
import clientes_state from "./states/clientes";
import clientes_mutations from "./mutations/clientes";
import clientes_actions from "./actions/clientes";

// motivos de cancelamento
import motivos_state from "./states/motivos";
import motivos_mutations from "./mutations/motivos";
import motivos_actions from "./actions/motivos";

// pedidos
import pedidos_state from "./states/pedidos";
import pedidos_mutations from "./mutations/pedidos";
import pedidos_actions from "./actions/pedidos";

// senhas
import senhas_state from "./states/senhas";
import senhas_mutations from "./mutations/senhas";
import senhas_actions from "./actions/senhas";

// vouchers
import vouchers_state from "./states/vouchers";
import vouchers_mutations from "./mutations/vouchers";
import vouchers_actions from "./actions/vouchers";

// dashboard
import dashboard_state from "./states/dashboard";
import dashboard_mutations from "./mutations/dashboard";
import dashboard_actions from "./actions/dashboard";
// reviews
import reviews_state from "./states/reviews";
import reviews_mutations from "./mutations/reviews";
import reviews_actions from "./actions/reviews";
// depoimentos
import depoimentos_state from "./states/depoimentos";
import depoimentos_mutations from "./mutations/depoimentos";
import depoimentos_actions from "./actions/depoimentos";
// blog
import blog_state from "./states/blog";
import blog_mutations from "./mutations/blog";
import blog_actions from "./actions/blog";
// comentário blog
import blog_comments_state from "./states/blog-comments";
import blog_comments_mutations from "./mutations/blog-comments";
import blog_comments_actions from "./actions/blog-comments";
// estoque
import estoque_state from "./states/estoque";
import estoque_mutations from "./mutations/estoque";
import estoque_actions from "./actions/estoque";

// reports_pedidos
import reports_pedidos_state from "./states/reports-pedidos";
import reports_pedidos_mutations from "./mutations/reports-pedidos";
import reports_pedidos_actions from "./actions/reports-pedidos";

// reports_taxasml
import reports_taxasml_state from "./states/reports-taxasml";
import reports_taxasml_mutations from "./mutations/reports-taxasml";
import reports_taxasml_actions from "./actions/reports-taxasml";

// reports_jadlog
import reports_jadlog_state from "./states/reports-jadlog";
import reports_jadlog_mutations from "./mutations/reports-jadlog";
import reports_jadlog_actions from "./actions/reports-jadlog";

// reports_produtos_fornecedor
import reports_produtos_fornecedor_state from "./states/reports-produtos-fornecedor";
import reports_produtos_fornecedor_mutations from "./mutations/reports-produtos-fornecedor";
import reports_produtos_fornecedor_actions from "./actions/reports-produtos-fornecedor";

// reports_inventory
import reports_inventory_state from "./states/reports-inventory";
import reports_inventory_mutations from "./mutations/reports-inventory";
import reports_inventory_actions from "./actions/reports-inventory";

// reports_marketplace
import reports_marketplace_state from "./states/reports-marketplace";
import reports_marketplace_mutations from "./mutations/reports-marketplace";
import reports_marketplace_actions from "./actions/reports-marketplace";

// reports_sales
import reports_sales_state from "./states/reports-sales";
import reports_sales_mutations from "./mutations/reports-sales";
import reports_sales_actions from "./actions/reports-sales";


// duvidas produtos
import produtos_duvidas_state from "./states/produtos-duvidas";
import produtos_duvidas_mutations from "./mutations/produtos-duvidas";
import produtos_duvidas_actions from "./actions/produtos-duvidas";

// tickets
import tickets_state from "./states/tickets";
import tickets_mutations from "./mutations/tickets";
import tickets_actions from "./actions/tickets";

// monitoring
import monitoring_state from "./states/monitoring";
import monitoring_mutations from "./mutations/monitoring";
import monitoring_actions from "./actions/monitoring";

// alerts
import alerts_state from "./states/alerts";
import alerts_mutations from "./mutations/alerts";
import alerts_actions from "./actions/alerts";

// Sorteio
import raffle_state from "./states/raffle";
import raffle_mutations from "./mutations/raffle";
import raffle_actions from "./actions/raffle";

// produtos aguardando cadastro
import produtos_aguardando_state from "./states/waiting";
import produtos_aguardando_mutations from "./mutations/waiting";
import produtos_aguardando_actions from "./actions/waiting";

// update em massa de registros
import scale_state from "./states/scale";
import scale_mutations from "./mutations/scale";
import scale_actions from "./actions/scale";

const app = {
	namespaced: true,
	state: app_state,
	getters: {},
	mutations: app_mutations,
	actions: app_actions
};

const atributos = {
	namespaced: true,
	state: atributos_state,
	getters: {},
	mutations: atributos_mutations,
	actions: atributos_actions
};

const produto_tipos = {
	namespaced: true,
	state: produto_tipos_state,
	getters: {},
	mutations: produto_tipos_mutations,
	actions: produto_tipos_actions
};

const categorias = {
	namespaced: true,
	state: categorias_state,
	getters: {},
	mutations: categorias_mutations,
	actions: categorias_actions
};

const marcas = {
	namespaced: true,
	state: marcas_state,
	getters: {},
	mutations: marcas_mutations,
	actions: marcas_actions
};

const produtos = {
	namespaced: true,
	state: produtos_state,
	getters: {},
	mutations: produtos_mutations,
	actions: produtos_actions
};

const precos = {
	namespaced: true,
	state: precos_state,
	getters: {},
	mutations: precos_mutations,
	actions: precos_actions
};

const grupos = {
	namespaced: true,
	state: usuarios_grupos_state,
	getters: {},
	mutations: usuarios_grupos_mutations,
	actions: usuarios_grupos_actions
};

const usuarios = {
	namespaced: true,
	state: usuarios_state,
	getters: {},
	mutations: usuarios_mutations,
	actions: usuarios_actions
};

const rotas = {
	namespaced: true,
	state: rotas_state,
	getters: {},
	mutations: rotas_mutations,
	actions: rotas_actions
};

const vitrines = {
	namespaced: true,
	state: vitrines_state,
	getters: {},
	mutations: vitrines_mutations,
	actions: vitrines_actions
};

const banners = {
	namespaced: true,
	state: banners_state,
	getters: {},
	mutations: banners_mutations,
	actions: banners_actions
};

const carts = {
	namespaced: true,
	state: carts_state,
	getters: {},
	mutations: carts_mutations,
	actions: carts_actions
};

const paginas_adicionais = {
	namespaced: true,
	state: paginas_adicionais_state,
	getters: {},
	mutations: paginas_adicionais_mutations,
	actions: paginas_adicionais_actions
};

const paginas_especiais = {
	namespaced: true,
	state: paginas_especiais_state,
	getters: {},
	mutations: paginas_especiais_mutations,
	actions: paginas_especiais_actions
};

const menus = {
	namespaced: true,
	state: menus_state,
	getters: {},
	mutations: menus_mutations,
	actions: menus_actions
};

const cupons = {
	namespaced: true,
	state: cupons_state,
	getters: {},
	mutations: cupons_mutations,
	actions: cupons_actions
};

const descontos_progressivos = {
	namespaced: true,
	state: descontos_progressivos_state,
	getters: {},
	mutations: descontos_progressivos_mutations,
	actions: descontos_progressivos_actions
};

const promocoes_frete = {
	namespaced: true,
	state: promocoes_frete_state,
	getters: {},
	mutations: promocoes_frete_mutations,
	actions: promocoes_frete_actions
};

const promocoes_produtos = {
	namespaced: true,
	state: promocoes_produtos_state,
	getters: {},
	mutations: promocoes_produtos_mutations,
	actions: promocoes_produtos_actions
};

const brindes = {
	namespaced: true,
	state: brindes_state,
	getters: {},
	mutations: brindes_mutations,
	actions: brindes_actions
};

const layout = {
	namespaced: true,
	state: layout_state,
	getters: {},
	mutations: layout_mutations,
	actions: layout_actions
};

const templates_email = {
	namespaced: true,
	state: templates_email_state,
	getters: {},
	mutations: templates_email_mutations,
	actions: templates_email_actions
};

const pedidos_status = {
	namespaced: true,
	state: pedidos_status_state,
	getters: {},
	mutations: pedidos_status_mutations,
	actions: pedidos_status_actions
};

const clientes = {
	namespaced: true,
	state: clientes_state,
	getters: {},
	mutations: clientes_mutations,
	actions: clientes_actions
};

const motivos = {
	namespaced: true,
	state: motivos_state,
	getters: {},
	mutations: motivos_mutations,
	actions: motivos_actions
};

const pedidos = {
	namespaced: true,
	state: pedidos_state,
	getters: {},
	mutations: pedidos_mutations,
	actions: pedidos_actions
};

const senhas = {
	namespaced: true,
	state: senhas_state,
	getters: {},
	mutations: senhas_mutations,
	actions: senhas_actions
};

const vouchers = {
	namespaced: true,
	state: vouchers_state,
	getters: {},
	mutations: vouchers_mutations,
	actions: vouchers_actions
};

const dashboard = {
	namespaced: true,
	state: dashboard_state,
	getters: {},
	mutations: dashboard_mutations,
	actions: dashboard_actions
};

const tickets = {
	namespaced: true,
	state: tickets_state,
	getters: {},
	mutations: tickets_mutations,
	actions: tickets_actions
};

const produtos_duvidas = {
	namespaced: true,
	state: produtos_duvidas_state,
	getters: {},
	mutations: produtos_duvidas_mutations,
	actions: produtos_duvidas_actions
};

const reviews = {
	namespaced: true,
	state: reviews_state,
	getters: {},
	mutations: reviews_mutations,
	actions: reviews_actions
};

const depoimentos = {
	namespaced: true,
	state: depoimentos_state,
	getters: {},
	mutations: depoimentos_mutations,
	actions: depoimentos_actions
};

const blog = {
	namespaced: true,
	state: blog_state,
	getters: {},
	mutations: blog_mutations,
	actions: blog_actions
};

const blog_comments = {
	namespaced: true,
	state: blog_comments_state,
	getters: {},
	mutations: blog_comments_mutations,
	actions: blog_comments_actions
};

const estoque = {
	namespaced: true,
	state: estoque_state,
	getters: {},
	mutations: estoque_mutations,
	actions: estoque_actions
};

const reports_pedidos = {
	namespaced: true,
	state: reports_pedidos_state,
	getters: {},
	mutations: reports_pedidos_mutations,
	actions: reports_pedidos_actions
};

const reports_taxasml = {
	namespaced: true,
	state: reports_taxasml_state,
	getters: {},
	mutations: reports_taxasml_mutations,
	actions: reports_taxasml_actions
};

const reports_jadlog = {
	namespaced: true,
	state: reports_jadlog_state,
	getters: {},
	mutations: reports_jadlog_mutations,
	actions: reports_jadlog_actions
};

const reports_produtos_fornecedor = {
	namespaced: true,
	state: reports_produtos_fornecedor_state,
	getters: {},
	mutations: reports_produtos_fornecedor_mutations,
	actions: reports_produtos_fornecedor_actions
};

const reports_inventory = {
	namespaced: true,
	state: reports_inventory_state,
	getters: {},
	mutations: reports_inventory_mutations,
	actions: reports_inventory_actions
};

const reports_marketplace = {
	namespaced: true,
	state: reports_marketplace_state,
	getters: {},
	mutations: reports_marketplace_mutations,
	actions: reports_marketplace_actions
};

const reports_sales = {
	namespaced: true,
	state: reports_sales_state,
	getters: {},
	mutations: reports_sales_mutations,
	actions: reports_sales_actions
};

const monitoring = {
	namespaced: true,
	state: monitoring_state,
	getters: {},
	mutations: monitoring_mutations,
	actions: monitoring_actions	
};

const alerts = {
	namespaced: true,
	state: alerts_state,
	getters: {},
	mutations: alerts_mutations,
	actions: alerts_actions	
};

const raffle = {
	namespaced: true,
	state: raffle_state,
	getters: {},
	mutations: raffle_mutations,
	actions: raffle_actions	
};

const waiting = {
	namespaced: true,
	state: produtos_aguardando_state,
	getters: {},
	mutations: produtos_aguardando_mutations,
	actions: produtos_aguardando_actions
};
const scale = {
	namespaced: true,
	state: scale_state,
	getters: {},
	mutations: scale_mutations,
	actions: scale_actions
};

export default new Vuex.Store({
	modules: {
		app: app,
		atributos: atributos,
		produto_tipos: produto_tipos,
		categorias: categorias,
		marcas: marcas,
		produtos: produtos,
		precos: precos,
		grupos: grupos,
		usuarios: usuarios,
		rotas: rotas,
		vitrines: vitrines,
		banners: banners,
		paginas_adicionais: paginas_adicionais,
		paginas_especiais: paginas_especiais,
		menus: menus,
		cupons: cupons,
		descontos_progressivos: descontos_progressivos,
		promocoes_frete: promocoes_frete,
		promocoes_produtos: promocoes_produtos,
		brindes: brindes,
		layout: layout,
		templates_email: templates_email,
		pedidos_status: pedidos_status,
		clientes: clientes,
		motivos: motivos,
		pedidos: pedidos,
		senhas: senhas,
		vouchers: vouchers,
		dashboard: dashboard,
		tickets: tickets,
		produtos_duvidas: produtos_duvidas,
		reviews: reviews,
		depoimentos: depoimentos,
		blog: blog,
		estoque: estoque,
		reports_pedidos: reports_pedidos,
		reports_taxasml: reports_taxasml,
		reports_jadlog: reports_jadlog,
		reports_sales: reports_sales,
		reports_marketplace: reports_marketplace,
		monitoring: monitoring,
		alerts: alerts,
		reports_produtos_fornecedor: reports_produtos_fornecedor,
		reports_inventory: reports_inventory,
		raffle: raffle,
		blog_comments: blog_comments,
		waiting: waiting,
		scale: scale,
		carts: carts
	}
});