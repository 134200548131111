export default {
	init: function() {
		return {
			model: {
				id: null,
				store_id: null,
				order_number: null,
				customer: {
					id: null,
					name: null
				},
				user: {
					id: null,
					name: null
				},
				value: 0, 
				reason: null, 
				used: false, 
				used_at: null,
				orders: [],
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "order_number",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
		};
	}
};
