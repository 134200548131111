<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao :is_order="false" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row">
			<div class="col-4 my-3">
				<div class="card card-view card-base">
					<div class="card-header ">
						O que deseja atualizar?
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col">
								<FormSelect :required=false rules="option" label="" info="" :options="type_options" v-model="update_type" />		
							</div>							
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row" v-if="update_type == 'kits'">
			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header ">
						Atualização em massa de combos						
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-4">
								<FormInput :required=false rules="string" label="" placeholder="SKU Atual" info="" v-model="kit.from" />
							</div>
							<div class="col-2" v-if="(kits.length == 0 && kit.from.length > 0) || !hasPermission('editar', false)">
								<button class="btn btn-success" v-on:click="getKitsFromSku()">Buscar</button>
							</div>
							<div class="col-4" v-if="kits.length > 0 && hasPermission('editar', false)">
								<FormInput :required=false rules="string" label="" placeholder="SKU Novo" info="" v-model="kit.to" />
							</div>
							<div class="col-2" v-if="kits.length > 0 && hasPermission('editar', false)">
								<button class="btn btn-success" v-on:click="updateKits()">Atualizar</button>
							</div>
							<div class="col-2" v-if="kits.length > 0 && hasPermission('editar', false)">
								<button class="btn btn-danger" v-on:click="clearKits()">Limpar</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 my-3" v-if="kits.length">
				<div class="card card-view card-base">
					<div class="card-header">
						Combos com esse produto
					</div>
					<div class="card-body p-0">
						<table class="table table-striped table-bordered m-0">
							<thead>
								<tr class="table-secondary">
									<th scope="col">Título</th>
									<th scope="col">SKU</th>
									<th scope="col">Estoque</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(kit,i) in kits" :key=i>
									<td class="align-middle">{{ kit.title }}</td>
									<td class="center align-middle">{{ kit.variations[0].sku }}</td>
									<td class="center align-middle">{{ kit.variations[0].stock }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

import Header from "./../../common/Header";
import alerts from "./../../../common/alerts";
import Breadcrumb from "./../../common/Breadcrumb";
import FormInput from "../../common/form/Input";
import FormSelect from "../../common/form/Select";

export default {
	name: "UpdatesView",
	components: {
		Header,
		Breadcrumb,
		FormInput,
		FormSelect,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},		
	},	
	data () {
		return {
			header_info: {
				title: "Atualizações em massa de registros",
				chamada: "Atualize informações em massa dos seus registros",
				links: {}
			},
			breadcrumb_info: [				
				{
					name: "Atualizações em massa",
					link: ""
				}
			],
			permissao: [],
			config: {
				modulo_permissao: "scale-update"
			},	
			kits: [],
			kit: {
				to: "",
				from: ""
			},
			update_type: null,
			type_options: [
				{
					id: "kits",
					label: "Combos"
				}
			]
		};
	},  
	methods: {	
		hasPermission(type, manager) {
			if(manager == undefined){
				manager = false;
			}
			if(!manager){
				return this.permissao[type] === 1;
			}else{
				return this.usuario.manager && this.permissao[type] === 1;
			}
		},
		getKitsFromSku() {
			const self = this;
			this.$store.dispatch("scale/getKitsFromSku", this.kit.from).then(response => {
				if(response.length == 0){
					alerts.alertTop("error", "Nenhum combo foi encontrado para esse produto");
				}
				self.kits = response;
			});
		},
		updateKits() {
			let self = this;
			let event_confirm = () => {
				self.$store.dispatch("scale/updateKits", this.kit).then( response => {
					if(response.data.ok){
						alerts.alertTop("success", `${response.data.total} produtos foram atualizados com sucesso`);
						self.clearKits();
					}else{
						if(response.data.message){
							alerts.alertTop("error", response.data.message);
						}else{
							alerts.alertTop("error", "Houve um problema ao atualizar os kits");
						}
						
					}
				});
				return true;
			};
			alerts.alertConfirm("Tem certeza que deseja atualizar esses kits?",event_confirm);
		},
		clearKits() {
			this.kits = [];
			this.kit.to = "";
			this.kit.from = "";
		}
	},
	created() {
		this.$store.dispatch("scale/reset");
		if(this.permissoes) {
			this.permissao = this.permissoes[this.config.modulo_permissao];
		}	
		
	}	
};
</script>

<style scoped lang="scss">	
	
</style>
