<template>
	<div class="row comp">
		<div class="col-12 py-3">
			<ul>
				<li>Filtros de Pesquisa:</li>
				<li class="ml-3" v-if="search_data.filtros.length == 0">
					<select class="form-control" v-on:change="carrega_filtro()" v-model="filtro_selecionado">
						<option :value="0">Selecionar Filtro Padrão</option>
						<option :value="filter.id" v-for="filter in saved_filters" :key="filter.id">{{ filter.name }}</option>
					</select>
				</li>
				<li class="ml-3" v-if="search_data.filtros.length > 0">
					<div class="input-group filtro-save-name">
						<input type="text" class="form-control" id="filter_name" v-model="filter_name" placeholder="Dê um nome a esse filtro" v-on:keyup.enter="salvaFiltro()">
						<div class="input-group-append">
							<button class="btn btn-success" type="button" v-on:click="salvaFiltro()"><i class="fas fa-check"></i></button>
						</div>
					</div>
				</li>
				<li class="ml-3" v-if="search_data.filtros.length > 0">
					<button class="btn btn-warning" v-on:click="limparPesquisa()"><i class="fas fa-eraser"></i> Limpar Pesquisa</button>
				</li>
				<li class="ml-3" v-if="filtro_selecionado != 0">
					<button class="btn btn-danger" v-on:click="excluir_filtro()"><i class="fas fa-trash"></i> Excluir Filtro</button>
				</li>
			</ul>
		</div>
		<div class="col-12">
			<button class="btn btn-filtrar float-left" v-on:click="(exibir_busca_filtros) ? exibir_busca_filtros = false : exibir_busca_filtros = true"><i class="fas fa-filter" v-if="exibir_busca_filtros == false"></i><i class="fas fa-times" v-else></i></button>
			<div class="filtro-campos">
				<!-- Fazer um v-if e só exibir esse span se não tiver nenhum filtro selecionado -->
				<span class="filtro-aviso" v-if="filters.length == 0" v-on:click="(exibir_busca_filtros) ? exibir_busca_filtros = false : exibir_busca_filtros = true">Nenhum filtro selecionado até o momento</span>

				<!-- Lista de Filtros Selecionados -->
				<div class="filtro-campos-escolhidos mt-2 ml-2" v-for="(filter,index) in filters" :key="filter.key">
					<!-- String -->
					<filtro-texto :filter=filter :index=index v-if="['string','fulltext','cep', 'int', 'money'].includes(filter.config.type)"></filtro-texto>					
					<!-- Select -->
					<filtro-select :filter=filter :index=index v-if="['option','select'].includes(filter.config.type)" :use_operator="filter.config.use_group"></filtro-select>					
					<!-- Date -->
					<filtro-date :filter=filter :index=index v-if="['date', 'datetime'].includes(filter.config.type)"></filtro-date>					
				</div> 
			</div>
		</div>	
		<div class="col-12 mt-1" v-if="exibir_busca_filtros">			
			<input class="form-control" placeholder="Busque por um filtro" v-on:keyup="searchFilters()" v-model="search_filters" id="input_search_filter" v-on:keyup.38="navigateActive('up')" v-on:keyup.40="navigateActive('down')" v-on:keyup.enter="selectItemFromKeyboard()" />
			<ul class="filtros-selecionar">
				<li v-for="filtro in filtered" :key="filtro.field" v-on:click="selecionaFiltro(filtro)"><i class="fas fa-hand-pointer"></i> {{ filtro.label }}</li>
			</ul>
		</div>	
	</div>
</template>

<script>

import filtroTexto from "./filtros/Texto";
import filtroSelect from "./filtros/Select";
import filtroDate from "./filtros/Date";
import alerts from "../../../common/alerts";

export default {
	name: "Filtros",
	props: {
		config: Object,
		modulo_permissao: String,
		table_fields: Object
	},
	components: {
		filtroTexto,
		filtroSelect,
		filtroDate,
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		filters() {
			return this.$store.state[this.config.state].filters;
		},
		saved_filters(){
			return this.$store.state[this.config.state].saved_filters;
		},
		search_data() {			
			return this.$store.state[this.config.state].search_data;
		}
	},
	data () {
		return {
			permissao: {},
			exibir_busca_filtros: false,
			search_filters: "",
			filtered: [],
			filter_name: "", 
			filtro_selecionado: 0
		};
	}, 	
	inject: ["aplicaFiltro"],
	methods: {		
		// monta a busca para o filtro selecionado
		selecionaFiltro: function(filtro){
			let filters = this.filters;
			// criando a chave do filtro
			let chave = 1;
			filters.forEach((item) => {
				if(item.key >= chave){
					chave = item.key+1;
				}
			});

			let filter = {
				key: chave,
				config: filtro,
				search: {
					field: filtro.field,
					value: "",
					operator: "",
					type: ""
				}
			};

			filters.push(filter);

			this.$store.commit(this.config.state + "/filters",filters);	
			this.exibir_busca_filtros = false;
			this.search_filters = "";
			this.searchFilters();
		},
		updateFiltro: function(filtro, index){
			let self = this;
			let filters = this.filters;

			filters[index] = filtro;

			self.$store.commit(self.config.state + "/filters",filters);
			self.$store.dispatch(self.config.state + "/altera_pagina", 1).then(function(){					
				self.aplicaFiltro();
			});	
			// self.aplicaFiltro();
		},
		removeFiltro: function(index){
			let self = this;
			let filters = Object.assign([],this.filters);
			filters.splice(index, 1);
			this.$store.commit(this.config.state + "/filters",filters);			
			self.$store.dispatch(self.config.state + "/altera_pagina", 1).then(function(){					
				self.aplicaFiltro();
			});	
			// this.aplicaFiltro();
		},
		filterJson: function(item){
			let self = this;
			return item.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(self.search_filters.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
		},
		searchFilters: function(){
			let self = this;
			let filtros = this.table_fields.filtros;			
			if(this.search_filters != ""){
				let new_filtros = [];
				filtros.forEach(function(item){
					if(self.filterJson(item.label)){
						new_filtros.push(Object.assign({}, item));
					}
				});		
				filtros = new_filtros;						
			}
			
			this.filtered = filtros;
		},
		selectItemFromKeyboard: function(){
			let lista = document.getElementsByClassName("filtros-selecionar")[0].children;
			let indice_maximo = lista.length-1;
			for(let li=0;li<=indice_maximo;li++){					
				if(lista[li].classList.contains("active")){
					lista[li].classList.remove("active");
					lista[li].click();
					break;
				}
			}
		},
		navigateActive: function(direction){

			this.$nextTick(function () {
				let lista = document.getElementsByClassName("filtros-selecionar")[0].children;
				let indice_maximo = lista.length-1;
				let indice_lista = 0;
				if(direction == "up"){
					for(let li=indice_maximo;li>=0;li--){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li > 0){
								indice_lista = li-1;	
							}
							break;
						}
					}
				}else{
					for(let li=0;li<=indice_maximo;li++){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li < indice_maximo){
								indice_lista = li+1;	
							}						
							break;
						}
					}
				}
				
				// aplica a classe
				lista[indice_lista].classList.add("active");				
			});			
		},
		limparPesquisa: function(){
			let self = this;
			self.$store.commit(self.config.state + "/filters",[]);
			self.$store.dispatch(self.config.state + "/altera_pagina", 1).then(function(){					
				self.aplicaFiltro();
			});	
			// self.aplicaFiltro();	
			self.filter_name = "";								
			// atualiza o filtro selecionado no state
			this.$store.commit(this.config.state + "/select_filter",0);
		},
		salvaFiltro: function(){
			if(this.filter_name == ""){
				alerts.alertTop("error","Informe um nome de filtro para salvá-lo");
				document.getElementById("filter_name").focus();				
				return;
			}
			let filtro = {
				id: (this.filtro_selecionado != 0) ? this.filtro_selecionado : null,
				name: this.filter_name,
				type: this.config.tipo,
				user_id: this.usuario.id,
				rules: this.filters
			};
			this.$store.dispatch(this.config.state + "/save_filter", filtro).then(function(){
				alerts.alertTop("success","Filtro Salvo com Sucesso.",3000);
			});

			// {"name":"Transferência","type":"pedidos","rules":[{"tipo":"select","value":"id","label":"name","function":"load_status_filtro_data","dbfield":"status.id","field":"status.id","name":"Status do pedido","state":"pedidos","index":2,"data":{"field":"status.id","value":"5bc5de68b02ab2000594d22f","operator":"=","type":"string"}}],"id":null}
		},
		carrega_filtro: function(){
			let self = this;
			let id = this.filtro_selecionado;			
			let filtro = this.saved_filters.filter(item => {
				return item.id == id;
			})[0];

			this.filter_name = filtro.name;
			this.$store.commit(this.config.state + "/filters",filtro.rules);
			// atualiza o filtro selecionado no state
			this.$store.commit(this.config.state + "/select_filter",id);
			self.$store.dispatch(self.config.state + "/altera_pagina", 1).then(function(){					
				self.aplicaFiltro();
			});	
			// this.aplicaFiltro();
			
		},
		excluir_filtro: function(){
			let self = this;
			let event_confirm = () => {
				self.$store.dispatch(self.config.state + "/excluir_filtro",self.filtro_selecionado).then( () => {
					self.limparPesquisa();
				});
				return true;
			};
			alerts.alertConfirm("Tem certeza que deseja excluir este filtro?",event_confirm);				
		}
	},
	provide: function () {
		return {
			updateFiltro: this.updateFiltro,
			removeFiltro: this.removeFiltro
		};
	},
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
		this.searchFilters();
	},
	mounted(){
		// pega os filtros salvos
		this.$store.dispatch(this.config.state + "/get_filtros_salvos", { tipo: this.config.tipo, user_id: this.usuario.id });

		this.filtro_selecionado = this.search_data.filtro_selecionado;

		let self = this;
		let body = document.getElementsByTagName("body")[0];
		body.addEventListener ("keypress", (event) => {
			let keyName = event.key;			
			if(keyName == "S"){
				if(self.exibir_busca_filtros){
					self.exibir_busca_filtros = false;
				}else{
					self.exibir_busca_filtros = true;
				}
			}			
		});
	},
	watch: {
		exibir_busca_filtros: function(valor){			
			if(valor){
				this.$nextTick(function () {
					let input = document.getElementById("input_search_filter");
					input.focus();
				});				
			}
		},
		"search_data.filtro_selecionado": function(valor){		
			this.filtro_selecionado = valor;			
		}
	}
};
</script>

<style scoped lang="scss">	
	.comp{
		background: $bg_gray;
		ul{
			li{
				display: inline-block;
				select{
					padding: 5px;
				}
			}			
		}	
		.filtro-campos{
			border: 2px dotted $bg_destaque;
			border-radius: 5px;
			background: #FFF;
			cursor: s-resize;
			// min-height: 63px;
			margin-left: 3.2em;			
			padding-bottom: 0.5em;
			span{
				&.filtro-aviso{					
					color: $color_light;
					padding-left: 1em;
					margin: 1.2em 0 0.7em 0;
					display: block;
				}				
			}
			.filtro-campos-escolhidos{
				display: inline-block;
				background: $bg_destaque;
				padding: 0.3em 0.6em;
				color: #FFF;
				font-weight: bold;
				// input, select{
				// 	background: #FFF;
				// 	border: 1px solid $border_light;
				// 	border-radius: 2px;
				// 	padding: 0.3em;
				// 	font-size: 0.95em;
				// 	color:#555555;
				// }
				// button{
				// 	border-radius: 2px;
				// 	padding: 0.45em;	
				// 	background: $color_red;
				// 	border:1px solid $color_red;
				// 	font-size: 0.9em;
				// 	color:#FFF;
				// }
			}
		}
		.btn-filtrar{
			width: 44px;
			min-height: 58px;
			max-width: 3.2em;
			background: $bg_destaque;
			color:#FFF;			
		}
		.filtros-selecionar{
			border: 1px solid #d5d5d5;
			li{
				display: block;
				padding: 0.5em;
				cursor: pointer;
				i{
					display: none;
				}
				&:hover, &.active{
					background: $bg_destaque;
					color:#FFF;
					i{
						display: inline-block;
					}
				}
			}
		}
	}
</style>
