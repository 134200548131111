<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Banner
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-8">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Grid" info="" :options_none="{id: 0, label: ''}" :options="grid" v-model="model.grid"  type="number" />
							</div>														
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1, label: 'Mundo da Carabina'}, {id: 2,label: 'Aventura & Cia'}, {id: 3,label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>							
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Tipo de Banner" info="" :options="tipos_banners.map(item => { return { id: item.id, label: item.name }; })" v-model="model.banner_type.id" type="number" />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Vínculo" info="" :options="[{id: 0, label: 'Nenhum'}, {id: 1,label: 'Marcas'}, {id: 2,label: 'Categorias'}, {id: 3,label: 'Páginas Especiais'}, {id: 4,label: 'Páginas Ofertas'}]" v-model="model.type_link"  @input="changeType()" type="number" />
							</div>							
							<div class="col-8">
								<FormInput :required=false rules="string" label="Link" placeholder="" info="" v-model="model.link" />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Alvo" info="" :options="[{id: '_self',label: 'Mesma Janela'}, {id: '_blank', label: 'Nova Janela'}]" v-model="model.target" />		
							</div>
							<div class="col-6">
								<Imagem :required=true rules="string" label="Banner Desktop" placeholder="" info="" v-model="model.image" type="banners" :store_id="model.store_id" slug="desktop"></Imagem>
							</div>
							<div class="col-6">
								<Imagem :required=false rules="string" label="Banner Mobile" placeholder="" info="" v-model="model.image_mobile" type="banners" :store_id="model.store_id" slug="mobile"></Imagem>
							</div>
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data de Início" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.initial_date" :index=0 />
							</div>
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data Final" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.final_date" :index=1 />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status"  type="number" />
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Marcas -->
				<div class="hide card card-base">
					<div class="card-header">
						Marcas
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="banners" tipo="marcas_selecionadas" :dados="marcas" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Categorias -->
				<div class="hide card card-base">
					<div class="card-header">
						Categorias
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Categorias :permissao=permissao state="banners" :selecionados="categorias_selecionadas" :selecionavel=true :store_enabled=model.store_id />
						<!-- Fim Conteudo -->
					</div>
				</div>				
				<!-- Páginas Especiais -->
				<div class="hide card card-base">
					<div class="card-header">
						Páginas Especiais
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="banners" tipo="special_selecionadas" :dados="getPaginasEspeciais()" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Páginas Ofertas -->
				<div class="hide card card-base">
					<div class="card-header">
						Páginas Ofertas
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="banners" tipo="offerpages_selecionadas" :dados="getPaginasOfertas()" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import MultiSelect from "./../../common/MultiSelect";
import Categorias from "./../../common/Categorias";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";
import Imagem from "./../../common/form/Imagem";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		MultiSelect,
		Categorias,
		Imagem
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.banners.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		categorias_selecionadas() {
			return this.$store.state.banners.model.categorias_selecionadas;
		},
		marcas_selecionadas() {
			return this.$store.state.banners.model.marcas_selecionadas;
		},
		special_selecionadas() {
			return this.$store.state.banners.model.special_selecionadas;
		},
		offerpages_selecionadas() {
			return this.$store.state.banners.model.offerpages_selecionadas;
		},
		categorias() {
			return this.$store.state.banners.categorias;
		},
		grid() {
			return [
				{
					id: 6,
					label: "2 Colunas"
				},
				{
					id: 3,
					label: "3 Colunas"
				},
				{
					id: 4,
					label: "4 Colunas"
				},				
				{
					id: 12,
					label: "12 Colunas"
				},
			];
		}
	},	
	data () {
		return {
			header_info: {
				title: "Banners",
				chamada: "Gerencie os banners da sua loja",
				links: {
					back: "banners.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Banners",
					link: "/banners"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Marcas",
				"Categorias",
				"Páginas Especiais",
				"Páginas Ofertas"
			],
			disabled_items: [],
			hidden_items: [
				"Marcas",
				"Categorias",
				"Páginas Especiais",
				"Páginas Ofertas"
			],
			tipos_banners: [],
			marcas: [],
			special: [],
			offers_pages: [],
			permissao: {},
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){	
				let model = this.model;

				// tratando os dados de relação
				if(typeof model.type_link == "string"){
					model.type_link = parseInt(model.type_link);
				}
				let relation_link = [];
				switch(model.type_link){
				case 0:
					// geral
					relation_link = [];
					break;
				case 1:
					// marca
					model.marcas_selecionadas.forEach(item => {
						relation_link.push(item.id);
					});
					break;
				case 2:
					// categoria
					model.categorias_selecionadas.forEach(item => {
						relation_link.push({id: item.id});
					});
					break;				
				case 3:
					// paginas especiais
					model.special_selecionadas.forEach(item => {
						relation_link.push(item.id);
					});
					break;	
				case 4:
					// offers pages
					model.offerpages_selecionadas.forEach(item => {
						relation_link.push(item.id);
					});
					break;					
				default:
					relation_link = [];
					break;
				}
				model.relation_link = relation_link;
				
				// apaga os campos
				delete model.marcas_selecionadas;
				delete model.categorias_selecionadas;
				delete model.special_selecionadas;

				// tipos de banners
				let type = this.tipos_banners.find(item => {
					return item.id == model.banner_type.id;
				});
				model.banner_type = {
					id: type.id,
					name: type.name
				};

				// salva
				this.$store.dispatch("banners/save", model);
			}
		},
		changeType: function(){
			let type = parseInt(this.model.type_link);
			switch(type){			
			case 1:
				// Marcas
				this.hidden_items = [
					"Categorias",
					"Páginas Especiais",
					"Páginas Ofertas"
				];
				break;
			case 2:
				// Categorias
				this.hidden_items = [
					"Marcas",
					"Páginas Especiais",
					"Páginas Ofertas"
				];
				break;
			case 3:
				// Páginas Especiais
				this.hidden_items = [
					"Marcas",
					"Categorias",
					"Páginas Ofertas"
				];
				break;
			case 4:
				// Páginas Ofertas
				this.hidden_items = [
					"Marcas",
					"Categorias",
					"Páginas Especiais"
				];
				break;
			default:
				// Nenhum 
				this.hidden_items = [
					"Marcas",
					"Categorias",
					"Páginas Especiais",
					"Páginas Ofertas",
				];
				break;
			}
		},
		updateDadosMultiselect: function(objeto,tipo){
			this[tipo].push(objeto);
		},
		getPaginasEspeciais(){
			let self = this;
			if(this.model.store_id > 0){
				let paginas_especiais = this.special.filter(item => {
					return item.store_id == self.model.store_id;
				});
				return paginas_especiais;
			}else{
				return this.special;
			}
		},
		getPaginasOfertas() {
			let self = this;
			if(this.model.store_id > 0){
				let paginas_ofertas = this.offers_pages.filter(item => {
					return item.store_id == self.model.store_id;
				});
				return paginas_ofertas;
			}else{
				return this.offers_pages;
			}
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			updateDadosMultiselect: this.updateDadosMultiselect,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("banners/reset");

		let startingPromises = [
			// pega as categorias
			this.$store.dispatch("banners/load_categorias"),
			// pega as marcas
			this.$store.dispatch("banners/load_marcas").then(response => {
				self.marcas = response;
			}),
			// pega os tipos de banners
			this.$store.dispatch("banners/load_tipos").then(response => {
				self.tipos_banners = response;
			}),
			// pega as páginas especiais
			this.$store.dispatch("banners/load_paginas_especiais").then(response => {
				self.special = response;
			}),
			// pega as páginas ofertas
			this.$store.dispatch("banners/load_paginas_ofertas").then(response => {
				self.offers_pages = response;
			})
		];

		Promise.all(startingPromises).then(() => {
			if(this.$route.params.id != undefined && this.$route.params.id != "") {
				this.$store.dispatch("banners/load", this.$route.params.id).then(response => {
					if(response.return_code == 1){
						let data = response.data;

						// executa a função que vai exibir a aba correta de acordo com o tipo
						self.changeType();

						switch(data.type_link){
						case 1:
							// marcas
							self.$store.commit("banners/update_marcas_selecionadas",data.relation_link.map(item => {
								let marca = self.marcas.find(dado => {
									return item == dado.id;
								});
								if(marca){
									return marca;
								}
							}));
							break;
						case 2:
							// categoria
							self.$store.commit("banners/update_categorias_selecionadas",data.relation_link);
							break;
						case 3:						
							// páginas especiais
							self.$store.commit("banners/update_special_selecionadas",data.relation_link.map(item => {
								let pagina_especial = self.special.find(dado => {
									return item == dado.id;
								});
								if(pagina_especial){
									return pagina_especial;
								}
							}));
							break;
						}

					}
				});
			}
		}, () => {
			
		});

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}

	},
	watch: {		
		
	}
};
</script>

<style scoped lang="scss">	
	
</style>
