<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row justify-content-center  my-3">
			<div class="col-6">
				<div class="form-group m-0">
					<select class="form-control" v-model="modelo">
						<option value="horizontal">Imprimir na Horizontal</option>
						<option value="vertical">Imprimir na Veritical</option>
					</select>
				</div>
			</div>				
		</div>
		
		<div class="p-3" id="printMe">			
			<!-- Pedido Vertical Início -->
			<template v-if="modelo == 'vertical'">
				<div class="col-12 pedido-impressao" style="break-before: page;" v-for="(pedido,i) in pedidos" :key="modelo+'-'+i" v-bind:class="modelo" :id="'order-'+pedido.order_number">
					<div class="col-12 msg-error d-print-none">
						<div class="alert alert-danger mb-3" role="alert">
							
						</div>
					</div>
					<ul class="list-group">
						<li class="list-group-item disabled">Pedido</li>
						<li class="list-group-item ">
							<barcode :number='pedido.order_number | numberToString' :text="'Pedido:' + pedido.order_number"></barcode>
						</li>							
						<li class="list-group-item " v-if="pedido.customer.metadata && pedido.customer.metadata.viasoft_id">
							<barcode :number='pedido.customer.metadata.viasoft_id | numberToString' :text="'Cliente:' + pedido.customer.metadata.viasoft_id"></barcode>
						</li>	
						<li class="list-group-item " v-if="pedido.marketplace.marketplace_id">
							<barcode :number='pedido.marketplace.marketplace_order_number | numberToString' :text="'Marketplace:' + pedido.marketplace.marketplace_order_number"></barcode>
						</li>								
						<li class="list-group-item text-center">
							<strong class="pb-1">Data:</strong> {{ pedido.created_at | date(true) }}<br />
							<strong>Pagamento:</strong> <template v-if="dataPagamento(pedido) != ''">{{ dataPagamento(pedido) | date_time(true) }}</template><template v-else>À Pagar</template><br />
							<strong>Valor:</strong>{{ pedido.total_order | money }}
							<template v-if="pedido.shipment && pedido.shipment.price !== undefined">
								<br />
								<strong>Valor do Frete:</strong>{{ pedido.shipment.price | money }}
							</template>
						</li>
						<li class="list-group-item disabled">Informações do Pedido</li>
						<li class="list-group-item">
							{{ pedido.store_id | store }} / {{ pedido.company_id | company }} <br />
							<template v-if="pedido.marketplace.marketplace_id">Marketplace: {{ pedido.marketplace.marketplace_canal }} | {{ pedido.marketplace.marketplace_order_number }}</template>
						</li>			
						<li class="list-group-item disabled">Cliente</li>
						<li class="list-group-item">
							{{ pedido.customer.name }}<br />
							{{ pedido.customer.document | cnpfcnpj}}<br />
							<template v-for="(phone,idx) in pedido.customer.phones"><template v-if="idx > 0"> / </template>{{ phone.number | phone }}</template>
						</li>							
						<li class="list-group-item disabled">Entrega</li>
						<li class="list-group-item">
							Aos cuidados: {{ pedido.address.contact}}<br />
							{{ pedido.address.street }}, {{ pedido.address.number }} - {{ pedido.address.complement }} | Bairro: {{ pedido.address.neighborhood }}<br />
							CEP: {{ pedido.address.postal_code }} | {{ pedido.address.city }}/{{ pedido.address.state }}<br />
							Telefone: <template v-for="(phone,idx) in pedido.address.phones"><template v-if="idx > 0"> / </template>{{ phone.number | phone }}</template><br />
							Referência: {{ pedido.address.reference}}
						</li>
						<li class="list-group-item">
							{{ pedido.shipment.carrier }} - {{ (pedido.shipment.code == 999999) ? getShipmentName(pedido.shipment) : pedido.shipment.name }} - {{ pedido.shipment.delivery_time | filterDelivery }}
						</li>
						<li class="list-group-item disabled" v-if="pagamentosRetirada(pedido).length > 0 || (pedido.extra_information && pedido.extra_information != '')">Observações</li>
						<li class="list-group-item" v-for="payment in pagamentosRetirada(pedido)" :key="payment.id">
							** Efetuar cobrança de {{ payment.value | money }} na retirada do produto <br />({{ payment.prices.value | money }} à prazo / {{ payment.prices.cash_value | money }} à vista)
						</li>
						<li class="list-group-item" v-if="pedido.extra_information && pedido.extra_information != ''">
							{{ pedido.extra_information }}
						</li>	
						<li class="list-group-item" v-if="pedido.important_information && pedido.important_information != ''">
								{{ pedido.important_information }}
							</li>
						<li class="list-group-item disabled">Produtos</li>
						<li class="list-group-item" v-for="(produto,k) in pedido.products" :key="'produto-'+k">
							{{ produto.name }}<br />
							SKU: {{ produto.sku }} ({{ produto.supplier_code }}) - Quantidade: {{ produto.quantity }}<br />
							<span v-for="(atributo,ix) in produto.attributes" :key=ix class="clearfix">{{ atributo.name }}: {{ atributo.value.name ? atributo.value.name : atributo.value }}</span>
							<template v-if="produto.custom">
								<span v-for="(custom,ix) in produto.custom" :key=ix class="clearfix">{{ custom.name }}: {{ custom.value }}</span>
							</template>
							<br v-if="produto.custom" />Número de Série:
						</li>						
					</ul>
				</div>	
			</template>
			<!-- Pedido Vertical Fim -->

			<!-- Pedido Horizontal Início -->
			<template v-if="modelo == 'horizontal'">
				<div class="pedido-impressao row my-2" style="break-before: page;" v-for="(pedido,i) in pedidos" :key="modelo+'-'+i" v-bind:class="modelo" :id="'order-'+pedido.order_number">
					<div class="col-12 msg-error d-print-none">
						<div class="alert alert-danger mb-3" role="alert">
							
						</div>
					</div>					
					<div class="col-12 mb-3">
						<p class="text-size-menor text-center">Página {{ i+1 }}</p>
					</div>
					<div class="col-7" v-if="pedido.customer">
						<ul class="list-group">
							<li class="list-group-item">
								<strong>{{ pedido.shipment.carrier }} - {{ (pedido.shipment.code == 999999) ? getShipmentName(pedido.shipment) : pedido.shipment.name }} {{ (pedido.shipment.reference && pedido.shipment.reference != '') ? '('+pedido.shipment.reference+')' : '' }} - {{ pedido.shipment.delivery_time | filterDelivery }}</strong>
							</li>
							<li class="list-group-item disabled">Informações do Pedido</li>
							<li class="list-group-item">
								{{ pedido.store_id | store }} / {{ pedido.company_id | company }} <br />
								<template v-if="pedido.marketplace.marketplace_id">Marketplace: {{ pedido.marketplace.marketplace_canal }} | {{ pedido.marketplace.marketplace_order_number }}</template>							
							</li>								
							<li class="list-group-item disabled">Cliente</li>
							<li class="list-group-item">
								{{ pedido.customer.name }} <br />
								{{ pedido.customer.document | cnpfcnpj }} <br />
								Telefone: <template v-for="(phone,idx) in pedido.customer.phones"><template v-if="idx > 0"> / </template>{{ phone.number | phone }}</template>
							</li>							
							<li class="list-group-item disabled">Entrega</li>
							<li class="list-group-item">
								Aos cuidados: {{ pedido.address.contact}}<br />
								{{ pedido.address.street }}, {{ pedido.address.number }} - {{ pedido.address.complement }} | Bairro: {{ pedido.address.neighborhood }} | {{ pedido.address.city }}/{{ pedido.address.state }} <br /> CEP: {{ pedido.address.postal_code }}
								| Telefone: <template v-for="(phone,idx) in pedido.address.phones"><template v-if="idx > 0"> / </template>{{ phone.number | phone }}</template><br />
								Referência: {{ pedido.address.reference}}
							</li>														
						</ul>
					</div>
					<div class="col-5">
						<ul class="list-group">
							<li class="list-group-item ">
								<barcode :number='pedido.order_number | numberToString' :text="pedido.order_number | numberToString"></barcode>
							</li>							
							<li class="list-group-item " v-if="pedido.customer.metadata && pedido.customer.metadata.viasoft_id">
								<barcode :number='pedido.customer.metadata.viasoft_id | numberToString' :text="pedido.customer.metadata.viasoft_id | numberToString"></barcode>
							</li>	
							<li class="list-group-item " v-if="pedido.marketplace.marketplace_id">
								<barcode :number='pedido.marketplace.marketplace_order_number | numberToString' :text="pedido.marketplace.marketplace_order_number | numberToString"></barcode>
							</li>						
							<li class="list-group-item text-center">
								<strong>Data:</strong> {{ pedido.created_at | date_time(true) }}<br />
								<strong>Pagamento:</strong> <template v-if="dataPagamento(pedido) != ''">{{ dataPagamento(pedido) | date_time(true) }}</template><template v-else>À Pagar</template><br />
								<strong>Valor:</strong>{{ pedido.total_order | money }}
								<template v-if="pedido.shipment && pedido.shipment.price !== undefined">
									<br />
									<strong>Valor do Frete:</strong>{{ pedido.shipment.price | money }}
								</template>
							</li>
							<li class="list-group-item">Conferido por:<br /></li>
						</ul>	
					</div>
					<div class="col-12 mt-3">
						<table class="table table-bordered">
							<thead>
								<tr class="active">
									<td>Produto</td>
									<td class="text-center">SKU</td>
									<td class="text-center">Fornecedor</td>
									<td class="text-center">Quantidade ({{ getTotalItens(pedido) }})</td>									
								</tr>
							</thead>
							<tbody>
								<tr v-for="(produto,k) in pedido.products" :key=k>
									<td class="align-middle">
										{{ produto.name }}<br />
										<span v-for="(atributo,ix) in produto.attributes" :key=ix class="clearfix">{{ atributo.name }}: {{ atributo.value.name ? atributo.value.name : atributo.value }}</span>
										<template v-if="produto.custom">
											<span v-for="(custom,ix) in produto.custom" :key=ix class="clearfix">{{ custom.name }}: {{ custom.value }}</span>
										</template>
										<br v-if="produto.custom" />Número de Série:
									</td>
									<td class="text-center align-middle">{{ produto.sku }}</td>
									<td class="text-center align-middle">{{ produto.supplier_code }}</td>
									<td class="text-center align-middle">{{ produto.quantity }}</td>
								</tr>
							</tbody>
						</table>							
					</div>		
					<div class="col-12 mt-3">			
						<ul class="list-group">
							<li class="list-group-item" v-if="pedido.otherOrders > 0 || pagamentosRetirada(pedido).length > 0 || (pedido.extra_information && pedido.extra_information != '')"><strong>OBSERVAÇÕES:</strong></li>
							<li class="list-group-item" v-if="pedido.otherOrders > 0">Este cliente tem {{ pedido.otherOrders }} outros pedidos prontos para envio. </li>
							<li class="list-group-item" v-for="ord in pedido.otherOrdersList" :key="ord.order_number">
								Pedido: {{ ord.order_number }}
							</li>
							<li class="list-group-item" v-for="payment in pagamentosRetirada(pedido)" :key="payment.id">
								** Efetuar cobrança de {{ payment.value | money }} na retirada do produto <br />({{ payment.prices.value | money }} à prazo / {{ payment.prices.cash_value | money }} à vista)
							</li>	
							<li class="list-group-item" v-if="pedido.extra_information && pedido.extra_information != ''">
								{{ pedido.extra_information }}
							</li>								
							<li class="list-group-item" v-if="pedido.important_information && pedido.important_information != ''">
								{{ pedido.important_information }}
							</li>								
						</ul>
					</div>		
					<div class="col-6 recibo" v-for="payment in pagamentosRetirada(pedido)" :key="payment.id">
						<div>
							<strong class="d-block text-center my-3">RECIBO</strong>
							<p>Recebi de <strong>{{ pedido.customer.name }}</strong> a quantia de <strong>{{ payment.value | money }}</strong> correspondente ao pagamento vinculado ao pedido {{ pedido.order_number }} na loja {{ pedido.store_id | store }}.</p>
							<p class="mt-2"><strong>Forma de pagamento: </strong> <template v-if="payment.payment_type == 'cash'">Dinheiro</template><template v-else-if="payment.payment_type == 'debit_card'">Cartão de Débito</template><template v-else-if="payment.payment_type == 'credit_card'">Cartão de Crédito <template v-if="payment.installments">em {{ payment.installments }}x</template></template></p>
							<p class="mt-3 mb-4"><strong>Recebido em: </strong></p>
						</div>
					</div>
				</div>	
			</template>
			<!-- Pedido Horizontal Fim -->
		</div>		
	</div>
</template>

<script>

import Vue from "vue";
import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import VueHtmlToPaper from "vue-html-to-paper";
import barcode from "./../../common/BarCode.vue";
import Filters from "./../../../common/filters";
import {getCookie} from "./../../../common/utils";

const options_print = {
	name: "_blank",
	specs: [
		"fullscreen=yes",
		"titlebar=yes",
		"scrollbars=yes"
	],
	styles: [
		"/dist/css/build.css"
	]
};

Vue.use(VueHtmlToPaper, options_print);


export default {
	name: "PedidosPrint",
	components: {
		Header,
		Breadcrumb,
		barcode,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		pedidos(){
			return this.$store.state.pedidos.pedidos_impressao;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Imprimir Pedidos",
				chamada: "Gerencie a impressão dos pedidos da sua loja",
				links: {
					back: "pedidos.shipment",			
					print: "."		
				}
			},
			breadcrumb_info: [				
				{
					name: "Pedidos",
					link: "/pedidos"
				},
				{
					name: "Impressão",
					link: ""
				}
			],
			itens_tab:[
			],
			disabled_items: [],
			hidden_items: [],
			options_print: {
				name: "_blank",
				specs: [
					"fullscreen=yes",
					"titlebar=yes",
					"scrollbars=yes"
				],
				styles: [
					"https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
					"/dist/css/build.css"
				]
			},
			modelo: "vertical"
		};
	},  
	filters: Filters,
	methods: {		
		async print() {
			// Pass the element id here
			this.$htmlToPaper("printMe",this.options_print);
			// pegando qual é o status do pedido para em separação
			let status = await this.$store.dispatch("pedidos_status/get_status_by_type", "in-separation");
			if(status.length > 0){
				status = status[0].id;
				// atualiza o status do pedido para em separação			
				let orders = [];
				this.$store.state.pedidos.pedidos_impressao.forEach(item => {
					orders.push(item.id);
				});
				this.$store.dispatch("pedidos/update_status_pedidos_massa", {
					orders: orders,
					status_id: status,
					user_id: this.usuario.id
				}).then(response => {
					response.forEach(item => {
						let _div = document.getElementById("order-"+item.order_number);
						_div.classList.add("error");
						_div.getElementsByClassName("alert")[0].innerHTML = "Erro: "+item.msg;
					});
				});
			}						
		},
		dataPagamento(pedido) {
			if(!pedido.status_log){
				return "";
			}
			const _pedido = pedido.status_log.find(item => {
				return item.status_type === "paid";
			});

			if(_pedido){
				return _pedido.updated_at;
			}else{
				return "";
			}
		},
		pagamentosRetirada(pedido){			
			if(!pedido){
				return [];
			}
			return pedido.payments.filter(item => {
				return item.type === "withdraw";
			});
		},
		getTotalItens(pedido){
			let total = 0;
			pedido.products.forEach(item => {
				total += item.quantity;
			});
			return total;
		},
		getShipmentName(shipment) {
			try {
				const result = shipment.log.find(item => {
					return item.delivery_time == shipment.new_price.delivery_time && item.price == shipment.new_price.price;
				});
				return `${result.name} (${shipment.name})`;
			} catch {
				return shipment.name;
			}
		}
	},
	provide: function () {
		return {
			print: this.print,
		};
	},
	created() {
		if(this.permissoes.permissoes) {
			this.permissao = this.permissoes.permissoes[this.config.modulo_permissao];
		}		
		if(getCookie("formatoImpressao")){
			this.modelo = getCookie("formatoImpressao");
		}	


	},
	watch: {
		modelo: function(dados){
			// cria o cookie
			document.cookie = "formatoImpressao="+dados;	
		}
	},
	mounted() {
		if(!this.pedidos.length){
			this.$router.push({
				path: "/pedidos"
			});
			return;
		}		
	}
};
</script>

<style scoped lang="scss">	
	#printMe{
		background:#FFF;
		.disabled{
			font-weight: bold;
			background: #eee;
			cursor: default;
		}
		hr{
			margin: 0.5em 0;
		}
		.table-bordered {
			.active {
				font-weight: bold;
				background: #eee;
				color: #6c757d;
			}
		}		
		.list-group-item.disabled{
			strong {
				color: #6c757d;
			}
		}
		.pedido-impressao{	
			line-height: 1.3em;		
			.text-size-menor {
				font-size: 0.8em;
			}
			&::after {
				content: "";
				display: block;
				width: 80%;
				margin: 1em auto;
				border-top: 1px dotted #6c757d;
			}
			.msg-error{
				display: none;
			}
			&.error{
				.list-group-item.disabled{
					background: $color_red;
					color: #FFF;
				}
				.table-bordered {
					.active {
						background: $color_red;
						color: #FFF;
					}
				}
				.msg-error{
					display: inline-block;
				}
			}
		}
		.recibo {
			div {
				margin: 1em 0;
				border: 1px solid #d5d5d5;
				p{
					padding-left: 1em;
					padding-right: 1em;
				}
			}
		}
	}

	/*CSS para impressão*/
	@media print {
		* {
			background:transparent !important;
			color:#000 !important;
			text-shadow:none !important;
			filter:none !important;
			-ms-filter:none !important;
		}
			
		body {
			margin:0;
			padding:0;
			line-height: 1.4em;
		}

		@page {
			margin: 0.5cm;
		}
		img {
			max-width: 100%;
		}
		body {
			font: 12pt Georgia, "Times New Roman", Times, serif;
			color: #000;
		}
		
		h1 {
			font-size: 24pt;
		}
		
		h2 {
			font-size: 18pt;
		}
		
		h3 {
			font-size: 14pt;
		}		
		.pedido-impressao{
			.disabled{
				background-color: #eee !important;
				-webkit-print-color-adjust: exact; 
			}
			&.horizontal{
				height: 100%;
			}        
			&.vertical{
				.list-group-item{
					border-color: #333 !important;
					&.disabled{
						background-color: #333 !important;
						-webkit-print-color-adjust: exact;
						color:#FFF !important; 
					}
				}            
			}	
			.recibo {
				div {
					background: #d5d5d5 !important;
					border: 1px solid #d5d5d5 !important;
					-webkit-print-color-adjust:exact ;					
				}
			}		
		}
	}
</style>
