import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/scale";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	save: function(context, data) {
		api.request("scale/loaded", "admin/brand", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Marca cadastrada com sucesso." : "Marca atualizada com sucesso.");
				router.push({
					name: "scale.lista"
				});
			}
		});
	},
	getKitsFromSku: function(context, sku) {
		return api.request("scale/no_action", "admin/products", "post", { "fields": ["title","variations"], "filters": [{
			field: "kit_products.skus.sku",
			operator: "=",
			value: sku
		}], "sort": "title,asc", "limit": 999999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				if(response.body.data.items){
					response.body.data.items.forEach(item => {
						if(!item.variations){
							item["variations"] = [{
								sku: "AA-0000-0000",
								stock: 0
							}];
						}
					});
					return response.body.data.items;
				}
			}else{
				return [];
			}
		});
	},
	updateKits: function(context, kit){
		return api.request("scale/no_action", "actions/combos/change", "post", { to: kit.to, from: kit.from }).then(response => { return response.body; });
	}
};