<template>
	<div class="row">		
		<div :class="(selecionavel) ? 'col-4' : 'col-12'" v-if="![1,2,3].includes(store_enabled)">			
			<div class="input-group mb-3">
				<div class="input-group-prepend">
					<span class="input-group-text">Loja: </span>
				</div>				
				<select class="form-control" v-model="store_id" v-on:change="select_all = false;">
					<option :value=1>Mundo da Carabina</option>
					<option :value=2>Aventura & Cia</option>
					<option :value=3>Lexo</option>
				</select>
			</div>
		</div>
		<div :class="(selecionavel && ![1,2,3].includes(store_enabled)) ? 'col-8' : 'col-12'">
			<div class="input-group mb-3">
				<input type="text" class="form-control" placeholder="Busque por uma categoria" id="input_search_categoria" v-model="search">
				<div class="input-group-append">
					<button class="btn btn-secondary" type="button" v-if="search == ''"><i class="fas fa-search"></i></button>
					<button class="btn btn-danger" type="button" v-else v-on:click="search = ''"><i class="fas fa-times"></i></button>
				</div>
			</div>
		</div>
		<div class="col-12 mb-2" v-if="selecionavel">
			<div class="form-check float-left mr-3">
				<input class="form-check-input" type="checkbox" value="" id="open_all" :checked="open_all" v-on:change="openAll()">
				<label class="form-check-label" for="open_all">
					Abrir todas
				</label>
			</div>
			<div class="form-check float-left" v-if="enable_selectall">
				<input class="form-check-input" type="checkbox" value="" id="select_all"  :checked="select_all" v-on:change="selectAll()">
				<label class="form-check-label" for="select_all">
					Selecionar todas
				</label>
			</div>
			<!-- <button class="btn btn-sm btn-secondary mr-2 float-left" ><i class="fas fa-angle-up" v-if="open_all"></i><i class="fas fa-angle-down" v-else></i> Abrir</button>
			<button class="btn btn-sm float-left" :class="(select_all) ? 'btn-success' : 'btn-secondary'"><i class="fas fa-check"></i> Selecionar</button> -->
		</div>
		<div class="col-12">
			<div class="departamentos pt-3">
				<!-- Inicio Departamento -->
				<div class="departamento" v-for="(departamento,i) in categorias" :key=i >
					<div class="input-group mb-2" v-show="search == '' || (filtered.indexOf(departamento.id) != -1 || departamento.open)">
						<div class="input-group-prepend">
							<button class="btn btn-secondary" type="button" v-on:click="(departamento.open) ? departamento.open = false : departamento.open = true"><i class="fas fa-angle-up" v-if="departamento.open"></i><i class="fas fa-angle-down" v-else></i></button>
						</div>
						<input type="text" class="form-control" :class="(filtered.indexOf(departamento.id) != -1) ? 'filtered' : ''" :value="departamento.name" :disabled=true >
						<div class="input-group-append" v-if="permissao.editar == 1">
							<button class="btn btn-warning" type="button" v-on:click="edit(departamento)" v-if="!selecionavel"><i class="fas fa-edit"></i></button>
							<button class="btn" :class="(verificaSelecionado(departamento)) ? 'btn-success' : 'btn-secondary'" type="button" v-on:click="select(departamento)" v-else><i class="fas fa-check"></i></button>							
						</div>
					</div>								
					<div class="categorias" v-if="departamento.open">
						<!-- inicio Categoria -->
						<div class="categoria" v-for="(categoria,j) in departamento.children" :key=j>
							<div class="input-group mb-2" v-show="search == '' || (filtered.indexOf(categoria.id) != -1 || categoria.open)">
								<div class="input-group-prepend">														
									<button class="btn btn-secondary" type="button" v-on:click="(categoria.open) ? categoria.open = false : categoria.open = true"><i class="fas fa-angle-up" v-if="categoria.open"></i><i class="fas fa-angle-down" v-else></i></button>
								</div>
								<input type="text" class="form-control" :class="(filtered.indexOf(categoria.id) != -1) ? 'filtered' : ''"  :value="categoria.name" :disabled=true >
								<div class="input-group-append" v-if="permissao.editar == 1">
									<button class="btn btn-warning" type="button" v-on:click="edit(categoria)" v-if="!selecionavel"><i class="fas fa-edit"></i></button>
									<button class="btn" :class="(verificaSelecionado(categoria)) ? 'btn-success' : 'btn-secondary'" type="button" v-on:click="select(categoria)" v-else><i class="fas fa-check"></i></button>
								</div>
							</div>	
							<div class="subcategorias" v-if="categoria.open">
								<!-- inicio subcategoria -->
								<div class="subcategoria" v-for="(subcategoria,k) in categoria.children" :key=k>
									<div class="input-group mb-2" v-show="search == '' || filtered.indexOf(subcategoria.id) != -1">
										<input type="text" class="form-control" :class="(filtered.indexOf(subcategoria.id) != -1) ? 'filtered' : ''" :value="subcategoria.name" :disabled=true >
										<div class="input-group-append" v-if="permissao.editar == 1">
											<button class="btn btn-warning" type="button" v-on:click="edit(subcategoria)" v-if="!selecionavel"><i class="fas fa-edit"></i></button>
											<button class="btn" :class="(verificaSelecionado(subcategoria)) ? 'btn-success' : 'btn-secondary'" type="button" v-on:click="select(subcategoria)" v-else><i class="fas fa-check"></i></button>
										</div>
									</div>	
								</div>
								<!-- Nova Subcategoria -->
								<div class="input-group mb-2" v-if="permissao.criar == 1 && !selecionavel">										
									<input type="text" class="form-control label_nova_categoria" placeholder="Digite o nome da Subcategoria" v-on:keyup.enter="novo($event,'subcategoria',categoria)">
									<div class="input-group-append">
										<button class="btn btn-success" type="button" v-on:click="novo($event,'subcategoria',categoria)"><i class="fas fa-plus"></i></button>
									</div>
								</div>
							</div>
						</div>
						<!-- Nova Categoria -->
						<div class="input-group mb-2" v-if="permissao.criar == 1 && !selecionavel">										
							<input type="text" class="form-control label_nova_categoria" placeholder="Digite o nome da Categoria" v-on:keyup.enter="novo($event,'categoria',departamento)">
							<div class="input-group-append">
								<button class="btn btn-success" type="button" v-on:click="novo($event,'categoria',departamento)"><i class="fas fa-plus"></i></button>
							</div>
						</div>
					</div>										
				</div>
				<!-- Novo Departamento -->
				<div class="input-group mb-2" v-if="permissao.criar == 1 && !selecionavel">										
					<input type="text" class="form-control label_nova_categoria" placeholder="Digite o nome do Departamento" v-on:keyup.enter="novo($event,'departamento')" >
					<div class="input-group-append">
						<button class="btn btn-success" type="button" v-on:click="novo($event,'departamento')"><i class="fas fa-plus"></i></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import url_prefix from "./../../assets/json/urls.json";
import {Slug} from "./../../common/utils";
import Alerts from "./../../common/alerts";

export default {
	name: "Categorias",
	props: {
		permissao: {
			type: Object,
			required: true
		},
		state: {
			type: String,
			required: true
		},
		selecionavel: {
			type: Boolean,
			required: false,
			default: false
		},
		selecionados: {
			type: Array,
			required: false,
			default: () => {
				return [];
			}
		},
		store_enabled: {
			type: Number,
			required: false,
			default: 0
		},
		enable_selectall: {
			type: Boolean,
			required: false,
			default: true
		},
	},
	computed: {
		categorias(){
			let self = this;
			// tira os já selecionados
			let dados = Object.assign([], this.$store.state[self.state].categorias.filter(item => {
				return item.store_id == self.store_id;
			}));			
			self.filtered = [];
			// limpa os open 
			dados.forEach(function(item){
				item.open = self.open_all;
				if(item.children.length > 0){
					item.children.forEach(it => {
						it.open = self.open_all;
					});
				}
			});		
			// faz o filtro
			if(this.search != ""){
				dados.forEach(function(item){
					if(self.filterJson(item.name)){
						self.filtered.push(item.id);
					}
					if(item.children.length > 0){
						item.children.forEach(it => {
							if(self.filterJson(it.name)){								
								item.open = true;
								self.filtered.push(it.id);
							}
							if(it.children.length > 0){
								it.children.forEach(c => {
									if(self.filterJson(c.name)){
										it.open = true;
										item.open = true;
										self.filtered.push(c.id);
									}
								});
							}	
						});
					}
				});		
			}else if(this.selecionavel){
				dados.forEach(function(item){
					if(item.children.length > 0){
						item.children.forEach(it => {
							if(self.verificaSelecionado(it)){
								item.open = true;
							}
							if(it.children.length > 0){
								it.children.forEach(c => {
									if(self.verificaSelecionado(c)){
										it.open = true;
										item.open = true;
									}
								});
							}	
						});
					}					
				});
			}
			return dados;
		}, 		
	},
	data () {
		return {
			search: "",
			filtered: [],
			open_all: false,
			select_all: false,
			store_id: 1,
		};
	},  
	created() {
	},
	methods: {		
		novo: function(el, type,parent){

			let name;
			let element = event.currentTarget;			
			if(element.classList.contains("label_nova_categoria")){
				// se o evento for do input
				name = element.value;
				element.value = "";
			}else{
				// é o button
				element = element.parentNode.parentNode.getElementsByClassName("label_nova_categoria")[0];
				name = element.value;
				element.value = "";
			}

			// validação para ver se preencheu o nome
			if(name == ""){
				Alerts.alertTop("error","Digite um nome para a categoria que deseja criar.");
				return;
			}
			
			let level;
			let slug;
			switch(type){
			case "departamento":
				level = 1;
				slug = url_prefix.category + Slug(name);
				break;	
			case "categoria":
				level = 2;
				slug = parent.slug + "/" + Slug(name);
				break;	
			case "subcategoria":
				level = 3;
				slug = parent.slug + "/" + Slug(name);
				break;			
			}

			let new_category = {
				id: null,
				name: name,
				metatags: {
					title: name,
					description: null,
					keywords: null
				},
				parent_id: (parent != null) ? parent.id : "",
				level: level,
				store_id: this.store_id,
				status: 1,
				slug: slug				
			};

			this.$store.dispatch("categorias/nova_categoria",new_category);

		},
		edit: function(categoria){
			this.$store.dispatch("categorias/load",categoria.id);
		},
		select: function(categoria){
			let cat = {
				name: categoria.name,
				slug: categoria.slug,
				level: categoria.level,
				id: categoria.id,
				store_id: this.store_id,
			};
			// verifica se já para incluir ou remover
			if(this.verificaSelecionado(categoria)){
				// remover
				this.$store.commit(this.state + "/remover_categoria", cat);
			}else{		
				// incluir 
				this.$store.commit(this.state + "/incluir_categoria", cat);
			}
			
		},
		filterJson: function(item){
			let self = this;			
			return item.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(self.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
		},
		verificaSelecionado(categoria){			
			return this.selecionados.find(item => { return item.id == categoria.id; }) !== undefined;
		},
		openAll: function(){
			this.open_all = !this.open_all;
		},
		selectAll: function(){
			let self = this;
			let categorias = this.categorias;
			this.select_all = !this.select_all;
			if(this.select_all){
				// selecionar as categorias 				
				categorias.forEach(function(item){
					if(!self.verificaSelecionado(item)){
						self.select(item);
					}
					if(item.children.length > 0){
						item.children.forEach(it => {
							if(!self.verificaSelecionado(it)){
								self.select(it);
							}
							if(it.children.length > 0){
								it.children.forEach(c => {
									if(!self.verificaSelecionado(c)){
										self.select(c);
									}
								});
							}
						});
					}
				});	
			}else{
				// desmarcar as categorias 				
				categorias.forEach(function(item){
					if(self.verificaSelecionado(item)){
						self.select(item);
					}
					if(item.children.length > 0){
						item.children.forEach(it => {
							if(self.verificaSelecionado(it)){
								self.select(it);
							}
							if(it.children.length > 0){
								it.children.forEach(c => {
									if(self.verificaSelecionado(c)){
										self.select(c);
									}
								});
							}
						});
					}
				});	
			}						
		}
	},	
	watch: {
		store_enabled: function(dado){
			if(dado >= 1){
				this.store_id = dado;
			}else{
				this.store_id = 1;
			}
		}
	},
};
</script>

<style scoped lang="scss">
	.departamentos{
		border-top: 1px solid #d5d5d5;
		.departamento{
			.categorias{
				border-left: 1px dashed #d5d5d5;
				padding-left: 1.1em;
				margin-left: 1.1em;
				.categoria{
					.subcategorias{
						border-left: 1px dashed #d5d5d5;
						padding-left: 1.1em;
						margin-left: 1.1em;
					}
				}
				
			}
		}
	}
	input.filtered{
		background-color: $color_yellow;
		border-color: $color_yellow;
	}
	.form-check-input{
		margin-top: 0!important;
	}
</style>
