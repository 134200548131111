<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Produto
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>
							<div class="col-6">
								<FormInput :required=true rules="string" label="SKU" placeholder="" info="" v-model="model.sku" :slug_function=false />
							</div>
							<div class="col-6">
								<FormInput :required=false rules="string" label="Código de Fornecedor" placeholder="" info="" v-model="model.supplier_code" :slug_function=false />
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import Alerts from "./../../../common/alerts";

// Inputs
import FormInput from "./../../common/form/Input";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.waiting.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Produtos Aguardando Cadastro",
				chamada: "Gerencie os produtos aguardando cadastro da sua loja",
				links: {
					back: "produtos.aguardando.lista",
					save: ".",
					finish: ".",
				}
			},
			breadcrumb_info: [				
				{
					name: "Aguardando Cadastro",
					link: "/produtos/aguardando"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
			],
			disabled_items: [],
			hidden_items: [],
			permissao: {},
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){	
				// salva
				this.$store.dispatch("waiting/save", this.model);
			}
		},
		async finish() {

			if(this.model.id) {
				Alerts.loader(true);
				const product = await this.$store.dispatch("waiting/get_full_product", this.model.id);
				if(product){
					this.$router.push({
						name: "produtos.novo",
						params: product
					});
				}else{
					Alerts.alertTop("error", "Este SKU não foi encontrado no Viasoft");	
				}				
			}else{
				Alerts.alertTop("error", "É necessário estar editando um produto para prosseguir");
			}
			
		},
	},
	provide: function () {
		return {
			save: this.save,
			finish: this.finish,
		};
	},
	created() {
		this.$store.dispatch("waiting/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("waiting/load", this.$route.params.id);
		}

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}

	},
	watch: {		
		
	}
};
</script>

<style scoped lang="scss">	
	
</style>
