<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Ticket
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">

							<div class="col-12">
								<div class="form-group required"> 
									<label class="mb-1">Busque por um Cliente </label>
									<MultiSelect state="tickets" tipo="customers" :dynamic=true dynamic_action="clientes/filter_customers_data" :dados="[]" :field_required=false :multiple=false :required=false :excluir_validation=false></MultiSelect>
								</div>
							</div>		

							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome do Cliente" placeholder="" info="" v-model="model.customer.name" :slug_function=true />
							</div>

							<div class="col-6">
								<FormInput :required=true rules="string" label="E-mail do Cliente" placeholder="" info="" v-model="model.customer.email" :slug_function=true />
							</div>				

							<div class="col-6">
								<div class="form-group required"> 
									<label class="mb-1">Responsável pelo Ticket<i class="fas fa-asterisk"></i></label>
									<MultiSelect state="tickets" tipo="responsible" :dados="responsible_users" :field_required=false :multiple=false :required=true :excluir_validation=false></MultiSelect>
								</div>
							</div>	

							<div class="col-6">
								<div class="form-group required"> 
									<label class="mb-1">Número do pedido</label>
									<MultiSelect state="tickets" tipo="orders" :dados="client_orders" :field_required=false :multiple=false :required=false :excluir_validation=false></MultiSelect>
								</div>
							</div>	

							<div class="col-12">
								<div class="form-group"> 
									<label class="mb-1">Assunto<i class="fas fa-asterisk"></i></label>
									<MultiSelect state="tickets" tipo="subjects" :dados="subjects" :required=true :field_required=false :insert=false :multiple=false :excluir_validation=false></MultiSelect>
								</div>
							</div>

							<div class="col-12">
								<div class="form-group"> 
									<label class="mb-1">Tags</label>
									<MultiSelect state="tickets" tipo="tags" :dados="tags" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true></MultiSelect>
								</div>
							</div>

							<div class="col-4">
								<FormSelect :required=true rules="option" label="Modo" info="" :options="[{id: false,label: 'Público'}, {id: true, label: 'Privado'}]" v-model="model.private"  type="boolean" />
							</div>

							<div class="col-4">
								<FormSelect :required=true rules="option_store" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>	

							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Aberto'}, {id: 0, label: 'Finalizado'}]" v-model="model.status" type="number" />		
							</div>

						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<div class="hide card card-base">
					<div class="card-header">
						Mensagem do Ticket
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">

							<div class="col-12">
								<FormSelect :required=true rules="option" label="Destinatário" info="" :options="[{id: 'customer', label: 'Cliente e Loja'}, {id: 'store',label: 'Somente Loja'}]" v-model="model.messages[0].receiver" type="string" />		
							</div>	

							<div class="col-12">
								<FormTextarea :required=true label="Mensagem" v-model="model.messages[0].message" :rows=3 placeholder="Digite aqui seu texto" info="" type="textarea" />
							</div>

							<div class="col-12">
								<FormSelect :required=true rules="option" label="SLA" info="" :options="[{id: 'urgent',label: 'Urgente'}, {id: 'moderate', label: 'Moderado'}, {id: 'normal', label: 'Normal'}, {id: 'weekly', label: 'Semanal'}]" v-model="model.messages[0].sla" />		
							</div>

							<div class="col-12">
								<div class="buttons">
									<FileUpload
										ref="upload"
										v-model="defectFiles"
										class="btn btn-info"
										post-action="/"
										extensions="png,jpg,jpeg"
										accept="image/png,image/jpeg"
										:multiple="true"
										:size="1024 * 1024 * 10"
										:disabled="ticketSending"
										@input-filter="uploadFilter"
									>
										Incluir anexos
									</FileUpload>
								</div>	
							</div>	

							<div
								v-if="defectFiles && defectFiles.length"
								class="attachments col-12"
							>
								<p class="my-3"><strong>Anexos selecionados</strong></p>

								<ul>
									<li v-for="file of defectFiles" :key="file.id" class="p-2 my-2">
										<img
										v-if="file.thumb && file.thumb !== 'arquivo'"
										:src="file.thumb"
										alt="Pré-visualização da imagem"
										class="align-middle"
										/>
										<i class="far fa-file-pdf align-middle" v-if="file.thumb && file.thumb === 'arquivo'"></i>
										<span class="align-middle mx-3">{{ file.name }}</span>

										<button class="btn btn-sm btn-danger align-middle" @click="removeFile(file)">
										<i class="fas fa-trash-alt"></i>
										</button>
									</li>
								</ul>
							</div>


						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug,deepCopy} from "./../../../common/utils";
import FileUpload from "vue-upload-component/src/FileUpload.vue";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormTextarea from "./../../common/form/Textarea";
import MultiSelect from "./../../common/MultiSelect";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormTextarea,
		MultiSelect,
		FileUpload,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.tickets.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		client_orders() {
			return this.$store.state.tickets.client_orders;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Tickets",
				chamada: "Gerencie os tickets dos clientes da sua loja",
				links: {
					back: "tickets.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Tickets",
					link: "/tickets"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Mensagem"
			],
			disabled_items: [],
			hidden_items: [],
			responsible_users: [],
			subjects: [],
			tags: [],
			ticketSending: false,
			defectFiles: [],
		};
	},  
	methods: {		
		save: function(){							
			if(validation.validation().valid){		
				let model = deepCopy(this.model);

				model.messages[0].responsible = {
					id: this.usuario.id,
					name: this.usuario.name
				};
				model.subject = model.subjects.name;	
				if(model.tags.length > 0){
					model.tags = [];
					this.model.tags.forEach(item => {
						model.tags.push({
							name: item.name,
							slug: item.id
						});
					});
				}		
				if(model.orders.id){
					model.order = {
						id: model.orders.id,
						number: model.orders.name
					};
				}	
				
				model.attachments = this.defectFiles
					? this.defectFiles.map((el) => el.file)
					: [],

				delete model.customers;
				delete model.subjects;
				delete model.orders;

				// salva
				this.$store.dispatch("tickets/save", model);
			}
		},
		updateDadosMultiselect: function(objeto,tipo){	
			// criando o slug
			if(!objeto.slug){
				objeto.slug = Slug(objeto.name);
			}
			// criando o id a partir do slug pois o componente obriga ter um id válido
			if(!objeto.id){
				objeto.id = objeto.slug;
			}
			// inclui dentro do data para pesquisa
			this[tipo].push(objeto);
			// seleciona já como a opção escolhida pelo cliente
			this.$store.commit("tickets/addTag",{item: objeto, tipo: tipo});
		},
		uploadFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				if (
					!newFile.file ||
					!!newFile.error ||
					/^\.(gif|jpe?g|png|webp|pdf)$/i.test(newFile.name) ||
					(newFile.type.substr(0, 6) !== "image/" &&
						/^application\/(wps-office.pdf|pdf)$/i.test(newFile.name))
				) {
					return prevent();
				}
			}
			if (
				newFile &&
				!newFile.error &&
				newFile.file &&
				(!oldFile || newFile.file !== oldFile.file)
			) {
				newFile.blob = "";
				const URL = window.URL || window.webkitURL;
				if (URL) {
					newFile.blob = URL.createObjectURL(newFile.file);
				}
				newFile.thumb = "arquivo";
				if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
					newFile.thumb = newFile.blob;
				}
			}
		},
		removeFile(file) {
			this.$refs.upload.remove(file);
		},
	},
	provide: function () {
		return {
			save: this.save,
			updateDadosMultiselect: this.updateDadosMultiselect
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("tickets/reset"); // reseta o model


		let startingPromises = [ 
			this.$store.dispatch("tickets/load_subjects").then(response => { // pega os assuntos
				self.subjects = []; 
				response.forEach(item => {
					self.subjects.push({
						id: Slug(item),
						name: item
					});
				});					
			}),
			// this.$store.dispatch("tickets/load_tags").then(response => { // pega as tags
			// 	self.tags = []; // cria um array vazio para tags
			// 	response.forEach(item => {
			// 		self.tags.push({
			// 			id: item.slug,
			// 			name: item.name
			// 		});
			// 	});	
			// }),
			this.$store.dispatch("tickets/load_responsible_users").then(response => { // pega os users
				self.responsible_users = []; 
				if(response.items){
					response.items.forEach(item => {
						self.responsible_users.push({
							id: item.id,
							name: item.name
						});
					});
				}				
			}),
		];

		Promise.all(startingPromises).then(() => {

			// pegando parametros enviados
			const {from, document_id} = this.$route.params;
			if(from !== undefined){
				if(from === "order"){
					self.$store.dispatch("pedidos/load", document_id).then(response => {
						if(response.id){
							self.$store.commit("tickets/multiselect_push", {
								item: {
									id: response.customer.id,
									name: response.customer.name,	
								},
								multiple: false,
								tipo: "customers",
							});

							self.model.customer = {
								id: response.customer.id,
								name: response.customer.name,
								email: response.customer.email
							};

							self.$store.commit("tickets/multiselect_push", {
								item: {
									id: response.id,
									name: response.order_number,	
								},
								multiple: false,
								tipo: "orders",
							});	
							
							self.model.store_id = parseInt(response.store_id);
						}						
						
					});
				}
			}
		});
	},
	watch: {
		"model.customers": function(valor){
			if(valor.id){
				this.$store.dispatch("pedidos/getOrdersByCustomer", valor.id).then(response => {
					this.$store.commit("tickets/updateOrdersByCustomer", response);
				});
				this.$store.dispatch("clientes/load", valor.id).then(retorno => {
					if(retorno.return_code == 1){
						this.$store.commit("tickets/updateCustomerData", {
							id: retorno.data.id,	
							name: retorno.data.name,
							email: retorno.data.email,
						});
					}		
				});
			}else{
				this.$store.commit("tickets/updateOrdersByCustomer", []);
			}
		},
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";

	.attachments {
		ul {
			li {
				border-bottom: 1px solid #e2e2e2;
				img {
					max-width: 5em;
				}
				i.fa-file-pdf {
					width: 1.7em;
					font-size: 3em;
					margin: 0 auto;
					text-align: center;
				}
			}
		}
	}
</style>
