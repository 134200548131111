<template>
	<div class="form-group" v-bind:class="{'required': required}">
		<label class="mb-1" v-if="label != ''">{{ label }} <i class="fas fa-asterisk"></i></label>
		<input :type="type" class="form-control" :name=name :class="extra_class" :maxlength="maxlength" :disabled=disabled :placeholder="placeholder" v-if="['text', 'number', 'password'].includes(type)" ref="model" :value="valueTratado" @input="updateDate()" :required=required :validation-rules="rules" v-on:change="changeAction()" />
		<input type="text" class="form-control" :name=name :class="extra_class" :disabled=disabled :placeholder="placeholder" v-if="['postalcode', 'phone', 'cpf', 'cnpj','cellphone'].includes(type)" ref="model" :value="valueTratado" @input="updateDate()" v-mask="pegaMascara()"  :required=required :validation-rules="rules" v-on:change="changeAction()" />
		<input type="text" class="form-control" :name=name :class="extra_class" :disabled=disabled :placeholder="placeholder" v-if="['time_addOn'].includes(type)" ref="model" :value="valueTratado" @blur="updateDate()" v-mask="pegaMascara()"  :required=required :validation-rules="rules" />
		<input type="text" class="form-control" :name=name :class="extra_class" :disabled=disabled :placeholder="placeholder" v-if="type == 'money'" ref="model" :value="valueTratado" v-money="money" @blur="updateDate()" :required=required :validation-rules="rules" />
		<small class="form-text text-muted mt-2" v-if="info != ''">{{ info }}</small>
	</div>
</template>

<script>

import {mask} from "vue-the-mask";
import {VMoney} from "v-money";

export default {
	name: "Input",
	components: {
		
	},
	computed: {
		valueTratado() {
			if(this.type == "money"){
				let valor = this.value;
				if(typeof valor == "number"){
					valor = valor.toFixed(2);
				}
				valor = valor.replace(".", ",").replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
				return valor;
			}else{
				return this.value;
			}
		}
	},
	directives: {
		mask,
		money: VMoney
	},
	props: {
		required: {
			type: Boolean,
			default: true,			
		},
		rules: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: "",			
		},
		info: {
			type: String,
			default: "",			
		},
		placeholder: {
			type: String,
			default: "",			
		},
		type: {
			type: String,
			default: "text",			
		},
		value: {			
			default: "",
		},
		slug_function: {
			type: Boolean,			
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		extra_class: {
			type: String,
			required: false,
			default: ""
		},
		name: {
			type: String,
			required: false,
			default: ""
		},
		dataCheckout: {
			type: String,
			required: false,
			default: ""
		},
		maxlength: {
			type: Number,
			required: false,
			default: 524288,
		},
	},
	data () {
		return {
			masks: [
				{
					type: "postalcode",
					mask: "#####-###"
				},
				{
					type: "phone",
					mask: "(##)####-####"
				},
				{
					type: "cellphone",
					mask: "(##)#####-####"
				},
				{
					type: "int",
					mask: "#############"
				},
				{
					type: "time_addOn",
					mask: "##:##:##"
				},
				{
					type: "cpf",
					mask: "###.###.###-##"
				},
				{
					type: "cnpj",
					mask: "##.###.###/####-##"
				}
			],
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "",
				suffix: "",
				precision: 2,
				masked: false
			},
		};
	},  
	inject: {
		toSlug: {
			default: () => {}
		}
	},	
	created() {
		// this.$el.input.querySelector('input').setAttribute("data-toggle", this.col.col_spec.tooltip);
		// window.console.log(this.$el.getElementsByTagName("input"));
		
	},
	methods: {		
		updateDate() {			
			let value = this.$refs.model.value;
			if(this.type == "money"){
				value = value.replace(/\./g,"");
				value = value.replace(",",".");
				value = parseFloat(value);
			}
			if(this.type == "number"){				
				value = parseInt(value);
			}
			this.$emit("input", value);
		},
		pegaMascara: function(){
			let type = this.type;
			return this.masks.filter((mask) => {
				return mask.type == type;
			})[0].mask;
		},
		changeAction(){
			if(this.slug_function){
				this.toSlug();
			}else{
				this.$emit("change", this.$refs.model.value);
			}
		},
		focusInput(){
			this.$refs.model.focus();
		}
	},
	mounted() {
		if(this.dataCheckout != ""){
			this.$refs.model.setAttribute("data-checkout", this.dataCheckout);
		}		
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
