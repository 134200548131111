export default {
	init: function() {
		return {
			model: {
				protocol_number: null,
				id: null,
				source: "admin",
				order: null,
				customer: {
					id: null,
					name: null,
					email: null
				},
				subject: null,
				tags: [],
				store_id: null,
				status: 1,
				messages: [{
					receiver: "customer",
					message: null,
					sla: "normal",
				}],
				attachments: [],
				customers: [],
				orders: [],
				subjects: [],
				private: false,
				responsible: {}
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "updated_at",
					order: "desc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
			client_orders: [],
			responsible_users: [],
		};
	}
};
