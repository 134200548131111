<template>
	<div class="form-group" v-bind:class="{'required': required}">
		<label class="mb-1" v-if="label != ''">{{ label }} <i class="fas fa-asterisk"></i></label>
		<select class="form-control" ref="model" :value="value" @input="updateDate()" :required="(as_variation) ? false : required" :validation-rules="rules" :disabled="as_variation && checkbox">
			<option :value="options_none.id" v-if="options_none.id != undefined">{{ options_none.label }}</option>
			<option value="" v-else></option>
			<option :value=opcao.id v-for="opcao in options" :key=opcao.id>{{ (opcao.label) ? opcao.label : opcao.name }}</option>
		</select>
		<FormCheckbox label="Usar como Variação?" :index=id :checked=as_variation :disabled=disabled v-if="checkbox" />
		<small class="form-text text-muted mt-2" v-if="info != ''">{{ info }}</small>
	</div>
</template>

<script>

import FormCheckbox from "./../../common/form/Checkbox";

export default {
	name: "Select",
	props: {
		required: {
			type: Boolean,
			default: true
		},
		label: {
			type: String,
			default: ""
		},
		info: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			default: null			
		},
		value: {			
			default: "",
		},
		rules: {
			type: String,
			default: ""
		},
		checkbox: {
			type: Boolean,
			default: false
		},
		as_variation: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: ""
		},
		options_none: {
			type: Object,
			required: false,
			default: () => {
				return {};
			}
		},
		type: {
			type: String,
			required: false,
			default: "string"
		},
		dataCheckout: {
			type: String,
			required: false,
			default: ""
		}
	},
	components: {
		FormCheckbox
	},
	data () {
		return {

		};
	},  
	created() {
	},
	provide: function () {
		return {
			checkIt: this.checkParent,
		};
	},
	inject: {
		checkIt: {
			// passa um default como uma função vazia pois o Header é usado em outros lugares que não usa a função save()
			default: () => {}
		},
	},
	methods: {		
		updateDate() {		
			let value = this.$refs.model.value;
			if(this.type == "number" && typeof value == "string"){
				value = parseInt(value);
			}	
			if(this.type == "boolean" && typeof value == "string"){
				if(value == "false"){
					value = false;
				}else{
					value = true;
				}
			}	
			this.$emit("input", value);
		},	
		checkParent(e) {
			this.checkIt(e);
		}
	},
	mounted() {
		if(this.dataCheckout != ""){
			this.$refs.model.setAttribute("data-checkout", this.dataCheckout);
		}		
	}
};
</script>

<style scoped lang="scss">	
	@import '~@/assets/css/form.scss';
</style>
