<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao :is_order="false" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row" v-if="alert">
			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações do Alerta
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Tipo:</strong> {{ alert.type }} </li>
							<li><strong>ID:</strong> {{ alert.document_id }} </li>
							<li v-if="users.length > 0"><strong>Responsáveis:</strong> 
								<template v-for="(res, key) in users">
									<template v-if="key > 0">,</template>
									{{ res.name }}
								</template>
							</li>
							<li><strong>Criada em:</strong> {{ alert.created_at | date_time }} </li>							
							<li><strong>Mensagem:</strong> <span v-html="alert.msg"></span> </li>														
						</ul>
						<button class="btn btn-danger my-3 mx-3" @click="removeAlert">Clique para remover este alerta da fila</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Filters from "../../../common/filters";
import alerts from "../../../common/alerts";


export default {
	name: "AlertsView",
	components: {
		Header,
		Breadcrumb,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Informações do Alerta",
				chamada: "Gerencie os alertas da sua loja",
				links: {
					back: "alerts.lista",
				}
			},
			breadcrumb_info: [				
				{
					name: "Alertas",
					link: "/alerts"
				},
				{
					name: "Ver",
					link: ""
				}
			],
			permissao: [],
			alert: null,
			users: []		
		};
	},  
	methods: {		
		loadData() {
			let self = this;		
			this.$store.dispatch("alerts/load", this.$route.params.id).then(response => {
				if(response){
					self.alert = response;
					// pega os usuários 
					self.$store.dispatch("usuarios/load_users_by_id", response["users"]).then(res => {
						self.users = res;
					});
				}else{
					alerts.alertTop("error","Houve um problema ao carregar as informações");
				}
				
			});		
		},
		removeAlert() {
			let self = this;
			let event_confirm = () => {
				self.$store.dispatch("alerts/excluir_registro", self.alert.id );
				self.$router.push({
					name: "alerts.lista"
				});
				return true;
			};
			alerts.alertConfirm("Tem certeza que deseja excluir este alerta?",event_confirm);	
			
		}
	},
	filters: Filters,
	provide: function () {
		return {
		};
	},
	created() {
		this.loadData();
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	
</style>
