<template>
	<div class="form-group" v-bind:class="{'required': required, 'component_required': (['date', 'datetime'].includes(type))}" :required="(['date', 'datetime'].includes(type) && required)" :validation-rules="(['date', 'datetime'].includes(type)) ? 'flatpickr' : null" :valor="(['date', 'datetime'].includes(type)) ? valueTratado : null">
		<label class="mb-1" v-if="label != ''">{{ label }} <i class="fas fa-asterisk"></i></label>
		<div class="input-group">
			<div class="input-group-prepend" v-if="position == 'left'">
				<span class="input-group-text" v-if="text != ''">{{ text }}</span>
				<button class="btn btn-secondary btn-date" v-if="icon != ''" :data-toggle="['date', 'datetime'].includes(type)"><i :class="icon" ></i></button>				
				<button class="btn btn-secondary" v-if="action_button != ''" v-on:click="actionButton(action_button)">{{ action_button }}</button>
			</div>
			<input type="text" class="form-control" :disabled=disabled :placeholder="placeholder" v-if="type == 'text'" ref="model" :value="valueTratado" @input="updateDate()" :required=required :validation-rules="rules" />
			<input type="text" class="form-control" :disabled=disabled :placeholder="placeholder" v-if="['postalcode', 'phone', 'cellphone'].includes(type)" ref="model" :value="valueTratado" @input="updateDate()" v-mask="pegaMascara()"  :required=required :validation-rules="rules" />
			<input type="text" class="form-control" :disabled=disabled :placeholder="placeholder" v-if="['phones'].includes(type)" ref="model" :value="valueTratado" @input="updateDate()" v-mask="['(##)#####-####','(##)####-####']"  :required=required :validation-rules="rules" />
			<input type="text" class="form-control" :disabled=disabled :placeholder="placeholder" v-if="type == 'money'" ref="model" :value="valueTratado" v-money="money" @blur="updateDate()" :required=required :validation-rules="rules" />
			<flat-pickr
				v-if="['date', 'datetime'].includes(type)"								
				:config="options_calendar"                                                          
				class="form-control"
				v-bind:class="'flat_input_'+index"
				v-model.lazy="date_value">
			</flat-pickr>
			<div class="input-group-append" v-if="position == 'right'">
				<span class="input-group-text" v-if="text != ''">{{ text }}</span>
				<button class="btn btn-secondary btn-date" v-if="icon != ''" :data-toggle="['date', 'datetime'].includes(type)"><i :class="icon" ></i></button>				
				<button class="btn btn-secondary" v-if="action_button != ''" v-on:click="actionButton(action_button)">{{ action_button }}</button>
			</div>
		</div>
		<small class="form-text text-muted mt-2" v-if="info != ''">{{ info }}</small>
	</div>
</template>

<script>

import {mask} from "vue-the-mask";
import {VMoney} from "v-money";
import flatPickr from "vue-flatpickr-component";
import lang from "./../../../../node_modules/flatpickr/dist/l10n/pt";

export default {
	name: "InputGroup",
	components: {
		flatPickr
	},
	directives: {
		mask,
		money: VMoney
	},
	props: {		
		required: {
			type: Boolean,
			default: true,			
		},
		rules: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: "",			
		},
		info: {
			type: String,
			default: "",			
		},
		placeholder: {
			type: String,
			default: "",			
		},
		position: {
			type: String,
			default: "left", // left ou right
		},
		text: {
			type: String,
			default: "",
		},
		type: {
			type: String,
			default: "text",			
		},
		value: {			
			default: "",
		},
		icon: {
			type: String,
			default: "",
		},
		index: {
			type: Number,
			default: 0,
		},
		disabled: {
			type: Boolean,
			default: false
		},
		action_button: {
			type: String,
			default: "",
		}	

	},
	computed: {
		valueTratado() {
			if(this.type == "money"){
				let valor = this.value;
				if(typeof valor == "number"){
					valor = valor.toFixed(2);
				}
				valor = valor.replace(".", ",").replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
				return valor;
			}else{
				return this.value;
			}
		},
		options_calendar() {
			let config;
			if(this.type == "date"){
				config = {
					altInputClass: "input_calendar input_calendar_form",
					enableTime: false,
					enableSeconds: false,
					altFormat: "d/m/Y",
					altInput: true,
					dateFormat: "Y-m-d",
					time_24hr: true,
					locale: lang.pt,
					allowInput: false,
					placeholder: "00/00/0000",
					wrap: true
				};
			}else{
				config = {
					altInputClass: "input_calendar input_calendar_form",
					enableTime: true,
					enableSeconds: true,
					altFormat: "d/m/Y H:i:S",
					altInput: true,
					dateFormat: "Y-m-d H:i:S",
					time_24hr: true,
					locale: lang.pt,
					allowInput: false,
					defaultHour: "00",
					placeholder: "00/00/0000",
					wrap: true
				};
			}

			return config;
			
		},
	},
	data () {
		return {
			masks: [
				{
					type: "postalcode",
					mask: "#####-###"
				},
				{
					type: "phone",
					mask: "(##)####-####"
				},
				{
					type: "cellphone",
					mask: "(##)#####-####"
				},
				{
					type: "int",
					mask: "#############"
				}
			],
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "",
				suffix: "",
				precision: 2,
				masked: false
			},
			date_value: ""
		};
	},  
	created() {

	},
	updated(){
		if(["date", "datetime"].includes(this.type)){
			this.date_value = this.valueTratado;
		}		
	},
	methods: {		
		updateDate(valor) {			
			let value;
			if(valor == undefined){
				value = this.$refs.model.value;
			}else{
				value = valor;
			}
			if(this.type == "money"){
				value = value.replace(/\./g,"");
				value = value.replace(",",".");
				value = parseFloat(value);
			}
			this.$emit("input", value);
		},
		pegaMascara: function(){
			let type = this.type;
			return this.masks.filter((mask) => {
				return mask.type == type;
			})[0].mask;
		}
	},
	inject: {
		actionButton: {
			default: () => {}
		},
	},
	watch: {
		date_value: function(val){
			if(val != undefined){				
				this.updateDate(val);
			}			
		}
	}
};
</script>

<style scoped lang="scss">	
	
	@import '~@/assets/css/form.scss';
	@import "./../../../../node_modules/flatpickr/dist/flatpickr.css";
	@import "./../../../../node_modules/flatpickr/dist/themes/material_green.css";

	.input-group-prepend{
		span{
			font-size: 0.9em;			
		}				
		button{
			&.btn-date{
				background-color: #e9ecef;		
				border-color: #ced4da;			
				i{
					color: #444444!important;
				}
			}			
		}		
	}
	.error{
		.input-group-prepend{
			button{
				&.btn-date{
					i{
						color: #FFF!important;
					}
				}			
			}
		}		
	}	
</style>
