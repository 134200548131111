export default {
	init: function() {
		return {
			model: {
				id: null,
				offer_type: 1,
				discount_type: 1,
				name: "",
				value: 0,
				placement_type: 1,
				final_date: "",
				initial_date: "",
				relation: [],
				schedules: [],
				status: 1,
				store_id: 0,
				target: "",
				margin_rules: [],
				categorias_selecionadas: [],
				marcas_selecionadas: [],
				produtos_selecionados: [],
				pages: [],
				pages_selecionados: [],
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "name",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
			schedules: [],
			categorias: [],
			margin_rules: []
		};
	}
};
