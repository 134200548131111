import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/usuarios";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		api.request("usuarios/loaded", "admin/user/" + id, "get");
	},
	save: function(context, data) {
		api.request("usuarios/update", "admin/user", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Usuário cadastrado com sucesso." : "Usuário atualizado com sucesso.");
				router.push({
					name: "usuarios.lista"
				});
			}
		});
	},
	update_ordem: function(state, data) {
		return api.request("usuarios/no_action", "admin/users/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.usuarios);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 
		let fields = [];
		config.usuarios.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		state.commit("clear_valores");
		api.request("usuarios/data_valores_loaded", "admin/users", "post", { fields: fields, "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {				
		let data = {};
		data[dados.field] = dados.value;
		return api.request("usuarios/no_action", "admin/users", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("usuarios/acao_massa", "admin/users", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{		
		return api.request("usuarios/acao_massa", "admin/users", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("usuarios/no_action", "admin/user/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("usuarios/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("usuarios/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("usuarios/delete_filter", "admin/filter/"+id, "delete", id);
	},
	search_multiselect: function(state,dados){
		let text = dados.text.trim();
		// verificando se é uma busca por uma única palavra ou mais palavras
		let search = text.split(" ");
		let filters_default = [];
		filters_default.push(
			{
				field: "status", 
				operator: "=", 
				type: "integer", 
				value: 1
			}
		);
		if(dados.store_id){
			filters_default.push(
				{
					field: "stores", 
					operator: "in", 
					value: [dados.store_id]
				}
			);
		}		
			
		let filters = {};
		// busca somente titulo
		let _and = filters_default;
		// percorre cada palavra
		search.forEach(item => {
			_and.push({
				field: "name", 
				operator: "like", 
				type: "string", 
				value: item
			});
		});
		// monta o filtro
		filters = _and;
		return api.request("usuarios/no_action", "admin/users", "post", { "filters": filters, "sort": "name,asc", "limit": 10, "page": 1 }).then(response => {
			response = response.body;
			let _dados = [];
			if(response.return_code == 1){
				response.data.items.forEach(item => {
					_dados.push({ id: item.id, name: item.name });
				});
				
			}
			return _dados;
		});
	},
	load_users: () => { 
		let fields = [
			"name",
			"id"
		];		

		return api.request("usuarios/no_action", "admin/users", "post", { fields: fields, "filters": [], "sort": "name,asc", "limit": 99999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},
	load_users_by_id: (state, users_ids) => { 
		let fields = [
			"name",
			"id"
		];		

		let filters = [
			{
				field: "_id", 
				operator: "in", 
				value: users_ids
			}
		];

		return api.request("usuarios/no_action", "admin/users", "post", { fields: fields, "filters": filters, "sort": "name,asc", "limit": 99999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},
	load_sellers: () => { 
		let fields = [
			"name",
			"id"
		];		

		let filters = [
			{"field":"seller","value":true,"operator":"="},
			{"field":"status","value":1,"operator":"="}
		];

		return api.request("usuarios/no_action", "admin/users", "post", { fields: fields, "filters": filters, "sort": "name,asc", "limit": 99999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},
	getTotalNotifications(state, user_id){
		return api.request("usuarios/no_action", "admin/users/notifications/" + user_id, "get").then(response => {
			if (response.body.return_code == 1){
				return response.body.data.notifications;
			}else{
				return 0;
			}
		});
	}
};