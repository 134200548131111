// import router from "./../../router";
import api from "./../../common/api";
// import alerts from "./../../common/alerts";
import model from "./../models/precos";
import config from "./../../assets/json/filters";
import alerts from "../../common/alerts";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	update: (state,prices) => {
		api.request("precos/no_action", "admin/products/prices", "put", prices).then(response => {
			if(response.body.return_code == 1){
				if(response.body.data.ok){
					alerts.alertTop("success","Preços Atualizados com Sucesso");
				}				
			}
		});
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.precos);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 
		let fields = [];
		config.precos.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		state.commit("clear_valores");		
		let order_field = data.opcoes.order_field;
		switch(data.opcoes.order_field){
		case "full_price":
			order_field = "prices.v.full_price";
			break;
		case "discount_real":
			order_field = "prices.v.discount_real";
			break;
		case "discount_percentage":
			order_field = "prices.v.discount_percentage";
			break;
		case "margin":
			order_field = "prices.v.margin";
			break;
		case "store_id":
			order_field = "prices.k";
			break;
		}
		api.request("precos/data_valores_loaded", "admin/products/prices", "post", { fields: fields, "filters": data.filtros, "sort": order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	get_filtros_salvos: (state,config) => {
		return api.request("precos/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("precos/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("precos/delete_filter", "admin/filter/"+id, "delete", id);
	},
};