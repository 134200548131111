<template>
	<div class="row" ref="clientes"> 
		<div class="col-12">
			<div class="input-group mb-0" v-if="model.id == null && !customer_form">
				<div class="input-group-prepend">
					<span class="input-group-text"><i class="fas fa-search"></i></span>
				</div>
				<input type="text" class="form-control" placeholder="Busque por um cliente (Nome, E-mail ou CPF)" v-model="search" v-on:keyup="pegaClientes($event)" v-on:focusin="exibir_clientes=true;" v-on:blur="exibir_clientes=false;" v-on:keyup.enter="seleciona()" v-on:keyup.38="navigateActive('up')" v-on:keyup.40="navigateActive('down')" />
				<div class="input-group-append">
					<button class="btn btn-success" type="button" v-on:click="novoCliente()">+ Novo Cliente</button>
				</div>
			</div>			
			<div class="dados" v-show="exibir_clientes">
				<ul class="list-group filtros-component-clientes" >
					<li class="list-group-item" v-for="dado in dadosFiltered()" :key=dado.id v-on:mousedown="seleciona(dado)" :id="dado.id" >
						<strong>{{ dado.name }}</strong>
						Documento: {{ dado.document }}<br />
						E-mail: {{ dado.email }}						
					</li>
					<li class="list-group-item" v-show="dadosFiltered().length == 0">{{ msg_search }}</li>
				</ul>
			</div>
			<div class="clientes" v-if="customer_form">
				<div class="row">
					<div class="col-6">
						<FormSelect :required=true  rules="option" label="Tipo" info="" :options="[{id: 1,label: 'Pessoa Física'}, {id: 2, label: 'Pessoa Jurídica'}]" v-model="model.type" type="number" />								
					</div>
					<div class="col-6">
						<FormInput :required=true name="document" rules="string" :label="(model.type == 1) ? 'CPF' : 'CNPJ'" placeholder="" info="" v-model="model.document" :type="(model.type == 1) ? 'cpf' : 'cnpj'" />
					</div>									
					<div :class="(model.type == 1) ? 'col-12' : 'col-6'">
						<FormInput :required=true name="name" rules="string" :label="(model.type == 1) ? 'Nome Completo' : 'Razão Social'" placeholder="" info="" v-model="model.name" :slug_function=false />
					</div>
					<div class="col-6" v-show="model.type == 2">
						<FormInput :required="(model.type == 2) ? true : false" rules="string" label="Nome Fantasia" placeholder="" info="" v-model="model.fancy_name" />
					</div>					
					<div class="col-6" v-show="model.type == 2">
						<FormInput :required=false rules="string" label="Inscrição Estadual" placeholder="" info="" v-model="model.ie" />
					</div>
					<div class="col-6">
						<FormSelect :required=true rules="option" label="Sexo" info="" :options="[{id: 'MF',label: 'Ambos'}, {id: 'F', label: 'Feminino'}, {id: 'M', label: 'Masculino'}]" v-model="model.gender" type="string" />
					</div>
					<div class="col-6" v-if="model.type == 1">
						<FormInputGroup :required=true rules="string" label="Data de Nascimento" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="model.birth" :index=0 />
					</div>
					<div class="col-8">
						<FormInput :required=true name="email" rules="string" label="E-mail" placeholder="" info="" v-model="model.email" />
					</div>
					<div class="col-4">
						<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
					</div>
					<div class="col-12">
						<AddOn ref="phones" state="clientes" title="Telefones do Cliente" type="phones" button_name="Novo Telefone" />
					</div>
					<div class="col-6 my-3">
						<button class="btn btn-sm btn-block btn-secondary" v-on:click="cancel()">Trocar Cliente</button>
					</div>
					<div class="col-6 my-3">
						<button class="btn btn-sm btn-block btn-success" v-on:click="save()">Salvar Cliente</button>
					</div>
				</div>	
			</div>
		</div>
	</div>	
</template>

<script>

import Filters from "./../../../../common/filters";
import validation from "./../../../../common/validation";
import Alerts from "./../../../../common/alerts";
import FormSelect from "./../../../common/form/Select";
import FormInputGroup from "./../../../common/form/InputGroup";
import FormInput from "./../../../common/form/Input";
import AddOn from "./../../../common/AddOn";

export default {
	name: "Clientes",
	props: {
		state: {
			type: String,
			required: true
		},
	},
	components: {
		FormSelect,
		FormInputGroup,
		FormInput,
		AddOn
	},
	computed: {		
		usuario() {
			return this.$store.state.app.usuario;
		},
		cart() {		
			return this.$store.state.pedidos.cart;
		},
		model() {
			return this.$store.state.clientes.model;
		},
		phones() {
			return this.$store.state.clientes.phones;
		},			
	},	
	data () {
		return {
			search: "",
			exibir_clientes: false,
			customer_form: false,
			dados: []
		};
	},  
	created() {
				
	},
	methods: {		
		navigateActive: function(direction){

			this.$nextTick(function () {
				let lista = document.getElementsByClassName("filtros-component-clientes")[0].children;
				let indice_maximo = lista.length-1;
				let indice_lista = 0;
				if(direction == "up"){
					for(let li=indice_maximo;li>=0;li--){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li > 0){
								indice_lista = li-1;	
							}
							break;
						}
					}
				}else{
					for(let li=0;li<=indice_maximo;li++){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li < indice_maximo){
								indice_lista = li+1;	
							}						
							break;
						}
					}
				}
				
				// aplica a classe
				lista[indice_lista].classList.add("active");				
			});			
		},
		novoCliente: function(){
			this.$store.dispatch("clientes/reset");
			this.customer_form = true;
		},		
		pegaClientes: function(ev){	
			let self = this;			

			// ESC - Limpa pesquisa
			if(ev.keyCode == 27){
				self.search = "";
			}

			// validando algumas teclas
			if([40,38,37,39,13,36,35,16,17].includes(ev.keyCode)){
				return;
			}			

			self.dados = [];
			if(self.search == ""){
				self.msg_search = "Digite no mínimo 4 letras para pesquisar";
			}else{
				self.msg_search = "Procurando ...";
			}

			let data = {
				text: self.search
			};

			clearTimeout(self.searchTime);
			self.searchTime = setTimeout(function(){ 
				if(self.search.length > 3){									
					self.$store.dispatch("clientes/filter_customers_data",data).then(response => {
						self.dados = response;
						if(self.dados.length == 0){
							self.msg_search = "Nenhum cliente encontrado para a sua pesquisa.";		
						}
					});
				}else if(self.search.length <= 3){
					self.dados = [];
					self.msg_search = "Digite no mínimo 4 letras para pesquisar";
				}
			}, 650);
		},
		dadosFiltered: function(){			
			let self = this;						
			let dados = Object.assign([],self.dados);					
			if(dados.length == 0 && self.search.length == 0){
				self.msg_search = "Digite no mínimo 4 letras para pesquisar";
			}

			// trata os dados
			let dados_tratados = [];
			if(dados.length > 0){
				dados.forEach(item => {
					dados_tratados.push({
						name: item.name,
						id: item.id,
						document: item.document,
						email: item.email
					});
				});				
			}

			return dados_tratados;			
		},
		seleciona: function(dado, setaPostalCodeByCustomer=true){			
			let self = this;
			let id;
			if(dado == undefined){
				let lista = document.getElementsByClassName("filtros-component-clientes")[0];									
				if(lista.children.length == 0){
					return;
				}				
				let ativo = lista.getElementsByClassName("active");				
				if(ativo.length > 0){
					id = ativo[0].getAttribute("id");
				}else{
					id = lista.children[0].getAttribute("id");
				}	
				// se pegar um li informativo ele ignora
				if(id == undefined){
					return;
				}						
			}else{
				id = dado.id;
			}

			// pega os dados
			this.$store.dispatch("clientes/reset");
			this.$store.dispatch("clientes/load", id).then(response => {
				if(response.return_code == 1){
					// popula com os dados vindo do banco
					self.$store.commit("clientes/addOnUpdateAll",{ tipo: "phones", itens: response.data.phones});
					// faz com que o component atualize o state com os dados já tratados
					self.$refs.phones.updateComponent();
					if (setaPostalCodeByCustomer) {
						self.setaPostalCodeByCustomer(response.data);
					}					
				}					
			});
			this.$store.dispatch("pedidos/addCliente",{
				cart_id: self.cart.id,
				customer_id: id
			});

			// exibe o form
			this.customer_form = true;
			this.search = "";
			this.dados = [];

		},
		cancel: function(){
			this.customer_form = false;
			this.$store.dispatch("clientes/reset");
		},
		save: function(){
			if(validation.validation(this.$refs.clientes).valid){			
				let model = Object.assign({},this.model);
				let valid = true;
				let self = this;

				// tratando os telefones 
				model.phones = [];
				this.phones.forEach(item => {
					if(typeof item == "object"){
						if(item.type.value != "" && item.number.value != ""){
							let number = item.number.value.replace(/[^0-9]/g,""); // deixa apenas os numeros
							model.phones.push({ type: item.type.value, number: number });
						}else{
							Alerts.alertTop("error","Preencha corretamente os Telefones do Cliente");
							valid = false;
						}
					}					
				});

				// tratando os stores				
				let store_id = this.cart.store_id;
				if(!model.stores.find(item => { return item == store_id; })){
					model.stores.push(this.cart.store_id);
				}					

				delete model.store_mundo;
				delete model.store_aventura;
				delete model.store_lexo;

				// marca que é um cadastro feito pelo pedido
				model.source = "order";

				// salva
				if(valid){
					this.$store.dispatch("clientes/save", model).then(response => {
						if(response.return_code == 1){
							self.$store.dispatch("pedidos/addCliente",{
								cart_id: self.cart.id,
								customer_id: response.data.id
							});
						}
					});
				}				
			}
		}
	},
	filters: Filters,
	mounted() {		
		
	},
	inject: {
		setaPostalCodeByCustomer: {
			// passa um default como uma função vazia pois o Header é usado em outros lugares que não usa a função save()
			default: () => {}
		},
	}
};
</script>

<style scoped lang="scss">	
	.row{
		.dados{
			position: relative;
			.list-group{
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				z-index: 10;
				li{
					font-size: 0.9em;
					line-height: 1.2em;
					&:hover, &.active, &.selected{
						background: $bg_destaque;
						color: #FFF;
						cursor: pointer;
						border:none;
					}
					strong{
						display: block;
						font-size: 1.2em;
						margin-bottom: 0.3em;
					}
				}
			}			
		}		
	}
</style>
