<template>
	<div class="row"> 
		<div class="col-12">
			<div class="form-group mb-0">
				<input type="text" class="form-control" placeholder="Busque pelo produto" v-model="search" v-on:keyup="pegaProdutos($event)" v-on:focusin="exibir_produtos=true;" v-on:blur="exibir_produtos=false;" v-on:keyup.enter="seleciona()" v-on:keyup.38="navigateActive('up')" v-on:keyup.40="navigateActive('down')" />
			</div>			
			<div class="dados" v-show="exibir_produtos">
				<ul class="list-group" :class="'filtros-component-produtos_'+tipo" >
					<li class="list-group-item" v-for="dado in dadosFiltered()" :key=dado.id v-on:mousedown="seleciona(dado)" :id="dado.id" >
						{{ dado.title }}
					</li>
					<li class="list-group-item" v-show="dadosFiltered().length == 0" v-html="msg_search"></li>
				</ul>
			</div>
			<div class="produtos mt-3">
				<div class="row produto" >
					<div class="col-3" v-for="(item,index) in selecionados" :key=index> 
						<div class="card">
							<img :src="`https://imagens.mundodacarabina.com.br${item.image}`" class="card-img-top" :alt="item.name" />
							<div class="card-body">
								<label class="mb-2">{{ item.product_name }}</label>
								<div class="skus p-2 mb-3" v-if="selectVariations">
									<span class="badge badge-success ml-1 px-2 mx-2 my-1" :class="(sku.use) ? 'valid' : 'ignore'" v-for="(sku,idx) in item.skus" :key=idx v-on:click="inativaSku(sku,item)">
										{{ sku.sku }} <i class="pl-2 fas fa-check"></i>
									</span>
								</div>
								<FormInput :required=false rules="string" label="Quantidade" placeholder="" info="" v-model="item.quantity" :slug_function=false v-if="item.hasOwnProperty('quantity')" type="number" />
								<FormInput :required=false rules="string" label="Quantidade Mínima" placeholder="" info="" v-model="item.min_quantity" :slug_function=false v-if="item.hasOwnProperty('min_quantity')" type="number" />
								<FormInput :required=false rules="string" label="Custo" placeholder="" info="" v-model="item.amount" :slug_function=false v-if="item.hasOwnProperty('amount')" type="money" />
								<button class="btn btn-danger btn-block btn-sm mt-2" v-on:click="removeProduto(item)">Remover</button>
							</div>
						</div>
					</div>
				</div>
			</div>						
		</div>
	</div>	
</template>

<script>

import FormInput from "./form/Input";

export default {
	name: "MultiSelectProdutos",
	props: {
		tipo: {
			type: String,
			required: true			
		},
		selectVariations: {
			type: Boolean,
			default: true
		},
		store_id: {
			type: Number,
			default: 0
		},
		needs_store: {
			type: Boolean,
			default: false
		},
		state: {
			type: String,
			required: true
		},
		extra_fields: {
			type: Array,
			required: false,
			default: () => {
				return [];
			}
		}
	},
	components: {
		FormInput
	},
	computed: {		
		selecionados() {						
			return this.$store.state[this.state].model[this.tipo];
		}		
	},	
	data () {
		return {
			exibir_produtos: false,
			search: "",			
			dados: [],
			searchTime: null,
			msg_search: "Digite no mínimo 4 letras para pesquisar"
		};
	},  
	created() {
				
	},
	methods: {		
		filterJson: function(item){
			return item.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
		},
		navigateActive: function(direction){

			this.$nextTick(function () {
				let lista = document.getElementsByClassName("filtros-component-produtos_"+this.tipo)[0].children;
				let indice_maximo = lista.length-1;
				let indice_lista = 0;
				if(direction == "up"){
					for(let li=indice_maximo;li>=0;li--){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li > 0){
								indice_lista = li-1;	
							}
							break;
						}
					}
				}else{
					for(let li=0;li<=indice_maximo;li++){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li < indice_maximo){
								indice_lista = li+1;	
							}						
							break;
						}
					}
				}
				
				// aplica a classe
				lista[indice_lista].classList.add("active");				
			});			
		},
		pegaProdutos: function(ev){	
			let self = this;

			// validando algumas teclas
			if([40,38,37,39,13,36,35,16,17].includes(ev.keyCode)){
				return;
			}

			self.dados = [];
			if(self.search == ""){
				self.msg_search = "Digite no mínimo 4 letras para pesquisar";
			}else{
				self.msg_search = "Procurando ...";
			}

			if(this.needs_store && !this.store_id > 0){
				self.msg_search = "Selecione uma loja para continuar a pesquisa.";				
				return;
			}
			
			let data = {
				text: self.search, 
				combo: false 
			};
			if(this.needs_store){
				data.store_id = this.store_id;
			}

			clearTimeout(self.searchTime);
			self.searchTime = setTimeout(function(){ 
				if(self.search.length > 3){									
					const ignored_skus = [];
					self.selecionados.forEach(item => {
						item.skus.forEach(dado => {
							ignored_skus.push(dado.sku);
						});						
					});
					data.ignored_skus = ignored_skus;
					self.$store.dispatch("produtos/filter_products_data",data).then(response => {
						self.dados = response;
						if(self.dados.length == 0){
							self.msg_search = "Nenhum produto encontrado para a sua pesquisa.";		
						}
					});
				}else if(self.search.length <= 3){
					self.dados = [];
					self.msg_search = "Digite no mínimo 4 letras para pesquisar";
				}
			}, 650);
		},
		dadosFiltered: function(){			
			let self = this;						
			let dados = Object.assign([],self.dados);
			// tira os já selecionados
			dados = dados.filter(item => {																				
				return self.selecionados.find(dado => { return item.id == dado.product_id; }) == undefined;
			});			
			if(dados.length == 0 && self.search.length == 0){
				self.msg_search = "Digite no mínimo 4 letras para pesquisar";
			}
			return dados;
		},
		seleciona: function(dado){
			if(dado == undefined){
				let lista = document.getElementsByClassName("filtros-component-produtos_"+this.tipo)[0];									
				if(lista.children.length == 0){
					return;
				}				
				let ativo = lista.getElementsByClassName("active");
				let id;
				if(ativo.length > 0){
					id = ativo[0].getAttribute("id");
				}else{
					id = lista.children[0].getAttribute("id");
				}	
				// se pegar um li informativo ele ignora
				if(id == undefined){
					return;
				}		
				dado = this.dados.find(item => {
					return item.id == id;
				});
			}

			// pegando a imagem
			let imagem = "";
			if(dado.variations.length > 0 && dado.galleries.length > 0 && dado.variations[0].gallery_id != ""){
				let gallery = dado.galleries.find(item => {
					return item.id == dado.variations[0].gallery_id;
				});
				if(gallery){					
					gallery.pictures.sort(function (a, b) {
						if (a.order > b.order) {
							return 1;
						}
						if (a.order < b.order) {
							return -1;
						}
						// a must be equal to b
						return 0;
					});					
					if(gallery.pictures[0]){
						imagem = gallery.pictures[0].url+"?size=300";
					}				
				}
				
			}

			let product_data = {
				product_id: dado.id,
				product_name: dado.title,								
				image: imagem,
				skus: dado.variations.map(item => {
					return {
						sku: item.sku,
						supplier_code: item.supplier_code,
						id: item.id,
						use: true
					};
				})
			};
			if(this.extra_fields.includes("quantity")){
				product_data["quantity"] = 1;
			}
			if(this.extra_fields.includes("amount")){
				product_data["amount"] = 0.01;
			}
			if(this.extra_fields.includes("min_quantity")){
				product_data["min_quantity"] = 1;
			}

			this.$store.commit(this.state + "/selectProduct",{ tipo: this.tipo, item: product_data });
			this.search = "";			

		},
		inativaSku: function(sku,produto){
			let produtos = this.selecionados;
			produtos.forEach(item => {
				if(item.product_id == produto.product_id){
					item.skus.forEach(dado => {
						if(dado.id == sku.id){
							dado.use = !sku.use;
						}
					});
				}				
			});
			this.$store.commit(this.state + "/updateProducts",{ tipo: this.tipo, item: produtos });
		},
		removeProduto: function(produto){
			let produtos = this.selecionados;
			let index = produtos.indexOf(produto);
			produtos.splice(index, 1);
			this.$store.commit(this.state + "/updateProducts",{ tipo: this.tipo, item: produtos });
		},
	},
	mounted() {
		
	}
};
</script>

<style scoped lang="scss">	
	.row{
		.dados{
			position: relative;
			.list-group{
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				z-index: 10;
				li{
					font-size: 0.95em;
					&:hover, &.active, &.selected{
						background: $bg_destaque;
						color: #FFF;
						cursor: pointer;
						border:none;
					}
				}
			}			
		}		
		.produtos{
			.skus{
				background: $bg_gray;
				span{
					cursor: pointer;
					&:hover{
						background: $bg_gray_medium!important;
					}
					&.ignore{
						background: $bg_gray_medium!important;
						&:hover{
							background: $color_green!important;
						}
					}
				}
			}
		}	
	}
	
</style>
