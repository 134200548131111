<template>
	<div class="card card-view card-base">
		<div class="card-header">
			<slot name="header"></slot>
			<i class="fas fa-sync-alt ml-3" aria-hidden="true" @click="refresh" v-if="allowRefresh"></i>
		</div>
		<div class="card-body">
			<slot name="default"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "Card",
	props: {
		// If true, shows the 'refresh data' button.
		allowRefresh: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		refresh: function () {
			this.$emit("card:refresh");
		}
	}
};
</script>

<style scoped lang="scss">
.card-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	& > i:hover {
		cursor: pointer;
		opacity: 0.9;
	}
}
</style>
