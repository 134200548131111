<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao :is_order="false" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />	

		<div class="row " v-if="ticket.id">
			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações do Ticket
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Protocolo:</strong> {{ ticket.protocol_number }} </li>
							<li><strong>Loja:</strong> {{ ticket.store_id | store }} </li>
							<li :class="{ 'bg-danger': (ticket.status == 0) }"><strong>Status:</strong> {{ (ticket.status == 0) ? 'Finalizado' : 'Aberto' }} </li>
							<li><strong>Assunto:</strong> {{ ticket.subject }} </li>
							<li v-if="ticket.order != null">
								<strong>Pedido: </strong> 
								<router-link :to="{ name: 'pedidos.ver', params: { id: ticket.order.id } }">{{ ticket.order.number }}</router-link>					
								<button class="btn btn-primary btn-sm ml-4" @click="inDevolution()"><i class="fas fa-check mr-1" style="display: inline-block"></i> marcar como em devolução</button>
							</li>							
							<li><strong>Nome do cliente:</strong> {{ ticket.customer.name }} </li>
							<li><strong>E-mail do cliente:</strong> {{ ticket.customer.email }} </li>							
							<li><strong>Aberto em:</strong> {{ ticket.created_at | date_time }} </li>
							<li><strong>Última atualização em:</strong> {{ ticket.updated_at | date_time }} </li>
							<li v-if="ticket.status == 0"><strong>Tags:</strong> <template v-for="tag in ticket.tags">{{ tag.name }}, </template> </li>
							<li v-if="ticket.status == 0"><strong>Responsável:</strong> {{ ticket.responsible.name }}</li>
							<li><strong>Modo:</strong> 
								<select class="form-control my-2" v-model="ticket.private" v-on:change="alteraModo()">									
									<option :value="false">Acesso público</option>
									<option :value="true">Acesso privado</option>
								</select>														
							</li>
						</ul>
						<div class="col-12 mt-3"  v-if="ticket.status == 1">
							<div class="form-group"> 
								<label class="mb-1">Tags</label>
								<MultiSelect state="tickets" tipo="tags" :dados="tags" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true @input="tagUpdated"></MultiSelect>
							</div>
						</div>

						<div class="col-12 mt-3"  v-if="ticket.status == 1">
							<div class="form-group required"> 
								<label class="mb-1">Responsável pelo Ticket<i class="fas fa-asterisk"></i></label>
								<MultiSelect state="tickets" tipo="responsible" :dados="responsible_users" :field_required=false :multiple=false :required=true :excluir_validation=false></MultiSelect>
							</div>
						</div>	
					</div>
				</div>

				<div class="card card-view card-base my-3 formulario-dados" v-if="ticket.status == 1">
					<div class="card-header">
						Nova Mensagem
					</div>
					<div class="card-body p-0 py-3">
						<div class="col-12">
							<FormSelect :required=true rules="option" label="Destinatário" info="" :options="[{id: 'customer', label: 'Cliente e Loja'}, {id: 'store',label: 'Somente Loja'}]" v-model="message.receiver" type="string" />		
						</div>	

						<div class="col-12">
							<FormTextarea :required=true label="Mensagem" v-model="message.message" :rows=3 placeholder="Digite aqui seu texto" info="" type="textarea" />
						</div>

						<div class="col-12">
							<FormSelect :required=true rules="option" label="SLA" info="" :options="[{id: 'urgent',label: 'Urgente'}, {id: 'moderate', label: 'Moderado'}, {id: 'normal', label: 'Normal'}, {id: 'weekly', label: 'Semanal'}]" v-model="message.sla" />		
						</div>

						<div class="col-12">
							<div class="form-group required"> 
								<label class="mb-1">Assunto<i class="fas fa-asterisk"></i></label>
								<MultiSelect state="tickets" tipo="subjects" :dados="subjects" :required=true :field_required=false :insert=false :multiple=false :excluir_validation=false></MultiSelect>
							</div>
						</div>
						<div class="row m-0">
							<div class="col-6">
								<FileUpload
									ref="upload"
									v-model="defectFiles"
									class="btn btn-info btn-sm"
									post-action="/"
									extensions="png,jpg,jpeg"
									accept="image/png,image/jpeg"
									:multiple="true"
									:size="1024 * 1024 * 10"
									:disabled="ticketSending"
									@input-filter="uploadFilter"
								>
									Incluir anexos
								</FileUpload>
							</div>
							<div class="col-6 text-right">
								<button class="btn-success btn btn-sm" v-on:click="salvarMsg()">Enviar Mensagem</button>
							</div>								
						</div>						
						
						<div
							v-if="defectFiles && defectFiles.length"
							class="attachments col-12"
						>
							<p class="my-3"><strong>Anexos selecionados</strong></p>

							<ul>
								<li v-for="file of defectFiles" :key="file.id" class="p-2 my-2">
									<img
									v-if="file.thumb && file.thumb !== 'arquivo'"
									:src="file.thumb"
									alt="Pré-visualização da imagem"
									class="align-middle"
									/>
									<i class="far fa-file-pdf align-middle" v-if="file.thumb && file.thumb === 'arquivo'"></i>
									<span class="align-middle mx-3">{{ file.name }}</span>

									<button class="btn btn-sm btn-danger align-middle" @click="removeFile(file)">
									<i class="fas fa-trash-alt"></i>
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<ul class="list-group msgs-ticket">
					<li class="list-group-item p-0 open" v-for="(msg,i) in ticket.messages" :key=i>
						<label class="p-2">
							<strong class="px-1">{{ msg.sla | sla }}</strong> em {{ msg.created_at | date_time }} 
							<span v-if="!msg.read" class="px-2">
								novo 								
							</span>
						</label>
						<div class="m-3">
							<p class="mb-2" v-html="nl2br(msg.message)"></p>
							<p v-if="msg.extra" class="extra">
								<span v-if="msg.extra.defect_product" class="mb-2"
								><strong>Produtos: </strong><br />
								{{ msg.extra.defect_product.join(', ') }}</span
								>
								<span v-if="msg.extra.defect_description" class="mb-2"
								><strong>Descrição: </strong>
								<span v-html="nl2br(msg.extra.defect_description)"></span
								></span>
								<span v-if="msg.extra.defect_resolution" class="mb-2"
								><strong>Desejo: </strong>
								<span v-html="nl2br(msg.extra.defect_resolution)"></span
								></span>
							</p>
							<!-- privacidade da mensagem -->
							<span v-if="msg.receiver == 'customer'" class="my-1"><strong>Privacidade:</strong> Loja e Cliente</span>
							<span v-else class="my-1"><strong>Privacidade:</strong> Somente para Loja</span>
							<!-- Criado por -->
							<span v-if="msg.responsible.id" class="my-1"><strong>Criado por:</strong> {{ msg.responsible.name }}</span>
							<span v-else class="my-1"><strong>Criado por:</strong> Cliente</span>
							<!-- Visto por -->
							<span v-if="msg.read_by" class="my-1"><strong>Visto por:</strong> {{ msg.read_by.name }} em {{ msg.read_at | date_time }}</span>
							<!-- Attachments -->
							<span v-if="msg.attachments && msg.attachments.length > 0"><strong>Anexos:</strong></span>
							<ul v-if="msg.attachments && msg.attachments.length > 0">
								<li v-for="(file,index) in msg.attachments" :key=index class="my-2">
									<a
										v-if="
										!getting_attachment || getting_attachment !== file
										"
										:href="file.url || null"
										target="_blank"
										rel="noopener noreferrer"
										@click.prevent="showAttachment(file)"
									>
										{{ file.name || `Anexo ${index + 1}` }}</a
									>
								</li>
							</ul>
							<button v-if="!msg.read" class="btn-success btn btn-sm mt-2" v-on:click="mark_message_as_read(msg)" alt="Marcar como lida"><i class="fa fa-envelope-open-text"></i> Marcar como lida</button>
						</div>
					</li>							
				</ul>
			</div>


			
		</div>

	</div>
</template>

<script>

import modelTicket from "./../../../store/models/tickets";
import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Filters from "./../../../common/filters";
import FormSelect from "./../../common/form/Select";
import FormTextarea from "./../../common/form/Textarea";
import {Slug} from "./../../../common/utils";
import MultiSelect from "./../../common/MultiSelect";
import validation from "./../../../common/validation";
import alerts from "../../../common/alerts";
import FileUpload from "vue-upload-component/src/FileUpload.vue";

export default {
	name: "TicketsView",
	components: {
		Header,
		Breadcrumb,
		FormSelect,
		FormTextarea,
		MultiSelect,
		FileUpload,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		ticket() {			
			return this.$store.state.tickets.model;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Visão Geral do Ticket",
				chamada: "Gerencie os tickets da sua loja",
				links: {
					back: "tickets.lista",
					finish: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Tickets",
					link: "/tickets"
				},
				{
					name: "Ver",
					link: ""
				}
			],
			permissao: [],
			msg_open: [],
			message: null,
			subjects: [],
			tags: [],
			responsible_users: [],
			responsavel_atual: null,
			getting_attachment: null,
			ticketSending: false,
			defectFiles: [],
		};
	},  
	methods: {		
		mark_message_as_read(msg) {
			if(!msg.read) {
				// primeira leitura
				this.$store.dispatch("tickets/update_read_message", msg);
			}
		},
		new_message() {
			const message = modelTicket.init();
			return message.model.messages[0];
		},
		salvarMsg() {
			const self = this;
			if(validation.validation().valid){
				
				const ticket_msg = {
					ticket_id: this.ticket.id,
					message: {
						receiver: this.message.receiver,
						message: this.message.message,
						sla: this.message.sla,
						responsible: {
							id: this.usuario.id,
							name: this.usuario.name
						}
					},
					attachments: this.defectFiles
						? this.defectFiles.map((el) => el.file)
						: []
				};

				// verificando se precisa trocar o assunto
				let subject = null;				
				if(this.ticket.subjects.name && this.ticket.subjects.name !== this.ticket.subject){
					subject = this.ticket.subjects.name;
				}

				// salva a mensagem
				this.$store.dispatch("tickets/new_message", ticket_msg).then(response => {
					if(response.body.return_code == 1){
						alerts.alertTop("success", "Mensagem cadastrada com sucesso");
						self.defectFiles = [];
					}
					if(subject){
						self.$store.dispatch("tickets/updateTicket", {
							id: self.ticket.id,
							subject: subject
						}).then(response => {
							if(response.return_code == 1){
								alerts.alertTop("success", "Assunto alterado com sucesso");
							}
						});
						
					}
				});
				// this.msg_open = null;
				this.message = this.new_message();
			}
		},
		updateDadosMultiselect: function(objeto,tipo){	
			// criando o slug
			if(!objeto.slug){
				objeto.slug = Slug(objeto.name);
			}
			// criando o id a partir do slug pois o componente obriga ter um id válido
			if(!objeto.id){
				objeto.id = objeto.slug;
			}
			// inclui dentro do data para pesquisa			
			if(tipo != "tags"){
				this[tipo].push(objeto);
				// seleciona já como a opção escolhida pelo cliente
				this.$store.commit("tickets/addTag",{item: objeto, tipo: tipo});
			}			
		},
		tagUpdated(objeto) {	
			let self = this;
			let tags = Object.assign([], this.ticket.tags);
			switch(objeto.type){
			case "deleted":
				tags = objeto.itens;
				break;
			case "new":
			case "selected":
				tags.push({
					name: objeto.item.name,
					slug: objeto.item.id || Slug(objeto.item.name)
				});
				break;
			}
			this.$store.dispatch("tickets/updateTicket", {
				id: this.ticket.id,
				tags: tags
			}).then(response => {
				if(response.return_code == 1){
					self.$store.commit("tickets/loaded", response);
					alerts.alertTop("success", "Tag alterada com sucesso");
				}
			});
		},
		finish() {
			let self = this;
			let event_confirm = () => {
				self.$store.dispatch("tickets/updateTicket", {
					id: self.ticket.id,
					status: 0
				}).then( response => {
					if(response.return_code == 1){
						alerts.alertTop("success", "Ticket finalizado com sucesso");						
						if(self.header_info.links.finish){
							self.header_info.links.finish = null;
						}	
					}
				});
				return true;
			};
			if(self.ticket.subject == "Entrega em Atraso"){
				let dados = {
					title: "Tem certeza que deseja finalizar esse ticket? ",
					body: [{
						type: "label",
						text: "ATENÇÃO: Não esqueça de inserir os custos extras no pedido ao encerrar este ticket."
					}]
				};
				alerts.alertConfirm(dados,event_confirm);		
			}else{
				alerts.alertConfirm("Tem certeza que deseja finalizar esse ticket?",event_confirm);		
			}
		},
		async showAttachment(attachment) {
			if (this.getting_attachment && this.getting_attachment === attachment) {
				return false;
			}
			this.getting_attachment = attachment;
			try {
				const response = await this.$store.dispatch("tickets/getAttachment",attachment);
				if (!response) return;
				const a = document.createElement("a");
				a.href = `data:${response.mimetype};base64,${response.content.$binary.base64}`;
				a.download = attachment.name;
				a.click();
				this.getting_attachment = null;
			} catch (error) {
				this.getting_attachment = null;
			}
			return false;
		},
		uploadFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				if (
					!newFile.file ||
					!!newFile.error ||
					/^\.(gif|jpe?g|png|webp|pdf)$/i.test(newFile.name) ||
					(newFile.type.substr(0, 6) !== "image/" &&
						/^application\/(wps-office.pdf|pdf)$/i.test(newFile.name))
				) {
					return prevent();
				}
			}
			if (
				newFile &&
				!newFile.error &&
				newFile.file &&
				(!oldFile || newFile.file !== oldFile.file)
			) {
				newFile.blob = "";
				const URL = window.URL || window.webkitURL;
				if (URL) {
					newFile.blob = URL.createObjectURL(newFile.file);
				}
				newFile.thumb = "arquivo";
				if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
					newFile.thumb = newFile.blob;
				}
			}
		},
		removeFile(file) {
			this.$refs.upload.remove(file);
		},
		alteraModo() {
			this.$store.dispatch("tickets/updateTicket", {
				id: this.ticket.id,
				private: this.ticket.private
			}).then(response => {
				if(response.return_code == 1){
					alerts.alertTop("success", "Modo do ticket alterado com sucesso");
				}
			});
		},
		nl2br(str) {
			return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br />$2");
		},
		inDevolution() {
			let self = this;
			let event_confirm = () => {
				self.$store.dispatch("tickets/update_status_order",{
					order_id: self.ticket.order.id,
					status_id: "61afa102116a5e427ff62e3f",
					user_id: self.usuario.id,
					data: []
				}).then( response => {	
					if(response.return_code == 1){
						alerts.alertTop("success", "Status de pedido alterado com sucesso");						
					}
				});

				return true;
			};
			
			alerts.alertConfirm("Tem certeza que deseja alterar o status desse pedido para \"Em Devolução\"?",event_confirm);		
		}
	},
	filters: Filters,
	provide: function () {
		return {
			updateDadosMultiselect: this.updateDadosMultiselect,
			finish: this.finish
		};
	},
	created() {	
		let self = this;	
		if(this.permissoes.permissoes) {
			this.permissao = this.permissoes.permissoes[this.config.modulo_permissao];
		}
		this.$store.dispatch("tickets/reset");
		let startingPromises = [ 
			this.$store.dispatch("tickets/load_subjects").then(response => { // pega os assuntos
				self.subjects = []; 			
				response.forEach((item,i) => {
					self.subjects.push({
						id: `${i}-${Slug(item)}`,
						name: item
					});
				});					
			}),
			this.$store.dispatch("tickets/load_tags").then(response => { // pega as tags
				self.tags = []; // cria um array vazio para tags
				response.forEach((item,i) => {
					self.tags.push({
						id: `${i}-${item.slug}`,
						name: item.name
					});
				});	
			}),
			this.$store.dispatch("tickets/load_responsible_users").then(response => { // pega os users
				self.responsible_users = []; 
				if(response.items){
					response.items.forEach(item => {
						self.responsible_users.push({
							id: item.id,
							name: item.name
						});
					});
				}				
			})
		];
		Promise.all(startingPromises).then(() => {
			self.$store.dispatch("tickets/load", this.$route.params.id).then(response => {
				if(response.body.return_code == 1){
					if(response.body.data.status == 0){
						if(self.header_info.links.finish){							
							self.header_info.links.finish = null;
						}						
					}
					self.$store.commit("tickets/multiselect_push", {item: {
						id: Slug(response.body.data.subject),	
						name: response.body.data.subject,
					}, tipo: "subjects", multiple: false});

					response.body.data.messages.forEach((item,index) => {
						if(item.read){
							this.msg_open.push(index);
						}						
					});
				}
			});		
		});		
		this.message = this.new_message();

	},
	watch: {
		"ticket.responsible": function(dado){
			let self = this;
			if(dado.id && this.responsavel_atual == null){
				this.responsavel_atual = dado.id;
			}else{
				if(dado.id !== undefined && dado.id !== null){
					if(this.responsavel_atual != dado.id){
						// alterou o responsável
						this.responsavel_atual = dado.id;
						self.$store.dispatch("tickets/updateTicket", {
							id: self.ticket.id,
							responsible: dado
						}).then(response => {
							if(response.return_code == 1){
								alerts.alertTop("success", "Responsável alterado com sucesso");
							}
						});
					}
				}				
			}
		}
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
	.card-view {
		.card-body {
			ul {
				li.bg-danger {
					color: #FFF;
				}
			}
		}
	}
	.msgs-ticket {
		li{
			label {
				background: $bg_destaque;
				border-radius: 5px 5px 0 0;
				display: block;
				color: #FFF;
				cursor: pointer;
				strong {
					color: #FFF;
					text-decoration: underline;
				}
				span {
					background: $color_red;
					float: right;
				}
			}
			div {
				display: none;
				span {
					font-size: 0.85em;
					display: block;
				}
			}
			&.open {
				div {
					display: block;
				}
			}
		}
		.extra {
			span {
				font-size: 1em;
			}
		}
		
	}
	.attachments {
		ul {
			li {
				background: #FFF !important;
				border-bottom: 1px solid #e2e2e2;
				img {
					max-width: 5em;
				}
				i.fa-file-pdf {
					display: inline-block;
					width: 1.7em;
					font-size: 3em;
					margin: 0 auto;
					text-align: center;
				}
			}
		}
	}
</style>
