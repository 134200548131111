import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/tickets";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function (state, id) {
		return api.request("tickets/loaded", "ticket/" + id, "get");
	},
	save: function (context, ticket) {

		const data = new FormData();

		data.append("protocol_number", ticket.protocol_number);
		data.append("id", ticket.id);
		data.append("source", ticket.source);
		data.append("private", ticket.private);
		data.append("order", JSON.stringify(ticket.order));
		data.append(
			"customer",
			JSON.stringify(ticket.customer),
		);
		data.append("subject", ticket.subject);
		data.append("tags", JSON.stringify(ticket.tags));
		data.append("store_id", ticket.store_id);
		data.append("status", ticket.status);
		ticket.attachments.forEach((attachment) => {
			data.append("attachments", attachment);
		});
		data.append(
			"messages",
			JSON.stringify(ticket.messages),
		);
		data.append("responsible", JSON.stringify(ticket.responsible));

		api.request("tickets/loaded", "ticket", (ticket.id == null) ? "post" : "put", data, null, false , null, true, true, true).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Ticket cadastrado com sucesso." : "Ticket atualizado com sucesso.");
				router.push({
					name: "tickets.lista"
				});
			}
		});
	},
	update_ordem: function (state, data) {
		return api.request("tickets/no_action", "admin/tickets/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.tickets);
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state, data) => {
		let fields = [];
		config.tickets.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		state.commit("clear_valores");
		api.request("tickets/data_valores_loaded", "admin/tickets", "post", { fields: fields, "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state, pagina) => {
		state.commit("altera_pagina", pagina);
	},
	// altera o limit da listagem 
	altera_limit: (state, limit) => {
		state.commit("altera_limit", limit);
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state, id) => {
		state.commit("select_listagem", id);
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state, ids) => {
		state.commit("select_listagem_all", ids);
	},
	// método switch na linha da listagem
	switch_listagem: (state, dados) => {
		let data = {};
		data[dados.field] = dados.value;
		return api.request("tickets/no_action", "admin/tickets", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state, dados) => {
		let data = {};
		data[dados.field] = dados.value;
		return api.request("tickets/acao_massa", "admin/tickets", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state, dados) => {
		return api.request("tickets/acao_massa", "admin/tickets", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state, id) => {
		return api.request("tickets/no_action", "admin/ticket/" + id, "delete");
	},
	sort_listagem: (state, field) => {
		state.commit("sort_listagem", field);
	},
	get_filtros_salvos: (state, config) => {
		return api.request("tickets/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if (filtro.id != null) {
			method = "put";
		} else {
			method = "post";
		}
		return api.request("tickets/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function (state, id) {
		return api.request("tickets/delete_filter", "admin/filter/" + id, "delete", id);
	},
	addTag: (state, data) => {
		return data.item;
	},
	load_tags: () => {
		return api.request("tickets/no_action", "admin/ticket/tags", "get").then(response => {
			if (response.body.return_code == 1) {	
				const retorno = response.body.data.reduce((tags, item) => {
					return tags.findIndex((t) => t.slug === item.slug ) >= 0 ? tags : [...tags, item];
				}, []);
				return retorno;
			} else {
				return [];
			}
		});				
	},
	load_subjects: () => {
		return api.request("tickets/no_action", "admin/ticket/subjects", "get").then(response => {
			if (response.body.return_code == 1) {
				return response.body.data;
			} else {
				return [];
			}
		});		
	},
	load_responsible_users: () => {
		let filters = { "filters": [{ "field": "status", "value": 1, "operator": "=" }], "limit": 999999, "page": 1, "sort": "name,asc" };
		return api.request("tickets/no_action", "admin/users", "post", filters).then(response => {
			if (response.body.return_code == 1) {
				return response.body.data;
			} else {
				return [];
			}
		});		
		
	},
	update_read_message: function(state, msg) {
		api.request("tickets/loaded", "ticket/message", "put", {
			message_id: msg.id,
			read: true,
			read_by: {
				id: state.rootState.app.usuario.id,
				name: state.rootState.app.usuario.name
			}
		});
	},
	new_message: function(state, payload){

		const data = new FormData();

		data.append("ticket_id", payload.ticket_id);
		data.append(
			"message",
			JSON.stringify(payload.message),
		);
		payload.attachments.forEach((attachment) => {
			data.append("attachments", attachment);
		});
		
		return api.request("tickets/loaded", "ticket/message", "post", data, null, false , null, true, true, true). then(response => {
			return response;
		});
	},
	updateTicket: function (context, data) {
		return api.request("tickets/loaded", "ticket", "put", data).then((response) => {
			return response.body;
		});
	},
	async getAttachment(state, attachment) {
		const response = await api.request("tickets/no_action", "documents" + attachment.url, "get").then((response) => {
			return response.body;
		});		 
		if (response.return_code === 1 && response.data.ok && response.data.file) {
			return response.data.file;
		} else {
			return null;
		}
	},
	load_tickets_by_order_number: (state, data) => {		
		let fields = [
			"id",
			"protocol_number",
			"subject",
			"status",
			"created_at",
		];

		let filtros = [
			{
				field: "order.number",
				value: data,
				operator: "="
			},
		];

		return api.request("tickets/data_valores_loaded", "admin/tickets", "post", { fields: fields, "filters": filtros, "sort": "created_at,desc", "limit": 99999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
			
		});
	},
	update_status_order: function(context, data){		
		try {
			return api.request("pedidos/no_action", "admin/order/status/update", "put", data).then(function(response){
				return response.body;
			});
		} catch(e) {			
			alerts.alertTop("error", "Houve um problema ao alterar o status.");
			return [];
		}
		
	},
};