<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<!-- Resumo -->
		<div class="row formulario-dados">
			<div class="col-6 my-3">					
				<div class="card card-view card-base">
					<div class="card-header">
						Configurações para Dashboard
					</div>
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Período Inicial" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="initial_date" :index=0 />
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Período Final" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="final_date" :index=1 />
							</div>
							<div class="col-12">
								<button class="btn btn-block btn-success" v-on:click="montarDashboard()">Montar Dashboard</button>
							</div>
						</div>						
					</div>	
				</div>
			</div>

			<div class="col-6 my-3"  v-if="resume && resume.all">
				<div class="card card-view card-base">
					<div class="card-header">
						Faturamento por canal no período
					</div>
					<div class="card-body">
						<reports-pizza :info=resume ></reports-pizza>
					</div>	
				</div>
			</div>

			<div class="col-12 my-3"  v-if="resume && resume.all">
				<div class="card card-view card-base">
					<div class="card-header">
						Faturamento por canal diário
					</div>
					<div class="card-body">
						<reports-line-area :info=resume ></reports-line-area>
					</div>	
				</div>
			</div>
		</div>

		<!-- Tabela -->
		<Tabela :config="config" :modulo_permissao=modulo_permissao />
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Tabela from "./../../common/Tabela";
import Filters from "../../../common/filters";
import FormInputGroup from "./../../common/form/InputGroup";
import validation from "./../../../common/validation";
import ReportsLineArea from "./../../common/dashboards/ReportsLineArea.vue";
import ReportsPizza from "./../../common/dashboards/ReportsPizza.vue";

export default {
	name: "MarketplacesDash",
	components: {
		Header,
		Breadcrumb,
		Tabela,
		FormInputGroup,
		ReportsLineArea,
		ReportsPizza
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		resume() {
			return this.$store.state.reports_marketplace.resume_data || null;
		},		
	},
	filters: Filters,
	data () {
		return {
			header_info: {
				title: "Relatório de Vendas Marketplace",
				chamada: "Relatório de vendas da sua loja nos marketplaces",
				links: {
					criar: ""
				}
			},
			breadcrumb_info: [
				{
					name: "Relatórios de Vendas Marketplace",
					link: ""
				}
			],
			filtros: null,
			valores: null,
			permissao: [],
			config: {
				state: "reports_marketplace",
				tipo: "reports_marketplace",
				modulo_permissao: this.modulo_permissao,
				url_path: "pedidos",
				flags: {
					multi: {
						enable: true,
						delete: false,
						export: true,
						print: false,
						transfer: false,
						activate: false,
						inactivate: false,						
					},
					look: true,
					edit: false,
					delete: false,
				}
			},
			initial_date: null,
			final_date: null,
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	mounted() {
	},
	provide: function () {
		return {
			gerarResumo: this.gerarResumo
		};
	},
	methods: {		
		novo() {
			this.$store.dispatch(this.config.state + "/reset");
		},
		montarDashboard() {
			if(validation.validation().valid){
				this.$store.dispatch("reports_marketplace/get_dashboard", { initial_date: this.initial_date, final_date: this.final_date });
			}
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
