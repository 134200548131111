export default {
	reset(state, payload) {
		for (let p in state) {
			state[p] = payload[p];
		}
	},
	loaded(state, payload) {
		if (payload.return_code == 1) {			
			// pega o model como veio do banco
			let model = state.model;
			// percorre o que veio do banco para ver se tem campo a mais
			//  e preencher corretamente os valores dos que existem 
			for(let chave in payload.data){
				if (chave == "description"){
					if(payload.data[chave]){
						// removendo espaços e \r\n para evitar conflitos
						payload.data[chave] = payload.data[chave].replace(/\r\n/g,"");
						payload.data[chave] = payload.data[chave].replace(/> {1}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {2}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {3}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {4}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {5}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {6}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {7}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {8}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {9}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {10}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {11}</g,"><");
						payload.data[chave] = payload.data[chave].replace(/> {12}</g,"><");		
						// convertendo tabela pois o editor não entende tabela
						payload.data[chave] = payload.data[chave].replace(/<table width="100%" border="0" cellpadding="1" cellspacing="1">/g,"");		
						payload.data[chave] = payload.data[chave].replace(/<\/table>/g,"");
						payload.data[chave] = payload.data[chave].replace(/<tbody>/g,"<ul>");
						payload.data[chave] = payload.data[chave].replace(/<\/tbody>/g,"</ul>");
						payload.data[chave] = payload.data[chave].replace(/<tr>/g,"");
						payload.data[chave] = payload.data[chave].replace(/<\/tr>/g,"");
						payload.data[chave] = payload.data[chave].replace(/<td>/g,"<li>");
						payload.data[chave] = payload.data[chave].replace(/<\/td>/g,"</li>");
						// corrigindo o src da imagem
						payload.data[chave] = payload.data[chave].replace(/<img src="\/editor/g,"<img src=\"https://imagens.mundodacarabina.com.br/editor");
					}	
				}
				model[chave] = payload.data[chave];
			}            

			delete model.questions;
			
			state.model = model;
		}
	},
	//lista
	data_valores_loaded(state, payload) {
		if (payload.return_code == 1) {
			let data = payload.data;
			if(data.items.length > 0){
				data.items.forEach(item => {
					item.skus = item.variations ? item.variations.map(function(o) { return o.sku; }).join(", ") : "";
					item.supplier_codes = item.variations ? item.variations.map(function(o) { return o.supplier_code; }).join(", "): "";
				});
			}

			state.valores = data;
		}
	},
	data_filtros_loaded(state, payload) {
		state.table_fields = payload;
	},
	altera_pagina(state,pagina){
		let search_data = state.search_data;
		search_data.opcoes.page = pagina;
		state.search_data = search_data;
	},
	altera_limit(state,limit){
		let search_data = state.search_data;
		search_data.opcoes.page = 1;
		search_data.opcoes.limit = limit;
		state.search_data = search_data;
	},
	select_listagem(state,id){
		let select = state.selecionados;
		if(select.find((item) => {
			return item == id;
		})){
			// remover dos selecionados
			select.splice(select.indexOf(id), 1);
		}else{
			// incluir dos selecionados
			select.push(id);
		}
		state.selecionados = select;
	},
	select_listagem_all(state,ids){
		state.selecionados = ids;
	},
	sort_listagem(state,field){
		let search_data = state.search_data;
		search_data.opcoes.page = 1;
		if(search_data.opcoes.order_field == field){
			// executa o toogle na direção
			if(search_data.opcoes.order == "asc"){
				search_data.opcoes.order = "desc";
			}else{
				search_data.opcoes.order = "asc";
			}
		}else{
			// aplica o asc
			search_data.opcoes.order = "asc";
		}
		search_data.opcoes.order_field = field;
		state.search_data = search_data;
	},
	clear_valores(state){
		state.valores = {};
		state.selecionados = [];
		state.checkAll = false;
	},
	acao_massa(){
		// state.selecionados = [];
	},
	no_action(){

	},
	filters(state,filtros){		
		state.filters = [];
		// percorre os filtros que já estão com valores para enviar a pesquisa
		let search_filters = [];
		filtros.forEach( item => {			
			if(item.search.value !== ""){
				search_filters.push(item.search);
			}
		});
		// armazena o search
		state.search_data.filtros = search_filters;
		// armazena o filtro		
		state.filters = filtros;
		if(filtros.length == 0){
			// limpa os outros dados
			state.search_data.filtro_selecionado = 0;
		}
	},
	get_filtros_salvos(state,payload){
		if(payload.return_code == 1){
			state.saved_filters = payload.data;
		}		
	},
	save_filter(state,payload){
		if(payload.return_code == 1){
			// atualizando os filtros
			let index = state.saved_filters.findIndex(item => {
				return item.id == payload.data.id;
			});
			if(index >= 0){
				state.saved_filters[index] = payload.data;				
			}else{
				state.saved_filters.push(payload.data);
			}
			state.search_data.filtro_selecionado = payload.data.id;
		}
	},
	delete_filter(state,payload){
		if(payload.return_code == 1){
			let id = state.search_data.filtro_selecionado;
			let filtros = state.saved_filters;
			let index = filtros.findIndex(item => {
				return item.id == id;
			});
			filtros.splice(index, 1);
			state.saved_filters = filtros;
			state.search_data.filtro_selecionado = 0;
		}
	},
	select_filter(state,payload){
		state.search_data.filtro_selecionado = payload;
	},
	multiselect_push: function(state,data){
		if(data.multiple){
			state.model[data.tipo].push(data.item);
		}else{
			state.model[data.tipo] = data.item;
		}		
	},
	multiselect_update: function(state,data){
		state.model[data.tipo] = data.item;
	},
	load_categorias: function(state,payload){
		if(payload.return_code == 1){
			state.categorias = payload.data;
		}
	},
	incluir_categoria: function(state,data){
		state.model.categories.push(data);
	},
	remover_categoria: function(state,data){
		let categorias = Object.assign([],state.model.categories);
		let index = categorias.findIndex(item => {
			return item.id == data.id;
		});
		categorias.splice(index,1);
		state.model.categories = categorias;
	},
	galeria_created(state, payload) {
		if (payload.return_code == 1) {
			if (payload.data.id) {
				let galerias = Object.assign([],state.model.galleries);
				galerias.push({
					id: payload.data.id,
					name: payload.data.name,
					url: payload.data.url,
					pictures: []
				});
				state.model.galleries = galerias;
			}
		}
	},
	upload_galeria: function(state,payload){
		if (payload.return_code == 1) {			
			let picture = payload.data;
			let galerias = Object.assign([],state.model.galleries);
			galerias.forEach( item => {
				if(item.id == picture.gallery_id){
					item.pictures.push(picture);
				}
			});
			state.model.galleries = galerias;
		}
	},
	remove_galeria: function(state, galeria){
		let galerias = state.model.galleries;
		galerias.splice(galerias.indexOf(galeria), 1);
		state.model.galleries = galerias;
	},
	pictures_ordem_updated: function(state, data){	
		let galerias = Object.assign([],state.model.galleries);
		galerias.forEach( item => {
			if(item.id == data.gallery_id){
				item.pictures = data.pictures;
			}
		});
		state.model.galleries = galerias;
	},
	picture_deleted: function(state,picture){
		let galerias = Object.assign([],state.model.galleries);
		galerias.forEach( item => {
			if(item.id == picture.gallery_id){
				let index = item.pictures.findIndex(pic => {
					return pic.id == picture.id;
				});
				item.pictures.splice(index, 1);				
			}
		});
		state.model.galleries = galerias;
	},
	picture_updated: function(state,data){
		let galerias = Object.assign([],state.model.galleries);
		galerias.forEach( item => {
			if(item.id == data.gallery_id){
				item.pictures.forEach(pic => {
					if(pic.id == data.id){
						pic.alt = data.alt;
					}
				});								
			}
		});
		state.model.galleries = galerias;
	},
	addTag: function(state,data){
		state.model[data.tipo].push(data.item);
	},
	novaVariacao: function(state,data){
		state.model.variations.push(data);
	},
	removeVariacao: function(state,variacao){
		let variacoes = Object.assign([],state.model.variations);
		let index = state.model.variations.indexOf(variacao);
		variacoes.splice(index, 1);
		state.model.variations = variacoes;
	},
	updateVariacoes: function(state,variacoes){		
		state.model.variations = variacoes;
	},
	selectComboProduct: function(state,produto){
		state.model.kit_products.push(produto);
	},
	updateComboProducts: function(state,combos){
		state.model.kit_products = combos;
	},
	load_preview: function(state, payload){
		if(payload.return_code == 1){			
			const model = Object.assign({}, state.model);
			model.preview = payload.data.preview;
			state.model = model;
		}
	},
	pre_cadastro: function(state, payload){
		const model = Object.assign({}, state.model);

		model.title = payload.title;
		model.product_type = {
			name: "Geral",
			id: "6071e09aa244c22801185a9c"
		};
		model.provider = payload.provider;
		model.published_at = payload.created_at;
		
		state.model = model;
	},
	update_order_videos(state,payload){		
		state.model.videos = payload;
	},
	deleteVideo(state,item){
		state.model.videos.splice(state.model.videos.indexOf(item), 1);
	},
};