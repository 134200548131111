<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		
		

		<div class="row formulario-dados">
			<div class="col-8 my-3">					
				<div class="card card-view card-base">
					<div class="card-header">
						Configurações de busca do relatório
					</div>
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Período Inicial" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="initial_date" :index=0 />
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Período Final" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="final_date" :index=1 />
							</div>
							<div class="col-4">
								<FormInput :required=true rules="string" label="Quantidade de Produtos" placeholder="" info="Informe o número máximo de produtos que deseja listar" v-model="products_count" :slug_function=false type="number" />
							</div>
							<div class="col-8">
								<FormInput :required=false rules="string" label="SKUs" placeholder="" info="Informe os SKUs separados por vírgula" v-model="skus" :slug_function=false />
							</div>							
							<div class="col-12">
								<div class="form-group mb-0"> 
									<label class="mb-1"><strong>Canais</strong></label>
									<MultiSelect state="reports_marketplace" tipo="channels" :dados="channels.map(item => { return { id: item.value, name: item.label }; })" :required=false :field_required=false :multiple=true :insert=false :modo_tag=true></MultiSelect>
								</div>
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Ordenação" info="" :options="[{id: 'quantidade',label: 'Quantidade'}, {id: 'valor', label: 'Valor'}]" v-model="ordenacao" />		
							</div>
							<div class="col-3">
								<button class="btn btn-block btn-success" v-on:click="filtrar()">Filtrar Dados</button>								
							</div>
							<template v-if="marketplaces.length > 0">
								<downloadexcel								 	
									class            = "col-3"
									:fetch           = "fetchDataExport"
									:fields          = "json_fields"
									:before-generate = "startDownload"
									:before-finish   = "finishDownload">
									<button class="btn btn-block btn-info">Exportar Dados</button>
								</downloadexcel>								
							</template>
						</div>						
					</div>	
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 my-3" v-for="marketplace in marketplaces" :key="marketplace.marketplace">
				<div class="card card-view card-base">
					<div class="card-header">
						{{ marketplace.marketplace | marketplaces }}
					</div>
					<div class="card-body">
						<table width="100%">							
							<col width="40%" />
							<col width="15%" />
							<col width="10%" />
							<col width="15%" />							
							<tr class="cabecalho">
								<th>Produto</th>
								<th>SKU</th>																
								<th>Quantidade</th>
								<th>Valor</th>
							</tr>
							<template v-if="marketplace.products.length > 0">
								<tr v-for="(product,i) in marketplace.products" :key=i>
									<td class="titulo align-middle">{{ product.title }}</td>
									<td class="align-middle">{{ product.sku }}</td>
									<td class="center align-middle">
										{{ product.quantity | milhar }}
									</td>
									<td class="center align-middle">{{ product.amount | money }}</td>								
									
								</tr>	
							</template>
							<template v-else>
								<tr><td colspan="4" class="center">Nenhum produto encontrado</td></tr>
							</template>								
						</table>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import validation from "./../../../common/validation";
import Filters from "./../../../common/filters";
import FiltersJson from "./../../../assets/json/filters.json";
import alerts from "./../../../common/alerts";

import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";
import MultiSelect from "./../../common/MultiSelect";
import downloadexcel from "vue-json-excel";

export default {
	name: "Dashboard",
	components: {
		Header,
		Breadcrumb,
		FormInput,
		MultiSelect,
		FormSelect,
		FormInputGroup,
		downloadexcel
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		channels() {
			const channel = FiltersJson.reports_marketplace.filtros.find(item => { return item.field == "marketplace.marketplace_channel";} );
			return channel.options;
		},
		json_fields() {
			return {
				"Marketplace": "marketplace",
				"Nome": "title",
				"SKU": "sku",
				"Quantidade": "quantity",
				"Valor Total": "amount"
			};
		}
	},
	filters: Filters,
	data () {
		return {
			header_info: {
				title: "Relatório de Marketplaces",
				chamada: "Acompanhe as principais informações das vendas nos marketplaces",
				links: {
					
				}
			},
			breadcrumb_info: [
				{
					name: "Relatório Marketplaces",
					link: "/"
				}
			],
			initial_date: null,
			final_date: null,
			products_count: null,
			skus: null,
			marketplaces: [],
			ordenacao: "quantidade"
		};
	},  
	created() {
		this.$store.dispatch("pedidos/reset");		
	},
	methods: {		
		filtrar() {			
			if(validation.validation().valid){
				let channel = null;
				if(this.$store.state.reports_marketplace.model.channels.length > 0){
					channel = this.$store.state.reports_marketplace.model.channels.map(item => { return item.id; });
				}
				this.$store.dispatch("pedidos/get_report_marketplaces", { initial_date: this.initial_date, final_date: this.final_date, products_count: parseInt(this.products_count), channel: channel, skus: this.skus, ordenacao: this.ordenacao }).then(response => {
					if(response.length == 0){
						alerts.alertTop("error", "Nenhum registro encontrado");
					}
					this.marketplaces = response;
				});
			}
		},
		fetchDataExport() {

			if(this.marketplaces.length == 0){
				alerts.alertTop("error","Nenhum dado encontrado para exportar.");
				return false;
			}

			let dados = [];

			this.marketplaces.forEach(mktplace => {
				if(mktplace.products.length > 0){
					dados = dados.concat(mktplace.products.map(item => {
						return {
							marketplace: Filters.marketplaces(mktplace.marketplace),
							title: item.title,
							sku: item.sku,
							quantity: item.quantity,
							amount: item.amount.toString().replace(".",","),
						};
					}));
				}
			});

			return dados;

		},
		startDownload(){
			alerts.alertTop("alert","Carregando os dados para exportar em Excel.");			
		},
		finishDownload(){
			alerts.alertTop("success","O arquivo está sendo baixado no seu computador.");
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
