<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro my-3" v-if="order">
			<div class="col-8 my-3">
				<div class="card card-base">
					<div class="card-header">
						Pedido {{ order.order_number }} / {{ order.store_id | store }}
					</div>
					<div class="card-body">
						<ProdutosPedidos ref="products" state="pedidos" tipo="products" :needs_store=true :store_id=order.store_id :cart=order :order=true :in_stock=true ></ProdutosPedidos>
					</div>
				</div>

				<div class="card card-base my-3">
					<div class="card-header">
						Informações de Frete
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-6">
								<FormInputGroup :required=true rules="string" type="money" label="Frete Real" placeholder="" info="O valor do frete sem desconto aplicado" position="left" text="R$ " v-model="order.shipment.price" @change="atualizaDadosOrder()" />
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" type="money" label="Desconto no Frete" placeholder="" info="O valor do desconto a ser aplicado no frete" position="left" text="R$ " v-model="order.shipment.discount"  @change="atualizaDadosOrder()" />
							</div>			
						</div>			
					</div>
				</div>
			</div>	
			<div class="col-4 my-3">
				<div class="card card-base">
					<div class="card-header">
						Valores do Pedido
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<ul class="resumo">													
									<li><strong>Produtos:</strong> <span class="positivo"> {{ order.total_products | money }}</span></li>
									<li><strong>Frete:</strong> <span class="positivo"> {{ order.total_shipping | money }}</span></li>
									<li><strong>Descontos:</strong> <span class="negativo"> {{ order.total_discounts - order.shipment.discount | money }}</span></li>
									<li class="total"><strong>Prazo:</strong> <span> {{ order.total_order | money }}</span></li>									
									<li><strong>À vista:</strong> <span> {{ novoTotalAvista | money }}</span></li>									
								</ul>	
								<div class="alert alert-danger my-3" role="alert" v-if="Math.abs(diferenca_valores) > 0">
									<template v-if="diferenca_tipo == 'charge'">
										Existe uma diferença de <strong>{{ diferenca_valores | money }}</strong> a ser cobrada
									</template>
									<template v-else-if="diferenca_tipo == 'refund'">
										Existe uma diferença de <strong>{{ diferenca_valores | money }}</strong> a ser devolvida
									</template>
									<template v-else-if="diferenca_tipo == 'charge_cash'">
										Existe uma diferença de <strong>{{ diferenca_valores | money }}</strong> a ser cobrada no valor a vista
									</template>
									<template v-else-if="diferenca_tipo == 'refund_cash'">
										Existe uma diferença de <strong>{{ diferenca_valores | money }}</strong> a ser devolvida no valor a vista
									</template>									
								</div>		
								<div class="alert alert-primary my-3" role="alert">
									<p><strong>Dicas para melhor uso da ferramenta:</strong></p>
									<p class="my-2">- Para encontrar o valor a prazo a partir de um valor a vista desejado nesse pedido, multiplique o valor a vista por <strong>{{ order.total_order / novoTotalAvista }}</strong>.</p>									
									<p class="my-2">- Manipule os pagamentos apenas depois de ter alterado tudo que for necessário no pedido. Em caso de problemas com os valores, remova e insira novamente os pagamentos.</p>
									<p class="my-2">- Considere a divisão dos pagamentos pelo valor a prazo, pois o valor à vista é calculado automaticamente.</p>									
								</div>				
							</div>							
						</div>						
					</div>
				</div>
			</div>
			<div class="col-12 my-3">
				<div class="card card-base">
					<div class="card-header">
						Formas de Pagamento
					</div>
					<div class="card-body">
						<div class="row payments">
							<div class="col-3">								
								<ul class="list-group">
									<li class="list-group-item py-3" v-on:click="addPagamento('credit_card')">Cartão de Crédito <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('invoice')">Boleto Bancário <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('deposit')">Depósito Bancário <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('pix')">PIX <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('withdraw')">Pagamento Retirada <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('internal')">Pagamento Interno <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('pagaleve')">Pix Parcelado <i class="fas fa-plus"></i></li>
								</ul>
							</div>
							<div class="col-9">
								<div class="row cards-payment">
									<div class="col-4 mb-4" v-for="(payment,i) in opcoesPagamento" :key=i  :class="{ opacity: payment.deleted }">
										<div class="card">
											<div class="card-header">
												{{ pegaNomePagamento(payment) }}
											</div>
											<template v-if="payment.disabled">
												<div class="card-body pb-1">
													<div class="alert alert-primary text-center" role="alert" v-if="payment.valor > payment.avista">
														<strong class="d-block">Prazo: {{ payment.valor | money }}</strong>
													</div>
													<div class="alert alert-primary text-center" role="alert">
														<strong class="d-block">À vista: {{ payment.avista | money }}</strong>
													</div>
													<div class="credit_card" v-if="payment.tipo == 'credit_card' && payment.status != 'paid'">	
														<FormSelect :required=false rules="option" label="Forçar Parcela" info="" :options="[{ id: true, label: 'Sim' },{ id: false, label: 'Não' }]" v-model="payment.force_installments" type="boolean" @input="getInstallments(payment, i)" />
														<FormSelect v-if="payment.force_installments && renderComponent" :required="payment.force_installments ? true : false" rules="option" label="Número de Parcelas" info="" :options="payment.opcao_parcelas" v-model="payment.parcelas" />
													</div>
												</div>
												<div class="card-footer" v-if="payment.status != 'paid'">
													<button class="btn btn-danger btn-block btn-sm" v-on:click="removerPagamento(i)" :disabled="payment.deleted">Remover Este Pagamento</button>
												</div>
											</template>
											<template v-else>
												<div class="card-body pb-1">												
													<FormInputGroup :key="`${i}-${payment.payment_sale_key}`" :required=true rules="string" type="money" :label="(payment.tipo != 'credit_card') ? 'Valor à Prazo' : 'Valor'" placeholder="" info="Valor referente a diferença no pedido" position="left" text="R$ " v-model="payment.valor" @input="recalculaAvistaPayments(i, payment.valor)" />
													<FormInputGroup :key="`${i}-${payment.payment_cash_key}`" :required=true rules="string" type="money" label="Valor à Vista" placeholder="" info="Valor com desconto à vista" position="left" text="R$ " v-model="payment.avista" :disabled=true />
													<div class="boleto" v-if="payment.tipo == 'invoice'">
														
													</div>
													<div class="pix" v-if="payment.tipo == 'pix'">
														
													</div>
													<div class="deposito" v-if="payment.tipo == 'deposit'">
														<FormSelect :required=true rules="option" label="Selecione o Banco" info="" :options="banks" v-model="payment.bank" />
													</div>
													<!--
													<div class="retirada" v-if="payment.tipo == 'withdraw'">
														<FormSelect :required=true rules="option" label="Tipo de Pagamento" info="" :options="[{id: 'sale', label: 'Prazo'}, {id: 'cash',label: 'À vista'}]" v-model="payment.payment_type" />
													</div>
													-->
													<div class="retirada" v-if="payment.tipo == 'withdraw'">
														<FormSelect :required=true rules="option" label="Opção de Pagamento"  info="" :options="[{id: 'credit_card', label: 'Cartão de Crédito'}, {id: 'debit_card', label: 'Cartão de Débito'}, {id: 'cash',label: 'Dinheiro'}]" v-model="payment.payment_type" />
														<FormSelect v-if="payment.payment_type == 'credit_card'" :required="payment.payment_type == 'credit_card' ? true : false" rules="option" label="Número de Parcelas" info="" :options="payment.opcao_parcelas" v-model="payment.parcelas" />
													</div>	
													<div class="credit_card" v-if="['credit_card','withdraw'].includes(payment.tipo)">	
														<FormSelect :required=false rules="option" label="Forçar Parcela" info="" :options="[{ id: true, label: 'Sim' },{ id: false, label: 'Não' }]" v-model="payment.force_installments" type="boolean" @input="getInstallments(payment, i)" />
														<FormSelect v-if="payment.force_installments && renderComponent" :required="payment.force_installments ? true : false" rules="option" label="Número de Parcelas" info="" :options="payment.opcao_parcelas" v-model="payment.parcelas" />
													</div>												
												</div>
												<div class="card-footer">
													<button class="btn btn-danger btn-block btn-sm" v-on:click="removerPagamento(i)">Remover Este Pagamento</button>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Vue from "vue";
import VueMeta from "vue-meta";
import VueResource from "vue-resource";

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import ProdutosPedidos from "./utils/ProdutosPedido";
import Filters from "./../../../common/filters";
import FormInputGroup from "./../../common/form/InputGroup";
import FormSelect from "./../../common/form/Select";
import Alerts from "./../../../common/alerts";
import states from "./../../../assets/json/states";
import validade from "./../../../assets/json/validade";
import validation from "./../../../common/validation";
import {deepCopy} from "./../../../common/utils";

Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true
});

Vue.use(VueResource);


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		ProdutosPedidos,
		FormInputGroup,
		FormSelect,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {		
		olderOrder() {
			return this.$store.state.pedidos.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		cities(){
			return this.$store.state.pedidos.cities;
		},		
		usuario() {
			return this.$store.state.app.usuario;
		},
		novoTotalAvista() {
			return this.order.total_order - this.descontoAvistaProdutos();
		}
	},	
	data () {
		return {
			header_info: {
				title: "Pedidos > Editar",
				chamada: "Edite os pedidos dos clientes",
				links: {
					back: "pedidos.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Pedidos",
					link: "/pedidos"
				},
				{
					name: "Editar",
					link: ""
				}
			],
			itens_tab:[
			],
			disabled_items: [],
			hidden_items: [],
			order: null,
			states: states,		
			validade: validade,
			payment_type: "installments",
			gateways: [],
			merchant_id: null,
			banks: [],
			opcoesPagamento: [],
			diferenca_valores: 0,
			diferenca_tipo: "charge",
			order_number: null,
			payment_discount_deleted: 0,
			renderComponent: false,
		};
	},  
	methods: {		
		async save(){	

			if(!validation.validation().valid){
				Alerts.alertTop("error", "Existem campos que precisam ser preenchidos");
				return;
			}

			const self = this;
			// validando diferença de valores
			if (this.diferenca_valores > 0.05 && !["refund_sale","refund","refund_cash"].includes(this.diferenca_tipo)) {
				Alerts.alertTop("error", "Existe uma diferença de valores em aberto");
				return;
			}

			if (this.diferenca_valores > 0.05 && ["refund_sale","refund","refund_cash"].includes(this.diferenca_tipo)) {
				let event_confirm = () => {
					self.atualizaPedido();
					return true;
				};
				Alerts.alertConfirm(`Está ciente que existe uma diferença a ser devolvida ao cliente ${Filters.money(self.diferenca_valores)} ?`,event_confirm);	
			}else{
				this.atualizaPedido();
			}

		},
		async atualizaPedido(){
			const payments = [];
			let msg_erro;
			let erro = 0;						
			this.opcoesPagamento.forEach(pay => {
				if (!pay.disabled && pay.valor > 0 && pay.avista > 0) {
					pay.valor = parseFloat(pay.valor.toFixed(2));
					pay.avista = parseFloat(pay.avista.toFixed(2));
					// popula o payments
					
					if(pay.tipo == "invoice"){
						// validação boleto
						if(pay.valor <= 0){
							msg_erro = "O valor do boleto tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							payments.push({
								type: "invoice",
								value: pay.avista,
								prices: {
									value: pay.valor,
									cash_value: pay.avista
								}
							});  
						}    
					}else if(pay.tipo == "deposit"){
						// validação boleto
						if(pay.valor <= 0){
							msg_erro = "O valor do depósito tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							payments.push({
								type: "deposit",
								value: pay.avista,
								bank: pay.bank,
								prices: {
									value: pay.valor,
									cash_value: pay.avista
								}
							});   
						}  
					}else if(pay.tipo == "pix"){
						// validação boleto
						if(pay.valor <= 0){
							msg_erro = "O valor do pix tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							payments.push({
								type: "pix",
								value: pay.avista,
								prices: {
									value: pay.valor,
									cash_value: pay.avista
								}
							});   
						}
					}else if(pay.tipo == "credit_card"){
						// validação cartao
						if(pay.valor <= 0){
							msg_erro = "O valor do cartão tem que ser maior que R$ 0,00.";
							erro++;
						}
						// gera o nó de pagamento do cartão
						var _pay = this.montaCartaoPedido(pay);
						payments.push(_pay);
					}else if(pay.tipo == "withdraw"){
						// validação retirada
						if(pay.valor <= 0){
							msg_erro = "O valor do Pagamento Retirada tem que ser maior que R$ 0,00.";
							erro++;
						}
												
						if(pay.payment_type == ""){
							msg_erro = "O Tipo do Pagamento deve ser informado.";
							erro++;
						}
						
						// inclui o retirada caso esteja válido
						if(erro == 0){
							let value = pay.valor;

							if (pay.payment_type == "debit_card" || pay.payment_type == "cash") {
								value = pay.avista;
							}else if(pay.payment_type == "credit_card"){
								// pega o valor da parcela pois pode ter desconto a vista no cartão
								var valor_parcela = pay.opcao_parcelas.filter(function(dado){                        
									return dado.installment == pay.parcelas;
								})[0]["price"];
								value = parseFloat((valor_parcela * pay.parcelas).toFixed(2));	
							}
							
							payments.push({
								type: "withdraw",
								value: value,
								payment_type: pay.payment_type,
								prices: {
									value: pay.valor,
									cash_value: pay.avista
								},
								installments: parseInt(pay.parcelas)
							}); 
						}
					}else if(pay.tipo == "internal"){
						// validação boleto
						if(pay.valor <= 0){
							msg_erro = "O valor do pagamento interno tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							payments.push({
								type: "internal",
								value: pay.avista,
								prices: {
									value: pay.valor,
									cash_value: pay.avista
								}
							});   
						}
					}else if(pay.tipo == "pagaleve"){
						// validação boleto
						if(pay.valor <= 0){
							msg_erro = "O valor do pix parcelado tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							payments.push({
								type: "pagaleve",
								value: pay.avista,
								prices: {
									value: pay.valor,
									cash_value: pay.avista
								}
							});   
						}
					}
				}else if(pay.disabled && pay.deleted){
					payments.push({
						id: pay.id, 
						deleted: true
					});
				}else if(pay.disabled) {
					if(pay.tipo == "credit_card"){	
						var _valor_pagamento = pay.valor;
						const _parcelas = parseInt(pay.parcelas);
						if(pay.opcao_parcelas.length > 0){
							// descobrindo se é a vista ou a prazo							
							var parcela = pay.opcao_parcelas.filter(function(dado){                        
								return dado.installment == _parcelas;
							});							
							if(parcela && parcela[0]){
								if(parcela[0]["type"] == "cash") {
									_valor_pagamento = pay.avista;
								}
							}else if(_parcelas === 1){
								_valor_pagamento = pay.avista;
							}
						}else{
							_valor_pagamento = pay.original_value;
						}
						
						payments.push({
							id: pay.id, 
							updated: true,
							value: _valor_pagamento,
							force_installments: pay.force_installments,
							installments: _parcelas,
						});
					} else {
						// adiciona apenas para calcular os valores totais
						switch(pay.tipo){
						case "invoice":
						case "deposit":
						case "pix":						
						case "internal":
						case "pagaleve":
							payments.push({
								id: pay.id, 
								ignore: true,
								value: pay.avista
							});
							break;
						case "withdraw":
							var value_withdraw = pay.valor;

							if (pay.payment_type == "debit_card" || pay.payment_type == "cash") {
								value_withdraw = pay.avista;
							}else if(pay.payment_type == "credit_card"){
								// pega o valor da parcela pois pode ter desconto a vista no cartão
								var valor_parcela_withdraw = pay.opcao_parcelas.filter(function(dado){                        
									return dado.installment == pay.parcelas;
								})[0]["price"];
								value_withdraw = parseFloat((valor_parcela_withdraw * pay.parcelas).toFixed(2));	
							}
							payments.push({
								id: pay.id, 
								ignore: true,
								value: value_withdraw
							});
							break;
						default:
							payments.push({
								id: pay.id, 
								ignore: true,
								value: pay.valor									
							});
							break;

						}
						
					}
				}
			});

			if(erro > 0){
				Alerts.alertTop("error",msg_erro);
				return;
			}

			// isolando a variável antes do tratamento
			let _order = deepCopy(this.order);
			// tratando os produtos
			_order.products.forEach(item => {
				delete item.stock;
				delete item.full_price;
				delete item.discount_percentage;
				delete item.subtotal_cash_price;
			});
			// atualizando o total discounts com os descontos de produtos
			_order.total_discounts = this.order.products.reduce((total, currentValue) => { 
				return (!currentValue.deleted) ? (total + (Object.values(currentValue.discounts).reduce((t, n) => t + n) * currentValue.quantity)) : total; 
			},0);
			_order.total_discounts += this.descontoAvistaProdutos();
			// atualizando o total discounts com o desconto do frete
			_order.total_discounts += _order.shipment.discount;
			// atualiza os valores da venda sem os descontos de pagamento
			_order.total_order = _order.total_products + _order.shipment.price - _order.total_discounts;
			_order.total_discounts = parseFloat(_order.total_discounts.toFixed(2));			
			_order.total_shipping = parseFloat((_order.shipment.price - _order.shipment.discount).toFixed(2));			

			// tratando a informação dos pagamentos para recalcular o valor final do pedido
			const totalPagamentos = payments.reduce((total, item) => {
				return (!item.deleted) ? total + item.value : total;
			}, 0);

			// ajustando preco do pedido se tiver formas de pagamento a prazo
			if(_order.total_order < totalPagamentos) {
				const diff = totalPagamentos - _order.total_order;
				_order.total_order += diff;
				_order.total_discounts -= diff;
			}


			let refunded = this.olderOrder.refunded || 0;
			if (this.diferenca_tipo == "refund" && this.diferenca_valores > 0){				
				refunded += Math.abs(this.diferenca_valores);
			}

			this.$store.dispatch("pedidos/atualizaPedido", {
				id: _order.id,
				products: _order.products,
				shipment: _order.shipment,
				payments: payments.filter(_item => { return !_item.ignore; }),
				refunded: refunded,
				total_order: parseFloat(_order.total_order.toFixed(2)),
				total_products: parseFloat(_order.total_products.toFixed(2)),
				total_discounts: parseFloat(_order.total_discounts.toFixed(2)),
				total_shipping: parseFloat(_order.total_shipping.toFixed(2)),
			}).then(response => {
				if(response.return_code == 1 && response.data.ok){
					Alerts.alertTop("success", "Dados atualizados com sucesso.");
					this.$router.push({
						name: "pedidos.ver", params: { id: _order.id }
					});
				}else{
					Alerts.alertTop("error", "Houve um problema ao atualizar as informações do pedido");
				}
			});
		},
		calculaParcelas: function(valor, avista) {
			let parcelas = [];
			
			this.$store.dispatch("pedidos/installments",{value: valor, cash_value: avista}).then(response => {
				if(response.body.return_code == 1) {							
					response.body.data.forEach(function(data){								
						parcelas.push({
							id: data.installment,
							label: data.installment + "x de "+ Filters.money(data.price),
							installment: data.installment,
							price: data.price
						});
					});
				}
			});

			return parcelas;
		},
		montaCartaoPedido: function(item){						
			item.parcelas = parseInt(item.parcelas);
			let valor_pagamento = item.valor;
			if(item.parcelas > 0){
				var parcela = item.opcao_parcelas.filter(function(dado){                        
					return dado.installment == item.parcelas;
				});				
				if(parcela && parcela[0]){
					if(parcela[0]["type"] == "cash") {
						valor_pagamento = item.avista;
					}
				}else if(item.parcelas === 1){
					valor_pagamento = item.avista;
				}
			}		
			
			const numero_cartao = item.numero.toString();
			return {
				type: "credit_card",
				value: valor_pagamento,
				prices: {
					value: item.valor,
					cash_value: item.avista
				},
				token: {
					mercadopago: "",
					mercadopago_method_id: "",
					mundipagg: "",
				},
				card: {
					number: (numero_cartao.length <= 10) ? "" : numero_cartao.substring(0,6) + "x".repeat(numero_cartao.length - 10) + numero_cartao.substring(numero_cartao.length - 4,numero_cartao.length),
					expiration_date: item.mes+"/"+item.ano,
					document: item.document.replace(/[^0-9]/g,""),
					name: item.nome 
				},
				installments: item.parcelas || 1,
				force_installments: item.force_installments
			};
		},
		addPagamento: function(tipo) {
			let value_padrao = 0;

			let opcao = this.montaEsqueletoPagamento(tipo,value_padrao);

			opcao.payment_sale_key = 0;
			opcao.payment_cash_key = 1;
			opcao.valor = this.order.total_order - this.pagamentosPrazo();
			opcao.avista = 0;

			let new_index = this.opcoesPagamento.length;

			this.opcoesPagamento.push(opcao);

			this.recalculaAvistaPayments(new_index,opcao.valor);
			
			// se fo retirada calcula as parcelas
			if (opcao.tipo == "withdraw") {
				opcao.opcao_parcelas = this.calculaParcelas(opcao.valor, opcao.avista);
			}

			this.atualizaDadosOrder();

		},
		montaEsqueletoPagamento: function(tipo, valor){
			switch(tipo){
			case "credit_card":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor,				
					numero: "",
					nome: "",
					mes: "",
					ano: "",
					codigo: "",
					document_type: "CPF",
					document: "",
					parcelas: 0,
					opcao_parcelas: [],
					set_data: false,
					force_installments: false
				};
			case "invoice":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor
				};
			case "deposit":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor,
					bank: {}
				};
			case "pix":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor
				};
			case "withdraw":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor,
					payment_type: "",
					parcelas: 0
				};
			case "voucher":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor,
				};
			case "internal":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor
				};
			case "pagaleve":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor
				};
			}
			
		},
		pegaNomePagamento: function(payment){
			switch(payment.tipo){
			case "credit_card":
				return "Cartão de Crédito";
			case "invoice":
				return "Boleto Bancário";
			case "deposit":
				return "Depósito Bancário";
			case "pix":
				return "Pix";
			case "withdraw":
				return "Pagamento Retirada";
			case "voucher":
				return "Voucher";
			case "internal":
				return "Pagamento Interno";
			case "pagaleve":
				return "Pix Parcelado";
			}
		},
		removerPagamento: function(index){			
			let pagamentos = Object.assign([], this.opcoesPagamento);
			if(pagamentos[index].disabled){
				pagamentos[index].deleted = true;
			}else{
				pagamentos.splice(index, 1);
			}

			this.opcoesPagamento = pagamentos;

			this.atualizaDadosOrder();
		},
		async manipulaPedido({type, data}){			
			const self = this;
			switch(type){
			case "quantity":
				var {index, product} = data;
				var olderProduct = this.olderOrder.products[index] || null;
				if (typeof product.quantity == "string") product.quantity = parseInt(product.quantity);
				if(product.quantity <= 0){
					product.quantity = 1;
				}					
				if(olderProduct) {									
					var diferenca = product.quantity - olderProduct.quantity;
					if(diferenca > 0 && diferenca > product.stock){
						Alerts.alertTop("error", "A quantidade máxima para esse item é: "+ (product.stock + olderProduct.quantity));
						product.quantity = product.stock + olderProduct.quantity;
					}
				}else{
					if(product.quantity > product.stock){
						Alerts.alertTop("error", "A quantidade máxima para esse item é: "+ product.stock);
						product.quantity = product.stock;
					}
				}				
				break;
			case "new_product":
				var exists = self.order.products.find(item => {
					return item.new && item.sku == data;
				});
				if(exists){
					Alerts.alertTop("error", "Já foi incluído esse produto no pedido");
					return;
				}
				// pega os dados do produto
				await this.$store.dispatch("produtos/getBySku", [data]).then(response => {
					response.forEach(item => {
						item.variations.forEach(variation => {
							if(variation.sku === data){
								var prices;
								switch(self.order.store_id){
								case 1:
									prices = variation.prices.mundo;
									break;
								case 2:
									prices = variation.prices.aventura;
									break;
								case 3:
									prices = variation.prices.lexo;
									break;
								}
								let _product = {
									sku: variation.sku,
									name: variation.name,
									supplier_code: variation.supplier_code,
									image: null,
									discounts: prices.discounts,
									attributes: variation.attributes,
									unit_price: prices.price,									
									subtotal_price: prices.price,
									unit_cost: variation.cost,
									quantity: 1,
									new: true,
									stock: variation.stock,
									full_price: prices.price + Object.values(prices.discounts).reduce((t, n) => t + n),
									discount_percentage: prices.discount_percentage,
									unit_cash_price: prices.cash_price,
									subtotal_cash_price: prices.cash_price
								};
								self.order.products.push(_product);
							}						
						});					
					});
				});

				break;
			case "delete_product":				
				if(data.product.new){										
					this.order.products.splice(data.index,1);
				}else{					
					data.product.deleted = true;
					var _olderProduct = this.olderOrder.products[data.index] || null;
					if(_olderProduct){
						data.product.quantity = _olderProduct.quantity;
					}
				}
				this.$refs.products.$forceUpdate();
				break;
			case "product_reverse":
				delete data.product.deleted;
				this.$refs.products.$forceUpdate();
				break;
			case "appliedDiscount":
				if(data.discount.type == 1){
					this.order.products[data.index].discounts.applied = data.discount.value;
				}else{
					this.order.products[data.index].discounts.applied = this.order.products[data.index].unit_price * (data.discount.value / 100);
				}
				this.order.products[data.index].applied_discount = {
					type: data.discount.type,
					value: data.discount.value,
				};
				break;
			case "removeAppliedDiscount":
				this.order.products[data.index].discounts.applied = 0;
				delete this.order.products[data.index].applied_discount;
				break;
			case "product_cash_discount_remove":
				this.order.products[data.index].unit_cash_price = data.product.unit_price;				
				this.$refs.products.$forceUpdate();
				break;
			}
			

			this.atualizaDadosOrder();
		},
		atualizaDadosOrder: function(){

			// atualizando o nó produtos			
			this.order.products.forEach(item => {
				item.unit_price = item.full_price - Object.values(item.discounts).reduce((t, n) => t + n);
				if(item.unit_cash_price != item.unit_price){
					item.unit_cash_price = item.unit_price - (item.unit_price * (item.discount_percentage / 100));
				}
				item.subtotal_cash_price = item.unit_cash_price * item.quantity;
				item.subtotal_price = parseFloat((item.unit_price * item.quantity).toFixed(2));
			});
			// atualizando o total products
			this.order.total_products = this.order.products.reduce((total, currentValue) => { 
				return (!currentValue.deleted) ? (total + (currentValue.full_price * currentValue.quantity)) : total; 
			},0);						
			// atualizando o total discounts com os descontos de produtos
			this.order.total_discounts = this.order.products.reduce((total, currentValue) => { 
				return (!currentValue.deleted) ? (total + (Object.values(currentValue.discounts).reduce((t, n) => t + n) * currentValue.quantity)) : total; 
			},0);					
			// atualizando o total discounts com o desconto do frete
			this.order.total_discounts += this.order.shipment.discount;
			this.order.total_shipping = parseFloat(this.order.shipment.price - this.order.shipment.discount.toFixed(2));
			// atualiza os valores da venda sem os descontos de pagamento
			this.order.total_order = this.order.total_products + this.order.shipment.price - this.order.total_discounts;
			this.order.total_discounts = parseFloat(this.order.total_discounts.toFixed(2));
			

			// corrigindo diferença de centavos
			if(Math.abs(this.olderOrder.total_order - this.order.total_order) <= 0.05){
				this.order.total_order = this.olderOrder.total_order;
			}
			
			// validando se tem diferença no pagamento
			const pagamentosPrazo = this.pagamentosPrazo();
			if(Math.abs(this.order.total_order - pagamentosPrazo) > 0.05){
				if(this.order.total_order > pagamentosPrazo) {					
					this.diferenca_tipo = "charge";					
				}else if(this.order.total_order < pagamentosPrazo) {
					this.diferenca_tipo = "refund";
				}
				this.diferenca_valores = Math.abs(this.order.total_order - pagamentosPrazo);
			}else{
				this.diferenca_tipo = "none";
				this.diferenca_valores = 0;

				// bater os valores dos pagamentos com o pedido
				const pagamentosNovos = this.opcoesPagamento.filter(item => item.new).length;
				if(pagamentosNovos > 0){
					const order_cash_value = (this.order.total_order - this.descontoAvistaProdutos());
					const diff_avista = Math.abs(order_cash_value - this.pagamentosAvista());
					if (diff_avista > 0.05) {
						this.diferenca_valores = diff_avista;
						this.diferenca_tipo = (order_cash_value > this.pagamentosAvista()) ? "charge_cash" : "refund_cash";
					}
				}				
			}
		},
		pegaInformacoesPagamento(uf){
			this.$store.dispatch("pedidos/informacoesPagamento",{ sa: uf, store_id: this.order.store_id }).then(response => {
				if(response.return_code == 1){
					this.banks = response.data.banks.map(item => {
						return {
							id: item.code.toString(),
							label: item.bank
						};
					});
				}else{
					this.banks = [];
				}
			});
		},
		pagamentosPrazo() {
			return this.opcoesPagamento.reduce((total, item) => {
				return (!item.deleted) ? total + item.valor : total;
			},0);
		},
		pagamentosAvista() {
			return this.opcoesPagamento.reduce((total, item) => {
				return (!item.deleted) ? total + item.avista : total;
			},0);
		},
		descontoAvistaProdutos() {
			// pegando os descontos a vista de produtos
			return parseFloat(this.order.products.reduce((total,item) => {
				return (!item.deleted) ? total + ((item.unit_price - item.unit_cash_price) * item.quantity) : total;
			},0).toFixed(2));
		},
		recalculaAvistaPayments(index, valor) {
			const pay = this.opcoesPagamento[index];
			const _part = valor / this.order.total_order;
			pay.avista = this.novoTotalAvista * _part;
			// atualiza as keys para renderizar o componente
			pay.payment_sale_key++;
			pay.payment_cash_key++;
			this.atualizaDadosOrder();
		},
		getInstallments(payment, index) {
			const self = this;
			self.renderComponent = false;
			self.$store.dispatch("pedidos/installments",{value: payment.valor, cash_value: payment.avista}).then(response => {
				payment.opcao_parcelas = [];
				if(response.body.return_code == 1){							
					response.body.data.forEach(function(data){								
						payment.opcao_parcelas.push({
							id: data.installment,
							label: data.installment + "x de "+ Filters.money(data.price),
							installment: data.installment,
							price: data.price,
							type: data.type
						});
					});
				}
				self.renderComponent = true;
				self.opcoesPagamento[index] = payment;
			});	
			
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			manipulaPedido: this.manipulaPedido,
		};
	},
	filters: Filters,
	created() {		

		// validando se tem permissão para acessar essa página
		const permissao = this.permissoes ? this.permissoes.pedidos : null;		
		if(!this.usuario.manager){
			//chama a tela de erro
			this.$router.push({
				name: "404"
			});
			return;
		}else if(permissao && permissao.editar !== 1){
			//chama a tela de erro
			this.$router.push({
				name: "404"
			});
			return;
		}

		const self = this;
		this.$store.dispatch("pedidos/reset");
		
		this.$store.dispatch("pedidos/load", this.$route.params.id).then(response => {
			self.order = deepCopy(response);
			self.order_number = self.order.order_number;
			self.order.products.forEach(item => {
				item.full_price = item.unit_price + Object.values(item.discounts).reduce((t, n) => t + n);								
				item.discount_percentage = (100 - ((item.unit_cash_price * 100) / item.unit_price)).toFixed(2);	
			});		

			self.pegaInformacoesPagamento(self.order.address.sa);
			// formas de pagamento
			self.order.payments.forEach(item => {
				let opcao = self.montaEsqueletoPagamento(item.type, item.value);
				opcao.valor = item.prices.value;
				opcao.avista = item.prices.cash_value;
				opcao.original_value = item.value;
				opcao.disabled = true;
				opcao.payment_sale_key = 0;
				opcao.payment_cash_key = 1;
				opcao.id = item.id;
				opcao.status = item.status;
				opcao.force_installments = item.force_installments || false;
				opcao.parcelas = item.installments;
				opcao.opcao_parcelas = [];
				self.opcoesPagamento.push(opcao);

				if (opcao.force_installments){
					const _index = self.opcoesPagamento.indexOf(opcao);
					self.getInstallments(self.opcoesPagamento[_index], _index);
				}
			});			
		});		
	},
	metaInfo() {
		const meta = { title: `Edição do pedido ${this.order_number}` };
		return meta;
	},
	watch: {
		"order.shipment.price": function(){
			this.atualizaDadosOrder();
		},
		"order.shipment.discount": function(){
			this.atualizaDadosOrder();
		}
	}
};
</script>

<style scoped lang="scss">	
	.formulario-cadastro{
		ul{
			li {
				padding: 7px 15px;
				background: #f3f3f3;
				position: relative;
				&:nth-child(odd) {
					background: #FFF;
				}
				i {
					display: block;
					font-style: normal;
					font-weight: bold;
					font-size: 12px;
				}
			}
			&.resumo {
				width: 100%;
				li {
					position: relative;
					span {
						position: absolute;
						right: 15px;
						&.positivo {
							&::before {
								content: '+ ';
							}
						}
						&.negativo {
							color: $color_red;
							&::before {
								content: '- ';
							}
						}
						s{
							color: $color_red;
							font-size: 0.95em;
						}
					}
					&.total {
						background: $bg_destaque;
						color: #FFF;
					}
				}
			}
		}
		.frete-results{
			margin: 0.3em auto 0 0;
			div{
				background: #e2e2e2;
				border: 1px solid darken(#e2e2e2,10%);
				border-radius: 5px;
				cursor: pointer;
				&.frete-check{
					border-radius: 5px 0 0 5px;
					padding: 1.6em;
					cursor: pointer;
					font-size: 0.7em;
					text-align: center;
					float: left;
					border-right: 1px solid darken(#e2e2e2,10%);
					&.checked,
					&:hover{
						background: $bg_destaque;
						border-color: $bg_destaque;
						color: #FFF;
					}
				}
				&.frete-desc{
					padding: 0.25em;
					padding-left: 3.5em;
					vertical-align: middle;
					line-height: 1.3em;
				}
			}
		}
		.payments{
			.opacity{
				opacity: 0.25;
			}
			ul{
				li{
					cursor: pointer;
					&:hover{
						background: $bg_destaque;
						color: #FFF;
					}
					i{
						float: right;
					}
				}
			}
		}
	}
</style>
