<script>

export default {
	props:{
		type_module:{
			type: String,
			required: true,
			default: null
		},		
	},
	computed: {
		box_geral() {
			return this.$store.state.dashboard.box_geral;
		}
	},
	data() {
		return {
			config: {},			
			type: "number"
		};
	},
	methods: {
		updateData: function(){
			let self = this; 

			this.$store.dispatch("dashboard/calculatePeriod", { type: self.box_geral.period.type, module: self.type_module } ).then(response => {
				this.$store.commit("dashboard/periods",response);

				let endpoint;			
				let json_data = {
					filters: [
						{
							field: "created_at",
							value: self.box_geral.period.date_init,
							operator: ">=",
							type: "datetime"
						},
						{
							field: "created_at",
							value: self.box_geral.period.date_end,
							operator: "<=",
							type: "datetime"
						}
					]
				};

				let type_request = "post";

				switch(this.config.action){
				case "orders":
					endpoint = "admin/dashboard/orders/total";
					break;
				case "carts":
					endpoint = "admin/dashboard/carts/total";
					break;
				case "customers":
					endpoint = "admin/dashboard/customers/total";
					break;
				case "payment_problem":
					endpoint = "admin/dashboard/orders/total";
					json_data.filters.push({
						field: "status.type",
						value: "payment-problem",
						operator: "=",
						type: "text"
					});
					break;
				}

				// Faz a chamada
				this.$store.dispatch("dashboard/box_data", {
					type_module: self.type_module,
					endpoint: endpoint, 
					method: type_request, 
					filters: json_data
				});			

			});
			
		},
		toogleType: function(){
			if(this.type == "number"){
				this.type = "money";
			}else{
				this.type = "number";
			}
		}
	},
	filters: {
		price: function(value){
			let valor = "0,00";
			
			if(value) {				
				valor = value.toFixed(2);
				valor = valor.toString().replace(/\D/g,"");
				valor = valor.toString().replace(/(\d)(\d{8})$/,"$1.$2");
				valor = valor.toString().replace(/(\d)(\d{5})$/,"$1.$2");
				valor = valor.toString().replace(/(\d)(\d{2})$/,"$1,$2");
			}
			return valor;
		},
		number: function(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender
			return value.toString().replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
		}
	},
	created() {		
		this.config = this.box_geral[this.type_module];
		this.config.number = 0;
		this.config.price = 0;
	},
	watch: {		
		"box_geral.period.type": function(){						
			this.updateData();
		},
		"box_geral.period.refresh": function(){
			this.updateData();				
		}
	},
	mounted(){		
		this.updateData();
	}
};
</script>

<template>
	<div>
		<section :class="'panel dash-info-rapida dash-purple ' + config.class">			
			<div class="symbol"  v-if="type == 'number'">
				<i :class="config.icon"></i>
			</div>
			<div class="value ">
				<i class="fa fa-retweet invert-data" aria-hidden="true" v-on:click="toogleType()" v-if="config.number != null && config.price != null"></i>
				<h1 v-if="type == 'number'">{{ config.number | number }}</h1>
				<h1 v-if="type == 'money'">R$ {{ config.price | price }}</h1>
				<p>{{ config.label }}</p>
			</div>
		</section>
	</div>
</template>