import store from "../store/store";
import api from "./api";

export default {	
	getToken: async () => {
		const now = new Date;  		
		// faz o refresh do token		
		return await api.request("app/no_action", "admin/user/refresh_token", "get").then(response => {
			if(response && response.body.return_code == 1){						
				let _usuario = store.state.app.usuario;
				_usuario.token = response.body.data.token;
				_usuario.token_expiration = response.body.data.token_expiration;						
				_usuario.tokenUpdated = now.getTime();
				// atualiza os dados do usuário
				store.commit("app/login", { "data": _usuario });
				return response.body.data.token;
			}else{
				return null;
			}
		});			
	},
	setToken: (token) => {		
		const now = new Date;
		// grava os cookies
		document.cookie = "tokenApplication="+JSON.stringify({token: token, tokenUpdated: now.getTime()});		
	}
};
