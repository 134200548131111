<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="card card-base">
					<div class="card-header">
						Informações Gerais do Template
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" extra_class="input_subject" label="Assunto" placeholder="" info="" v-model="model.subject" :slug_function=false />
							</div>							
							<div class="col-6">
								<FormSelect :required=true rules="option_store" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Layout Base" info="" :options="layouts" v-model="model.layout" type="string" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Tipos de Template" info="" :options="templateTypes" v-model="model.type" type="string" />
							</div>							
							<div class="col-12 shortcodes mb-3">
								<div class="alert alert-primary" role="alert">
									Para usar um Shortcode basta clicar sobre o botão correspondente abaixo, depois ir até a parte do texto desejada e apertar ctrl + v.
								</div>
								<button
									v-for="(shortcode, index) in shortcodes"
									:key="index"
									v-on:click.prevent="addShortcode(shortcode.shortcode)"
									class="btn btn-info btn-sm mx-1 my-1">
									{{ shortcode.label }} <i class="pl-2 fas fa-save"></i>
								</button>
							</div>
							<div class="col-12">
								<FormTextarea state="templates_email" upload_type="editor_templates" ref="cp_editor" :required=true label="Conteúdo do E-mail" v-model="model.text" :rows=3 placeholder="" info="" type="editor" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import shortcodes from "./../../../assets/json/shortcodes";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormTextarea from "./../../common/form/Textarea";
import Alerts from "../../../common/alerts";



export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormTextarea,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.templates_email.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},		
	},	
	data () {
		return {
			header_info: {
				title: "Templates de E-mails",
				chamada: "Gerencie os templates dos e-mails da sua loja",
				links: {
					back: "email.template.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Templates de E-mails",
					link: "/email/templates"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[],
			disabled_items: [],
			hidden_items: [],
			layouts: [],
			shortcodes: shortcodes,
			in_focus: null,
			templateTypes: [
				{
					id: "status",
					label: "Status de Pedidos"
				},
				{
					id: "boleto",
					label: "Envio de Boleto"
				},
				{
					id: "password",
					label: "Nova Senha"
				},
				{
					id: "new_customer",
					label: "Novo Cadastro de Cliente"
				},
				{
					id: "documents",
					label: "Aguardando Documentos"
				},
				{
					id: "voucher",
					label: "Voucher"
				},
				{
					id: "ticket",
					label: "Tickets"
				},
				{
					id: "raffle",
					label: "Sorteio"
				},
				{
					id: "question_answer",
					label: "Resposta de Dúvidas"
				},
				{
					id: "stock_alert",
					label: "Alerta de Estoque"
				}
			]
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				// salva
				this.$store.dispatch("templates_email/save", this.model);
			}
		},
		addShortcode: function(shortcode){

			let input = document.createElement("input");
			document.getElementsByTagName("body")[0].append(input);
			input.value = shortcode;
			input.select();
			document.execCommand("copy");
			input.remove();

			Alerts.alertTop("success","Shortcode copiado com sucesso!");

			// switch(this.in_focus){
			// case "subject":
			// 	// no input
			// 	var input = document.getElementsByClassName("input_subject")[0];				
			// 	//IE support
			// 	if (document.selection) {
			// 		input.focus();
			// 		let sel = document.selection.createRange();
			// 		sel.text = shortcode;
			// 	}			
			// 	//MOZILLA and others
			// 	else if (input.selectionStart || input.selectionStart == "0") {
			// 		var startPos = input.selectionStart;
			// 		var endPos = input.selectionEnd;
			// 		input.value = input.value.substring(0, startPos)
			// 			+ shortcode
			// 			+ input.value.substring(endPos);
			// 	} else {
			// 		input.value += shortcode;
			// 	}		
			// 	break;
			// case "editor":
			// 	// editor				
			// 	var elem = document.getElementsByClassName("ql-editor")[0];
			// 	var range = this.$refs.cp_editor.getRangeIndex();			
			// 	var texto_atual = elem.innerText;							
			// 	texto_atual = texto_atual.replace(/\r/g, "");	
			// 	texto_atual = texto_atual.replace(/\n\n/g, "\n");				
			// 	texto_atual = texto_atual.replace(/<br>/g, "");
			// 	var new_text = texto_atual.substring(0, range)
			// 					+ shortcode
			// 					+ texto_atual.substring(range);								
			// 	elem.textContent = new_text;
			// 	break;
			// }
		}	
	},
	provide: function () {
		return {
			save: this.save,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("templates_email/reset");

		this.$store.dispatch("layout/load_templates").then(response => {
			self.layouts = response;
		});

		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("templates_email/load", this.$route.params.id);
		}
	},
	mounted() {
		let self = this;
		document.getElementsByClassName("input_subject")[0].addEventListener("focus", function() {
			self.in_focus = "subject";
		});

		document.getElementsByClassName("ql-editor")[0].addEventListener("focus", function() {
			self.in_focus = "editor";
		});
	},
	watch: {		
	}
};
</script>

<style scoped lang="scss">	
	.shortcodes{
		.btn{
			i{
				opacity:0.4;
			}
			&:hover{
				i{
					opacity:1;
				}
			}
		}
	}
</style>
