<template>
	<div class="row row-header" ref="header">
		<div class="col-12 py-2" id="botoes_topo">
			<ul class="botoes_topo">				
				<li v-if="!is_order">					
					<button v-if="dados.links.content_validation && permissao.editar == 1" class="btn-primary btn" v-on:click="finish('content')"><i class="fas fa-archive mr-1"></i> Confirmar Validação Conteúdo</button>
					<button v-if="!dados.links.content_validation && dados.links.sale_validation && permissao.editar == 1" class="btn-primary btn" v-on:click="finish('back-content')"><i class="fas fa-archive mr-1"></i> Voltar para Conteúdo</button>
					<button v-if="!dados.links.content_validation && dados.links.sale_validation && permissao.editar == 1" class="btn-danger btn" v-on:click="finish('sale')"><i class="fas fa-archive mr-1"></i> Confirmar Validação Final</button>
					<button v-if="dados.links.clear" class="btn-danger btn" v-on:click="clear()"><i class="fas fa-trash"></i> Limpar</button>
					<button v-if="dados.links.finish && permissao.editar == 1" class="btn-danger btn" v-on:click="finish()"><i class="fas fa-archive mr-1"></i> Finalizar</button>
					<router-link v-if="dados.links.criar && permissao.criar == 1" class="btn-success btn" :to="{ name: dados.links.criar }"><i class="fas fa-plus"></i> Adicionar</router-link>
					<router-link v-if="dados.links.back" class="btn-warning btn" :to="{ name: dados.links.back }"><i class="fas fa-arrow-left"></i> Voltar</router-link>					
					<button v-if="dados.links.delete && permissao.excluir == 1" class="btn-danger btn" v-on:click="excluir()"><i class="fas fa-trash mr-1"></i> Excluir</button>
					<button v-if="dados.links.cancel" class="btn-warning btn" v-on:click="cancel()"><i class="fas fa-undo-alt mr-1"></i> Cancelar</button>
					<button v-if="dados.links.save && (permissao.criar == 1 || permissao.editar == 1)" class="btn-success btn" v-on:click="save()"><i class="fas fa-save mr-1"></i> Salvar</button>
					<button v-if="dados.links.print" class="btn btn-success" v-on:click.prevent="print()"><i class="fa fa-print"></i> Imprimir</button>
					<button v-if="dados.links.resume" class="btn-primary btn btn-lg" v-on:click="gerarResumo()"><i class="fas fa-database"></i> Mostrar Resumo</button>					
				</li>
				<li v-if="is_order && pedido.status_proximo && pedido.status_atual && pedido.status_anterior">						
					<template v-if="pedido.permissao_manager">
						<a class="btn btn-success" v-on:click.prevent="alteraStatus(pedido.status_proximo)" v-if="pedido.status_proximo.type == 'paid' && pedido.status_atual.type != 'canceled'"><span class="fa fa-usd"></span> Confirmar Pagamento</a>
						<a class="btn btn-success" v-on:click.prevent="alteraStatus(pedido.status_proximo)" v-if="pedido.status_proximo.type == 'sent' && pedido.status_atual.type != 'canceled'"><span class="fa fa-paper-plane"></span> Confirmar Envio</a>
						<a class="btn btn-success" v-on:click.prevent="alteraStatus(pedido.status_proximo)" v-if="pedido.status_proximo.type == 'delivered' && pedido.status_atual.type != 'canceled'"><span class="fa fa-archive"></span> Confirmar Entrega</a>
						<a class="btn btn-danger" v-on:click.prevent="alteraStatus(pedido.options.filter(item=>{return item.type=='canceled'})[0])" v-if="pedido.status_atual.type != 'canceled'"><span class="fa fa-ban"></span> Cancelar</a>						
					</template>
				</li>
				<li v-if="is_order && pedido.status_atual.type != 'canceled' && pedido.permissao_manager">
					<div class="dropdown">
						<button v-on:click="openDrop = !openDrop;" class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
							<span class="fa fa-list"></span> Alterar Status
						</button>							
						<ul class="dropdown-menu dropdown-menu-right " v-bind:class="{show: openDrop}" aria-labelledby="dropdownMenu1">								
							<li v-for="status in pedido.options" :key="status.id" v-show="status.id != pedido.status.id">
								<a href="#" v-on:click.prevent="alteraStatus(status)"><i class="fa fa-check"></i>{{ status.name }}</a>
							</li>
						</ul>
					</div>
				</li>
			</ul>
			<h1>{{ dados.title }}</h1>
			<p>{{ dados.chamada }}</p>
		</div>		
	</div>
</template>

<script>

export default {
	name: "Header",
	props: {
		dados: Object,
		modulo_permissao: String,
		pedido: {
			type: Object,
			default: () => {
				return {};
			}
		},
		is_order:{
			type: Boolean,
			default: false
		},
		btn_preview: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},
	data () {
		return {
			permissao: {},
			openDrop: false
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	inject: {
		save: {
			// passa um default como uma função vazia pois o Header é usado em outros lugares que não usa a função save()
			default: () => {}
		},
		excluir: {
			// passa um default como uma função vazia pois o Header é usado em outros lugares que não usa a função save()
			default: () => {}
		},
		cancel: {
			// passa um default como uma função vazia pois o Header é usado em outros lugares que não usa a função save()
			default: () => {}
		},
		alteraStatus: {
			default: () => {}
		},
		print: {
			default: () => {}	
		},
		gerarResumo: {
			default: () => {}
		},
		finish: {
			default: () => {}
		},
		previewAction: {
			default: () => {}
		},
		clear: {
			default: () => {}
		},

	},
	methods: {		
		
	},
	mounted(){
		window.onscroll = () => {
			// let offset = window.scrollTop;
			let offset = document.documentElement.scrollTop || document.body.scrollTop;
			if(document.getElementById("botoes_topo")){
				if(offset >= 100){
					document.getElementById("botoes_topo").classList.add("flutuante");
				}else{
					document.getElementById("botoes_topo").classList.remove("flutuante");
				}
			}			
		};
	}
};
</script>

<style scoped lang="scss">	
	.row{
		&.row-header{
			min-height: 3.4em;
		}
		background: $bg_clean;
		border-bottom: 1px solid $border_light;
		h1{
			font-size: 20px;
			padding: 0;
			margin: 0;
			font-weight: 500;
		}
		p{
			color:$color_light;
			font-size: 14px;
			padding: 0;
			margin: 0;
		}
		.botoes_topo{
			float: right;
			li {
				display: inline-block;
				list-style: none;
				a {
					margin-left: 10px;
					font-weight: bold;		
					&.btn-warning{
						color: #FFF;
					}			
				}
				button {
					margin-left: 10px;
					font-weight: bold;			
					// background: $color_blue;
					border:none;		
					&.btn-warning{
						color: #FFF;
					}	
				}
				.dropdown-menu{
					min-width: 20em;
					li{     
						width: 100%;               
						a{
							display: block;
							padding: 0.5em 1em;
							margin: 0;
							color: $color_blue;
							i{
								margin-right: 0.5em;
								color: #FFF;
							}
							&:hover{
								background: $color_blue;
								color:#FFF;
								text-decoration: none;
							}
						}
					}
				}
			}			
		}
		.flutuante{
			position: fixed;
			top: 0;
			right: 0;
			// width: 100%;
			// text-align: right;
			z-index: 99998;
			background: #FFF;
			// padding: 0.5em;
			box-shadow: 2px 2px 6px 2px #666666;  
		}
	}
</style>
