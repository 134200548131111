export default {
	reset(state, payload) {
		for (let p in state) {
			state[p] = payload[p];
		}
	},
	refresh(state,payload){
		let valor;
		switch(payload.type){
		case "box_geral":
			valor = eval(state[payload.type].period.refresh) + 1;
			state[payload.type].period.refresh = valor;
			break;
		case "carts":
		case "orders":
			valor = eval(state.lines[payload.type].period.refresh) + 1;
			state.lines[payload.type].period.refresh = valor;
			break;
		case "products_best_sellers":
		case "products_abandoned":
		case "states_best_sellers":
			valor = eval(state.bars[payload.type].period.refresh) + 1;
			state.bars[payload.type].period.refresh = valor;
			break;
		default:
                
			break;
		}
        
	},
	periods(state,payload) {
		switch(payload.module){
		case "box_orders":
		case "box_cart":
		case "box_customers":
		case "box_payment_problem":
			state.box_geral.period.date_init = payload.date_init;
			state.box_geral.period.date_end = payload.date_end;
			break;

		case "carts":
		case "orders":
			state.lines[payload.module].period.date_init = payload.date_init;
			state.lines[payload.module].period.date_end = payload.date_end;
			state.lines[payload.module].period.group = payload.group;
			break;

		case "products_best_sellers":
		case "products_abandoned":
		case "states_best_sellers":
			state.bars[payload.module].period.date_init = payload.date_init;
			state.bars[payload.module].period.date_end = payload.date_end;
			state.bars[payload.module].period.group = payload.group;
			break;

		default:
                
			break;
		}
	},
	box_data_box_orders(state,payload){
		if(payload.return_code == 1){
			let data = payload.data;
			state.box_geral.box_orders.number = data.number;
			state.box_geral.box_orders.price = data.price;
		}
	},
	box_data_box_cart(state,payload){
		if(payload.return_code == 1){
			let data = payload.data;
			state.box_geral.box_cart.number = data.number;
			state.box_geral.box_cart.price = data.price;
		}
	},
	box_data_box_customers(state,payload){
		if(payload.return_code == 1){
			let data = payload.data;
			state.box_geral.box_customers.number = data.number;
			state.box_geral.box_customers.price = null;
		}
	},
	box_data_box_payment_problem(state,payload){
		if(payload.return_code == 1){
			let data = payload.data;
			state.box_geral.box_payment_problem.number = data.number;
			state.box_geral.box_payment_problem.price = data.price;
		}
	},
	lines_carts(state,payload){
		if(payload.return_code == 1){
			state.lines.carts.data = payload.data;   
		}
	},
	lines_orders(state,payload){
		if(payload.return_code == 1){
			state.lines.orders.data = payload.data;   
		}
	},
	bars_products_best_sellers(state,payload){
		if(payload.return_code == 1){
			state.bars.products_best_sellers.data = payload.data;   
		}
	},
	bars_products_abandoned(state,payload){
		if(payload.return_code == 1){
			state.bars.products_abandoned.data = payload.data;   
		}
	},
	bars_states_best_sellers(state,payload){
		if(payload.return_code == 1){
			state.bars.states_best_sellers.data = payload.data;   
		}
	}
    
};