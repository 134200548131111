import api from "./../../common/api";

export default {
	//form
	get_report: function(state, data) {                
		const filters = [
			{ 
				field: "created_at", 
				value: `${data.initial_date} 00:00:00`,
				operator: ">=",
				type: "datetime"
			},
			{ 
				field: "created_at", 
				value: `${data.final_date} 23:59:59`,
				operator: "<=",
				type: "datetime"
			},		
		];
                
		// pegando os fields que é para trazer
		const fields = [
			"total_order",
			"total_shipping",
			"marketplace",
			"products.quantity",
			"products.unit_cost",
			"products.cost",
			"products.unit_price",
			"store_id",
			"shipment",
			"status",
			"status_log",
			"payments",
			"shipping_real_cost"
		];
		
		return api.request(
			"reports_sales/data_resume_loaded",
			"admin/orders",
			"post",
			{
				fields,
				filters,
				sort: "created_at,desc",
				limit: 99999999,
				page: 1
			}).then(response => {
			return response.body;
		});
	},
	/**
	 * Gets in the API all the order sales sum by a given period and store id.
	 *
	 * @param state
	 * @param {object } data
	 * @param { number } data.store_id
	 * @param { string } data.initial_date
	 * @param { string } data.final_date
	 * @param { string[] } data.sources
	 */
	get_all_sales_by_period: async (state, data) => {
		const response = await api.request(
			"reports_sales/data_resume_loaded",
			"admin/reports/sales-by-period",
			"post",
			{
				initial_date: data.initial_date,
				final_date: data.final_date,
				store_id: data.store_id,
				sources: data.sources
			}
		);

		if (response.body.return_code !== 1) {
			throw new Error("Request has failed");
		}

		return response.body.data;
	},
	/**
	 *
	 *
	 * @param state
	 * @param {object } data
	 * @param { number } data.storeId
	 * @param { string } data.initialDate
	 * @param { string } data.finalDate
	 * @param { string } data.categoryId
	 */
	getCategorySalesReport: async ({state}, categoryId) => {
		const response = await api.request(
			"categorias/set_category_report",
			"admin/reports/sales-by-category",
			"post",
			{
				initial_date: state.filterData.startDate,
				final_date: state.filterData.endDate,
				store_id: state.filterData.store,
				category_id: categoryId,
				limit: state.filterData.productsQuantity,
				sources: state.filterData.sources
			}
		);

		if (response?.return_code === 0) {
			throw new Error("Request has failed");
		}
	}
};