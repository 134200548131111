export default {
	init: function () {
		return {
			model: {
				id: null,
				origin: null,
				order_id: null,
				amount: null,
				interest_amount: null,
				cash_discount: null,
				products: {
					amount: null,
					itens: []
				},
				shipment: {
					amount: null,
					tracking_number: null,
					shipping_method: null,
					id: null,
					free: null, // 1 e 2 para sim / não
					deadline: null,
					company: {}
				},
				coupoun: {
					amount: null,
					type: null,
					code: null,
				},
				customer: {},
				ip_address: null,
				reverse_ip_address: null,
				invoice: {
					number: null,
					range: null,
					status: null,
					key: null,
					document_link: null
				},
				status: {

				},
				create_date: null,
				cancel_reason: null,
				payments: [],
				fraud: {
					score: null,
					status: null,
					status_description: null
				},
				seller: {
					id: null,
					name: null
				},
				notes: [],
				address: [],
				log: [],
				store_id: null,
				extra_costs: 0
			},
			cart: {},
			status: [],
			motivos_cancelamento: [],
			cities: [],
			pedidos_impressao: [],
			opcoesPagamento: [],
			//lista
			search_data: {
				opcoes: {
					order_field: "created_at",
					order: "desc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
			notes: [],
			shipment_dash: null,
		};
	}
};
