import { render, staticRenderFns } from "./SenhasLista.vue?vue&type=template&id=33495dae&scoped=true&"
import script from "./SenhasLista.vue?vue&type=script&lang=js&"
export * from "./SenhasLista.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33495dae",
  null
  
)

export default component.exports