export default {
	reset(state, payload) {
		for (let p in state) {
			state[p] = payload[p];
		}
	},
	loaded(state, payload) {
		if (payload.return_code == 1) {			
			// pega o model como veio do banco
			let model = state.model;
			// percorre o que veio do banco para ver se tem campo a mais
			//  e preencher corretamente os valores dos que existem 
			for(let chave in payload.data){
				if (chave == "description"){
					if(payload.data[chave]){
						payload.data[chave] = payload.data[chave].replace(/\r\n/g,"");
						payload.data[chave] = payload.data[chave].replace(/> {4}</g,"><");
					}	
				}
				model[chave] = payload.data[chave];
			}            
			state.model = model;
		}
	},
	no_action(){

	},
	nova_categoria(state,dados) {
		let model = dados.default;
		for(let chave in dados.categoria){
			model[chave] = dados.categoria[chave];
		}   		
		state.model = model;
	},
	multiselect_push: function(state,data){
		state.model[data.tipo].push(data.item);
	},
	multiselect_update: function(state,data){
		state.model[data.tipo] = data.item;
	},
	load_categorias: function(state,payload){
		if(payload.return_code == 1){
			state.categorias = payload.data;
		}
	},
	data_filtros_loaded: function (state, payload) {
		state.table_fields = payload;
	},
	load_categories_by_store(state, payload) {
		state.categorias = payload.data;
	},
	/**
	 *
	 * @param state - the current 'categorias' state.
	 * @param { object } data -
	 */
	set_category_report(state, { data }) {
		const currentReports = state.categoriesReports ?? [];
		state.categoriesReports = [...currentReports, { orderBy: "quantity", ...data }];
	},
	/**
	 *
	 * @param state - the current 'categorias' state.
	 * @param categoryId
	 */
	remove_category_report(state, categoryId) {
		state.categoriesReports = state.categoriesReports.filter(report => report.categoryId !== categoryId);
	},
	order_category_products(state, {field, categoryId}) {
		state.categorieReports = state.categoriesReports.map(category => {
			if (category.categoryId === categoryId) {
				category.orderBy = field  === "quantity" ? "ticket" : "quantity";
			}
			return category;
		});
	},
	incluir_categoria: function(state, data){
		state.selecionados.push(data);
		this.dispatch("reports_sales/getCategorySalesReport", data.id);
	},
	remover_categoria: function(state, data){
		let categorias = Object.assign([],state.selecionados);
		let index = categorias.findIndex(item => {
			return item.id == data.id;
		});
		categorias.splice(index,1);
		state.selecionados = categorias;
		this.commit("categorias/remove_category_report", data.id);
	},
	reset_report_sales_by_category(state) {
		state.selecionados = [];
		state.categoriesReports = [];
		state.categorias = [];
	}
};