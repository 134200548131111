<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<div class="row">
					<div class="col-8">
						<div class="card card-base" :class="itens_tab.length > 0">
							<div class="card-header">
								Informações do Atributo
							</div>
							<div class="card-body">
								<!-- Conteudo -->
								<div class="row">
									<div class="col-12">
										<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" />
									</div>									
									<div class="col-12">
										<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
									</div>
								</div>						
								<!-- Fim Conteudo -->
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="card card-base" :class="itens_tab.length > 0">
							<div class="card-header">
								Atributos
							</div>
							<div class="card-body">
								<!-- Conteudo -->
								<MultiSelect state="produto_tipos" tipo="attributes" :dados="atributos" :field_required=true :required=false></MultiSelect>
								<!-- Fim Conteudo -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import alerts from "./../../../common/alerts";
import {Slug} from "./../../../common/utils";
import MultiSelect from "./../../common/MultiSelect";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";

export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		MultiSelect,
		FormSelect
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.produto_tipos.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Tipos de Produtos",
				chamada: "Gerencie os tipos de produtos da sua loja",
				links: {
					back: "produto.tipos.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Tipos de Produtos",
					link: "/produto/tipos"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[],
			disabled_items: [],
			hidden_items: [],
			atributos: [],
			atributos_selecionados: []
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){				
				if(this.model.attributes.length == 0) {
					alerts.alertTop("error","É necessário inserir ao menos um atributo.");
					return false;
				}

				//cria o slug
				this.model.slug = Slug(this.model.name);

				// salva
				this.$store.dispatch("produto_tipos/save", this.model);
			}
		},
	},
	provide: function () {
		return {
			save: this.save
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("produto_tipos/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("produto_tipos/load", this.$route.params.id);		
		}
		// pega os atributos
		this.$store.dispatch("atributos/load_attributes").then((response) => {
			self.atributos = response;
		});
	},
	watch: {
		
	}
};
</script>

<style scoped lang="scss">	
	
</style>
