<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro my-3">
			<div class="col-6 my-3">
				<div class="card card-base">
					<div class="card-header">
						Produtos do pedido
					</div>
					<div class="card-body">
						<ProdutosPedidos state="pedidos" tipo="products" :needs_store=true :store_id=cart.store_id :cart=cart :in_stock=true ></ProdutosPedidos>
					</div>
				</div>
			</div>	
			<div class="col-6 my-3">
				<div class="card card-base">
					<div class="card-header">
						Dados do Cliente 
					</div>
					<div class="card-body">
						<Clientes ref="customers" state="pedidos" />
					</div>
				</div>
			</div>
			<div class="col-4 my-3">
				<div class="card card-base">
					<div class="card-header">
						Valores do Pedido
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<FormSelect :required=false rules="option" label="Empresa Responsável" info="" :options="[{ id: 0, label: 'Automático' }, { id: 2, label: 'CEM' }, { id: 3, label: 'NOVO COMERCIO' }, { id: 7, label: 'LEXO' }, { id: 9, label: 'ECON' }, { id: 12, label: 'MDC GROUP' }]" v-model="company_id" type="int" />
								<ul class="resumo">													
									<li><strong>Produtos:</strong> <span class="positivo"> {{ cart.total_products | money }}</span></li>
									<li v-if="cart.delivery && cart.delivery.shipments.length > 0"><strong>Frete:</strong> <span class="positivo"> {{ pegaFreteValor() | money }}</span></li>
									<li><strong>Descontos:</strong> <span class="negativo"> {{ cart.total_discounts - cart.shipping_discount | money }}</span></li>
									<li class="total"><strong>Total:</strong> <span>{{ cart.total_sale | money }}</span></li>
									<li><strong>À vista:</strong> <span> {{ cart.total_cash | money }}</span></li>									
								</ul>						
								<div class="alert alert-info my-3" role="alert">Para encontrar o valor a prazo a partir do valor a vista multiplique o valor a vista por: <strong>{{ cart.total_sale / cart.total_cash }} </strong></div>
							</div>							
						</div>						
					</div>
				</div>
			</div>
			<div class="col-4 my-3">
				<div class="card card-base">
					<div class="card-header">
						Frete
					</div>
					<div class="card-body" v-if="cart.delivery">
						<FormInputGroup :required=true rules="string" type="postalcode" label="" placeholder="00000-000" info="" position="right" action_button="Buscar" v-model="cart.delivery.postalcode" />
						<div class="frete-results" v-on:click="selecionaFrete(ship)" v-for="(ship, index) in cart.delivery.shipments" :key="index">
							<div class="frete-check" v-bind:class="{'checked': cart.delivery.shipping == ship.type}"><i class="fas fa-check"></i></div>
							<div class="frete-desc">
								<span>{{ ship.name }}</span><br />
								<span class="fpr-preco" v-if="ship.price > 0">{{ ship.price | money}}</span>													
								<span class="fpr-preco" v-else>R$ 0,00</span>													
								<span class="fpr-prazo"> - {{ ship.delivery_time | filterDelivery}} </span>
							</div>
						</div>						
					</div>
				</div>
			</div>
			<div class="col-4 my-3">
				<div class="card card-base">
					<div class="card-header">
						Endereço de Entrega
					</div>
					<div class="card-body" v-if="cart.delivery">
						<div class="row" v-if="address.postalcode != ''">
							<div class="col-12">	
								<FormInputGroup :required=true rules="string" type="postalcode" label="" placeholder="" info="" position="left" text="CEP" v-model="address.postalcode" />
							</div>                        
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="" placeholder="" info="" position="left" text="Cuidados" v-model="address.contact" />								
							</div>                        
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="" placeholder="" info="" position="left" text="Rua" v-model="address.street" />
							</div>
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="" placeholder="" info="" position="left" text="Número" v-model="address.number" />								
							</div>
							<div class="col-12">
								<FormInputGroup :required=false rules="string" label="" placeholder="" info="" position="left" text="Complemento" v-model="address.complement" />																
							</div>
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="" placeholder="" info="" position="left" text="Bairro" v-model="address.neighborhood" />								
							</div>
							<div class="col-12">	
								<div class="form-group">																				
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Estado: </span>
										</div>
										<select class="form-control" placeholder="Estado" v-model="address.sa" v-on:change="alteraState()">
											<option value="">Estado</option>
											<option :value="state.sigla" v-for="(state,i) in states" :key="i">{{ state.name }}</option>
										</select>														
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="form-group">
									<div class="input-group">
										<div class="input-group-prepend">
											<span class="input-group-text">Cidade: </span>
										</div>
										<select class="form-control" placeholder="" v-model="address.city">
											<option value=""></option>
											<option :value="city.city_name" v-for="(city,i) in cities" :key="i">{{ city.city_name }}</option>
										</select>														
									</div>
								</div>								
							</div>												
							<div class="col-12">
								<FormInputGroup :required=true rules="string" type="phones" label="" placeholder="" info="" position="left" text="Telefone" v-model="address.phone" />								
							</div>
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="" placeholder="" info="" position="left" text="Referência" v-model="address.reference" />
							</div>
						</div>
						<div v-else class="alert alert-info" role="alert">Informe o CEP para preencher o endereço</div>						
					</div>
				</div>
			</div>
			<div class="col-12 my-3">
				<div class="card card-base">
					<div class="card-header">
						Formas de Pagamento
					</div>
					<div class="card-body">
						<div class="row payments">
							<div class="col-3">								
								<ul class="list-group">
									<li class="list-group-item py-3" v-on:click="addPagamento('credit_card')">Cartão de Crédito <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('invoice')">Boleto Bancário <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('deposit')">Depósito Bancário <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('pix')">Pix <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('withdraw')">Pagamento Retirada <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('internal')">Pagamento Interno <i class="fas fa-plus"></i></li>
									<li class="list-group-item py-3" v-on:click="addPagamento('pagaleve')">Pix Parcelado (Paga Leve) <i class="fas fa-plus"></i></li>
								</ul>
							</div>
							<div class="col-9">
								<div class="row cards-payment">
									<div class="col-4 mb-4" v-for="(payment,i) in opcoesPagamento" :key=i>
										<div class="card">
											<div class="card-header">
												{{ pegaNomePagamento(payment) }}
											</div>
											<div class="card-body pb-1">
												<FormInputGroup :key=force_update_key :required=true rules="string" type="money" label="" placeholder="" :info="infoAvista(payment.avista)" position="left" text="R$ " v-model="payment.valor" @input="controlaValoresPagamento()" />
												<div class="credit_card" v-if="payment.tipo == 'credit_card'">
													<FormSelect :required=false rules="option" label="Informar dados do Cartão?" info="" :options="[{ id: true, label: 'Sim' },{ id: false, label: 'Não' }]" v-model="payment.set_data" type="boolean" />
													<div v-if="payment.set_data">
														<FormInput :required=false rules="string" label="Nome do Titular do Cartão" placeholder="" info="" v-model="payment.nome" data-checkout="cardholderName" />
														<FormInput :required=false rules="string" type="number" label="Número do Cartão" placeholder="" info="" v-model="payment.numero" data-checkout="cardNumber" />
														<FormSelect :required=false rules="option" label="Tipo Documento" info="" :options="[{id: 'CPF', label: 'CPF'},{id: 'CNPJ', label: 'CNPJ'}]" v-model="payment.document_type" data-checkout="docType" />
														<FormInput :required=false rules="string" label="CPF / CNPJ" placeholder="" info="" v-model="payment.document" data-checkout="docNumber" />
														<div class="row">
															<div class="col-6">
																<FormSelect :required=false rules="option" label="Mês" info="" :options="validade.mes" v-model="payment.mes" data-checkout="cardExpirationMonth" />
															</div>
															<div class="col-6">
																<FormSelect :required=false rules="option" label="Ano" info="" :options="validade.ano" v-model="payment.ano" data-checkout="cardExpirationYear" />
															</div>
														</div>
														<FormInput :required=false type="text" rules="string" label="Código de Segurança" placeholder="" info="" v-model="payment.codigo" data-checkout="securityCode" />														
													</div>
													<FormSelect v-if="payment.set_data || payment.force_installments" :required=false rules="option" label="Número de Parcelas" info="" :options="payment.opcao_parcelas" v-model="payment.parcelas" />
													<FormSelect v-if="hasPermission('editar', true)" :required=false rules="option" label="Forçar Parcela" info="" :options="[{ id: true, label: 'Sim' },{ id: false, label: 'Não' }]" v-model="payment.force_installments" type="boolean" />
												</div>
												<div class="boleto" v-if="payment.tipo == 'invoice'">
													
												</div>
												<div class="pix" v-if="payment.tipo == 'pix'">
													
												</div>
												<div class="deposito" v-if="payment.tipo == 'deposit'">
													<FormSelect :required=true rules="option" label="Selecione o Banco" info="" :options="banks" v-model="payment.bank" />
												</div>
												<div class="retirada" v-if="payment.tipo == 'withdraw'">
													<FormSelect :required=true rules="option" label="Opção de Pagamento" info="" :options="[{id: 'credit_card', label: 'Cartão de Crédito'}, {id: 'debit_card', label: 'Cartão de Débito'}, {id: 'cash',label: 'Dinheiro'}]" v-model="payment.payment_type" />
													<FormSelect v-if="payment.payment_type == 'credit_card'" :required=false rules="option" label="Número de Parcelas" info="" :options="payment.opcao_parcelas" v-model="payment.parcelas" />
												</div>
												<div class="internal" v-if="payment.tipo == 'internal'">
													
												</div>
												<div class="pagaleve" v-if="payment.tipo == 'pagaleve'">
													
												</div>
											</div>
											<div class="card-footer">
												<button class="btn btn-danger btn-block btn-sm" v-on:click="removerPagamento(i)">Remover Este Pagamento</button>
											</div>
										</div>
									</div>							
								</div>
							</div>							
						</div>						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Vue from "vue";
import VueMeta from "vue-meta";
import VueResource from "vue-resource";

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import validation from "./../../../common/validation";
import ProdutosPedidos from "./utils/ProdutosPedido";
import Clientes from "./utils/Clientes";
import Filters from "./../../../common/filters";
import FormInputGroup from "./../../common/form/InputGroup";
import FormSelect from "./../../common/form/Select";
import FormInput from "./../../common/form/Input";
import Alerts from "./../../../common/alerts";
import states from "./../../../assets/json/states";
import validade from "./../../../assets/json/validade";

Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true
});

Vue.use(VueResource);


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		ProdutosPedidos,
		Clientes,
		FormInputGroup,
		FormSelect,
		FormInput
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		cart() {
			return this.$store.state.pedidos.cart;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		cities(){
			return this.$store.state.pedidos.cities;
		},
		opcoesPagamento(){
			return this.$store.state.pedidos.opcoesPagamento;
		},
		usuario() {
			return this.$store.state.app.usuario;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Pedidos > Novo",
				chamada: "Faça os pedidos dos clientes",
				links: {
					back: "pedidos.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Pedidos",
					link: "/pedidos"
				},
				{
					name: "Criar",
					link: ""
				}
			],
			itens_tab:[
			],
			disabled_items: [],
			hidden_items: [],
			address: {
				postalcode: "",
				contact: "",
				street: "",
				number: "",
				neighborhood: "",
				city: "",
				state: "",
				complement: "",
				phone: "",
				reference: ""
			},
			states: states,		
			force_update_key: 0,
			validade: validade,
			payment_type: "installments",
			gateways: [],
			merchant_id: null,
			banks: [],
			permissao: [],
			config: {
				modulo_permissao: "pedidos"
			},
			company_id: 0
		};
	},  
	methods: {		
		hasPermission(type, manager) {
			if(manager == undefined){
				manager = false;
			}
			if(!manager){
				return this.permissao[type] === 1;
			}else{
				return this.usuario.manager && this.permissao[type] === 1;
			}
		},
		async save(){			
			if(validation.validation().valid){

				Alerts.loader(true);

				let self = this;
				let msg_erro;
				// fecha o pedido
				let pedido = {};
				pedido.cart_id = this.cart.id;
				pedido.type = this.payment_type;
				// pegando e validando o endereço
				pedido.address = this.address;
				msg_erro = "";
				for (let key in pedido.address) {
					if(pedido.address[key] == "" && !["complement"].includes(key)){
						// valida o erro
						let name = "";
						switch(key){
						case "postalcode":
							name = "CEP";
							break ;
						case "contact":
							name = "Aos Cuidados";
							break ;
						case "street":
							name = "Rua";
							break ;
						case "number":
							name = "Número";
							break ;
						case "neighborhood":
							name = "Bairro";
							break ;
						case "city":
							name = "Cidade";
							break ;
						case "state":
							name = "Estado";
							break ;
						case "complement":
							name = "Complemento";
							break ;
						case "phone":
							name = "Telefone";
							break ;
						case "reference":
							name = "Referência";
							break ;
						}
						// eslint-disable-next-line quotes
						msg_erro += '- O campo "'+name+'" é obrigatório.<br />';
					}else{
						if(key == "postalcode"){
							// valida se o cep foi alterado
							let cep_delivery = self.cart.delivery.postalcode.replace(".","").replace("-","").replace(" ","");
							let cep_address = pedido.address[key].replace(".","").replace("-","").replace(" ","");
							if(cep_delivery != cep_address){
								msg_erro += "- O CEP do endereço deve ser o mesmo calculado no frete.<br />";
							}
						}
					}
				}

				// validando se tem forma de envio selecionada
				if(this.cart.delivery.shipping == null || this.cart.delivery.shipments.length == 0){
					msg_erro += "- Selecione uma forma de envio para continuar.<br />";
				}

				// validando formas de pagamento
				if(this.opcoesPagamento.length == 0){
					msg_erro += "- Selecione uma forma de pagamento para continuar.<br />";
				}

				if(msg_erro != ""){
					Alerts.loader(false);
					Alerts.alertTop("error",msg_erro);
					return;
				}	

				if(!pedido.address.phones){
					pedido.address.phones = [];
					pedido.address.phones.push({number: pedido.address.phone});
				}

				// endereço de billing
				pedido.address_billing = pedido.address;
				
				// guarda a somatória dos valores dos pagamentos para validação
				pedido.payments = [];				
				this.opcoesPagamento.forEach(function(item){
					// popula o payments
					var erro = 0;
					if(item.tipo == "invoice"){
						// validação boleto
						if(item.valor <= 0){
							msg_erro = "O valor do boleto tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							pedido.payments.push({
								type: "invoice",
								value: item.avista,
								prices: {
									value: item.valor,
									cash_value: item.avista
								}
							});  
						}    
					}else if(item.tipo == "deposit"){
						// validação boleto
						if(item.valor <= 0){
							msg_erro = "O valor do depósito tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							pedido.payments.push({
								type: "deposit",
								value: item.avista,
								bank: item.bank,
								prices: {
									value: item.valor,
									cash_value: item.avista
								}
							});   
						}   
					}else if(item.tipo == "pix"){
						// validação boleto
						if(item.valor <= 0){
							msg_erro = "O valor do pix tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							pedido.payments.push({
								type: "pix",
								value: item.avista,
								prices: {
									value: item.valor,
									cash_value: item.avista
								}
							});   
						}                    
					}else if(item.tipo == "credit_card"){
						// validação cartao
						if(item.valor <= 0){
							msg_erro = "O valor do cartão tem que ser maior que R$ 0,00.";
							erro++;
						}
						if(item.set_data){							
							// valida os dados do cartão 
							for (var key in item) {
								if(item[key] == "" && key != "force_installments"){
									msg_erro += self.validaCartao(key);
									erro++;
								}
							}
						}
						// inclui o cartao caso esteja válido
						if(erro == 0){
							// gera o nó de pagamento do cartão
							var pay = self.montaCartaoPedido(item);
							pedido.payments.push(pay);		
						}
					}else if(item.tipo == "withdraw"){
						// validação retirada
						if(item.valor <= 0){
							msg_erro = "O valor do Pagamento Retirada tem que ser maior que R$ 0,00.";
							erro++;
						}

						if(item.payment_type == ""){
							msg_erro = "O Tipo do Pagamento deve ser informado.";
							erro++;							
						}
						// inclui o retirada caso esteja válido
						if(erro == 0){
							let value = item.valor;

							if (item.payment_type == "debit_card" || item.payment_type == "cash") {
								value = item.avista;
							}else if(item.payment_type == "credit_card"){
								// pega o valor da parcela pois pode ter desconto a vista no cartão
								var valor_parcela = item.opcao_parcelas.filter(function(dado){                        
									return dado.installment == item.parcelas;
								})[0]["price"];
								value = parseFloat((valor_parcela * item.parcelas).toFixed(2));	
							}
							
							pedido.payments.push({
								type: "withdraw",
								value: (Math.abs(item.avista - value) <= 0.1) ? item.avista : item.valor,
								payment_type: item.payment_type,
								prices: {
									value: item.valor,
									cash_value: item.avista
								},
								installments: parseInt(item.parcelas)
							}); 

						}
					}else if(item.tipo == "internal"){
						// validação boleto
						if(item.valor <= 0){
							msg_erro = "O valor do pagamento interno tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							pedido.payments.push({
								type: "internal",
								value: item.avista,
								prices: {
									value: item.valor,
									cash_value: item.avista
								}
							});   
						}                    
					}else if(item.tipo == "pagaleve"){
						// validação boleto
						if(item.valor <= 0){
							msg_erro = "O valor do Pix parcelado tem que ser maior que R$ 0,00.";
							erro++;
						}
						// inclui o boleto caso esteja válido
						if(erro == 0){
							pedido.payments.push({
								type: "pagaleve",
								value: item.avista,
								prices: {
									value: item.valor,
									cash_value: item.avista
								}
							});   
						}                    
					}
				});

				if(msg_erro != ""){
					Alerts.loader(false);
					Alerts.alertTop("error",msg_erro);
					return;
				}
				
				// Não tem informação de pagamento
				if(pedido.payments.length == 0){
					msg_erro = "Nenhuma forma de pagamento foi escolhida.";
				}

				await self.enviaPedido(pedido);
				
			}
		},
		async enviaPedido(pedido){
			// let self = this;
			let useMercadopago = false;
			let useMundipagg = false;
			let _mercadopago = {};
			let mundipagg = {};

			try {
				// gerando token mercadopago
				let _mercadopago = this.gateways.find(gateway => {
					return gateway.name == "mercadopago";
				});
				if(_mercadopago !== undefined && _mercadopago.token){
					// setando a chave do mercadopago
					window.Mercadopago.setPublishableKey(_mercadopago.token);
					useMercadopago = true;
				}
				
				// gerando token mundipagg
				let mundipagg = this.gateways.find(gateway => {
					return gateway.name == "mundipagg";
				});
				if(mundipagg !== undefined) {
					useMundipagg = true;
				}
			} catch (error) {
				window.console.log("Não foi possível gerar token");
			}
			
			// faz uma última verificação se o pedido é à vista ou parcelado
			var type_validate = "cash"; // cash or installments		
			let msg_erro = "";	
			for(const idx in pedido.payments){			
				const item = pedido.payments[idx];				
				// validando se é cartão, pois boleto naturalmente já é à vista
				if(item.type == "credit_card"){
					// verificando se o numero de parcelas é maior que 1
					if(item.installments > 1){
						type_validate = "installments"; // pelo menos 1 opção de pagamento foi marcada como parcelado
					}else{
						// verifica se teve desconto aplicado no pagamento em 1x
						if(item.value == item.prices.value){
							// não teve desconto a vista em 1x
							type_validate = "installments";  // pelo menos 1 opção de pagamento foi marcada como parcelado
						}
					}
					// pegando os dados do cartao
					if(item.card.number != ""){
						let _card = document.getElementsByClassName("cards-payment")[0].children[idx];					
						if(useMercadopago && _mercadopago.token){		
							try {
								const data_mercadopago = await this.getMercadoPagoToken(_card);							
								pedido.payments[idx].token.mercadopago = data_mercadopago.token;
								pedido.payments[idx].token.mercadopago_method_id = data_mercadopago.methodId;							
							} catch(e){
								msg_erro = e.error;
							}							
							
						}
						if(useMundipagg && mundipagg.token){						
							try {
								const data_mundipagg = await this.getMercadoMundipaggToken(mundipagg.token, _card);
								pedido.payments[idx].token.mundipagg = data_mundipagg.token;
							} catch(e){
								if(e.error && e.error.body && e.error.body.message){
									msg_erro = e.error.body.message;
								}else{
									msg_erro = "Falha na conexão com Mundipagg";
								}							
							}							
						}
					}
				}else if(item.type == "withdraw"){
					// payment_type pagamento na retirada - cash - sale
					if(item.payment_type == "cash"){
						type_validate = "cash";
					}else{
						type_validate = "installments";
					}
				}				
			}

			if(msg_erro != ""){
				Alerts.loader(false);
				Alerts.alertTop("error",msg_erro);
				return;
			}
			
			// atualiza o type do pedido
			pedido.type = type_validate;
			// Marca que o pedido é feito pelo admin
			pedido.source = "admin";
			// envia o seller
			pedido.seller = {
				id: this.usuario.id,
				name: this.usuario.name
			};
			// grava o customer
			pedido.customer_id = this.cart.customer_id;
			if(this.company_id > 0){
				pedido.company_id = this.company_id;
			}

			// envia o pedido
			this.$store.dispatch("pedidos/gravaPedido",{ pedido, store_id: this.cart.store_id });
		},
		async getMercadoPagoToken(card){
			return await new Promise((resolve, reject) => {
				const field = card.querySelector("[data-checkout=\"cardNumber\"]");

				const payload = {
					bin: (field.value || "").replace(/[^\d]/g, ""),
				};

				const Mercadopago = window.Mercadopago || {};

				Mercadopago.getPaymentMethod(payload, (status, response) => {
					if ([200, 201].includes(status)) {
						if(response && response.length > 0 && response[0].id){
							const methodId = response[0].id;
							Mercadopago.createToken(card, (status, response) => {
								if ([200, 201].includes(status) && response.id) {
									return resolve({
										token: response.id,
										methodId,
									});
								} else {
									let error = "Confira os dados informados";

									if (response.cause && response.cause.length) {
										switch (response.cause[0].code) {
										case "212":
										case "213":
										case "214":
											error = "Informe o documento do titular";
											break;
										case "220":
											error = "Informe o banco emissor";
											break;
										case "E301":
											error = "Confira o número informado";
											break;
										case "E302":
											error = "Confira o código de segurança";
											break;
										case "316":
											error = "Confira o nome do titular";
											break;
										case "322":
										case "323":
										case "324":
											error = "Revise o documento de identificação cadastrado em sua conta";
											break;
										case "325":
											error = "Confira o mês de vencimento";
											break;
										case "326":
											error = "Confira o ano de vencimento";
											break;
										case "801":
											error = "Você efetuou um pagamento no mesmo valor há pouco tempo, aguarde alguns minutos";
											break;
										}
									}
									return reject({
										error
									});
								}
							});
						}

					}else{
						return reject({
							error: "Confira o número informado"
						});
					}
				});
			});
		},
		async getMercadoMundipaggToken(publicToken, form){
			const payload = {
				type: "card",
				card: {
					number: "",
					holder_name: "",
					exp_month: "",
					exp_year: "",
					cvv: "",
				},
			};

			for (const key of Object.keys(payload.card)) {
				let _key;
				switch(key){
				case "number":
					_key = "cardNumber";
					break;
				case "holder_name":
					_key = "cardholderName";
					break;
				case "exp_month":
					_key = "cardExpirationMonth";
					break;
				case "exp_year":
					_key = "cardExpirationYear";
					break;
				case "cvv":
					_key = "securityCode";
					break;
				}
				
				const field = form.querySelector("[data-checkout=\""+_key+"\"]");
				payload.card[key] = field.value || "";

				if (key === "number") {
					payload.card[key] = payload.card[key].replace(/[^\d]/g, "");
				}
			}

			return new Promise((resolve, reject) => {
				Vue.http.headers.common = {};
				Vue.http.headers.common["Content-Type"] = "application/json";
				Vue.http.headers.common["Accept"] = "application/json";
				return Vue.http.post(`https://api.mundipagg.com/core/v1/tokens?appId=${publicToken}`, payload).then((response) => {
					if (!response || !response.data || !response.data.id) {
						return reject({ error: "Confira os dados informados" });
					} else {
						return resolve({token: response.data.id});
					}
				}).catch(function(error) {
					return reject({error});
				});				
			});
		},
		montaCartaoPedido: function(item){						
			item.parcelas = parseInt(item.parcelas);			
			// se está passando os dados do cartão e escolhendo as parcelas ele trata os valores
			let valor_pagamento;
			if(item.set_data){
				// pega o valor da parcela pois pode ter desconto a vista no cartão
				var valor_parcela = item.opcao_parcelas.filter(function(dado){                        
					return dado.installment == item.parcelas;
				})[0]["price"];
				valor_pagamento = parseFloat((valor_parcela * item.parcelas).toFixed(2));	
			}else{
				// se apenas está configurando o pagamento para o cliente pagar depois, ele coloca o valor normal
				valor_pagamento = item.valor;
			}            
			const numero_cartao = item.numero.toString();
			return {
				type: "credit_card",
				value: (Math.abs(item.avista - valor_pagamento) <= 0.1) ? item.avista : item.valor,
				prices: {
					value: item.valor,
					cash_value: item.avista
				},
				token: {
					mercadopago: "",
					mercadopago_method_id: "",
					mundipagg: "",
				},
				card: {
					number: (numero_cartao.length <= 10) ? "" : numero_cartao.substring(0,6) + "x".repeat(numero_cartao.length - 10) + numero_cartao.substring(numero_cartao.length - 4,numero_cartao.length),
					expiration_date: item.mes+"/"+item.ano,
					document: item.document.replace(/[^0-9]/g,""),
					name: item.nome 
				},
				installments: item.parcelas,
				force_installments: item.force_installments
			};
		},
		validaCartao: function(key){
			switch(key){
			case "ano":
				// eslint-disable-next-line quotes
				return '- É necessário selecionar o "Ano de Validade" do Cartão.';
			case "mes":
				// eslint-disable-next-line quotes
				return '- É necessário selecionar o "Mês de Validade" do Cartão.';
			case "codigo":
				// eslint-disable-next-line quotes
				return '- É necessário informar o "Código de Segurança" do Cartão.';
			case "nome":
				// eslint-disable-next-line quotes
				return '- É necessário informar o "Nome do Portador" do Cartão.';
			case "numero":
				// eslint-disable-next-line quotes
				return '- É necessário informar o "Número" do Cartão.';
			case "parcelas":
				// eslint-disable-next-line quotes
				return '- É necessário selecionar o "Número de Parcelas" para o pagamento.';
			case "valor":
				// eslint-disable-next-line quotes
				return '- O valor do cartão tem que ser maior que R$ 0,00.';
			default:
				// eslint-disable-next-line quotes
				return "";
			}
		},
		calculaFrete(){
			let self = this;
			let cep = this.cart.delivery.postalcode.replace(".","").replace("-","");
			// valida se já tem cliente selecionado			
			if(self.cart.customer_id == undefined){
				Alerts.alertTop("error","Cadastre ou selecione um cliente antes de calcular o frete");				
				return;
			}
			if(cep.length == 8){
				self.cart.delivery.shipments = [];
				this.$store.dispatch("pedidos/calculaFrete",{ cart_id: self.cart.id, postalcode: cep }).then(response => {
					if (response.body.return_code == 1) {
						if (response.body.data.delivery.shipments.length == 0) {
							Alerts.alertTop("error","Nenhuma forma de envio encontrada para o cep: "+cep);				
						}
						self.buscaEndereco();
					}  
				});
			}else{
				if(cep != ""){
					Alerts.alertTop("error","CEP Inválido, informe o código de 8 dígitos com números apenas.");
				}else{
					Alerts.alertTop("error","Informe o CEP para calcular o frete");
				}
			}
		},
		actionButton: function(tipo){
			switch(tipo){
			case "Buscar":
				// calculo de frete
				this.calculaFrete();
				break;
			}
		},
		buscaEndereco: function(){
			let self = this;		
			// buscando na api
			this.$store.dispatch("pedidos/busca_endereco",{postalcode: self.cart.delivery.postalcode.replace(/[^0-9]+/g,""), customer_id: self.cart.customer_id}).then(response => {
				self.address.postalcode = self.cart.delivery.postalcode;
				if (response.body.return_code == 1) {
					let data = response.body.data;
					if(!data.errors){
						if(!data.sa){
							// caso não tenha o uf gravado ainda busca pelo nome da cidade							
							data.sa = self.states.filter(function(cidade){
								return cidade.name == data.state;
							})[0]["sigla"];
						}    
						self.address.contact = data.contact;
						self.address.street = data.street;
						self.address.number = data.number;
						self.address.neighborhood = data.neighborhood;
						self.address.city = data.city;
						self.address.state = data.state;
						self.address.sa = data.sa;
						self.address.complement = data.complement;
						if(data.phones && data.phones.length > 0){
							self.address.phone = data.phones[0].number;
						}else{
							self.address.phone = "";
						}
						
						self.address.reference = data.reference;
						// pega as cidades
						self.pegaCidades(data.sa);
						// pega as informações de pagamento
						this.pegaInformacoesPagamento(data.sa);
					}
				}
			}); 
		},
		pegaCidades: function(uf){
			this.$store.dispatch("pedidos/pega_cidades",uf);
		},
		selecionaFrete: function(frete){
			let self = this;
			frete.cart_id = self.cart.id; // adiciona o cart_id
			this.$store.dispatch("pedidos/selecionaFrete", frete);
		},
		alteraState: function(){
			let uf = this.address.sa;
			// corrige o nome do estado
			this.address.state = this.states.filter(function(state){
				return state.sigla == uf;
			})[0]["name"];
			// limpa o campo cidade
			this.address.city = "";
            
			this.pegaCidades(uf);
		},
		pegaFreteValor(){
			let shipping = this.cart.delivery.shipments.filter(item => item.type == this.cart.delivery.shipping);
			if(shipping.length > 0){
				return shipping[0].price;
			}else{
				return 0;
			}
		},
		addPagamento: function(tipo) {
			let value_padrao = 0;

			let opcao = this.montaEsqueletoPagamento(tipo,value_padrao);

			let opcoesPagamento = this.opcoesPagamento;
			opcoesPagamento.push(opcao);
			this.$store.commit("pedidos/opcoesPagamento",opcoesPagamento);
			this.controlaValoresPagamento();

		},
		montaEsqueletoPagamento: function(tipo, valor){
			switch(tipo){
			case "credit_card":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor,				
					numero: "",
					nome: "",
					mes: "",
					ano: "",
					codigo: "",
					document_type: "CPF",
					document: "",
					parcelas: 0,
					opcao_parcelas: [],
					set_data: false,
					force_installments: false,
				};
			case "invoice":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor
				};
			case "deposit":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor,
					bank: {}
				};
			case "pix":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor
				};
			case "withdraw":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor,
					payment_type: "",
					parcelas: 0
				};
			case "internal":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor
				};
			case "pagaleve":
				return {
					tipo: tipo,
					valor: valor,
					avista: valor
				};
			}
		},
		controlaValoresPagamento: function(){
			// eslint-disable-next-line no-unused-vars
			let self = this;	

			let valor_compra = parseFloat(this.cart.total_sale.toFixed(2));
			let valor_avista = parseFloat(this.cart.total_cash.toFixed(2));

			let opcoesPagamento = this.opcoesPagamento;

			// pegandos os valores
			let valores_alocados = 0;
			let index_maior_valor = 0;
			let maior_valor = 0;
			opcoesPagamento.forEach(function(item,i){
				valores_alocados += item.valor;
				// pega o pagamento com maior valor para corrigir
				if(item.valor > maior_valor){
					index_maior_valor = i;
					maior_valor = item.valor;
				}
			});
			
			valores_alocados = parseFloat(valores_alocados.toFixed(2));

			let diferenca = 0;

			if(opcoesPagamento.length > 0){
				switch(opcoesPagamento.length){
				case 1:
					// coloca o valor da compra na primeira opção
					opcoesPagamento[0].valor = parseFloat(valor_compra.toFixed(2));
					break;

				default:       
					// pega a diferenca
					diferenca = (valor_compra - valores_alocados);
					
					var valor_opcao = opcoesPagamento[index_maior_valor].valor + diferenca;

					// joga o desconto na opcao selecionada
					opcoesPagamento[index_maior_valor].valor = parseFloat(valor_opcao.toFixed(2));
						
					break;

				}     
			}			      
			
			let soma_final = 0;
			opcoesPagamento.forEach(function(item){

				// valida os valores
				soma_final += Math.abs(item.valor);

				// define o valor a vista com uma regra de proporção
				item.avista = valor_avista * (item.valor / valor_compra); // pegando o proporcional do valor cheio
				item.avista = parseFloat(item.avista.toFixed(2)); // arredondando

				if(item.tipo == "credit_card" || item.tipo == "withdraw"){
					// pega as parcelas para esta opção
					item.parcelas = "";					
					self.$store.dispatch("pedidos/installments",{value: item.valor, cash_value: item.avista}).then(response => {
						item.opcao_parcelas = [];
						if(response.body.return_code == 1){							
							response.body.data.forEach(function(data){								
								item.opcao_parcelas.push({
									id: data.installment,
									label: data.installment + "x de "+ Filters.money(data.price),
									installment: data.installment,
									price: data.price
								});
							});
						}
					});					
				}            
			});

			soma_final = parseFloat(soma_final.toFixed(2)); // arredondando
			if(soma_final > valor_compra){	
				Alerts.alertTop("error","A soma dos valores dos pagamentos ultrapassou o valor da compra, corrija antes de fechar o pedido.");
			}else if(diferenca != 0){
				Alerts.alertTop("error","Uma diferença de valor foi alocada na primeira forma de pagamento (" + this.pegaNomePagamento(opcoesPagamento[index_maior_valor]) + "), revise os valores novamente.");
			}

			// atualiza o state
			this.$store.commit("pedidos/opcoesPagamento",opcoesPagamento);
			this.force_update_key++;
		},
		pegaNomePagamento: function(payment){
			switch(payment.tipo){
			case "credit_card":
				return "Cartão de Crédito";
			case "invoice":
				return "Boleto Bancário";
			case "deposit":
				return "Depósito Bancário";
			case "pix":
				return "PIX";
			case "withdraw":
				return "Pagamento Retirada";
			case "internal":
				return "Pagamento Interno";
			case "pagaleve":
				return "Pix Parcelado";
			}
		},
		removerPagamento: function(index){			
			let opcoesPagamento = this.opcoesPagamento;
			opcoesPagamento.splice(index, 1);
			this.$store.commit("pedidos/opcoesPagamento",opcoesPagamento);
			this.controlaValoresPagamento();
		},
		infoAvista: function(valor){
			let valor_tratado = "Valor à vista: " + Filters.money(valor);
			return valor_tratado;
		},
		pegaInformacoesPagamento(uf){
			this.$store.dispatch("pedidos/informacoesPagamento",{ sa: uf, store_id: this.cart.store_id }).then(response => {
				if(response.return_code == 1){
					this.gateways = response.data.gateways;
					this.merchant_id = parseInt(response.data.merchant_id);			
					this.banks = response.data.banks.map(item => {
						return {
							id: item.code.toString(),
							label: item.bank
						};
					});
				}else{
					this.gateways = [];
					this.merchant_id = null;
					this.banks = [];
				}
			});
		},
		setaPostalCodeByCustomer(customer) {
			if(this.cart){
				this.cart.customer_id = customer.id;
			}
			if(customer.address && customer.address.postalcode){				
				if(this.cart && this.cart.delivery && this.cart.delivery.postalcode == ""){
					this.address.postalcode = customer.address.postalcode;
					this.cart.delivery.postalcode = customer.address.postalcode;
					this.buscaEndereco();
				}
			}
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			actionButton: this.actionButton,
			setaPostalCodeByCustomer: this.setaPostalCodeByCustomer,
		};
	},
	filters: Filters,
	created() {		
		this.$store.dispatch("pedidos/reset");
		this.$store.dispatch("clientes/reset");
		if(this.permissoes) {
			this.permissao = this.permissoes[this.config.modulo_permissao];
		}
		// let self = this;
		if(["mundo","aventura","lexo"].includes(this.$route.params.id)){
			let store_id;
			switch(this.$route.params.id){
			case "mundo":
				store_id = 1;
				break;
			case "aventura":
				store_id = 2;
				break;
			case "lexo":
				store_id = 3;
				break;
			}
			this.$store.dispatch("pedidos/create_cart",{store_id: store_id}).then((response) => {
				if(response.body.return_code == 1){
					this.$store.commit("pedidos/atualizaCart",response.body);					
				}				
			});
		}else{
			this.$store.dispatch("pedidos/load_cart",{id: this.$route.params.id}).then(response => {
				if(response.body.data.customer_id){
					// Pega os dados do cliente
					this.$refs.customers.seleciona({id: response.body.data.customer_id}, false);
				}				
			});			
		}	

		// montando o range de datas para cartão
		let now = new Date;
		let year_init = now.getFullYear();    
		let year_end = year_init + 10;
		this.validade.ano = [];
		for(let i=year_init;i <= year_end; i++){
			this.validade.ano.push({ id: i, label: i });
		}
	},
	metaInfo() {
		const meta = { title: "Pagamento" };
		meta.script = meta.script || [];
		meta.script.push({
			hid: "mercadopago",
			src: "https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js",
			defer: true,
			callback: () => {				
			},
		});
		return meta;
	},
	watch: {
		"cart.delivery.postalcode"(dado) {
			if (this.address.postalcode != "" && dado === ""){
				this.cart.delivery.postalcode = this.address.postalcode;
			}
		}
	}
};
</script>

<style scoped lang="scss">	
	.formulario-cadastro{
		ul{
			li {
				padding: 7px 15px;
				background: #f3f3f3;
				position: relative;
				&:nth-child(odd) {
					background: #FFF;
				}
				i {
					display: block;
					font-style: normal;
					font-weight: bold;
					font-size: 12px;
				}
			}
			&.resumo {
				width: 100%;
				li {
					position: relative;
					span {
						position: absolute;
						right: 15px;
						&.positivo {
							&::before {
								content: '+ ';
							}
						}
						&.negativo {
							color: $color_red;
							&::before {
								content: '- ';
							}
						}
					}
					&.total {
						background: $bg_destaque;
						color: #FFF;
					}
				}
			}
		}
		.frete-results{
			margin: 0.3em auto 0 0;
			div{
				background: #e2e2e2;
				border: 1px solid darken(#e2e2e2,10%);
				border-radius: 5px;
				cursor: pointer;
				&.frete-check{
					border-radius: 5px 0 0 5px;
					padding: 1.6em;
					cursor: pointer;
					font-size: 0.7em;
					text-align: center;
					float: left;
					border-right: 1px solid darken(#e2e2e2,10%);
					&.checked,
					&:hover{
						background: $bg_destaque;
						border-color: $bg_destaque;
						color: #FFF;
					}
				}
				&.frete-desc{
					padding: 0.25em;
					padding-left: 3.5em;
					vertical-align: middle;
					line-height: 1.3em;
				}
			}
		}
		.payments{
			ul{
				li{
					cursor: pointer;
					&:hover{
						background: $bg_destaque;
						color: #FFF;
					}
					i{
						float: right;
					}
				}
			}
		}
	}
</style>
