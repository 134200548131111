export default {
	reset(state, payload) {
		for (let p in state) {
			state[p] = payload[p];
		}
	},
	no_action() {
		
	}
	// kitsFromSku(state, payload){
	// 	if(payload.return_code == 1){
	// 		if(payload.data.items){
	// 			state.kits = payload.data.items.map(item => {
	// 				return {
	// 					title: item.title
	// 				};
	// 			});
	// 		}else{
	// 			state.kits = [];
	// 		}
			
	// 	}else{
	// 		state.kits = [];
	// 	}
	// },
};