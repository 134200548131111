<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class=" card card-base">
					<div class="card-header">
						Informações da Rota
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Tipo de Rota" info="" :options="routeTypes" v-model="route_type" type="string" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option_store" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="Rota Antiga" placeholder="" info="" position="left" text="https://*.com.br" v-model="model.uri" />
							</div>
							<div class="col-12">
								<FormInputGroup :required=true rules="string" label="Rota Nova" placeholder="" info="" position="left" text="https://*.com.br" v-model="model.value" />
							</div>							
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";

// Inputs
import FormInputGroup from "./../../common/form/InputGroup";
import FormSelect from "./../../common/form/Select";
import Alerts from "./../../../common/alerts";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInputGroup,
		FormSelect,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.rotas.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		routeTypes() {
			return [
				{
					label: "Produto",
					id: "product"
				},
				{
					label: "Página",
					id: "page"
				},
				{
					label: "Blog",
					id: "blog_post"
				},
				{
					label: "Categoria",
					id: "category"
				},
				{
					label: "Marca",
					id: "brand"
				},
				{
					label: "Página Especial",
					id: "special_page"
				}
			];
		}
	},	
	data () {
		return {
			header_info: {
				title: "Rotas",
				chamada: "Gerencie as rotas da sua loja",
				links: {
					back: "rotas.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Rotas",
					link: "/rotas"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
			],
			disabled_items: [],
			hidden_items: [],
			route_type: null
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			

				if(this.model.uri.startsWith("http") || this.model.uri.startsWith("www") || this.model.value.startsWith("http") || this.model.value.startsWith("www")){
					Alerts.alertTop("error","Inclua o link apenas após o domínio. Ex: após o .com.br");
					return false;
				}

				if(!this.model.uri.startsWith("/")){
					this.model.uri = "/" + this.model.uri;
				}
				if(!this.model.value.startsWith("/")){
					this.model.value = "/" + this.model.value;
				}

				if(this.route_type === "product" || this.route_type === "brand") {
					this.model.store_id = 0;
				}

				// salva
				this.$store.dispatch("rotas/save", this.model);
			}
		}
	},
	provide: function () {
		return {
			save: this.save
		};
	},
	created() {
		this.$store.dispatch("rotas/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("rotas/load", this.$route.params.id);
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	
</style>
