export default {
	init: function() {
		return {
			model: {
				id: null,
				title: null,
				title_marketplace: null,
				is_kit: false,
				is_marketplace: false,
				release_until: null,
				published_at: null,
				need_cr: false,
				marketplace_id: null,				
				product_type: {
					id: null,
					name: null
				},								
				brand: {
					id: null,
					name: null
				},
				allows_offer: true,				
				is_outlet: false,
				display_in_list: true,
				provider: {
					id: null,
					name: null
				},
				allows_correios: true,
				under_18: false,				
				google_shopping: true,
				facebook_shopping: true,
				shipping_rules: 1,				
				slug: null,
				status: 1,
				metatags: {
					title: null,
					description: null,
					keywords: null
				},
				short_description: null,
				description: null,
				attributes: [],
				galleries: [],				
				categories: [],
				kit_products: [],
				tags: [],
				order: 0,
				stores: [],
				metadata: {},
				route_id: null,
				variations: [],
				sale_validation: false,
				content_validation: false,
				rating: {
					average: 0,
					reviews: []
				},
				canonical: null,
				videos: [],
				on_request: false
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "published_at",
					order: "desc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
			// atributos para o cliente preencher de acordo com o tipo de produto
			attributes: [],
			// categorias para selecionar
			categorias: [],
			// variável do search para o componente de combo
			search_combo: "",			
			// model da variação
			variation_model: {
				id: null,
				name: null,
				invoice_description: null,
				product_id: null,
				sku: null,
				attributes: [],
				metadata: {},
				supplier_code: null,
				discount_joint_sale: 3,
				combo_rules : {
					type : "automatic", // automatic / manual
					value : 0
				},
				dimension: {
					height: 0,
					width: 0,
					length: 0
				},
				weight: 0,				
				gallery_id: null,				
				cost: 0,
				prices : {
					mundo : {
						full_price : 0,
						discount_real : 0,
						discount_percentage : 0,
						margin: 0
					},
					aventura : {
						full_price : 0,
						discount_real : 0,
						discount_percentage : 0,
						margin: 0
					},
					lexo : {
						full_price : 0,
						discount_real : 0,
						discount_percentage : 0,
						margin: 0
					},
					marketplace : {
						full_price : 0,
						discount_real : 0,
						discount_percentage : 0,
						margin: 0
					}
				},
				stock : 0,
				stock_min : 1,
				extra_time : 0,
				ean_code : null,
				marketplace_id : null,
				order : 0				
			}
		};
	}
};
