<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		
		
		<div class="row dashboard">
			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header ">
						Informações Gerais
						<i class="fas fa-sync-alt ml-3" aria-hidden="true" v-on:click="refresh('box_geral')"></i>						
						<select class="form-group" v-model="box_geral.period.type">
							<option :value="period.id" v-for="period in periods" :key="period.id">{{ period.label }}</option>
						</select>						
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-3 ">
								<box-data type_module='box_orders' ></box-data>
							</div>
							<div class="col-md-3 ">
								<box-data type_module='box_cart' ></box-data>
							</div>
							<div class="col-md-3 ">
								<box-data type_module='box_customers' ></box-data>
							</div>		
							<div class="col-md-3 ">
								<box-data type_module='box_payment_problem'></box-data>
							</div>			
						</div>
					</div>
				</div>
			</div>
			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Pedidos
						<i class="fas fa-sync-alt  ml-3" aria-hidden="true" v-on:click="refresh('orders')"></i>
						<select class="form-group" v-model="lines.orders.period.type" v-on:change="refresh('orders')">
							<option :value="period.id" v-for="period in periods" :key="period.id">{{ period.label }}</option>
						</select>				
					</div>
					<div class="card-body dash-grafico">
						<line-area type_module='orders' ></line-area>
					</div>
				</div>
			</div>
			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Carrinhos Abandonados
						<i class="fas fa-sync-alt  ml-3" aria-hidden="true" v-on:click="refresh('carts')"></i>
						<select class="form-group" v-model="lines.carts.period.type" v-on:change="refresh('carts')">
							<option :value="period.id" v-for="period in periods" :key="period.id">{{ period.label }}</option>
						</select>									
					</div>
					<div class="card-body dash-grafico">
						<line-area type_module='carts' ></line-area>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Produtos mais vendidos
						<i class="fas fa-sync-alt  ml-3" aria-hidden="true" v-on:click="refresh('products_best_sellers')"></i>
						<select class="form-group" v-model="bars.products_best_sellers.period.type" v-on:change="refresh('products_best_sellers')">
							<option :value="period.id" v-for="period in periods" :key="period.id">{{ period.label }}</option>
						</select>				
					</div>
					<div class="card-body dash-grafico">
						<bar-chart type_module='products_best_sellers' ></bar-chart>
					</div>
				</div>
			</div>
			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Produtos Mais Abandonados
						<i class="fas fa-sync-alt  ml-3" aria-hidden="true" v-on:click="refresh('products_abandoned')"></i>
						<select class="form-group" v-model="bars.products_abandoned.period.type" v-on:change="refresh('products_abandoned')">
							<option :value="period.id" v-for="period in periods" :key="period.id">{{ period.label }}</option>
						</select>										
					</div>
					<div class="card-body dash-grafico">
						<bar-chart type_module='products_abandoned' ></bar-chart>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Estados Com Mais Vendas
						<i class="fas fa-sync-alt  ml-3" aria-hidden="true" v-on:click="refresh('states_best_sellers')"></i>
						<select class="form-group" v-model="bars.states_best_sellers.period.type" v-on:change="refresh('states_best_sellers')">
							<option :value="period.id" v-for="period in periods" :key="period.id">{{ period.label }}</option>
						</select>				
					</div>
					<div class="card-body dash-grafico">
						<bar-chart type_module='states_best_sellers' ></bar-chart>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";

import BoxData from "./../../common/dashboards/BoxData.vue";
import LineArea from "./../../common/dashboards/LineArea.vue";
import BarChart from "./../../common/dashboards/BarChart.vue";

export default {
	name: "Dashboard",
	components: {
		Header,
		Breadcrumb,
		BoxData,
		LineArea,
		BarChart
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		periods() {
			return this.$store.state.dashboard.periods;
		},		
		box_geral() {
			return this.$store.state.dashboard.box_geral;
		},
		lines() {
			return this.$store.state.dashboard.lines;
		},
		bars() {
			return this.$store.state.dashboard.bars;
		}
	},
	data () {
		return {
			header_info: {
				title: "Informações Gerais da Loja",
				chamada: "Acompanhe as principais informações da sua loja",
				links: {
					
				}
			},
			breadcrumb_info: [
				{
					name: "Dashboard",
					link: "/"
				}
			]
		};
	},  
	created() {
		this.$store.dispatch("dashboard/reset");
	},
	methods: {		
		refresh: function(type){
			this.$store.commit("dashboard/refresh",{ type: type });
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
