<template>
	<div class="row">
		<div class="col-12 py-2">
			<ul>
				<li><router-link to="/">Início</router-link></li>
				<li v-for="(breadcrumb,i) in dados" :key=i><router-link :to=breadcrumb.link v-if="breadcrumb.link != ''">{{ breadcrumb.name }}</router-link><span v-else>{{ breadcrumb.name }}</span></li>
			</ul>
		</div>
	</div>	
</template>

<script>

export default {
	name: "Breadcrumb",
	props: {
		dados: Array,
	},
	data () {
		return {
		};
	},  
	created() {
	},
	methods: {		
		
	}
};
</script>

<style scoped lang="scss">	
	.row{
		background: $bg_clean;
		border-bottom: 1px solid $border_light;
		ul{
			li{
				display: inline-block;
				font-size: 14px;
				a{
					color: $color_light;				
				}			
				&:after{
					content: "\f105";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					color: $color_light;
					margin-left: 5px;
					margin-right: 5px;
				}
				&:last-child{
					a{
						color: $color_escuro;
					}				
					&:after{
						display: none;
					}
				}
			}
		}
	}
	
</style>
