import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/clientes";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		return api.request("clientes/loaded", "admin/customer/" + id, "get").then(response => {
			return response.body;
		});
	},
	save: function(context, data) {
		let redirect = true;
		if(data.source == "order"){
			redirect = false;
			delete data.source;
		}
		return api.request("clientes/loaded", "admin/customer", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Cliente cadastrado com sucesso." : "Cliente atualizado com sucesso.");
				if(redirect){
					router.push({
						name: "clientes.lista"
					});
				}else{
					return response.body;
				}				
			}
		});
	},
	save_documents: function(context, data) {		
		api.request("clientes/no_action", "admin/customer", "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", "Documentos atualizados com sucesso.");
			}
		});
	},
	delete_documents: function(context, document) {
		return api.request("clientes/no_action", "documents" + document, "delete").then(response => {
			if(response){
				return response.body;
			}else{
				return {
					return_code: 0
				};
			}
			
		});
	},
	async getAttachment(state, attachment) {
		const response = await api.request("clientes/no_action", "documents" + attachment.url, "get").then((response) => {
			return response.body;
		});		 
		if (response.return_code === 1 && response.data.ok && response.data.file) {
			return response.data.file;
		} else {
			return null;
		}
	},
	update_ordem: function(state, data) {
		return api.request("clientes/no_action", "admin/customers/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.clientes);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 
		let fields = [];
		config.clientes.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		state.commit("clear_valores");
		api.request("clientes/data_valores_loaded", "admin/customers", "post", { fields: fields, "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {				
		let data = {};
		data[dados.field] = dados.value;
		return api.request("clientes/no_action", "admin/customers", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("clientes/acao_massa", "admin/customers", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{		
		return api.request("clientes/acao_massa", "admin/customers", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("clientes/no_action", "admin/customer/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("clientes/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("clientes/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("clientes/delete_filter", "admin/filter/"+id, "delete", id);
	},
	filter_customers_data: function(state,dados){
		let text = dados.text;
		// verificando se é uma busca por uma única palavra ou mais palavras
		let search = text.split(" ");
		let filters_default = [];
		let filters = {};
		if(search.length > 1){
			// busca somente name
			let _and = filters_default;
			// percorre cada palavra
			search.forEach(item => {
				_and.push({
					field: "name", 
					operator: "like", 
					type: "string", 
					value: item
				});
			});
			// monta o filtro
			filters = {
				and: [
					{
						and: _and
					}
				]
			};
		}else{
			// busca name, cpf e e-mail
			let search_filtered = [];
			// verificando alguns dados para facilitar a busca
			let document = search[0].replace(/[^0-9]+/g,"");
			// verificando se é uma busca por CPF ou CNPJ
			if(document.length == 11 || document.length == 14){
				// busca somente pelo document
				search_filtered = [
					{
						field: "document", 
						operator: "=", 
						type: "string", 
						value: document
					}
				];
			}else if(search[0].includes("@")){
				search_filtered = [
					{
						field: "email", 
						operator: "like", 
						type: "string", 
						value: search[0]
					}
				];
			}else{
				search_filtered = [
					{
						field: "name", 
						operator: "like", 
						type: "string", 
						value: search[0]
					},
					{
						field: "email", 
						operator: "like", 
						type: "string", 
						value: search[0]
					}
				];
			}
			
			filters = {
				and: [
					{
						and: filters_default,
						or: search_filtered
					}
				]
			};
		}
		return api.request("clientes/no_action", "admin/customers", "post", { "filters": filters, "sort": "name,asc", "limit": 10, "page": 1 }).then(response => {
			if(response.body.return_code == 1 && response.body.data && response.body.data.items){
				if(dados.type_result && dados.type_result == "simple"){
					response = response.body;
					let _dados = [];
					if(response.return_code == 1){
						response.data.items.forEach(item => {
							_dados.push({ id: item.id, name: item.name });
						});
						
					}
					return _dados;
				}else{
					return response.body.data.items;
				}
				
			}else{
				return [];
			}
		});
	},
	load_notes: function(state, customer_id){
		return api.request("clientes/notes_loaded", "admin/notes/customer/" + customer_id, "get");
	},
	add_note: function(state, dados){
		const { note, id } = dados;
		return api.request("clientes/notes_created", "admin/note", "post", {
			type: "customer",
			document_id: id,
			user_id: state.rootState.app.usuario.id,
			message: note
		});
	},
	login_temporario: function(state, id){
		return api.request("clientes/no_action", "admin/customer/temp", "post", {
			customer_id: id			
		}).then(response => {
			return response.body;
		});
	}
};