<script>

import ApexCharts from "vue-apexcharts";

export default {
	props:{
		type_module:{
			type: String,
			required: true,
			default: null
		},		
	},
	components: {
		ApexCharts
	},
	computed: {
		bars() {
			return this.$store.state.dashboard.bars;
		},
		options() {
			let self = this;
			let options = {				
				chart: {
					height: 350,
					type: "bar",
					zoom: {
						enabled: false
					}
				},	
				plotOptions: {
					bar: {
						horizontal: true,
					}
				},			
				dataLabels: {
					enabled: true,
					formatter: function(x){
						if(self.type != "number"){
							return self.formataPrice(x);
						}else{
							return self.formataNumber(x);
						}
					}
				},
				xaxis: {
					type: "string",
					categories: []
				},
				tooltip: {
					x: {
						show: true,						
					},
					y: {
						formatter: function(x){
							if(self.type != "number"){
								return self.formataPrice(x);
							}else{
								return self.formataNumber(x);
							}
						},
						title: {
							formatter: (seriesName) => seriesName,
						},
					}
				}
			};

			let labels = [];
			if(this.config.data.data){
				this.config.data.data.forEach(function(item){
					labels.push(item.label);
				});
			}			
			
			options.xaxis.categories = labels;

			return options;
		},
		series: function(){
			if(!this.options){
				return {};
			}

			let self = this;
			let options = this.options;			
			let series = [
				{
					name: "",
					data: [],
				}
			];

			if(self.type != "number"){
				series[0].name = "Valor";
			}else{
				series[0].name = "Quantidade";
			}

			series[0].data = [];
			let valor_grafico;
			let dados_api;
			options.xaxis.categories.forEach(function(item){								
				
				// valor padrão de 0 caso essa data não tenha retorno da api
				valor_grafico = {
					x: "",
					y: 0
				};

				// Verifica se tem dado retornado da api				
				if(self.config.data.data){
					dados_api = self.config.data.data.filter(dado => dado.label == item)[0];
					if(dados_api){
						// se tiver insere
						if(self.type == "number"){
							valor_grafico = {
								x: "Quantidade",
								y: dados_api.number
							};
						}else{
							valor_grafico = {
								x: "R$ ",
								y: dados_api.price
							};
						}	
					}
				}

				series[0].data.push(valor_grafico);			
			});

			return series;
		},
	},
	data() {
		return {
			config: {},			
			type: "number"			
		};
	},
	methods: {
		// updateGrafico: function(){
		// 	let self = this;
		// 	let options = Object.assign({}, this.options);
		// 	options.tooltip.y = {
		// 		formatter: function (y) {
		// 			if(typeof y !== "undefined") {
		// 				if(self.type == "number"){
		// 					return self.formataNumber(y);
		// 				}else{
		// 					return "R$ "+self.formataPrice(y);
		// 				}
		// 			}
		// 			return y;
					
		// 		}
		// 	};
		// 	options.dataLabels = {
		// 		formatter: function (y) {
		// 			if(typeof y !== "undefined") {
		// 				if(self.type == "number"){
		// 					return self.formataNumber(y);
		// 				}else{
		// 					return "R$ "+self.formataPrice(y);
		// 				}
		// 			}
		// 			return y;
					
		// 		}
		// 	};

		// 	if(self.type == "number"){
		// 		options.yaxis[0].name = "Quantidade";
		// 		options.yaxis[0].title.text = "Quantidade";
		// 	}else{
		// 		options.series[0].name = "Valor";
		// 		options.yaxis[0].title.text = "Valor em R$";
		// 	}

		// 	options.series[0].data = [];
		// 	options.xaxis.categories = [];
		// 	this.config.data.data.forEach(function(item){
		// 		if(self.type == "number"){
		// 			options.series[0].data.push(item.number);
		// 		}else{
		// 			options.series[0].data.push(item.price);
		// 		}
		// 		options.xaxis.categories.push(item.label);
		// 	});
			

		// 	var chart = new ApexCharts(
		// 		document.querySelector("#"+this.type_module),
		// 		options
		// 	);

		// 	chart.render();
		// },
		updateData: function(){
			let self = this; 

			this.$store.dispatch("dashboard/calculatePeriod",{ type: self.config.period.type, module: self.type_module }).then(response => {
				this.$store.commit("dashboard/periods",response);

				let endpoint;			
				let json_data = {
					filters: [
						{
							field: "created_at",
							value: self.config.period.date_init,
							operator: ">=",
							type: "datetime"
						},
						{
							field: "created_at",
							value: self.config.period.date_end,
							operator: "<=",
							type: "datetime"
						}
					],
					limit: 10
				};

				let type_request = "post";
				
				switch(self.type_module){
				case "products_best_sellers":
					endpoint = "admin/dashboard/products/best-sellers";
					break;					
				case "products_abandoned":
					endpoint = "admin/dashboard/products/abandoned";
					break;	
				case "states_best_sellers":
					endpoint = "admin/dashboard/states/best-sellers";
					break;				
				}

				// Faz a chamada
				this.$store.dispatch("dashboard/bars", {
					type_module: self.type_module,
					endpoint: endpoint, 
					method: type_request, 
					filters: json_data
				}).then(() => {
					self.updateGrafico();
				});			

			});
			
		},
		toogleType: function(){
			if(this.type == "number"){
				this.type = "money";
			}else{
				this.type = "number";
			}
			this.updateGrafico();
		},
		setaConfig(){
			this.config = this.bars[this.type_module];
		},
		formataPrice(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender            
			if(value == 0){
				return "R$ 0,00";
			}

			if(typeof value == "number"){
				value = value.toFixed(2);
			}

			if (value)
				return "R$ "+value.replace(".", ",").replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
			else
				return "";

		},
		formataNumber(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender
			if(value)
				return value.toString().replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
			else
				return 0;
		},
		updateGrafico(){			
			this.$refs.chart.updateOptions(this.options);			
			this.$refs.chart.updateSeries(this.series);
		}
	},
	created() {		
		this.setaConfig();	
	},
	watch: {		
		"config.period.refresh": function(){
			this.updateData();				
		}	
	},
	mounted(){		
		
	}
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<i class="fa fa-retweet invert-data" aria-hidden="true" v-on:click="toogleType()"></i>
				<Apex-charts ref="chart" width="100%" type="bar" :options="options" :series="series"></Apex-charts>
			</div>
		</div>
	</div>
</template>