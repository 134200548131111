<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao :is_order="false" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row" v-if="queue">
			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações da Task
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Worker:</strong> {{ queue.worker_name }} </li>
							<li><strong>Type:</strong> {{ queue.job_type }} </li>
							<li><strong>Action:</strong> {{ queue.action }} </li>
							<li><strong>Status:</strong> {{ queue.status }} </li>
							<li><strong>Criada em:</strong> {{ queue.created_at | date_time }} </li>
							<li v-if="queue.updated_at"><strong>Atualizado em:</strong> {{ queue.updated_at | date_time }} </li>
							<li v-if="queue.initialized_at"><strong>Iniciado em:</strong> {{ queue.initialized_at | date_time }} </li>
							<li v-if="queue.finalized_at"><strong>Finalizado em:</strong> {{ queue.finalized_at | date_time }} </li>
							<li v-if="queue.requeued_at"><strong>Reprocessado em:</strong> {{ queue.requeued_at | date_time }} </li>							
							<li v-if="queue.return_value"><strong>Valor Retornado:</strong> {{ queue.return_value }} </li>
							<li v-if="queue.document_id"><strong>ID (document_id):</strong> {{ queue.document_id }} </li>
							<template v-if="queue.error">
								<li v-if="queue.error.traceback"><strong>Traceback:</strong> {{ queue.error.traceback }} </li>
								<li v-if="queue.error.exception"><strong>Exception:</strong> {{ queue.error.exception }} </li>
								<li v-if="queue.error.failed_at"><strong>Data:</strong> {{ queue.error.failed_at | date_time }} </li>
							</template>							
							<li v-if="queue.exception"><strong>Exception:</strong> {{ queue.exception }} </li>							
							<li v-if="queue.traceback"><strong>Traceback:</strong> {{ queue.traceback }} </li>
							<li v-if="queue.job_data"><strong>Payload data:</strong> {{ queue.job_data }} </li>
						</ul>
						<button class="btn btn-primary my-3 mx-3" v-if="queue.status === 'FAILURE'" @click="requeue">Clique para processar novamente na fila</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Filters from "../../../common/filters";
import alerts from "../../../common/alerts";


export default {
	name: "MonitoringView",
	components: {
		Header,
		Breadcrumb,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Informações de Monitoramento",
				chamada: "Gerencie os erros da sua loja",
				links: {
					back: "monitoring.lista",
				}
			},
			breadcrumb_info: [				
				{
					name: "Monitoramento",
					link: "/monitoring"
				},
				{
					name: "Ver",
					link: ""
				}
			],
			permissao: [],
			queue: null		
		};
	},  
	methods: {		
		requeue(){
			let self = this;
			this.$store.dispatch("monitoring/requeue", this.queue.id).then(response => {
				if(response.return_code == 1 && response.data.ok){
					alerts.alertTop("success", "Inserido na fila para processamento com sucesso");
					self.loadData();
				}else{
					alerts.alertTop("error", "Não foi possível reprocessar o registro.");
				}
			});
		}, 
		loadData() {
			let self = this;		
			this.$store.dispatch("monitoring/load", this.$route.params.id).then(response => {
				if(response){
					self.queue = response;
				}else{
					alerts.alertTop("error","Houve um problema ao carregar as informações");
				}
				
			});		
		}
	},
	filters: Filters,
	provide: function () {
		return {
		};
	},
	created() {
		this.loadData();
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	
</style>
