
import storage from "./../../storage";
import router from "./../../router";

export default {
	add_request: function(context, requestData) {
		context.commit("add_request", requestData);
	},
	remove_request: function(context, requestData) {
		context.commit("remove_request", requestData);
	},
	async login(context, usuario) {
		if(usuario.lastAcess === undefined || usuario.lastAcess == null){
			// tira o login do cliente 
			storage.remove("usuario");
			location.reload();
			return;
		}        
		let now = new Date;      
		let diff_minutos = ((now.getTime() - usuario.lastAcess) / 1000) / 60; // minutos 		
		if(diff_minutos >= 30){
			// tira o login do cliente 
			storage.remove("usuario");
			// location.reload()
			router.push({
				name: "auth.login",
				query: { expired: true, redirect: window.location.href }                
			});
		}else{			
			usuario.lastAcess = now.getTime();									
		}        
		// comita os dados do usuario atualizados
		context.commit("login", { "data": usuario });
	},	
};