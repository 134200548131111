import Alerts from "./alerts";

const validation = (refs) => {

	// limpa as validações
	reset();

	// verifica se tem as tabs da esquerda
	let existe_tab = document.getElementsByClassName("menu-lateral").length > 0;
	
	// pega os elementos com possibilidade de validação
	let input;	
	if(refs){
		input = refs.querySelectorAll("input,select,textarea,div.component_required");	
	}else{
		input = document.querySelectorAll("input,select,textarea,div.component_required");	
	}
	let results = [];		
	let valid = true;
	input.forEach(elem => {	
		// verifica se tem o atributo required
		if(elem.hasAttribute("required")){	
			// pega as regras
			let rules = elem.getAttribute("validation-rules");
			// pega a mensagem
			let msg = elem.getAttribute("validation-msg");
			// monta o result padrão
			let result = {
				valid: true,
				msg: (msg == null) ? "Este campo é obrigatório!" : msg,
				elem: null,
				type: null
			};
			// trata cada caso das regras
			switch(rules){
			case "option":
				if(elem.value === ""){
					result.valid = false;
					result.elem = elem;
					result.type = "input";
				}
				break;
			case "option_store":
				if(elem.value == 0){
					result.valid = false;
					result.elem = elem;
					result.type = "input";
				}
				break;
			case "money":
				// trata a variável
				var _value = elem.value;
				if(typeof _value == "string" && _value != ""){
					_value = _value.replace(/\./g,"");
					_value = _value.replace(",",".");
					_value = parseFloat(_value);
				}
				// valida se é maior que zero
				if(_value <= 0){
					result.valid = false;
					result.elem = elem;
					result.type = "input";
				}
				break;
			case "multiselect":
				if(elem.getAttribute("valor") == ""){
					result.valid = false;
					result.elem = elem;
					result.type = "multiselect";
				}
				break;
			case "flatpickr":
				if(elem.getAttribute("valor") == "" || elem.getAttribute("valor") == null){
					result.valid = false;
					result.elem = elem;
					result.type = "flatpickr";
				}
				break;
			case "editor":
				if(elem.getAttribute("valor") == "" || elem.getAttribute("valor") == null){
					result.valid = false;
					result.elem = elem;
					result.type = "editor";
				}
				break;		
			default:
				// validação simples de string vazia				
				if(elem.value == ""){
					result.valid = false;
					result.elem = elem;	
					result.type = "input";													
				}
				break;
			}					
			// guarda os elementos falso
			if(result.valid == false){
				valid = false;
				results.push(result);
				marca_aba_erro(result.elem);
				if(result.elem.closest(".card-variation") != null){
					result.elem.closest(".card-variation").getElementsByClassName("card-header")[0].classList.add("header-error");
				}
			}						
		}
	});

	// exibe os resultados
	show(results);

	if(results.length > 0){
		Alerts.alertTop("error","Alguns campos apresentam erro e devem ser corrigidos.");
		// abre na aba que tem o primeiro erro
		if(existe_tab){		
			let elem = results[0].elem;
			let card = elem.closest("div.card-base");
			let cards = document.getElementsByClassName("card-base");						
			cards = Object.values(cards);
			let index = cards.indexOf(card);
			let lista = document.getElementsByClassName("menu-lateral");
			lista[0].children.forEach((item,i) => {
				if(index == i){
					// marca como ativo
					item.classList.add("active");	
					cards[i].classList.remove("hide");								
				}else{
					item.classList.remove("active");
					cards[i].classList.add("hide");						
				}				
			});
		}
	}	

	return {
		valid: valid,
		elements: results
	};
};

const marca_aba_erro = (elem) => {
	let lista = document.getElementsByClassName("menu-lateral");
	if(lista.length > 0){
		let card = elem.closest("div.card-base");
		let cards = document.getElementsByClassName("card-base");		
		cards = Object.values(cards);
		let index = cards.indexOf(card);	
		lista[0].children[index].classList.add("tab-error-validate");	
	}	
};

const show = (results) => {
	// percorre os resultados com erro para exibir as mensagens
	results.forEach( valid => {
		let elem = valid.elem;
		let parent;		
		// verifica se faz parte de um input group ou não para pegar o parent correto		
		if(valid.type == "flatpickr" || valid.type == "editor"){
			parent = elem;					
		}else if(elem.parentElement.classList.contains("input-group") || valid.type == "multiselect"){
			parent = elem.parentElement.parentElement;					
		}else{
			parent = elem.parentElement;
		}
		// add a class error e monta a mensagem
		parent.classList.add("error");
		let span_msg = document.createElement("span");
		span_msg.classList.add("error_msg");
		span_msg.innerHTML = valid.msg;
		let icon = document.createElement("i");
		icon.classList.add("fas");
		icon.classList.add("fa-exclamation-triangle");
		span_msg.prepend(icon);
		parent.appendChild(span_msg);					
	});
};

const reset = () => {
	// limpa todos os elementos com class error
	let clean = Object.assign([], document.getElementsByClassName("error"));
	clean.forEach(elem => {
		// tira a class error
		elem.classList.remove("error");
		elem.getElementsByClassName("error_msg").forEach(child => {
			// remove o elemento com a mensagem de erro
			child.remove();
		});
	});
	// limpa todas as abas com class tab-error-validate
	let clean_aba = Object.assign([], document.getElementsByClassName("tab-error-validate"));
	clean_aba.forEach(elem => {
		// tira a class error
		elem.classList.remove("tab-error-validate");
	});
	// limpa todas as abas com class header-error
	let clean_header = Object.assign([], document.getElementsByClassName("header-error"));
	clean_header.forEach(elem => {
		// tira a class error
		elem.classList.remove("header-error");
	});
	
};

export default {
	validation: validation,
	show: show,
	reset: reset
};