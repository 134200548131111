<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais da Vitrine
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=true />
							</div>			
							<div class="col-6">
								<FormInput :required=true :disabled="model.id != null" rules="string" label="Identificador" placeholder="" info="" v-model="model.slug" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Tipo de Vitrine" info="" :options="[{id: 1,label: 'Geral'}, {id: 2, label: 'Marca'}, {id: 3, label: 'Categoria'}, {id: 4, label: 'Tags'}, {id: 5, label: 'Páginas de Ofertas'}, {id: 6, label: 'Campanha Destaque'}]" v-model="model.type" @input="alteraTipo()" type="number" />		
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Regra da Vitrine" info="" :options="[{id: 1,label: 'Selecionar Produtos Manualmente'}, {id: 2, label: 'Produtos Mais Vistos'}, {id: 3, label: 'Produtos Mais Vendidos'}, {id: 4, label: 'Últimos Produtos Cadastrados'}, {id: 5, label: 'Aleatório'}]" v-model="model.rule"  @input="alteraTipo()" type="number" />
							</div>
							<div class="col-6" v-if="[2,3,4].includes(model.rule)">
								<FormInput :required=true rules="string" label="Quantidade" placeholder="" info="" v-model="model.quantity" />
							</div>
							<div class="col-6" v-if="[2,3].includes(model.rule)">
								<FormSelect :required=true rules="option" label="Período" info="" :options="[{id: 7,label: 'Últimos 7 dias'}, {id: 15, label: 'Últimos 15 dias'}, {id: 30, label: 'Últimos 30 dias'}]" v-model="model.period" type="number" />
							</div>
						</div>
						<div class="row">
							<div class="col">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id" type="number" />
							</div>
							<div class="col">
								<FormSelect :required=true rules="option" label="Tipo de Exibição" info="" :options="[{id: 'vertical',label: 'Vertical'}, {id: 'horizontal', label: 'Horizontal'}, {id: 'duplo', label: 'Duplo'}]" v-model="model.config.type" type="string" />		
							</div>
							<div class="col">
								<FormSelect :required=true rules="option" label="Slide" info="" :options="[{id: true,label: 'Habilitado'}, {id: false, label: 'Desabilitado'}]" v-model="model.config.slide.active" type="boolean" />
							</div>
							<div class="col" v-if="model.config.slide.active">
								<FormSelect :required=true rules="option" label="Slide Automático" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.config.slide.auto" type="boolean" />
							</div>							
						</div>	
						<div class="row">
							<div class="col-6">
								<Imagem :required=false rules="string" label="Banner" placeholder="" info="" v-model="model.banner" type="showcases" :store_id=model.store_id></Imagem>
							</div>		
							<div class="col-6">
								<Imagem :required=false rules="string" label="Banner Mobile" placeholder="" info="" v-model="model.banner_mobile" type="showcases" :store_id=model.store_id></Imagem>
							</div>																									
						</div>					
						<div class="row">
							<div class="col-6">
								<FormInput :required=false rules="string" label="Link" placeholder="" info="" v-model="model.link" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Categorias -->
				<div class="hide card card-base">
					<div class="card-header">
						Categorias
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Categorias :permissao=permissao state="vitrines" :selecionados="categorias_selecionadas" :selecionavel=true :store_enabled=model.store_id />
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Marcas -->
				<div class="hide card card-base">
					<div class="card-header">
						Marcas
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="vitrines" tipo="marcas_selecionadas" :dados="marcas" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Tags -->
				<div class="hide card card-base">
					<div class="card-header">
						Tags
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="vitrines" tipo="tags_selecionadas" :dados="tags" :required=false :field_required=false :multiple=true :insert=false :modo_tag=true></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Produtos -->
				<div class="hide card card-base">
					<div class="card-header">
						Produtos
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelectProdutos state="vitrines" tipo="rule_relation" :selectVariations=false :needs_store=true :store_id=model.store_id></MultiSelectProdutos>
						<!-- Fim Conteudo -->
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import Categorias from "./../../common/Categorias";
import MultiSelect from "./../../common/MultiSelect";
import MultiSelectProdutos from "./../../common/MultiSelectProdutos";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import Imagem from "./../../common/form/Imagem";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		Categorias,
		MultiSelect,
		MultiSelectProdutos,
		Imagem
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.vitrines.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		categorias_selecionadas() {
			return this.$store.state.vitrines.model.categorias_selecionadas;
		},
		marcas_selecionadas() {
			return this.$store.state.vitrines.model.marcas_selecionadas;
		},
		tags_selecionadas() {
			return this.$store.state.vitrines.model.tags_selecionadas;
		}, 
		categorias() {
			return this.$store.state.vitrines.categorias;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Vitrines",
				chamada: "Gerencie as vitrines da sua loja",
				links: {
					back: "vitrines.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Vitrines",
					link: "/vitrines"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Categorias",
				"Marcas",
				"Tags",
				"Produtos"
			],
			disabled_items: [],
			hidden_items: [
				"Categorias",
				"Marcas",
				"Tags",
				"Produtos"
			],
			permissao: {},
			marcas: [],
			tags: []
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){	
				let model = Object.assign({},this.model);
				// tratando os dados de relação
				if(typeof model.type == "string"){
					model.type = parseInt(model.type);
				}
				let type_relation = [];
				switch(model.type){
				case 1:
					// geral
					type_relation = [];
					break;
				case 2:
					// marca
					model.marcas_selecionadas.forEach(item => {
						type_relation.push(item.id);
					});
					break;
				case 3:
					// categoria
					model.categorias_selecionadas.forEach(item => {
						type_relation.push(item.id);
					});
					break;
				case 4:
					// tags					
					model.tags_selecionadas.forEach(item => {
						type_relation.push(item.id);
					});
					break;
				default:
					type_relation = [];
					break;
				}
				model.type_relation = type_relation;
				// apaga os campos
				delete model.marcas_selecionadas;
				delete model.categorias_selecionadas;
				delete model.tags_selecionadas;

				// trata os produtos selecionados manualmente
				if(model.rule == 1){
					let produtos = Object.assign([],model.rule_relation);
					model.rule_relation = [];
					produtos.forEach(item => {
						model.rule_relation.push(item.product_id);
					});
				}

				// salva
				this.$store.dispatch("vitrines/save", model);
			}
		},
		toSlug: function(){
			if(this.model.slug == null){
				this.model.slug = Slug(this.model.name);
			}			
		},
		alteraTipo: function(){
			let type = parseInt(this.model.type);
			let rule = parseInt(this.model.rule);
			switch(type){
			case 1:
				// Geral
				this.hidden_items = [
					"Categorias",
					"Marcas",
					"Tags",					
				];
				break;
			case 2:
				// Marcas 
				this.hidden_items = [
					"Categorias",
					"Tags",
				];
				break;
			case 3:
				// Categorias
				this.hidden_items = [
					"Marcas",
					"Tags",
				];
				break;
			case 4:
				// Tags
				this.hidden_items = [
					"Categorias",
					"Marcas",
				];
				break;
			default:
				this.hidden_items = [
					"Categorias",
					"Marcas",
					"Tags",					
				];
				break;
			}
			if(rule != 1){
				// selecionar produtos manualmente
				this.hidden_items.push("Produtos");
			}
		},
		updateDadosMultiselect: function(objeto,tipo){
			this[tipo].push(objeto);
		},
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			updateDadosMultiselect: this.updateDadosMultiselect,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("vitrines/reset");
		let startingPromises = [
			// pega as categorias
			this.$store.dispatch("vitrines/load_categorias"),
			// pega as marcas
			this.$store.dispatch("vitrines/load_marcas").then(response => {
				self.marcas = response;
			}),
			// pega as tags
			this.$store.dispatch("vitrines/load_tags").then(response => {
				self.tags = response;
			})
		];
		
		Promise.all(startingPromises).then(() => {
			if(self.$route.params.id != undefined && self.$route.params.id != "") {
				self.$store.dispatch("vitrines/load", self.$route.params.id).then(response => {
					if(response.return_code == 1){
						let data = response.data;

						// executa a função que vai exibir a aba correta de acordo com o tipo
						self.alteraTipo();

						switch(data.type){
						case 2:
							// marcas
							self.$store.commit("vitrines/update_marcas_selecionadas",data.type_relation.map(item => {
								let marca = self.marcas.find(dado => {
									return item == dado.id;
								});
								if(marca){
									return marca;
								}
							}));
							break;
						case 3:
							// categoria
							// eslint-disable-next-line no-case-declarations
							let categorias = [];
							data.type_relation.forEach(item => {
								categorias.push({id: item});
							});
							self.$store.commit("vitrines/update_categorias_selecionadas",categorias);
							break;
						case 4:
							// tags
							self.$store.commit("vitrines/update_tags_selecionadas",data.type_relation.map(item => {
								let tag = self.tags.find(dado => {
									return item == dado.id;
								});
								if(tag){
									return tag;
								}
							}));
							break;
						}

						// se for tipo selecionar produtos, trata o objeto de produtos
						if(data.rule == 1 && data.rule_relation.length > 0){
							self.$store.dispatch("vitrines/load_produtos_selecionados_data",data.rule_relation);
						}
					}
				});
			}
		}, () => {
			
		});

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	watch: {
		"model.rule": function(dado){
			if(typeof dado == "string"){
				this.model.rule = parseInt(dado);
			}			
		},
		"model.store_id": function(dado){
			if(typeof dado == "string"){
				this.model.store_id = parseInt(dado);
			}			
		},
	}
};
</script>

<style scoped lang="scss">	
	
</style>
