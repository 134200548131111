<script>

import ApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
	props:{
		type_module:{
			type: String,
			required: true,
			default: null
		},		
	},
	components: {
		ApexCharts
	},
	computed: {
		lines() {
			return this.$store.state.dashboard.lines;
		},
		options() {
			let self = this;
			let options = {				
				chart: {
					height: 350,
					type: "area",
					zoom: {
						enabled: false
					}
				},				
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					curve: "smooth",
					lineCap: "butt",
					width: 2,
					dashArray: 0,      
				},
				xaxis: {
					type: "string",
					datetimeUTC: false,
					categories: []
				},
				tooltip: {
					x: {
						show: true,
						formatter: function(x){
							switch(self.config.period.type){
							case "7-days":
							case "15-days":
							case "current-month":
							case "last-month":
								return "Dia "+x;
							default:
								return x+"h";
							}
							
						},
					},
					y: {
						formatter: function(x){
							if(self.type != "number"){
								return self.formataPrice(x);
							}else{
								return self.formataNumber(x);
							}
						},
						title: {
							formatter: (seriesName) => seriesName,
						},
					}
				},	
			};

			let labels = [];
			// Montando os dias de acordo com o período			
			if(this.config.period.type == "24-hours"){
				let hora_init;				
				if(this.config.period.date_init != null){
					hora_init = new Date(this.config.period.date_init);
					for(let i=0;i<24;i++){
						let _date = new Date(hora_init);						
						_date.setHours(hora_init.getHours() + i);
						labels.push(moment(_date).format("HH"));
					}
				}	
			}else if(this.config.period.type == "today" || this.config.period.type == "yesterday"){
				let hora_init;				
				if(this.config.period.date_init != null){
					hora_init = new Date(this.config.period.date_init);
					for(let i=0;i<24;i++){
						let _date = new Date(hora_init);						
						_date.setHours(hora_init.getHours() + i);
						labels.push(moment(_date).format("HH"));
					}
				}
			}else if(this.config.period.type == "current-month" || this.config.period.type == "last-month"){
				if(this.config.period.date_init != null){
					let dia_maximo = moment(moment(this.config.period.date_init).format("YYYY-MM"), "YYYY-MM").daysInMonth();				
					let day_init = new Date(this.config.period.date_init);
					for(let i=0;i<dia_maximo;i++){		
						let _date = new Date(day_init);						
						_date.setDate(day_init.getDate() + i);						
						labels.push(moment(_date).format("DD"));				
					}
				}
				
			}else if(this.config.period.type == "7-days" || this.config.period.type == "15-days"){
				let diferenca = 0;
				if(this.config.period.type == "7-days"){
					diferenca = 7;
				}else if(this.config.period.type == "15-days"){
					diferenca = 15;
				}
				
				let day_init;				
				if(this.config.period.date_init != null){
					day_init = new Date(this.config.period.date_init);
					for(let i=0;i<diferenca;i++){
						let _date = new Date(day_init);						
						_date.setDate(day_init.getDate() + i);
						labels.push(moment(_date).format("DD"));
					}
				}
			}

			options.xaxis.categories = labels;

			return options;
		},
		series: function(){
			if(!this.options){
				return {};
			}

			let self = this;
			let options = this.options;			
			let series = [
				{
					name: "",
					data: [],
				}
			];

			if(self.type != "number"){
				series[0].name = "Valor";
			}else{
				series[0].name = "Quantidade";
			}

			series[0].data = [];
			let valor_grafico;
			let dados_api;
			options.xaxis.categories.forEach(function(item){								
				
				if(typeof item == "string"){
					if(item.startsWith("0")){
						item = eval(item.substr(1));						
					}else{
						item = eval(item);
					}
				}				
				
				// valor padrão de 0 caso essa data não tenha retorno da api
				valor_grafico = {
					x: "",
					y: 0
				};

				// Verifica se tem dado retornado da api				
				if(self.config.data.data){
					dados_api = self.config.data.data.filter(dado => dado.label == item)[0];
					if(dados_api){
						// se tiver insere
						if(self.type == "number"){
							valor_grafico = {
								x: "Quantidade",
								y: dados_api.number
							};
						}else{
							valor_grafico = {
								x: "R$ ",
								y: dados_api.price
							};
						}	
					}
				}

				series[0].data.push(valor_grafico);			
			});

			return series;
		},
	},
	data() {
		return {
			config: {},			
			type: "number"			
		};
	},
	methods: {
		dateToNumber: function(data, format){
			if(data == null){
				return 0;
			}
			let retorno = moment(data).format(format);
			if(retorno.startsWith("0")){
				return eval(retorno.substr(1));						
			}else{
				return eval(retorno);
			}
		},		
		updateData: function(){
			let self = this; 

			this.$store.dispatch("dashboard/calculatePeriod",{ type: self.config.period.type, module: self.type_module }).then(response => {
				this.$store.commit("dashboard/periods",response);

				let endpoint;			
				let json_data = {
					filters: [
						{
							field: "created_at",
							value: self.config.period.date_init,
							operator: ">=",
							type: "datetime"
						},
						{
							field: "created_at",
							value: self.config.period.date_end,
							operator: "<=",
							type: "datetime"
						}
					],
					group: self.config.period.group
				};

				let type_request = "post";
				
				switch(self.type_module){
				case "carts":
					endpoint = "admin/dashboard/carts/data";
					break;					
				case "orders":
					endpoint = "admin/dashboard/orders/data";
					break;					
				}

				// Faz a chamada
				this.$store.dispatch("dashboard/lines", {
					type_module: self.type_module,
					endpoint: endpoint, 
					method: type_request, 
					filters: json_data
				}).then(() => {
					self.updateGrafico();
				});			

			});
			
		},
		toogleType: function(){
			if(this.type == "number"){
				this.type = "money";
			}else{
				this.type = "number";
			}
			this.updateGrafico();
		},
		setaConfig(){
			this.config = this.lines[this.type_module];
		},
		formataPrice(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender            
			if(value == 0){
				return "R$ 0,00";
			}

			if(typeof value == "number"){
				value = value.toFixed(2);
			}

			if (value)
				return "R$ "+value.replace(".", ",").replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
			else
				return "";

		},
		formataNumber(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender
			if(value)
				return value.toString().replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
			else
				return 0;
		},
		updateGrafico(){			
			this.$refs.chart.updateOptions(this.options);			
			this.$refs.chart.updateSeries(this.series);
		}
	},
	filters: {
		price: function(value){
			this.formataPrice(value);
			return value;
		},
		number: function(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender
			return value.toString().replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
		}		
	},
	created() {		
		this.setaConfig();	
	},
	watch: {		
		"config.period.refresh": function(){
			this.updateData();				
		}	
	},
	mounted(){		
		
	}
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<i class="fa fa-retweet invert-data" aria-hidden="true" v-on:click="toogleType()"></i>
				<Apex-charts ref="chart" width="100%" type="bar" :options="options" :series="series"></Apex-charts>
			</div>
		</div>
	</div>
</template>