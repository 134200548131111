<template>
	<div class="row"> 
		<div class="col-12 component_required" :required="required" validation-rules="multiselect" :valor="(selecionados.name) ? selecionados.name : ''">
			<div class="input-group" v-if="multiple || (!multiple && selecionados.id == null)">
				<input autocomplete="off" type="text" class="form-control" placeholder="Digite para Buscar" :id="'input_multiselect_'+tipo" v-on:focusin="exibirLista('focusin')" v-on:blur="exibirLista('focusout')" v-on:keyup="dadosFiltered($event)" v-model="search" v-on:keyup.38="navigateActive('up')" v-on:keyup.40="navigateActive('down')" v-on:keydown.enter="selectItemFromKeyboard()" v-on:keyup.esc="clearSearch()">
				<div class="input-group-append" v-show="dadosFiltered().length > 0">
					<button class="btn btn-secondary" type="button" :id="'button_multiselect_'+tipo" v-on:click="exibirLista('click')"><i class="fas fa-caret-up" v-if="exibir_lista"></i><i class="fas fa-caret-down" v-else></i></button>
				</div>
			</div>
			<div class="input-group" v-else>				
				<input autocomplete="off" type="text" class="form-control" placeholder="Digite para Buscar" :disabled=true v-model="selecionados.name"  v-on:keyup.esc="clearSearch()" />
				<div class="input-group-append">
					<button class="btn btn-danger" type="button" v-on:click="clearSelected()"><i class="fas fa-times"></i></button>
				</div>
			</div>
			<div class="dados" v-show="exibir_lista">
				<ul class="list-group" v-bind:class="'filtros-selecionar_'+tipo">
					<li class="list-group-item" :class="(!multiple && dado.id == selecionados.id) ? 'selected' : ''" v-for="(dado,index) in dadosFiltered()" :key=dado.id v-on:mousedown="seleciona(dado, (insert && search != '' && index == 0) ? true : false)" :id="dado.id" >
						{{ dado.name }} 
						<span class="badge badge-success float-right" v-if="insert && search != '' && index == 0">{{ text_new_tag }}</span>
					</li>
					<li class="list-group-item"  v-if="dadosFiltered().length == 0 && msg_search != ''">{{ msg_search }}</li>
				</ul>
			</div>
			<div class="dados_selecionados mt-3" v-if="multiple && !modo_tag">
				<div class="input-group mb-2" v-for="(item,idx) in selecionados" :key="item.id">
					<div class="input-group-prepend" v-if="item.hasOwnProperty('required') && field_required">
						<div class="input-group-text">
							<input type="checkbox" :checked="item.required" v-on:click="checkRequired(item,idx)" />
						</div>
					</div>
					<input type="text" class="form-control" :disabled=true :value="item.name" />
					<div class="input-group-append">
						<button class="btn btn-danger" type="button" v-on:click="removeSelecionado(idx)"><i class="fas fa-times"></i></button>
					</div>
				</div>	
			</div>			
			<div class="dados_selecionados mt-3" v-if="multiple && modo_tag">
				<label class="tag mb-2 mr-2" v-for="(item,idx) in selecionados" :key="item.id">
					{{ item.name }}
					<span class="badge badge-success ml-1" v-on:click="removeSelecionado(idx)" ><i class="fas fa-times"></i></span>
				</label>	
			</div>			
		</div>
	</div>	
</template>

<script>
import {Slug} from "./../../common/utils";
export default {
	name: "MultiSelect",
	props: {
		state: String, // para acessar o state correto
		tipo: String, // para pegar o campo correto dentro de model
		field_required: Boolean, // verifica se o componente usa o campo required
		multiple: { // identifica se é multiplo ou não
			type: Boolean,
			default: true
		},
		required: {
			type: Boolean,
			default: false,			
		},
		insert: {
			type: Boolean,
			default: false
		},
		modo_tag: {
			type: Boolean,
			default: false
		},
		excluir_validation: {
			type: Boolean,
			default: false
		},
		clear_on_enter: {
			type: Boolean,
			required: false,
			default: false
		},
		dynamic: {
			type: Boolean,
			required: false,
			default: false
		},
		dynamic_action: {
			type: String,
			required: false,
			default: ""
		},
		text_new_tag: {
			type: String,
			required: false,
			default: "Inserir Nova Tag"			
		},		
		dados: {			
			type: Array,
			validator: function (dados) {		
				// caso não tenha o campo required ainda, ele inclui
				dados.forEach((item) => {
					if(item.required === undefined){
						item.required = false;
					}					
				});			
				return dados;
			}
		}
	},
	computed: {		
		selecionados() {			
			let tipo = this.tipo.split("."); // exemplo: rules.email			
			if(tipo.length > 1){				
				return this.$store.state[this.state].model[tipo[0]][tipo[1]];
			}else{
				return this.$store.state[this.state].model[this.tipo];
			}
			
		}
	},	
	data () {
		return {
			exibir_lista: false,
			ignora_click: false,
			search: "",
			msg_search: "",
			dynamic_data: []
		};
	},  
	created() {
	},
	methods: {		
		exibirLista: function(method){	
			let self = this;
			let button = document.getElementById("button_multiselect_"+this.tipo);
			switch(method){
			case "click":
				if(!this.ignora_click){
					if(button.classList.contains("active")){
						button.classList.remove("active");
						this.exibir_lista == false;
					}else{
						button.classList.add("active");
						this.exibir_lista == true;
						document.getElementById("input_multiselect_"+this.tipo).focus();
					}				
				}
				this.ignora_click = false;
				break;
			case "focusin":				
				if(!button.classList.contains("active")){
					button.classList.add("active");
				}
				this.exibir_lista = true;
				break;
			case "focusout":
				// remove a class active da lista
				var lista = document.getElementsByClassName("filtros-selecionar_"+this.tipo)[0].children;
				var indice_maximo = lista.length-1;
				for(let li=0;li<=indice_maximo;li++){					
					lista[li].classList.remove("active");
				}
				// remove a class active do botão
				if(button.classList.contains("active")){
					button.classList.remove("active");
				}				
				// esconde a lista
				this.exibir_lista = false;
				// ignora o click caso ele esteja clicando no botão
				this.ignora_click = true;				
				setTimeout(function(){
					// timeout para caso seja apenas mouseout e não tenha o click depois
					self.ignora_click = false;
				},1000);
				break;
			default:
				break;
			}
		},
		filterJson: function(item){
			let self = this;
			return item.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(self.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
		},
		selectItemFromKeyboard: function(){
			let lista = document.getElementsByClassName("filtros-selecionar_"+this.tipo)[0].children;						
			let indice_maximo = lista.length-1;		
			let selected = document.getElementsByClassName("filtros-selecionar_"+this.tipo)[0].getElementsByClassName("active").length;			
			// pega o primeiro como selecionado
			if(selected == 0 && lista.length > 0){
				lista[0].classList.add("active");
			}	
			for(let li=0;li<=indice_maximo;li++){					
				if(lista[li].classList.contains("active")){
					lista[li].classList.remove("active");
					let id = lista[li].getAttribute("id");	
					let _dados = [];
					if(this.dynamic){
						_dados = this.dynamic_data;
					}else{
						_dados = this.dados;
					}					
					let item = _dados.filter(dado => {
						return dado.id == id;
					})[0];
					if(item != undefined){
						this.seleciona(item, false);
					}else{
						let _item = _dados.filter(dado => {
							return dado.id == Slug(this.search);
						})[0];
						if(_item != undefined){
							this.seleciona(_item, false);
						}else{
							// cria uma nova tag
							if(this.insert && this.search != ""){							
								this.seleciona({
									name: this.search,
									slug: Slug(this.search)
								}, true);
							}
						}						
					}
					
					break;
				}
			}
		},
		navigateActive: function(direction){
			this.$nextTick(function () {
				let lista = document.getElementsByClassName("filtros-selecionar_"+this.tipo)[0].children;
				let indice_maximo = lista.length-1;
				let indice_lista = 0;
				if(direction == "up"){
					for(let li=indice_maximo;li>=0;li--){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li > 0){
								indice_lista = li-1;	
							}
							break;
						}
					}
				}else{
					for(let li=0;li<=indice_maximo;li++){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li < indice_maximo){
								indice_lista = li+1;	
							}						
							break;
						}
					}
				}
				
				// aplica a classe
				lista[indice_lista].classList.add("active");				
			});			
		},
		dadosFiltered: function(ev){
			let self = this;			
			let dados = [];
			// se for busca dinamica no banco
			if(this.dynamic){
				if(ev === undefined){
					return this.dynamic_data;
				}
				// validando algumas teclas
				if([40,38,37,39,13,36,35,16,17].includes(ev.keyCode)){
					return this.dynamic_data;
				}
				if(self.search == ""){
					self.msg_search = "Digite no mínimo 4 letras";
				}else{
					self.msg_search = "Procurando ...";
				}
				if(this.needs_store && !this.store_id > 0){
					self.msg_search = "Selecione uma loja.";
					return;
				}
				if(this.search != ""){					
					let data = {
						text: self.search
					};
					if(this.needs_store){
						data.store_id = this.store_id;
					}
					clearTimeout(self.searchTime);
					self.searchTime = setTimeout(function(){ 
						self.dynamic_data = [];
						if(self.search.length > 3){									
							self.$store.dispatch(self.dynamic_action,data).then(response => {		
								response.forEach(item => {
									if(!item.hasOwnProperty("name")){
										item.name = item.title;
									}						
								});								
								self.dynamic_data = response;
								self.msg_search = "";
								if(self.dynamic_data.length == 0){
									self.msg_search = "Nenhum registro encontrado.";		
								}
							});
						}else if(self.search.length <= 3){
							self.dynamic_data = [];
							self.msg_search = "Digite no mínimo 4 letras";
						}
					}, 650);
				}
			}else{
				// se for busca dentro dos registros enviados na props
				dados = Object.assign([], this.dados);
				if(this.search != ""){
					// se for habilitado o modelo de inserir novas opções
					if(this.insert){
						dados.unshift({
							id: null,
							name: this.search
						});
					}
					// faz o filtro
					let new_data = [];
					dados.forEach(function(item){
						if(self.filterJson(item.name)){						
							new_data.push(Object.assign({}, item));
						}
					});		
					dados = new_data;
				}
			}		
			// tira os já selecionados se for tipo multiple			
			if(this.multiple){
				dados = dados.filter(item => {																				
					return self.selecionados.find(dado => { return item.name == dado.name; }) == undefined;
				});	
			}				
			
			// tratando o required
			dados.forEach(item => {				
				if(!this.field_required){
					delete item.required;
				}
			});	
			
			return dados;
		},
		checkRequired: function(item,index){
			if(item.required == true){
				item.required = false;
			}else{
				item.required = true;
			}
			let selecionados = Object.assign([],this.selecionados);
			selecionados[index] = item;
			this.$store.commit(this.state + "/multiselect_update", {item:selecionados,tipo:this.tipo});
		},
		removeSelecionado: function(index){
			let selecionados = Object.assign([],this.selecionados);
			selecionados[index].required = false;
			selecionados.splice(index,1);
			this.$store.commit(this.state + "/multiselect_update", {item:selecionados,tipo:this.tipo});		
			// informa que houve a alteração
			this.$emit("input", { type: "deleted", itens: selecionados, tipo:this.tipo });			
		},
		clearSelected: function(){
			let valido = true;
			// se tiver que validar a exclusão
			if(this.excluir_validation && !this.validaExclusao(this.tipo)){
				valido = false;
			}	
			if(valido){
				let item = {
					id: null,
					name: null
				};
				this.search = "";
				this.seleciona(item,false);
			}
		},
		clearSearch: function(){
			this.search = "";
		},
		seleciona: function(item, criar){
			let self = this;
			if(!this.field_required){
				delete item.required;
			}
			if(!this.multiple){
				item = {
					id: item.id,
					name: item.name
				};
			}
			if(this.insert && criar){
				this.$store.dispatch(this.state + "/addTag", {item:item,tipo:this.tipo}).then(response => {
					self.updateDadosMultiselect(response,self.tipo);
					self.search = "";
					this.$emit("input", { type: "new", item: item, tipo:self.tipo });
				});
			}else{				
				this.$store.commit(this.state + "/multiselect_push", {item:item,tipo:this.tipo, multiple: this.multiple});
				this.$emit("input", { type: "selected", item: item, tipo:this.tipo });
			}
			if(this.clear_on_enter){
				this.clearSearch();
			}			
		},
	},
	inject: {
		updateDadosMultiselect: {
			default: () => {}
		},		
		validaExclusao: {
			default: () => {}
		}
	},
	mounted() {
		
	}	
};
</script>

<style scoped lang="scss">	
	.row{
		.dados{
			position: relative;
			.list-group{
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				z-index: 10;
				li{
					font-size: 0.95em;
					&:hover, &.active, &.selected{
						background: $bg_destaque;
						color: #FFF;
						cursor: pointer;
						border:none;
					}
				}
			}			
		}	
		.tag{
			padding: 0.3em 0.5em;
			background: $bg_destaque;
			border-radius: 5px;
			color: #FFF;
			span{
				background: #FFF;
				cursor: pointer;
				i{
					color: $bg_destaque;
				}
			}
		}	
	}
	
</style>