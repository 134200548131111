export default {
	init: function() {
		return {
			name: "CEM Admin",
			img_url_base: "https://imagens.mundodacarabina.com.br",
			img_url_stores: {
				1: "https://imagens.mundodacarabina.com.br",
				2: "https://imagens.aventuraecia.com.br",
				3: "https://imagens.lexo.com.br"
			},
			requests: [],
			usuario: null,
			logged: false,
			hide_menu: false,
			permissoes: [],
			timeExpireToken: 10,
			refreshing_token: false,
			passValidate: "Mundo0680*2022"
		};
	}
};