<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		
		<!-- Tabela -->
		<Tabela :config="config" :modulo_permissao=modulo_permissao />
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Tabela from "./../../common/Tabela";

export default {
	name: "Banners",
	components: {
		Header,
		Breadcrumb,
		Tabela
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	data () {
		return {
			header_info: {
				title: "Banners",
				chamada: "Gerencie os banners da sua loja",
				links: {
					criar: "banners.novo"
				}
			},
			breadcrumb_info: [
				{
					name: "Banners",
					link: ""
				}
			],
			filtros: null,
			valores: null,
			permissao: [],
			config: {
				state: "banners",
				tipo: "banners",
				modulo_permissao: this.modulo_permissao,
				url_path: "banners",
				flags: {
					multi: {
						enable: true,
						delete: true,
						export: false,
						print: false,
						transfer: false,
						activate: true,
						inactivate: true
					},
					look: false,
					edit: true,
					delete: true
				}
			},
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	mounted() {
	},
	methods: {		
		novo() {
			this.$store.dispatch(this.config.state + "/reset");
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
