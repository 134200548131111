<template>
	<div class="form-group" v-bind:class="{'required': required, 'component_required': (['editor'].includes(type))}" :required="(['editor'].includes(type) && required)" :validation-rules="(['editor'].includes(type)) ? 'editor' : null" :valor="(['editor'].includes(type)) ? value : null">
		<label class="mb-1">{{ label }} <i class="fas fa-asterisk"></i></label>
		<textarea type="text" class="form-control" :placeholder="placeholder" :rows="rows" ref="model" :value="value" @input="updateDate()" @change="change()" :required=required :validation-rules="rules" :disabled=disabled v-if="type == 'textarea'"></textarea>
		<vue-editor  ref="editor" v-model="content_editor" :placeholder="placeholder" v-if="type == 'editor'" @blur="onEditorBlur" @selection-change="onSelectionChange" useCustomImageHandler @image-added="handleImageAdded" @image-removed="handleImageToDelete" />
		<small class="form-text text-muted mt-2" v-if="info != ''">{{ info }}</small>
	</div>
</template>

<script>

import { VueEditor } from "vue2-editor";
import api from "./../../../common/api";

export default {
	name: "Textarea",
	props: {
		type: {
			type: String,
			default: "textarea",
		},
		required: {
			type: Boolean,
			default: true,			
		},
		disabled: {
			type: Boolean,
			default: false,			
		},
		rules: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: "",			
		},
		info: {
			type: String,
			default: "",			
		},
		placeholder: {
			type: String,
			default: "",			
		},
		rows: {
			type: Number,
			default: 3,			
		},
		value: {			
			default: "",
		},
		state: {
			type: String,
			default: "",			
		},
		upload_type: {
			type: String,
			default: "",			
		}
	},
	components: {
		VueEditor
	},
	computed: {
		model() {
			if(this.state != ""){
				return this.$store.state[this.state].model;
			}else{
				return {};
			}
		},	
		img_base_urls() {
			return this.$store.state.app.img_url_stores;
		}
	},	
	data () {
		return {
			content_editor: this.value,
			last_range: null			
		};
	},  
	created() {
		
	},
	mounted() {
		
	},
	methods: {		
		updateDate() {									
			this.$emit("input", this.$refs.model.value);
		},
		change() {									
			this.$emit("change", this.$refs.model.value);
		},
		onEditorBlur(quill){			
			this.last_range = quill.selection.savedRange.index;
		},
		onSelectionChange(range){
			if(range != null){
				this.last_range = range.index;
			}			
		},
		getRangeIndex() {
			this.$refs.editor.handleSelectionChange();
			return this.last_range;
		},
		handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {

			let self = this;
			let store_id = 1;
			if(this.model.hasOwnProperty("store_id") && this.model.store_id > 0){
				store_id = this.model.store_id;
			}		

			api.upload("", "admin/image", "post", {type: self.upload_type, file: file, store_id: store_id}, (response) => {
				if (response.body.return_code == 1) {
					let url = `${this.img_base_urls[store_id]}${response.body.data.url}`;
					Editor.insertEmbed(cursorLocation, "image", url);
					resetUploader();
				}
			});
			
			
		},
		handleImageToDelete: function(url){			
			let store_id = 1;
			if(this.model.hasOwnProperty("store_id") && this.model.store_id > 0){
				store_id = this.model.store_id;
			}
			for (const value of Object.values(this.img_base_urls)) {
				url = url.replace(value, "");
			}
			api.request("", "admin/image", "delete", {url: url, store_id: store_id}, null);
		}
	},	
	watch: {
		// Para o caso do plugin do editor dispara o evento por aqui
		content_editor: function(dado){		
			this.$emit("input", dado);
		},
		value: function(dado){			
			if(this.content_editor == null){				
				this.content_editor = dado;
			}
		}		
	}
};
</script>

<style scoped lang="scss">	
	@import '~@/assets/css/form.scss';
</style>
