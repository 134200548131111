<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="card card-base">
					<div class="card-header">
						Informações Gerais do Voucher
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<div class="form-group required"> 
									<label class="mb-1">Loja <i class="fas fa-asterisk"></i></label>
									<select class="form-control" v-model="model.store_id" v-on:change="select_all = false;">
										<option :value=1>Mundo da Carabina</option>
										<option :value=2>Aventura & Cia</option>
										<option :value=3>Lexo</option>
									</select>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group required"> 
									<label class="mb-1">Cliente <i class="fas fa-asterisk"></i></label>
									<MultiSelect state="vouchers" tipo="customer" :dynamic=true dynamic_action="clientes/filter_customers_data" :dados="[]" :field_required=false :multiple=false :required=true :excluir_validation=false></MultiSelect>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group required"> 
									<label class="mb-1">Número do pedido</label>
									<MultiSelect state="vouchers" tipo="orders" :dados="client_orders" :field_required=false :multiple=false :required=false :excluir_validation=false></MultiSelect>
								</div>
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Valor" placeholder="" info="" position="left" text="R$" v-model="model.value" type="money" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: true,label: 'Utilizado'}, {id: false, label: 'Aguardando'}]" v-model="model.used"  type="boolean" />
							</div>
							<div class="col-6">
								<FormInput :required=true name="reason" rules="string" label="Motivo" placeholder="" info="" v-model="model.reason" :slug_function=false />
							</div>
							
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import MultiSelect from "./../../common/MultiSelect";

// Inputs
import FormInput from "./../../common/form/Input";
import FormInputGroup from "./../../common/form/InputGroup";
import FormSelect from "./../../common/form/Select";

export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		MultiSelect,
		FormInputGroup,
		FormSelect
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.vouchers.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},	
		usuario() {
			return this.$store.state.app.usuario;
		}	
	},	
	data () {
		return {
			header_info: {
				title: "Vouchers",
				chamada: "Gerencie as vouchers da sua loja",
				links: {
					back: "vouchers.lista",
					save: this.save_enabled() 
				}
			},
			breadcrumb_info: [				
				{
					name: "Vouchers",
					link: "/vouchers"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral"
			],
			disabled_items: [],
			hidden_items: [],
			client_orders: [],
		};
	},  
	methods: {		
		save_enabled: function() {
			if (this.$route.name.indexOf("editar") != -1) {
				return "";
			} else {
				return ".";
			}
		},
		save: function() {

			if(validation.validation().valid){			
				let model = Object.assign({},this.model);
				model.user = {
					id: this.usuario.id,
					name: this.usuario.name
				};	
				
				if(model.orders.id){
					model.order_number = parseInt(model.orders.name);
				}	

				delete model.orders;

				this.$store.dispatch("vouchers/save", model);
			}
		},
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("vouchers/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			self.$store.dispatch("vouchers/load", this.$route.params.id).then(() => {
				if(self.model.order_number){
					self.model.orders = {
						id: self.model.order_number,
						name: self.model.order_number,
					};
				}				
			});
		}
	},
	watch: {
		"model.customer": function(valor){
			if(valor.id){
				this.$store.dispatch("pedidos/getOrdersByCustomer", valor.id).then(response => {
					this.client_orders = response;
				});				
			}else{
				this.client_orders = [];
			}
		},
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
