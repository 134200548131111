export default {
	init: function() {
		return {
			model: {
				id: null,
				name: null,
				store_id: 0,
				banner_type: {
					id: null,
					name: null
				},
				type_link: 0, //relacionamento
				relation_link: [], //relação com categorias ou marcas
				initial_date: null,
				final_date: null,
				link: "",
				target: "_self",
				status: 1,				
				categories: [],
				brands: [],
				menus: [],
				grid: 0,
				image: "",
				image_mobile: "",
				// armazenam as marcas, categorias e tags selecionados da vitrine
				marcas_selecionadas: [],
				categorias_selecionadas: [],
				special_selecionadas: [],
				offerpages_selecionadas: []
			},
			categorias: [],
			//lista
			search_data: {
				opcoes : {
					order_field: "name",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
		};
	}
};
