<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row dashboard">
			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header ">
						Filtros Principais						
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col">
								<FormInputGroup ref="data_de" :required=true rules="string" label="Data de" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="d_filters.data_de" :index=0 />
							</div>					
							<div class="col">
								<FormInputGroup ref="data_ate" :required=true rules="string" label="Data até" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="d_filters.data_ate" :index=1 />
							</div>
							<div class="col">
								<FormInputGroup :required=true rules="string" label="% Nova Compra" placeholder="" info="" position="right" text="%" v-model="d_filters.sugestao" type="money" />
							</div>
							<div class="col">
								<FormInput :required=false rules="string" label="Dias Futuro" placeholder="" info="" v-model="d_filters.dias_futuro" type="number" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Tabela -->
		<Tabela ref="tabela" :config="config" :modulo_permissao=modulo_permissao />
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Tabela from "./../../common/Tabela";
import Filters from "../../../common/filters";

import FormInput from "../../common/form/Input";
import FormInputGroup from "../../common/form/InputGroup";

export default {
	name: "ReportsPedidos",
	components: {
		Header,
		Breadcrumb,
		Tabela,
		FormInput,
		FormInputGroup
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		d_filters() {
			return this.$store.state.reports_produtos_fornecedor.d_filters;
		},
	},
	filters: Filters,
	data () {
		return {
			header_info: {
				title: "Relatório de Produtos por Fornecedor",
				chamada: "Relatório de produtos por fornecedor",
				links: {
					criar: "",
				}
			},
			breadcrumb_info: [
				{
					name: "Relatórios de Produtos",
					link: ""
				}
			],
			filtros: null,
			valores: null,
			permissao: [],
			config: {
				state: "reports_produtos_fornecedor",
				tipo: "reports_produtos_fornecedor",
				modulo_permissao: this.modulo_permissao,
				url_path: "reports.produtos_fornecedor",
				flags: {
					multi: {
						enable: true,
						delete: false,
						export: true,
						print: false,
						transfer: false,
						activate: false,
						inactivate: false,						
					},
					look: false,
					edit: false,
					delete: false,
				}
			}			
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	mounted() {
		this.$refs.data_de.$forceUpdate();
		this.$refs.data_ate.$forceUpdate();
	},
	provide: function () {
		return {
			gerarResumo: this.gerarResumo
		};
	},
	methods: {		
		novo() {
			this.$store.dispatch(this.config.state + "/reset");
		},
		gerarResumo() {
			let search_data = Object.assign({}, this.$store.state[this.config.state].search_data);
			search_data.opcoes.resume = true;
			this.$store.dispatch(this.config.state + "/lista_filter_update", search_data);
		},
		aplicaFiltro() {
			const _store = this.$store.state[this.config.state];
			this.$store.dispatch(this.config.state + "/lista_filter_update", _store.search_data);
		}		
	},	
	watch: {
		d_filters:{
			handler: function(item){
				this.$store.commit(this.config.state + "/update_d_filters", item);
				this.$store.commit(this.config.state + "/data_valores_loaded", {return_code: 1, data: this.$store.state[this.config.state].valores});
			},		
			deep: true
		},
		"d_filters.data_de"(item) {
			if(item != ""){
				this.aplicaFiltro();
			}
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
