<template>
	<!-- Listagem -->
	<div class="row">
		<div class="col-12">
			<table class="table table-striped table-bordered table-hover table-listagem">
				<thead>
					<tr>
						<th v-bind:class="{'width-min': campo.field == 'id', 'ativo': validaSortAtivo(campo, 'field')}" v-for="campo in table_fields.campos" :key="(campo.field_object) ? `${campo.field}_${campo.field_object}` : campo.field"  v-on:click="sort(campo)" :width="campo.size + '%'">
							{{ campo.label }}							
							<!-- <i class="fas fa-sort" v-if="campo.field != 'id' && search_data.opcoes.order_field != campo.field"></i> -->
							<!-- <i class="fas fa-sort-up" v-if="campo.field != 'id' && search_data.opcoes.order_field == campo.field && search_data.opcoes.order == 'asc'"></i>
							<i class="fas fa-sort-down" v-if="campo.field != 'id' && search_data.opcoes.order_field == campo.field && search_data.opcoes.order == 'desc'"></i> -->

							<i class="fas fa-sort-amount-up" v-if="validaSortAtivo(campo, 'asc')"></i>
							<i class="fas fa-sort-amount-down" v-if="validaSortAtivo(campo, 'desc')"></i>
							
							<div class="checkbox_personalizado" v-if="campo.field == 'id'">
								<label class="fa fa-check" :class="checkAll ? 'checked' : ''" v-on:click="checkSelecionadoAll()"></label>
							</div>
						</th>
						<th v-if="(config.flags.look || config.flags.edit || config.flags.delete) && (permissao.editar == 1 || permissao.excluir == 1 || permissao.ver == 1)" class="actions">
							Ações
						</th>						
					</tr>
				</thead>					

				<draggable 
					handle=".order"
					:group="{ name: 'people', pull: 'clone', put: false }"
					ghost-class="ghost"
					:sort="true"
					:tag="'tbody'"
					@end="sortable_update"					
					v-bind="dragOptions"
					>
					<tr v-for="item in valores" :key="item.id">
						<td v-for="(value, name, index) in item" :key="index">		
							<div class="checkbox_personalizado text-center" v-if="getItemType(index,item).type == 'checkbox_id'">
								<label class="fa fa-check" :class="isChecked(value) ? 'checked' : ''" v-on:click="checkSelecionado(value)"></label>
							</div>								
							<div v-if="getItemType(index,item).type == 'string'">
								{{ getItemType(index,item).value }}
							</div>
							<div v-if="['object_string','object_substring'].includes(getItemType(index,item).type)">
								{{ getItemType(index,item).value }}
							</div>
							<div v-if="['object_string_group'].includes(getItemType(index,item).type)">
								{{ getItemType(index,item).value }}
							</div>							
							<div v-if="getItemType(index,item).type == 'money'">
								{{ getItemType(index,item).value | money }}
							</div>
							<div v-if="getItemType(index,item).type == 'percent'" class="text-center">
								{{ getItemType(index,item).value.toFixed(2) }}%
							</div>
							<div v-if="getItemType(index,item).type == 'status'">
								<span class="status_tabela" v-bind:class="getItemType(index,item).value.type">{{ getItemType(index,item).value.name }}</span>								
							</div>
							<div v-if="getItemType(index,item).type == 'cpfcnpj'">
								{{ getItemType(index,item).value | cnpfcnpj }}
							</div>
							<div v-if="getItemType(index,item).type == 'phone'">
								{{ getItemType(index,item).value }}
							</div>
							<div v-if="getItemType(index,item).type == 'cep'">
								{{ getItemType(index,item).value }}
							</div>
							<div v-if="getItemType(index,item).type == 'date_string'" class="text-center">
								{{ getItemType(index,item).value }}
							</div>
							<div v-if="getItemType(index,item).type == 'datetime_string'" class="text-center">
								{{ getItemType(index,item).value }}
							</div>
							<div v-if="getItemType(index,item).type == 'option'">
								{{ getItemType(index,item).value }}
							</div>
							<div v-if="getItemType(index,item).type == 'input_int'">
								{{ getItemType(index,item).value }}
							</div>
							<div v-if="getItemType(index,item).type == 'stars'">
								<!-- Ativo -->
								<i :class="[{'fas fa-star': (i<=getItemType(index,item).value)},{'far fa-star': (i>getItemType(index,item).value)}]" v-for="i in 5" :key="i"></i>
							</div>
							<div v-if="getItemType(index,item).type == 'input_money'">
								<FormInputGroup :required=true rules="string" type="money" label="" placeholder="" info="" position="left" text="R$" v-model="item[table_fields.campos[index].field]" @input="updatePrice(item)" />
							</div>
							<div v-if="getItemType(index,item).type == 'input_percentage'">
								<FormInputGroup :required=true rules="string" type="money" label="" placeholder="" info="" position="right" text="%" v-model="item[table_fields.campos[index].field]"  @input="updatePrice(item)" />
							</div>
							<div class="text-center" v-if="getItemType(index,item).type == 'margin_edicao_preco'">
								{{ getMargin(item) }}
							</div>
							<div  class="text-center" v-if="getItemType(index,item).type == 'switch'">
								<i class="fas fa-exclamation-circle" v-if="value == 2"></i>
								<i class="fas fa-toggle-on switch" v-else :class="value == 1 ? 'active' : ''" v-on:click="clickSwitch(name,value,item.id)"></i>
							</div>
							<div class="text-center" v-if="getItemType(index,item).type == 'order'">
								<i class="fas fa-sort order"></i>
							</div>
							<div v-if="getItemType(index,item).type == 'list'">
								{{ getItemType(index,item).value.map(function(o, i) { return o.name }).join(', ') }}
							</div>
							<div class="text-center td-store" v-if="getItemType(index,item).type == 'store'">
								<img src="./../../../assets/img/logo-mdc-40.png" v-if="getItemType(index,item).value == 'mundo'" style="width: 2em;" />
								<img src="./../../../assets/img/logo-aventura-40.png" v-if="getItemType(index,item).value == 'aventura'" style="width: 2em;" />
								<img src="./../../../assets/img/logo-lexo-40.png" v-if="getItemType(index,item).value == 'lexo'" style="width: 2em;" />
								<i class="fas fa-store marketplace" v-if="getItemType(index,item).value == 'marketplace'"></i>
							</div>
						</td>
						
						<td class="text-center" v-if="config.flags.look || config.flags.edit || config.flags.delete">
							<!-- Ver -->								
							<router-link :class="coresBotoes['view']['class']" v-if="config.flags.look && permissao.ver == 1" :to="{ name: config.url_path+'.ver', params: { id: item.id } }" ><i class="fas fa-eye"></i></router-link>
							<!-- Editar -->
							<router-link :class="coresBotoes['edit']['class']" v-if="config.flags.edit && permissao.editar == 1" :to="{ name: config.url_path+'.editar', params: { id: item.id } }"><i class="fas fa-edit"></i></router-link>
							<!-- Excluir -->
							<button :class="coresBotoes['remove']['class']" v-if="config.flags.delete  && permissao.excluir == 1" v-on:click="deletaRegistro(item.id)"><i class="fas fa-trash-alt"></i></button>
						</td>
					</tr>

					<!-- Caso não encontre nenhum registro, exibe a linha abaixo -->
					<tr class="text-center" v-if="valores.length == 0">
						<td :colspan="table_fields.campos.length+1">Nenhum registro encontrado</td>
					</tr>

				</draggable>
			</table>
		</div>
	</div>
</template>

<script>

import draggable from "vuedraggable";

import Filters from "./../../../common/filters";
import alerts from "./../../../common/alerts";
import FormInputGroup from "./../../common/form/InputGroup";

export default {
	name: "Listagem",
	props: {
		config: Object,
		modulo_permissao: String,
		table_fields: Object
	},
	components: {
		draggable,
		FormInputGroup
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		selecionados() {
			return this.$store.state[this.config.state].selecionados;
		},
		search_data(){			
			return this.$store.state[this.config.state].search_data;
		},
		valores() {
			let old_valores = Object.assign({}, this.$store.state[this.config.state].valores);
			let campos = this.table_fields.campos;
			let new_valores = [];

			if(old_valores != undefined && old_valores.hasOwnProperty("items")){
				old_valores = old_valores.items;
				// percorre o array de valores da listagem
				old_valores.forEach( lista => {
					let fields = {};
					// percorre os campos definidos no filters.json
					campos.forEach((item) => {
						// percorre as propriedades de cada objeto retornado para organizar na ordem correto os valores
						Object.getOwnPropertyNames(lista).forEach(function(val) {
							if(item.field == val){
								fields[val] = lista[val];
							}							
						});
						// se não tiver o campo no retorno, monta ele vazio para não quebrar a listagem
						if(!fields.hasOwnProperty(item.field)){							
							fields[item.field] = "";
						}						
					});
					new_valores.push(fields);
				});
			}			

			return new_valores;
		},
		dragOptions() {
			// verifica se tem algum campo order para permitir o drag
			let disabled = this.table_fields.campos.find((item) =>{
				return item.field == "order";
			});						
			return {
				animation: 200,
				group: "description",
				disabled: !disabled, // se existir marca como false, se não existir marca como true
				ghostClass: "ghost"
			};
		}
	},
	data () {
		return {			
			permissao: {},
			coresBotoes: {
				edit: {
					class : "btn btn-warning btn-sm mx-1"
				},
				view: {
					class : "btn btn-primary btn-sm mx-1"
				},
				remove: {
					class : "btn btn-danger btn-sm mx-1"
				}
			},			
			checkAll: false
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	methods: {		
		getItemType: function(index,item) {	
			// É passado o index para seguir a ordem crescente que foi criado no json de atributos
			let campos = this.table_fields.campos;								
			// verifica se existe o campo
			if(campos[index] != undefined){
				let value = "";
				// faz o tratamento para casos específicos ou pega o valor padrão
				switch(campos[index].type){
				case "option":
					value = campos[index].options.filter(dado => {
						return (dado.value == item[campos[index].field]);
					})[0].label;
					break;				
				case "object_string":										
					try {
						value = item[campos[index].field][campos[index].field_object];
					} catch {
						value = "";
					}								
					break;
				case "object_string_group":
					try {
						value = "";
						for (const [_key, _value] of Object.entries(item[campos[index].field])) {
							if(campos[index].objects.includes(_key)){
								if(value != ""){
									value += " | ";
								}
								value += _value;
							}
						}
					} catch {
						value = "";
					}								
					break;
				case "object_substring":					
					if (item[campos[index].field][campos[index].field_object]){
						value = item[campos[index].field][campos[index].field_object][campos[index].field_object_sub];
					}else{
						value = "";
					}					
					break;
				case "datetime_string":
					value = Filters.date_time(item[campos[index].field]);
					break;
				case "date_string":
					value = Filters.date(item[campos[index].field]);
					break;					
				default:
					value = item[campos[index].field];
					break;
				}
				return {
					type: campos[index].type,
					value: value
				};
			}else{
				return {
					type: "ignore"
				};
			}
		},
		isChecked: function(id){
			return this.selecionados.find((item) => {
				return id == item;
			});
		},
		checkSelecionado: function(id){
			this.$store.dispatch(this.config.state + "/select_listagem", id);
			
		},
		checkSelecionadoAll: function(){
			let selecionados = [];
			if(this.checkAll){
				// retirar a seleção
				this.checkAll = false;
			}else{
				// selecionar todos
				this.checkAll = true;
				this.valores.forEach((item) => {
					selecionados.push(item.id);
				});
			}
			this.$store.dispatch(this.config.state + "/select_listagem_all", selecionados);
			
		},
		clickSwitch: function(name,value,id){
			if(this.permissao.editar != 1){
				alerts.alertTop("error","Você não tem permissão para editar os dados desta página");
			}else{
				let self = this;
				if(value == 1){				
					value = 0;
				}else{
					value = 1;
				}
				self.$store.dispatch(self.config.state + "/switch_listagem", {field: name, value:value, id:id }).then( () => {
					self.aplicaFiltro();
				});
			}			
		},
		deletaRegistro: function(id){				
			
			if(this.permissao.excluir != 1){
				alerts.alertTop("error","Você não tem permissão para excluir os dados desta página");
			}else{
				let self = this;
				let event_confirm = () => {
					self.$store.dispatch(self.config.state + "/excluir_registro",id).then( () => {
						self.aplicaFiltro();
					});
					return true;
				};
				alerts.alertConfirm("Tem certeza que deseja excluir?",event_confirm);				
			}	
		},
		sort: function (objeto) {		
			let field;		
			if(objeto.field_object_sub){
				field = `${objeto.field}.${objeto.field_object}.${objeto.field_object_sub}`;
			}else if(objeto.field_object){
				field = `${objeto.field}.${objeto.field_object}`;
			}else{
				field = objeto.field;
			}			
			let self = this;		
			if(field != "id"){
				self.$store.dispatch(self.config.state + "/sort_listagem", field).then( () => {
					self.aplicaFiltro();
				});
			}
		},
		validaSortAtivo: function(objeto, type){
			// se for campo id não exibe
			if(objeto.field == "id'"){
				return false;
			}
			let field;
			if(objeto.field_object_sub){
				field = `${objeto.field}.${objeto.field_object}.${objeto.field_object_sub}`;
			}else if(objeto.field_object){
				field = `${objeto.field}.${objeto.field_object}`;
			}else{
				field = objeto.field;
			}			
			
			// validando se é apenas para validar se é um campo utilizado na ordenação
			if(type == "field"){
				return field === this.search_data.opcoes.order_field;
			}

			// validando para validar se a validação é asc ou desc
			if(this.search_data.opcoes.order_field == field && this.search_data.opcoes.order == type){
				return true;
			}
			return false;
		},
		sortable_update: function(obj){
			let self = this;
			// pega o order do registro antigo onde o novo registro entrou no lugar
			let order = this.valores[obj.newIndex].order;
			// pega o id registro que foi mexido
			let id = this.valores[obj.oldIndex].id;
			
			let revert = false;
			if(this.search_data.opcoes.order_field != "order"){
				alerts.alertTop("error","Para realizar esta ação é necessário estar ordenando pela coluna Ordem");				
				revert = true;
			}	

			if(!revert){
				// executa 
				self.$store.dispatch(self.config.state + "/update_ordem", {
					order: order,
					id: id
				}).then(() => {
					// aplica o filtro para atualizar as ordens dos registros no json
					self.aplicaFiltro();
				});
			}else{
				// reverte
				this.aplicaFiltro();
			}

		},
		updatePrice: function(item){
			// marca como updated
			let valores = Object.assign([],this.valores);
			// atualiza o storage
			this.$store.commit("precos/update_prices",{items: valores, item: item});
		},
		getMargin: function(item){
			if(item.cost <= 0){
				return 0;
			}
			return ((item.full_price - item.discount_real) / item.cost).toFixed(2);
		}
	},
	inject: ["aplicaFiltro"],
	filters: Filters,
	mounted() {
		let self = this;
		let table_fields = this.table_fields;
		// pega via action os dados para exibir no options
		table_fields.filtros.forEach(item => {
			if(item.type == "select" && item.action != undefined){
				self.$store.dispatch(item.action).then(response => {
					item.options = [];
					response.forEach(dado => {
						item.options.push({
							value: dado[item.data_value],
							label: dado[item.data_label]
						});
					});
				});
			}
		});	
		this.$store.commit(this.config.state + "/data_filtros_loaded", table_fields);
	},
	watch: {
		selecionados: function(itens){			
			if(itens.length == 0){
				this.checkAll = false;
			}
		}
	}
};
</script>

<style scoped lang="scss">	
	.table-listagem {
		tbody,
		thead {
			tr {
				cursor: pointer;				
				td {
					vertical-align: middle;
					&.width-min {
						width: 1%;
						text-align: center;
						input[type=checkbox] {
							width: 1em;
							height: 1em;
						}
					}
				}
			}
		}
		tbody {
			tr {
				background: #f3f3f3;
				td {
					vertical-align: middle;
					border-left: none;
					border-right: none;
					&:first-child {
						border-right: 1px solid #D5D5D5;
					}					
					i{
						&.fa-star{
							color: $color_yellow;
						}
						&.switch{
							font-size: 2.3em;
							color: $color_red;
							&.active{
								color: $color_green;
							}
						}
						&.order{
							font-size: 2.3em;
						}
						&.fa-exclamation-circle{
							font-size: 2em;
							color: $color_red;
						}
					}
					.btn-sm{
						font-size: 0.8em;
						padding: 0.2em 0.4em;
					}
					.td-store{						
						i{
							font-size: 1.6em;
							&.mundo{
								color: #985C16;
							}
							&.aventura{
								color: #3B402D;
							}
							&.lexo{
								color: #16548D;
							}
							&.marketplace{
								color: #16aa99;
							}
						}
					}
					.form-group{
						margin-bottom: 0!important;
					}
				}
				&:nth-of-type(odd) {
					background: #F9F9F9;
					&:hover {
						background: #efecd5;
					}
				}
				&:hover {
					background: #efecd5;
				}
				&:active {
					opacity: 0.2;
				}
			}
		}
		thead {
			tr {
				th:not(.width-min):not(.actions){
					position: relative;
					cursor: pointer;
					&:hover,
					&.ativo {
						border: 1px solid $border_destaque;
						background: $bg_destaque;
						color: #FFF;
					}
				}
				th {
					border: none;
					border-left: 1px solid #e2e2e2;
					background: #FFF;
					span {
						position: absolute;
						right: 9px;
						top: 12px;
						display: none;
					}
					i{
						float: right;
					}
					&:hover,
					&.ativo {
						span {
							display: inline;
						}
					}
					.checkbox_personalizado {
						text-align: center;
						label {
							padding: 2px;
							margin: 0 auto;
						}
					}
				}
			}
		}
		.checkbox_personalizado {
			position: relative;
			input {
				position: absolute;
				opacity: 0;
			}
			label {
				padding: 3px;
				font-size: 11px;
				text-align: center;
				background: #FFF;
				color: #FFF;
				border: 1px solid #e2e2e2;
				cursor: pointer;
				&.checked,
				&:hover {
					background: $bg_destaque;
					border-color: $border_destaque;
					color: #FFF;
				}
				&.checked {
					&:hover {
						background: #FFF;
						border-color: #e2e2e2;
						color: #e2e2e2;
					}
				}
			}
		}
		.status_tabela{
			display: block;
			padding: 0.3em 1em;        
			background: #bababa;        
			color:#444444; 
			text-transform: uppercase;
			font-size: 0.9em;
			&.new{
				background: #6bd3f3;
				color:#FFF;
			}
			&.processing{
				background: #ffd200;
				color:#444444;
			}
			&.payment-problem{
				background: #e55957;
				color:#FFF;
			}
			&.paid{
				background: #53d192;
				color:#FFF;
			}
			&.sent{
				background: #9c78cd;
				color:#FFF;
			}
			&.delivered{
				background: #444444;
				color:#FFF;
			}
			&.canceled{
				background: #e55957;
				color:#FFF;
			}
			&.problem{
				background: #e55957;
				color:#FFF;
			}
		}
	}
</style>
