<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="formulario-dados mt-4 col-12"> 
				<div class="row">
					<div class="col-5">
						<div class="card">
							<div class="card-header">
								Categorias Cadastradas
							</div>
							<div class="card-body">
								<!-- Conteudo -->
								<Categorias :permissao=permissao state="categorias" />
								<!-- Fim Conteudo -->
							</div>
						</div>
					</div>
					<div class="col-7"  v-if="model.name != null">
						<div class="card card-base">
							<div class="card-header">
								Dados da Categoria
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-12">
										<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" />
									</div>
									<div class="col-12">
										<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id" type="number" />
									</div>
									<div class="col-12">
										<FormTextarea state="categorias" upload_type="editor_categories" :required=false label="Descrição" v-model="model.description" :rows=3 placeholder="Digite aqui um texto sobre essa categoria" info="" type="editor" />
									</div>
									<div class="col-12">
										<FormInputGroup :required=true rules="string" label="Slug" placeholder="" info="" position="left" text="https://*.com.br" v-model="model.slug" />
									</div>
									<div class="col-12">
										<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
									</div>
								</div>	
							</div>
						</div>
						<div class="card card-base">
							<div class="card-header">
								Atributos
							</div>
							<div class="card-body">
								<MultiSelect state="categorias" tipo="attributes" :dados="atributos" :selecionados="atributos_selecionados" :field_required=false></MultiSelect>
							</div>
						</div>
						<div class="card card-base">
							<div class="card-header">
								Metatags
							</div>
							<div class="card-body">
								<Metatags  v-model="model.metatags"></Metatags>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import validation from "./../../../common/validation";
import Categorias from "./../../common/Categorias";
import MultiSelect from "./../../common/MultiSelect";
import Metatags from "./../../common/MetaTags";
import Alerts from "./../../../common/alerts";

// Inputs
import FormInput from "./../../common/form/Input";
import FormInputGroup from "./../../common/form/InputGroup";
import FormSelect from "./../../common/form/Select";
import FormTextarea from "./../../common/form/Textarea";

export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		Categorias,
		FormInput,
		FormInputGroup,
		FormSelect,
		MultiSelect,
		Metatags,
		FormTextarea
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		},		
	},
	computed: {
		model() {
			return this.$store.state.categorias.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		header_info() {
			let links = {};
			if(this.model.name != null){
				links["save"] = ".";				
				links["cancel"] = ".";
			}
			if(this.model.id != null){
				links["delete"] = ".";
			}
			return {
				title: "Categorias",
				chamada: "Gerencie as categorias da sua loja",
				links: links
			};			
		},		
	},	
	data () {
		return {			
			breadcrumb_info: [				
				{
					name: "Categorias",
					link: "/categorias"
				}				
			],
			itens_tab:[],
			disabled_items: [],
			hidden_items: [],
			atributos: [],
			atributos_selecionados: []
		};
	},  
	methods: {		
		save: function(){			
			let self= this;
			if(validation.validation().valid){								
				// salva
				this.$store.dispatch("categorias/save", this.model).then((response) => {
					if(response.return_code == 1){
						// pega as categorias
						self.$store.dispatch("categorias/reset");
						self.$store.dispatch("categorias/load_categorias");
					}					
				});
			}
		},
		excluir: function(){
			if(this.permissao.excluir != 1){
				Alerts.alertTop("error","Você não tem permissão para excluir esta categoria.");
				return;
			}
			let self= this;
			let event_confirm = () => {
				self.$store.dispatch("categorias/excluir_registro",self.model.id).then((response) => {
					if(response.return_code == 1){
						// pega as categorias
						self.$store.dispatch("categorias/reset");
						self.$store.dispatch("categorias/load_categorias");
					}					
				});
				return true;
			};
			Alerts.alertConfirm("Tem certeza que deseja excluir esta categoria?",event_confirm);
			
		},
		cancel: function(){
			this.$store.dispatch("categorias/reset");
			this.$store.dispatch("categorias/load_categorias");
		}
	},
	provide: function () {
		return {
			save: this.save,
			excluir: this.excluir,
			cancel: this.cancel
		};
	},
	created() {
		let self = this;
		// pega os atributos
		this.$store.dispatch("atributos/load_attributes").then((response) => {
			self.atributos = [];
			response.forEach(item => {
				if(item.type != "text"){
					self.atributos.push(item);
				}
			});
		});
		// pega as categorias
		this.$store.dispatch("categorias/load_categorias");

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	}
};
</script>

<style scoped lang="scss">	
	.departamento{
		.categorias{
			border-left: 1px dashed #d5d5d5;
			padding-left: 1.1em;
			margin-left: 1.1em;
			.subcategorias{
				border-left: 1px dashed #d5d5d5;
				padding-left: 1.1em;
				margin-left: 1.1em;
			}
		}
	}
</style>
