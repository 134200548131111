<template>
	<ul>
		<li><strong>{{ filter.config.label }}</strong></li>
		<li class="ml-2" v-if="['cep', 'int', 'money', 'date', 'datetime'].includes(filter.config.type)">
			<select v-model="operator">
				<option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
			</select>
		</li>
		<li class="ml-2">
			<input type="text" v-model="value" v-on:change="updateValue()" v-bind:class="'input_'+index" v-if="['string', 'fulltext'].includes(filter.config.type)" />
			<input type="text" v-model="value" v-on:change="updateValue()" v-bind:class="'input_'+index" v-if="['cep', 'int'].includes(filter.config.type)" v-mask="pegaMascara()" />
			<input type="text" v-model.lazy="value" v-money="money" v-on:blur="updateValue()" v-on:keyup.enter="updateValue()" v-bind:class="'input_'+index" v-if="filter.config.type == 'money'" />
		</li>
		<li class="ml-2"><button v-on:click="removeFiltro(index)"><i class="fas fa-times"></i></button></li>
	</ul>
</template>

<script>

import {mask} from "vue-the-mask";
import {VMoney} from "v-money";

export default {
	name: "FiltroTexto",
	props: {
		filter: Object,
		index: Number
	},
	components: {
	},
	directives: {
		mask,
		money: VMoney
	},
	computed: {		
		
	},
	data () {
		return {
			value: "",
			operator: "=",
			options: [
				{
					value: "=",
					label: "Igual"
				},
				{
					value: ">",
					label: "Maior que"
				},
				{
					value: ">=",
					label: "Maior ou igual a"
				},
				{
					value: "<",
					label: "Menor que"
				},
				{
					value: "<=",
					label: "Menor ou igual a"
				},
				{
					value: "!=",
					label: "Diferente de"
				}
			],
			masks: [
				{
					type: "cep",
					mask: "#####-###"
				},
				{
					type: "int",
					mask: "#############"
				}
			],
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "",
				suffix: "",
				precision: 2,
				masked: false
			},
		};
	}, 
	created() {
		this.value = this.filter.search.value;
	},
	methods: {		
		updateValue: function(){

			let type;
			let valor = this.value.trim();

			switch(this.filter.config.type){
			case "string":
				this.operator = "like";
				type = "string";
				break;
			case "fulltext":
				this.operator = "=";
				type = "fulltext";
				break;				
			case "cep":
				type = "int";
				valor = Number(valor.replace("-",""));
				break;
			case "int":
				type = "int";
				valor = Number(valor);
				break;
			case "money":
				type = "decimal";
				valor = valor.replace(/\./g,"");
				valor = valor.replace(",",".");
				valor = parseFloat(valor);
				break;
			}
			
			this.filter.search.value = valor;
			this.filter.search.operator = this.operator;
			this.filter.search.type = type;

			this.updateFiltro(this.filter,this.index);
		},
		pegaMascara: function(){
			let config = this.filter.config;
			return this.masks.filter((mask) => {
				return mask.type == config.type;
			})[0].mask;
		}
	},
	inject: ["updateFiltro","removeFiltro"],
	mounted(){
		if(this.value == "" || this.value == "0,00"){
			document.getElementsByClassName("input_"+this.index)[0].focus();
		}	
	}
};
</script>

<style scoped lang="scss">	

@import "./../../../../assets/css/filters.scss";
	
</style>
