<template>
	<ul>
		<li><strong>{{ filter.config.label }}</strong></li>
		<li class="ml-2">
			<select v-model="operator">
				<option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
			</select>
		</li>
		<li class="ml-2">
			<flat-pickr
				v-if="['date', 'datetime'].includes(filter.config.type)"								
				:config="options_calendar"                                                          
				class=""
				v-bind:class="'input_'+index"
				v-model.lazy="value">
			</flat-pickr>
		</li>
		<li class="ml-2"><button v-on:click="removeFiltro(index)"><i class="fas fa-times"></i></button></li>
	</ul>
</template>

<script>

import flatPickr from "vue-flatpickr-component";
import lang from "./../../../../../node_modules/flatpickr/dist/l10n/pt";

export default {
	name: "FiltroDate",
	props: {
		filter: Object,
		index: Number
	},
	components: {
		flatPickr		
	},
	directives: {
	},
	computed: {		
		options_calendar() {
			let config;
			if(this.filter.config.type == "date"){
				config = {
					altInputClass: "input_calendar",
					enableTime: false,
					enableSeconds: false,
					altFormat: "d/m/Y",
					altInput: true,
					dateFormat: "Y-m-d",
					time_24hr: true,
					locale: lang.pt,
					allowInput: false,
					placeholder: "00/00/0000"
				};
			}else{
				config = {
					altInputClass: "input_calendar",
					enableTime: true,
					enableSeconds: true,
					altFormat: "d/m/Y H:i:S",
					altInput: true,
					dateFormat: "Y-m-d H:i:S",
					time_24hr: true,
					locale: lang.pt,
					allowInput: false,
					defaultHour: "00",
					placeholder: "00/00/0000"
				};
			}

			return config;
			
		},
	},
	data () {
		return {
			value: "",
			operator: "=",			
			options: [
				{
					value: "=",
					label: "Igual"
				},
				{
					value: ">",
					label: "Maior que"
				},
				{
					value: ">=",
					label: "Maior ou igual a"
				},
				{
					value: "<",
					label: "Menor que"
				},
				{
					value: "<=",
					label: "Menor ou igual a"
				},
				{
					value: "!=",
					label: "Diferente de"
				}
			],
		};
	}, 
	created() {
		this.value = this.filter.search.value;
		this.operator = this.filter.search.operator;
	},
	methods: {		
		updateValue: function(){

			let type;
			let valor = this.value;

			switch(this.filter.config.type){
			case "date":
				type = "date";
				break;
			case "datetime":
				type = "datetime";
				break;
			}
			
			this.filter.search.value = valor;
			this.filter.search.operator = this.operator;
			this.filter.search.type = type;

			this.updateFiltro(this.filter,this.index);
		}
	},
	inject: ["updateFiltro","removeFiltro"],
	mounted(){
		if(this.value == ""){
			document.getElementsByClassName("input_"+this.index)[0].focus();
		}	
		
	},
	watch: {
		value: function(val){
			if(val != undefined && val != this.filter.search.value){
				this.updateValue();
			}			
		}
	}
};
</script>

<style scoped lang="scss">	

@import "./../../../../assets/css/filters.scss";
@import "./../../../../../node_modules/flatpickr/dist/flatpickr.css";
@import "./../../../../../node_modules/flatpickr/dist/themes/material_green.css";
	
</style>
