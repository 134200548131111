<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row" v-if="model.id">
			<div class="col my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações do Carrinho
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Loja:</strong> {{ model.store_id | store }}</li>
							<li><strong>Data do Carrinho:</strong> {{ model.created_at | date_time }}</li>
							<li><strong>Última Atualização:</strong> {{ model.updated_at | date_time }}</li>
							<li v-if="model.coupon && model.coupon.code"><strong>Cupom:</strong> {{ model.coupon.code }} </li>
							<li v-if="model.delivery && model.delivery.postalcode"><strong>CEP Destino:</strong> {{ model.delivery.postalcode | cep }} </li>
							<template v-if="model.delivery && model.delivery.shipments">
								<li><strong>Opções de Frete:</strong> </li>
								<li v-for="(log,i) in model.delivery.shipments" :key="`log_${i}`"><strong>{{ log.name }}:</strong> {{ log.delivery_time }} dias / {{ log.price | money }}</li>
							</template>
							<li><strong>Vendedor Responsável:</strong> 
								<template v-if="!seller_edit">
									{{ model.seller.name || "Não informado" }} <button @click="pegaVendedores()" class="btn btn-sm btn-edit ml-2" v-if="!model.seller.name"><i class="fa fa-edit"></i></button>
								</template>
								<template v-else>
									<select class="form-control my-2" placeholder="Estado" v-model="model.seller.id" v-on:change="alteraVendedor()">
										<option :value="undefined">Selecione</option>
										<option :value="user.id" v-for="(user,i) in sellers" :key="i">{{ user.name }}</option>
									</select>														
								</template>
							</li>
							<li><strong>Status de Atendimento:</strong> 
								<select class="form-control my-2" placeholder="Status" v-model="model.status" v-on:change="alteraStatus()">
									<option :value="undefined">Selecione</option>
									<option :value=0>Novo</option>
									<option :value=1>Em recuperação</option>
									<option :value=2>Descartado</option>
									<option :value=3>Recuperado</option>
								</select>														
							</li>
						</ul>						
					</div>
				</div>
			</div>
			<div class="col my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações do Cliente
						<router-link class="btn btn-sm" :to="{ name: 'clientes.ver', params: { id: model.customer_id } }" ><i class="fas fa-eye"></i></router-link>
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Nome:</strong> {{ customer.name }}</li>
							<li><strong>E-mail:</strong> {{ customer.email }}</li>
							<template v-if="customer.phones">
								<li v-for="(phones,i) in customer.phones" :key=i ><strong>Telefone {{ phones.type | tipos_telefone }}:</strong> <a class="" target="_blank" :href="'https://wa.me/55'+phones.number">{{ phones.number | phone }}</a></li>
							</template>			
							<template v-if="customer.address && customer.address.postalcode">
								<li><strong>CEP:</strong> {{ customer.address.postalcode | cep }}</li>
								<li><strong>Rua:</strong> {{ customer.address.street }}, {{ customer.address.number }}</li>
								<li v-if="customer.address.complement != ''"><strong>Complemento:</strong> {{ customer.address.complement }}</li>
								<li><strong>Bairro:</strong> {{ customer.address.neighborhood }}</li>
								<li><strong>Cidade:</strong> {{ customer.address.city }} / {{ customer.address.sa }}</li>
								<li v-if="customer.address.reference != ''"><strong>Referência:</strong> {{ customer.address.reference }}</li>
								<template v-if="customer.address.phones">
									<li v-for="(phone,i) in customer.address.phones" :key="'ad-'+i">
										<strong>Telefone {{ phone.type | tipos_telefone }}: </strong> 
										<a class="" target="_blank" :href="'https://wa.me/55'+phone.number">{{ phone.number | phone }}</a>
									</li>
								</template>
							</template>
						</ul>
					</div>
				</div>
			</div>
			<div class="col my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações Financeiras
					</div>
					<div class="card-body p-0">
						<ul class="resumo">
							<li><strong>Produtos:</strong> <span class="positivo"> {{ model.total_products | money }}</span></li>
							<li><strong>Frete:</strong> <span class="positivo"> {{ model.shipment_price | money }}</span></li>
							<li><strong>Descontos:</strong> <span class="negativo"> {{ model.total_discounts | money }}</span></li>
							<li class="total"><strong>Total à prazo:</strong> <span>{{ model.total_sale | money }}</span></li>
							<li class="total"><strong>Total à vista:</strong> <span>{{ model.total_cash | money }}</span></li>
						</ul>
					</div>
				</div>
			</div>


			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Produtos do Carrinho
					</div>
					<div class="card-body order-products" v-if="model && model.products.length > 0">
						<table width="100%">
							<col width="60%" />
							<col width="20%" />
							<col width="5%" />
							<col width="15%" />
							<tr class="cabecalho">
								<th>Produto</th>
								<th>SKU</th>
								<th class="center">Qtd</th>
								<th class="center">Subtotal</th>
							</tr>
							<tr v-for="(p,i) in model.products" :key=i>
								<td class="titulo align-middle">
									{{ p.name }}
									<span v-for="(atributo,j) in p.attributes" :key=j>{{ atributo.name }}: {{ atributo.value.name ? atributo.value.name : atributo.value }}</span>
									<span v-if="p.gift" class="product_info_destaque">* Brinde</span>
								</td>
								<td class="align-middle">{{ p.sku }}</td>
								<td class="center align-middle">{{ p.quantity }}</td>
								<td class="center align-middle">
									{{ p.subtotal_sale_price | money }}<br /> ou {{ p.subtotal_cash_price | money }} à vista
								</td>
							</tr>									
						</table>
					</div>
				</div>
			</div>

			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Pedidos do Cliente
					</div>
					<div class="card-body">
						<table class="table table-striped table-bordered m-0">
							<thead>
								<tr class="table-secondary">
									<th scope="col">Pedido</th>
									<th scope="col">Data</th>
									<th scope="col">Valor</th>
									<th scope="col">Status</th>
									<th scope="col"></th>
								</tr>
							</thead>
							<tbody v-if="orders.length > 0">
								<tr v-for="(order,i) in orders" :key=i>
									<td class="center align-middle">{{ order.store_id }}-{{ order.order_number }}</td>
									<td class="center align-middle">{{ order.created_at | date_time }}</td>
									<td class="center align-middle">{{ order.total_order | money }}</td>
									<td class="center align-middle">{{ order.status.name }}</td>
									<td class="center align-middle">
										<router-link class="btn btn-primary btn-sm mx-1" :to="{ name: 'pedidos.ver', params: { id: order.id } }" ><i class="fas fa-eye"></i></router-link>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="5" class="center">Nenhum pedido realizado</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- <div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Observações do Carrinho
					</div>
					<div class="card-body">
						<FormTextarea :disabled="!hasPermission('editar') || !['new','processing','paid','problem','payment-problem','review'].includes(model.status.type)" :required=false label="Informações Extras do Pedido:" v-model="extra_information" :rows=3 placeholder="" type="textarea" @change="updateExtraInformation" />
						<FormTextarea :disabled="!hasPermission('editar') || !['new','processing','paid','problem','payment-problem','review'].includes(model.status.type)" :required=false label="Informações Importantes do Pedido:" v-model="important_information" :rows=3 placeholder="" type="textarea"  @change="updateImportantInformation" />

						<Notes state="carts" :id="model.id" :disabled="!hasPermission('editar')" />
					</div>
				</div>
			</div> -->

		</div>

	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Filters from "./../../../common/filters";
// import FormInput from "./../../common/form/Input";
import alerts from "../../../common/alerts";
// import Notes from "./../../common/Notes";
// import FormTextarea from "./../../common/form/Textarea";
// import states from "./../../../assets/json/states";
// import moment from "moment";


export default {
	name: "PedidosView",
	components: {
		Header,
		Breadcrumb,
		// FormInput,
		// Notes,
		// FormTextarea
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		model() {
			let cart = Object.assign({},this.$store.state.carts.model);						
			if(!cart.status){
				cart["status"] = 0;
			}			
			if(!cart.seller){
				cart["seller"] = {};
			}

			return cart;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		orderedObservacoes: function () {
			return Filters.orderBy(this.model.notes, "created_at","desc");
		},
	},	
	data () {
		return {
			header_info: {
				title: "Visão Geral do Carrinho",
				chamada: "Gerencie os carrinhos abandonados da sua loja",
				links: {
					back: "carts.lista",
				}
			},
			breadcrumb_info: [				
				{
					name: "Carrinhos Abandonados",
					link: "/carts"
				},
				{
					name: "Ver",
					link: ""
				}
			],
			config: {
				modulo_permissao: "carts"
			},
			permissao: [],
			users: [],
			sellers: [],
			seller_edit: false,
			orders: [],
			customer: [],
		};
	},  
	methods: {
		hasPermission(type, manager) {
			if(manager == undefined){
				manager = false;
			}
			if(!manager){
				return this.permissao[type] === 1;
			}else{
				return this.usuario.manager && this.permissao[type] === 1;
			}
		},
		alteraStatus: function(){
			let self = this;			

			let event_confirm = () => {		
				self.$store.dispatch("carts/atualizaStatus",{
					id: self.model.id,
					status: self.model.status
				}).then(response => {
					if(response.return_code == 1){
						alerts.alertTop("success", "Status alterado com sucesso");
					}else{
						alerts.alertTop("error", "Houve um problema ao alterar o vendedor");
					}
					self.$store.commit("carts/update_field", { field: "status", data: self.model.status });
					self.seller_edit = false;
				});
				return true;
			};

			let dados = [];
			dados = {
				title: "Deseja alterar o status?",				
			};
			
			alerts.alertConfirm(dados,event_confirm);
		},
		getUserName(id) {
			const user = this.users.find(user => {
				return user.id == id;
			});
			if(user){
				return user.name;
			}

			return "";
		},
		buscaEndereco: function(){
			let self = this;		
			// buscando na api
			this.$store.dispatch("carts/busca_endereco",{postalcode: self.address.postalcode.replace(/[^0-9]+/g,""), customer_id: self.model.customer_id}).then(response => {
				if (response.body.return_code == 1) {
					let data = response.body.data;
					if(!data.errors){
						if(!data.sa){
							// caso não tenha o uf gravado ainda busca pelo nome da cidade							
							data.sa = self.states.filter(function(cidade){
								return cidade.name == data.state;
							})[0]["sigla"];
						}    
						self.address.contact = data.contact;
						self.address.street = data.street;
						self.address.number = data.number;
						self.address.neighborhood = data.neighborhood;
						self.address.city = data.city;
						self.address.state = data.state;
						self.address.sa = data.sa;
						self.address.complement = data.complement;
						if(data.phones && data.phones.length > 0){
							self.address.phone = data.phones[0].number;
						}else{
							self.address.phone = "";
						}
						
						self.address.reference = data.reference;
						// pega as cidades
						self.pegaCidades(data.sa);
					}
				}
			}); 
		},		
		async pegaVendedores(){
			
			this.sellers = await this.$store.dispatch("usuarios/load_sellers").then(response => {
				return response;
			});

			this.seller_edit = true;
		},
		alteraVendedor() {
			const self = this;
			// pegando os dados do vendedor selecionado			
			const _seller = this.sellers.find(item => item.id === this.model.seller.id);
			if(_seller){
				this.$store.dispatch("carts/atualizaVendedor", {
					id: self.model.id,
					seller: { id: _seller.id, name: _seller.name }
				}).then(response => {
					if(response.return_code == 1){
						alerts.alertTop("success", "Vendedor alterado com sucesso");
					}else{
						alerts.alertTop("error", "Houve um problema ao alterar o vendedor");
					}
					self.$store.commit("carts/update_field", { field: "seller", data: { id: _seller.id, name: _seller.name } });
					self.seller_edit = false;
				});
			}else{
				alerts.alertTop("error", "O vendedor não foi encontrado");
			}
		},
		loadCart() {
			let self = this;
			this.$store.dispatch("carts/load", this.$route.params.id).then(response => {				
				if(response.customer_id){
					self.customer = {};
					self.$store.dispatch("clientes/load", response.customer_id).then(response => {
						if(response.return_code == 1){
							const customer = response.data;
							self.customer = {
								"address": customer.address || {},
								"email": customer.email || {},
								"name": customer.name || {},
								"phones": customer.phones || {},
							};
						}
						
					});

					self.$store.dispatch("pedidos/load_pedidos_by_customer", response.customer_id).then(response => {
						self.orders = response;
					});
				}				
				
			});		
		},
	},
	filters: Filters,
	provide: function () {
		return {
		};
	},
	created() {

		const self = this;
		this.$store.dispatch("carts/reset");
		if(this.permissoes) {
			this.permissao = this.permissoes[this.config.modulo_permissao];
		}
		this.$store.dispatch("usuarios/load_users").then(response => {
			self.users = response;
		});
		this.loadCart();
		// this.$store.dispatch("carts/load_notes", this.$route.params.id);						
	},
	watch: {
		$route(to) {
			if(to.params.id && to.params.id != this.model.id){				
				this.$nextTick().then(() => {
					location.reload();
				});				
			}
		}
	}
};
</script>

<style scoped lang="scss">	
.order-products{
	.titulo {
		.product_info_destaque {
			color: $color_red;
			font-size: 0.8em;
		}
	}
	s {
		font-size: 0.8em;
		color: $color_red;
	}
}
button.btn-edit{
	background: $color_red;
	color: #FFF;
	font-size: 0.8em;	
}
.internal-fraud {
	.card {
		&.card-view {
			.card-body {
				ul {
					li {
						a {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}	
}
	
</style>
