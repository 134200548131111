import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/brindes";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		return api.request("brindes/loaded", "admin/gift/" + id, "get").then(response => {
			return response.body;
		});
	},
	save: function(context, data) {
		api.request("brindes/loaded", "admin/gift", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Brinde cadastrado com sucesso." : "Brinde atualizado com sucesso.");
				router.push({
					name: "brindes.lista"
				});
			}
		});
	},
	update_ordem: function(state, data) {
		return api.request("brindes/no_action", "admin/gifts/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.brindes);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 
		let fields = [];
		config.brindes.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		state.commit("clear_valores");
		api.request("brindes/data_valores_loaded", "admin/gifts", "post", { fields: fields, "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {				
		let data = {};
		data[dados.field] = dados.value;
		return api.request("brindes/no_action", "admin/gifts", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("brindes/acao_massa", "admin/gifts", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{		
		return api.request("brindes/acao_massa", "admin/gifts", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("brindes/no_action", "admin/gift/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("brindes/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("brindes/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("brindes/delete_filter", "admin/filter/"+id, "delete", id);
	},
	load_cupons(){
		return api.request("brindes/no_action", "admin/coupons", "post", {}).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},
	load_produtos_selecionados_data: function(state,data){
		let filter_data = [];
		let id_products = [];
		if(data.field == "products_gifts"){
			id_products = data.itens.map(item => {
				return item.id;
			});
			filter_data = [{
				field: "_id", 
				operator: "in", 
				value: id_products
			}];		
		}else if(data.field == "produtos_selecionados"){
			id_products = data.itens.map(item => {
				return item.sku;
			});
			filter_data = [{
				field: "variations.sku", 
				operator: "in", 
				value: id_products
			}];	
		}

		return api.request("brindes/no_action", "admin/products", "post", { "filters": filter_data, "sort": "title,asc", "limit": 100000, "page": 1 }).then(response => {
			let products = [];
			if(response.body.return_code == 1){				
				response.body.data.items.forEach( dado => {
					// pegando a imagem
					let imagem = "";
					if(dado.variations.length > 0 && dado.galleries.length > 0 && dado.variations[0].gallery_id != ""){
						let gallery = dado.galleries.find(item => {
							return item.id == dado.variations[0].gallery_id;
						});
						if(gallery){					
							gallery.pictures.sort(function (a, b) {
								if (a.order > b.order) {
									return 1;
								}
								if (a.order < b.order) {
									return -1;
								}
								// a must be equal to b
								return 0;
							});					
							if(gallery.pictures[0]){
								imagem = gallery.pictures[0].url+"?size=300";
							}				
						}		
					}

					let product_data = {
						product_id: dado.id,
						product_name: dado.title,								
						image: imagem,
						skus: dado.variations.map(item => {
							return {
								sku: item.sku,
								supplier_code: item.supplier_code,
								id: item.id,
								use: id_products.find(x => { return x == item.sku; }) != undefined
							};
						})
					};

					if(data.field == "products_gifts"){
						let item = data.itens.find(it => {
							return it.id == dado.id;
						});
						product_data["quantity"] = item.quantity;
						product_data["amount"] = item.amount;
					}
					if(data.field == "produtos_selecionados"){
						let item = data.itens.find(it => {
							return dado.variations.find(v => {
								return v.sku == it.sku;
							});
						});
						product_data["min_quantity"] = item.min_quantity;
					}

					products.push(product_data);

					
				});
			}

			state.commit("updateProducts",{ tipo: data.field, item: products });			
		});
	}
};