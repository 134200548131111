<script>

import ApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
	props:{
		info:{
			type: Object,
			required: true,
			default: () => {
				return {};
			}
		},		
	},
	components: {
		ApexCharts
	},
	computed: {
		options() {
			let self = this;
			let options = {				
				chart: {					
					type: "area",
					// stacked: true,
					height: "350",
					toolbar: {
						show: false
					},
					zoom: {
						enabled: true
					}
				},	
				dataLabels: {
					enabled: false,
				},		
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 0
					},
				},
				fill: {
					opacity: 1
				},
				xaxis: {
					type: "string",
					datetimeUTC: false,
					categories: []
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							return self.formataPrice(value);
						}
					},
				},
				tooltip: {
					x: {
						show: true,
						formatter: function(x){
							return "Dia "+x;
						},
					},
					y: {
						formatter: function(x){
							return self.formataPrice(x);
						},
						title: {
							formatter: (seriesName) => seriesName,
						},
					}
				},	
			};

			let labels = [];
			this.info.days.forEach(item => {
				labels.push(item.day);
			});
			
			options.xaxis.categories = labels;

			return options;
		},
		series: function(){
			if(!this.options){
				return {};
			}

			const series = [];
			
			this.info.channels.forEach(item => {
				series.push({
					name: item.name,
					data: item.days.map((d) => { return d.amount; }),
				});
			});

			return series;
		},
	},
	data() {
		return {
			config: {},			
			type: "number"			
		};
	},
	methods: {
		dateToNumber: function(data, format){
			if(data == null){
				return 0;
			}
			let retorno = moment(data).format(format);
			if(retorno.startsWith("0")){
				return eval(retorno.substr(1));						
			}else{
				return eval(retorno);
			}
		},		
		toogleType: function(){
			if(this.type == "number"){
				this.type = "money";
			}else{
				this.type = "number";
			}
			this.updateGrafico();
		},
		formataPrice(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender            
			if(value == 0){
				return "R$ 0,00";
			}

			if(typeof value == "number"){
				value = value.toFixed(2);
			}

			if (value)
				return "R$ "+value.replace(".", ",").replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
			else
				return "";

		},
		formataNumber(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender
			if(value)
				return value.toString().replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
			else
				return 0;
		},
		updateGrafico(){			
			this.$refs.chart.updateOptions(this.options);			
			this.$refs.chart.updateSeries(this.series);
		}
	},
	filters: {
		price: function(value){
			this.formataPrice(value);
			return value;
		},
		number: function(value){
			// formata o numero com os separadores de milhar para ficar visualmente melhor de entender
			return value.toString().replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
		}		
	},
	created() {		
	},
	mounted(){		
	}
};
</script>

<template>
	<div>
		<div class="row" v-if="info.days">
			<div class="col-md-12">
				<Apex-charts ref="chart" width="100%" type="area" :options="options" :series="series"></Apex-charts>
			</div>
		</div>
	</div>
</template>