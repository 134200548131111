import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/produtos";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		return api.request("produtos/loaded", "admin/product/" + id, "get");
	},
	save: function(context, data) {
		let new_product = true;
		if(data.id != null){
			new_product = false;
		}
		api.request("produtos/loaded", "admin/product", (new_product) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (new_product) ? "Produto cadastrado com sucesso." : "Produto atualizado com sucesso.");
				if(new_product){
					router.push({
						name: "produtos.editar",
						params: { id: response.body.data.id }
					});
				}else{
					router.push({
						name: "produtos.lista"
					});
				}				
			}
		});
	},
	update_ordem: function(state, data) {
		return api.request("produtos/no_action", "admin/products/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.produtos);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 
		let fields = [];
		config.produtos.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		// não trazer com status excluir
		data.filtros.push({
			field: "status",
			operator: "!=",
			value: 3
		});

		let sort = data.opcoes.order_field + "," + data.opcoes.order;
		if(data.opcoes.order_field === "published_at"){
			if(data.opcoes.order === "asc"){
				sort += "|created_at,asc";
			}else{
				sort += "|created_at,desc";
			}
		}

		state.commit("clear_valores");
		api.request("produtos/data_valores_loaded", "admin/products", "post", { fields: fields, "filters": data.filtros, "sort": sort, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {				
		let data = {};
		data[dados.field] = dados.value;
		return api.request("produtos/no_action", "admin/products", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("produtos/acao_massa", "admin/products", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{		
		return api.request("produtos/acao_massa", "admin/products", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("produtos/no_action", "admin/product/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("produtos/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("produtos/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("produtos/delete_filter", "admin/filter/"+id, "delete", id);
	},
	load_providers: function(){
		return api.request("produtos/no_action", "admin/providers", "get").then(response => {			
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	save_galeria: function(state, data){
		api.request((data.id == null) ? "produtos/galeria_created" : "produtos/no_action", "admin/products/gallery", (data.id == null) ? "post" : "put", data);
	},
	upload_foto_galeria: function(state, data) {        
		api.upload("produtos/upload_galeria", "admin/products/gallery/picture", "post", data, () => {

		});
	},
	galeria_delete: function(state, data) {
		return api.request("produtos/no_action", "admin/products/gallery/" + data, "delete").then( response => {
			return response.body;
		});
	},
	update_pictures_order: function(state, data) {
		return api.request("produtos/no_action", "admin/products/gallery/pictures/order", "put", data).then( response => {
			if(response.body.return_code == 1 && response.body.data.ok){
				state.commit("pictures_ordem_updated",data);
			}			
		});
	},
	delete_picture: function(state, data) {
		return api.request("produtos/no_action", "admin/products/gallery/picture/" + data.id, "delete").then( response => {
			if(response.body.return_code == 1 && response.body.data.ok){
				state.commit("picture_deleted",data);
			}			
		});
	},
	update_picture: function(state, data) {
		return api.request("produtos/picture_updated", "admin/products/gallery/picture", "put", data).then( () => {
			state.commit("picture_updated",data);
		});
	},
	load_categorias(){
		api.request("produtos/load_categorias", "admin/categories", "get");
	},
	load_tags_data: function() {
		return api.request("produtos/no_action", "tags", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	addTag: function(state,data){
		return api.request("produtos/no_action","tag","post",data.item).then( (response) => {
			if(response.body.return_code == 1){
				let dados = response.body.data;
				state.commit("addTag",{item:dados, tipo: data.tipo});
				return dados;
			}
		});
	},
	load_produtos_aguardando_cadastro: function(){
		return api.request("produtos/no_action", "admin/products/waiting", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
		
	},
	check_sku: function(state,sku){
		return api.request("produtos/no_action", "admin/products/sku/" + sku, "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data.exist;
			}else{
				return true;
			}
		});
	},
	variation_delete: function(state, data) {
		return api.request("produtos/no_action", "admin/products/variation/" + data.id, "delete");
	},
	update_variations_order: function(state, data) {        
		return api.request("produtos/no_action", "admin/products/variations/order", "put", data).then((response) => {
			return response.body;
		});
	},
	filter_products_data: function(state,dados){
		let text = dados.text;
		// verificando se é uma busca por uma única palavra ou mais palavras
		let search = text.split(" ");
		let filters_default = [];
		filters_default.push(
			{
				field: "status", 
				operator: "=", 
				type: "integer", 
				value: 1
			}
		);
		if(dados.ignored_skus && dados.ignored_skus.length > 0){
			filters_default.push(
				{
					field: "variations.sku", 
					operator: "not_in", 
					value: dados.ignored_skus
				}
			);
		}		
		if(dados.combo){
			filters_default.push(
				{
					field: "is_kit", 
					operator: "=", 
					type: "integer", 
					value: false
				}
			);
		}
		if(dados.store_id){
			filters_default.push(
				{
					field: "stores", 
					operator: "in", 
					value: [dados.store_id]
				}
			);
		}		
		if(dados.in_stock){
			filters_default.push(
				{
					field: "variations.stock", 
					operator: ">", 
					value: 0
				}
			);
		}
			
		let filters = {};
		if(search.length > 1){
			// busca somente titulo
			let _and = filters_default;
			// percorre cada palavra
			search.forEach(item => {
				_and.push({
					field: "title", 
					operator: "like", 
					type: "string", 
					value: item
				});
			});
			// monta o filtro
			filters = {
				and: [
					{
						and: _and
					}
				]
			};
		}else{
			// busca titulo e sku
			filters = {
				and: [
					{
						and: filters_default,
						or: [
							{
								field: "title", 
								operator: "like", 
								type: "string", 
								value: search[0]
							},
							{
								field: "variations.sku", 
								operator: "like", 
								type: "string", 
								value: search[0]
							}      
						]
					}
				]
			};
		}
		return api.request("produtos/no_action", "admin/products", "post", { "filters": filters, "sort": "title,asc", "limit": 10, "page": 1 }).then(response => {
			if(response.body.return_code == 1 && response.body.data && response.body.data.items){
				return response.body.data.items;
			}else{
				return [];
			}
		});
	},
	getDadosProduto: function(state, skus) {
		let filters = [			
			{
				field: "variations.sku", 
				operator: "in", 
				value: skus
			}
		];
		return api.request("produtos/no_action", "admin/products", "post", {"fields": ["variations"], "filters": filters, "sort": "id,desc", "limit": 100, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return {};
			}
		});
	},
	geraSkuCombo: function(){
		let filters = [			
			{
				field: "variations.sku", 
				operator: "like", 
				type: "string", 
				value: "CO-"
			}
		];
		
		return api.request("produtos/no_action", "admin/products", "post", { "filters": filters, "sort": "variations.sku,desc", "limit": 1, "page": 1 }).then(response => {
			if(response.body.return_code == 1 && response.body.data && response.body.data.items){
				let sku = response.body.data.items[0].variations[0].sku;
				sku = sku.split("-");
				let sufixo = parseInt(sku[2]);
				sufixo += 1;
				sufixo = sufixo.toString().padStart(4,0);
				sku = sku[0]+"-"+sku[1]+"-"+sufixo;
				return sku;
			}else{
				return "";
			}
		});
	},
	pegaMedidasCombo: function(state,produtos){
		let ids = [];
		produtos.forEach(item => {
			ids.push(item.product_id);
		});

		let filters = [
			{
				field: "variations.product_id", 
				operator: "in", 
				value: ids
			}
		];
		return api.request("produtos/no_action", "admin/products", "post", { "filters": filters, "sort": "id,desc", "limit": 9999, "page": 1 }).then(response => {
			let shipment_weight = 0;
			let shipment_height = 0;
			let shipment_width = 0;
			let shipment_length = 0;
			let extra_time = 0;

			if(response.body.return_code == 1 && response.body.data && response.body.data.items){	
				
				let items = response.body.data.items;
				items.forEach(dado => {
					let item = dado.variations[0];
					shipment_weight += item.shipment_weight; // soma os pesos
					shipment_height += item.shipment_height; // soma as alturas
					if(item.shipment_width > shipment_width){
						shipment_width = item.shipment_width;
					}
					if(item.shipment_length > shipment_length){
						shipment_length = item.shipment_length;
					}
					if(item.extra_time > extra_time){
						extra_time = item.extra_time;
					}
				});

				return {
					shipment_weight: shipment_weight.toFixed(2),
					dimension: {
						height: shipment_height.toFixed(2),
						width: shipment_width,
						length: shipment_length
					},
					extra_time: extra_time
				};
			}else{
				return {
					shipment_weight: shipment_weight.toFixed(2),
					dimension: {
						height: shipment_height.toFixed(2),
						width: shipment_width,
						length: shipment_length
					},
					extra_time: extra_time
				};
			}
		});
		
	},
	getBySku: function(state, skus){
		let fields = [
			"variations"
		];
		let filters = [
			{
				field: "variations.sku", 
				operator: "in", 
				value: skus
			}
		];
		return api.request("produtos/no_action", "admin/products", "post", { fields:fields, "filters": filters, "sort": "id,desc", "limit": 9999, "page": 1 }).then(response => {
			if(response.body.return_code == 1){
				return response.body.data.items;
			}else{
				return null;
			}
			
		});
	},
	set_preview: function(state,data){
		api.request("produtos/load_preview", "admin/product", "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.preview) ? "Preview do produto habilitado com sucesso." : "Preview do produto desabilitado com sucesso.");
			}
		});
	},
	sync_image_marketplace: function( state, gallery_id ){
		const data = {
			"worker_name": "anymarket",
			"job_type": "anymarket",
			"action": "anymarket_images_update",
			"document_id": gallery_id,            
		};
		api.request("produtos/no_action", "admin/queue/task", "post", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", "Imagens sincronizadas com Marketplace");
			}else{
				alerts.alertTop("error", "Houve um problema, tente novamente!");
			}
		});
	}
};