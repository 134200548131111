<template>
	<div class="row"> 
		<div class="col-12">
			<div class="form-group mb-0">
				<input type="text" class="form-control" placeholder="Busque pelo produto" v-model="search" v-on:keyup="pegaProdutos()" v-on:focusin="exibir_produtos=true;" v-on:blur="exibir_produtos=false;" v-on:keyup.enter="seleciona()" v-on:keyup.38="navigateActive('up')" v-on:keyup.40="navigateActive('down')" />
			</div>			
			<div class="dados" v-show="exibir_produtos">
				<ul class="list-group filtros-combo-produtos" >
					<li class="list-group-item" v-for="dado in dadosFiltered()" :key=dado.id v-on:mousedown="seleciona(dado)" :id="dado.id" >
						{{ dado.title }}
					</li>
					<li class="list-group-item" v-show="dadosFiltered().length == 0 && search.length > 3">Nenhum produto encontrado</li>
					<li class="list-group-item" v-show="search.length <= 3">Digite no mínimo 4 letras para pesquisar</li>
				</ul>
			</div>
			<div class="combos mt-3">
				<div class="row combo" v-for="(combo,index) in selecionados" :key=index>
					<div class="col-12 my-2">
						<div class="input-group">
							<input class="form-control" :disabled=true :value="combo.product_name" />
							<div class="input-group-append">
								<button class="btn btn-success" v-on:click="showMoney(combo)"><i class="fas fa-dollar-sign"></i></button>
								<button class="btn btn-danger" v-on:click="removeProduto(combo)"><i class="fas fa-times"></i></button>
							</div>
						</div>						
					</div>
					<div class="col-12 my-2">
						<div class="skus p-2">
							<span class="badge badge-success ml-1 px-2 mx-2" :class="(sku.use) ? 'valid' : 'ignore'" v-for="(sku,idx) in combo.skus" :key=idx v-on:click="inativaSku(sku,combo)">
								{{ sku.sku }} <i class="pl-2 fas fa-check"></i>
							</span>
						</div>
					</div>
					<div class="col-6 my-2">
						<FormInput :required=false rules="string" label="Quantidade" placeholder="" info="Quantidade deste item no combo" v-model="combo.quantity" />
					</div>
					<div class="col-6 my-2">
						<FormInputGroup :required=false rules="string" label="Desconto" placeholder="" type="money" info="% de desconto sob o preço original" position="right" text="%" v-model="combo.discount" />
					</div>
					<div class="col-3" v-if="combo.show_prices">
						<FormInputGroup :required=false :disabled=true rules="string" label="Preço" placeholder="" type="money" info="" position="left" text="R$" v-model="combo.price" />
					</div>
					<div class="col-3" v-if="combo.show_prices">
						<FormInputGroup :required=false :disabled=true rules="string" label="Custo" placeholder="" type="money" info="" position="left" text="R$" v-model="combo.cost" />
					</div>
					<div class="col-3" v-if="combo.show_prices">
						<FormInput :required=false :disabled=true rules="string" label="Quantidade" placeholder="" info="" v-model="combo.stock" />
					</div>
					<div class="col-3" v-if="combo.show_prices">
						<FormInput :required=false :disabled=true rules="string" label="Margem" placeholder="" info="" v-model="combo.margin" />
					</div>					
				</div>
				<div class="row combo justify-content-md-center" v-if="selecionados.length > 0">
					<div class="col-12 mb-4">
						<button class="btn btn-success btn-sm btn-block" v-on:click="showMoneyAll($event)"> <i class="fas fa-mouse mr-2"></i> Clique para Calcular Preço do Combo</button>
					</div>
					<div class="col-3" v-if="comboCalculated.show_prices">
						<FormInputGroup :required=false :disabled=true rules="string" label="Preço" placeholder="" type="money" info="" position="left" text="R$" v-model="comboCalculated.price" />
					</div>
					<div class="col-3" v-if="comboCalculated.show_prices">
						<FormInputGroup :required=false :disabled=true rules="string" label="Custo" placeholder="" type="money" info="" position="left" text="R$" v-model="comboCalculated.cost" />
					</div>
					<div class="col-3" v-if="comboCalculated.show_prices">
						<FormInput :required=false :disabled=true rules="string" label="Quantidade" placeholder="" info="" v-model="comboCalculated.stock" />
					</div>
					<div class="col-3" v-if="comboCalculated.show_prices">
						<FormInput :required=false :disabled=true rules="string" label="Margem" placeholder="" info="" v-model="comboCalculated.margin" />
					</div>
				</div>
				
			</div>						
		</div>
	</div>	
</template>

<script>

import FormInput from "./../../common/form/Input";
import FormInputGroup from "./../../common/form/InputGroup";
import Alerts from "../../../common/alerts";

export default {
	name: "Combos",
	props: {
	},
	components: {
		FormInput,
		FormInputGroup
	},
	computed: {		
		selecionados() {			
			let selecionados = this.$store.state.produtos.model.kit_products;
			selecionados.forEach(item => {
				if(!item.hasOwnProperty("price")){
					item.cost = 0;
					item.margin = 0;
					item.price = 0;
					item.stock = 0;
				}
			});
			return selecionados;
		},
		model_produto() {
			return this.$store.state.produtos.model;
		}
	},	
	data () {
		return {
			exibir_produtos: false,
			search: "",
			last_search: "",
			dados: [],
			searchTime: null,
			comboCalculated: {
				cost: 0,
				price: 0,
				margin: 0,
				stock: 0,
				show_prices: false
			}
		};
	},  
	created() {
				
	},
	methods: {		
		filterJson: function(item){
			let self = this;
			return item.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(self.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1;
		},
		navigateActive: function(direction){

			this.$nextTick(function () {
				let lista = document.getElementsByClassName("filtros-combo-produtos")[0].children;
				let indice_maximo = lista.length-1;
				let indice_lista = 0;
				if(direction == "up"){
					for(let li=indice_maximo;li>=0;li--){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li > 0){
								indice_lista = li-1;	
							}
							break;
						}
					}
				}else{
					for(let li=0;li<=indice_maximo;li++){					
						if(lista[li].classList.contains("active")){
							lista[li].classList.remove("active");
							if(li < indice_maximo){
								indice_lista = li+1;	
							}						
							break;
						}
					}
				}
				
				// aplica a classe
				lista[indice_lista].classList.add("active");				
			});			
		},
		pegaProdutos: function(){
			let self = this;
			clearTimeout(self.searchTime);
			self.searchTime = setTimeout(function(){ 
				if(self.search != self.last_search && self.search.length > 3){				
					self.last_search = self.search;
					self.$store.dispatch("produtos/filter_products_data",{text: self.search, combo: true}).then(response => {
						self.dados = response;
					});
				}else if(self.search.length <= 3){
					self.dados = [];
				}
			}, 650);
		},
		dadosFiltered: function(){			
			let self = this;						
			let dados = Object.assign([],self.dados);
			// tira os já selecionados
			dados = dados.filter(item => {																				
				return self.selecionados.find(dado => { return item.id == dado.product_id; }) == undefined;
			});			
			return dados;
		},
		seleciona: function(dado){
			if(dado == undefined){
				let lista = document.getElementsByClassName("filtros-combo-produtos")[0];									
				if(lista.children.length == 0){
					return;
				}				
				let ativo = lista.getElementsByClassName("active");
				let id;
				if(ativo.length > 0){
					id = ativo[0].getAttribute("id");
				}else{
					id = lista.children[0].getAttribute("id");
				}	
				// se pegar um li informativo ele ignora
				if(id == undefined){
					return;
				}		
				dado = this.dados.find(item => {
					return item.id == id;
				});
			}

			let product_combo_data = {
				product_id: dado.id,
				product_name: dado.title,								
				type: (dado.variations.length > 1) ? "multiple" : "single",
				skus: dado.variations.map(item => {
					return {
						sku: item.sku,
						id: item.id,
						supplier_code: item.supplier_code,
						use: true
					};
				}),
				quantity: 1,
				discount: 0
			};

			this.$store.commit("produtos/selectComboProduct",product_combo_data);
			this.search = "";			

		},
		pegaPrecoPadrao: function(dado){								
			let price = dado.prices.mundo;				
			if(this.model_produto.store_mundo){
				price = dado.prices.mundo;
			}else if(this.model_produto.store_aventura){
				price = dado.prices.aventura;
			}else if(this.model_produto.store_lexo){
				price = dado.prices.lexo;
			}		
			return price.full_price - price.discount_real;
		},
		inativaSku: function(sku,combo){
			let combos = this.selecionados;
			combos.forEach(item => {
				if(item.product_id == combo.product_id){
					item.skus.forEach(dado => {
						if(dado.id == sku.id){
							dado.use = !sku.use;
						}
					});
				}				
			});
			this.$store.commit("produtos/updateComboProducts",combos);
		},
		removeProduto: function(combo){
			let combos = this.selecionados;
			let index = combos.indexOf(combo);
			combos.splice(index, 1);
			this.$store.commit("produtos/updateComboProducts",combos);
		},
		showMoney: function(obj){
			let self = this;			
			let combo = Object.assign({},obj);
			const selecionado = combo.skus.find( item => item.use);
			if (selecionado){
				// pega os dados do produto para pegar os preços
				this.$store.dispatch("produtos/getDadosProduto",[selecionado.sku]).then(response => {
					if(response.length > 0){
						self.populateInfoMoney(response[0], combo);
					}
				});
			}else{
				Alerts.alertTop("error","Não foi encontrada nenhuma variação habilitada para o combo.");
			}
		},
		populateInfoMoney(response,combo){
			let self = this;
			let combos = Object.assign([],this.selecionados);
			if(response.id){																				
				combos.forEach(item => {					
					if(item.skus == combo.skus){
						// pegando a variação com maior estoque
						let maior_estoque = 0;
						let variation = null;						
						response.variations.forEach((dado) => {		
							if(item.skus.find(sku => { return sku.use && sku.sku == dado.sku; }) && (dado.stock > maior_estoque || variation == null) ){
								maior_estoque = dado.stock;
								variation = dado;
							}
						});

						if(variation != null){
							item.price = self.pegaPrecoPadrao(variation);
							item.stock = variation.stock;
							item.cost = variation.cost;
							item.margin = (self.pegaPrecoPadrao(variation) / variation.cost).toFixed(2);
							item.show_prices = true;
						}else{
							Alerts.alertTop("error","Não foi encontrada nenhuma variação habilitada para o combo.");
						}

						
					}				
				});
				self.$store.commit("produtos/updateComboProducts",combos);			
			}
		},
		showMoneyAll: function(ev){
			let self = this;
			let el = ev.currentTarget;
			let divs = el.closest("div.combo").getElementsByClassName("show_prices");
			for (const item of divs) {
				item.classList.remove("hide");
			}

			const skus = [];
			this.selecionados.forEach(item => {
				const selecionado = item.skus.find( dado => dado.use);
				if (selecionado){
					skus.push(selecionado.sku);
				}else{
					Alerts.alertTop("error","Não foi encontrada nenhuma variação habilitada para o combo.");
				}
			});

			self.$store.dispatch("produtos/getDadosProduto",skus).then(response => {
				if(response.length > 0){
					response.forEach(item => {
						const _combo = self.selecionados.find(dado => { return dado.skus.find(d => { return d.sku == item.variations[0].sku; } );});
						self.populateInfoMoney(item, _combo);	
					});
					let combos = self.selecionados;
					let comum = {
						stock: null,
						cost: 0,
						price: 0,
						margin: 0,
					};
					
					combos.forEach(item => {	
						// tratando o estoque
						let stock = 0;										
						if(typeof item.quantity == "string"){
							item.quantity = eval(item.quantity);
						}
						if(item.stock > item.quantity){
							stock = Math.floor(item.stock / item.quantity);
						}
						if(comum.stock == null){
							comum.stock = stock;
						}else if(stock < comum.stock){
							comum.stock = stock;
						}
						// tratando o custo
						comum.cost += item.cost * item.quantity;
						// tratando o price
						comum.price += (item.price - (item.price * (item.discount / 100))) * item.quantity;
					});

					self.comboCalculated.cost = comum.cost;
					self.comboCalculated.price = comum.price;
					self.comboCalculated.margin = (comum.price / comum.cost).toFixed(2);
					self.comboCalculated.stock = comum.stock;
					self.comboCalculated.show_prices = true;
				}
			});
		}
	},
	mounted() {
		
	}
};
</script>

<style scoped lang="scss">	
	.row{
		.dados{
			position: relative;
			.list-group{
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				z-index: 10;
				li{
					font-size: 0.95em;
					&:hover, &.active, &.selected{
						background: $bg_destaque;
						color: #FFF;
						cursor: pointer;
						border:none;
					}
				}
			}			
		}		
		.combos{
			.skus{
				background: $bg_gray;
				span{
					cursor: pointer;
					&:hover{
						background: $bg_gray_medium!important;
					}
					&.ignore{
						background: $bg_gray_medium!important;
						&:hover{
							background: $color_green!important;
						}
					}
				}
			}
			.combo {
				border-top: 1px solid #D5D5D5;
				padding-top: 10px;
			}
		}	
	}
	
</style>
