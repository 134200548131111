<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao :is_order="false" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />

		<div class="row" v-if="raffle">
			<div class="col-3 my-3" v-for="i in raffle.quantity" :key=i>
				<div class="card card-view card-base">
					<div class="card-header text-center">
						Prêmio {{ i }} <a href="javascript:void(0)" title="Sortear novamente" class="btn" v-on:click="sortear(i)" v-if="getRaffle(i)"><i class="fas fa-sync-alt"></i></a>
					</div>
					<div class="card-body">
						<div class="sorteio" v-if="getRaffle(i)">
							<span class="d-block text-center">Este prêmio vai para:</span>
							<label class="my-3">{{ getRaffle(i).raffle_number }}</label>
							<p class="py-1"><strong>Nome:</strong> {{ getRaffle(i).customer.name }}</p>
							<p class="py-1"><strong>Pedido:</strong> {{ getRaffle(i).order_number }}</p>
							<p class="py-1"><strong>Data:</strong> {{ getRaffle(i).order_created_at | date }}</p>
							<p class="py-1" v-if="getRaffle(i).protocols.length > 0"><strong>Protocolos:</strong>
								<template v-for="(protocol,index) in getRaffle(i).protocols">
									<router-link :key=index class="my-2 d-block" :to="{ name: 'tickets.ver', params: { id: protocol.id } }" >{{ protocol.protocol_number }}</router-link>
								</template>
							</p>
						</div>
						<div v-else>
							<button class="btn btn-success btn-block my-3" v-on:click="sortear(i)">SORTEAR</button>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Filters from "./../../../common/filters";
import alerts from "./../../../common/alerts";
import { deepCopy } from "../../../common/utils";


export default {
	name: "RaffleView",
	components: {
		Header,
		Breadcrumb,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		raffles() {
			return this.$store.state.raffle.model.raffle || [];
		},
		raffle() {
			return this.$store.state.raffle.model || null;
		}
	},
	data () {
		return {
			header_info: {
				title: "Visão Geral do Sorteio",
				chamada: "Gerencie os sorteios da sua loja",
				links: {
					back: "raffle.lista",
					clear: "."
				}
			},
			breadcrumb_info: [
				{
					name: "Sorteios",
					link: "/raffle"
				},
				{
					name: "Ver",
					link: ""
				}
			],
			permissao: [],
		};
	},
	methods: {
		getRaffle(i) {
			return this.raffles.find( item => item.position == i);
		},
		sortear(i) {
			const self = this;
			const position = i;
			const store_id = this.raffle.store_id;
			this.$store.dispatch("raffle/sortear", { orders: this.orders(), store_id: store_id }).then(response => {
				if(response[0]){
					const sorteado = {
						position: position,
						order_number: response[0].order_number,
						raffle_number: response[0].raffle_number,
						customer: response[0].customer,
						order_created_at: response[0].order_created_at,
						protocols: response[0].protocols
					};

					const raffle = deepCopy(this.raffle);
					const raffeIndex = raffle.raffle.findIndex(item => item.position == position);
					if(raffeIndex >= 0){
						raffle.raffle[raffeIndex] = sorteado;
					}else{
						raffle.raffle.push(sorteado);
					}

					self.$store.dispatch("raffle/aplicaSorteio", raffle);

				}else{
					alerts.alertTop("error", "Nenhum bilhete foi encontrado para o sorteio do prêmio " + position);
				}
			});
		},
		orders() {
			return this.raffle.raffle.map( item => {
				return item.order_number;
			}) || [];
		},
		clearRaffle() {
			let self= this;
			let event_confirm = () => {
				const raffle = deepCopy(self.raffle);
				raffle.raffle = [];
				self.$store.dispatch("raffle/aplicaSorteio", raffle);
				return true;
			};
			alerts.alertConfirm("Tem certeza que deseja resetar o sorteio?",event_confirm);

		}
	},
	filters: Filters,
	provide: function () {
		return {
			clear: this.clearRaffle
		};
	},
	created() {
		this.$store.dispatch("raffle/reset");
		if(this.permissoes.permissoes) {
			this.permissao = this.permissoes.permissoes[this.config.modulo_permissao];
		}
		this.$store.dispatch("raffle/load", this.$route.params.id);
	},
	watch: {

	}
};
</script>

<style scoped lang="scss">
	.sorteio {
		label {
			display: block;
			text-align: center;
			font-size: 1.4em;
			font-weight: bold;
			background: $color_red;
			color: #fff;
			padding: 0.5em 0;
			border-radius: 5px;
		}
	}
</style>
