import moment from "moment";
import Filters from "./../../common/filters";
import {deepCopy} from "./../../common/utils";

export default {
	reset(state, payload) {
		for (let p in state) {
			state[p] = payload[p];
		}
	},
	loaded(state, payload) {
		if (payload.return_code == 1) {			
			// pega o model como veio do banco
			let model = state.model;
			// percorre o que veio do banco para ver se tem campo a mais
			//  e preencher corretamente os valores dos que existem 
			for(let chave in payload.data){
				model[chave] = payload.data[chave];
			}            
			state.model = model;
		}
	},
	update(state, payload) {
		if (payload.return_code == 1) {
			state.model = payload.data;
		}
	},	
	// //lista
	data_valores_loaded(state, payload) {
		state.resume_data = null;
		if (payload.return_code == 1) {
			state.valores = payload.data;
		}
	},
	data_resume_loaded(state, payload) {		
		// monta o resume
		const resume = {
			all: {},
			channels: [],
			days: []
		};
		if(payload.return_code == 1) {
			var initial = moment(state.initial_date);
			var final = moment(state.final_date); 
			var days_diff = final.diff(initial, "days");

			const allDays = [
				{
					day: initial.format("DD/MM"),
					amount: 0
				}				
			];
			let i = 0;			
			while(i < days_diff){
				allDays.push({
					day: initial.add(1, "d").format("DD/MM"),
					amount: 0
				});
				i++;
			}

			resume.days = deepCopy(allDays);

			// montando o geral e pegando todos os canais
			payload.data.items.forEach( item => {								
				const channel = item.marketplace.marketplace_channel.toLowerCase();
				// pegando a soma total
				if(!resume.all[channel]){
					resume.all[channel] = 0;
				}
				resume.all[channel] += item.total_order;
			});

			// pegando por dia
			payload.data.items.forEach( item => {	
				const channel = item.marketplace.marketplace_channel.toLowerCase();
				let channel_index = resume.channels.findIndex( (ch) => {
					return ch.slug === channel;
				});				

				if(channel_index === -1){	
					resume.channels.push(
						{
							days: deepCopy(allDays),
							name: Filters.marketplaces(channel),
							slug: channel
						}
					);
					channel_index = resume.channels.length - 1;
				}
				
				const day = moment(item.created_at).format("DD/MM");
				const day_index = resume.channels[channel_index].days.findIndex( (iday) => {
					return iday.day === day;
				});
				if(day_index !== -1){
					resume.channels[channel_index].days[day_index].amount += item.total_order;
				}
			});

		}
		state.resume_data = resume;
	},
	data_filtros_loaded(state, payload) {
		state.table_fields = payload;
	},
	altera_pagina(state,pagina){
		let search_data = state.search_data;
		search_data.opcoes.page = pagina;
		state.search_data = search_data;
	},
	altera_limit(state,limit){
		let search_data = state.search_data;
		search_data.opcoes.page = 1;
		search_data.opcoes.limit = limit;
		state.search_data = search_data;
	},
	select_listagem(state,id){
		let select = state.selecionados;
		if(select.find((item) => {
			return item == id;
		})){
			// remover dos selecionados
			select.splice(select.indexOf(id), 1);
		}else{
			// incluir dos selecionados
			select.push(id);
		}
		state.selecionados = select;
	},
	select_listagem_all(state,ids){
		state.selecionados = ids;
	},
	sort_listagem(state,field){
		let search_data = state.search_data;
		search_data.opcoes.page = 1;
		if(search_data.opcoes.order_field == field){
			// executa o toogle na direção
			if(search_data.opcoes.order == "asc"){
				search_data.opcoes.order = "desc";
			}else{
				search_data.opcoes.order = "asc";
			}
		}else{
			// aplica o asc
			search_data.opcoes.order = "asc";
		}
		search_data.opcoes.order_field = field;
		state.search_data = search_data;
	},
	clear_valores(state){
		state.valores = {};
		state.selecionados = [];
		state.checkAll = false;
	},
	acao_massa(){
		// state.selecionados = [];
	},
	no_action(){

	},
	filters(state,filtros){		
		state.filters = [];
		// percorre os filtros que já estão com valores para enviar a pesquisa
		let search_filters = [];
		filtros.forEach( item => {			
			if(item.search.value !== ""){
				search_filters.push(item.search);
			}
		});
		// armazena o search
		state.search_data.filtros = search_filters;
		// armazena o filtro		
		state.filters = filtros;
		if(filtros.length == 0){
			// limpa os outros dados
			state.search_data.filtro_selecionado = 0;
		}
	},
	get_filtros_salvos(state,payload){
		if(payload.return_code == 1){
			state.saved_filters = payload.data;
		}		
	},
	save_filter(state,payload){
		if(payload.return_code == 1){
			// atualizando os filtros
			let index = state.saved_filters.findIndex(item => {
				return item.id == payload.data.id;
			});
			if(index >= 0){
				state.saved_filters[index] = payload.data;				
			}else{
				state.saved_filters.push(payload.data);
			}
			state.search_data.filtro_selecionado = payload.data.id;
		}
	},
	delete_filter(state,payload){
		if(payload.return_code == 1){
			let id = state.search_data.filtro_selecionado;
			let filtros = state.saved_filters;
			let index = filtros.findIndex(item => {
				return item.id == id;
			});
			filtros.splice(index, 1);
			state.saved_filters = filtros;
			state.search_data.filtro_selecionado = 0;
		}
	},
	select_filter(state,payload){
		state.search_data.filtro_selecionado = payload;
	},	
	data_status_loaded(state,payload){        
		if(payload.return_code == 1){
			let items = payload.data.items;
			items.sort(function(a, b) {
				if (a.order > b.order) {
					return 1;
				}
				if (a.order < b.order) {
					return -1;
				}
				return 0;
			});
			state.status = items;
		}
	},
	data_status_order_updated(){
        
	},
	data_motivos_loaded(state,payload){
		if(payload.return_code == 1){
			state.motivos_cancelamento = payload.data;
		}
	},
	data_vendedores_loaded(){
		
	},
	status_load(){
        
	},
	multiselect_push: function(state,data){
		if(data.multiple){
			state.model[data.tipo].push(data.item);
		}else{
			state.model[data.tipo] = data.item;
		}		
	},
	multiselect_update: function(state,data){
		state.model[data.tipo] = data.item;
	},
};