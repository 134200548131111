import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store.js";

Vue.config.productionTip = false;
Vue.config.devtools = false;

const app = new Vue({
	render: h => h(App),
	router,
	store
});

app.$mount("#app");
