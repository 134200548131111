<template>
	<div class="form-check mt-1">
		<input class="form-check-input" type="checkbox" :value=index :id="'checkbox_'+index" v-on:change="checkIt($event)" :checked=checked :disabled=disabled>
		<label class="form-check-label" :for="'checkbox_'+index">
			{{ label }}
		</label>
	</div>
</template>

<script>

export default {
	name: "Checkbox",
	props: {
		label: {
			type: String,
			default: ""
		},
		index: {
			type: String,
			default: ""
		},
		checked: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {

		};
	},  
	created() {

	},
	inject: {
		checkIt: {
			// passa um default como uma função vazia pois o Header é usado em outros lugares que não usa a função save()
			default: () => {}
		},
	},
	methods: {		
		
	}
};
</script>

<style scoped lang="scss">	
	@import '~@/assets/css/form.scss';
	
	.form-check-label{
		padding: 0.25em 0;
	}
</style>
