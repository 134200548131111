import api from "./../../common/api";
import model from "./../models/reports-produtos-fornecedor";
import config from "./../../assets/json/filters";
import { deepCopy } from "../../common/utils";
import alerts from "./../../common/alerts";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.reports_produtos_fornecedor);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state) => { 
		state.commit("clear_valores");
		return state.dispatch("lista_filter_update", state.state.search_data);
	},
	lista_filter_update: function(context, data) {       		
		let elem_match = [];
		let new_filters = deepCopy(data.filtros).filter( filtros => {
			switch(filtros.field){
			case "match_updated_at":
				elem_match.push({
					field: filtros.field.replace("match_",""),
					operator: filtros.operator,
					value: filtros.value,
					type: "datetime"
				});
				return false;
			case "match_status_type":
				elem_match.push({
					field: filtros.field.replace("match_",""),
					operator: filtros.operator,
					value: filtros.value
				});
				return false;
			default:
				return true;
			}
		});

		if(elem_match.length > 0){
			new_filters.push({
				field: "status_log",
				operator: "elem_match",
				value: elem_match
			});
		}		
                
		let fields = [];
		config.reports_produtos_fornecedor.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		if(new_filters.length === 0){
			alerts.alertTop("error","Informe ao menos um filtro para pesquisar");
			const retorno_default = {
				return_code: 1,
				data: {
					total: 0,
					page: 1,
					limit: 10,
					items: []
				}		
			};
			context.commit("data_valores_loaded", retorno_default);
			return;
		}

		new_filters.push({
			field: "product.is_kit",
			operator: "=",
			type: "integer", 
			value: false
		});
		new_filters.push({
			field: "order.created_at",
			operator: ">=",
			value: `${context.state.d_filters.data_de} 00:00:00`
		});
		new_filters.push({
			field: "order.created_at",
			operator: "<=",
			value: `${context.state.d_filters.data_ate} 23:59:59`
		});

		data.opcoes.limit = 999999;
		
		api.request("reports_produtos_fornecedor/data_valores_loaded", "admin/reports/skus", "post", { "filters": new_filters, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
        
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);		
	},
	get_filtros_salvos: (state,config) => {
		return api.request("reports_produtos_fornecedor/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("reports_produtos_fornecedor/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("reports_produtos_fornecedor/delete_filter", "admin/filter/"+id, "delete", id);
	},	
};