<template>
	<ul class="menu-lateral">
		<li
			v-for="(item,index) in itens_tab" 
			v-bind:class="{ active: index == '0' , disabled: disabled_items.includes(item) } " 
			v-show="!hidden_items.includes(item)"
			:data-tabindex="index"
			:key="index"
		>
			<a href="" v-on:click.prevent="tabs(index)">{{ item }} <i class="fas fa-caret-right float-right"></i></a>
		</li>
	</ul>
</template>

<script>

export default {
	name: "TabsEsquerda",
	props: {
		itens: null,
		disabled_items: {
			type: Array,
			default() {
				return [];
			}
		},
		hidden_items: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data () {
		return {
			itens_tab: this.itens
		};
	},  
	computed : {
		
	},
	created() {

	},
	methods: {		
		tabs: function(index){
			const [sideMenu] = document.getElementsByClassName("menu-lateral");
			const [{ children: cards }] = document.getElementsByClassName("formulario-dados");

			for (const [itemIndex, item] of Object.entries(sideMenu.children)) {
				if (index == itemIndex) {
					// marca como ativo
					item.classList.add("active");	
					cards[itemIndex].classList.remove("hide");
				} else{
					item.classList.remove("active");
					cards[itemIndex].classList.add("hide");		
				}
			}
		}
	},
	mounted() {		
		// exibe a primeira tab
		let cards = document.getElementsByClassName("formulario-dados");
		cards[0].children[0].classList.remove("hide");
	}
};
</script>

<style scoped lang="scss">	
	.menu-lateral{
        li {
            background: #FFF;
            border: 1px solid #d5d5d5;
            border-left: 10px solid #d5d5d5;
            list-style: none;
            margin-bottom: 5px;
            position: relative;
            opacity: 0.6;			
            a {
                color: #666666;
                font-weight: bold;
                padding: 0.8em;
                display: block;
                i{
                    display: none;
                    font-size: 1.4em;
                    line-height: 0.7em;
                }
                &:active,
                &:hover {
                    text-decoration: none;
                    i{
                        display: inline-block;
                    }                    
                }                
            }
            &.disabled {
                position: relative;
                cursor: not-allowed;
                a {
                    cursor: default;
                    opacity: 0.6;
                }
                &:before {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    content: "";
                    opacity: 0;
                }
            }
            &.active {
                border-left: 10px solid $bg_escuro;
                border-color: $bg_escuro;
                background: $bg_escuro;
                opacity: 1;
                border-right: none;
                a {
                    color: #FFF;
                    i {
                        display: block;
                    }
                }
            }
            &:hover {
                border-left: 10px solid;
                border-color: $bg_escuro;
                opacity: 1;
            }
            &.tab-error-validate {
                border: 1px solid $color_red;
                border-left: 10px solid $color_red;
                a{
                    color: $color_red;                
                }
                &.active {
                    a{
                        color:#FFF;
                    }                    
                    background: $color_red;
                    i {
                        color: #FFF;
                    }
                }
            }
        }
    }
</style>
