<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Brinde
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>			
							<div class="col-6">
								<FormSelect :required=true rules="option_store" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>																
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Tipo" info="" :options="[{id: 'cart',label: 'Carrinho'}, {id: 'coupon', label: 'Cupons'}, {id: 'product', label: 'Produto'}]" v-model="model.type" type="string" @input="updateTipo()" />		
							</div>			
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Data de Início" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="model.initial_date" :index=0 />
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Data Final" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="model.final_date" :index=1 />
							</div>							
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Brindes -->
				<div class="hide card card-base">
					<div class="card-header">
						Brindes
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelectProdutos state="brindes" tipo="products_gifts" :selectVariations=false :needs_store=true :store_id=model.store_id :extra_fields="['quantity','amount']"></MultiSelectProdutos>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Regras Carrinho -->
				<div class="hide card card-base">
					<div class="card-header">
						Regras Carrinho
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<AddOn ref="rules_cart" state="brindes" title="Especificar regras do carrinho" type="rules_cart" />
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Cupons -->
				<div class="hide card card-base">
					<div class="card-header">
						Cupons
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="brindes" tipo="cupons_selecionados" :dados="cupons" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Produtos -->
				<div class="hide card card-base">
					<div class="card-header">
						Produtos
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelectProdutos state="brindes" tipo="produtos_selecionados" :selectVariations=true :needs_store=true :store_id=model.store_id :extra_fields="['min_quantity']"></MultiSelectProdutos>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import MultiSelectProdutos from "./../../common/MultiSelectProdutos";
import MultiSelect from "./../../common/MultiSelect";
import AddOn from "./../../common/AddOn";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		MultiSelect,
		MultiSelectProdutos,
		AddOn
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.brindes.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		rules_cart() {
			return this.$store.state.brindes.rules_cart;
		},
		cupons_selecionados() {
			return this.$store.state.descontos_progressivos.model.cupons_selecionados;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Brindes",
				chamada: "Gerencie os brindes da sua loja",
				links: {
					back: "brindes.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Brindes",
					link: "/brindes"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Brindes",
				"Regras: Carrinho",
				"Regras: Cupons",
				"Regras: Produtos"
			],
			disabled_items: [],
			hidden_items: [
				"Regras: Carrinho",
				"Regras: Cupons",
				"Regras: Produtos"
			],
			cupons: []
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){	
				let model = this.model;	
				
				// tratando os brindes
				model.products = [];
				model.products_gifts.forEach(item => {
					
					model.products.push({
						id: item.product_id,
						name: item.product_name,
						sku: item.skus[0]["sku"], //brinde tem apenas um sku ou pega o principal
						supplier_code: item.skus[0]["supplier_code"], //brinde tem apenas um supplier_code ou pega o principal
						image: item.image,
						quantity: item.quantity,
						amount: item.amount
					});
				});

				model.rules = [];
				switch(model.type){
				case "cart":
					this.rules_cart.forEach(item => {
						if(typeof item == "object"){
							if(item.type.value != "" && item.operator.value != "" && (item.valor.value != "" || item.valor.value === false)){
								// envia o postalcode somente numeros
								if(item.type.value == "postalcode" && typeof item.valor.value == "string") {
									item.valor.value = item.valor.value.replace(/\D/g,"");
								}
								model.rules.push({ type: item.type.value, operator: item.operator.value, value: item.valor.value });							
							}
						}					
					});
					break;
				case "coupon":
					model.rules = {
						type: "document_id",
						operator: "in",
						value: model.cupons_selecionados.map(item => {
							return item.id;
						})
					};
					break;
				case "product":
					model.produtos_selecionados.forEach(item => {
						item.skus.forEach(dado => {
							if(dado.use){
								model.rules.push({
									sku: dado.sku,
									min_quantity: item.min_quantity
								});
							}							
						});						
					});
					break;
				}

				delete model.cupons_selecionados;
				delete model.products_gifts;
				delete model.produtos_selecionados;

				// salva
				this.$store.dispatch("brindes/save", model);	
				
			}
		},
		updateTipo: function(){
			let tipo = this.model.type;
			switch(tipo){
			case "cart":
				this.hidden_items = [
					"Regras: Cupons",
					"Regras: Produtos"
				];
				break;
			case "coupon":
				this.hidden_items = [
					"Regras: Carrinho",
					"Regras: Produtos"
				];
				break;
			case "product":
				this.hidden_items = [
					"Regras: Carrinho",
					"Regras: Cupons",
				];
				break;
			default:
				this.hidden_items = [
					"Regras: Carrinho",
					"Regras: Cupons",
					"Regras: Produtos"
				];
				break;
			}			
		}
	},
	provide: function () {
		return {
			save: this.save,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("brindes/reset");
		let startingPromises = [			
			// pega as marcas
			this.$store.dispatch("brindes/load_cupons").then(response => {
				self.cupons = response;
			}),
		];		
		Promise.all(startingPromises).then(() => {
			if(this.$route.params.id != undefined && this.$route.params.id != "") {
				this.$store.dispatch("brindes/load", this.$route.params.id).then(response => {
					if(response.return_code == 1){						
						
						// executa a função que vai exibir a aba correta de acordo com o tipo
						self.updateTipo();

						let data = response.data;

						self.$store.dispatch("brindes/load_produtos_selecionados_data",{ field: "products_gifts", itens: data.products });

						switch(data.type){
						case "cart":
							// popula com os dados vindo do banco
							self.$store.commit("brindes/addOnUpdateAll",{ tipo: "rules_cart", itens: response.data.rules});
							// faz com que o component atualize o state com os dados já tratados
							self.$refs.rules_cart.updateComponent();
							break;
						case "product":
							self.$store.dispatch("brindes/load_produtos_selecionados_data",{ field: "produtos_selecionados", itens: data.rules });
							break;
						case "coupon":
							// cupons
							if(typeof data.rules == "object" && data.rules.hasOwnProperty("value")){
								self.$store.commit("brindes/update_cupons_selecionados",data.rules.value.map(item => {
									let cupons = self.cupons.find(dado => {
										return item == dado.id;
									});
									if(cupons){
										return cupons;
									}
								}));
							}
							
							break;
						}
						
					}					
				});
			}
		}, () => {
			
		});

	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	
</style>
