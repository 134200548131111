<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<!-- Resumo -->
		<div class="row dashboard">
			<div class="col-12" v-if="resume">
				<div v-for="(item,store) in resume" :key="store" class="card card-view card-base  my-3">
					<div class="card-header ">
						{{ store | storeSlug }} | {{ resume[store].total_orders | milhar}} Pedidos
					</div>
					<div class="card-body">
						<div class="row">							
							<div class="col-md-3 ">
								<section class="panel dash-info-rapida dash-green">			
									<div class="value ">
										<h1>{{ resume[store].total_revenue | money }}</h1>
										<p>Receita Sem Frete</p>
									</div>
								</section>
							</div>
							<div class="col-md-3 ">
								<section class="panel dash-info-rapida dash-blue">			
									<div class="value ">
										<h1>{{ resume[store].total_shipping | money }}</h1>
										<p>Total Frete</p>
									</div>
								</section>
							</div>		
							<div class="col-md-3 ">
								<section class="panel dash-info-rapida dash-red">			
									<div class="value ">
										<h1>{{ resume[store].total_cost | money }}</h1>
										<p>Total Custo</p>
									</div>
								</section>
							</div>
							<div class="col-md-3 ">
								<section class="panel dash-info-rapida dash-purple">			
									<div class="value ">
										<h1>{{ resume[store].total_revenue + resume[store].total_shipping | money }}</h1>
										<p>Receita Total</p>
									</div>
								</section>
							</div>			
						</div>
					</div>
				</div>
			</div>
			
		</div>

		<!-- Tabela -->
		<Tabela :config="config" :modulo_permissao=modulo_permissao />
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Tabela from "./../../common/Tabela";
import Filters from "./../../../common/filters";

export default {
	name: "ReportsPedidos",
	components: {
		Header,
		Breadcrumb,
		Tabela
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		resume() {
			return this.$store.state.reports_pedidos.resume_data || null;
		},		
	},
	filters: Filters,
	data () {
		return {
			header_info: {
				title: "Relatório de Pedidos",
				chamada: "Relatório de vendas da sua loja",
				links: {
					criar: "",
					resume: true
				}
			},
			breadcrumb_info: [
				{
					name: "Relatórios de Pedidos",
					link: ""
				}
			],
			filtros: null,
			valores: null,
			permissao: [],
			config: {
				state: "reports_pedidos",
				tipo: "reports_pedidos",
				modulo_permissao: this.modulo_permissao,
				url_path: "pedidos",
				flags: {
					multi: {
						enable: true,
						delete: false,
						export: true,
						print: false,
						transfer: false,
						activate: false,
						inactivate: false,						
					},
					look: true,
					edit: false,
					delete: false,
				}
			},
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	mounted() {
	},
	provide: function () {
		return {
			gerarResumo: this.gerarResumo
		};
	},
	methods: {		
		novo() {
			this.$store.dispatch(this.config.state + "/reset");
		},
		gerarResumo() {
			let search_data = Object.assign({}, this.$store.state[this.config.state].search_data);			
			// tratamento para ignorar os pedidos com o campo report_ignored: true nos relatórios de faturamento
			search_data.filtros.push({
				field: "report_ignored",
				operator: "=",
				value: null
			});
			search_data.opcoes.resume = true;
			this.$store.dispatch(this.config.state + "/lista_filter_update", search_data);
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
