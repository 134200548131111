<template>
	<div class="text-center">
		<svg></svg>
	</div>
</template>

<script>

import JsBarcode from "jsbarcode";

// https://github.com/lindell/JsBarcode/wiki/Options

export default {
	props:{
		number:{
			type: String,
			required: true,
			default: null
		},
		text:{
			type: String,
			required: false,
			default: undefined
		},		
	},
	computed: {
		
	},
	data() {
		return {
			config: {},			
			options: {
				format: "CODE128", // CODE39
				width: 1.2,
				height: 50,
				displayValue: true,
				text: this.text,
				fontOptions: "",
				font: "monospace",
				textAlign: "center",
				textPosition: "bottom",
				textMargin: 2,
				fontSize: 20,
				background: "#FFFFFF",
				lineColor: "#000000",
				margin: 10,
				marginTop: undefined,
				marginBottom: undefined,
				marginLeft: undefined,
				marginRight: undefined,
				valid: function(){

				}
			}			
		};
	},
	methods: {
		render: function(){
			
			// $("#barcode").JsBarcode("Hi!");
			JsBarcode(this.$el.childNodes[0], this.number, this.options);

		},
	},
	filters: {
		
	},
	created() {		
		
	},
	watch: {		
		
	},
	mounted(){		
		this.render();
	}
};
</script>