<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="card card-base">
					<div class="card-header">
						Informações Gerais da Promoção
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>			
							<div class="col-6">
								<FormSelect :required=true rules="option_store" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>																											
							<div :class="([1,2].includes(model.type)) ? 'col-4' : 'col-6'">
								<FormSelect :required=true rules="option" label="Tipo de Desconto" info="" :options="[{id: 1,label: 'Desconto Reais'}, {id: 2, label: 'Desconto Porcentagem'}, {id: 3, label: 'Frete Grátis'}]" v-model="model.type" type="number" />		
							</div>
							<div class="col-4" v-if="[1,2].includes(model.type)">
								<FormInputGroup :required=true rules="money" label="Valor do Desconto" placeholder="" info="" :position="(model.type == 1) ? 'left' : 'right'" :text="(model.type == 1) ? 'R$' : '%'" v-model="model.discount" type="money" />
							</div>
							<div :class="([1,2].includes(model.type)) ? 'col-4' : 'col-6'">
								<FormInputGroup :required=true rules="money" label="Valor Mínimo da compra" placeholder="" info="" position="left" text="R$" v-model="model.sale_value" type="money" />
							</div>
							<div class="col-12 mb-3">
								<AddOn state="promocoes_frete" title="Faixas de CEP" type="postal_range" />
							</div>
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data de Início" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.initial_date" :index=0 />
							</div>
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data Final" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.final_date" :index=1 />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status"  type="number" />
							</div>							
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import Alerts from "./../../../common/alerts";
import AddOn from "./../../common/AddOn";

// Inputs
import FormInput from "./../../common/form/Input";
import FormInputGroup from "./../../common/form/InputGroup";
import FormSelect from "./../../common/form/Select";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		AddOn
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.promocoes_frete.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		postal_range() {
			return this.$store.state.promocoes_frete.postal_range;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Promoções Frete",
				chamada: "Gerencie as promocões de frete da sua loja",
				links: {
					back: "promocoes.frete.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Promoções de Frete",
					link: "/promocoes/frete"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
			],
			disabled_items: [],
			hidden_items: [
			],
			permissao: {},
			marcas: []
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				let model = this.model;
				let valid = true;
				// tratando a faixa de cep
				model.postal_range = [];
				this.postal_range.forEach(item => {
					if(typeof item == "object"){
						if(item.cep_de.value != "" && item.cep_ate.value != ""){
							item.cep_de.value = item.cep_de.value.replace("-","");
							item.cep_ate.value = item.cep_ate.value.replace("-","");
							if(item.cep_de.value.length == 8 && item.cep_ate.value.length == 8){
								model.postal_range.push({ initial_code: item.cep_de.value, final_code: item.cep_ate.value });
							}else{
								Alerts.alertTop("error","Preencha corretamente as faixas de CEP");
								valid = false;
							}
						}else{
							if(item.cep_de.value != "" || item.cep_ate.value != ""){
								Alerts.alertTop("error","Preencha corretamente as faixas de CEP");
								valid = false;
							}
						}
					}					
				});

				// salva
				if(valid){
					this.$store.dispatch("promocoes_frete/save", model);
				}
				
			}
		},
	},
	provide: function () {
		return {
			save: this.save,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("promocoes_frete/reset");
		if(self.$route.params.id != undefined && self.$route.params.id != "") {
			self.$store.dispatch("promocoes_frete/load", self.$route.params.id).then(response => {
				if(response.return_code == 1){
					self.$store.commit("promocoes_frete/addOnUpdateAll",{ tipo: "postal_range", itens: response.data.postal_range});
				}					
			});
		}
		
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
</style>
