<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Layout
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=true />
							</div>							
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :disabled="model.id != null" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>																		
							<div class="col-6">
								<FormInput :required=true rules="string" label="Slug" :disabled="model.id != null" placeholder="" info="" v-model="model.slug" />
							</div>							
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Blocos -->
				<div class="hide card card-base">
					<div class="card-header">
						Blocos
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Tipo de Bloco" info="" :options="[{id: 'image',label: 'Banner'}, {id: 'content', label: 'Conteúdo'}, {id: 'html', label: 'HTML'}]" v-model="bloco_type" type="string" />
								<button class="btn btn-success btn-block" v-if="bloco_type != '' && chave_edit == null" v-on:click="saveBloco()"><i class="fas fa-plus mr-1"></i> Incluir Bloco</button>
								<button class="btn btn-success btn-block" v-if="bloco_type != '' && chave_edit != null" v-on:click="saveBloco()"><i class="fas fa-check mr-1"></i> Atualizar Bloco</button>
								<button class="btn btn-danger btn-block" v-if="bloco_type != '' && chave_edit != null" v-on:click="clearBloco()"><i class="fas fa-times mr-1"></i> Cancelar</button>
							</div>
							<div class="col-8">
								<Imagem :required=false rules="string" label="Banner" placeholder="" info="" v-model="image.banner" type="layout" :store_id="model.store_id" v-if="bloco_type == 'image'"></Imagem>								
								<FormInput :required=false rules="string" label="Link" placeholder="" info="Incluir o link completo com https://" v-model="image.link" :slug_function=false v-if="bloco_type == 'image'" />
								<FormTextarea :required=false label="Conteúdo" v-model="txt" :rows=6 placeholder="" info="Variável de Conteúdo: {!--content_template--}" type="textarea" v-if="bloco_type == 'content'" />
								<FormTextarea :required=false label="HTML" v-model="html" :rows=6 placeholder="" info="Variável de Conteúdo: {!--content_template--}" type="textarea" v-if="bloco_type == 'html'" />
							</div>
						</div>
						<div class="row" v-if="model.blocos.length > 0">
							<div class="col-12">
								<table class="table table-striped table-bordered">
									<thead>
										<tr>
											<th scope="col">Blocos Cadastros</th>
											<th scope="col">Ação</th>
										</tr>
									</thead>
									<draggable 
										handle=".order"
										:group="{ name: 'people', pull: 'clone', put: false }"
										ghost-class="ghost"
										:sort="true"
										:tag="'tbody'"
										@end="sortable_update"					
										v-bind="dragOptions"
										>
										<tr v-for="(bloco,index) in model.blocos" :key=bloco.chave>
											<td width="80%" class="py-1 align-middle order">{{ bloco.type | blocoType }}</td>
											<td width="20%" class="py-1 align-middle order">
												<button class="btn btn-sm btn-danger mx-1" v-on:click="removeBloco(bloco,index)"><i class="fas fa-times"></i></button>
												<button class="btn btn-sm btn-warning mx-1" v-on:click="editBloco(bloco)" v-if="bloco.type != 'image'"><i class="fas fa-edit"></i></button>
												<button class="btn btn-sm btn-secondary mx-1"><i class="fas fa-sort"></i></button>
											</td>
										</tr>										
									</draggable>
								</table>
							</div>
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Visualizar -->
				<div class="hide card card-base">
					<div class="card-header">
						Visualizar
					</div>
					<div class="card-body" v-html="model.html">
						<!-- Conteudo -->
						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import draggable from "vuedraggable";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import Imagem from "./../../common/form/Imagem";
import FormTextarea from "./../../common/form/Textarea";
import Alerts from "../../../common/alerts";



export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		Imagem,
		FormTextarea,
		draggable
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.layout.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}		
	},	
	data () {
		return {
			header_info: {
				title: "Layout de E-mails",
				chamada: "Gerencie os layouts dos e-mails da sua loja",
				links: {
					back: "email.layout.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Layout de E-mails",
					link: "/email/layout"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Blocos",
				"Visualizar"
			],
			disabled_items: [
				"Blocos",
				"Visualizar"
			],
			hidden_items: [],
			bloco_type: "",
			image: {
				link: "",
				banner: ""
			},
			txt: "",
			html: "",
			chave_edit: null,
			dragOptions: {
				animation: 200,
				group: "description",
				disabled: false, 
				ghostClass: "ghost"
			}
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				// salva
				this.$store.dispatch("layout/save", this.model);
			}
		},
		toSlug: function(){
			if(this.model.slug == ""){
				this.model.slug = Slug(this.model.name);
			}			
		},
		saveBloco: function(){
			let valid = true;
			let type = this.bloco_type;
			let id = this.chave_edit;
			let bloco = {};
			let chave = 0;
			let blocos = Object.assign([],this.model.blocos);
			blocos.forEach(item => {
				if(item.chave >= chave){
					chave = item.chave + 1;
				}
			});

			switch(type){
			case "image":
				if(this.image.banner != "" && this.image.link != ""){
					bloco = {
						type: type,
						image: `${this.getUrlDomain(this.model.store_id)}${this.image.banner}`,
						link: this.image.link,
						chave: chave
					};
					this.image = { link: "", banner: ""};
				}else{
					if(this.image.banner == ""){
						Alerts.alertTop("error","É necessário enviar um banner para atualizar o bloco.");
						valid = false;
					}else{
						Alerts.alertTop("error","É necessário informar um link para atualizar o bloco.");
						valid = false;
					}
				}
				break;
			case "content":
				if(this.txt != ""){
					bloco = {
						type: type,
						txt: this.txt,
						chave: chave
					};	
					this.txt = "";
				}else{
					Alerts.alertTop("error","Não é possível atualizar o bloco com conteúdo vazio.");
					valid = false;
				}
				
				break;
			case "html":
				if(this.html != ""){
					bloco = {
						type: type,
						html: this.html,
						chave: chave
					};
					this.html = "";
				}else{
					Alerts.alertTop("error","Não é possível atualizar o bloco com conteúdo vazio.");
					valid = false;
				}
				break;
			}

			// inclui
			if(valid && bloco.hasOwnProperty("chave")){
				if(id == null){
					blocos.push(bloco);
				}else{
					let index = blocos.findIndex(item => {
						return item.chave == id;
					});
					bloco.chave = id;
					blocos[index] = bloco;
				}				
				this.$store.commit("layout/updateBlocos",blocos);
				this.clearBloco();
			}
		},
		sortable_update: function(obj){
			let blocos = Object.assign([],this.model.blocos);

			blocos.splice(obj.newIndex, 0, blocos.splice(obj.oldIndex, 1)[0]);

			this.$store.commit("layout/updateBlocos",blocos);		

		},
		removeBloco: function(bloco,index){
			let self = this;
			let blocos = Object.assign([],this.model.blocos);

			if(bloco.type == "image"){
				self.$store.dispatch("layout/remove_imagem",bloco.image).then(function(){
					blocos.splice(index,1);
					self.$store.commit("layout/updateBlocos",blocos);
				});
			}else{
				blocos.splice(index,1);
				self.$store.commit("layout/updateBlocos",blocos);
			}				
		},
		editBloco: function(bloco){
			let type = bloco.type;
			this.bloco_type = type;
			this.chave_edit = bloco.chave;

			switch(type){
			case "content":
				this.txt = bloco.txt;
				break;
			case "html":
				this.html = bloco.html;				
				break;
			}
		},
		clearBloco: function(){
			this.chave_edit = null;
			this.bloco_type = "";
			this.txt = "";
			this.html = "";
			this.image = {
				link: "",
				banner: ""
			};
		},
		updateBloco: function(){

		},
		getUrlDomain(store_id) {
			switch(store_id){
			case 1:
				return "https://imagens.mundodacarabina.com.br";
			case 2:
				return "https://imagens.aventuraecia.com.br";
			case 3:
				return "https://imagens.lexo.com.br";
			default:
				return "https://imagens.mundodacarabina.com.br";
			}
		},
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug
		};
	},
	created() {
		this.$store.dispatch("layout/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("layout/load", this.$route.params.id);
			this.disabled_items = [];
		}
	},
	filters: {
		blocoType(type){
			let retorno = "";
			switch(type){
			case "image":
				retorno = "Conteúdo do tipo Banner";
				break;
			case "content":
				retorno = "Conteúdo do tipo Conteúdo";
				break;
			case "html":
				retorno = "Conteúdo do tipo HTML";
				break;
			}			
			return retorno;
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	.formulario-dados{
		.table{
			margin: 0 auto;
		}
	}
	
</style>
