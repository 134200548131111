import router from "./../../router";
import api from "./../../common/api";
import alerts from "./../../common/alerts";
import model from "./../models/vitrines";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		return api.request("vitrines/loaded", "admin/showcase/" + id, "get").then(response => {
			return response.body;
		});
	},
	save: function(context, data) {
		api.request("vitrines/loaded", "admin/showcase", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Vitrine cadastrada com sucesso." : "Vitrine atualizada com sucesso.");
				router.push({
					name: "vitrines.lista"
				});
			}
		});
	},
	update_ordem: function(state, data) {
		return api.request("vitrines/no_action", "admin/showcases/order", "put", data);
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.vitrines);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 
		let fields = [];
		config.vitrines.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		state.commit("clear_valores");
		api.request("vitrines/data_valores_loaded", "admin/showcases", "post", { fields: fields, "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {				
		let data = {};
		data[dados.field] = dados.value;
		return api.request("vitrines/no_action", "admin/showcases", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("vitrines/acao_massa", "admin/showcases", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{		
		return api.request("vitrines/acao_massa", "admin/showcases", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("vitrines/no_action", "admin/showcase/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("vitrines/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("vitrines/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("vitrines/delete_filter", "admin/filter/"+id, "delete", id);
	},
	load_categorias(){
		return api.request("vitrines/load_categorias", "admin/categories", "get");
	},
	load_marcas(){
		return api.request("vitrines/no_action", "admin/brands", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	load_tags(){
		return api.request("vitrines/no_action", "tags", "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return [];
			}
		});
	},
	load_produtos_selecionados_data: function(state,data){
		let filter_data = [{
			field: "_id", 
			operator: "in", 
			value: data
		}];

		return api.request("vitrines/no_action", "admin/products", "post", { "filters": filter_data, "sort": "title,asc", "limit": 100000, "page": 1 }).then(response => {
			let products = [];
			if(response.body.return_code == 1){				
				response.body.data.items.forEach( dado => {
					// pegando a imagem
					let imagem = "";
					if(dado.variations.length > 0 && dado.galleries.length > 0 && dado.variations[0].gallery_id != ""){
						let gallery = dado.galleries.find(item => {
							return item.id == dado.variations[0].gallery_id;
						});
						if(gallery){					
							gallery.pictures.sort(function (a, b) {
								if (a.order > b.order) {
									return 1;
								}
								if (a.order < b.order) {
									return -1;
								}
								// a must be equal to b
								return 0;
							});					
							if(gallery.pictures[0]){
								imagem = gallery.pictures[0].url+"?size=300";
							}				
						}		
					}

					products.push({
						product_id: dado.id,
						product_name: dado.title,								
						image: imagem,
						skus: dado.variations.map(item => {
							return {
								sku: item.sku,
								id: item.id,
								use: true
							};
						})
					});
				});
			}

			state.commit("updateProducts",{ tipo: "rule_relation", item: products });			
		});
	}
};