<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao :is_order="false" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row" v-if="customer.id">
			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações do Cliente
						<!-- v-if="hasPermission('editar', true)" -->
						<a href="javascript:void(0)" title="Acessar como cliente" class="btn" v-on:click="loginTemporario()">ACESSAR COMO CLIENTE <span class="fa fa-eye"></span></a>
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Status:</strong> {{ (customer.status == 0) ? 'Inativo' : 'Ativo' }} <button v-if="hasPermission('editar', true)" class="btn btn-sm ml-2" :class="[{'btn-success': customer.status === 0}, {'btn-danger': customer.status === 1}]" v-on:click="changeStatusCustomer()"><template v-if="customer.status === 1">Inativar cliente</template><template v-else>Ativar cliente</template></button> </li>
							<li v-if="customer.metadata && customer.metadata.viasoft_id"><strong>Viasoft ID:</strong> {{ customer.metadata.viasoft_id }} </li>
							<li><strong>E-mail:</strong> {{ customer.email }} </li>
							<li><strong>Nome:</strong> {{ customer.name }} </li>
							<li v-if="customer.fancy_name != ''"><strong>Nome Fantasia:</strong> {{ customer.fancy_name }} </li>
							<li><strong>Documento:</strong> {{ customer.document | cnpfcnpj }} </li>
							<li v-if="customer.ie != ''"><strong>Inscrição Estadual:</strong> {{ customer.ie }} </li>
							<li><strong>Data de Nascimento:</strong> {{ customer.birth | date }} </li>
							<li><strong>Data de Cadastro:</strong> {{ customer.created_at | date }} </li>
							<template v-if="customer.phones">
								<li v-for="(phone,i) in customer.phones" :key=i>
									<strong>Telefone {{ phone.type | tipos_telefone }}:</strong> 
									{{ phone.number | phone }} 
								</li>
							</template>
							<li><strong>Lojas:</strong> <template v-for="(store,i) in customer.stores"><template v-if="i>0">, </template>{{ store | store }}</template> </li>
						</ul>
					</div>
				</div>
			</div>
			
			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Endereço de Cobrança
					</div>
					<div class="card-body p-0">
						<ul v-if="customer.address">
							<li><strong>Contato:</strong> {{ customer.address.contact }}</li>
							<li><strong>CEP:</strong> {{ customer.address.postalcode | cep }}</li>
							<li><strong>Rua:</strong> {{ customer.address.street }}, {{ customer.address.number }}</li>
							<li v-if="customer.address.complement != ''"><strong>Complemento:</strong> {{ customer.address.complement }}</li>
							<li><strong>Bairro:</strong> {{ customer.address.neighborhood }}</li>
							<li><strong>Cidade:</strong> {{ customer.address.city }} / {{ customer.address.sa }}</li>
							<li v-if="customer.address.reference != ''"><strong>Referência:</strong> {{ customer.address.reference }}</li>
							<template v-if="customer.address.phones">
								<li v-for="(phone,i) in customer.address.phones" :key=i>
									<strong>Telefone {{ phone.type | tipos_telefone }}:</strong> 
									{{ phone.number | phone }} 
								</li>
							</template>
						</ul>
					</div>
				</div>
			</div>

			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Pedidos do Cliente
					</div>
					<div class="card-body">
						<table class="table table-striped table-bordered m-0">
							<thead>
								<tr class="table-secondary">
									<th scope="col">Pedido</th>
									<th scope="col">Data</th>
									<th scope="col">Valor</th>
									<th scope="col">Status</th>
									<th scope="col"></th>
								</tr>
							</thead>
							<tbody v-if="orders.length > 0">
								<tr v-for="(order,i) in orders" :key=i>
									<td class="center align-middle">{{ order.store_id }}-{{ order.order_number }}</td>
									<td class="center align-middle">{{ order.created_at | date_time }}</td>
									<td class="center align-middle">{{ order.total_order | money }}</td>
									<td class="center align-middle">{{ order.status.name }}</td>
									<td class="center align-middle">
										<router-link class="btn btn-primary btn-sm mx-1" :to="{ name: 'pedidos.ver', params: { id: order.id } }" ><i class="fas fa-eye"></i></router-link>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="5" class="center">Nenhum pedido realizado</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Vouchers do Cliente
					</div>
					<div class="card-body p-0">
						<template v-if="vouchers.length">
							<ul v-for="(voucher,i) in vouchers" :key=i>
								<li><strong>Loja:</strong> {{ voucher.store_id | store }}</li>
								<li><strong>Pedido:</strong> {{ voucher.order_number }}</li>
								<li><strong>Valor:</strong> {{ voucher.value | money }}</li>
								<li><strong>Usado:</strong> {{ (voucher.used) ? 'Sim' : 'Não' }}</li>
								<li><strong>Data:</strong> {{ voucher.created_at | date_time }}</li>
								<li><strong>Motivo:</strong> {{ voucher.reason }}</li>
								<li><strong>Criado por:</strong> {{ voucher.user.name }}</li>
								<li class="text-center" v-if="i<(vouchers.length-1)">--------------------------------</li>
							</ul>
						</template>
						<template v-else>
							<div class="alert alert-info m-3" role="alert">
								Nenhum voucher para este cliente
							</div>
						</template>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Avaliações do Cliente
					</div>
					<div class="card-body p-0">
						<template v-if="reviews.length">
							<ul v-for="(review,i) in reviews" :key=i>
								<li><strong>Loja:</strong> {{ review.store_id | store }}</li>
								<li><strong>Avaliação:</strong> {{ review.rating | reviews }}</li>
								<li><strong>Status:</strong> {{ (review.status == 0) ? 'Aguardando Aprovação' : 'Aprovado' }}</li>
								<li><strong>Data:</strong> {{ review.created_at | date_time }}</li>
								<li><strong>Comentário:</strong> {{ review.comment }}</li>
								<li class="text-center" v-if="i<(reviews.length-1)">--------------------------------</li>																
							</ul>
						</template>
						<template v-else>
							<div class="alert alert-info m-3" role="alert">
								Nenhuma avaliação deste cliente
							</div>
						</template>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Observações do Cliente
					</div>
					<div class="card-body">
						<Notes state="clientes" :id="customer.id" />
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Documentos do Cliente
					</div>
					<div class="card-body p-0">
						<div class="col-12 my-3">
							<FileUpload
								ref="upload"
								v-model="defectFiles"
								class="btn btn-info btn-sm btn-block"
								post-action="/"
								extensions="/\.(gif|jpe?g|png|webp|pdf)$/i"
								accept="image/*,application/pdf"
								:multiple="true"
								:size="1024 * 1024 * 10"
								:disabled="documentSending"
								@input-file="uploadDocument"
							>
								Incluir Documentos
							</FileUpload>
						</div>
						<ul v-if="customer.documents && customer.documents.length > 0" class="documents">
							<li v-for="(file,index) in customer.documents" :key=index class="my-2">
								<button class="btn btn-danger btn-sm float-right" @click="deleteDocument(file)"><i class="fas fa-times"></i></button>
								<a
									v-if="
									!getting_attachment || getting_attachment !== file
									"
									:href="file.url || null"
									target="_blank"
									rel="noopener noreferrer"
									@click.prevent="showAttachment(file)"
								>
									{{ file.name }}</a
								>
								<i class="d-block mt-1">{{ file.created_at | date_time }} </i>
							</li>
						</ul>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Filters from "./../../../common/filters";
import Notes from "./../../common/Notes";
import alerts from "../../../common/alerts";
import api from "./../../../common/api";
import FileUpload from "vue-upload-component/src/FileUpload.vue";
import moment from "moment";


export default {
	name: "ClientesView",
	components: {
		Header,
		Breadcrumb,
		Notes,
		FileUpload
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Visão Geral do Cliente",
				chamada: "Gerencie os clientes da sua loja",
				links: {
					back: "clientes.lista",
				}
			},
			breadcrumb_info: [				
				{
					name: "Clientes",
					link: "/clientes"
				},
				{
					name: "Ver",
					link: ""
				}
			],
			permissao: [],
			pagamento_ativo: "",
			exibePagamento: 0,
			exibeTransacao: 0,
			editAddress: false,
			status_extra: [],
			status_selecionado: {},
			orders: [],
			vouchers: [],
			reviews: [],
			customer: {},
			documentSending: false,
			getting_attachment: null,
			defectFiles: [],		
			config: {
				modulo_permissao: "pedidos"
			},	
		};
	},  
	methods: {	
		hasPermission(type, manager) {
			if(manager == undefined){
				manager = false;
			}
			if(!manager){
				return this.permissao[type] === 1;
			}else{
				return this.usuario.manager && this.permissao[type] === 1;
			}
		},
		changeStatusCustomer() {
			const self = this;
			const new_status = (this.customer.status === 1) ? 0 : 1;
			self.$store.dispatch("clientes/switch_listagem", {field: "status", value: new_status, id: this.customer.id }).then( () => {
				self.customer.status = new_status;
			});
		},
		async uploadDocument(newFile) {
			this.documentSending = true;

			if(
				/\.(gif|jpe?g|png|webp|pdf)$/i.test(newFile.name) ||				
				(newFile.type.substr(0, 6) !== "image/" &&
					/^application\/(wps-office.pdf|pdf)$/i.test(newFile.name))
			){
				const data = new FormData();
				data.append("type", "customer");
				data.append("document", (this.customer.metadata && this.customer.metadata.older_id && this.customer.metadata.older_id != "") ? `arquivos_${this.customer.metadata.older_id}` : this.customer.id);				
				data.append("files", newFile.file);

				const errors = [];				
				let documents = [];
				let documentUpdated = false;
				await api.request("clientes/no_action", "documents", "post", data).then((response) => {					
					if (response.body.return_code == 1) {
						documents = this.customer.documents || [];
						if(response.body.data.urls) {
							response.body.data.urls.forEach(item => {
								documents.push({
									name: newFile.name,
									url: item,
									created_at: moment().format("YYYY-MM-DDTHH:mm:ss")
								});
							});
							documentUpdated = true;
						}
						if(response.body.data.errors){
							response.body.data.errors.forEach(error => {
								errors.push({
									file_name: newFile.name,
									error: `${newFile.name}: ${error}`
								});
							});
						}
					}else{
						errors.push({
							file_name: newFile.name,
							error: "Houve um problema ao enviar esse documento"
						});
					}					
				});

				if(documentUpdated){
					this.$store.dispatch("clientes/save_documents", {
						id: this.customer.id,
						documents: documents
					});
				}

				if(errors.length > 0){
					errors.forEach(item => {
						alerts.alertTop("error", item.error);
					});
				}
					
			}else{
				alerts.alertTop("error", "Este tipo de arquivo não é aceito");
			}
			
			this.documentSending = false;
			return;
		},
		deleteDocument(file){
			let self = this;
			this.$store.dispatch("clientes/delete_documents", file.url).then(response => {				
				if(response.return_code == 1 && response.data.ok){
					let documents = self.customer.documents || [];
					const index = documents.findIndex(item => {
						return file === item;
					});
					documents.splice(index, 1);
					self.$store.dispatch("clientes/save_documents", {
						id: this.customer.id,
						documents: documents
					});
				}else{
					alerts.alertTop("error", "Não foi possível excluir o documento");
				}
			});
		},
		async showAttachment(attachment) {
			if (this.getting_attachment && this.getting_attachment === attachment) {
				return false;
			}
			this.getting_attachment = attachment;
			try {
				const response = await this.$store.dispatch("clientes/getAttachment",attachment);
				if (!response) return;
				const a = document.createElement("a");
				a.href = `data:${response.mimetype};base64,${response.content.$binary.base64}`;
				a.download = attachment.name;
				a.click();
				this.getting_attachment = null;
			} catch (error) {
				this.getting_attachment = null;
			}
			return false;
		},	
		loginTemporario() {
			this.$store.dispatch("clientes/login_temporario", this.customer.id).then(response => {
				if (response.return_code == 1){
					let event_confirm = () => {		
						return true;
					};
					let dados = {
						title: "Acesso temporário na loja como cliente",
						body: [
							{
								type: "p",
								text: "A senha temporária é: "+response.data.temp_password
							},
							{
								type: "p",
								text: "Ela é válida para ser utilizada até: "+Filters.date_time(response.data.temp_expiration)
							}
						]
					};
					// ctrl+c
					let input = document.createElement("input");
					document.getElementsByTagName("body")[0].append(input);
					input.value = response.data.temp_password;
					input.select();
					document.execCommand("copy");
					input.remove();
					// alerta
					alerts.alertConfirm(dados,event_confirm);
				}else{
					alerts.alertTop("error", "Não foi possível gerar a senha temporária.");
				}
			});
		}	
	},
	filters: Filters,
	provide: function () {
		return {
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("clientes/reset");
		if(this.permissoes) {
			this.permissao = this.permissoes[this.config.modulo_permissao];
		}	
		this.$store.dispatch("clientes/load", this.$route.params.id).then(response => {
			if(response.return_code == 1){
				self.customer = response.data;
			}
		});
		this.$store.dispatch("pedidos/load_pedidos_by_customer", this.$route.params.id).then(response => {
			self.orders = response;
		});
		this.$store.dispatch("vouchers/load_vouchers_by_customer", this.$route.params.id).then(response => {
			self.vouchers = response;
		});
		this.$store.dispatch("reviews/load_reviews_by_customer", this.$route.params.id).then(response => {
			self.reviews = response;
		});		
		this.$store.dispatch("clientes/load_notes", this.$route.params.id);		
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	
</style>
