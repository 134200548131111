<template>
	<div id="menuGeral" :class="(hide_menu) ? 'off' : ''" v-on:mouseover="exibeAviso('over')" v-on:mouseleave="exibeAviso('leave')" v-on:click="exibeMenu()">
		<div class="logo text-center">
			<a href="/#/">				
				<img src="./../../assets/img/mdc_branca.png" />
			</a>
		</div>

		<ul>
			<li v-for="(item,k) in menu" :key="k">
				<label>{{ item.label }}</label>
				<ul>
					<li v-for="(sub,i) in item.itens" :key="i">
						<a :href="'/#' + sub.link"  v-if="sub.uri != ''">{{ sub.label }}</a>
						<a href="/#" class="sublink" v-on:click.prevent="submenu($event)" v-else-if="checkFilhos(sub)">{{ sub.label }}</a>						
						<ul v-if="sub.uri == ''">
							<li v-for="(s,k) in sub.itens" :key="k">
								<a :href="'/#' + s.link">{{ s.label }}</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>

		<label class="open-menu-flutuante pr-2 py-2" v-show="exibe_aviso">Clique para abrir o menu</label>
	</div>
</template>

<script>

import menu from "./../../assets/json/menu.json";

export default {
	name: "Menu",
	data () {
		return {
			texto: "",
			menu: [],
			exibe_aviso: false
		};
	},  
	computed: {
		hide_menu() {
			return this.$store.state.app.hide_menu;
		}
	},
	created() {
		this.menu = menu;
	},
	methods: {		
		submenu: function(ev){
			let elem = ev.target;
			if(elem.parentElement.classList.contains("open")){
				elem.parentElement.classList.remove("open");
			}else{
				elem.parentElement.classList.add("open");
			}
			return false;
		},
		checkFilhos: function(sub){
			let validos = 0;
			if(sub.itens){
				for(let i=0;i < sub.itens.length;i++){						
					validos++;
					let subsub = sub.itens[i];
					if(subsub.itens){
						for(let i=0;i < subsub.itens.length;i++){						
							validos++;
						}
					}
				}								
			}

			return validos > 0;
		},
		exibeAviso: function(type){
			if(type == "leave"){
				this.exibe_aviso = false;
			}else{
				if(this.hide_menu){
					this.exibe_aviso = true;
				}
			}			
		},
		exibeMenu: function(){
			if(this.hide_menu){
				this.$store.commit("app/set_hide_menu",false);
				this.exibe_aviso = false;
			}
		}
	}
};
</script>

<style scoped lang="scss">
	// @import "./../assets/css/_variables.scss";

	#menuGeral{
		background: $bg_escuro;
		width: 15em;
		position: absolute;
		left: 0;
		top: 0;
		min-height: 100%;
		height: auto;
		&.off{
			width: 0;
			border-right: 5px solid $bg_escuro; 
			overflow: hidden;
			ul,
			.logo{
				opacity: 0;
			}
		}
		.logo{
			img{
				height: 50px;
				margin-top: 5px;
				margin-bottom: 5px;
			}
		}
		ul{
			padding: 0;
			li{				
				list-style: none;								
				label{
					background: $bg_destaque;
					display: block;
					padding: 0.5em 0.5em;
					color: #FFF;					
					font-weight: bold;
					text-transform: uppercase;
					font-size: 13px;
				}
				a{
					color: #FFF;
					display: block;
				}
				ul{
					padding: 0.25em 0;
					padding-left: 1.2em;
					li{
						padding: 0.5em 0;
						a{
							&.sublink {
								position: relative;
								&:after {
									content: "\f105";
									display: inline-block;
									font-family: "Font Awesome 5 Free";
									font-weight: 900;
									padding-right: 20px;
									position: absolute;
									right: 0;
								}
							}
						}
						ul {
							display: none;
						}
						&.open {							
							ul {
								display: block;								
							}
							a.sublink {
								&:after {
									content: "\f107";
								}
							}							
						}
					}
				}
			}
		}
		.open-menu-flutuante{
			position: fixed;
			bottom: 0;			
			left:5px;
			margin-top: -50px;
			width: 4em;
			text-align: center;
			line-height: 1.3em;			
			background: $bg_escuro;
			border-radius: 0 15px 0 0;
			color: #FFF;
			z-index: 9999;
		}
	}
</style>
