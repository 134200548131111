import Vue from "vue";
import VueResource from "vue-resource";

import store from "../store/store";
import state from "../store/states/app";
import alerts from "../common/alerts";
import ftoken from "../common/token";
import router from "../router";
import env from "../env";
import validation from "./validation";
import {getCookie} from "../common/utils";

Vue.use(VueResource);

export default {
	login: function (data) {
		let domain = window.location.hostname == "0.0.0.0" ? env.local_url : window.location.hostname;
		data.domain = domain; //injeta o domain na request

		let url = env.api_url;

		if (window.location.hostname.indexOf("admin.") == 0)
			url = env.api_url_prod;

		Vue.http.headers.common = {};
		Vue.http.headers.common["Content-Type"] = "application/json";
		Vue.http.headers.common["Accept"] = "application/json";
		//Vue.http.headers.common["Authorization"] = "Basic " + basic_encode

		return Vue.http.post(url + "admin/user/login", data).then(function (response) {
			if (response.body.return_code == 1) {
				// define adata do acesso
				let now = new Date;
				response.body.data.lastAcess = now.getTime();
				response.body.data.tokenUpdated = now.getTime();
				ftoken.setToken(response.body.data.token);
				document.cookie = "refreshinToken=no";
				// guarda os dados
				store.commit("app/login", response.body);
			}
			return response; //para usar em then() aninhado
		});
	},
	async upload(mutation, endpoint, type, data, callback_func, store_id = null) {
		let self = this;

		let url = env.api_url + endpoint;

		if (window.location.hostname.indexOf("admin.") == 0)
			url = env.api_url_prod + endpoint;

		let requestData = new FormData();

		for (let i in data) {
			//se for metatags ou qualquer outro array deve passar por JSON.stringify
			if (i == "metatags")
				data[i] = JSON.stringify(data[i]);

			requestData.append(i, data[i]);
		}

		// validando expiração do token de autenticacao
		let token, tokenUpdated;		
		if(JSON.parse(getCookie("tokenApplication"))){
			const _token_information = JSON.parse(getCookie("tokenApplication"));
			token = _token_information.token;
			tokenUpdated = _token_information.tokenUpdated;
		}		
		const refreshingToken = getCookie("refreshinToken");
		let token_id = token;
		const now = new Date;
		// verificando se precisa gerar o token novamente
		if (tokenUpdated === undefined || (((now.getTime() - tokenUpdated) / 1000) / 60) >= state.timeExpireToken) {
			if (refreshingToken != "yes") {
				// se ainda não foi iniciado o processo de gerar o token ele marca que começou
				document.cookie = "refreshinToken=yes";
				token_id = await ftoken.getToken();	
				ftoken.setToken(token_id);
				document.cookie = "refreshinToken=no";
			} else {
				setTimeout(function () {
					// joga na fila novamente
					self.upload(mutation, endpoint, type, data, callback_func, store_id);
				}, 500);
				return {
					body: {
						return_code: 0, 
						data: {}
					}
				};
			}
		}

		const errorMsg = (data.file && data.file.name) ? `Houve um problema com a imagem [${data.file.name}], tente novamente!` : null;

		// add na fila de request
		let requestInfo = { key: new Date().getTime(), url: endpoint, errorMsg: errorMsg };
		store.dispatch("app/add_request", requestInfo);
		alerts.loader(true);

		Vue.http.headers.common = {};
		Vue.http.headers.common["Content-Type"] = "application/json";
		Vue.http.headers.common["Accept"] = "application/json";
		Vue.http.headers.common["Authorization"] = "Bearer " + token_id;

		// passando o store_id quando necessário
		switch(store_id){
		case 1:
		case 2:
		case 3:
			// manda o identificador da loja
			Vue.http.headers.common["StoreID"] = store_id.toString();
			break;
		default:
			// manda vazio
			Vue.http.headers.common["StoreID"] = "0";
			break;
		}

		if (type == "put") {
			return Vue.http.put(url, requestData).then(function (response) {
				self.handleResponse(requestInfo, response, mutation, callback_func);
				return response;
			}).catch(function (response) {
				self.handleError(requestInfo, response);
			});
		}

		if (type == "post") {
			return Vue.http.post(url, requestData).then(function (response) {
				self.handleResponse(requestInfo, response, mutation, callback_func);
				return response;
			}).catch(function (response) {
				self.handleError(requestInfo, response);
			});
		}
	},
	async request(mutation, endpoint, type, data, store_id = null, use_gateway = false ,callback_func, loader = true, show_error_dialog = true, is_form_data = false) {
		let self = this;
		let url = env.api_url + endpoint;

		if (use_gateway){
			url = env.gateway_api_url + endpoint;
			if (window.location.hostname.indexOf("admin.") == 0)
				url = env.gateway_api_url_prod + endpoint;
		}else{
			if (window.location.hostname.indexOf("admin.") == 0)
				url = env.api_url_prod + endpoint;
		}

		// validando expiração do token de autenticacao
		let token, tokenUpdated;		
		if(JSON.parse(getCookie("tokenApplication"))){
			const _token_information = JSON.parse(getCookie("tokenApplication"));
			token = _token_information.token;
			tokenUpdated = _token_information.tokenUpdated;
		}		
		const refreshingToken = getCookie("refreshinToken");
		let token_id = token;
		if (endpoint !== "admin/user/refresh_token") {
			const now = new Date;
			// verificando se precisa gerar o token novamente
			if (tokenUpdated === undefined || (((now.getTime() - tokenUpdated) / 1000) / 60) >= state.timeExpireToken) {
				if (refreshingToken != "yes") {
					// se ainda não foi iniciado o processo de gerar o token ele marca que começou
					document.cookie = "refreshinToken=yes";
					token_id = await ftoken.getToken();	
					ftoken.setToken(token_id);
					document.cookie = "refreshinToken=no";
				} else {
					setTimeout(function () {
						// joga na fila novamente
						self.request(mutation, endpoint, type, data, store_id, use_gateway, callback_func, loader, show_error_dialog, is_form_data);
					}, 500);
					return {
						body: {
							return_code: 0, 
							data: {}
						}
					};
				}
			}
		}

		// grava a request
		let requestInfo = { key: new Date().getTime(), url: endpoint };
		store.dispatch("app/add_request", requestInfo);
		if (loader) {
			alerts.loader(true);
		}

		Vue.http.headers.common = {};
		if(is_form_data){
			Vue.http.headers.common["Content-Type"] = "multipart/form-data";
		}else{
			Vue.http.headers.common["Content-Type"] = "application/json";
		}		
		Vue.http.headers.common["Accept"] = "application/json";
		Vue.http.headers.common["Authorization"] = "Bearer " + token_id;

		/**
		 * Contém um array de condições que caracterizam um endpoint cuja operação pode ser filtrada
		 * pelo STORE_ID.
		 * @type {(boolean|*)[]} // Array de operações lógicas (booleans)
		 */
		const FILTERABLE_BY_STORE_ID_CONDITIONS = [
			!endpoint.startsWith("admin/"),
			endpoint.startsWith("admin/payments/info"),
			endpoint === "admin/categories"
		];

		// passando o store_id quando necessário
		if (FILTERABLE_BY_STORE_ID_CONDITIONS.some(Boolean)) {
			switch (store_id) {
			case 1:
			case 2:
			case 3:
				// manda o identificador da loja
				Vue.http.headers.common["StoreID"] = store_id.toString();
				break;
			default:
				// manda vazio
				Vue.http.headers.common["StoreID"] = "0";
				break;
			}
		} else {
			Vue.http.headers.common["StoreID"] = "0";
		}

		if (type == "get") {
			return Vue.http.get(url).then(response => {
				//success
				return self.handleResponse(requestInfo, response, mutation, callback_func, show_error_dialog); //para usar em then() aninhado
			}, response => {
				// Trata se for problema de autenticação do usuário
				if (url.indexOf("/usuarios/permissoes/") != -1) {
					// storage.remove("usuario");
					// storage.remove("permissoes");
					// storage.remove("menu");
					router.go("/login");
				}

				return self.handleError(requestInfo, response);
			});
		}

		if (type == "post") {
			return Vue.http.post(url, data).then(function (response) {
				self.handleResponse(requestInfo, response, mutation, callback_func, show_error_dialog);
				return response; //para usar em then() aninhado
			}).catch(function (response) {
				return self.handleError(requestInfo, response);
			});
		}

		if (type == "put") {
			return Vue.http.put(url, data).then(function (response) {
				self.handleResponse(requestInfo, response, mutation, callback_func, show_error_dialog);
				return response; //para usar em then() aninhado
			}).catch(function (response) {
				return self.handleError(requestInfo, response);
			});
		}

		if (type == "delete") {
			return Vue.http.delete(url, { body: data }).then(function (response) {
				self.handleResponse(requestInfo, response, mutation, callback_func, show_error_dialog);
				return response; //para usar em then() aninhado
			}).catch(function (response) {
				return self.handleError(requestInfo, response);
			});
		}
	},
	handleResponse: function (requestInfo, response, mutation, callback_func, show_error_dialog) {
		if (response.body.return_code == 2) {
			store.commit("app/login_refresh", response.body.token);
			router.go({
				path: router.path,
				query: {
					t: +new Date()
				}
			});
		}
		if (response.body.return_code == 1) {

			if (mutation)
				store.commit(mutation, response.body);

			if (callback_func)
				callback_func(response);

			if (response.body.data.warning)
				alerts.alertTop("alert", response.body.data.warning);
		} else {
			//trata os erros
			let errors = [];

			// "data": {
			// 	"errors": {
			// 		"email": "J\u00e1 existe registro com esse email"
			// 	}
			// }

			for (let field in response.body.data.errors) {
				errors[field] = response.body.data.errors[field];
			}

			//Se for o campo error message default da api exibe seu valor, senão repassa a validação de campos
			if (errors.error_message) {
				alerts.alertTop("error", errors.error_message);
			} else if (response.body.data.message) {
				alerts.alertTop("error", response.body.data.message);
			} else {
				// validation.bindFields(errors)

				for (let x in errors) {
					let elem = document.getElementsByName(x);
					if (elem.length > 0) {
						elem = elem[0];
						validation.show([{
							valid: false,
							msg: errors[x],
							elem: elem,
							type: "input"
						}]);
					}
				}

				if (show_error_dialog)
					alerts.alertTop("error", "Há campos que devem ser validados!");
			}
		}

		//remove o loader caso não haja requests na fila
		store.dispatch("app/remove_request", requestInfo);
		if (store.state.app.requests.length == 0) {
			alerts.loader(false);
		}

		return response;
	},
	handleError: function (requestInfo, response) {
		// tratando erro de token		
		if(response.status === 401){
			//chama a tela de login
			router.push({
				name: "auth.login",
				query: { invalid: true, redirect: window.location.href }
			});	
		}

		if(requestInfo.errorMsg){
			alerts.alertTop("error", requestInfo.errorMsg);
		}
		
		//remove o loader caso não haja requests na fila
		store.dispatch("app/remove_request", requestInfo);
		// window.console.log("handleError", response);
		if (store.state.app.requests.length == 0) {
			alerts.loader(false);
		}
	},
};