export default {
	init: function() {
		return {
			model: {
				id: null,
				name: null,
				slug: null,
				type: null,
				rule: null,
				rule_relation: [],
				type_relation: [],
				quantity: 0,
				period: 0,
				products: [],
				store_id: 0,
				banner: "",
				banner_mobile: "",
				link: "",
				config: {
					type: "vertical",
					slide: {
						active: false,
						auto: false
					}
				},
				status: 1,
				// armazenam as marcas, categorias e tags selecionados da vitrine
				marcas_selecionadas: [],
				categorias_selecionadas: [],
				tags_selecionadas: []
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "name",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],			
			// para o componente de categoria
			categorias: []
		};
	}
};
