import { render, staticRenderFns } from "./MarcasLista.vue?vue&type=template&id=13581c83&scoped=true&"
import script from "./MarcasLista.vue?vue&type=script&lang=js&"
export * from "./MarcasLista.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13581c83",
  null
  
)

export default component.exports