<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />

		<div class="row formulario-cadastro">

			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais da Promoção
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option_store" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" @input="getOfferPages()" />
							</div>
							<div :class="[1,2,4].includes(model.discount_type) ? 'col-6' : 'col-12'">
								<FormSelect :required=true rules="option" label="Tipo de Desconto" info="" :options="[{id: 1,label: 'Desconto Reais'}, {id: 2, label: 'Desconto Porcentagem'}, {id: 3, label: 'Margem'}, {id: 4, label: 'Preço Fixo'}]" v-model="model.discount_type" type="number"  @input="alteraTipo()" />
							</div>
							<div :class="[1,2].includes(model.discount_type) ? 'col-6' : 'col-3'" v-if="[1,2,4].includes(model.discount_type)">
								<FormInputGroup :required=true rules="string" :label="(model.discount_type == 4) ? 'Valor do Produto' : 'Valor do Desconto'" placeholder="" info="" :position="([1,3,4].includes(model.discount_type) == 1) ? 'left' : 'right'" :text="([1,3,4].includes(model.discount_type) == 1) ? 'R$' : '%'" v-model="model.value" type="money" />
							</div>
							<div class="col-3" v-if="[4].includes(model.discount_type)">
								<FormInputGroup :required=false rules="string" label="Valor Marketplace" placeholder="" info="Preencha esse campo apenas se quiser um valor diferente para marketplace" position="left" text="R$" v-model="model.value_marketplace" type="money" />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Tipo de Promoção" info="" :options="[{id: 1,label: 'Todos os Produtos'}, {id: 2, label: 'Marcas'}, {id: 3, label: 'Categorias'}, {id: 4, label: 'Produtos'}]" v-model="model.offer_type" type="number" @input="alteraTipo()" />
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Destacar como" info="" :options_none="{id: '', label: 'Não Destacar'}" :options="[{id: 'black-friday', label: 'Black Friday'}, {id: 'natal', label: 'Natal'}, {id: 'limpa-estoque', label: 'Limpa Estoque'}, {id: 'aniversario', label: 'Aniversário'}, {id: 'independencia', label: 'Independência'}, {id: 'consumidor', label: 'Consumidor'} ]" v-model="model.target" type="string" />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Tipo de Veiculação" info="" :options="[{id: 1,label: 'Todos os dias e horários'}, {id: 2, label: 'Especificar dias e horários'}]" v-model="model.placement_type" type="number" @input="alteraTipo()" />
							</div>
							<div class="col-12">
								<div class="form-group"> 
									<label class="mb-1">Página Relacionada<i class="fas fa-asterisk"></i></label>
									<MultiSelect state="promocoes_produtos" tipo="pages_selecionados" :dados="offerpages" :required=false :field_required=false :insert=false :multiple=true :excluir_validation=false></MultiSelect>
								</div>
							</div>
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data de Início" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.initial_date" :index=0 />
							</div>
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data Final" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="datetime" v-model="model.final_date" :index=1 />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status"  type="number" />
							</div>
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Categorias -->
				<div class="hide card card-base">
					<div class="card-header">
						Categorias
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Categorias :permissao=permissao state="promocoes_produtos" :selecionados="categorias_selecionadas" :selecionavel=true :store_enabled=model.store_id />
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Marcas -->
				<div class="hide card card-base">
					<div class="card-header">
						Marcas
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="promocoes_produtos" tipo="marcas_selecionadas" :dados="marcas" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Produtos -->
				<div class="hide card card-base">
					<div class="card-header">
						Produtos
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelectProdutos  state="promocoes_produtos" tipo="produtos_selecionados" :selectVariations=true :needs_store=true :store_id=model.store_id></MultiSelectProdutos>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Dias e Horários -->
				<div class="hide card card-base">
					<div class="card-header">
						Dias e Horários
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<AddOn ref="schedules" state="promocoes_produtos" title="Especificar dias e horários da promoção" type="schedules" />
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Regras de Promoção por Margem -->
				<div class="hide card card-base">
					<div class="card-header">
						Regras de Margem
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<AddOn ref="margin_rules" state="promocoes_produtos" title="Especificar Regras para cada margem" type="margin_rules" />
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import MultiSelect from "./../../common/MultiSelect";
import AddOn from "./../../common/AddOn";
import MultiSelectProdutos from "./../../common/MultiSelectProdutos";
import Categorias from "./../../common/Categorias";

// Inputs
import FormInput from "./../../common/form/Input";
import FormInputGroup from "./../../common/form/InputGroup";
import FormSelect from "./../../common/form/Select";
import Alerts from "../../../common/alerts";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		MultiSelect,
		AddOn,
		MultiSelectProdutos,
		Categorias,
		FormInputGroup
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.promocoes_produtos.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		schedules() {
			return this.$store.state.promocoes_produtos.schedules;
		},
		margin_rules() {
			return this.$store.state.promocoes_produtos.margin_rules;
		},
		categorias_selecionadas() {
			return this.$store.state.promocoes_produtos.model.categorias_selecionadas;
		},
		marcas_selecionadas() {
			return this.$store.state.promocoes_produtos.model.marcas_selecionadas;
		},
		categorias() {
			return this.$store.state.promocoes_produtos.categorias;
		}
	},
	data () {
		return {
			header_info: {
				title: "Promoções Produtos",
				chamada: "Gerencie as promoções de produtos da sua loja",
				links: {
					back: "promocoes.produtos.lista",
					save: "."
				}
			},
			breadcrumb_info: [
				{
					name: "Promoções de Produtos",
					link: "/promocoes/produtos"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Categorias",
				"Marcas",
				"Produtos",
				"Dias e Horários",
				"Regras de Margem"
			],
			disabled_items: [],
			hidden_items: [
				"Categorias",
				"Marcas",
				"Produtos",
				"Dias e Horários",
				"Regras de Margem"
			],
			permissao: {},
			marcas: [],
			offerpages: [],
		};
	},
	methods: {
		save: function(){

			if(this.model.offer_type == 1 && this.model.discount_type != 3){
				Alerts.alertTop("error","Só é permitido criar uma promoção para todos os produtos do tipo margem.");
				return;
			}

			if(validation.validation().valid){
				let valid = true;
				let model = Object.assign({},this.model);

				// tratando os agendamentos
				model.schedules = [];
				this.schedules.forEach(item => {
					if(typeof item == "object"){
						let d = new Date();
						let initial_time = item.initial_time.value.split(":");
						let final_time = item.final_time.value.split(":");
						initial_time = new Date(d.getFullYear(), d.getMonth(), d.getDate(), initial_time[0], initial_time[1], initial_time[2]);
						final_time = new Date(d.getFullYear(), d.getMonth(), d.getDate(), final_time[0], final_time[1], final_time[2]);
						if(initial_time < final_time){
							model.schedules.push({ week_day: item.week_day.value, initial_time: item.initial_time.value, final_time: item.final_time.value });
						}else{
							Alerts.alertTop("error","O horário final tem que ser maior que o horário inicial");
							valid = false;
						}
					}
				});
				// tratando as faixas de cep
				model.margin_rules = [];
				this.margin_rules.forEach(item => {
					if(typeof item == "object"){
						let margin_min = item.min.value;
						let margin_max = item.max.value;
						if(margin_min < margin_max){
							model.margin_rules.push({ min: item.min.value, max: item.max.value, discount: item.discount.value });
						}else{
							Alerts.alertTop("error","A margem inicial não pode ser menor que a margem final.");
							valid = false;
						}
					}
				});
				if(model.discount_type == 3 && model.margin_rules.length == 0){
					Alerts.alertTop("error","É necessário cadastrar as regras de margens para esse tipo de desconto");
					valid = false;
				}

				model.pages = [];
				// pegando as paginas relacionadas
				model.pages_selecionados.forEach(item => {
					model.pages.push(item.slug);
				});

				model.relation = [];

				// tratando os relacionamentos
				switch(model.offer_type){
				case 2:
					// marca
					model.marcas_selecionadas.forEach(item => {
						model.relation.push(item.id);
					});
					break;
				case 3:
					// categoria
					model.categorias_selecionadas.forEach(item => {
						model.relation.push(item.id);
					});
					break;
				case 4:
					// produtos
					model.produtos_selecionados.forEach(produtos => {
						produtos.skus.forEach(item => {
							if(item.use){
								model.relation.push(item.sku);
							}
						});
					});
					break;
				}
				// apaga os campos
				delete model.marcas_selecionadas;
				delete model.categorias_selecionadas;
				delete model.produtos_selecionados;
				delete model.pages_selecionados;

				// salva
				if(valid){
					this.$store.dispatch("promocoes_produtos/save", model);
				}

			}
		},
		alteraTipo: function(){
			let type = this.model.offer_type;
			let placement_type = this.model.placement_type;
			let discount_type = this.model.discount_type;
			switch(type){
			case 2:
				// Marcas
				this.hidden_items = [
					"Categorias",
					"Produtos",
				];
				break;
			case 3:
				// Categorias
				this.hidden_items = [
					"Marcas",
					"Produtos",
				];
				break;
			case 4:
				// Produtos
				this.hidden_items = [
					"Categorias",
					"Marcas",
				];
				break;
			default:
				this.hidden_items = [
					"Categorias",
					"Marcas",
					"Produtos",
				];
				break;
			}

			if(discount_type != 3){
				this.hidden_items.push("Regras de Margem");
			}

			if(placement_type == 1){
				this.hidden_items.push("Dias e Horários");
			}
		},
		getOfferPages() {
			let self = this;
			// pega as offerPages
			this.$store.dispatch("promocoes_produtos/load_pages", this.model.store_id).then(response => { // pega os assuntos				
				self.offerpages = response; 				
			});
		},
	},
	provide: function () {
		return {
			save: this.save,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("promocoes_produtos/reset");
		let startingPromises = [
			// pega as categorias
			this.$store.dispatch("promocoes_produtos/load_categorias"),
			// pega as marcas
			this.$store.dispatch("promocoes_produtos/load_marcas").then(response => {
				self.marcas = response;
			}),
		];

		Promise.all(startingPromises).then(() => {
			if(self.$route.params.id != undefined && self.$route.params.id != "") {
				self.$store.dispatch("promocoes_produtos/load", self.$route.params.id).then(response => {
					if(response.return_code == 1){
						// popula com os dados vindo do banco
						self.$store.commit("promocoes_produtos/addOnUpdateAll",{ tipo: "schedules", itens: response.data.schedules});
						self.$store.commit("promocoes_produtos/addOnUpdateAll",{ tipo: "margin_rules", itens: response.data.margin_rules});
						// faz com que o component atualize o state com os dados já tratados
						this.$refs.schedules.updateComponent();
						this.$refs.margin_rules.updateComponent();

						let data = response.data;

						// executa a função que vai exibir a aba correta de acordo com o tipo
						self.alteraTipo();

						switch(data.offer_type){
						case 2:
							// marcas
							self.$store.commit("promocoes_produtos/update_marcas_selecionadas",data.relation.map(item => {
								let marca = self.marcas.find(dado => {
									return item == dado.id;
								});
								if(marca){
									return marca;
								}
							}));
							break;
						case 3:
							// categoria
							var categorias = [];
							data.relation.forEach(item => {
								categorias.push({id: item});
							});
							self.$store.commit("promocoes_produtos/update_categorias_selecionadas",categorias);
							break;
						case 4:
							// produtos
							self.$store.dispatch("promocoes_produtos/load_produtos_selecionados_data",data.relation);
							break;
						}

						self.getOfferPages();
					}
				});
			}
		}, () => {

		});

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	watch: {
		offerpages(newValue, oldValue) {
			let self = this;
			try {
				if(newValue && newValue.length > 0 && oldValue.length == 0 && self.model.store_id && self.model.pages){
					var pages_selecionados = [];
					const _pages = newValue.filter(item => {																				
						return self.model.pages.includes(item.slug);
					});	
					_pages.forEach(item => {
						pages_selecionados.push(item);
					});
					self.$store.commit("promocoes_produtos/update_pages_selecionadas",pages_selecionados);
				}
					
			} catch {
				self.$store.commit("promocoes_produtos/update_pages_selecionadas",[]);				
			}			
		}
	}
};
</script>

<style scoped lang="scss">
	@import "~@/assets/css/form.scss";
</style>
