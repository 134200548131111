<template>
	<ul>
		<li><strong>{{ filter.config.label }}</strong></li>
		<li class="ml-2" v-if="this.use_operator">
			<select v-model="operator" v-on:change="updateValue">
				<option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
			</select>
		</li>
		<li class="ml-2">
			<select v-model="value" v-on:change="updateValue">
				<option value="">Selecione</option>
				<option v-for="option in filter.config.options" :value="option.value" :key="option.value">{{ option.label }}</option>
			</select>
		</li>		
		<li class="ml-2"><button v-on:click="removeFiltro(index)"><i class="fas fa-times"></i></button></li>
	</ul>
</template>

<script>

export default {
	name: "FiltroSelect",
	props: {
		filter: Object,
		index: Number,
		use_operator: {
			type: Boolean,
			required: false,
			default: false
		}		
	},
	computed: {		
		
	},
	data () {
		return {
			value: "",
			operator: "=",
			options: [
				{
					value: "=",
					label: "Igual"
				},
				{
					value: ">",
					label: "Maior que"
				},
				{
					value: ">=",
					label: "Maior ou igual a"
				},
				{
					value: "<",
					label: "Menor que"
				},
				{
					value: "<=",
					label: "Menor ou igual a"
				},
				{
					value: "!=",
					label: "Diferente de"
				}
			],
		};
	}, 
	created() {
		this.value = this.filter.search.value;
	},
	methods: {		
		updateValue: function(){
			if (this.value == null || this.value === "") {
				return;
			}
			if(this.filter.config.operator && this.filter.config.operator == "in"){
				this.filter.search.value = [this.value];			
			}else{
				this.filter.search.value = this.value;			
			}

			this.filter.search.operator = (this.filter.config.operator) ? this.filter.config.operator : "=";			
			if(this.filter.search.field.includes(".id") && this.value && this.value.length === 24){
				this.filter.search.field = this.filter.search.field.replace(".id","._id");				
			}else{
				this.filter.search.type = typeof this.value == "number" ? "integer" : "string";
				if(typeof this.value == "boolean") delete this.filter.search.type;
			}

			if(this.filter.search.field.includes("._id")){
				delete this.filter.search.type;
			}

			if (this.use_operator) {
				this.filter.search.operator = this.operator;
			}			

			this.updateFiltro(this.filter,this.index);
		}
	},
	inject: ["updateFiltro","removeFiltro"],
	mounted(){
		if(this.filter.search.operator != ""){
			this.operator = this.filter.search.operator;
		}
	},
};
</script>

<style scoped lang="scss">	

@import "./../../../../assets/css/filters.scss";
	
</style>
