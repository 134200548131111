<template>
	<div class="card">
        <div class="card-header">
            {{ title }}
        </div>
        <div class="card-body">
            <div class="row" :class="(idx > 0) ? '' : ''" v-for="(item,idx) in dados" :key="idx">
                <div :class="'col-'+field.cols" v-for="(field, node, ix) in item" :key=ix>                    										
                    <FormInput :required=false rules="string" label="" :placeholder="field.placeholder" :info="field.info" v-model="item[node].value" :type="field.type" @input="updateComponent()" v-if="field.type != 'select'" />
					<FormSelect :required=false rules="option" label="" :info="field.info" :options="field.options" v-model="item[node].value" :type="field.type_return" @input="updateComponent()" v-if="field.type == 'select'" />
                </div>
                <div class="col-2 text-center">
                    <button class="btn btn-danger btn-sm btn-block" v-on:click="removeRegra(idx)"><i class="fas fa-times"></i></button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-success btn-sm btn-block" v-on:click="novaRegra()"><i class="fas fa-plus mr-1"></i> {{ button_name }}</button>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>

import FormInput from "./form/Input";
import FormSelect from "./form/Select";

export default {
	name: "AddOn",
	props: {
		title: {
			type: String,
			required: true            
		},
		type: {
			type: String,
			required: true
		},
		state: {
			type: String,
			required: true
		},
		button_name: {
			type: String,
			required: false,
			default: "Nova Regra"
		}
	},
	components: {
		FormInput,
		FormSelect
	},
	data () {
		return {
			values: [],
			model: {
				ceps: {
					cep_de: {
						placeholder: "00000-000",
						info: "Informe o início da faixa de cep",
						type: "postalcode",
						cols: 5,
						value: ""
					},
					cep_ate: {
						placeholder: "00000-000",
						info: "Informe o fim da faixa de cep",
						type: "postalcode",
						cols: 5,
						value: ""
					}
				},
				discounts: {
					min: {
						placeholder: "",
						info: "Informe a quantidade mínima para o desconto",
						type: "number",
						cols: 5,
						value: 0
					},
					value: {
						placeholder: "",
						info: "Informe o valor do desconto",
						type: "money",
						cols: 5,
						value: 0
					}
				},
				schedules: {
					week_day: {
						placeholder: "",
						info: "Informe o dia da semana para a promoção",
						type: "select",
						type_return: "number",
						cols: 4,
						value: 0,
						options: [
							{
								label: "Segunda-feira",
								id: 1
							},
							{
								label: "Terça-feira",
								id: 2
							},
							{
								label: "Quarta-feira",
								id: 3
							},
							{
								label: "Quinta-feira",
								id: 4
							},
							{
								label: "Sexta-feira",
								id: 5
							},
							{
								label: "Sábado",
								id: 6
							},
							{
								label: "Domingo",
								id: 7
							},
							{
								label: "Todos os Dias",
								id: 8
							}							
						]
					},
					initial_time: {
						placeholder: "",
						info: "Informe o horário de início da promoção",
						type: "time_addOn",
						cols: 3,
						value: "00:00:00"
					},
					final_time: {
						placeholder: "",
						info: "Informe o horário de encerramento da promoção",
						type: "time_addOn",
						cols: 3,
						value: "00:00:00"
					}
				},
				margin_rules: {
					min: {
						placeholder: "",
						info: "Informe a margem mínima",
						type: "money",
						cols: 3,
						value: 0
					},
					max: {
						placeholder: "",
						info: "Informe a margem máxima",
						type: "money",
						cols: 3,
						value: 0
					},
					discount: {
						placeholder: "",
						info: "Informe a % de desconto",
						type: "money",
						cols: 4,
						value: 0
					},
				},
				rules_cart: {
					type: {
						placeholder: "",
						info: "Informe o tipo de regra",
						type: "select",
						type_return: "string",
						cols: 3,
						value: "",
						options: [
							{
								label: "Quantidade de Produtos",
								id: "quantity"
							},
							{
								label: "Valor do Carrinho",
								id: "value"
							},
							{
								label: "CEP",
								id: "postalcode"
							},
							{
								label: "Primeira Compra?",
								id: "first_purchase"
							},							
						]
					},
					operator: {
						placeholder: "",
						info: "Informe o operador da regra",
						type: "select",
						type_return: "string",
						cols: 3,
						value: "",
						options: [
							{
								label: "Igual",
								id: "="
							},
							{
								label: "Maior",
								id: ">"
							},
							{
								label: "Maior ou igual",
								id: ">="
							},
							{
								label: "Menor",
								id: "<"
							},
							{
								label: "Menor ou igual",
								id: "<="
							}
						]
					},
					valor: {
						placeholder: "",
						info: "Informe o valor da regra",
						type: "text",
						type_return: "boolean",
						cols: 4,
						value: "",
						options: [
							{
								label: "Sim",
								id: true
							},
							{
								label: "Não",
								id: false
							}
						]
					},
				},
				phones: {
					type: {
						placeholder: "",
						info: "Informe o tipo de telefone",
						type: "select",
						type_return: "string",
						cols: 4,
						options: [
							{
								label: "Residencial",
								id: "home"
							},
							{
								label: "Celular",
								id: "cellphone"
							},
							{
								label: "Whatsapp",
								id: "whatsapp"
							},
							{
								label: "Trabalho",
								id: "work"
							},							
						],
						value: ""
					},
					number: {
						placeholder: "",
						info: "Informe o número",
						type: "phone",
						cols: 6,
						value: ""
					}					
				}
			}
		};
	},  
	computed : {
		dados() {            
			let tipo = this.type.split(".");			
			let dados;
			if(tipo.length > 1){				
				dados = this.$store.state[this.state][tipo[0]][tipo[1]];
			}else{
				dados = this.$store.state[this.state][this.type];
			}
			let valores = [];
			dados.forEach(item => {
				valores.push(this.addField(item));
			});		
			return valores;
		}
	},
	created() {

	},
	methods: {		
		addField: function(item){
			let self = this;
			let retorno = {};
			switch(self.type){
			case "postal_range":       
				if(typeof item == "object" && item.hasOwnProperty("cep_de")){					
					retorno = Object.assign({},item);
				}else{
					var ceps = JSON.parse(JSON.stringify(self.model.ceps)); // fiz dessa forma pois com o Assign ele está mantendo os getters e setters do objeto					
					if(item != undefined && typeof item == "object"){						
						ceps.cep_de.value = item.initial_code;
						ceps.cep_ate.value = item.final_code;						
					}else{
						ceps.cep_de.value = "";
						ceps.cep_ate.value = "";
					}
					retorno = ceps;
				}
				break;
			case "rules": 
				if(typeof item == "object" && item.hasOwnProperty("discounts")){
					retorno = Object.assign({},item);
				}else{
					var discounts = JSON.parse(JSON.stringify(self.model.discounts)); // fiz dessa forma pois com o Assign ele está mantendo os getters e setters do objeto					
					if(item != undefined && item != ""){	
						if(typeof item.min == "object"){
							discounts.min.value = item.min.value;
							discounts.value.value = item.value.value;
						}else{
							discounts.min.value = item.min;
							discounts.value.value = item.value;
						}
					}else{
						discounts.min.value = 0;
						discounts.value.value = 0;
					}
					retorno = discounts;
				}
				break;
			case "schedules":
				if(typeof item == "object" && item.hasOwnProperty("schedules")){
					retorno = Object.assign({},item);
				}else{
					var schedules = JSON.parse(JSON.stringify(self.model.schedules)); // fiz dessa forma pois com o Assign ele está mantendo os getters e setters do objeto					
					if(item != undefined && item != ""){							
						if(typeof item.week_day == "object"){
							schedules.week_day.value = item.week_day.value;
							schedules.initial_time.value = item.initial_time.value;
							schedules.final_time.value = item.final_time.value;
						}else{
							schedules.week_day.value = item.week_day;
							schedules.initial_time.value = item.initial_time;
							schedules.final_time.value = item.final_time;							
						}
					}else{
						schedules.week_day.value = 0;
						schedules.initial_time.value = "00:00:00";
						schedules.final_time.value = "00:00:00";			
					}
					retorno = schedules;
				}
				break;
			case "margin_rules": 
				if(typeof item == "object" && item.hasOwnProperty("margin_rules")){
					retorno = Object.assign({},item);
				}else{
					var margin_rules = JSON.parse(JSON.stringify(self.model.margin_rules)); // fiz dessa forma pois com o Assign ele está mantendo os getters e setters do objeto					
					if(item != undefined && item != ""){	
						if(typeof item.min == "object"){
							margin_rules.min.value = item.min.value;							
							margin_rules.max.value = item.max.value;
							margin_rules.discount.value = item.discount.value;
						}else{
							margin_rules.min.value = item.min;
							margin_rules.max.value = item.max;
							margin_rules.discount.value = item.discount;
						}
					}else{
						margin_rules.min.value = 0;
						margin_rules.max.value = 0;
						margin_rules.discount.value = 0;
					}
					retorno = margin_rules;
				}
				break;
			case "rules_cart":
				if(typeof item == "object" && item.hasOwnProperty("rules_cart")){
					retorno = Object.assign({},item);
				}else{
					var rules_cart = JSON.parse(JSON.stringify(self.model.rules_cart)); // fiz dessa forma pois com o Assign ele está mantendo os getters e setters do objeto					
					if(item != undefined && item != ""){	
						if(typeof item.type == "object"){
							rules_cart.type.value = item.type.value;							
							rules_cart.operator.value = item.operator.value;
							rules_cart.valor.value = item.valor.value;
						}else{
							rules_cart.type.value = item.type;
							rules_cart.operator.value = item.operator;
							rules_cart.valor.value = item.value;
						}
					}else{
						rules_cart.type.value = "";
						rules_cart.operator.value = "";
						rules_cart.valor.value = "";
					}
					// tratando algumas exceções
					switch(rules_cart.type.value){
					case "quantity":
						// Quantidade de Produtos
						rules_cart.valor.type = "number";						
						break;
					case "value":
						// Valor do Carrinho
						rules_cart.valor.type = "money";						
						break;
					case "postalcode":
						// CEP
						rules_cart.valor.type = "postalcode";
						break;
					case "first_purchase":
						// Primeira Compra
						rules_cart.operator.value = "=";
						rules_cart.valor.type = "select";						
						break;

					}
					retorno = rules_cart;
				}
				break;
			case "phones": 
				if(typeof item == "object" && item.hasOwnProperty("phones")){
					retorno = Object.assign({},item);
				}else{
					var phones = JSON.parse(JSON.stringify(self.model.phones)); // fiz dessa forma pois com o Assign ele está mantendo os getters e setters do objeto					
					if(item != undefined && item != ""){	
						if(typeof item.number == "object"){
							phones.type.value = item.type.value;							
							phones.number.value = item.number.value;
						}else{
							phones.type.value = item.type;
							phones.number.value = item.number;
						}
					}else{
						phones.type.value = "";
						phones.number.value = "";
					}
					// tratando algumas exceções
					switch(phones.type.value){
					case "home":
					case "work":
						// Residencial e trabalho
						phones.number.type = "phone";						
						break;
					case "cellphone":
					case "whatsapp":	
						// Celular e Whatsapp
						phones.number.type = "cellphone";						
						break;
					}
					retorno = phones;
				}
				break;
			}

			return retorno;
		},
		novaRegra: function(){
			this.$store.commit(this.state + "/addOn", {tipo: this.type, item: ""});
		},
		updateComponent: function(){			
			let dados = Object.assign([],this.dados);
			this.$store.commit(this.state + "/addOnUpdateAll", {tipo: this.type, itens: dados});
		},
		removeRegra: function(index){
			let dados = Object.assign([],this.dados);
			dados.splice(index, 1);
			this.$store.commit(this.state + "/addOnUpdateAll", {tipo: this.type, itens: dados});
		}
	},
	mounted() {		
		if(this.dados.length == 0){
			this.novaRegra();
		}		
	},
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
