<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<div class="row">
					<div class="col-8">
						<div class="card card-base" :class="itens_tab.length > 0">
							<div class="card-header">
								Informações do Usuário
							</div>
							<div class="card-body">
								<!-- Conteudo -->
								<div class="row">
									<div class="col-12">
										<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" />
									</div>									
									<div class="col-6">
										<FormInput :required=false rules="string" label="E-mail" placeholder="" info="" v-model="model.email" />
									</div>
									<div class="col-6">
										<FormSelect :required=true rules="option" label="Gestor?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.manager" type="boolean" />		
									</div>									
									<div class="col-6">
										<FormInput :required=true rules="string" label="Login" placeholder="" info="" v-model="model.login" />
									</div>
									<div class="col-6">
										<FormInput :required="(model.id) ? false : true" rules="string" label="Senha" placeholder="" info="" type="password" v-model="model.password" />
									</div>
									<div class="col-4">
										<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
									</div>
									<div class="col-4">
										<FormSelect :required=true rules="option" label="É Vendedor?" info="" :options="[{id: false,label: 'Não'}, {id: true, label: 'Sim'}]" v-model="model.seller" type="boolean" />		
									</div>
									<div class="col-4" v-show="model.seller">
										<FormInputGroup :required=true rules="string" label="Comissão do Vendedor" placeholder="" info="" position="right" text="%" type="money" v-model="model.commission" />
									</div>
									
								</div>						
								<!-- Fim Conteudo -->
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="card card-base" :class="itens_tab.length > 0">
							<div class="card-header">
								Grupos de Permissão
							</div>
							<div class="card-body">
								<!-- Conteudo -->
								<MultiSelect state="usuarios" tipo="groups" :dados="grupos" :field_required=false :required=false></MultiSelect>
								<!-- Fim Conteudo -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import alerts from "./../../../common/alerts";
import MultiSelect from "./../../common/MultiSelect";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		MultiSelect,
		FormSelect,
		FormInputGroup
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.usuarios.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Usuários",
				chamada: "Gerencie os usuários da sua loja",
				links: {
					back: "usuarios.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Usuários",
					link: "/usuarios"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[],
			disabled_items: [],
			hidden_items: [],
			grupos: [],
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){				
				if(this.model.groups.length == 0) {
					alerts.alertTop("error","É necessário inserir ao menos um grupo.");
					return false;
				}

				if(this.model.password == null || this.model.password == ""){
					delete this.model.password;
				}

				// salva
				this.$store.dispatch("usuarios/save", this.model);
			}
		},
	},
	provide: function () {
		return {
			save: this.save
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("usuarios/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("usuarios/load", this.$route.params.id);		
		}
		// pega os atributos
		this.$store.dispatch("grupos/load_grupos").then((response) => {
			self.grupos = response;
		});
	},
	watch: {
		
	}
};
</script>

<style scoped lang="scss">	
	
</style>
