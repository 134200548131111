<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		
		<!-- Tabela -->
		<Tabela :config="config" :modulo_permissao=modulo_permissao />
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Tabela from "./../../common/Tabela";

export default {
	name: "Marcas",
	components: {
		Header,
		Breadcrumb,
		Tabela
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		valores(){
			return this.$store.state.precos.valores;
		},
		prices_updated(){
			return this.$store.state.precos.prices_updated;
		}
	},
	data () {
		return {
			header_info: {
				title: "Edição Rápida de Preços",
				chamada: "Gerencie os preços dos produtos da sua loja",
				links: {
					save: "."
				}
			},
			breadcrumb_info: [
				{
					name: "Preços",
					link: ""
				}
			],
			filtros: null,
			permissao: [],
			config: {
				state: "precos",
				tipo: "precos",
				modulo_permissao: this.modulo_permissao,
				url_path: "precos",
				flags: {
					multi: {
						enable: true,
						delete: false,
						export: true,
						print: false,
						transfer: false,
						activate: false,
						inactivate: false
					},
					look: false,
					edit: false,
					delete: false
				}
			},
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	provide: function () {
		return {
			save: this.save
		};
	},
	mounted() {
	},
	methods: {		
		novo() {
			this.$store.dispatch(this.config.state + "/reset");
		},
		save: function(){
			let self = this;
			let prices = [];
			
			this.prices_updated.forEach(item => {
				let price = self.valores.items.find(dado => {
					return dado.sku == item.sku && dado.store_id == item.store;
				});			
				if(price){
					prices.push({
						sku: price.sku,
						store_id: price.store_id,
						cost: price.cost,
						full_price: price.full_price,
						discount_real: price.discount_real,
						discount_percentage: price.discount_percentage
					});
				}
			});
			this.$store.dispatch("precos/update",prices);
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
