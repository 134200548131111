<template>
	<div id="main-fluid" class="container-fluid " :class="(hide_menu) ? '' : 'menu-on'">		
		<Menu />
		<div class="main">			
			<Topo />			
			<router-view></router-view>
		</div>
		<div id="loader">
			<img id="loader_mascote" src="./assets/img/loader_mascote.gif" />
		</div>		
		
	</div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";

// components
import Menu from "./components/common/Menu";
import Topo from "./components/common/Topo";

export default {
	name: "app",
	components: {
		Menu,
		Topo
	},
	computed: {
		hide_menu() {
			return this.$store.state.app.hide_menu;
		}
	},
	methods: {
		
	},
	created() {		
	}
};
</script>

<style lang="scss">
	@import './assets/css/reset.scss';
	@import './assets/css/main.scss';

	.main{
		margin-left: 5px;
	}	
	.menu-on{
		.main{
			margin-left: 15em;
		}		
	}	
	#main-fluid{
		height: 100%;
		position: relative;
	}
	#loader{
		background: #FFF;
		width: 100%;
		opacity: 0.95;
		position: fixed;
		left: 0;
		top: 0;
		min-height: 100%;
		height: auto;
		z-index: 999999;
		display: none;
		&.open{
			display: block;
		}
	}
	#loader_mascote{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -125px;
		margin-top: -125px;
		z-index: 1999999;
		display: none;
		&.open{
			display: block;
		}
	}
	
</style>
