<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais da Avaliação
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<div class="form-group required"> 
									<label class="mb-1">Nome Cliente <i class="fas fa-asterisk"></i></label>
									<MultiSelect state="reviews" tipo="customers" :dynamic=true dynamic_action="clientes/filter_customers_data" :dados="[]" :field_required=false :multiple=false :required=true :excluir_validation=false></MultiSelect>
								</div>
							</div>
							<div class="col-12">
								<div class="form-group required"> 
									<label class="mb-1">Produto <i class="fas fa-asterisk"></i></label>
									<MultiSelect state="reviews" tipo="product" :dynamic=true dynamic_action="produtos/filter_products_data" :dados="[]" :field_required=false :multiple=false :required=true :excluir_validation=false></MultiSelect>
								</div>
							</div>	
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1, label: 'Mundo da Carabina'}, {id: 2,label: 'Aventura & Cia'}, {id: 3,label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>	
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Destaque" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.featured" type="boolean" />
							</div>
							<div class="col-12">
								<FormTextarea :required=true label="Depoimento" v-model="model.comment" :rows=3 placeholder="Digite aqui o depoimento" info="" type="textarea" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Avaliação" info="" :options="[{id: 1,label: 'Péssimo'}, {id: 2, label: 'Ruim'}, {id: 3, label: 'Razoável'}, {id: 4, label: 'Bom'}, {id: 5, label: 'Excelente'}]" v-model="model.rating" type="number" />		
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import url_prefix from "./../../../assets/json/urls.json";

// Inputs
// import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormTextarea from "./../../common/form/Textarea";
import MultiSelect from "./../../common/MultiSelect";



export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		// FormInput,
		FormSelect,
		FormTextarea,
		MultiSelect,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.reviews.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Avaliações",
				chamada: "Gerencie as avaliações dos produtos da sua loja",
				links: {
					back: "produtos.reviews.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "reviews",
					link: "/reviews"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
			],
			disabled_items: [],
			hidden_items: [],
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			

				let model = Object.assign({},this.model);

				model.product_id = model.product.id;
				delete model.product;

				model.customer_id = model.customers.id;
				delete model.customers;

				// salva
				this.$store.dispatch("reviews/save", model);
			}
		},
		toSlug: function(){
			if(this.model.slug == ""){
				this.model.slug = url_prefix.brand + Slug(this.model.name);
			}			
			if(this.model.metatags && this.model.metatags.title == null){
				this.model.metatags.title = this.model.name;
			}
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("reviews/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("reviews/load", this.$route.params.id).then(response => {
				if(response.body.return_code == 1){
					// pega clientes
					self.$store.dispatch("clientes/load", response.body.data.customer.id).then(retorno => {
						if(retorno.return_code == 1){
							self.$store.commit("reviews/multiselect_push", {item: {
								id: retorno.data.id,	
								name: retorno.data.name,
							}, tipo: "customers", multiple: false});
						}		
					});
					// pega produto
					self.$store.dispatch("produtos/load", response.body.data.product_id).then(retorno => {
						if(retorno.body.return_code == 1){
							self.$store.commit("reviews/multiselect_push", {item: {
								id: retorno.body.data.id,	
								name: retorno.body.data.title,
							}, tipo: "product", multiple: false});
						}						
					});
				}				
			});
		}
	},
	watch: {
		
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
