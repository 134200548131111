export default {
	set(key, value) {
		window.localStorage[key] = value;
		return window.localStorage[key];
	},
	get(key, defaultValue) {
		return window.localStorage[key] || defaultValue;
	},
	setObject(key, object) {
		window.localStorage[key] = JSON.stringify(object);
		return this.getObject(key);
	},
	getObject(key) {
		let object = window.localStorage[key];
		if (object)
			return JSON.parse(window.localStorage[key]);
		else
			return null;
	},
	remove(key) {
		window.localStorage.removeItem(key);
	},
};