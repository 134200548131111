<template>
  <div>
    <!-- Module Title and SubTitle -->
    <Header :dados=header_info :modulo_permissao=modulo_permissao />
    <!-- Breadcrumb -->
    <Breadcrumb :dados=breadcrumb_info />
		<!-- Form Filters Section -->
		<card class="mt-3">
			<template #header>Filtros</template>
			<template>
				<div class="row">
					<div class="col-6">
						<FormInputGroup
							:required=true
							rules="string"
							label="Data de Início"
							placeholder=""
							info=""
							position="left"
							text=""
							icon="fas fa-calendar-alt"
							type="date"
							v-model="filterData.startDate"
							:index=0
						/>
					</div>
					<div class="col-6">
						<FormInputGroup
							:required=true
							rules="string"
							label="Data Final"
							placeholder=""
							info=""
							position="left"
							text=""
							icon="fas fa-calendar-alt"
							type="date"
							v-model="filterData.endDate"
							:index=0
						/>
					</div>
					<div class="col-4">
						<form-select
							label="Loja"
							info=""
							:options="stores"
							:required="true"
							v-model="filterData.store"
						/>
					</div>
					<div class="col-4">
						<form-select
							label="Canal de Venda"
							info=""
							:options="sources"
							:required="true"
							v-model="filterData.source"
						/>
					</div>
					<div class="col-4">
						<Input
							:required=true
							rules=""
							label="Quantidade de Produtos"
							placeholder=""
							info=""
							type="number"
							v-model="filterData.productsQuantity"
						/>
					</div>
					<div class="col-6">
						<button class="btn btn-block btn-success" @click="handleApplyFilters()">
							Filtrar Dados
						</button>
					</div>
					<div class="col-6">
						<button class="btn btn-block btn-danger" @click="clearData()">
							Limpar Dados
						</button>
					</div>
				</div>
			</template>
		</card>
		<!-- Response Section -->
    <div class="row">
		<div class="col-6 my-3">
			<Categorias
				v-if="filterData.store"
				:permissao="{ criar: 1, editar: 1, excluir: 1, exportar: 1 }"
				:selecionavel="true"
				:selecionados="selectedCategories"
				:store_enabled="parseInt(filterData.store)"
				:enable_selectall="false"
				state="categorias"
			/>
		</div>

		<div class="col-6 my-3" v-if="categoriesReport.length">
			<div class="row">
				<div class="col-12">
					<card
						:key="category.categoryId"
						:allow-refresh=true
						class="mb-3"
						v-for="category in categoriesReport"
						@card:refresh="handleChangeOrderBy(category)"
					>
						<template #header>{{ category.categoryName }}</template>
						<template>
							<div role="alert" class="alert alert-info">
								<strong>Faturamento: </strong>{{ category.categoryTotalSold | money }} | {{ ((100 * category.categoryTotalSold) / totalOrders).toFixed(2) }} %
							</div>

							<!-- Category Report. -->
							<table width="100%">
								<col width="33%" />
								<col width="33%" />
								<col width="33%" />
								<tr class="cabecalho">
									<th class="align-middle">Produto</th>
									<th class="align-middle">Quantidade</th>
									<th class="align-middle">Valor</th>
								</tr>
								<tr v-for="(product, i) in category.orderedProducts[category.orderBy]" :key=i>
									<td class="titulo align-middle">{{ product.productName }}</td>
									<td class="align-middle">{{ product.quantity | milhar }}</td>
									<td class=" align-middle">{{ product.ticket | money }}</td>
								</tr>
							</table>
						</template>
					</card>
				</div>
			</div>
		</div>
    </div>
  </div>
</template>

<script>
import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import validation from "../../../common/validation";
import Filters from "../../../common/filters";

import alerts from "../../../common/alerts";
import Card from "@/components/common/Card";
import InputGroup from "@/components/common/form/InputGroup";
import Select from "@/components/common/form/Select";
import Input from "@/components/common/form/Input";
import Categorias from "@/components/common/Categorias.vue";

export default {
	name: "ReportsSalesByCategory",
	components: {
		Input,
		FormSelect: Select,
		FormInputGroup: InputGroup,
		Card,
		Header,
		Breadcrumb,
		Categorias
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	filters: Filters,
	computed: {
		allCategories: function () {
			return this?.$store.state.categorias.categorias;
		},
		selectedCategories: function () {
			return this?.$store.state.categorias.selecionados;
		},
		/**
		 *
		 * @returns {{categoryTotalSold: float; categoryId: string; categoryName: string; products: {productName: string; productSku: string; quantity: number; ticket: float}[]}[]|*}
		 */
		categoriesReport: function () {
			return this?.$store.state.categorias.categoriesReports;
		},
		selectedSources: function () {
			switch (this.filterData.source) {
			case "stores":
				return ["loja", "admin"];
			case "marketplace":
				return ["marketplace"];
			default:
				return ["loja", "admin", "marketplace"];
			}
		}
	},
	data () {
		return {
			header_info: {
				title: "Relatório Geral de Vendas por Categoria",
				chamada: "Acompanhe as principais informações das vendas dos sites e marketplaces",
				links: {}
			},
			breadcrumb_info: [
				{
					name: "Relatório de Vendas por Categoria",
					link: "/"
				}
			],
			stores: [
				{
					id: 1,
					name: "Mundo da Carabina"
				},
				{
					id: 2,
					name: "Aventura & Cia"
				},
				{
					id: 3,
					name: "Lexo"
				}
			],
			// Stores.
			sources: [
				{
					id: "all",
					name: "Todos"
				},
				{
					id: "stores",
					name: "Lojas"
				},
				{
					id: "marketplace",
					name: "Marketplace"
				}
			],
			// Filters.
			filterData: {
				startDate: undefined,
				endDate: undefined,
				store: undefined,
				productsQuantity: undefined,
				source: undefined
			},
			// config.
			config: {
				modulo_permissao: this.modulo_permissao,
				state: "categorias",
				stateKey: "categorias",
				tipo: "categorias",
				flags: {
					delete: false,
					edit: false,
					look: false,
					multi: {
						activate: false,
						delete: false,
						enable: false,
						export: false,
						inactivate: false,
						print: false,
						transfer: false
					}
				}
			},
			totalOrders: undefined
		};
	},
	methods: {
		/**
		 * Dispatches an action to load all the categories by a given store id.
		 *
		 * @param { 1 | 2 | 3 } storeId - The id of the selected store.
		 * @returns {Promise<void>}
		 */
		async loadCategoriesByStoreId(storeId) {
			await this.$store.dispatch("categorias/load_categories_by_store", storeId);
		},
		/**
		 * Loads all the total sales count by the given period and store.
		 *
		 * @returns {Promise<any>}
		 */
		async loadAllSalesByPeriod() {
			return this.$store.dispatch(
				"reports_sales/get_all_sales_by_period",
				{
					initial_date: this.filterData.startDate,
					final_date: this.filterData.endDate,
					store_id: this.filterData.store,
					sources: this.selectedSources
				}
			);
		},
		/**
		 * Applies the current filters' data.
		 *
		 * @returns {Promise<void>}
		 */
		async handleApplyFilters() {
			if(!validation.validation().valid){
				alerts.alertTop("error", "Houve um problema na busca, tente novamente");
				return;
			}

			this.$store.commit("categorias/reset_report_sales_by_category");

			try {
				await this.loadCategoriesByStoreId(this.filterData.store);
				const response = await this.loadAllSalesByPeriod();
				this.totalOrders = response.totalOrders;
				this.$store.commit("reports_sales/load_report_filters", {
					...this.filterData,
					sources: this.selectedSources
				});
			} catch(err) {
				alerts.alertTop("error", "Houve um problema na busca, tente novamente");
			}
		},

		clearData() {
			this.$store.commit("categorias/reset_report_sales_by_category");
		},

		handleChangeOrderBy(category) {
			this.$store.commit("categorias/order_category_products", { field: category.orderBy, categoryId: category.categoryId });
		}
	}
};
</script>

<style scoped lang="scss">
.checkbox {
	cursor: pointer !important;

	&:hover {
		opacity: .8;
	}
}
</style>
