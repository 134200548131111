<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<!-- Resumo -->
		<div class="row dashboard">
			<div class="col-12" v-if="resume && resume.total_order">
				<div class="card-body">
					<div class="row">							
						<div class="col-md-4 my-2 ">
							<section class="panel dash-info-rapida dash-green">			
								<div class="value ">
									<h1>{{ resume.total_order | money }}</h1>
									<p>Receita Pedidos</p>
								</div>
							</section>
						</div>
						<div class="col-md-4  my-2">
							<section class="panel dash-info-rapida dash-red">			
								<div class="value ">
									<h1>{{ resume.marketplace_fee | money }}</h1>
									<p>Tarifa Total | {{ ((resume.marketplace_fee / resume.total_order * 100)).toFixed(2) }}%</p>
								</div>
							</section>
						</div>		
						<div class="col-md-4 my-2 ">
							<section class="panel dash-info-rapida dash-green">			
								<div class="value ">
									<h1>{{ resume.total_shipping | money }}</h1>
									<p>Frete Cliente | {{ ((resume.total_shipping / resume.total_order * 100)).toFixed(2) }}%</p>
								</div>
							</section>
						</div>
						<div class="col-md-4 my-2">
							<section class="panel dash-info-rapida dash-red">			
								<div class="value ">
									<h1>{{ resume.seller_freight | money }}</h1>
									<p>Frete Loja - Custo | {{ ((resume.seller_freight / resume.total_order * 100)).toFixed(2) }}%</p>
								</div>
							</section>
						</div>	
						<div class="col-md-4 my-2">
							<section class="panel dash-info-rapida dash-green">			
								<div class="value ">
									<h1>{{ resume.total_order_real | money }}</h1>
									<p>Repasse Bruto | {{ (((resume.total_order_real / resume.total_order) * 100)).toFixed(2) }}%</p>
								</div>
							</section>
						</div>	
						<div class="col-md-4 my-2">
							<section class="panel dash-info-rapida dash-green">			
								<div class="value ">
									<h1>{{ resume.total_refund | money }}</h1>
									<p>Reembolso</p>
								</div>
							</section>
						</div>			
					</div>
				</div>
			</div>
			
		</div>

		<!-- Tabela -->
		<Tabela :config="config" :modulo_permissao=modulo_permissao />
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Tabela from "./../../common/Tabela";
import Filters from "./../../../common/filters";

export default {
	name: "ReportsTaxasMercadoLivre",
	components: {
		Header,
		Breadcrumb,
		Tabela
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		data_items() {
			let valores = this.$store.state.reports_taxasml.valores || {};
			return valores.items || [];
		},
		resume() {
			return this.$store.state.reports_taxasml.resume_data || null;
		},	
	},
	filters: Filters,
	data () {
		return {
			header_info: {
				title: "Relatório de Taxas Mercado Livre",
				chamada: "Relatório com as taxas cobrados pelo mercado livre",
				links: {
					criar: "",
					resume: true
				}
			},
			breadcrumb_info: [
				{
					name: "Relatórios de Taxas ML",
					link: ""
				}
			],
			filtros: null,
			valores: null,
			permissao: [],
			config: {
				state: "reports_taxasml",
				tipo: "reports_taxasml",
				modulo_permissao: this.modulo_permissao,
				url_path: "pedidos",
				flags: {
					multi: {
						enable: true,
						delete: false,
						export: true,
						print: false,
						transfer: false,
						activate: true,
						inactivate: true,						
					},
					look: true,
					edit: false,
					delete: false,
				}
			},
		};
	},  
	watch: {
		data_items: function(dado){			
			if(dado && dado.length > 0){				
				this.$nextTick(function () {
					let table = document.getElementsByClassName("table-listagem")[0];
					let linhas = table.getElementsByTagName("tbody")[0].children;

					let index=0;
					for(let element of linhas) {

						let td = element.getElementsByTagName("td");

						td[2].style.backgroundColor = "#d1e6c8";
						td[5].style.backgroundColor = "#d1e6c8";

						td[3].style.backgroundColor = "#f7cdcd";
						td[4].style.backgroundColor = "#f7cdcd";
						td[6].style.backgroundColor = "#f7cdcd";
						td[7].style.backgroundColor = "#f7cdcd";

						// frete loja
						if(((dado[index].seller_freight/dado[index].total_order) * 100) >= 10){
							td[7].getElementsByTagName("div")[0].style.backgroundColor = "#fcf91e";
						}

						// tarifa
						if(((dado[index].marketplace_fee/dado[index].total_order) * 100) >= 17){							
							td[4].getElementsByTagName("div")[0].style.backgroundColor = "#fcf91e";
							
						}

						index++;						

					}
				});
			}
		}
	},
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	mounted() {
	},
	provide: function () {
		return {
			gerarResumo: this.gerarResumo
		};
	},
	methods: {		
		novo() {
			this.$store.dispatch(this.config.state + "/reset");
		},
		gerarResumo() {
			let search_data = Object.assign({}, this.$store.state[this.config.state].search_data);			
			search_data.opcoes.resume = true;
			this.$store.dispatch(this.config.state + "/lista_filter_update", search_data);
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
