export default {
	init: function() {
		return {
			model: {
				id: null,
				name: null,
				code: null,
				reason: null,
				type: {
					id: null
				},
				value: 0,
				sale_value: 0,
				uses_per_customer: 0,
				quantity: 1,
				type_link: 1,
				initial_date: null,
				final_date: null,				
				products: [],
				brands: [],
				categories: [],
				rules: {
					sex: "MF",
					postal_range: [],
					email: [],
					document: [],
					birthday_day: 0,
					birthday_month: 0,
					first_purchase: 0
				},
				relation: [],
				status: 1,
				store_id: 0,
				categorias_selecionadas: [],
				marcas_selecionadas: [],
				produtos_selecionados: []
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "name",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
			// postal_range
			postal_range: [],
			categorias: []
		};
	}
};
