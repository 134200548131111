export default {
	reset(state, payload) {
		for (let p in state) {
			state[p] = payload[p];
		}
	},
	loaded(state, payload) {
		if (payload.return_code == 1) {			
			// pega o model como veio do banco
			let model = state.model;
			// percorre o que veio do banco para ver se tem campo a mais
			//  e preencher corretamente os valores dos que existem 
			for(let chave in payload.data){
				model[chave] = payload.data[chave];
			}            
			state.model = model;
		}
	},
	update(state, payload) {
		if (payload.return_code == 1) {
			state.model = payload.data;
		}
	},	
	// //lista
	data_valores_loaded(state, payload) {
		if (payload.return_code == 1) {
			state.valores = payload.data;
		}
	},
	data_filtros_loaded(state, payload) {
		state.table_fields = payload;
	},
	altera_pagina(state,pagina){
		let search_data = state.search_data;
		search_data.opcoes.page = pagina;
		state.search_data = search_data;
	},
	altera_limit(state,limit){
		let search_data = state.search_data;
		search_data.opcoes.page = 1;
		search_data.opcoes.limit = limit;
		state.search_data = search_data;
	},
	select_listagem(state,id){
		let select = state.selecionados;
		if(select.find((item) => {
			return item == id;
		})){
			// remover dos selecionados
			select.splice(select.indexOf(id), 1);
		}else{
			// incluir dos selecionados
			select.push(id);
		}
		state.selecionados = select;
	},
	select_listagem_all(state,ids){
		state.selecionados = ids;
	},
	sort_listagem(state,field){
		let search_data = state.search_data;
		search_data.opcoes.page = 1;
		if(search_data.opcoes.order_field == field){
			// executa o toogle na direção
			if(search_data.opcoes.order == "asc"){
				search_data.opcoes.order = "desc";
			}else{
				search_data.opcoes.order = "asc";
			}
		}else{
			// aplica o asc
			search_data.opcoes.order = "asc";
		}
		search_data.opcoes.order_field = field;
		state.search_data = search_data;
	},
	clear_valores(state){
		state.valores = {};
		state.selecionados = [];
		state.checkAll = false;
	},
	acao_massa(){
		// state.selecionados = [];
	},
	no_action(){

	},
	filters(state,filtros){		
		state.filters = [];
		// percorre os filtros que já estão com valores para enviar a pesquisa
		let search_filters = [];
		filtros.forEach( item => {			
			if(item.search.value !== ""){
				search_filters.push(item.search);
			}
		});
		// armazena o search
		state.search_data.filtros = search_filters;
		// armazena o filtro		
		state.filters = filtros;
		if(filtros.length == 0){
			// limpa os outros dados
			state.search_data.filtro_selecionado = 0;
		}
	},
	get_filtros_salvos(state,payload){
		if(payload.return_code == 1){
			state.saved_filters = payload.data;
		}		
	},
	save_filter(state,payload){
		if(payload.return_code == 1){
			// atualizando os filtros
			let index = state.saved_filters.findIndex(item => {
				return item.id == payload.data.id;
			});
			if(index >= 0){
				state.saved_filters[index] = payload.data;				
			}else{
				state.saved_filters.push(payload.data);
			}
			state.search_data.filtro_selecionado = payload.data.id;
		}
	},
	delete_filter(state,payload){
		if(payload.return_code == 1){
			let id = state.search_data.filtro_selecionado;
			let filtros = state.saved_filters;
			let index = filtros.findIndex(item => {
				return item.id == id;
			});
			filtros.splice(index, 1);
			state.saved_filters = filtros;
			state.search_data.filtro_selecionado = 0;
		}
	},
	select_filter(state,payload){
		state.search_data.filtro_selecionado = payload;
	},	
	data_resume_loaded(state,payload){        
		let resume = {};
		if(payload.return_code == 1) {
			const lojas = ["mundo","aventura","lexo","marketplace","all"];
			for(const loja in lojas){
				resume[lojas[loja]] = {
					total_orders: 0,
					total_revenue: 0,
					total_shipping: 0,
					total_discounts: 0,
					total_cost: 0,
				};
			}				
			payload.data.items.forEach( item => {				
				let tipo;
				if (item.marketplace.marketplace_id) {
					tipo = "marketplace";
				}else{
					switch(item.store_id){
					case 1:
						tipo = "mundo";
						break;
					case 2:
						tipo = "aventura";
						break;
					case 3:
						tipo = "lexo";
						break;					
					}
				}				
				let total_cost = 0;
				item.products.forEach( product => {	
					const unit_cost = product.unit_cost || product.cost || 0;
					total_cost += product.quantity * unit_cost;
				});
				// somando da loja específica
				resume[tipo].total_orders += 1;
				resume[tipo].total_revenue += item.total_order - item.total_shipping;
				resume[tipo].total_shipping += item.total_shipping;
				resume[tipo].total_discounts += item.total_discounts ?  item.total_discounts : item.total_discount;
				resume[tipo].total_cost += total_cost;
				// somando o geral
				resume.all.total_orders += 1;
				resume.all.total_revenue += item.total_order - item.total_shipping;
				resume.all.total_shipping += item.total_shipping;
				resume.all.total_discounts += item.total_discounts ?  item.total_discounts : item.total_discount;
				resume.all.total_cost += total_cost;
			});
		}
		state.resume_data = resume;
	},
	data_status_loaded(state,payload){        
		if(payload.return_code == 1){
			let items = payload.data.items;
			items.sort(function(a, b) {
				if (a.order > b.order) {
					return 1;
				}
				if (a.order < b.order) {
					return -1;
				}
				return 0;
			});
			state.status = items;
		}
	},
	data_status_order_updated(){
        
	},
	data_motivos_loaded(state,payload){
		if(payload.return_code == 1){
			state.motivos_cancelamento = payload.data;
		}
	},
	data_vendedores_loaded(){
		
	},
	status_load(){
        
	}
};