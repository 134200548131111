<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		
		<!-- Tabela -->
		<Tabela v-if="!blocked" :config="config" :modulo_permissao=modulo_permissao />
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Tabela from "./../../common/Tabela";
import alerts from "./../../../common/alerts";
import {getCookie} from "./../../../common/utils";

export default {
	name: "Senhas",
	components: {
		Header,
		Breadcrumb,
		Tabela
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		usuario() {
			return this.$store.state.app.usuario;
		},
		passValidate() {
			return this.$store.state.app.passValidate;
		}
	},
	data () {
		return {
			header_info: {
				title: "Senhas",
				chamada: "Gerencie as senhas da sua loja",
				links: {
					criar: "senhas.novo"
				}
			},
			breadcrumb_info: [
				{
					name: "Senhas",
					link: ""
				}
			],
			filtros: null,
			valores: null,
			blocked: true,
			passInputed: "",			
			permissao: [],			
			config: {
				state: "senhas",
				tipo: "senhas",
				modulo_permissao: this.modulo_permissao,
				url_path: "senhas",
				flags: {
					multi: {
						enable: true,
						delete: true,
						export: false,
						print: false,
						transfer: false,
						activate: false,
						inactivate: false
					},
					look: false,
					edit: true,
					delete: true
				}
			},
		};
	},  
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
		if(getCookie("PsGo") && getCookie("PsGo") === this.usuario.id){
			this.blocked = false;
		}else{						
			this.blocked = true;
			this.confirmaAcesso();
		}
	},
	mounted() {
	},
	methods: {		
		novo() {
			this.$store.dispatch(this.config.state + "/reset");
		},
		confirmaAcesso() {
			
			const self = this;
			let event_confirm = async () => {

				if(self.passInputed === self.passValidate){
					this.blocked = false;	
					const d = new Date();
					d.setTime(d.getTime() + (5*60*1000));
					// cria o cookie
					document.cookie = "PsGo="+self.usuario.id+"; expires="+ d.toUTCString();
					window.location.reload();
				}else{
					alerts.alertTop("error","Senha incorreta");
					self.confirmaAcesso();
				}						
				return true;
			};
			let dados = [];
			dados = {
				title: "Informe a senha de segurança:",
				body: [
					{
						type: "input_password",
						event: (valor) => {
							self.passInputed = valor;
						}
					},
				]
			};
			
			alerts.alertConfirm(dados,event_confirm);
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
