import moment from "moment";
export default {
	init: function() {
		return {
			//lista
			search_data: {
				opcoes : {
					order_field: "quantity_paid",
					order: "desc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
			d_filters: {
				data_de: moment().subtract(30, "d").format("YYYY-MM-DD"),
				data_ate: moment().format("YYYY-MM-DD"),
				sugestao: 20,
				dias_futuro: 60
			},
		};
	}
};
