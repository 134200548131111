<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="card card-base">
					<div class="card-header">
						Informações Gerais do Grupo
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>		
						</div>


						<table class="table table-bordered table-hover" v-for="modulo in modulos" :key=modulo.slug>
							<thead>
								<th width='50%' v-html="modulo.name"></th>
								<th class="text-center" width='8%' >Todos</th>
								<th class="text-center" width='8%' >Ver</th>
								<th class="text-center" width='8%' >Criar</th>
								<th class="text-center" width='8%' >Editar</th>
								<th class="text-center" width='8%' >Excluir</th>
								<th class="text-center" width='8%' >Exportar</th>
							</thead>
							<tbody>
								<tr v-for="submodulo in modulo.children" :key="submodulo.slug">
									<td class="align-middle " v-html="submodulo.name"></td>
									<td class="ckeck-place text-center" :class="verificaCheck('all',submodulo) ? 'checked' : ''" v-on:click="check('all',submodulo)">
										<i class="fas fa-check-square"></i>
									</td>
									<td class="ckeck-place text-center" :class="verificaCheck('ver',submodulo) ? 'checked' : ''" v-on:click="check('ver',submodulo)">
										<i class="fas fa-check-square"></i>
									</td>
									<td class="ckeck-place text-center" :class="verificaCheck('criar',submodulo) ? 'checked' : ''" v-on:click="check('criar',submodulo)">
										<i class="fas fa-check-square"></i>
									</td>
									<td class="ckeck-place text-center" :class="verificaCheck('editar',submodulo) ? 'checked' : ''" v-on:click="check('editar',submodulo)">
										<i class="fas fa-check-square"></i>
									</td>
									<td class="ckeck-place text-center" :class="verificaCheck('excluir',submodulo) ? 'checked' : ''" v-on:click="check('excluir',submodulo)">
										<i class="fas fa-check-square"></i>
									</td>
									<td class="ckeck-place text-center" :class="verificaCheck('exportar',submodulo) ? 'checked' : ''" v-on:click="check('exportar',submodulo)">
										<i class="fas fa-check-square"></i>
									</td>
								</tr>
							</tbody>
						</table>	
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";

// Inputs
import FormInput from "./../../common/form/Input";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.grupos.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		modulos() {
			let modulos = this.$store.state.grupos.modulos;

			modulos.forEach(item => {
				item.children.sort(function (a, b) {
					if (a.name > b.name) {
						return 1;
					}
					if (a.name < b.name) {
						return -1;
					}
					// a must be equal to b
					return 0;
				});
			});			

			return modulos;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Grupos de Usuários",
				chamada: "Gerencie os grupos de usuários da sua loja",
				links: {
					back: "usuarios.grupos.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Grupos de Usuários",
					link: "/usuarios/grupos"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				
			],
			disabled_items: [],
			hidden_items: [],
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				// salva
				this.$store.dispatch("grupos/save", this.model);
			}
		},
		verificaCheck: function(type,modulo){

			// pega o objeto correspondente
			let permissao = this.model.permissions.find(item => {
				return item.slug == modulo.slug;
			});
			if(!permissao){
				return false;
			}

			switch(type){
			case "all":
				if(permissao.ver == 1 && permissao.criar == 1 && permissao.editar == 1 && permissao.excluir == 1 && permissao.exportar == 1){
					return true;
				}
				break;
			case "ver":
				if(permissao.ver == 1){
					return true;
				}
				break;	
			case "criar":
				if(permissao.criar == 1){
					return true;
				}
				break;	
			case "editar":
				if(permissao.editar == 1){
					return true;
				}
				break;	
			case "excluir":
				if(permissao.excluir == 1){
					return true;
				}
				break;	
			case "exportar":
				if(permissao.exportar == 1){
					return true;
				}
				break;	
			}

			return false;
		},
		check: function(type, modulo){
			let permissoes = Object.assign([],this.model.permissions);
			// pegando o index
			let index = permissoes.findIndex(item => {
				return item.slug == modulo.slug;
			});

			let permissao;
			if(index >= 0){
				// existe
				permissao = permissoes[index];
			}else{
				// não existe ainda
				permissao = {
					name: modulo.name,
					slug: modulo.slug,
					ver: 0,
					criar: 0,
					editar: 0,
					excluir: 0,
					exportar: 0,
				};
			}

			if(type == "all"){
				if(permissao.ver == 1 && permissao.criar == 1 && permissao.editar == 1 && permissao.excluir == 1 && permissao.exportar == 1){
					permissao.ver = 0;
					permissao.criar = 0;
					permissao.editar = 0;
					permissao.excluir = 0;
					permissao.exportar = 0;
				}else{
					permissao.ver = 1;
					permissao.criar = 1;
					permissao.editar = 1;
					permissao.excluir = 1;
					permissao.exportar = 1;
				}
			}else{
				permissao[type] = (permissao[type] == 1) ? 0 : 1;
			}

			if(index >= 0){
				permissoes[index] = permissao;
			}else{
				permissoes.push(permissao);
			}

			this.$store.commit("grupos/update_permissions",permissoes);
			
		}
	},
	provide: function () {
		return {
			save: this.save
		};
	},
	created() {
		this.$store.dispatch("grupos/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("grupos/load", this.$route.params.id);
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	.ckeck-place{
		cursor: pointer;
		i{
			font-size: 1.3em;
			color: $color_gray;		
		}
		&.checked, &:hover{
			i{
				color: $color_green;
			}
		}
	}	
</style>
