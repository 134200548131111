import storage from "./../../storage";
import ftoken from "./../../common/token";

export default {
	add_request: function(state, payload) {
		state.requests.push(payload);
	},
	remove_request: function(state, payload) {
		state.requests.splice(state.requests.indexOf(payload), 1);
	},
	set_permissoes: function(state, payload) {
		state.permissoes = payload;
	},
	login: function(state, payload) {
		state.usuario = payload.data;
		state.logged = true;
		state.permissoes = state.usuario.permissoes;
		storage.setObject("usuario", payload.data);
		let hidden_menu = storage.getObject("hide_menu");
		if(hidden_menu != null){
			state.hide_menu = storage.getObject("hide_menu");
		}
	},
	login_refresh(state, payload) {
		state.usuario.token = payload;
		ftoken.setToken(payload);
		storage.setObject("usuario", state.usuario);
	},
	set_logged: function(state, payload) {
		state.logged = payload;
	},
	set_in_state: function(state, data) {
		this.state[data.state].model[data.field] = data.value;
	},
	set_hide_menu: function(state, data) {
		state.hide_menu = data;
		storage.setObject("hide_menu", data);
	},
	no_action: function(){

	},	
};