<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Cupom
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>			
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :options="[{id: 0,label: 'Todas as Lojas'}, {id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>						
							<div class="col-5">
								<FormInputGroup :required=true rules="string" label="Código do Cupom" placeholder="" info="" position="right" action_button="Gerar Código" v-model="model.code" />
							</div>
							<div class="col">
								<FormSelect :required=true rules="option" label="Tipo de Desconto" info="" :options="tipos_cupom" v-model="model.type.id" type="number" />		
							</div>
							<div class="col-3" v-if="[1,2,3,4].includes(model.type.id)">
								<FormInputGroup v-if="[1,2].includes(model.type.id)" :required=true rules="string" label="Valor do Desconto" placeholder="" info="" position="left" text="R$" type="money" v-model="model.value" />
								<FormInputGroup v-if="[3,4].includes(model.type.id)" :required=true rules="string" label="Valor do Desconto" placeholder="" info="" position="right" text="%" type="money" v-model="model.value" />
							</div>
							<div class="col-12">
								<FormTextarea :required=true label="Motivo do Cupom" v-model="model.reason" :rows=3 placeholder="Digite aqui o motivo deste cupom" info="" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Permissão" info="" :options="[{id: 1,label: 'Todos os Produtos'}, {id: 2, label: 'Selecionar Produtos'}, {id: 3, label: 'Selecionar Marcas'}, {id: 4, label: 'Selecionar Categorias'}]" v-model="model.type_link" type="number" @input="alteraTipo()" />		
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Valor Mínimo" placeholder="" info="" position="left" text="R$" v-model="model.sale_value" type="money" />
							</div>
							<div :class="{'col-md-6' : !set_use_per_customer, 'col-md-4' : set_use_per_customer}">
								<FormInput :required=true rules="string" label="Máximo de Utilizações" placeholder="" info="" v-model="model.quantity" :slug_function=false  type="number" />
							</div>
							<div :class="{'col-md-6' : !set_use_per_customer, 'col-md-4' : set_use_per_customer}">
								<FormSelect :required=false rules="option" label="Limitar quantidade por cliente?" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="set_use_per_customer" type="boolean" />		
							</div>
							<div class="col-4" v-if="set_use_per_customer">
								<FormInput :required=true rules="string" label="Quantidade por cliente" placeholder="" info="" v-model="model.uses_per_customer" :slug_function=false type="number" />
							</div>
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data de Início" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="model.initial_date" :index=0 />
							</div>
							<div class="col-4">
								<FormInputGroup :required=true rules="string" label="Data Final" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="model.final_date" :index=1 />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status"  type="number" />
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Regras -->
				<div class="hide card card-base">
					<div class="card-header">
						Regras
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<div class="form-group mb-0"> 
									<label class="mb-1">E-mails Permitidos</label>
									<MultiSelect state="cupons" tipo="rules.email" :dados="[]" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true></MultiSelect>
								</div>
							</div>
							<div class="col-12 mt-2">
								<div class="form-group mb-0"> 
									<label class="mb-1">CPF / CNPJ Permitidos</label>
									<MultiSelect state="cupons" tipo="rules.document" :dados="[]" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true></MultiSelect>
								</div>
							</div>
							<div class="col-12">
								<FormSelect :required=false rules="option" label="Sexo" info="" :options="[{id: 'MF',label: 'Ambos'}, {id: 'M', label: 'Masculino'}, {id: 'F', label: 'Feminino'}]" v-model="model.rules.sex" />
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Aniversariante do dia" info="" :options="[{id: 1,label: 'Sim'}, {id: 0, label: 'Não'}]" v-model="model.rules.birthday_day"  type="number" />
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Aniversariante do mês" info="" :options="[{id: 1,label: 'Sim'}, {id: 0, label: 'Não'}]" v-model="model.rules.birthday_month"  type="number" />
							</div>
							<div class="col-4">
								<FormSelect :required=false rules="option" label="Primeira Compra" info="" :options="[{id: 1,label: 'Sim'}, {id: 0, label: 'Não'}]" v-model="model.rules.first_purchase"  type="number" />
							</div>
							<div class="col-12">
								<AddOn state="cupons" title="Faixas de CEP" type="postal_range" />
							</div>							
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Categorias -->
				<div class="hide card card-base">
					<div class="card-header">
						Categorias
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Categorias :permissao=permissao state="cupons" :selecionados="categorias_selecionadas" :selecionavel=true :store_enabled=model.store_id />
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Marcas -->
				<div class="hide card card-base">
					<div class="card-header">
						Marcas
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelect state="cupons" tipo="marcas_selecionadas" :dados="marcas" :field_required=false :multiple=true :required=false :excluir_validation=false></MultiSelect>
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Produtos -->
				<div class="hide card card-base">
					<div class="card-header">
						Produtos
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<MultiSelectProdutos state="cupons" tipo="produtos_selecionados" :selectVariations=true :needs_store=true :store_id=model.store_id></MultiSelectProdutos>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import MultiSelect from "./../../common/MultiSelect";
import AddOn from "./../../common/AddOn";
import MultiSelectProdutos from "./../../common/MultiSelectProdutos";
import Categorias from "./../../common/Categorias";
import {deepCopy} from "./../../../common/utils";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";
import FormTextarea from "./../../common/form/Textarea";
import Alerts from "../../../common/alerts";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		FormTextarea,
		MultiSelect,
		AddOn,
		MultiSelectProdutos,
		Categorias
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.cupons.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		postal_range() {
			return this.$store.state.cupons.postal_range;
		},
		categorias_selecionadas() {
			return this.$store.state.cupons.model.categorias_selecionadas;
		},
		marcas_selecionadas() {
			return this.$store.state.cupons.model.marcas_selecionadas;
		},
		categorias() {
			return this.$store.state.cupons.categorias;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Cupons",
				chamada: "Gerencie os cupons da sua loja",
				links: {
					back: "cupons.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Cupons",
					link: "/cupons"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Regras",
				"Categorias",
				"Marcas",
				"Produtos"
			],
			disabled_items: [],
			hidden_items: [
				"Categorias",
				"Marcas",
				"Produtos"
			],
			permissao: {},
			tipos_cupom: [
				{
					id: 1, 
					label: "Reais"
				},
				{
					id: 2, 
					label: "Reais + Frete Grátis"
				}, 
				{
					id: 3, 
					label: "Porcentagem"
				},
				{
					id: 4, 
					label: "Porcentagem + Frete Grátis"
				},
				{
					id: 5, 
					label: "Frete Grátis"
				}
			],
			marcas: [],
			set_use_per_customer: false
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				let valid = true;
				// let model = Object.assign({},this.model);
				let model = deepCopy(this.model);

				// validando o uses_per_customer
				if(!this.set_use_per_customer){
					model.uses_per_customer = 0;
				}else if(model.uses_per_customer <= 0){
					Alerts.alertTop("error","Informe uma quantidade limitada por cliente maior que 0");
					return false;
				}

				// tratando o tipo
				let type_id = model.type.id;
				model.type = this.tipos_cupom.find(item => {
					return item.id == type_id;
				});

				// tratando a faixa de cep
				model.rules.postal_range = [];
				this.postal_range.forEach(item => {
					if(typeof item == "object"){
						if(item.cep_de.value != "" && item.cep_ate.value != ""){
							item.cep_de.value = item.cep_de.value.replace("-","");
							item.cep_ate.value = item.cep_ate.value.replace("-","");
							if(item.cep_de.value.length == 8 && item.cep_ate.value.length == 8){
								model.rules.postal_range.push({ initial_code: item.cep_de.value, final_code: item.cep_ate.value });
							}else{
								Alerts.alertTop("error","Preencha corretamente as faixas de CEP");
								valid = false;
							}
						}else{
							if(item.cep_de.value != "" || item.cep_ate.value != ""){
								Alerts.alertTop("error","Preencha corretamente as faixas de CEP");
								valid = false;
							}
						}
					}					
				});
				// tratando os documents
				model.rules.document.forEach(item => {
					item.name = item.name.replace(/[^\d]+/g,"");
				});

				// tratando os campos document e email
				const documents = model.rules.document.map((item => {
					return item.name;
				}));
				model.rules.document = documents;
				const emails = model.rules.email.map((item => {
					return item.name;
				}));
				model.rules.email = emails;

				model.products = [];				
				model.brands = [];
				model.categories = [];

				// tratando os relacionamentos
				switch(model.type_link){				
				case 2:
					// produtos
					model.produtos_selecionados.forEach(produtos => {
						produtos.skus.forEach(item => {
							if(item.use){
								model.products.push(item.id);
							}							
						});
					});
					break;
				case 3:
					// marca
					model.marcas_selecionadas.forEach(item => {
						model.brands.push(item.id);
					});
					break;
				case 4:
					// categoria
					model.categorias_selecionadas.forEach(item => {
						model.categories.push(item.id);
					});
					break;								
				}

				// apaga os campos
				delete model.marcas_selecionadas;
				delete model.categorias_selecionadas;
				delete model.produtos_selecionados;				

				// // salva
				if(valid){
					this.$store.dispatch("cupons/save", model);
				}
				
			}
		},
		actionButton: function(tipo){
			switch(tipo){
			case "Gerar Código":
				this.model.code = Math.random().toString(36).substring(5).toUpperCase();
				break;
			}
		},
		updateDadosMultiselect: function(){			
			// this[tipo].push(objeto);
		},	
		alteraTipo: function(){
			let type = this.model.type_link;			
			switch(type){			
			case 2:
				// Produtos 
				this.hidden_items = [
					"Categorias",
					"Marcas",
				];
				break;
			case 3:
				// Marcas 
				this.hidden_items = [
					"Categorias",
					"Produtos"	
				];
				break;
			case 4:
				// Categorias
				this.hidden_items = [
					"Marcas",
					"Produtos"	
				];
				break;
			default:
				this.hidden_items = [
					"Categorias",
					"Marcas",
					"Produtos"				
				];
				break;
			}		
		},	
	},
	provide: function () {
		return {
			save: this.save,
			actionButton: this.actionButton,
			updateDadosMultiselect: this.updateDadosMultiselect,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("cupons/reset");
		let startingPromises = [
			// pega as categorias
			this.$store.dispatch("cupons/load_categorias"),
			// pega as marcas
			this.$store.dispatch("cupons/load_marcas").then(response => {
				self.marcas = response;
			}),
		];
		
		Promise.all(startingPromises).then(() => {
			if(self.$route.params.id != undefined && self.$route.params.id != "") {
				self.$store.dispatch("cupons/load", self.$route.params.id).then(response => {
					if(response.return_code == 1){
						self.$store.commit("cupons/addOnUpdateAll",{ tipo: "postal_range", itens: response.data.rules.postal_range});
						
						let data = response.data;

						// tratando os campos document e email
						const documents = data.rules.document.map((item => {
							return { id: null, name: item};
						}));
						data.rules.document = documents;
						const emails = data.rules.email.map((item => {
							return { id: null, name: item};
						}));
						data.rules.email = emails;

						// executa a função que vai exibir a aba correta de acordo com o tipo
						self.alteraTipo();

						switch(data.type_link){
						case 2:
							self.$store.dispatch("cupons/load_produtos_selecionados_data",data.products);
							break;
						case 3:
							// marcas
							self.$store.commit("cupons/update_marcas_selecionadas",data.brands.map(item => {
								let marca = self.marcas.find(dado => {
									return item == dado.id;
								});
								if(marca){
									return marca;
								}
							}));
							break;
						case 4:
							// categoria
							var categorias = [];
							data.categories.forEach(item => {
								categorias.push({id: item});
							});
							self.$store.commit("cupons/update_categorias_selecionadas",categorias);
							break;
						}

						self.set_use_per_customer = (data.uses_per_customer > 0);						
					}					
				});
			}
		}, () => {
			
		});

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	watch: {
		
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
