export function Slug (str) {
	str = str.replace(/^\s+|\s+$/g, ""); // trim
	str = str.toLowerCase();
	str = str.replace(/\./g, "-"); // trocando pontos por -

	// remove accents, swap ñ for n, etc
	let from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
	let to   = "aaaaaeeeeeiiiiooooouuuunc------";
	for (let i=0, l=from.length ; i<l ; i++) {
		str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
	}

	str = str.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
		.replace(/\s+/g, "-") // collapse whitespace and replace by -
		.replace(/-+/g, "-"); // collapse dashes

	return str;
}

export function deepCopy(object) {
	if (typeof object !== "object" || object === null) {
		return object;
	}
  
	if (object instanceof Date) {
		return new Date(object.getTime());
	}
  
	if (Array.isArray(object)) {
		return object.reduce((arr, item, i) => {
			arr[i] = deepCopy(item);
			return arr;
		}, []);
	}
  
	if (object instanceof Object) {
		return Object.keys(object).reduce((newObject, key) => {
			newObject[key] = deepCopy(object[key]);
			return newObject;
		}, {});
	}
}

export function getCookie(name) {
	var cookies = document.cookie;
	var prefix = name + "=";
	var begin = cookies.indexOf("; " + prefix);
	if (begin == -1) {
		begin = cookies.indexOf(prefix);
		if (begin != 0) {
			return null;
		}
	} else {
		begin += 2;
	}
	var end = cookies.indexOf(";", begin);
	if (end == -1) {
		end = cookies.length;                        
	}
	return unescape(cookies.substring(begin + prefix.length, end));
}

// máscara de dinheiro sem plugin
export function ootzMaskMoney(valor,use_mask) {
	if(use_mask){
		if(typeof valor == "number")
			valor= valor.toString();

		valor = valor.replace(/[^0-9]/g,"");
		if(valor.startsWith("0")){
			valor = valor.substring(1);
		}
		var decimal;
		var milhar;
	
		switch(valor.length){
		case 0:
			valor = "0.00";
			break;
		case 1:	
			valor = "0.0"+valor;
			break;
		case 2:
			valor = "0."+valor;
			break;						
		default:
			// 0.123							
			decimal = valor.substring((valor.length - 2));
			if(decimal.length == 1){
				decimal = decimal+"0";
			}
			milhar = valor.substring(0,(valor.length - 2));
			valor = milhar+"."+decimal;
			break;
		}
		return valor.replace(".", ",").replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
	}else{
		valor = valor.replace(/\./g,"");
		valor = valor.replace(",",".");
		valor = parseFloat(valor);
		return valor;
	}
	
}

/**
 *
 * @param { string } currentType
 * @returns {string}
 */
export function toggleProductsFilter(currentType) {
	return currentType === "quantity" ? "ticket" : "quantity";
}