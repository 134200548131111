import api from "./../../common/api";
import model from "./../models/alerts";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		return api.request("alerts/no_action", "admin/users/alert/" + id, "get").then(response => {
			if(response.body.return_code == 1){
				return response.body.data;
			}else{
				return null;
			}
		});
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.alerts);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state,data) => { 
		const userId = state.rootState.app.usuario.id;
		
		data.filtros.push({
			field: "users",
			value: userId,
			operator: "="
		});	

		state.commit("clear_valores");
		api.request("alerts/data_valores_loaded", "admin/users/alerts", "post", { "filters": data.filtros, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	// método switch na linha da listagem
	switch_listagem: (state,dados) => {				
		let data = {};
		data[dados.field] = dados.value;
		return api.request("alerts/no_action", "admin/users/alerts", "put", { ids: [dados.id], data: data });
	},
	// método para atualização de status em massa 
	status_massa: (state,dados) =>{
		let data = {};
		data[dados.field] = dados.value;
		return api.request("alerts/acao_massa", "admin/users/alerts", "put", { ids: dados.id, data: data });
	},
	excluir_massa: (state,dados) =>{		
		return api.request("alerts/acao_massa", "admin/users/alerts", "delete", { ids: dados.id });
	},
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("alerts/no_action", "admin/users/alert/" + id, "delete");
	},
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);
	},
	get_filtros_salvos: (state,config) => {
		return api.request("alerts/get_filtros_salvos", "admin/filters/" + config.tipo + "/" + config.user_id, "get");
	},
	save_filter: (state, filtro) => {
		let method;
		if(filtro.id != null){
			method = "put";
		}else{
			method = "post";
		}		
		return api.request("alerts/save_filter", "admin/filter", method, filtro);
	},
	excluir_filtro: function(state,id){
		return api.request("alerts/delete_filter", "admin/filter/"+id, "delete", id);
	}
};