export default {
	init: function() {
		return {
			filterData: {
				startDate: undefined,
				endDate: undefined,
				store: undefined,
				productsQuantity: undefined,
				sources: undefined
			},
			model: {
				stores: []
			}
		};
	}
};
