<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="card card-base">
					<div class="card-header">
						Informações Gerais do Cliente
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-3">
								<FormSelect :required=true  rules="option" label="Tipo de Cliente" info="" :options="[{id: 1,label: 'Pessoa Física'}, {id: 2, label: 'Pessoa Jurídica'}]" v-model="model.type" type="number" />								
							</div>									
							<div :class="(model.type == 1) ? 'col-9' : 'col-6'">
								<FormInput :required=true name="name" rules="string" :label="(model.type == 1) ? 'Nome Completo' : 'Razão Social'" placeholder="" info="" v-model="model.name" :slug_function=false />
							</div>
							<div class="col-3" v-show="model.type == 2">
								<FormInput :required="(model.type == 2) ? true : false" rules="string" label="Nome Fantasia" placeholder="" info="" v-model="model.fancy_name" />
							</div>
							<div class="col-4">
								<FormInput :required=true name="document" rules="string" :label="(model.type == 1) ? 'CPF' : 'CNPJ'" placeholder="" info="" v-model="model.document" :type="(model.type == 1) ? 'cpf' : 'cnpj'" />
							</div>
							<div class="col-4" v-show="model.type == 2">
								<FormInput :required=false rules="string" label="Inscrição Estadual" placeholder="" info="" v-model="model.ie" />
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Sexo" info="" :options="[{id: 'MF',label: 'Ambos'}, {id: 'F', label: 'Feminino'}, {id: 'M', label: 'Masculino'}]" v-model="model.gender" type="string" />
							</div>
							<div class="col-4" v-if="model.type == 1">
								<FormInputGroup :required=true rules="string" label="Data de Nascimento" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="model.birth" :index=0 />
							</div>
							<div class="col-4">
								<FormInput :required=true name="email" rules="string" label="E-mail" placeholder="" info="" v-model="model.email" />
							</div>
							<div class="col-4">
								<FormInput :required=false rules="string" label="Nova Senha" placeholder="" info="" v-model="model.new_password" />
							</div>
							<div class="col-4" v-show="hasPermission('editar', true)">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Mundo da Carabina" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.store_mundo" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Aventura & Cia" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.store_aventura" type="boolean" />		
							</div>
							<div class="col-4">
								<FormSelect :required=true rules="option" label="Lexo" info="" :options="[{id: true,label: 'Sim'}, {id: false, label: 'Não'}]" v-model="model.store_lexo" type="boolean" />
							</div>
							<div class="col-12">
								<AddOn ref="phones" state="clientes" title="Telefones do Cliente" type="phones" button_name="Novo Telefone" />
							</div>
							
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import AddOn from "./../../common/AddOn";
import Alerts from "./../../../common/alerts";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		AddOn
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.clientes.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		phones() {
			return this.$store.state.clientes.phones;
		},
	},	
	data () {
		return {
			header_info: {
				title: "Clientes",
				chamada: "Gerencie os clientes da sua loja",
				links: {
					back: "clientes.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Clientes",
					link: "/clientes"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
			],
			disabled_items: [],
			hidden_items: [],
			config: {
				modulo_permissao: "clientes"
			},
		};
	},  
	methods: {		
		hasPermission(type, manager) {
			if(manager == undefined){
				manager = false;
			}
			if(!manager){
				return this.permissao[type] === 1;
			}else{
				return this.usuario.manager && this.permissao[type] === 1;
			}
		},
		save: function(){			

			if(validation.validation().valid){			
				let model = Object.assign({},this.model);
				let valid = true;

				// tratando os telefones 
				model.phones = [];
				this.phones.forEach(item => {
					if(typeof item == "object"){
						if(item.type.value != "" && item.number.value != ""){
							let number = item.number.value.replace(/[^0-9]/g,""); // deixa apenas os numeros
							model.phones.push({ type: item.type.value, number: number });
						}else{
							Alerts.alertTop("error","Preencha corretamente os Telefones do Cliente");
							valid = false;
						}
					}					
				});

				// tratando os stores
				model.stores = [];
				if(model.store_mundo){
					model.stores.push(1);
				}
				if(model.store_aventura){
					model.stores.push(2);
				}
				if(model.store_lexo){
					model.stores.push(3);
				}

				delete model.store_mundo;
				delete model.store_aventura;
				delete model.store_lexo;

				// verificando se foi enviado senha
				if(model.new_password && model.new_password != ""){
					model.password = model.new_password;					
				}

				delete model.new_password;
				this.model.new_password = null;

				// salva
				if(valid){
					this.$store.dispatch("clientes/save", model);
				}				
			}
		},
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("clientes/reset");
		if(this.permissoes) {
			this.permissao = this.permissoes[this.config.modulo_permissao];
		}		
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("clientes/load", this.$route.params.id).then(response => {
				if(response.return_code == 1){
					// popula com os dados vindo do banco
					self.$store.commit("clientes/addOnUpdateAll",{ tipo: "phones", itens: response.data.phones});
					// faz com que o component atualize o state com os dados já tratados
					self.$refs.phones.updateComponent();
				}					
			});
		}
	},
	watch: {
		
	}
};
</script>

<style scoped lang="scss">	
	
</style>
