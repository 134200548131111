import api from "../../common/api";
import alerts from "../../common/alerts";
import model from "../models/categorias";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	load: function(state, id) {
		api.request("categorias/loaded", "admin/category/" + id, "get");
	},
	save: function(context, data) {
		return api.request("categorias/no_action", "admin/category", (data.id == null) ? "post" : "put", data).then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", (data.id == null) ? "Categoria cadastrada com sucesso." : "Categoria atualizada com sucesso.");				
			}
			return response.body;
		});
	},			
	// método de exclusão de registro um por vez 
	excluir_registro: (state,id) => {
		return api.request("categorias/no_action", "admin/category/" + id, "delete").then((response) => {
			//tratar a resposta
			if (response.body.return_code == 1) {
				alerts.alertTop("success", "Categoria Removida com Sucesso");				
			}
			return response.body;
		});
	},
	nova_categoria(state,categoria){
		state.commit("nova_categoria",{default: model.init().model, categoria: categoria });
	},
	load_categorias(){
		api.request("categorias/load_categorias", "admin/categories", "get");
	},
	getStoresByCategoriesIds(state, categories){
		let fields = [
			"store_id"
		];
		const categories_ids = [];
		categories.forEach(item => {
			categories_ids.push(item.id);
		});

		const filtros = [
			{
				field: "_id",
				operator: "in",
				value: categories_ids
			}
		];		

		return api.request("produtos/data_valores_loaded", "admin/categories", "post", { fields: fields, "filters": filtros, "sort": "created_at,asc", "limit": 999999, "page": 1 }).then(response => {
			try {
				if(response.body.return_code == 1){
					const retorno = [];
					response.body.data.items.forEach(item => {
						if(!retorno.includes(item.store_id)){
							retorno.push(item.store_id);
						}
					});
					return retorno;
				}else{
					return [];
				}
			}catch {
				return [];
			}
		});
	},
	load_filtros_data() {},
	load_categories_by_store(state, store_id) {
		return api.request("categorias/load_categories_by_store", "admin/categories", "get", {}, parseInt(store_id));
	},
	/**
	 * Default action used by the `Listagem` component. It toggles the `selected` state of a category.
	 *
	 * @param state - The vuex global state.
	 * @param categoryId - the selected category id.
	 *
	 * @todo migrate it to a local component state.
	 */
	select_listagem({ state }, categoryId) {
		let select = state.selecionados;

		// remover dos selecionados
		if (select.find(item => item === categoryId)) {
			select.splice(select.indexOf(categoryId), 1);
			this.commit("categorias/remove_category_report", categoryId);
		} else {
			// incluir dos selecionados
			select.push(categoryId);
			this.dispatch("reports_sales/getCategorySalesReport", categoryId);
		}

		state.selecionados = select;
	},
	/**
	 * Default action used by the `Listagem` component. It selects all the items in the list.
	 *
	 * @param state
	 * @param ids
	 */
	select_listagem_all({ state }, ids){
		state.selecionados = [];
		ids.forEach(id => this.dispatch("categorias/select_listagem", id));
	},
};