<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Comentário
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<div class="form-group required"> 
									<label class="mb-1">Post <i class="fas fa-asterisk"></i></label>
									<MultiSelect state="blog_comments" tipo="posts" :dynamic=true dynamic_action="blog/filter_blog_data" :dados="[]" :field_required=false :multiple=false :required=true :excluir_validation=false></MultiSelect>
								</div>
							</div>		
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.customer.name" :slug_function=false />
							</div>						
							<div class="col-6">
								<FormInput :required=true rules="string" label="E-mail" placeholder="" info="" v-model="model.customer.email" :slug_function=false />
							</div>						
							<div class="col-12">
								<FormTextarea :required=true label="Comentário" v-model="model.comment" :rows=6 placeholder="Digite aqui o depoimento" info="" type="textarea" />
							</div>
							<div class="col-12">
								<FormTextarea :required=false label="Resposta" v-model="model.answer" :rows=6 placeholder="Digite aqui a resposta da loja" info="" type="textarea" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1, label: 'Mundo da Carabina'}, {id: 2,label: 'Aventura & Cia'}, {id: 3,label: 'Lexo'}]" v-model="model.store_id"  type="number" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "../../../common/validation";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormTextarea from "./../../common/form/Textarea";
import MultiSelect from "./../../common/MultiSelect";



export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormTextarea,
		MultiSelect,
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.blog_comments.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Comentários",
				chamada: "Gerencie os comentários do blog da sua loja",
				links: {
					back: "blog.comments.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "blog.comments",
					link: "/blog/comments"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
			],
			disabled_items: [],
			hidden_items: [],
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			

				let model = Object.assign({},this.model);				

				model.blog_post_id = model.posts.id;
				delete model.posts;

				// salva
				this.$store.dispatch("blog_comments/save", model);
			}
		}		
	},
	provide: function () {
		return {
			save: this.save,
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("blog_comments/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("blog_comments/load", this.$route.params.id).then(response => {
				if(response.body.return_code == 1){					
					// pega produto
					self.$store.dispatch("blog/load", response.body.data.blog_post_id).then(retorno => {
						if(retorno.return_code == 1){
							self.$store.commit("blog_comments/multiselect_push", {item: {
								id: retorno.data.id,	
								name: retorno.data.title,
							}, tipo: "posts", multiple: false});
						}						
					});
				}				
			});
		}
	},
	watch: {
		
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
