<template>
	<div class="row">		
		<div class="col-12">
			<div class="departamentos">
				<!-- Inicio Departamento -->
				<draggable 
						handle=".order"
						:group="{ name: 'n1', pull: false, put: false }"
						ghost-class="ghost"
						:class="''"
						:sort="true"
						:tag="'div'"
						@end="sortable_update($event,'n1')"					
						v-bind="dragOptions"
					>
					<div class="departamento" v-for="(n1,i) in menu" :key=n1.tid >
						<div class="input-group mb-2">
							<div class="input-group-prepend">
								<button class="btn btn-secondary" type="button" v-on:click="openList(n1)"><i class="fas fa-angle-up" v-if="n1.open"></i><i class="fas fa-angle-down" v-else></i></button>
							</div>
							<input type="text" class="form-control order" v-model="n1.label" :disabled="!n1.edit" v-on:keyup.enter="edit(n1,i)" >
							<input type="text" class="form-control" v-model="n1.link" v-if="n1.edit" v-on:keyup.enter="edit(n1,i)" >
							<div class="input-group-append" v-if="permissao.editar == 1">
								<button class="btn" v-bind:class="(n1.edit) ? 'btn-success' : 'btn-warning'" type="button" v-on:click="edit(n1,i)"><i class="fas" v-bind:class="(n1.edit) ? 'fa-check' : 'fa-edit'" ></i></button>								
								<button class="btn btn-danger" type="button" v-on:click="remove('n1',i)"><i class="fas fa-times"></i></button>
							</div>
						</div>								
						<div class="categorias" v-show="n1.open">
							<draggable 
								handle=".order"
								:group="{ name: 'n2', pull: false, put: false }"
								ghost-class="ghost"
								:class="''"
								:sort="true"
								:tag="'div'"
								@end="sortable_update($event,'n2',i)"					
								v-bind="dragOptions"
							>						
								<!-- inicio Categoria -->
								<div class="categoria" v-for="(n2,j) in n1.children" :key=n2.tid>
									<div class="input-group mb-2" v-show="n1.open">
										<div class="input-group-prepend">														
											<button class="btn btn-secondary" type="button" v-on:click="openList(n2)"><i class="fas fa-angle-up" v-if="n2.open"></i><i class="fas fa-angle-down" v-else></i></button>
										</div>
										<input type="text" class="form-control order" v-model="n2.label" :disabled="!n2.edit" v-on:keyup.enter="edit(n2,i,j)" >
										<input type="text" class="form-control" v-model="n2.link" v-if="n2.edit" v-on:keyup.enter="edit(n2,i,j)" >
										<div class="input-group-append" v-if="permissao.editar == 1">
											<button class="btn" v-bind:class="(n2.edit) ? 'btn-success' : 'btn-warning'" type="button" v-on:click="edit(n2,i,j)"><i class="fas" v-bind:class="(n2.edit) ? 'fa-check' : 'fa-edit'" ></i></button>
											<button class="btn btn-danger" type="button" v-on:click="remove('n2',i,j)"><i class="fas fa-times"></i></button>
										</div>
									</div>	
									<div class="subcategorias" v-show="n2.open">
										<draggable 
											handle=".order"
											:group="{ name: 'n3', pull: false, put: false }"
											ghost-class="ghost"
											:class="''"
											:sort="true"
											:tag="'div'"
											@end="sortable_update($event,'n3',i,j)"					
											v-bind="dragOptions"
										>	
											<!-- inicio subcategoria -->
											<div class="subcategoria" v-for="(n3,k) in n2.children" :key=n3.tid>
												<div class="input-group mb-2">
													<input type="text" class="form-control order" v-model="n3.label" :disabled="!n3.edit" v-on:keyup.enter="edit(n3,i,j,k)" >
													<input type="text" class="form-control" v-model="n3.link" v-if="n3.edit" v-on:keyup.enter="edit(n3,i,j,k)" >
													<div class="input-group-append" v-if="permissao.editar == 1">
														<button class="btn" v-bind:class="(n3.edit) ? 'btn-success' : 'btn-warning'" type="button" v-on:click="edit(n3,i,j,k)"><i class="fas" v-bind:class="(n3.edit) ? 'fa-check' : 'fa-edit'" ></i></button>
														<button class="btn btn-danger" type="button" v-on:click="remove('n3',i,j,k)"><i class="fas fa-times"></i></button>
													</div>
												</div>	
											</div>
										</draggable>
										<!-- Nova subcategoria -->
										<div class="alert alert-info mb-2" role="alert">
											Para adicionar um item neste nível do menu, digite abaixo o nome que irá aparecer no site e o link que irá direcionar o clique do cliente.
										</div>
										<div class="input-group mb-2" v-if="permissao.criar == 1">
											<input type="text" class="form-control" placeholder="Digite o nome" value="" v-on:keyup.enter="novo($event,'n3',i,j)" >
											<input type="text" class="form-control" placeholder="Digite o link" value="" v-on:keyup.enter="novo($event,'n3',i,j)" >
											<div class="input-group-append">
												<button class="btn btn-success" type="button" v-on:click="novo($event,'n3',i,j)"><i class="fas fa-plus"></i></button>
											</div>
										</div>										
									</div>
								</div>
							</draggable>
							<!-- Nova Categoria -->						
							<div class="alert alert-info mb-2" role="alert">
								Para adicionar um item neste nível do menu, digite o nome que irá aparecer no site e o link que irá direcionar o clique do cliente.
							</div>
							<div class="input-group mb-2" v-if="permissao.criar == 1">
								<input type="text" class="form-control" placeholder="Digite o nome" value="" v-on:keyup.enter="novo($event,'n2',i)" >
								<input type="text" class="form-control" placeholder="Digite o link" value="" v-on:keyup.enter="novo($event,'n2',i)" >
								<div class="input-group-append">
									<button class="btn btn-success" type="button" v-on:click="novo($event,'n2',i)"><i class="fas fa-plus"></i></button>
								</div>
							</div>							
						</div>						
					</div>
				</draggable>
				<!-- Novo Departamento -->
				<div class="alert alert-info mb-2" role="alert">
					Para adicionar um item neste nível do menu, digite o nome que irá aparecer no site e o link que irá direcionar o clique do cliente.
				</div>
				<div class="input-group mb-2" v-if="permissao.criar == 1">										
					<input type="text" class="form-control" placeholder="Digite o nome" value="" v-on:keyup.enter="novo($event,'n1')" >
					<input type="text" class="form-control" placeholder="Digite o link" value="" v-on:keyup.enter="novo($event,'n1')" >
					<div class="input-group-append">
						<button class="btn btn-success" type="button" v-on:click="novo($event,'n1')"><i class="fas fa-plus"></i></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Alerts from "../../common/alerts";
import draggable from "vuedraggable";

export default {
	name: "Menus",
	props: {
		permissao: {
			type: Object,
			required: true
		}
	},
	components: {
		draggable
	},
	computed: {
		menu(){
			return this.ordenaMenu(this.$store.state.menus.model.menu);
		},
		dragOptions() {			
			return {
				animation: 200,
				disabled: false, // se existir marca como false, se não existir marca como true
				ghostClass: "ghost"
			};
		}		
	},
	data () {
		return {
			n1: {
				name: "",
				link: ""
			},
			n2: {
				name: "",
				link: ""
			},
			n3: {
				name: "",
				link: ""
			}
		};
	},  
	mounted() {
	},
	methods: {		
		validaModel: function(model){
			// validação
			// label vazio
			if(model.name == ""){
				Alerts.alertTop("error","Informe um nome para salvar esse item.");
				return {
					is_valid: false					
				};
			}
			// link vazio
			if(model.link == ""){
				Alerts.alertTop("error","Informe um link para salvar esse item.");
				return {
					is_valid: false
				};
			}
			// corrigindo o link
			// verificando se não começa o link com /
			if(!model.link.startsWith("/")){
				// verificando se é um link com http
				if(model.link.substr(0,4) == "http"){
					return {
						is_valid: true
					};		
				}
				// verificando se é um link que inicia com www
				if(model.link.substr(0,3) == "www"){
					return {
						is_valid: true,
						link: "https://"+model.link
					};		
				}
				
				return {
					is_valid: true,
					link: "/"+model.link
				};
			}

			// se não pegar nenhuma validação, ele apenas retorna true
			return {
				is_valid: true
			};
		},
		novo: function(ev,tipo,index_n1,index_n2){
			let div;
			if(ev.type == "click"){
				div = ev.currentTarget.parentNode.parentNode;
			}else{
				div = ev.currentTarget.parentNode;
			}
			let inputs = div.querySelectorAll("input"); 
			let model = {
				name: inputs[0].value,
				link: inputs[1].value
			};
			
			let menu = Object.assign([],this.menu);			

			// validação
			let validacao = this.validaModel(model);
			if(!validacao.is_valid){
				return;
			}else if(validacao.hasOwnProperty("link")){
				model.link = validacao.link;
			}

			let children = [];
			if(index_n2 != undefined){
				children = menu[index_n1].children[index_n2].children;
			}else if(index_n1 != undefined){
				children = menu[index_n1].children;				
			}else{
				children = menu;
			}

			// pegando o tid			
			let tid = children.reduce( function( prevVal, elem ) {
				if(elem.tid >= prevVal){						
					return prevVal + 1;
				}				
			}, 1 );
			// pegando o order
			let order = children.reduce( function( prevVal, elem ) {
				if(elem.order >= prevVal){						
					return prevVal + 1;
				}
			}, 1 );
			children.push({
				label: model.name,
				link: model.link,
				tid: tid,
				order: order,
				children: []
			});

			this.$store.commit("menus/updateMenu",menu);

			// zerando os inputs
			inputs[0].value = "";
			inputs[0].focus();
			inputs[1].value = "";

		},
		edit: function(item,index_n1,index_n2,index_n3){
			item.edit = !item.edit;
			this.update(item,index_n1,index_n2,index_n3);			
		},	
		update: function(item,index_n1,index_n2,index_n3){		
			// validação
			let validacao = this.validaModel(item);
			if(!validacao.is_valid){
				return;
			}else if(validacao.hasOwnProperty("link")){
				item.link = validacao.link;
			}
			let menu = Object.assign([],this.menu);			
			if(index_n3 != undefined){
				menu[index_n1].children[index_n2].children[index_n3] = item;
			}else if(index_n2 != undefined){
				menu[index_n1].children[index_n2] = item;
			}else{
				menu[index_n1] = item;
			}			
			
			this.$store.commit("menus/updateMenu",menu);
		},
		openList: function(item,index_n1,index_n2,index_n3){
			item.open = !item.open;
			this.update(item,index_n1,index_n2,index_n3);
		},		
		sortable_update: function(obj,tipo,n1,n2){
			let menu = Object.assign([],this.menu);
			let children = [];

			// pegando a parte do menu para trabalhar
			switch(tipo){
			case "n1":
				children = menu;
				break;
			case "n2":
				children = menu[n1].children;				
				break;
			case "n3":
				children = menu[n1].children[n2].children;
				break;
			}

			children.splice(obj.newIndex, 0, children.splice(obj.oldIndex, 1)[0]);

			// reordenando
			let order = 1;
			children.forEach(item => {
				item.order = order;
				order++;
			});

			// atualizando o menu
			this.$store.commit("menus/updateMenu",menu);
		},
		remove: function(tipo,n1,n2,n3){
			let menu = Object.assign([],this.menu);
			let children = [];

			switch(tipo){
			case "n1":
				menu.splice(n1,1);
				children = menu;				
				break;
			case "n2":
				menu[n1].children.splice(n2,1);
				children = menu[n1].children;				
				break;
			case "n3":
				menu[n1].children[n2].children.splice(n3,1);
				children = menu[n1].children[n2].children;				
				break;
			}

			// reordenando
			let order = 1;
			children.forEach(item => {
				item.order = order;
				order++;
			});

			// atualizando o menu
			this.$store.commit("menus/updateMenu",menu);

		}
	},
	inject: {
		ordenaMenu: {
			default: () => {}
		}
	},	
	watch: {
		
	}
};
</script>

<style scoped lang="scss">
	.departamentos{
		.departamento{
			.categorias{
				border-left: 1px dashed #d5d5d5;
				padding-left: 1.1em;
				margin-left: 1.1em;
				.categoria{
					.subcategorias{
						border-left: 1px dashed #d5d5d5;
						padding-left: 1.1em;
						margin-left: 1.1em;
					}
				}
				
			}
		}
	}
	input.filtered{
		background-color: $color_yellow;
		border-color: $color_yellow;
	}
	.form-check-input{
		margin-top: 0!important;
	}
</style>
