export default {
	init: function() {
		return {
			model: {
				id: null,
				name: null,
				parent_id: 0,
				description: "",
				level: 1,
				order: 0,
				status: 1,
				store_id: 0,
				open: false,
				metatags: {
					title: null,
					description: null,
					keywords: null
				},
				slug: "",
				attributes: [],
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "name",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			table_fields: {
				filtros: [],
				campos: []
			},
			valores: {
				items: []
			},
			categorias: [],
			// categorias selecionados
			selecionados: [],
			categoriesReports: []
		};
	}
};
