import { ootzMaskMoney } from "./utils";
export default {
	loader: (opcao) => {
		if(opcao === undefined){
			opcao = false;
		}
		let elem_mascote = document.getElementById("loader_mascote");
		if(elem_mascote != null && elem_mascote != undefined){
			let elem = document.getElementById("loader");		
			if(!opcao){
				elem_mascote.classList.remove("open");
				elem.classList.remove("open");
			}else{
				elem_mascote.classList.add("open");
				elem.classList.add("open");
			}
		}		
	},
	alertTop: (type,msg,timeout) => {
		// se não passado o timeout, define o padrão
		if(timeout === undefined){
			timeout = 5000;
		}
		// pega o body
		let body = document.getElementsByTagName("body")[0];
		// cria o elemento div do alerta
		let alerta = document.createElement("div");		
		// aplica a classe do elemento para CSS
		alerta.classList.add("alertTop");		
		// para cada type, inclue um icone diferente
		let type_icon = "";		
		switch(type){
		case "success":
			alerta.classList.add("success");
			type_icon = "<i class=\"fas fa-check-square\"></i>";
			break;
		case "alert":
			alerta.classList.add("alert");
			type_icon = "<i class=\"fas fa-bell\"></i>";
			break;	
		case "error":
			alerta.classList.add("error");
			type_icon = "<i class=\"fas fa-exclamation-triangle\"></i>";
			break;			
		}
		// inclue um botão de remover o alerta
		let icon_remove = document.createElement("span");
		icon_remove.classList.add("fas");
		icon_remove.classList.add("fa-times");		
		// aplica o evento para remover no click
		icon_remove.addEventListener("click", (elem) => {
			elem.target.parentElement.remove();
		});
		// inclue a mensagem e o íconde
		alerta.innerHTML = type_icon+" "+msg;
		// aplica o icone de remover
		alerta.appendChild(icon_remove);
		// aplica o timeout
		setTimeout(() => { 
			icon_remove.parentElement.remove();
		}, timeout);
		// joga na tela
		body.append(alerta);
	},
	alertConfirm(dados,event_confirm){
		if(typeof dados == "string"){
			dados = {
				title: dados
			};
		}
		// pega o body
		let body = document.getElementsByTagName("body")[0];
		// cria o elemento modal fade show
		let modal = document.createElement("div");		
		modal.classList.add("modal","fade","show");		
		// cria o elemento modal-dialog modal-dialog-centered
		let modal_dialog = document.createElement("div");		
		modal_dialog.classList.add("modal-dialog","modal-dialog-centered");	
		// cria o elemento modal-content
		let modal_content = document.createElement("div");		
		modal_content.classList.add("modal-content");
		// cria o elemento modal-header
		let modal_header = document.createElement("div");		
		modal_header.classList.add("modal-header");
		modal_header.innerHTML = "<h5 class=\"modal-title\" id=\"exampleModalLabel\">"+dados.title+"</h5>";
		let modal_body = null;
		if(dados.body != null){
			// cria o elemento modal-body
			modal_body = document.createElement("div");		
			modal_body.classList.add("modal-body");		
			// montando o body
			dados.body.forEach(item => {
				switch(item.type){
				case "label":
					var label = document.createElement("label");						
					label.innerHTML = item.text;
					modal_body.appendChild(label);
					break;
				case "p":
					var p = document.createElement("p");		
					p.classList.add("my-2");
					p.innerHTML = item.text;
					modal_body.appendChild(p);
					break;
				case "select":
					var select = document.createElement("select");		
					select.classList.add("form-control","my-2");
					select.addEventListener("change", (elem) => {
						item.event(elem.target.value);					
					});		
					// opção vazia
					var option = document.createElement("option");
					option.setAttribute("value","");
					option.innerHTML = "Selecione";
					select.appendChild(option);				
					// opções para selecionar
					item.options.forEach(option => {
						var _option = document.createElement("option");
						_option.setAttribute("value",option.id);
						_option.innerHTML = option.name;
						select.appendChild(_option);
					});
					if(item.value){
						select.value = item.value;
					}
					modal_body.appendChild(select);
					break;
				case "input_price":
					var input = document.createElement("input");	
					input.classList.add("form-control","my-2");					
					input.value = ootzMaskMoney(item.value,true);
					input.addEventListener("change", (elem) => {
						item.event(ootzMaskMoney(elem.target.value,false));					
					});		
					input.addEventListener("keyup", (elem) => {
						let valor = elem.target.value;						
						elem.target.value = ootzMaskMoney(valor,true);					
					});							
					modal_body.appendChild(input);
					break;
				case "input_password":
					var _input = document.createElement("input");	
					_input.classList.add("form-control","my-2");					
					_input.value = "";
					_input.type = "password";
					_input.addEventListener("change", (elem) => {
						item.event(elem.target.value);					
					});		
					_input.addEventListener("keyup", (elem) => {
						let valor = elem.target.value;						
						elem.target.value = valor;
					});							
					modal_body.appendChild(_input);
					break;
				}				
			});
		}
		
		// cria o elemento modal-footer
		let modal_footer = document.createElement("div");
		modal_footer.classList.add("modal-footer");		
		if(!dados.close) {
			// criando o botão cancelar
			let button_cancel = document.createElement("button");
			button_cancel.classList.add("btn");
			button_cancel.classList.add("btn-cancel");
			button_cancel.classList.add("btn-sm");
			button_cancel.innerText = "Cancelar";		
			button_cancel.addEventListener("click", (elem) => {
				elem.target.parentElement.parentElement.parentElement.parentElement.remove();
			});		
			modal_footer.appendChild(button_cancel);
			// criando o botão de confirmar
			let button_confirm = document.createElement("button");
			button_confirm.classList.add("btn");
			button_confirm.classList.add("btn-confirm");
			button_confirm.classList.add("btn-sm");
			button_confirm.innerText = "Confirmar";
			// aplica o evento para remover no click
			button_confirm.addEventListener("click", (elem) => {
				if(event_confirm()){
					elem.target.parentElement.parentElement.parentElement.parentElement.remove();
				}			
			});		
			modal_footer.appendChild(button_confirm);
		}else{
			// criando o botão fechar
			let button_cancel = document.createElement("button");
			button_cancel.classList.add("btn");
			button_cancel.classList.add("btn-confirm");
			button_cancel.classList.add("btn-sm");
			button_cancel.innerText = "Fechar";		
			button_cancel.addEventListener("click", (elem) => {
				elem.target.parentElement.parentElement.parentElement.parentElement.remove();
			});		
			modal_footer.appendChild(button_cancel);
		}
		

		// montando o dialog para exibir na tela
		modal_content.appendChild(modal_header);
		if(modal_body != null){
			modal_content.appendChild(modal_body);
		}		
		modal_content.appendChild(modal_footer);
		modal_dialog.appendChild(modal_content);
		modal.appendChild(modal_dialog);

		// joga na tela
		body.append(modal);
	},
};