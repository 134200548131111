import { render, staticRenderFns } from "./Combos.vue?vue&type=template&id=661d126c&scoped=true&"
import script from "./Combos.vue?vue&type=script&lang=js&"
export * from "./Combos.vue?vue&type=script&lang=js&"
import style0 from "./Combos.vue?vue&type=style&index=0&id=661d126c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661d126c",
  null
  
)

export default component.exports