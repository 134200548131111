import api from "./../../common/api";
import model from "./../models/reports-inventory";
import config from "./../../assets/json/filters";

export default {
	//form
	reset(store) {
		store.commit("reset", model.init());
	},
	//lista
	// faz o load do filters.json
	load_filtros_data: (state) => {
		state.commit("data_filtros_loaded", config.reports_inventory);		
	},
	// faz o load dos registros do banco na listagem
	load_valores_data: (state) => { 
		state.commit("clear_valores");
		return state.dispatch("lista_filter_update", state.state.search_data);
	},
	lista_filter_update: function(context, data) {       		
                
		let fields = [];
		config.reports_inventory.campos.forEach(item => {
			if(item.hasOwnProperty("field_object")){
				fields.push(item.field+"."+item.field_object);
			}else{
				fields.push(item.field);
			}
		});

		const d_filters = context.state.d_filters;
		let filters = [];

		if(d_filters.sku != ""){
			filters.push({
				field: "sku",
				operator: "=",
				value: d_filters.sku
			});
		}
		if(d_filters.name != ""){
			filters.push({
				field: "name",
				operator: "like",
				value: d_filters.name
			});
		}
		if(d_filters.product_type != ""){
			filters.push({
				field: "product_type",
				operator: "like",
				value: d_filters.product_type
			});
		}
		if(d_filters.months_6 != ""){
			filters.push({
				field: "months_6",
				operator: "=",
				type: "integer",
				value: d_filters.months_6
			});
		}
		if(d_filters.months_12 != ""){
			filters.push({
				field: "months_12",
				operator: "=",
				type: "integer",
				value: d_filters.months_12
			});
		}
		if(d_filters.stock){
			filters.push({
				field: "stock",
				operator: ">",
				type: "integer",
				value: 0
			});
		}
		if(d_filters.discount > 0){
			filters.push({
				field: "discount",
				operator: "=",
				type: "integer",
				value: d_filters.discount
			});
		}
		if(d_filters.store_id > 0){
			filters.push({
				field: "store_id",
				operator: "=",
				type: "integer",
				value: d_filters.store_id
			});
		}
		
		api.request("reports_inventory/data_valores_loaded", "admin/reports/inventory-control", "post", { "filters": filters, "sort": data.opcoes.order_field + "," + data.opcoes.order, "limit": data.opcoes.limit, "page": data.opcoes.page });
        
	},
	// altera a página da listagem
	altera_pagina: (state,pagina) => {
		state.commit("altera_pagina", pagina);		
	},
	// altera o limit da listagem 
	altera_limit: (state,limit) => {
		state.commit("altera_limit", limit);		
	},
	// faz a marcação dos itens da listagem para ações em massa
	select_listagem: (state,id) => {
		state.commit("select_listagem",id);		
	},
	// [em massa] faz a marcação dos itens da listagem para ações em massa
	select_listagem_all: (state,ids) => {
		state.commit("select_listagem_all",ids);		
	},	
	sort_listagem: (state,field) => {
		state.commit("sort_listagem",field);		
	},
	get_filtros_salvos: (state) => {
		state.commit("get_filtros_salvos",[]);		
	}
};