<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais da Página
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Título" placeholder="" info="" v-model="model.name" :slug_function=true />
							</div>			
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1, label: 'Mundo da Carabina'}, {id: 2,label: 'Aventura & Cia'}, {id: 3,label: 'Lexo'}]" v-model="model.store_id"  type="number" />	
							</div>						
							<div class="col-12">
								<FormTextarea state="paginas_especiais" upload_type="editor_special" :required=false label="Descrição" v-model="model.description" :rows=3 placeholder="Digite aqui um texto sobre essa página" info="" type="editor" />
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Slug" placeholder="" info="" position="left" text="https://*.com.br" v-model="model.slug" />
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Metatags -->
				<div class="hide card card-base">
					<div class="card-header">
						MetaTags
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Metatags v-model="model.metatags"></Metatags>
						<div class="row">
							<div class="col-12">
								<FormInput :required=false rules="string" label="Canonical Link" placeholder="" info="Informe o link completo com https" v-model="model.canonical" />
							</div>	
						</div>	
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Filtros -->
				<div class="hide card card-base">
					<div class="card-header">
						Filtros
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-12">
								<div class="form-group mb-0"> 
									<label class="mb-1">Contém a Palavra</label>
									<MultiSelect state="paginas_especiais" tipo="filters_in" :dados="[]" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true :clear_on_enter=true></MultiSelect>
								</div>
							</div>
							<div class="col-12 mt-2">
								<div class="form-group mb-0"> 
									<label class="mb-1">Não Contém a Palavra</label>
									<MultiSelect state="paginas_especiais" tipo="filters_notin" :dados="[]" :required=false :field_required=false :multiple=true :insert=true :modo_tag=true  :clear_on_enter=true></MultiSelect>
								</div>
							</div>
						</div>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import url_prefix from "./../../../assets/json/urls.json";
import Metatags from "./../../common/MetaTags";
import MultiSelect from "./../../common/MultiSelect";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";
import FormInputGroup from "./../../common/form/InputGroup";
import FormTextarea from "./../../common/form/Textarea";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		FormInputGroup,
		FormTextarea,
		Metatags,
		MultiSelect
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.paginas_especiais.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Páginas Especiais",
				chamada: "Gerencie as páginas especiais da sua loja",
				links: {
					back: "paginas.especiais.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Páginas Especiais",
					link: "/paginas/especiais"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"MetaTags",
				"Filtros"
			],
			disabled_items: [],
			hidden_items: [],
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				let model = this.model;

				// tratando filters in
				model.filters.in = [];
				model.filters_in.forEach(item =>{
					model.filters.in.push(item.name);
				});
				delete model.filters_in;
				// tratando filters not in
				model.filters.notin = [];
				model.filters_notin.forEach(item =>{
					model.filters.notin.push(item.name);
				});
				delete model.filters_notin;

				// salva
				this.$store.dispatch("paginas_especiais/save", model);
			}
		},
		toSlug: function(){
			if(this.model.slug == ""){
				this.model.slug = url_prefix.special_page + Slug(this.model.name);
			}			
			if(this.model.metatags && this.model.metatags.title == null){
				this.model.metatags.title = this.model.name;
			}
		},
		updateDadosMultiselect: function(){
			// no caso do páginas especiais
		},
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			updateDadosMultiselect: this.updateDadosMultiselect
		};
	},
	created() {
		let self = this;
		this.$store.dispatch("paginas_especiais/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("paginas_especiais/load", this.$route.params.id).then(response => {

				if(response.return_code == 1){
					let model = response.data;
				
					// tratando filters in
					model.filters_in = [];
					model.filters.in.forEach(item =>{
						model.filters_in.push({ id: null, name: item });
					});					
					// tratando filters not in
					model.filters_notin = [];
					model.filters.notin.forEach(item =>{
						model.filters_notin.push({ id: null, name: item });
					});			

					response.data = model;
					
					self.$store.commit("paginas_especiais/loaded",response);
				}
				
			});
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	@import "~@/assets/css/form.scss";
</style>
