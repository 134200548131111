<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 text-center my-4">
				<img src="./../../assets/img/MDC.jpg" />
			</div>
			<div class="col-12" v-if="retorno.alerta == 1"> 
				<div class="alert alert-danger" role="alert">
					{{ retorno.msgRetorno }}
				</div>
			</div>
			<div class="col-12 mb-2">
				<div class="input-group input-group-lg">
					<div class="input-group-prepend">
						<span class="input-group-text icon_user" id="basic-addon1"><i class="fas fa-user"></i></span>
					</div>
					<input type="text" class="form-control" name="login" v-model="model.login" placeholder="Digite seu login" required validation-rules="string">
				</div>				
			</div>			
			<div class="col-12">
				<div class="input-group input-group-lg">
					<div class="input-group-prepend">
						<span class="input-group-text" id="basic-addon1"><i class="fas fa-key"></i></span>
					</div>
					<input type="password" name="senha" class="form-control" v-model="model.password" placeholder="Digite sua senha" required validation-rules="string" v-on:keyup.enter="login()">
				</div>				
			</div>

			<hr />

			<div class="col-12">
				<button class="btn btn-lg btn-block btn-success mb-3" v-on:click="login()">CLIQUE PARA ENTRAR</button>
			</div>
		</div>
	</div>
</template>

<script>

import storage from "../../storage";
import alerts from "../../common/alerts";
import validation from "../../common/validation";
import api from "../../common/api";

export default {
	name: "Login",
	props: {
		
	},
	data () {
		return {
			model: {
				login : null,
				password: null
			},
			url_redirect: null,
			retorno: {
				msgRetorno: null,
				alerta: 0
			}
		};
	},  
	created() {
		
	},
	mounted() {
		let self = this;			

		if(this.$route.query.expired){
			self.retorno.msgRetorno = "Seu acesso expirou! Faça o login novamente.";
			self.retorno.alerta = 1;
		}
		if(this.$route.query.invalid){
			self.retorno.msgRetorno = "Seu token expirou! Faça o login novamente.";
			self.retorno.alerta = 1;
		}

		this.url_redirect = this.$route.query.redirect;		
		
	},
	methods: {		
		validaLogin() {
			let usuario = storage.getObject("usuario");

			if(usuario) {
				this.$store.dispatch("app/login", usuario);
				this.logged();
			}
		},
		login: function() {
			// fazer validação se o cliente já está logado,
			// se tiver retorna true
			// se não tiver retorna false para proibir de esconder o modal

			// exibe o loader
			alerts.loader(true);

			if(validation.validation().valid){
				api.login(this.model).then((response) => {
					validation.reset();
					// esconde o loader
					alerts.loader(false);
					if(response.body.return_code == 1) {
						this.logged();
					} else {
						let elem;
						if(response.data.data.errors.error_message.startsWith("Login")){
							elem = document.getElementsByName("login")[0];
						}else{
							elem = document.getElementsByName("senha")[0];
						}
						validation.show([{
							valid: false,
							msg: response.data.data.errors.error_message,
							elem: elem
						}]);
					}
				});
			}else{
				alerts.loader(false);
			}
			
		},
		logged: function() {
			if(this.url_redirect != undefined){
				window.location.href = this.url_redirect;
			}else{
				this.$router.push({
					name: "dashboard"
				});
			}				
		}
	}
};
</script>

<style scoped lang="scss">	
	.container-fluid{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background: #666666;
		.row{
			background: #FFF;
			width: 35em;			
			margin: 2em auto 0 auto;
			border-radius: 5px;
			hr{
				width: 100%;
				margin: 1em 0;
			}
			.icon_user{
				min-width: 2.7em;
				text-align: center;
				display: block;
			}
		}
	}
</style>
