<template>
	<div>		
		<FormTextarea :required=true label="Nova Observação" v-model="note" :rows=3 placeholder="Digite aqui a observação desejada" info="" v-if="!disabled" />
		<div class="text-right" v-if="!disabled" >
			<button class="btn btn-success" v-on:click="addNote()">Salvar Observação</button>
		</div>
		<template v-if="notes.length">
			<div class="note p-3 my-2" v-for="(note,i) in notes" :key=i>
				<p class="mb-1">{{ note.message }}</p>
				<i>em {{ note.created_at | date_time }}, por {{ note.user.name }}</i>				
			</div>
		</template>		
	</div>	
</template>

<script>

import FormTextarea from "./form/Textarea";
import Alerts from "./../../common/alerts";
import Filters from "./../../common/filters";

export default {
	name: "Notes",
	components: {
		FormTextarea,
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		notes() {
			return this.$store.state[this.state].notes;
		},
	},	
	props: {
		state: {
			type: String,
			default: "",
			required: true
		},
		id: {
			type: String,
			default: "",
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	filters: Filters,
	data () {
		return {
			note: ""
		};
	},  
	created() {
	},
	methods: {		
		addNote() {
			if(this.disabled){
				Alerts.alertTop("error","Você não tem permissão para inserir uma observação.");
				return;
			}
			let self = this;
			if(this.note != ""){
				this.$store.dispatch(this.state+"/add_note", {note: this.note, id: this.id}).then(response => {
					if(response.body.return_code == 1){
						self.note = "";
						Alerts.alertTop("success","Observação gravada com sucesso");
					}
				});
			}else{
				Alerts.alertTop("error","Informe uma observação para salvar.");
			}			
		}
	}
};
</script>

<style scoped lang="scss">	
	.note {
		border: 1px solid $border_light;
		border-radius: 5px;
		background: $bg_clean;		
		i {
			font-size: 0.8em;
			background: $bg_gray;
		}
	}
</style>
