<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		
		

		<div class="row formulario-dados">
			<div class="col-6 my-3">					
				<div class="card card-view card-base">
					<div class="card-header">
						Configurações de busca do relatório
					</div>
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Período Inicial" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="initial_date" :index=0 />
							</div>
							<div class="col-6">
								<FormInputGroup :required=true rules="string" label="Período Final" placeholder="" info="" position="left" text="" icon="fas fa-calendar-alt" type="date" v-model="final_date" :index=1 />
							</div>							
							<div class="col-12">
								<button class="btn btn-block btn-success" v-on:click="filtrar()">Filtrar Dados</button>
							</div>
						</div>						
					</div>	
				</div>
			</div>

			<div class="col-6 my-3" v-if="report.products_cost">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações Gerais						
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Receita Produtos (Preço Prazo):</strong> {{ report.products_sale | money }}</li>
							<li><strong>Custo Produtos:</strong> {{ report.products_cost | money }}</li>
							<li><strong>Markup Médio (Preço Prazo):</strong> {{ getMarkup() | milhar }}</li>
							<li><strong>Receita Frete Lojas:</strong> {{ report.total_shipping | money }}</li>
							<li><strong>Custo Frete Lojas:</strong> {{ report.total_shipping_cost | money }}</li>
							<li><strong>Custo Frete Grátis:</strong> {{ getShippingPercent() | milhar }}%</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 my-3" v-if="report.stores">
				<div class="card card-view card-base">
					<div class="card-header">
						Venda Lojas x Marketplaces
					</div>
					<div class="card-body">
						<table width="100%">							
							<col width="20%" />
							<col width="15%" />
							<col width="15%" />
							<col width="7%" />
							<col width="18%" />
							<col width="18%" />
							<col width="7%" />							
							<tr class="cabecalho">
								<th class="align-middle">Loja</th>
								<th class="align-middle">Pedidos</th>																
								<th class="align-middle">Pedidos Pagos</th>
								<th class="center align-middle">%</th>
								<th class="align-middle">Faturamento</th>
								<th class="align-middle">Faturamento Pago</th>
								<th class="center align-middle">%</th>
							</tr>
							<tr v-for="(store,i) in report.stores" :key=i>
								<td class="titulo align-middle">{{ i | storeSlug }}</td>
								<td class="align-middle">{{ store.orders | milhar }}</td>
								<td class="align-middle">{{ store.orders_paid | milhar }}</td>
								<td class="center align-middle">{{ getOrdersxOrdersPaid(store.orders, store.orders_paid) | milhar }}%</td>
								<td class=" align-middle">
									{{ store.revenue | money }}
								</td>
								<td class=" align-middle">{{ store.revenue_paid | money }}</td>								
								<td class="center align-middle">{{ getOrdersxOrdersPaid(store.revenue, store.revenue_paid) | milhar }}%</td>
							</tr>							
							<tr class="cabecalho">
								<th class="align-middle">Marketplace</th>
								<th class="align-middle">Pedidos</th>																
								<th class="align-middle">Pedidos Pagos</th>
								<th class="center align-middle">%</th>
								<th class="align-middle">Faturamento</th>
								<th class="align-middle">Faturamento Pago</th>
								<th class="center align-middle">%</th>
							</tr>
							<tr v-for="(marketplace,i) in report.marketplaces" :key=i>
								<td class="titulo align-middle">{{ i | marketplaces }}</td>
								<td class="align-middle">{{ marketplace.orders | milhar }}</td>
								<td class="align-middle">{{ marketplace.orders_paid | milhar }}</td>
								<td class="center align-middle">{{ getOrdersxOrdersPaid(marketplace.orders, marketplace.orders_paid) | milhar }}%</td>
								<td class=" align-middle">
									{{ marketplace.revenue | money }}
								</td>
								<td class=" align-middle">{{ marketplace.revenue_paid | money }}</td>								
								<td class="center align-middle">{{ getOrdersxOrdersPaid(marketplace.revenue, marketplace.revenue_paid) | milhar }}%</td>
							</tr>	
						</table>
					</div>
				</div>
			</div>

			<div class="col-6 my-3" v-if="report.payments">
				<div class="card card-view card-base">
					<div class="card-header">
						Formas de Pagamento
					</div>
					<div class="card-body">
						<table width="100%">							
							<col width="20%" />
							<col width="15%" />
							<col width="7%" />
							<col width="15%" />
							<col width="7%" />													
							<tr class="cabecalho">
								<th class="align-middle">Pagamento</th>
								<th class="align-middle">Quantidade</th>																
								<th class="center align-middle">%</th>			
								<th class="align-middle">Faturamento</th>
								<th class="center align-middle">%</th>								
							</tr>
							<tr v-for="(pay,i) in report.payments" :key=i>
								<td class="titulo align-middle">{{ i | payments }}</td>
								<td class="align-middle">{{ pay.quantity | milhar }}</td>
								<td class="center align-middle">{{ pegaProporcaoPagamento(pay.quantity, "quantity") | milhar }}%</td>
								<td class="align-middle">{{ pay.revenue | money }}</td>
								<td class="center align-middle">{{ pegaProporcaoPagamento(pay.revenue, "revenue") | milhar }}%</td>
							</tr>
						</table>
					</div>
				</div>
			</div>

			<div class="col-6 my-3" v-if="report.shipping">
				<div class="card card-view card-base">
					<div class="card-header">
						Formas de Envio
					</div>
					<div class="card-body">
						<table width="100%">							
							<col width="40%" />
							<col width="20%" />
							<col width="10%" />
							<col width="20%" />
							<col width="10%" />													
							<tr class="cabecalho">
								<th class="align-middle">Transportadora</th>
								<th class="align-middle">Quantidade</th>																
								<th class="center align-middle">%</th>		
								<th class="align-middle">Custo</th>																
								<th class="center align-middle">%</th>								
							</tr>
							<tr v-for="(ship,i) in report.shipping" :key=i>
								<td class="titulo align-middle">{{ i | carrier }}</td>
								<td class="align-middle">{{ ship.quantity | milhar }}</td>
								<td class="center align-middle">{{ pegaProporcaoTransporte(ship.quantity, "quantity") | milhar }}%</td>
								<td class="align-middle">{{ ship.cost | money }}</td>
								<td class="center align-middle">{{ pegaProporcaoTransporte(ship.cost, "cost") | milhar }}%</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import validation from "../../../common/validation";
import Filters from "../../../common/filters";
import moment from "moment";

import FormInputGroup from "./../../common/form/InputGroup";
import alerts from "../../../common/alerts";

export default {
	name: "Dashboard",
	components: {
		Header,
		Breadcrumb,
		FormInputGroup
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		
	},
	filters: Filters,
	data () {
		return {
			header_info: {
				title: "Relatório Geral de Vendas",
				chamada: "Acompanhe as principais informações das vendas dos sites e marketplaces",
				links: {
					
				}
			},
			breadcrumb_info: [
				{
					name: "Relatório Vendas",
					link: "/"
				}
			],
			initial_date: null,
			final_date: null,
			report: {}
		};
	},  
	mounted() {
		const mes_anterior = moment().subtract(1, "M").format("YYYY-MM");
		this.initial_date = mes_anterior+"-01";		
		this.final_date = mes_anterior+"-"+moment(mes_anterior, "YYYY-MM").daysInMonth();
		this.resetReport();
	},
	methods: {		
		filtrar() {
			if(validation.validation().valid){
				this.$store.dispatch("reports_sales/get_report", { initial_date: this.initial_date, final_date: this.final_date }).then(response => {
					if (response.return_code == 1){
						this.updateReport(response.data.items);
					}else{
						alerts.alertTop("error", "Houve um problema na busca, tente novamente");
					}
				});
			}
		}, 
		resetReport() {
			this.report = {};
		},
		getMarkup() {
			return (this.report.products_sale) ? (this.report.products_sale / this.report.products_cost).toFixed(2) : 0;
		},
		getShippingPercent() {			
			if(this.report.total_shipping < this.report.total_shipping_cost){
				const extra_cost = this.report.total_shipping_cost - this.report.total_shipping;
				return ((extra_cost * 100) / this.report.total_store_sales).toFixed(2);
			}else{
				return 0;
			}
		},
		getOrdersxOrdersPaid(value, paidValue) {
			return ((paidValue / value)*100).toFixed(2);
		},
		pegaProporcaoPagamento(value, type) {
			if(type == "quantity"){
				return ((value / this.report.amount_payments)*100).toFixed(2);
			}else{
				return ((value / this.report.total_paid_sales)*100).toFixed(2);
			}				
		},
		getShippingCost(order){
			const shipment = order.shipment;
			let shipping_cost = 0;
			if(order.shipping_real_cost && order.shipping_real_cost > 0){
				shipping_cost = order.shipping_real_cost;
			}else if(shipment.new_price){
				try {
					const result = shipment.log.find(ship => {
						return ship.delivery_time == shipment.new_price.delivery_time && ship.price == shipment.new_price.price;
					});
					shipping_cost = result.price;
				} catch {
					shipping_cost = shipment.price;
				}
			}else if(shipment.type == "free" && shipment.carrier == "motoboy"){
				try {
					const result = shipment.log.find(ship => {
						return ship.name == "Delivery" || ship.name == "Motoboy (Normal)" || ship.name == "Motoboy (Expresso)";
					});
					shipping_cost = result.price;
				} catch {
					shipping_cost = shipment.price;
				}					
			}else{
				shipping_cost = shipment.price;
			}

			return shipping_cost;
		},
		pegaProporcaoTransporte(value, type) {
			if(type == "quantity") {
				return ((value / this.report.total_paid_orders)*100).toFixed(2);
			}else{
				return ((value / this.report.total_shipping_cost_with_marketplace)*100).toFixed(2);
			}
		},
		isPaid(order) {
			const log_paid = order.status_log.find( item => {
				return item.status_type == "paid";
			});

			return log_paid && order.status.type != "canceled";
		},
		updateReport(data) {
			let resume = {
				stores: {
					mundo: {
						orders: 0,
						revenue: 0,
						orders_paid: 0,
						revenue_paid: 0
					},
					aventura: {
						orders: 0,
						revenue: 0,
						orders_paid: 0,
						revenue_paid: 0
					},
					lexo: {
						orders: 0,
						revenue: 0,
						orders_paid: 0,
						revenue_paid: 0
					},
				},
				marketplaces: {},
				total_store_sales: 0,
				total_paid_sales: 0,
				total_paid_orders: 0,
				total_shipping: 0,
				total_shipping_cost: 0,
				total_shipping_cost_with_marketplace: 0,
				amount_payments: 0,
				products_cost: 0,
				products_sale: 0,
				payments: {},
				shipping: {}
			};
					
			data.forEach( item => {				
				let tipo;
				if (item.marketplace.marketplace_id) {
					tipo = "marketplace";
				}else{
					switch(item.store_id){
					case 1:
						tipo = "mundo";
						break;
					case 2:
						tipo = "aventura";
						break;
					case 3:
						tipo = "lexo";
						break;					
					}
				}								
				

				// pegando dados de faturamento separados
				if(tipo == "marketplace"){
					const channel = (item.marketplace.marketplace_channel == "MERCADO_LIVRE_AV") ? "MERCADO_LIVRE" : item.marketplace.marketplace_channel;
					if(!resume.marketplaces[channel]){
						resume.marketplaces[channel] = {
							orders: 0,
							revenue: 0,
							orders_paid: 0,
							revenue_paid: 0
						};
					}

					resume.marketplaces[channel].orders += 1;
					resume.marketplaces[channel].revenue += item.total_order;

					// pegando o resultado de pedidos pagos
					if(this.isPaid(item)){
						resume.marketplaces[channel].orders_paid += 1;
						resume.marketplaces[channel].revenue_paid += item.total_order;
					}
				}else{
					if(!resume.stores[tipo]){
						resume.stores[tipo] = {
							orders: 0,
							revenue: 0
						};
					}
					resume.stores[tipo].orders += 1;
					resume.stores[tipo].revenue += item.total_order;

					if(this.isPaid(item)){
						resume.stores[tipo].orders_paid += 1;
						resume.stores[tipo].revenue_paid += item.total_order;
					}
				}
				
				// a partir daqui são apenas informações sobre pedidos pagos
				if(this.isPaid(item)){

					resume.total_paid_sales += item.total_order;
					resume.total_paid_orders += 1;					

					// calculo de markup
					item.products.forEach( product => {	
						const unit_cost = product.unit_cost || product.cost || 0;
						resume.products_cost += product.quantity * unit_cost;
						resume.products_sale += product.quantity * product.unit_price;
					});
					
					// informações de custos de frete apenas para pedidos das lojas
					if(tipo != "marketplace"){
						resume.total_store_sales += item.total_order;
						resume.total_shipping += item.total_shipping;
						let shipping_cost = this.getShippingCost(item);
						resume.total_shipping_cost += shipping_cost;
					}

					// pegando as formas de pagamentos de pedidos pagos
					item.payments.forEach(pay => {
						let pay_type = pay.type;
						if(pay.type == "credit_card"){
							pay_type = pay.gateway;							
						}
						if(!resume.payments[pay_type]){
							resume.payments[pay_type] = {
								quantity: 0,
								revenue: 0,
							};
						}

						resume.payments[pay_type].quantity += 1;
						resume.amount_payments += 1;
						resume.payments[pay_type].revenue += pay.value;						
					});

					// pegando as transportadoras mais utilizadas
					const carrier = (item.shipment.carrier == "retirada") ? "loja" : item.shipment.carrier;
					if(!resume.shipping[carrier]){
						resume.shipping[carrier] = {
							quantity: 0,
							cost: 0
						};
					}
					resume.shipping[carrier].quantity += 1;
					const _shipping_cost = this.getShippingCost(item);
					resume.shipping[carrier].cost += _shipping_cost;
					resume.total_shipping_cost_with_marketplace += _shipping_cost;
				}
				
			});	
			
			window.console.log(resume);
			this.report = resume;
		}
	}
};
</script>

<style scoped lang="scss">	
	
</style>
