<template>
	<div class="row topo align-items-center py-2">
		<div class="col-1 icon-menu">
			<a href="#" v-on:click.prevent="manipulaMenu()"><i class="fa fa-outdent" v-if="!hide_menu"></i><i class="fa fa-indent" v-else></i></a>
		</div>
		<div class="col-8">
			
		</div>
		<div class="col-3 text-right">
			<router-link class="btn-alerts mr-3" :class="{'active': notifications > 0}" :title="notifications+' Notificações'" :to="{ name: 'alerts.lista' }" ><i class="fas fa-bell"></i></router-link>
			<a href="#" v-on:click.prevent="logout()" title="Sair" class="btn-notificacao logout"><i class="fas fa-door-open"></i></a>
		</div>
	</div>
</template>

<script>

import storage from "./../../storage";

export default {
	name: "Topo",
	data () {
		return {
			notifications: 0
		};
	},  
	created() {
		// let self = this;
		// this.get_notifications();
		// setInterval(function () {
		// 	self.get_notifications();			
		// }, 60000);
	},
	computed: {
		hide_menu() {
			return this.$store.state.app.hide_menu;
		},
		usuario_id() {
			return this.$store.state.app.usuario.id;
		}
	},
	methods: {		
		manipulaMenu: function(){
			let novo_valor;
			if(this.hide_menu){
				novo_valor = false;
			}else{
				novo_valor = true;
			}
			this.$store.commit("app/set_hide_menu",novo_valor);
		},
		logout: function(){
			storage.remove("usuario");
			location.reload();
		},	
		get_notifications: function() {
			let self = this;
			this.$store.dispatch("usuarios/getTotalNotifications", self.usuario_id).then(response => {
				self.notifications = response;
			});
		}	
	},
};
</script>

<style scoped lang="scss">	
	.topo{
		border-bottom: 1px solid $border_light;
		background: #FFF;
		.icon-menu{
			a{
				color: $color_escuro;
				display: block;
				font-size: 1.4em;
				&:hover{
					opacity: 0.8;
				}
			}
		}
		.logout{
			font-size: 2em;
			color: $color_escuro;
		}
		.btn-alerts {
			font-size: 1.8em;
			color: $color_escuro;
			&.active {
				color: $color_red;
			}
			
		}
	}
</style>
