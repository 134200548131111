<template>
  <div class="form-group" v-bind:class="{ required: required }">
    <label class="mb-1">{{ label }} <i class="fas fa-asterisk"></i></label
    ><br />
    <input
      v-if="value == ''"
      type="file"
      :name="'imagem_' + slug"
      :id="'fileSimple_' + slug"
      class="inputFileSimple"
      v-on:change="onFileChange($event)"
      :required="required"
      :validation-rules="rules"
    />
    <label
      v-if="value == ''"
      class="btn btn-primary fileSimple"
      :for="'fileSimple_' + slug"
      >Selecione uma imagem</label
    >
    <div class="input-group mb-3" v-if="value != ''">
      <img :src="getUrlDomain(store_id)+value" width="100" class="img-fluid mr-3" />
      <div class="input-group-append">
        <button class="btn btn-danger" v-on:click="removerImagem()">
          Trocar Imagem
        </button>
      </div>
    </div>
    <small class="form-text text-muted mt-2" v-if="info != ''">{{
      info
    }}</small>
  </div>
</template>

<script>
import api from "./../../../common/api";
import Alerts from "../../../common/alerts";

export default {
	name: "Imagem",
	props: {
		required: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
			default: "",
		},
		info: {
			type: String,
			default: "",
		},
		value: {
			default: "",
		},
		rules: {
			type: String,
			default: "",
		},
		type: {
			type: String,
			required: true,
		},
		store_id: {
			type: Number,
			required: true,
		},
		slug: {
			type: String,
			required: false,
			default: "",
		},
	},	
	data() {
		return {};
	},
	created() {},
	methods: {
		getUrlDomain(store_id) {
			switch(store_id){
			// case 1:
			// 	return "https://imagens.mundodacarabina.com.br";
			// case 2:
			// 	return "https://imagens.aventuraecia.com.br";
			// case 3:
			// 	return "https://imagens.lexo.com.br";
			default:
				return "https://imagens.mundodacarabina.com.br";
			}
		},
		onFileChange(e) {
			if (!this.store_id > 0) {
				Alerts.alertTop(
					"error",
					"Para incluir uma imagem, selecione a loja que ela pertence."
				);
				return false;
			}

			let files = e.target.files || e.dataTransfer.files;
			if (!files.length) return;

			this.createImage(files[0]);
		},
		createImage(file) {
			let self = this;
			let reader = new FileReader();

			reader.onload = () => {
				api.upload(
					"",
					"admin/image",
					"post",
					{ type: self.type, file: file, store_id: self.store_id },
					(response) => {
						if (response.body.return_code == 1) {
							let url = response.body.data.url.replace(this.getUrlDomain(self.store_id),"");
							self.$emit("input", url);
						}
					}
				);
			};

			reader.readAsDataURL(file);
		},
		removerImagem: function () {
			let self = this;
			api.request(
				"",
				"admin/image",
				"delete",
				{ url: this.getUrlDomain(self.store_id)+self.value, store_id: self.store_id },
				null,
				false,
				(response) => {
					if (response.body.return_code == 1) {
						self.$emit("input", "");
					}
				}
			);
		},
	},
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/form.scss";

.inputFileSimple {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputFileSimple + label.fileSimple {
  color: #fff;
}

.inputFileSimple + label.fileSimple:after {
  content: "";
}
</style>
