export default {
	init: function() {
		return {
			model: {
				id: null,
				name: null,
				permissions: [],
				status: 1,
			},
			//lista
			search_data: {
				opcoes : {
					order_field: "name",
					order: "asc",
					limit: 10,
					page: 1
				},
				filtros: [],
				filtro_selecionado: 0
			},
			// variável responsável por armazenar os ids dos registros selecionados na listagem
			selecionados: [],
			// variável responsável por armazenar todas as informações do filters.json relacionados a esse módulo
			table_fields: [],
			// armazena os valores retornados pela busca na listagem
			valores: [],
			// armazena os filtros que estão sendo usados na listagem
			filters: [],
			// armazena os filtros salvos
			saved_filters: [],
			// módulos
			modulos: [
				{
					name: "Configurações",
					slug: "configuracoes",
					children: [
						{
							name: "Atualização em Massa",
							slug: "scale-update"
						},
						{
							name: "Usuários",
							slug: "usuarios"
						},
						{
							name: "Grupos de Usuários",
							slug: "grupos"
						},
						{
							name: "Status dos Pedidos",
							slug: "pedido-status"
						},
						{
							name: "Motivos de Cancelamento",
							slug: "motivos-cancelamento"
						},
						{
							name: "Templates de E-mail",
							slug: "template-email"
						},
						{
							name: "Layout de E-mail",
							slug: "layout-email"
						},
						{
							name: "Rotas",
							slug: "rotas"
						},
						{
							name: "Monitoramento de Erros",
							slug: "monitoring"
						},
						{
							name: "Alertas de Sistema",
							slug: "alerts"
						},
					]
				},
				{
					name: "Catálogo",
					slug: "catalogo",
					children: [
						{
							name: "Tipos de Produtos",
							slug: "produto-tipos"
						},
						{
							name: "Atributos de Produtos",
							slug: "atributos"
						},
						{
							name: "Avaliaçoes de Produtos",
							slug: "reviews"
						},
						{
							name: "Marcas",
							slug: "marcas"
						},
						{
							name: "Categorias",
							slug: "categorias"
						},
						{
							name: "Produtos",
							slug: "produtos"
						},
						{
							name: "Dúvidas de Produtos",
							slug: "produtos-duvidas"
						},
						{
							name: "Edição Rápida de Estoque",
							slug: "estoque-edicao"
						},
						{
							name: "Edição Rápida de Preço",
							slug: "preco-edicao"
						},
						{
							name: "Vitrines",
							slug: "vitrines"
						}
					]
				},
				{
					name: "Layout",
					slug: "layout",
					children: [
						{
							name: "E-mail Marketing",
							slug: "email-marketing"
						},
						{
							name: "Páginas Especiais",
							slug: "paginas-especiais"
						},
						{
							name: "Banners",
							slug: "banners"
						},
						{
							name: "Páginas Adicionais",
							slug: "paginas-adicionais"
						},
						{
							name: "Menus",
							slug: "menus"
						},
						{
							name: "Blog",
							slug: "blog"
						}												
					]
				},
				{
					name: "CRM",
					slug: "crm",
					children: [
						{
							name: "Clientes",
							slug: "clientes"
						},
						{
							name: "Depoimentos",
							slug: "depoimentos"
						},
						{
							name: "Newsletter",
							slug: "newsletter"
						},
						{
							name: "Tickets",
							slug: "tickets"
						},
						{
							name: "Sorteios",
							slug: "raffle"
						}					
					]
				},
				{
					name: "Vendas",
					slug: "vendas",
					children: [
						{
							name: "Promoções de Produtos",
							slug: "promocoes"
						},
						{
							name: "Descontos Progressivos",
							slug: "descontos-progressivos"
						},
						{
							name: "Promoções de Frete",
							slug: "frete-promocoes"
						},
						{
							name: "Cupom de Desconto",
							slug: "cupom-desconto"
						},
						{
							name: "Brindes",
							slug: "brindes"
						},
						{
							name: "Pedidos",
							slug: "pedidos"
						},
						{
							name: "Vouchers",
							slug: "vouchers"
						},
						{
							name: "Expedição",
							slug: "packing"
						},
						{
							name: "Carrinhos Abandonados",
							slug: "carts"
						},						
					]
				},				
				{
					name: "Atendimento",
					slug: "atendimento",
					children: [
						{
							name: "Tickets",
							slug: "tickets"
						}
					]
				},
				{
					name: "Relatórios",
					slug: "relatorios",
					children: [
						{
							name: "Dashboard",
							slug: "dashboard"
						},
						{
							name: "Pedidos",
							slug: "relatorios-pedidos"
						},
						{
							name: "Produtos Fornecedor",
							slug: "relatorios-produtos-fornecedor"
						},
						{
							name: "Formas de Pagamento",
							slug: "relatorios-forma-pagamento"
						},
						{
							name: "Desempenho de Vendas",
							slug: "relatorios-desempenho-vendas"
						},
						{
							name: "Produtos Aguardados",
							slug: "relatorios-produto-aguardados"
						},
						{
							name: "Produtos Favoritos",
							slug: "relatorios-produto-favoritos"
						},
						{
							name: "Marketplaces",
							slug: "relatorios-marketplaces"
						},						
						{
							name: "Vendas",
							slug: "relatorios-sales"
						},
						{
							name: "Vendas por Categoria",
							slug: "relatorios-sales-by-category"
						},
						{
							name: "Vendas Por Produtos",
							slug: "relatorios-products-sales"
						},
						{
							name: "Controle de Estoque e Obsolescência",
							slug: "relatorios-inventario"
						},
						{
							name: "Tarifas Jadlog",
							slug: "relatorios-jadlog"
						}				
					]
				},
			],
		};
	}
};
