<template>
	<div class="row comp my-3 py-2">
		<div class="col-8">
			<ul>
				<li>
					<button class="btn-atualizar mr-2" v-on:click="aplicaFiltro"><i class="fas fa-sync-alt"></i></button>
					<!-- o btn-paginacao tem a classe "ativo" que deixa ele mais escuro para informar que é possível clicar -->
					<button class="btn-paginacao" v-on:click.prevent="paginate('down',paginacao.page > 1)" :class="paginacao.page == 1 ? 'disable' : ''"><i class="fas fa-chevron-left"></i></button>
					<button class="btn-paginacao" v-on:click.prevent="paginate('up',paginacao.total > paginacao.reg_fim)" :class="paginacao.total == paginacao.reg_fim  ? 'disable' : ''"><i class="fas fa-chevron-right"></i></button>
				</li>
				<li class="ml-3">
					<span>{{ paginacao.reg_ini }}</span> - <span>{{ paginacao.reg_fim }}</span> registros de {{ paginacao.total | milhar }} 
				</li>
				<li class="mx-2">|</li>
				<li>Exibir <select v-on:change="alteraLimit" v-model="paginacao.limit"><option :value=10>10</option><option :value=20>20</option><option :value=30>30</option><option :value=50>50</option><option :value=100>100</option><option :value=200>200</option></select> registros por página</li>
			</ul>
		</div>
		<div class="col-4 pt-1 text-right" v-if="config.flags.multi.enable">
			<ul class="selecionados">
				<li>
					Com Selecionados: 
				</li>
				<li class="ml-2" v-if="config.flags.multi.print && permissao.ver == 1" v-on:click="acaoEmMassa('imprimir')"><button><i class="fas fa-print"></i></button></li>
				<li class="ml-2" v-if="config.flags.multi.transfer && permissao.editar == 1" v-on:click="acaoEmMassa('status_pedidos')"><button><i class="fas fa-exchange-alt"></i></button></li>				
				<li class="ml-2" v-if="config.flags.multi.export && permissao.exportar == 1">
					<downloadexcel
						class            = ""
						:fetch           = "fetchDataExport"
						:fields          = "json_fields"
						:before-generate = "startDownload"
						:before-finish   = "finishDownload">
						<button><i class="fas fa-file-excel"></i></button>
					</downloadexcel>
				</li>				
				<li class="ml-2" v-if="config.flags.multi.inactivate && permissao.editar == 1" v-on:click="acaoEmMassa('inativar')"><button><i class="far fa-eye-slash"></i></button></li>
				<li class="ml-2" v-if="config.flags.multi.activate && permissao.editar == 1" v-on:click="acaoEmMassa('ativar')"><button><i class="far fa-eye"></i></button></li>
				<li class="ml-2" v-if="config.flags.multi.delete && permissao.excluir == 1" v-on:click="acaoEmMassa('excluir')"><button><i class="far fa-trash-alt"></i></button></li>
			</ul>
		</div>
	</div>
</template>

<script>

import Filters from "./../../../common/filters";
import Alerts from "./../../../common/alerts";
import configFilters from "./../../../assets/json/filters";
import downloadexcel from "vue-json-excel";
import {deepCopy} from "./../../../common/utils";

export default {
	name: "Paginacao",
	props: {
		config: Object,
		modulo_permissao: String
	},
	components: {
		downloadexcel
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		paginacao() {
			let dados = this.$store.state[this.config.state].valores;
			let pag = {};

			// atualiza os contadores de registro
			let reg_ini = 1;
			let reg_fim;

			if(dados.page > 1) {
				reg_ini = (dados.page - 1) * dados.limit;
				reg_fim = eval(reg_ini) + eval(dados.limit);
			} else {
				reg_fim = dados.limit;
			}

			if(reg_fim > dados.total)
				reg_fim = dados.total;

			pag["total"] = dados.total;
			pag["limit"] = dados.limit;
			pag["reg_ini"] = reg_ini;
			pag["reg_fim"] = reg_fim;
			pag["page"] = dados.page;

			return pag;
		},
		selecionados() {
			return this.$store.state[this.config.state].selecionados;
		},
		json_fields() {
			if(configFilters[this.config.state] && configFilters[this.config.state].export){
				return configFilters[this.config.state].export;
			}else{
				return {};
			}			
		}
	},
	data () {
		return {
			permissao: {},
			dados_alteracao_status: {}, 
			status_canceled: {
				id: null, 
				name: null
			},			
		};
	}, 
	created() {
		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
		this.clearStatusUpdate();
	},
	inject: ["aplicaFiltro"],
	methods: {		
		paginate: function(tipo, valido) {	
			if(valido){
				let self = this;
				let new_page = self.paginacao.page;
				switch(tipo){
				case "up":
					new_page++;			
					break;
				case "down":
					new_page--;
					break;				
				}
				self.$store.dispatch(self.config.state + "/altera_pagina",new_page).then(function(){					
					self.aplicaFiltro();
				});	
			}			
		},
		alteraLimit: function() {
			let self = this;	
			self.$store.dispatch(self.config.state + "/altera_limit",self.paginacao.limit).then(function(){
				self.aplicaFiltro();
			});	
		},
		clearStatusUpdate: function(){
			this.dados_alteracao_status = {
				novo_status: null,
				motivo_cancelamento: null
			};
		},
		async acaoEmMassa(tipo){			
			let msgErro = "";
			let field;
			let value;
			let ids = this.selecionados;			
			let action;
			let msgConfirm;

			// validando se tem algum registro selecionado
			if(this.selecionados.length == 0){
				Alerts.alertTop("error","É necessário selecionar algum registro para realizar essa ação.");
				return;
			}

			switch(tipo){
			case "inativar":
				if(this.permissao.editar != 1){
					msgErro = "Você não tem permissão para editar os registros dessa página.";
				}
				field = "status";
				value = 0;
				action = "status_massa";
				msgConfirm = "Tem certeza que deseja inativar esses registros?";
				break;
			case "ativar":
				if(this.permissao.editar != 1){
					msgErro = "Você não tem permissão para editar os registros dessa página.";
				}
				field = "status";
				value = 1;
				action = "status_massa";
				msgConfirm = "Tem certeza que deseja ativar esses registros?";
				break;
			case "excluir":
				if(this.permissao.excluir != 1){
					msgErro = "Você não tem permissão para excluir os registros dessa página.";
				}
				field = "";
				value = 0;
				action = "excluir_massa";
				msgConfirm = "Tem certeza que deseja excluir esses registros?";
				break;
			case "imprimir":
				if(this.permissao.ver != 1){
					msgErro = "Você não tem permissão para imprimir os registros dessa página.";
				}
				field = "";
				value = 0;
				action = "imprimir_massa";
				msgConfirm = "Tem certeza que deseja imprimir esses registros?";
				break;
			case "status_pedidos":
				if(this.permissao.editar != 1){
					msgErro = "Você não tem permissão para atualizar os status dos pedidos.";
				}
				field = "";
				value = 0;
				action = "update_status_pedidos_massa";
				msgConfirm = "Tem certeza que deseja atualizar esses pedidos?";
				break;
			}
			

			if(msgErro != ""){
				Alerts.alertTop("error",msgErro);
			}else{
				let self = this;			
				let event_confirm = () => {		
					if(tipo == "status_pedidos" && this.status_canceled.id && this.status_canceled.id == this.dados_alteracao_status.novo_status){
						// valida se foi preenchido o motivo de cancelamento
						if(this.dados_alteracao_status.motivo_cancelamento == null || this.dados_alteracao_status.motivo_cancelamento == ""){
							Alerts.alertTop("error", "Selecione um motivo de cancelamento para continuar");
							return false;
						}
					}
					self.$store.dispatch(self.config.state + "/" + action, {field: field, value:value, id: ids, status_update_data: self.dados_alteracao_status }).then( () => {
						this.clearStatusUpdate(); // limpa os dados alterados
						if(tipo != "imprimir"){
							self.aplicaFiltro();
						}						
					});
					return true;
				};
				let dados = [];
				if(tipo == "status_pedidos"){
					// pegando os status de pedidos possiveis
					const status_pedidos = await this.$store.dispatch("pedidos_status/load_status_filtro_data").then(response => {
						return response.map(item => {
							return {
								id: item.id,
								name: item.name,
								type: item.type
							};
						});
					});
					const motivos = await this.$store.dispatch("motivos/load_motivos_data").then(response => {
						return response.map(item => {
							return {
								id: item.id,
								name: item.name
							};
						});
					});

					// pegando o id do status cancelar
					this.status_canceled = status_pedidos.find(item => {
						return item.type === "canceled";
					});

					dados = {
						title: "Alterar status dos pedidos selecionados",
						body: [
							{
								type: "label",
								text: "Selecione o novo status"
							},
							{
								type: "select",
								options: status_pedidos,
								event: (valor) => {
									self.dados_alteracao_status.novo_status = valor;
								}
							},
							{
								type: "label",
								text: "Selecione o motivo do cancelamento"
							},
							{
								type: "select",
								options: motivos,
								event: (valor) => {
									self.dados_alteracao_status.motivo_cancelamento = valor;
								}
							},
						]
					};
				}else{
					dados = {
						title: msgConfirm,
						body: null
					};
				}
				Alerts.alertConfirm(dados,event_confirm);
			}	
		},
		fetchDataExport() {
			// validando se tem algum registro selecionado
			let dados = deepCopy(this.$store.state[this.config.state].valores.items);
			if(this.selecionados.length > 0){
				dados = dados.filter(item => { return this.selecionados.includes(item.id); });				
			}
			dados.forEach(item => {
				for (const [key, value] of Object.entries(item)) {
					if(typeof(value) == "number"){
						item[key] = value.toString().replace(".",",");
					}
				}
			});
			return dados;
		},
		startDownload(){
			Alerts.alertTop("alert","Carregando os dados para exportar em Excel.");			
		},
		finishDownload(){
			Alerts.alertTop("success","O arquivo está sendo baixado no seu computador.");
		}
	},
	filters: Filters,
	mounted() {
		
	}
};
</script>

<style scoped lang="scss">	
	.comp{
		background: #FFF;
		border: 1px solid #D5D5D5;
		border-left: none;
		border-right: none;
		button{
			padding: 0.4em 0.3em;
			font-size: 0.85em;
		}
		.btn-atualizar{
			background: $color_blue;
			border: 1px solid $color_blue;
			color: #FFF;			
			&:hover{
				background: $bg_destaque;
				border-color: $bg_destaque;
			}
		}
		.btn-paginacao{
			background: $bg_gray_medium;
			border: 1px solid $bg_gray;
			margin-left: 0.2em;
			color: #444444;
			&.disable{
				background: $bg_gray;
				opacity: 0.7;
				cursor: not-allowed;
			}			
		}
		ul{
			vertical-align: middle;
			li{
				display: inline-block;
				vertical-align: middle;
				select{
					background: $bg_gray;
					border: 1px solid $border_gray_dark;
					padding: 0.2em;
					margin: 0 0.3em;
				}
			}
			&.selecionados{
				li{
					button{
						background: none;
						border:none;
						padding: 0;
						font-size: 1.3em;
						color: $color_gray;
						&:hover{
							color: $color_destaque;
						}
					}
				}
			}
		}
	}
</style>
