<template>
	<div>
		<!-- Área de Filtros -->
		<Filtros :config=config :modulo_permissao=modulo_permissao :table_fields=table_fields v-if="show_filters" />
		<!-- Paginação e Com Selecionados -->
		<Paginacao :config=config :modulo_permissao=modulo_permissao />
		<!-- Listagem -->
		<Listagem :config=config :modulo_permissao=modulo_permissao :table_fields=table_fields />
		<!-- Paginação e Com Selecionados -->
		<Paginacao :config=config :modulo_permissao=modulo_permissao />
	</div>
</template>

<script>

import Filtros from "./tabela/Filtros";
import Paginacao from "./tabela/Paginacao";
import Listagem from "./tabela/Listagem";

export default {
	name: "Tabela",
	components: {
		Filtros,
		Paginacao,
		Listagem
	},
	computed: {
		table_fields() {			
			return this.$store.state[this.config.state].table_fields;
		},
		search_data() {			
			return this.$store.state[this.config.state].search_data;
		},
	},
	props: {
		config: {
			type: Object,
			required: true
		},
		modulo_permissao: {
			type: String,
			required: true
		},
		show_filters: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data () {
		return {
		};
	},  
	created() {		
		this.$store.dispatch(this.config.state + "/load_filtros_data");						
	},	
	provide: function () {
		return {
			aplicaFiltro: this.aplicaFiltro
		};
	},
	methods: {		
		aplicaFiltro: function() {			
			const search_data = Object.assign({}, this.search_data);
			// tratando os filtros duplicados para criar um where
			const filtros = [];
			search_data.filtros.forEach(item => {
				const filterIndex = filtros.findIndex(res => res.field === item.field && res.operator === item.operator);
				if(filterIndex >= 0){
					switch(item.operator){					
					case "=":
						filtros[filterIndex].operator = "in";
						if(typeof filtros[filterIndex].value === "string"){
							var _value = filtros[filterIndex].value;
							filtros[filterIndex].value = [];
							filtros[filterIndex].value.push(_value);
							filtros[filterIndex].value.push(item.value);
							delete filtros[filterIndex].type;
						}else{
							filtros[filterIndex].value.push(item.value);
						}
						
						break;
					default:
						filtros.push(item);
						break;
					}
				}else{
					filtros.push(item);
				}
			});
			search_data.filtros = filtros;
			
			this.$store.dispatch(this.config.state + "/load_valores_data", search_data);

			window.scrollTo(0, 0);
			
		},
	},
	mounted(){
		this.$store.dispatch(this.config.state + "/load_valores_data",this.search_data);
	}

};
</script>

<style scoped lang="scss">	
	
</style>
