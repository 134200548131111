import Vue from "vue";
import VueRouter from "vue-router";
import storage from "./storage";
import store from "./store/store";

import Dashboard from "./components/pages/Dashboard/Default.vue";
// Atributos
import AtributosLista from "./components/pages/atributos/AtributosLista";
import AtributosForm from "./components/pages/atributos/AtributosForm";
// Tipos de Produtos
import ProdutosTipoLista from "./components/pages/produtos-tipo/ProdutosTipoLista";
import ProdutosTipoForm from "./components/pages/produtos-tipo/ProdutosTipoForm";
// login
import Login from "./components/pages/Login";
// 404
import notFound from "./components/pages/404";
// grupos de usuarios
import UsuariosGruposForm from "./components/pages/usuarios/GrupoForm";
import UsuariosGruposLista from "./components/pages/usuarios/GrupoLista";
// usuarios
import UsuariosForm from "./components/pages/usuarios/UsuariosForm";
import UsuariosLista from "./components/pages/usuarios/UsuariosLista";
// Categorias
import CategoriasForm from "./components/pages/categorias/CategoriasForm";
// Marcas
import MarcasLista from "./components/pages/marcas/MarcasLista";
import MarcasForm from "./components/pages/marcas/MarcasForm";
// Produtos
import ProdutosLista from "./components/pages/produtos/ProdutosLista";
import ProdutosForm from "./components/pages/produtos/ProdutosForm";
// Preços
import PrecosLista from "./components/pages/precos/PrecosLista";
// Rotas
import RotasLista from "./components/pages/rotas/RotasLista";
import RotasForm from "./components/pages/rotas/RotasForm";
// Vitrines
import VitrinesLista from "./components/pages/vitrines/VitrinesLista";
import VitrinesForm from "./components/pages/vitrines/VitrinesForm";
// Banners
import BannersLista from "./components/pages/banners/BannersLista";
import BannersForm from "./components/pages/banners/BannersForm";
// Páginas Adicionais
import PaginasAdicionaisLista from "./components/pages/paginas/AdicionaisLista";
import PaginasAdicionaisForm from "./components/pages/paginas/AdicionaisForm";
// Páginas Especiais
import PaginasEspeciaisLista from "./components/pages/paginas/EspeciaisLista";
import PaginasEspeciaisForm from "./components/pages/paginas/EspeciaisForm";
// Menus
import MenusLista from "./components/pages/menus/MenusLista";
import MenusForm from "./components/pages/menus/MenusForm";
// Cupons
import CuponsLista from "./components/pages/cupons/CuponsLista";
import CuponsForm from "./components/pages/cupons/CuponsForm";
// Promoções Progressivos
import ProgressivosLista from "./components/pages/promocoes/ProgressivosLista";
import ProgressivosForm from "./components/pages/promocoes/ProgressivosForm";
// Promoções Frete
import PromocoesFreteLista from "./components/pages/promocoes/FreteLista";
import PromocoesFreteForm from "./components/pages/promocoes/FreteForm";
// Promoções Produtos
import PromocoesProdutosLista from "./components/pages/promocoes/ProdutosLista";
import PromocoesProdutosForm from "./components/pages/promocoes/ProdutosForm";
// Brindes
import BrindesLista from "./components/pages/brindes/BrindesLista";
import BrindesForm from "./components/pages/brindes/BrindesForm";
// Layout E-mail
import EmailLayoutLista from "./components/pages/email/LayoutLista";
import EmailLayoutForm from "./components/pages/email/LayoutForm";
// Templates E-mail
import EmailTemplatesLista from "./components/pages/email/TemplatesLista";
import EmailTemplatesForm from "./components/pages/email/TemplatesForm";
// Pedidos Status
import PedidosStatusLista from "./components/pages/pedidos/StatusLista";
import PedidosStatusForm from "./components/pages/pedidos/StatusForm";
// Clientes
import ClientesLista from "./components/pages/clientes/ClientesLista";
import ClientesForm from "./components/pages/clientes/ClientesForm";
import ClientesView from "./components/pages/clientes/ClientesView";
// Motivos de Cancelamento
import MotivosLista from "./components/pages/pedidos/MotivosLista";
import MotivosForm from "./components/pages/pedidos/MotivosForm";
// Senhas
import SenhasLista from "./components/pages/senhas/SenhasLista";
import SenhasForm from "./components/pages/senhas/SenhasForm";
// Pedidos
import OotzPedidosView from "./components/pages/pedidos/PedidosView.vue";
import OotzPedidosFormEdit from "./components/pages/pedidos/PedidosForm.vue";
import OotzPedidosLista from "./components/pages/pedidos/PedidosLista.vue";
import OotzPedidosCreate from "./components/pages/pedidos/PedidosCreate.vue";
import OotzPedidosPrint from "./components/pages/pedidos/PedidosPrint.vue";
import OotzPedidosShipment from "./components/pages/pedidos/PedidosShipment.vue";
// Vouchers
import VouchersLista from "./components/pages/vouchers/VouchersLista";
import VouchersForm from "./components/pages/vouchers/VouchersForm";
// Tickets
import TicketsLista from "./components/pages/tickets/TicketsLista";
import TicketsForm from "./components/pages/tickets/TicketsForm";
import TicketsView from "./components/pages/tickets/TicketsView";
// Produtos Duvidas
import ProdutosDuvidasLista from "./components/pages/produtos-duvidas/ProdutosDuvidasLista";
import ProdutosDuvidasForm from "./components/pages/produtos-duvidas/ProdutosDuvidasForm";
// Reviews
import ProdutosReviewsLista from "./components/pages/produtos-reviews/ReviewsLista";
import ProdutosReviewsForm from "./components/pages/produtos-reviews/ReviewsForm";
// Depoimentos
import DepoimentosLista from "./components/pages/depoimentos/DepoimentosLista";
import DepoimentosForm from "./components/pages/depoimentos/DepoimentosForm";
// Blog
import BlogLista from "./components/pages/blog/BlogLista";
import BlogForm from "./components/pages/blog/BlogForm";
// Comentários blog
import BlogCommentsLista from "./components/pages/blog/CommentsLista";
import BlogCommentsForm from "./components/pages/blog/CommentsForm";
// Reports
import ReportPedidos from "./components/pages/reports/ReportsPedidos";
import ReportsTaxasml from "./components/pages/reports/ReportsTaxasMercadoLivre";
import ReportsJadlog from "./components/pages/reports/ReportsJadlog";
import ReportsProdutosFornecedor from "./components/pages/reports/ReportsProdutosFornecedor";
import ReportsInventory from "./components/pages/reports/ReportsInventory";
import ReportsMarketplaces from "./components/pages/reports/Marketplaces";
import ReportsProductsSales from "./components/pages/reports/VendasProdutos";
import ReportsMarketplaceDash from "./components/pages/reports/MarketplacesDash";
import ReportsSales from "./components/pages/reports/Sales";
import ReportsSalesByCategory from "./components/pages/reports/SalesByCategory";
// Estoque
import EstoqueLista from "./components/pages/estoque/EstoqueLista";
// Monitoramento de erros
import MonitoringLista from "./components/pages/monitoring/Lista";
import MonitoringView from "./components/pages/monitoring/View";
// alertas sistema
import AlertsLista from "./components/pages/alerts/Lista";
import AlertsView from "./components/pages/alerts/View";

// Sorteio
import RaffleLista from "./components/pages/raffle/RaffleLista";
import RaffleForm from "./components/pages/raffle/RaffleForm";
import RaffleView from "./components/pages/raffle/RaffleView";
// Produtos Aguardando
import WaitingLista from "./components/pages/produtos/WaitingLista";
import WaitingForm from "./components/pages/produtos/WaitingForm";
// Update em massa de registros
import ScaleView from "./components/pages/scale-update/UpdatesView";

// Carts
import CartsLista from "./components/pages/carts/CartsLista";
import CartsView from "./components/pages/carts/CartsView";


Vue.use(VueRouter);

// criando as rotas
var routes = [
	// auth
	{ name: "auth.login", path: "/login", component: Login, meta: { auth: false } },
	// 404
	{ name: "404", path: "/404", component: notFound },
	// usuarios grupo
	{ name: "usuarios.grupos.lista", path: "/usuarios/grupos", component: UsuariosGruposLista, props: { modulo_permissao: "grupos" } },
	{ name: "usuarios.grupos.novo", path: "/usuarios/grupos/novo", component: UsuariosGruposForm, props: { modulo_permissao: "grupos" } },
	{ name: "usuarios.grupos.editar", path: "/usuarios/grupos/editar/:id", component: UsuariosGruposForm, props: { modulo_permissao: "grupos" } },
	// usuarios
	{ name: "usuarios.lista", path: "/usuarios", component: UsuariosLista, props: { modulo_permissao: "usuarios" } },
	{ name: "usuarios.novo", path: "/usuarios/novo", component: UsuariosForm, props: { modulo_permissao: "usuarios" } },
	{ name: "usuarios.editar", path: "/usuarios/editar/:id", component: UsuariosForm, props: { modulo_permissao: "usuarios" } },
	// Dashboard
	{ name: "dashboard", path: "/", component: Dashboard, props: { modulo_permissao: "dashboard" } },
	// Atributos de produtos
	{ name: "atributos.lista", path: "/atributos", component: AtributosLista, props: { modulo_permissao: "atributos" } },
	{ name: "atributos.novo", path: "/atributos/novo", component: AtributosForm, props: { modulo_permissao: "atributos" } },
	{ name: "atributos.editar", path: "/atributos/editar/:id", component: AtributosForm, props: { modulo_permissao: "atributos" } },
	// tipos de produtos
	{ name: "produto.tipos.lista", path: "/produto/tipos", component: ProdutosTipoLista, props: { modulo_permissao: "produto-tipos" } },
	{ name: "produto.tipos.novo", path: "/produto/tipos/novo", component: ProdutosTipoForm, props: { modulo_permissao: "produto-tipos" } },
	{ name: "produto.tipos.editar", path: "/produto/tipos/editar/:id", component: ProdutosTipoForm, props: { modulo_permissao: "produto-tipos" } },
	// categorias
	{ name: "categorias", path: "/categorias", component: CategoriasForm, props: { modulo_permissao: "categorias" } },
	// marcas
	{ name: "marcas.lista", path: "/marcas", component: MarcasLista, props: { modulo_permissao: "marcas" } },
	{ name: "marcas.novo", path: "/marcas/novo", component: MarcasForm, props: { modulo_permissao: "marcas" } },
	{ name: "marcas.editar", path: "/marcas/editar/:id", component: MarcasForm, props: { modulo_permissao: "marcas" } },
	// produtos
	{ name: "produtos.lista", path: "/produtos", component: ProdutosLista, props: { modulo_permissao: "produtos" } },
	{ name: "produtos.novo", path: "/produtos/novo", component: ProdutosForm, props: { modulo_permissao: "produtos" } },
	{ name: "produtos.editar", path: "/produtos/editar/:id", component: ProdutosForm, props: { modulo_permissao: "produtos" } },
	// preços de produtos
	{ name: "preco-edicao", path: "/edicao/precos", component: PrecosLista, props: { modulo_permissao: "preco-edicao" } },
	// rotas
	{ name: "rotas.lista", path: "/rotas", component: RotasLista, props: { modulo_permissao: "rotas" } },
	{ name: "rotas.novo", path: "/rotas/novo", component: RotasForm, props: { modulo_permissao: "rotas" } },
	// vitrines
	{ name: "vitrines.lista", path: "/vitrines", component: VitrinesLista, props: { modulo_permissao: "vitrines" } },
	{ name: "vitrines.novo", path: "/vitrines/novo", component: VitrinesForm, props: { modulo_permissao: "vitrines" } },
	{ name: "vitrines.editar", path: "/vitrines/editar/:id", component: VitrinesForm, props: { modulo_permissao: "vitrines" } },
	// banners
	{ name: "banners.lista", path: "/banners", component: BannersLista, props: { modulo_permissao: "banners" } },
	{ name: "banners.novo", path: "/banners/novo", component: BannersForm, props: { modulo_permissao: "banners" } },
	{ name: "banners.editar", path: "/banners/editar/:id", component: BannersForm, props: { modulo_permissao: "banners" } },
	// páginas adicionais
	{ name: "paginas.adicionais.lista", path: "/paginas/adicionais", component: PaginasAdicionaisLista, props: { modulo_permissao: "paginas-adicionais" } },
	{ name: "paginas.adicionais.novo", path: "/paginas/adicionais/novo", component: PaginasAdicionaisForm, props: { modulo_permissao: "paginas-adicionais" } },
	{ name: "paginas.adicionais.editar", path: "/paginas/adicionais/editar/:id", component: PaginasAdicionaisForm, props: { modulo_permissao: "paginas-adicionais" } },
	// páginas especiais
	{ name: "paginas.especiais.lista", path: "/paginas/especiais", component: PaginasEspeciaisLista, props: { modulo_permissao: "paginas-especiais" } },
	{ name: "paginas.especiais.novo", path: "/paginas/especiais/novo", component: PaginasEspeciaisForm, props: { modulo_permissao: "paginas-especiais" } },
	{ name: "paginas.especiais.editar", path: "/paginas/especiais/editar/:id", component: PaginasEspeciaisForm, props: { modulo_permissao: "paginas-especiais" } },
	// menus
	{ name: "menus.lista", path: "/menus", component: MenusLista, props: { modulo_permissao: "menus" } },
	{ name: "menus.novo", path: "/menus/novo", component: MenusForm, props: { modulo_permissao: "menus" } },
	{ name: "menus.editar", path: "/menus/editar/:id", component: MenusForm, props: { modulo_permissao: "menus" } },
	// cupons
	{ name: "cupons.lista", path: "/cupons", component: CuponsLista, props: { modulo_permissao: "cupom-desconto" } },
	{ name: "cupons.novo", path: "/cupons/novo", component: CuponsForm, props: { modulo_permissao: "cupom-desconto" } },
	{ name: "cupons.editar", path: "/cupons/editar/:id", component: CuponsForm, props: { modulo_permissao: "cupom-desconto" } },
	// descontos progressivos
	{ name: "promocoes.progressivos.lista", path: "/promocoes/progressivos", component: ProgressivosLista, props: { modulo_permissao: "descontos-progressivos" } },
	{ name: "promocoes.progressivos.novo", path: "/promocoes/progressivos/novo", component: ProgressivosForm, props: { modulo_permissao: "descontos-progressivos" } },
	{ name: "promocoes.progressivos.editar", path: "/promocoes/progressivos/editar/:id", component: ProgressivosForm, props: { modulo_permissao: "descontos-progressivos" } },
	// descontos frete
	{ name: "promocoes.frete.lista", path: "/promocoes/frete", component: PromocoesFreteLista, props: { modulo_permissao: "frete-promocoes" } },
	{ name: "promocoes.frete.novo", path: "/promocoes/frete/novo", component: PromocoesFreteForm, props: { modulo_permissao: "frete-promocoes" } },
	{ name: "promocoes.frete.editar", path: "/promocoes/frete/editar/:id", component: PromocoesFreteForm, props: { modulo_permissao: "frete-promocoes" } },
	// Promoções produtos
	{ name: "promocoes.produtos.lista", path: "/promocoes/produtos", component: PromocoesProdutosLista, props: { modulo_permissao: "promocoes" } },
	{ name: "promocoes.produtos.novo", path: "/promocoes/produtos/novo", component: PromocoesProdutosForm, props: { modulo_permissao: "promocoes" } },
	{ name: "promocoes.produtos.editar", path: "/promocoes/produtos/editar/:id", component: PromocoesProdutosForm, props: { modulo_permissao: "promocoes" } },
	// Brindes
	{ name: "brindes.lista", path: "/brindes", component: BrindesLista, props: { modulo_permissao: "brindes" } },
	{ name: "brindes.novo", path: "/brindes/novo", component: BrindesForm, props: { modulo_permissao: "brindes" } },
	{ name: "brindes.editar", path: "/brindes/editar/:id", component: BrindesForm, props: { modulo_permissao: "brindes" } },
	// Layout de e-mail
	{ name: "email.layout.lista", path: "/email/layout", component: EmailLayoutLista, props: { modulo_permissao: "layout-email" } },
	{ name: "email.layout.novo", path: "/email/layout/novo", component: EmailLayoutForm, props: { modulo_permissao: "layout-email" } },
	{ name: "email.layout.editar", path: "/email/layout/editar/:id", component: EmailLayoutForm, props: { modulo_permissao: "layout-email" } },
	// tempĺates de e-mail
	{ name: "email.template.lista", path: "/email/templates", component: EmailTemplatesLista, props: { modulo_permissao: "template-email" } },
	{ name: "email.template.novo", path: "/email/templates/novo", component: EmailTemplatesForm, props: { modulo_permissao: "template-email" } },
	{ name: "email.template.editar", path: "/email/templates/editar/:id", component: EmailTemplatesForm, props: { modulo_permissao: "template-email" } },
	// status de pedido
	{ name: "pedidos.status.lista", path: "/pedidos/status", component: PedidosStatusLista, props: { modulo_permissao: "pedido-status" } },
	{ name: "pedidos.status.novo", path: "/pedidos/status/novo", component: PedidosStatusForm, props: { modulo_permissao: "pedido-status" } },
	{ name: "pedidos.status.editar", path: "/pedidos/status/editar/:id", component: PedidosStatusForm, props: { modulo_permissao: "pedido-status" } },
	// clientes
	{ name: "clientes.lista", path: "/clientes", component: ClientesLista, props: { modulo_permissao: "clientes" } },
	{ name: "clientes.ver", path: "/clientes/view/:id", component: ClientesView, props: { modulo_permissao: "clientes" } },
	{ name: "clientes.novo", path: "/clientes/novo", component: ClientesForm, props: { modulo_permissao: "clientes" } },
	{ name: "clientes.editar", path: "/clientes/editar/:id", component: ClientesForm, props: { modulo_permissao: "clientes" } },
	// senhas
	{ name: "senhas.lista", path: "/senhas", component: SenhasLista, props: { modulo_permissao: "senhas" } },
	{ name: "senhas.novo", path: "/senhas/novo", component: SenhasForm, props: { modulo_permissao: "senhas" } },
	{ name: "senhas.editar", path: "/senhas/editar/:id", component: SenhasForm, props: { modulo_permissao: "senhas" } },
	// motivos de cancelamento
	{ name: "pedidos.motivos.lista", path: "/pedidos/motivos", component: MotivosLista, props: { modulo_permissao: "motivos-cancelamento" } },
	{ name: "pedidos.motivos.novo", path: "/pedidos/motivos/novo", component: MotivosForm, props: { modulo_permissao: "motivos-cancelamento" } },
	{ name: "pedidos.motivos.editar", path: "/pedidos/motivos/editar/:id", component: MotivosForm, props: { modulo_permissao: "motivos-cancelamento" } },
	// pedidos
	{ name: "pedidos.lista", path: "/pedidos", component: OotzPedidosLista, props: { modulo_permissao: "pedidos" } },
	{ name: "pedidos.ver", path: "/pedidos/view/:id", component: OotzPedidosView, props: { modulo_permissao: "pedidos" } },
	{ name: "pedidos.editar", path: "/pedidos/editar/:id", component: OotzPedidosFormEdit, props: { modulo_permissao: "pedidos" } },
	{ name: "pedidos.create", path: "/pedidos/create/:id", component: OotzPedidosCreate, props: { modulo_permissao: "pedidos" } },
	{ name: "pedidos.print", path: "/pedidos/print", component: OotzPedidosPrint, props: { modulo_permissao: "pedidos" } },
	{ name: "pedidos.shipment", path: "/pedidos/shipment", component: OotzPedidosShipment, props: { modulo_permissao: "packing" } },
	// vouchers
	{ name: "vouchers.lista", path: "/vouchers", component: VouchersLista, props: {modulo_permissao: "vouchers"} },
	{ name: "vouchers.novo", path: "/vouchers/novo", component: VouchersForm, props: {modulo_permissao: "vouchers"} },
	{ name: "vouchers.editar", path: "/vouchers/editar/:id", component: VouchersForm, props: {modulo_permissao: "vouchers"} },
	// tickets
	{ name: "tickets.lista", path: "/tickets", component: TicketsLista, props: {modulo_permissao: "tickets"} },
	{ name: "tickets.novo", path: "/tickets/novo", component: TicketsForm, props: {modulo_permissao: "tickets"} },
	{ name: "tickets.editar", path: "/tickets/editar/:id", component: TicketsForm, props: {modulo_permissao: "tickets"} },
	{ name: "tickets.ver", path: "/tickets/view/:id", component: TicketsView, props: {modulo_permissao: "tickets"} },	
	// produtos duvidas
	{ name: "produtos.duvidas.lista", path: "/produtos/duvidas", component: ProdutosDuvidasLista, props: {modulo_permissao: "produtos-duvidas"} },
	{ name: "produtos.duvidas.novo", path: "/produtos/duvidas/novo", component: ProdutosDuvidasForm, props: {modulo_permissao: "produtos-duvidas"} },
	{ name: "produtos.duvidas.editar", path: "/produtos/duvidas/editar/:id", component: ProdutosDuvidasForm, props: {modulo_permissao: "produtos-duvidas"} },
	// avaliações
	{ name: "produtos.reviews.lista", path: "/produtos/reviews", component: ProdutosReviewsLista, props: {modulo_permissao: "reviews"} },
	{ name: "produtos.reviews.novo", path: "/produtos/reviews/novo", component: ProdutosReviewsForm, props: {modulo_permissao: "reviews"} },
	{ name: "produtos.reviews.editar", path: "/produtos/reviews/editar/:id", component: ProdutosReviewsForm, props: {modulo_permissao: "reviews"} },
	// depoimentos
	{ name: "depoimentos.lista", path: "/depoimentos", component: DepoimentosLista, props: { modulo_permissao: "depoimentos" } },
	{ name: "depoimentos.novo", path: "/depoimentos/novo", component: DepoimentosForm, props: { modulo_permissao: "depoimentos" } },
	{ name: "depoimentos.editar", path: "/depoimentos/editar/:id", component: DepoimentosForm, props: { modulo_permissao: "depoimentos" } },
	// blog
	{ name: "blog.lista", path: "/blog", component: BlogLista, props: { modulo_permissao: "blog" } },
	{ name: "blog.novo", path: "/blog/novo", component: BlogForm, props: { modulo_permissao: "blog" } },
	{ name: "blog.editar", path: "/blog/editar/:id", component: BlogForm, props: { modulo_permissao: "blog" } },
	// comments blog
	{ name: "blog.comments.lista", path: "/blog/comments", component: BlogCommentsLista, props: { modulo_permissao: "blog" } },
	{ name: "blog.comments.novo", path: "/blog/comments/novo", component: BlogCommentsForm, props: { modulo_permissao: "blog" } },
	{ name: "blog.comments.editar", path: "/blog/comments/editar/:id", component: BlogCommentsForm, props: { modulo_permissao: "blog" } },
	// reports
	{ name: "reports.pedidos", path: "/reports/pedidos", component: ReportPedidos, props: { modulo_permissao: "relatorios-pedidos" } },
	{ name: "reports.produtos_fornecedor", path: "/reports/produtos-fornecedor", component: ReportsProdutosFornecedor, props: { modulo_permissao: "relatorios-produtos-fornecedor" } },	
	{ name: "reports.inventory", path: "/reports/inventario", component: ReportsInventory, props: { modulo_permissao: "relatorios-inventario" } },	
	{ name: "reports.marketplaces", path: "/reports/marketplaces", component: ReportsMarketplaces, props: { modulo_permissao: "relatorios-marketplaces" } },
	{ name: "reports.taxasml", path: "/reports/taxasml", component: ReportsTaxasml, props: { modulo_permissao: "relatorios-marketplaces" } },
	{ name: "reports.jadlog", path: "/reports/jadlog", component: ReportsJadlog, props: { modulo_permissao: "relatorios-jadlog" } },
	{ name: "reports.products.sales", path: "/reports/products/sales", component: ReportsProductsSales, props: { modulo_permissao: "relatorios-products-sales" } },
	{ name: "reports.marketplaces.dash", path: "/reports/marketplaces/dash", component: ReportsMarketplaceDash, props: { modulo_permissao: "relatorios-marketplaces" } },
	{ name: "reports.sales", path: "/reports/sales", component: ReportsSales, props: { modulo_permissao: "relatorios-sales" } },
	{
		name: "reports.sales_by_category",
		path: "/reports/sales-by-category",
		component: ReportsSalesByCategory,
		props: {
			modulo_permissao:
				"relatorios-sales-by-category"
		}
	},
	// estoque
	{ name: "edicao.estoque.lista", path: "/edicao/estoque", component: EstoqueLista, props: { modulo_permissao: "estoque-edicao" } },
	// lista de monitoramento
	{ name: "monitoring.lista", path: "/monitoring/lista", component: MonitoringLista, props: { modulo_permissao: "monitoring" } },
	{ name: "monitoring.ver", path: "/monitoring/view/:id", component: MonitoringView, props: {modulo_permissao: "monitoring"} },	
	// alertas de sistema
	{ name: "alerts.lista", path: "/alerts/lista", component: AlertsLista, props: { modulo_permissao: "alerts" } },
	{ name: "alerts.ver", path: "/alerts/view/:id", component: AlertsView, props: {modulo_permissao: "alerts"} },	

	// sorteio
	{ name: "raffle.lista", path: "/raffle", component: RaffleLista, props: { modulo_permissao: "raffle" } },
	{ name: "raffle.novo", path: "/raffle/novo", component: RaffleForm, props: { modulo_permissao: "raffle" } },
	{ name: "raffle.editar", path: "/raffle/editar/:id", component: RaffleForm, props: { modulo_permissao: "raffle" } },
	{ name: "raffle.ver", path: "/raffle/view/:id", component: RaffleView, props: {modulo_permissao: "raffle"} },	

	// carts
	{ name: "carts.lista", path: "/carts", component: CartsLista, props: { modulo_permissao: "carts" } },
	{ name: "carts.ver", path: "/carts/view/:id", component: CartsView, props: {modulo_permissao: "carts"} },	

	// produtos aguardando cadastro
	{ name: "produtos.aguardando.lista", path: "/produtos/aguardando", component: WaitingLista, props: { modulo_permissao: "produtos" } },
	{ name: "produtos.aguardando.novo", path: "/produtos/aguardando/novo", component: WaitingForm, props: { modulo_permissao: "produtos" } },
	{ name: "produtos.aguardando.editar", path: "/produtos/aguardando/editar/:id", component: WaitingForm, props: { modulo_permissao: "produtos" } },
	// update em massa de registros
	{ name: "scale", path: "/atualizacao-em-massa", component: ScaleView, props: { modulo_permissao: "scale-update" } },	
	
];

const router = new VueRouter({
	history: true,
	root: "app",
	routes: routes,
	// scrollBehavior(to, from, savedPosition) {
	scrollBehavior() {
		// if (savedPosition) {
		// 	return savedPosition;
		// } else {
		// 	return { x: 0, y: 0 };
		// }
		return { x: 0, y: 0 };
	}
});

// //verifica login
// // router.beforeEach((to, from, next) => {
router.beforeResolve((to, from, next) => {

	let usuario = storage.getObject("usuario");

	// verificando o login
	if (to.name != "auth.login") {
		if (usuario) {
			//seta o usuario do storage na app
			store.dispatch("app/login", usuario);
		} else {
			//chama a tela de login
			router.push({
				name: "auth.login"
			}).catch(err => {
				if (err.name == "NavigationDuplicated" && from.name == "auth.login") {
					router.push({
						name: "auth.login"
					});
				}
			});
		}
	}

	var error = false;

	// verificando se existe a rota 
	let rota = routes.filter(item => item.name == to.name)[0];
	if (rota && usuario && rota.name != "404" && rota.name != "auth.login" && rota.name != "root") {
		// verificando e pegando a props
		let modulo_permissao;
		let existe_prop_modulo = to.matched.some(item => {
			if (item.props.default != undefined) {
				if (item.props.default.modulo_permissao) {
					// pega o nome do modulo
					modulo_permissao = item.props.default.modulo_permissao;
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		});

		// verifica se tem a prop modulo permissão
		if (existe_prop_modulo) {
			// verifica se o usuário tem acesso ao modulo
			if (usuario.permissoes[modulo_permissao]) {
				if (usuario.permissoes[modulo_permissao].ver != 1) {
					//chama a tela de erro
					router.push({
						name: "404"
					});
					error = true;
				}
			} else {
				//chama a tela de erro
				router.push({
					name: "404"
				}).catch(err => {
					if (err.name == "NavigationDuplicated" && from.name != "404") {
						router.push({
							name: "404"
						});
					}
				});
				error = true;
			}
		} else {
			//chama a tela de erro
			router.push({
				name: "404"
			});
			error = true;
		}
	}

	if (!error) {
		next();		
	}
});

export default router;