<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header :dados=header_info :modulo_permissao=modulo_permissao />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row formulario-cadastro">
			<div class="col-3 mt-4" v-if="itens_tab.length > 0">
				<TabsEsquerda :itens="itens_tab" :disabled_items="disabled_items" :hidden_items="hidden_items" />
			</div>

			<div class="formulario-dados mt-4" v-bind:class="{'col-12': itens_tab.length == 0, 'col-9': itens_tab.length > 0}">
				<!-- Geral -->
				<div class="hide card card-base">
					<div class="card-header">
						Informações Gerais do Menu
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<div class="row">
							<div class="col-6">
								<FormInput :required=true rules="string" label="Nome" placeholder="" info="" v-model="model.name" :slug_function=true />
							</div>									
							<div class="col-6">
								<FormInput :required=true rules="string" label="Identificador" :disabled="model.id != null" placeholder="" info="" v-model="model.slug" :slug_function=false />
							</div>																
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Loja" info="" :options_none="{id: 0, label: ''}" :options="[{id: 1,label: 'Mundo da Carabina'}, {id: 2, label: 'Aventura & Cia'}, {id: 3, label: 'Lexo'}]" v-model="model.store_id" type="number" />		
							</div>
							<div class="col-6">
								<FormSelect :required=true rules="option" label="Status" info="" :options="[{id: 1,label: 'Ativo'}, {id: 0, label: 'Inativo'}]" v-model="model.status" type="number" />		
							</div>
						</div>						
						<!-- Fim Conteudo -->
					</div>
				</div>
				<!-- Menus -->
				<div class="hide card card-base">
					<div class="card-header">
						Menu
					</div>
					<div class="card-body">
						<!-- Conteudo -->
						<Menus :permissao=permissao ></Menus>
						<!-- Fim Conteudo -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import TabsEsquerda from "./../../common/TabsEsquerda";
import validation from "./../../../common/validation";
import {Slug} from "./../../../common/utils";
import Menus from "./../../common/Menus";

// Inputs
import FormInput from "./../../common/form/Input";
import FormSelect from "./../../common/form/Select";


export default {
	name: "Formulario",
	components: {
		Header,
		Breadcrumb,
		TabsEsquerda,
		FormInput,
		FormSelect,
		Menus
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		model() {
			return this.$store.state.menus.model;
		},
		permissoes() {
			return this.$store.state.app.permissoes;
		}
	},	
	data () {
		return {
			header_info: {
				title: "Menus",
				chamada: "Gerencie os menus da sua loja",
				links: {
					back: "menus.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Menus",
					link: "/menus"
				},
				{
					name: "Cadastro",
					link: ""
				}
			],
			itens_tab:[
				"Geral",
				"Menu"
			],
			disabled_items: [],
			hidden_items: [],
			permissao: {}
		};
	},  
	methods: {		
		save: function(){			
			if(validation.validation().valid){			
				let model = this.model;

				// limpa os open e edit
				model.menu.forEach(function(item){
					delete item.open;
					delete item.edit;
					if(item.children.length > 0){
						item.children.forEach(it => {
							delete it.open;
							delete it.edit;
							if(it.children.length > 0){
								it.children.forEach(i => {
									delete i.open;
									delete i.edit;
								});
							}
						});
					}
				});	
				// ordena o menu para gravar na ordem correta
				model.menu = this.ordenaMenu(model.menu);

				// salva
				this.$store.dispatch("menus/save", model);
			}
		},
		toSlug: function(){
			if(this.model.slug == ""){
				this.model.slug = Slug(this.model.name);
			}						
		},
		ordenaMenu: function(menu){		
			if(menu.length > 0){
				menu.sort((a, b) => {
					if (a.order > b.order) return 1;
					if (b.order > a.order) return -1;
					return 0;
				});
				menu.forEach(function(item){
					if(item.children.length > 0){					
						item.children.sort((a, b) => {
							if (a.order > b.order) return 1;
							if (b.order > a.order) return -1;
							return 0;
						});
						item.children.forEach(it => {						
							if(it.children.length > 0){							
								it.children.sort((a, b) => {
									if (a.order > b.order) return 1;
									if (b.order > a.order) return -1;
									return 0;
								});								
							}
						});
					}
				});	
			}			
			return menu;
		}
	},
	provide: function () {
		return {
			save: this.save,
			toSlug: this.toSlug,
			ordenaMenu: this.ordenaMenu
		};
	},
	created() {
		this.$store.dispatch("menus/reset");
		if(this.$route.params.id != undefined && this.$route.params.id != "") {
			this.$store.dispatch("menus/load", this.$route.params.id);
		}

		if(this.permissoes) {
			this.permissao = this.permissoes[this.modulo_permissao];
		}
	},
	watch: {
	
	}
};
</script>

<style scoped lang="scss">	
	
</style>
