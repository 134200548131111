<template>
	<div class="row">
		<div class="col-12">
			<FormInput :required=required rules="string" label="Nome" placeholder="" info="" v-model="value.title" @input="updateDate()" />
		</div>		
		<div class="col-12">
			<FormTextarea :required=required rules="string" label="Descrição" :rows=3 placeholder="" info="" v-model="value.description" @input="updateDate()" />
		</div>		
		<div class="col-12">
			<FormInput :required=required rules="string" label="Palavras-chave" placeholder="" info="" v-model="value.keywords" @input="updateDate()" />
		</div>		
	</div>
</template>

<script>

import FormInput from "./form/Input";
import FormTextarea from "./form/Textarea";


export default {
	name: "MetaTags",
	props: {
		value: {
			type: Object,
			required: true
		},
		required: {
			type: Boolean,
			default: true
		}
	},
	components: {
		FormInput,
		FormTextarea
	},
	computed: {
		
	},
	data () {
		return {
		};
	},  
	created() {
	},
	methods: {		
		updateDate: function(){	
			this.$emit("input", this.value);
		}
	}
};
</script>

<style scoped lang="scss">
	
</style>
