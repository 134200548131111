import api from "../../common/api.js";
import model from "../models/dashboard";
import moment from "moment";

export default {
	calculatePeriod(store,data) {

		let type = data.type;
		let type_module = data.module;
        
		let group; // (hour / day / month)
		let date_end = moment().format("YYYY-MM-DD")+" 23:59:59";
		let date_init;
		let mes_atual;	
		let mes_anterior;		
		switch(type){
		case "24-hours":
			date_init = moment().subtract(23, "H").format("YYYY-MM-DD HH")+":00:00";
			date_end = moment().format("YYYY-MM-DD HH")+":59:59";
			group = "hour";
			break;

		case "today":
			date_init = moment().format("YYYY-MM-DD")+" 00:00:00";
			group = "hour";
			break;

		case "yesterday":
			date_init = moment().subtract(1, "d").format("YYYY-MM-DD")+" 00:00:00";
			date_end = moment().subtract(1, "d").format("YYYY-MM-DD")+" 23:59:59";
			group = "hour";
			break;

		case "7-days":
			date_init = moment().subtract(6, "d").format("YYYY-MM-DD")+" 00:00:00";
			group = "day";
			break;

		case "15-days":
			date_init = moment().subtract(14, "d").format("YYYY-MM-DD")+" 00:00:00";
			group = "day";
			break;

		case "current-month":
			mes_atual = moment().format("YYYY-MM");
			date_end = mes_atual+"-"+moment(mes_atual, "YYYY-MM").daysInMonth()+" 23:59:59";
			date_init = mes_atual+"-01"+" 00:00:00";					
			group = "day";
			break;

		case "last-month":
			mes_anterior = moment().subtract(1, "M").format("YYYY-MM");
			date_end = mes_anterior+"-"+moment(mes_anterior, "YYYY-MM").daysInMonth()+" 23:59:59";
			date_init = mes_anterior+"-01"+" 00:00:00";
			group = "day";
			break;
		}

		return {
			date_init: date_init,
			date_end: date_end,
			group: group,
			module: type_module,
		};
	},
	// form
	reset(store) {
		store.commit("reset", model.init());
		// trata as datas de acordo com os padrões
	},
	box_data(store,data){        
		api.request("dashboard/box_data_"+data.type_module, data.endpoint, data.method, data.filters);
	},
	lines(store,data){
		return api.request("dashboard/lines_"+data.type_module, data.endpoint, data.method, data.filters);
	},
	bars(store,data){
		return api.request("dashboard/bars_"+data.type_module, data.endpoint, data.method, data.filters);
	}
};