<template>
	<div>
		<!-- Título e subtitulo do modulo -->
		<Header ref="header" :dados=header_info :modulo_permissao=modulo_permissao :pedido=header_pedidos :is_order="true" />
		<!-- Breadcrum -->
		<Breadcrumb :dados=breadcrumb_info />		

		<div class="row" v-if="model.id">
			<div class="col-12 my-3" v-if="model.important_information">
				<div class="alert alert-danger my-0" role="alert">
					<i class="fas fa-exclamation-triangle"></i> {{ model.important_information }}
				</div>
			</div>
			<div class="col-12 my-3" v-if="model.internal_fraud && model.internal_fraud.status">
				<div class="alert alert-danger my-0" role="alert">
					<i class="fas fa-exclamation-triangle"></i> <strong class="ml-2">ATENÇÃO</strong> Pedido com Suspeita de Fraude <button class="btn btn-sm btn-primary ml-3" v-on:click="pegaAnalise()">Ver Análise Interna</button>
				</div>				
				<div class="row" v-if="show_internal_fraud">
					<div class="col-12 mt-3 internal-fraud">
						<div class="card card-view card-base">
							<div class="card-header">
								Informações da Análise Interna de Fraude
							</div>
							<div class="card-body p-0">
								<ul>									
									<li v-if="internal_fraud.customer && internal_fraud.customer.status === 0"><strong>Status do Cliente:</strong> Bloqueado</li>
									<li v-if="internal_fraud.frauds.length > 0"><strong>Pedidos Suspeitos</strong></li>									
									<li v-for="fraud in internal_fraud.frauds" :key="`fraud_${fraud.order_number}`">Pedido <router-link :to="{ name: 'pedidos.ver', params: { id: fraud.id } }" >{{ fraud.order_number }}</router-link> em {{ fraud.created_at | date_time}} | Status: {{ fraud.status.name }}</li>
									<li><strong>Compras nos últimos 30 dias</strong></li>
									<li v-for="order in internal_fraud.orders30" :key="`order_${order.order_number}`">Pedido <router-link :to="{ name: 'pedidos.ver', params: { id: order.id } }" >{{ order.order_number }}</router-link> em {{ order.created_at | date_time}} | Status: {{ order.status.name }}</li>
								</ul>
							</div>
						</div>	
					</div>	
				</div>			
			</div>			
			<div class="col my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações do Pedido
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Número do Pedido:</strong> {{ model.store_id }} - {{ model.order_number }}</li>
							<li><strong>Loja:</strong> {{ model.store_id | store }}</li>
							<li><strong>Empresa:</strong> {{ model.company_id | company }}</li>
							<li v-if="model.marketplace && model.marketplace.marketplace_id"><strong>Marketplace:</strong> {{ model.marketplace.marketplace_canal || model.marketplace.marketplace_channel || '' }} | {{ model.marketplace.marketplace_order_number }}</li>
							<li v-if="model.metadata.old_marketplace && model.metadata.old_marketplace.marketplace_id"><strong>Marketplace:</strong> {{ model.metadata.old_marketplace.marketplace_channel || '' }} | {{ model.metadata.old_marketplace.marketplace_order_number }}</li>
							<li><strong>Data da Compra:</strong> {{ model.created_at | date_time }}</li>
							<li><strong>Status Atual:</strong> {{ model.status.name }}</li>				
							<li v-if="model.cancel_reason_id && motivosCancelamento && motivosCancelamento.filter((mot) => { return mot.id == model.cancel_reason_id })[0]"><strong>Motivo Cancelamento:</strong> {{ motivosCancelamento.filter((mot) => { return mot.id == model.cancel_reason_id })[0]['name'] }}</li>								  
							<li><strong>Vendedor:</strong> 
								<template v-if="!seller_edit">
									{{ model.seller.name || "Não informado" }} <button @click="pegaVendedores()" class="btn btn-sm btn-edit ml-2" v-if="hasPermission('editar', true)"><i class="fa fa-edit"></i></button>
								</template>
								<template v-else>
									<select class="form-control my-2" placeholder="Estado" v-model="model.seller.id" v-on:change="alteraVendedor()">
										<option :value="undefined">Selecione</option>
										<option :value="user.id" v-for="(user,i) in sellers" :key="i">{{ user.name }}</option>
									</select>														
								</template>
							</li>
							<li><strong>Código de Rastreamento:</strong>
								<template v-if="!tracking_code_edit">
									{{ model.tracking_code || "Não informado" }} <button @click="editTrackingCode()" class="btn btn-sm btn-edit ml-2" v-if="hasPermission('editar', false)"><i class="fa fa-edit"></i></button>
								</template>
								<template v-else>
									<FormInput rules="string" label="" placeholder="" info="" position="left" text="" v-model="model.tracking_code" />
									<button class="btn btn-success mx-2" v-on:click="saveTrackingCode">Atualizar</button>
								</template>
							</li>
							<li v-if="hasPermission('editar', true) && ['paid'].includes(model.status.type) && !model.read_to_ship">
								<button class="btn btn-sm btn-primary" v-on:click="liberaDocumentos()">Liberar este pedido sem documento</button>
							</li>		
							<li>
								<label class="my-2"><strong>Custos Extras do Pedido</strong></label>
								<FormInputGroup :required=false rules="string" placeholder="" info="" position="right" type="money" action_button="Alterar Custo" v-model="model.extra_costs" />
							</li>				
						</ul>
						<div class="text-center">
							<a class="btn btn-primary m-2" v-on:click.prevent="synErp()"><span class="fas fa-sync-alt"></span> Viasoft</a>
							<a class="btn btn-primary m-2" v-on:click.prevent="syncAnymarket()" v-if="model.source == 'marketplace'"><span class="fas fa-sync-alt"></span> Anymarket</a>
						</div>	
					</div>
				</div>
			</div>
			<div class="col my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações do Cliente
						<router-link class="btn btn-sm" :to="{ name: 'clientes.ver', params: { id: model.customer_id } }" ><i class="fas fa-eye"></i></router-link>
					</div>
					<div class="card-body p-0">
						<ul>
							<li><strong>Nome:</strong> {{ model.customer.name }}</li>
							<li><strong>Documento:</strong> {{ model.customer.document | cnpfcnpj }}</li>
							<li><strong>E-mail:</strong> {{ model.customer.email }}</li>
							<li><strong>Data Cadastro: </strong> {{ model.customer.created_at | date}} </li>
							<template v-if="model.customer.phones">
								<li v-for="(phones,i) in model.customer.phones" :key=i ><strong>Telefone {{ phones.type | tipos_telefone }}:</strong> {{ phones.number | phone }}</li>
							</template>
							<li v-if="model.address && model.address.phones && model.address.phones.length > 0"><strong>Telefone Entrega:</strong> <template v-for="(phone,idx) in model.address.phones"><template v-if="idx > 0"> / </template>{{ phone.number | phone }}</template></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações Financeiras
					</div>
					<div class="card-body p-0">
						<ul class="resumo">
							<li><strong>Produtos:</strong> <span class="positivo"> {{ model.total_products | money }}</span></li>
							<li><strong>Frete:</strong> <span class="positivo"> {{ model.total_shipping | money }}</span></li>
							<li><strong>Descontos:</strong> <span class="negativo"> {{ model.total_discounts - model.shipment.discount | money }}</span></li>
							<li><strong>Juros:</strong> <span class="positivo"> {{ model.interest | money }}</span></li>
							<li class="total"><strong>Total:</strong> <span>{{ model.total_order | money }}</span></li>
						</ul>
					</div>
				</div>
			</div>

			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Produtos do Pedido
					</div>
					<div class="card-body order-products" v-if="model && model.products.length > 0">
						<table width="100%">
							<col width="60%" />
							<col width="20%" />
							<col width="5%" />
							<col width="15%" />
							<tr class="cabecalho">
								<th>Produto</th>
								<th>SKU</th>
								<th class="center">Qtd</th>
								<th class="center">Subtotal</th>
							</tr>
							<tr v-for="(p,i) in model.products" :key=i>
								<td class="titulo align-middle">
									{{ p.name }}
									<span v-for="(atributo,j) in p.attributes" :key=j>{{ atributo.name }}: {{ atributo.value.name ? atributo.value.name : atributo.value }}</span>
									<span v-if="p.gift" class="product_info_destaque">* Brinde</span>
									<span v-if="p.kit_reference" class="product_info_destaque mt-1">* Produto referente ao Kit {{ p.kit_reference }}</span>
								</td>
								<td class="align-middle">{{ p.sku }}</td>
								<td class="center align-middle">{{ p.quantity }}</td>
								<td class="center align-middle">
									<s class="d-block mb-1">{{ getProductFullPrice(p) * p.quantity | money }}</s>
									{{ p.subtotal_price | money }}
								</td>
							</tr>									
						</table>
					</div>
				</div>
			</div>

			<div class="col-6 my-3" v-if="exibePagamento == 0">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações de Pagamento
					</div>
					<div class="card-body">
						<div class="row pagamento" v-if="model.payments">
							<div class="col-4" v-for="(c, index) in model.payments" :key=index>
								<a v-if="c.type == 'credit_card'" v-on:click.prevent="setPagamento(index)">
									<i class="fas fa-credit-card mr-2"></i>
									<label>Cartão {{ c.installments }}x</label>
									<span class="my-2">{{ c.value | money }}</span>
									
									<span v-if="['waiting_card_data','waiting'].includes(c.status)" class="warning">Aguardando</span>
									<span v-if="c.status == 'waiting_payment'" class="warning">Pendente</span>
									<span v-if="['processing','pending'].includes(c.status)" class="processing">Processando</span>
									<span v-if="['review'].includes(c.status)" class="processing">Em Análise</span>
									<span v-if="['authorized_pending_capture','authorized'].includes(c.status)" class="success">Autorizado</span>
									<span v-if="c.status == 'paid'" class="success">Pago</span>
									<span v-if="c.status == 'failed'" class="error">Falhou</span>
									<span v-if="['refused','not_authorized','rejected'].includes(c.status)" class="error">Recusado</span>									
									<span v-if="c.status == 'refunded'" class="error">Estornado</span>
									<span v-if="c.status == 'error'" class="error">Com erro</span>
									<span v-if="c.status == 'overpaid'" class="error">À Maior</span>
									<span v-if="c.status == 'underpaid'" class="error">À Menor</span>
								</a>

								<a v-if="c.type == 'invoice'" v-on:click.prevent="setPagamento(index)">
									<i class="fa fa-barcode mr-2"></i>
									<label>Boleto</label>
									<span class="my-2">{{ c.value | money }}</span>
									<span v-if="['waiting_payment'].includes(c.status)" class="warning">Pendente</span>
									<span v-if="['processing','pending'].includes(c.status)" class="processing">Processando</span>
									<span v-if="c.status == 'paid'" class="success">Pago</span>	
									<span v-if="['failed','error'].includes(c.status)" class="danger">Com erro</span>									
								</a>									
								<button class="btn btn-sm btn-danger my-2" v-on:click="emitirNovoBoleto(c)" v-if="hasPermission('editar', true) && c.type == 'invoice' && ['waiting_payment','failed','error'].includes(c.status) && header_pedidos.status_atual.type != 'canceled' && validaVencimento(c.boleto_expiration_date)">Atualizar Boleto</button>

								<a v-if="c.type == 'deposit'" v-on:click.prevent="setPagamento(index)">
									<i class="fas fa-money-bill-alt mr-2"></i>
									<label >Depósito</label>
									<span class="my-2">{{ c.value | money }}</span>
									<span v-if="['waiting_payment', 'pending'].includes(c.status)" class="warning">Pendente</span>									
									<span v-if="c.status == 'paid'" class="success">Pago</span>										
								</a>

								<a v-if="c.type == 'pagaleve'" v-on:click.prevent="setPagamento(index)">
									<i class="fa fa-barcode mr-2"></i>
									<label>Pagaleve</label>
									<span class="my-2">{{ c.value | money }}</span>
									<span v-if="['waiting_payment'].includes(c.status)" class="warning">Pendente</span>
									<span v-if="['processing','pending'].includes(c.status)" class="processing">Processando</span>
									<span v-if="c.status == 'paid'" class="success">Pago</span>	
									<span v-if="['failed','error'].includes(c.status)" class="danger">Com erro</span>									
								</a>	

								<a v-if="c.type == 'pix'" v-on:click.prevent="setPagamento(index)">
									<i class="fas fa-money-bill-alt mr-2"></i>
									<label >PIX</label>
									<span class="my-2">{{ c.value | money }}</span>
									<span v-if="['waiting_payment', 'pending'].includes(c.status)" class="warning">Pendente</span>									
									<span v-if="c.status == 'paid'" class="success">Pago</span>										
								</a>

								<a v-if="c.type == 'voucher'" v-on:click.prevent="setPagamento(index)">
									<i class="fas fa-wallet mr-2"></i>
									<label >Voucher</label>
									<span class="my-2">{{ c.value | money }}</span>
									<span v-if="c.status == 'waiting_payment'" class="warning">Pendente</span>
									<span v-if="c.status == 'processing'" class="success">Gerado</span>
									<span v-if="c.status == 'paid'" class="success">Pago</span>										
								</a>

								<a v-if="c.type == 'withdraw'" v-on:click.prevent="setPagamento(index)">
									<i class="fas fa-flag-checkered mr-2"></i>
									<label >Retirada</label>
									<span class="my-2">{{ c.value | money }}</span>
									<span v-if="c.status == 'waiting_payment'" class="warning">Pendente</span>
									<span v-if="c.status == 'processing'" class="success">Gerado</span>
									<span v-if="c.status == 'paid' && c.status_validation" class="success">Pago</span>
									<span v-if="c.status == 'paid' && !c.status_validation" class="warning">Confirmação</span>
									<button v-if="hasPermission('editar', true) && c.type == 'withdraw' && c.status == 'paid' && !c.status_validation" class=" my-2 btn btn-success btn-sm btn-block" href="" v-on:click.prevent="aprovarRecebimento(c.type, c)"><i class="fas fa-check"></i> RECEBIDO?</button>
								</a>

								<a v-if="c.type == 'marketplace'" v-on:click.prevent="setPagamento(index)">
									<i class="fas fa-store mr-2"></i>
									<label>Marketplace</label>
									<span class="my-2">{{ c.value | money }}</span>
									<span v-if="c.status == 'waiting_payment'" class="warning">Pendente</span>
									<span v-if="c.status == 'processing'" class="success">Gerado</span>
									<span v-if="c.status == 'paid'" class="success">Pago</span>					
								</a>		

								<a v-if="c.type == 'internal'" v-on:click.prevent="setPagamento(index)">
									<i class="fas fa-money-bill-alt mr-2"></i>
									<label >Pagamento Interno</label>
									<span class="my-2">{{ c.value | money }}</span>
									<span v-if="['waiting_payment', 'pending'].includes(c.status)" class="warning">Pendente</span>									
									<span v-if="c.status == 'paid'" class="success">Pago</span>										
								</a>							
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-6 my-3" v-if="exibePagamento == 1">
				<div class="card card-view card-base">
					<div class="card-header">
						Timeline Pagamento <a title="Voltar" class="btn" v-on:click.prevent="exibePagamento = 0"><span class="fa fa-arrow-left"></span></a>
					</div>
					<div class="card-body" v-if="pagamento_ativo">
						<div v-if="pagamento_ativo.type && ['credit_card'].includes(pagamento_ativo.type) && pagamento_ativo.status == 'pending'" class="alert alert-info margin_superior_10" role="alert">
							Ainda estamos processando o pagamento
						</div>
						<div v-else-if="pagamento_ativo.type && ['credit_card'].includes(pagamento_ativo.type) && pagamento_ativo.status == 'waiting'" class="alert alert-info margin_superior_10" role="alert">
							O cliente ainda não passou o cartão
						</div>
						<div v-else-if="pagamento_ativo.type && ['withdraw','deposit', 'pix', 'internal', 'pagaleve'].includes(pagamento_ativo.type) && pagamento_ativo.status == 'waiting_payment'">
							<!-- Depósito -->
							<ul class="timeline">
								<li>
									Aguardando Pagamento &nbsp; 																		
									<a v-if="hasPermission('editar', true)" class="text-success float-right" href="" v-on:click.prevent="aprovarPagamento(pagamento_ativo.type, pagamento_ativo)"><span class="fa fa-check-square-o"></span> Aprovar?</a>																			
									<i v-bind:class="transacaoStatusClass('waiting_payment')"></i>
									<label v-if="pagamento_ativo.account"><i>Banco Escolhido: {{ pagamento_ativo.account.bank }}</i></label>
									<label v-if="pagamento_ativo.payment_type" class="mt-1"><i v-if="pagamento_ativo.payment_type == 'cash'">Dinheiro</i><i v-else-if="pagamento_ativo.payment_type == 'credit_card'">Cartão de Crédito <template v-if="pagamento_ativo.installments">{{ pagamento_ativo.installments }}x</template></i><i v-else-if="pagamento_ativo.payment_type == 'debit_card'">Débito</i></label>
								</li>									
							</ul>
						</div>						
						<div v-else>								
							<ul class="timeline">
								<li v-for="(t, index) in pagamento_ativo" :key=index>
									{{ t.status | transacaoStatus }} &nbsp; 									
									<a class="text-success" href="" v-on:click.prevent="aprovarPagamento('default', pagamento_ativo)" v-if="exibeBotaoAprovar(t) "><span class="fa fa-check-square-o"></span> Aprovar?</a>									
									<i v-if="t.metadata && t.metadata.source && t.metadata.source == 'manual'">Confirmado manualmente</i> 
									<i v-bind:class="transacaoStatusClass(t.status)"></i>
									<label><i>{{ t.created_at | date_time }}</i></label>
									<button class="btn btn-primary" v-if="!t.status.includes(['processing','waiting_payment'])" v-on:click.prevent="setTransacao(index)"><span class="fa fa-angle-double-right"></span></button>
								</li>									
							</ul>								
						</div>
					</div>
				</div>
			</div>

			<div class="col-6 my-3" v-if="exibeTransacao == 1 && transacao_ativa.id ">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações da Transação <a title="Voltar" class="btn" v-on:click.prevent="closeTransacao()"><span class="fa fa-arrow-left"></span></a>
					</div>
					<div class="card-body p-0" v-if="pagamento_ativo">
						<ul>
							<!-- Sucesso -->
							<li v-if="transacao_ativa.gateway"><strong>Gateway:</strong> {{ transacao_ativa.gateway }} </li>
							<li v-if="transacao_ativa.transaction_id"><strong>Código da Transação:</strong> {{ transacao_ativa.transaction_id }} </li>
							<li v-if="transacao_ativa.operation_type"><strong>Tipo de Transação:</strong> {{ transacao_ativa.operation_type }} </li>
							<li v-if="transacao_ativa.nsu"><strong>NSU:</strong> {{ transacao_ativa.nsu }} </li>
							<li v-if="transacao_ativa.tid"><strong>TID:</strong> {{ transacao_ativa.tid }} </li>
							<li v-if="transacao_ativa.acquirer"><strong>Adquirente:</strong> {{ transacao_ativa.acquirer }} </li>
							<li v-if="transacao_ativa.authorization_code"><strong>Cód. Autorização:</strong> {{ transacao_ativa.authorization_code }} </li>
							<li v-if="transacao_ativa.status"><strong>Status:</strong> {{ transacao_ativa.status | transacaoStatus }} </li>
							<li v-if="transacao_ativa.status_detail"><strong>Status Detalhe:</strong> {{ transacao_ativa.status_detail }} </li>
							<li v-if="transacao_ativa.status_detail_message"><strong>Mensagem:</strong> {{ transacao_ativa.status_detail_message }} </li>
							<li v-if="transacao_ativa.card && transacao_ativa.card.number"><strong>Cartão utilizado:</strong> {{ transacao_ativa.card.number }} </li>							
							<template v-if="index_pagamento >= 0 && transacao_ativa.card && model.payments[index_pagamento].card.number === transacao_ativa.card.number">
								<li v-if="model.payments[index_pagamento].card.holder"><strong>Nome Cartão:</strong> {{ model.payments[index_pagamento].card.holder }} </li>															
								<li v-if="model.payments[index_pagamento].card.document"><strong>Documento Cartão:</strong> {{ model.payments[index_pagamento].card.document | cnpfcnpj }} </li>															
							</template>
							<li v-if="transacao_ativa.created_at"><strong>Data:</strong> {{ transacao_ativa.created_at | date_time }} </li>
							<li v-if="transacao_ativa.boleto_url"><strong>Boleto:</strong> <a :href="transacao_ativa.boleto_url" target="_blank">Clique para acessar</a> </li>
							<li v-if="transacao_ativa.boleto_barcode"><strong>Código de Barras:</strong> {{ transacao_ativa.boleto_barcode }} </li>
							<li v-if="transacao_ativa.boleto_expiration_date"><strong>Vencimento do Boleto:</strong> {{ transacao_ativa.boleto_expiration_date | date_time }} </li>							
							<!-- pagamento manual -->
							<li v-if="transacao_ativa.source && transacao_ativa.source == 'manual'"><strong>Aprovação Manual:</strong> Sim </li>							
							<li v-if="transacao_ativa.source && transacao_ativa.source == 'manual' && transacao_ativa.user_id"><strong>Aprovado por:</strong> {{ getUserName(transacao_ativa.user_id) }} </li>							
							<!-- Erros -->
							<li v-if="transacao_ativa.errors && typeof transacao_ativa.errors == 'string'"><strong>Erro:</strong> {{ transacao_ativa.errors }}</li>
							<li v-else v-for="(erro,i) in transacao_ativa.errors" :key=i><strong>Erro:</strong> {{ erro }}</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Atualizações de Status do Pedido
					</div>
					<div class="card-body p-0">
						<ul v-if="model && model.status_log">
							<li v-for="(l,i) in model.status_log" :key=i><i>{{ l.updated_at | date_time }} - {{ l.responsible }}</i> {{ l.status_name }}</li>
						</ul>
						<div v-if="model && model.status_log && model.status_log.length <= 0" class="alert alert-info m-3" role="alert">
							Nenhuma alteração foi realizada
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Tickets vinculados a esse pedido
						<router-link class="btn" :to="{ name: 'tickets.novo', params: { from: 'order', document_id: model.id  } }" ><strong>NOVO TICKET</strong></router-link>
					</div>
					<div class="card-body">
						<table width="100%">							
							<col width="10%" />
							<col width="40%" />
							<col width="25%" />
							<col width="15%" />							
							<col width="5%" />
							<tr class="cabecalho">
								<th>Protocolo</th>
								<th>Assunto</th>																
								<th>Data</th>
								<th>Status</th>
								<th></th>
							</tr>
							<template v-if="tickets.length > 0">
								<tr v-for="(ticket,i) in tickets" :key=i>
									<td class="titulo align-middle">{{ ticket.protocol_number }}</td>
									<td class="align-middle">{{ ticket.subject }}</td>
									<td class="center align-middle">
										{{ ticket.created_at | date_time }}
									</td>
									<td class="center align-middle">{{ (ticket.status == 1) ? 'Aberto' : 'Finalizado' }}</td>								
									<td>
										<router-link class="btn btn-sm btn-primary" :to="{ name: 'tickets.ver', params: { id: ticket.id } }" ><i class="fas fa-eye"></i></router-link>
									</td>
								</tr>	
							</template>
							<template v-else>
								<tr><td colspan="5" class="center">Nenhum ticket vinculado a esse pedido</td></tr>
							</template>								
						</table>
					</div>
				</div>
			</div>

			<div class="col-4 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações de Entrega 
						<a v-if="!editAddress && hasPermission('editar', true)" href="javascript:void(0)" title="Editar Endereço" class="btn" v-on:click="editarEndereco()"><span class="fa fa-edit"></span></a>
					</div>
					<div class="card-body" v-bind:class="{'p-0': !editAddress}">
						<div v-if="!editAddress">
							<ul v-if="model && model.address" class="resumo">
								<li><strong>Aos Cuidados de:</strong> {{ model.address.contact }}</li>
								<li><strong>Endereço:</strong> {{ model.address.street }}, {{ model.address.number }} - {{ model.address.complement }} - {{ model.address.neighborhood }} - {{ model.address.city }} - {{ model.address.state }} - {{ model.address.postalcode | cep }}</li>
								<li><strong>Referência:</strong> {{ model.address.reference }}</li>
								<li v-for="(phone,i) in model.address.phones" :key="i"><strong>Telefone:</strong> {{ phone.number | phone }}</li>
								<li><strong>Forma de Envio:</strong> 
									<template v-if="!shipment_edit.use">
										{{ model.shipment.name }} <button @click="edicaoShipment()" class="btn btn-sm btn-edit ml-2" v-if="hasPermission('editar', true)"><i class="fa fa-edit"></i></button>
									</template>
									<template v-else>
										<select class="form-control my-2" placeholder="Estado" v-model="shipment_edit.type" v-on:change="alteraShipment()">
											<option value="">Selecione</option>
											<option :value="item.type" v-for="(item,i) in deliveryOptions" :key="i">{{ item.name }}</option>
										</select>
									</template>
								</li>
								<li><strong>Prazo de Entrega:</strong> {{ model.shipment.delivery_time }} dias úteis</li>
								<li><strong>Custo Real de Frete:</strong> 
									<template v-if="!shipping_real_cost_edit.use">
										{{ model.shipping_real_cost | money }} <button @click="editShippingRealCost()" class="btn btn-sm btn-edit ml-2" v-if="hasPermission('editar', false)"><i class="fa fa-edit"></i></button>
									</template>
									<template v-else>
										<FormInput rules="string" type="money" label="" placeholder="" info="" position="left" text="R$ " v-model="model.shipping_real_cost" />
										<button class="btn btn-success mx-2" v-on:click="saveShippingRealCost">Atualizar Custo Real de Frete</button>
									</template>
								</li>								
								<template v-if="model.shipment && model.shipment.log">
									<li><strong>Log de Frete</strong> </li>
									<li v-for="(log,i) in model.shipment.log" :key="`log_${i}`"><strong>{{ log.name }}:</strong> {{ log.delivery_time }} dias / {{ log.price | money }}</li>
								</template>
								
							</ul>
						</div>
						<div v-if="hasPermission('editar', true) && editAddress && model && model.address">
							<div class="row">
								<div class="col-12">	
									<FormInput :required=true rules="string" type="postalcode" label="CEP" placeholder="" info="" position="left" text="CEP" v-model="address.postalcode" @change="buscaEndereco()" />
								</div> 
							</div>
							<div class="row ">
								<div class="col-sm-12">
									<FormInput :required=true rules="string" label="Aos Cuidados" placeholder="" info="" v-model="address.contact" />
								</div>
							</div>		
							<div class="row">
								<div class="col-sm-8">
									<FormInput :required=true rules="string" label="Rua" placeholder="" info="" v-model="address.street" />
								</div>
								<div class="col-sm-4">
									<FormInput :required=true rules="string" label="Número" placeholder="" info="" v-model="address.number" />
								</div>
							</div>
							<div class="row">								
								<div class="col-sm-6">
									<FormInput :required=false rules="string" label="Complemento" placeholder="" info="" v-model="address.complement" />
								</div>		
								<div class="col-sm-6">
									<FormInput :required=false rules="string" label="Bairro" placeholder="" info="" v-model="address.neighborhood" />
								</div>						
							</div>
							<div class="row">
								<div class="col-4">	
									<div class="form-group">																				
										<div class="input-group">											
											<select class="form-control" placeholder="Estado" v-model="address.sa" v-on:change="alteraState()">
												<option value="">Estado</option>
												<option :value="state.sigla" v-for="(state,i) in states" :key="i">{{ state.sigla }}</option>
											</select>														
										</div>
									</div>
								</div>
								<div class="col-8">
									<div class="form-group">
										<div class="input-group">
											<select class="form-control" placeholder="" v-model="address.city">
												<option value=""></option>
												<option :value="city.city_name" v-for="(city,i) in cities" :key="i">{{ city.city_name }}</option>
											</select>														
										</div>
									</div>								
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<FormInput :required=false rules="string" label="Referência" placeholder="" info="" v-model="address.reference" />
								</div>								
							</div>
							<div class="text-right">
								<button class="btn btn-danger mx-2" v-on:click="editAddress = false">Cancelar</button>
								<button class="btn btn-success mx-2" v-on:click="atualizaEndereco">Atualizar Endereço</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-4 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Cupom de Desconto
					</div>
					<div class="card-body p-0">
						<template v-if="model.coupon.code">
							<ul >
								<li ><strong>Código do Cupom:</strong> {{ model.coupon.code }}</li>
								<li><strong>Valor do Cupom:</strong> {{ model.coupon.value | money }}</li>
							</ul>
						</template>							
						<template v-else>
							<div class="alert alert-info m-3" role="alert">
								Nenhum cupom utilizado nesse pedido
							</div>
						</template>							
						
					</div>
				</div>
			</div>

			<div class="col-4 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Informações AntiFraude
					</div>
					<div class="card-body p-0" >
						<template v-if="model.fraud && (model.fraud.konduto || model.fraud.mercadopago)">
							<ul>
								<li v-if="model.fraud.konduto"><strong>Análise da Konduto</strong> </li>
								<li v-if="model.fraud.konduto"><strong>Score:</strong> {{ model.fraud.konduto.score }}</li>
								<li v-if="model.fraud.konduto"><strong>Status:</strong> {{ model.fraud.konduto.status | StatusFraude }}</li>
								<li v-if="model.fraud.konduto && model.fraud.konduto.recommendation != 'NONE' && model.fraud.konduto.recommendation != ''"><strong>Recomendação:</strong> {{ model.fraud.konduto.recommendation }}</li>								
								<li v-if="model.fraud.mercadopago && model.fraud.mercadopago.status"><strong>Mercado Pago</strong> </li>
								<li v-if="model.fraud.mercadopago && model.fraud.mercadopago.status"><strong>Status:</strong> {{ model.fraud.mercadopago.status | StatusFraude  }}</li>
								<li v-if="model.fraud.mercadopago && model.fraud.mercadopago.recommendation"><strong>Recomendação:</strong> {{ model.fraud.mercadopago.recommendation }}</li>								
							</ul>
						</template>
						<template v-else>
							<div class="alert alert-info m-3" role="alert">
								Nenhuma informação de fraude								
							</div>
							<div class=" my-3 text-center">
								<button v-if="hasPermission('editar', true)" class="btn btn-primary btn-sm" @click="sendFraud()">ENVIAR PARA ANÁLISE</button>
							</div>
						</template>
					</div>
				</div>
			</div>

			<div class="my-3" :class="[{'col-4': hasTaxMl}, {'col-6': !hasTaxMl}]">
				<div class="card card-view card-base">
					<div class="card-header">
						Nota Fiscal
					</div>
					<div class="card-body p-0">
						<template v-if="model.invoices.length">
							<ul v-for="(invoice,i) in model.invoices" :key=i>
								<li><strong>Série - Número:</strong> {{ invoice.range }}-{{ invoice.number }}</li>
								<li><strong>Chave:</strong> {{ invoice.key }}</li>								
								<li class="text-center" v-if="i<(model.invoices.length-1)">--------------------------------</li>
								<li class="text-center" v-else-if="hasPermission('editar', false)">
									<button class="btn btn-sm btn-danger" @click="deleteInvoice()" title="Denvincular Notas"> REMOVER NOTAS FISCAIS</button>
								</li>
							</ul>
							
						</template>
						<template v-else>
							<div class="alert alert-info m-3" role="alert">
								Nenhuma nota fiscal para este pedido
							</div>
						</template>
					</div>
				</div>
			</div>

			<div class="col-4 my-3" v-if="hasTaxMl">
				<div class="card card-view card-base">
					<div class="card-header">
						Custos Marketplace
					</div>
					<div class="card-body p-0">
						<ul >
							<li ><strong>Frete Loja - Custo: </strong> 
								<s v-if="model.marketplace.mercado_livre_info.refund > 0">{{ model.marketplace.mercado_livre_info.sellerFreight | money}}</s> {{ model.marketplace.mercado_livre_info.sellerFreight - model.marketplace.mercado_livre_info.refund | money}}
							</li>
							<li><strong>Taxa Marketplace:</strong> {{ model.marketplace.mercado_livre_info.gatewayFee + model.marketplace.mercado_livre_info.marketplaceFee | money }}</li>
							<li><strong>Reembolso:</strong> 
								<FormInput rules="string" type="money" label="" placeholder="" info="" position="left" text="R$ " v-model="model.marketplace.mercado_livre_info.refund" />
								<button class="btn btn-success mx-2" v-on:click="saveRefundCost">Atualizar Valor de Reembolso</button>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="my-3" :class="[{'col-4': hasTaxMl}, {'col-6': !hasTaxMl}]">
				<div class="card card-view card-base">
					<div class="card-header">
						E-mails enviados sobre esse Pedido
					</div>
					<div class="card-body">
						<template v-if="emails.length">
							<ul v-for="(email,i) in emails" :key=i>
								<li><strong>Assunto:</strong> {{ email.subject }}</li>
								<li><strong>Criado em:</strong> {{ email.created_at | date_time }}</li>
								<li v-if="email.sended"><strong>Enviado em:</strong> {{ email.sended_at | date_time }}</li>
								<li v-else>Na fila para envio</li>
								<li v-if="email.sended && hasPermission('editar', true)"><button class="btn btn-sm btn-primary" @click="enviarEmailNovamente(email)"><i class="fas fa-paper-plane d-inline-block mr-2 ml-1"></i> Enviar Novamente</button></li>
								<li class="text-center" v-if="i<(emails.length-1)">--------------------------------</li>
							</ul>
						</template>
						<template v-else>
							<div class="alert alert-info m-3" role="alert">
								Nenhum e-mail enviado para este pedido
							</div>
						</template>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Log do Pedido
					</div>
					<div class="card-body p-0">
						<template v-if="model.actions_log.length">
							<ul>
								<li v-for="(log,i) in model.actions_log" :key=i>
									<strong class="pb-1">{{ getUserName(log.user_id) }}</strong> em {{ log.created_at | date_time }}:
									<p class="d-block mt-1">{{ log.message }}</p>
								</li>
							</ul>
						</template>
						<template v-else>
							<div class="alert alert-info m-3" role="alert">
								Nenhuma ação registrada nesse pedido
							</div>
						</template>
					</div>
				</div>
			</div>

			<div class="col-6 my-3">
				<div class="card card-view card-base">
					<div class="card-header">
						Observações do Pedido
					</div>
					<div class="card-body">
						<FormTextarea :disabled="!hasPermission('editar') || !['new','processing','paid','problem','payment-problem','review'].includes(model.status.type)" :required=false label="Informações Extras do Pedido:" v-model="extra_information" :rows=3 placeholder="" type="textarea" @change="updateExtraInformation" />
						<FormTextarea :disabled="!hasPermission('editar') || !['new','processing','paid','problem','payment-problem','review'].includes(model.status.type)" :required=false label="Informações Importantes do Pedido:" v-model="important_information" :rows=3 placeholder="" type="textarea"  @change="updateImportantInformation" />

						<Notes state="pedidos" :id="model.id" :disabled="!hasPermission('editar')" />
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>

import Header from "./../../common/Header";
import Breadcrumb from "./../../common/Breadcrumb";
import Filters from "./../../../common/filters";
import FormInput from "./../../common/form/Input";
import FormInputGroup from "./../../common/form/InputGroup";
import alerts from "../../../common/alerts";
import Notes from "./../../common/Notes";
import FormTextarea from "./../../common/form/Textarea";
import states from "./../../../assets/json/states";
import moment from "moment";


export default {
	name: "PedidosView",
	components: {
		Header,
		Breadcrumb,
		FormInput,
		FormInputGroup,
		Notes,
		FormTextarea
	},
	props: {
		modulo_permissao:{
			type: String,
			required: true
		}
	},
	computed: {
		permissoes() {
			return this.$store.state.app.permissoes;
		},
		model() {
			const pedido = Object.assign({},this.$store.state.pedidos.model);			
			if(pedido.status_log){
				// tratando a ordenação dos status de log
				pedido.status_log.sort(function (a, b) {
					const _dtA = new Date(a.updated_at);
					const _dtB = new Date(b.updated_at);
					if (_dtA < _dtB) {
						return 1;
					}
					if (_dtA > _dtB) {
						return -1;
					}
					// a must be equal to b
					return 0;
				});
			}

			return pedido;
		},
		statusPedidos() {
			return this.$store.state.pedidos.status;
		},
		motivosCancelamento() {
			return this.$store.state.pedidos.motivos_cancelamento;
		},
		usuario() {
			return this.$store.state.app.usuario;
		},
		orderedObservacoes: function () {
			return Filters.orderBy(this.model.notes, "created_at","desc");
		},
		header_pedidos: function(){
			// zera os status
			let status_atual = {};
			let status_anterior = {};
			let status_proximo	= {};

			// pega o status atual
			status_atual = this.statusPedidos.filter(item => {
				return item.id == this.model.status.id;
			})[0];			
			// pega o indice do status atual
			let index_atual = this.statusPedidos.indexOf(status_atual);			
			// pega o status anterior, caso tenha
			if(index_atual > 0){
				status_anterior = this.statusPedidos[index_atual-1];
			}
			// pega o proximo status, caso tenha
			if(index_atual < (this.statusPedidos.length - 1)){				
				status_proximo = this.statusPedidos[index_atual+1];
			}			
			// Validando
			if(!status_atual){
				status_atual = {};
			}
			if(!status_anterior){
				status_anterior = {};
			}
			if(!status_proximo){
				status_proximo = {};
			}

			return {
				status_atual,
				status_anterior,
				status_proximo,
				options: this.statusPedidos,
				status: this.model.status,
				permissao_geral: this.hasPermission("editar"),
				permissao_manager: this.hasPermission("editar", true)
			};
		},	
		cities(){
			return this.$store.state.pedidos.cities;
		},
		deliveryOptions() {
			return [
				{
					carrier: "plimor",
					name : "Plimor",
					type : "plimor_padrao"
				},
				{
					carrier: "transfolha",
					name : "Transfolha",
					type : "transfolha_padrao"
				},
				{
					carrier: "jadlog",
					name : "Jadlog Expresso",
					type : "jadlog_expresso"
				},
				{
					carrier: "jadlog",
					name : "Jadlog Package",
					type : "jadlog_package"
				},
				{
					carrier: "jadlog",
					name : "Jadlog Pickup",
					type : "jadlog_pickup"
				},
				{
					carrier: "retirada",
					name : "Retirada",
					type : "loja_retirada"
				},
				{
					carrier: "correios",
					name : "Sedex",
					type : "correios_sedex"
				},
				{
					carrier: "correios",
					name : "PAC",
					type : "correios_pac"
				},
				{
					carrier: "correios",
					name : "E-Sedex",
					type : "correios_esedex"
				},
				{
					carrier: "motoboy",
					name : "Motoboy (Normal)",
					type : "motoboy_normal"
				},
				{
					carrier: "motoboy",
					name : "Motoboy (Expresso)",
					type : "motoboy_expresso"
				}
			];
		},
		hasTaxMl() {
			if(this.model && this.model.marketplace && this.model.marketplace.mercado_livre_info){
				return true;
			}else {
				return false;
			}
		}	
	},	
	data () {
		return {
			header_info: {
				title: "Visão Geral do Pedido",
				chamada: "Gerencie os pedidos da sua loja",
				links: {
					back: "pedidos.lista",
					save: "."
				}
			},
			breadcrumb_info: [				
				{
					name: "Pedidos",
					link: "/pedidos"
				},
				{
					name: "Ver",
					link: ""
				}
			],
			config: {
				modulo_permissao: "pedidos"
			},
			permissao: [],
			pagamento_ativo: "",
			exibePagamento: 0,
			exibeTransacao: 0,
			editAddress: false,
			status_selecionado: {},
			dados_alteracao_status: {}, 
			users: [],
			extra_information: null,
			important_information: null,
			emails: [],
			states: states,
			sellers: [],
			seller_edit: false,
			shipment_edit: {
				use: false,
				type: "",
				name: "",
				carrier: ""
			},
			shipping_real_cost_edit: {
				use: false,
				value: 0
			},
			tracking_code_edit: false,
			address: {
				postalcode: "",
				contact: "",
				street: "",
				number: "",
				neighborhood: "",
				city: "",
				state: "",
				complement: "",
				phone: "",
				reference: ""
			},
			tickets: [],
			internal_fraud: {
				customer: null,
				frauds: [],
				orders30: []
			},
			show_internal_fraud: false,
			index_pagamento: null,
		};
	},  
	methods: {
		hasPermission(type, manager) {
			if(manager == undefined){
				manager = false;
			}
			if(!manager){
				return this.permissao[type] === 1;
			}else{
				return this.usuario.manager && this.permissao[type] === 1;
			}
		},
		getProductFullPrice(item){
			let full_price = item.unit_price;
			for (const discountType in item.discounts) {
				full_price += item.discounts[discountType];
			}
			return full_price;
		},	
		setPagamento: function(index) {			
			if(this.model.payments[index].transactions.length == 0){					
				this.pagamento_ativo = this.model.payments[index];
			}else{								
				this.pagamento_ativo = this.model.payments[index].transactions;
			}
			this.index_pagamento = index;
			this.exibePagamento = 1;
		},
		transacaoStatusClass: function (value) {
			let response = "";
			
			switch(value) {
			case "processing":
			case "waiting_payment":
				response = "fas fa-check-circle wait";				
				break;
			case "paid":
			case "authorized":
			case "received":
				response = "fas fa-check-circle ok";
				break;
			case "not_authorized":
			case "refunded":
			case "refused":
			case "rejected":
			case "error":
			case "failed":
				response = "fas fa-stop-circle error";
				break;
			default:
				response = "fas fa-check-circle wait";
				break;
			}

			return response;
		},
		setTransacao: function(index) {
			this.transacao_ativa = this.pagamento_ativo[index];			
			this.exibePagamento = 2;
			this.exibeTransacao = 1;
		},
		closeTransacao: function() {
			this.exibePagamento = 1;
			this.exibeTransacao = 0;
		},
		aprovarPagamento: function(type, pay){			

			if(!this.hasPermission("editar", true)){
				alerts.alertTop("error","Você não tem permissão para confirmar o pagamento manualmente desse pagamento");
				return;
			}

			let self = this;

			let event_confirm = () => {		
				let dados;
				if(["deposit","withdraw","pix", "internal"].includes(type)) {
					dados = {
						payment_id: pay.id,
						status: "paid",
						user_id: self.usuario.id
					};
				}else{
					dados = {
						payment_id: self.model.payments.filter(item => { return item.id == pay[0].payment_id; })[0].id,
						status: "paid",
						user_id: self.usuario.id
					};
				}
				self.$store.dispatch("pedidos/aprovarPagamento", dados);
				self.exibePagamento = 0;
				return true;
			};

			let model_data = {
				title: "Confirmar o pagamento manualmente?",
				body: null
			};
			
			alerts.alertConfirm(model_data,event_confirm);

		},
		aprovarRecebimento: function(type, pay){	
			let self = this;							

			// validando se o usuário tem permissão (Apenas Klaus, Enir, Cesar e Moacir) 			
			if(!["6071cf8d4fafc920a7716c67","6071cfadfe330d19cae1b2fa","6071cfaefe330d19cae1b2fb","6071cfaefe330d19cae1b2fc"].includes(this.usuario.id)){
				alerts.alertTop("error","Você não tem permissão para confirmar o recebimento desse pagamento");
				return false;
			}

			let event_confirm = () => {						
				let dados;
				dados = {
					payment_id: pay.id,
					status_validation: true
				};
				self.$store.dispatch("pedidos/aprovarRecebimento", dados).then(response => {
					if(response.return_code == 1){
						alerts.alertTop("success","Pagamento Confirmado com Sucesso");
						this.$store.dispatch("pedidos/load", self.model.id);
					}
				});				
				return true;
			};

			let model_data = {
				title: "Confirmar o recebimento deste pagamento?",
				body: null
			};
			
			alerts.alertConfirm(model_data,event_confirm);

		},
		emitirNovoBoleto: function(pay){
			this.$store.dispatch("pedidos/atualizarBoleto",pay);
		},
		validaVencimento: function(data_vencimento){
			// 2018-09-22T03:00:00.000Z
			// se tiver dado erro e a data vier como null ou undefined
			if(data_vencimento == null || data_vencimento === undefined){
				return true;
			}
			data_vencimento = data_vencimento.substr(0,10);
			data_vencimento = new Date(data_vencimento);
			let data_atual = new Date();
			if(data_atual > data_vencimento){
				// se a data atual for maior que a do vencimento do boleto ele permite emitir um novo boleto
				return true;
			}else{
				return false;
			}
		},
		atualizaEndereco: function(){			
			const self = this;
			this.$store.dispatch("pedidos/atualizaEndereco", {order_id: this.model.id , address: this.address}).then(response => {
				if(response.return_code == 1){
					self.editAddress = false;
					self.$store.dispatch("pedidos/load", self.model.id);
				}
			});
		},
		alteraStatus: function(status){
			let self = this;
			this.status_selecionado = status;

			self.$refs.header.openDrop = false;

			let event_confirm = () => {		
				const extra_data = [];
				// verifica se é um status do tipo cancelado
				if(this.status_selecionado.type == "canceled"){
					// valida se foi preenchido o motivo de cancelamento
					if(this.dados_alteracao_status.motivo_cancelamento == null || this.dados_alteracao_status.motivo_cancelamento == ""){
						alerts.alertTop("error", "Selecione um motivo de cancelamento para continuar");
						return false;
					}
					extra_data.push({
						name: "cancel_reason",
						value: this.dados_alteracao_status.motivo_cancelamento
					});
				}
				self.$store.dispatch("pedidos/update_status_order",{
					order_id: self.model.id,
					status_id: self.status_selecionado.id,
					user_id: self.usuario.id,
					data: extra_data
				}).then(function(){					
					self.clearStatusUpdate(); // limpa os dados alterados
				});
				return true;
			};

			let dados = [];
			const motivosCancelamento = this.motivosCancelamento.map(item => {
				return {
					id: item.id,
					name: item.name
				};
			});
			// pegando o id do status cancelar
			this.status_canceled = this.statusPedidos.find(item => {
				return item.type === "canceled";
			});

			dados = {
				title: "Alterar Status para: "+self.status_selecionado.name+" ? ",
				body: (self.status_selecionado.id == self.status_canceled.id) ? [
					
					{
						type: "label",
						text: "Selecione o motivo do cancelamento"
					},
					{
						type: "select",
						options: motivosCancelamento,
						event: (valor) => {
							self.dados_alteracao_status.motivo_cancelamento = valor;
						}
					},
				] : null
			};
			
			alerts.alertConfirm(dados,event_confirm);
		},
		clearStatusUpdate: function(){
			this.dados_alteracao_status = {
				novo_status: null,
				motivo_cancelamento: null
			};
		},
		exibeBotaoAprovar(transaction) {			
			const payment = this.model.payments.filter(pay => { 
				return pay.id == this.pagamento_ativo[0].payment_id;
			});

			if(payment.length > 0){
				return this.hasPermission("editar", true) && payment[0].status == "waiting_payment" && transaction.status == "waiting_payment" && this.header_pedidos.status_atual.type != "canceled";
			}
			return false;			
		}, 
		getUserName(id) {
			const user = this.users.find(user => {
				return user.id == id;
			});
			if(user){
				return user.name;
			}

			return "";
		},
		updateExtraInformation(valor){
			this.updateInformation({extra_information: valor});
		},
		updateImportantInformation(valor){
			this.updateInformation({important_information: valor});
		},
		updateInformation(data){
			if(!this.hasPermission("editar")){
				alerts.alertTop("error","Você não tem permissão para alterar essa informação.");
				return;
			}
			const body = {id: this.model.id, ...data};
			const self = this;
			this.$store.dispatch("pedidos/atualizaInformacao", body).then(response => {
				if(response.return_code == 1 && response.data.ok){
					alerts.alertTop("success", "Informação salva com sucesso");
				}else{
					alerts.alertTop("error", "Houve um problema ao salvar a informação");
				}
				for(const f in data){
					self.$store.commit("pedidos/update_field", { field: f, data: data[f] });
				}
				
			});
		},
		enviarEmailNovamente(email){
			this.$store.dispatch("pedidos/reenviar_email",email.id).then(response => {
				if(response.return_code == 1){
					alerts.alertTop("success", "E-mail enviado novamente");
				}else{
					alerts.alertTop("error", "Houve um problema ao enviar o e-mail. Tente novamente.");
				}
			});
		},
		alteraState: function(){
			let uf = this.address.sa;
			// corrige o nome do estado
			this.address.state = this.states.filter(function(state){
				return state.sigla == uf;
			})[0]["name"];
			// limpa o campo cidade
			this.address.city = "";
            
			this.pegaCidades(uf);
		},
		pegaCidades: function(uf){
			this.$store.dispatch("pedidos/pega_cidades",uf);
		},
		editarEndereco: function(){
			this.address = Object.assign({},this.model.address);
			this.editAddress = true;			
			this.pegaCidades(this.address.sa);
		},
		buscaEndereco: function(){
			let self = this;		
			// buscando na api
			this.$store.dispatch("pedidos/busca_endereco",{postalcode: self.address.postalcode.replace(/[^0-9]+/g,""), customer_id: self.model.customer_id}).then(response => {
				if (response.body.return_code == 1) {
					let data = response.body.data;
					if(!data.errors){
						if(!data.sa){
							// caso não tenha o uf gravado ainda busca pelo nome da cidade							
							data.sa = self.states.filter(function(cidade){
								return cidade.name == data.state;
							})[0]["sigla"];
						}    
						self.address.contact = data.contact;
						self.address.street = data.street;
						self.address.number = data.number;
						self.address.neighborhood = data.neighborhood;
						self.address.city = data.city;
						self.address.state = data.state;
						self.address.sa = data.sa;
						self.address.complement = data.complement;
						if(data.phones && data.phones.length > 0){
							self.address.phone = data.phones[0].number;
						}else{
							self.address.phone = "";
						}
						
						self.address.reference = data.reference;
						// pega as cidades
						self.pegaCidades(data.sa);
					}
				}
			}); 
		},		
		async pegaVendedores(){
			
			this.sellers = await this.$store.dispatch("usuarios/load_sellers").then(response => {
				return response;
			});

			this.seller_edit = true;
		},
		alteraVendedor() {
			const self = this;
			// pegando os dados do vendedor selecionado			
			const _seller = this.sellers.find(item => item.id === this.model.seller.id);
			if(_seller){
				this.$store.dispatch("pedidos/atualizaPedido", {
					id: self.model.id,
					seller: { id: _seller.id, name: _seller.name }
				}).then(response => {
					if(response.return_code == 1 && response.data.ok){
						alerts.alertTop("success", "Vendedor alterado com sucesso");
					}else{
						alerts.alertTop("error", "Houve um problema ao alterar o vendedor");
					}
					self.$store.commit("pedidos/update_field", { field: "seller", data: { id: _seller.id, name: _seller.name } });
					self.seller_edit = false;
				});
			}else{
				alerts.alertTop("error", "O vendedor não foi encontrado");
			}
		},
		edicaoShipment() {
			this.shipment_edit.use = true;
			this.shipment_edit.type = this.model.shipment.type;
			this.shipment_edit.name = this.model.shipment.name;
			this.shipment_edit.carrier = this.model.shipment.carrier;
		},
		editShippingRealCost() {
			this.shipping_real_cost_edit.use = true;
		},
		editTrackingCode() {
			this.tracking_code_edit = true;
		},
		saveShippingRealCost() {
			const self = this;
			this.$store.dispatch("pedidos/atualizaPedido", {
				id: self.model.id,
				shipping_real_cost: self.model.shipping_real_cost
			}).then(response => {
				if(response.return_code == 1 && response.data.ok){
					alerts.alertTop("success", "Custo Real de Frete alterado com sucesso");
				}else{
					alerts.alertTop("error", "Houve um problema ao alterar o custo real do frete");
				}
				self.$store.commit("pedidos/update_field", { field: "shipping_real_cost", data: self.model.shipping_real_cost });
				self.shipping_real_cost_edit.use = false;
			});
		},
		saveRefundCost() {
			const self = this;
			this.$store.dispatch("pedidos/atualizaPedido", {
				id: self.model.id,
				marketplace_refund: self.model.marketplace.mercado_livre_info.refund
			}).then(response => {
				if(response.return_code == 1 && response.data.ok){
					alerts.alertTop("success", "Valor de Reembolso alterado com sucesso");
				}else{
					alerts.alertTop("error", "Houve um problema ao alterar o valor de reembolso");
				}
				self.$store.commit("pedidos/update_field", { field: "marketplace", data: self.model.marketplace });
			});
		},
		saveTrackingCode() {
			const self = this;
			this.$store.dispatch("pedidos/atualizaPedido", {
				id: self.model.id,
				tracking_code: self.model.tracking_code
			}).then(response => {
				if(response.return_code == 1 && response.data.ok){
					alerts.alertTop("success", "Código de Rastreamento alterado com sucesso");
				}else{
					alerts.alertTop("error", "Houve um problema ao alterar o Código de Rastreamento");
				}
				self.$store.commit("pedidos/update_field", { field: "tracking_code", data: self.model.tracking_code });
				self.tracking_code_edit = false;
			});
		},
		deleteInvoice() {			
			const self = this;
			let event_confirm = () => {
				self.$store.dispatch("pedidos/atualizaPedido", {
					id: self.model.id,
					invoice_reset: true
				}).then(response => {
					if(response.return_code == 1 && response.data.ok){
						alerts.alertTop("success", "Nfe(s) desvinculada(s) com sucesso");
						self.model.invoices = [];
					}else{
						alerts.alertTop("error", "Houve um problema ao desvincular a nota fiscal");
					}
					self.$store.commit("pedidos/update_field", { field: "invoices", data: self.model.invoices });
				});
				return true;
			};
			alerts.alertConfirm("Tem certeza que deseja excluir o vínculo das notas?",event_confirm);	
		},
		alteraShipment() {
			const self = this;
			let shipment = Object.assign({}, this.model.shipment);
			// pega o shipment novo
			const newShipment = this.deliveryOptions.find(item => item.type == this.shipment_edit.type);
			shipment.type = newShipment.type;
			shipment.name = newShipment.name;
			shipment.carrier = newShipment.carrier;

			this.$store.dispatch("pedidos/atualizaPedido", {
				id: self.model.id,
				shipment: shipment
			}).then(response => {
				if(response.return_code == 1 && response.data.ok){
					alerts.alertTop("success", "Forma de envio alterada com sucesso");
				}else{
					alerts.alertTop("error", "Houve um problema ao alterar a forma de envio");
				}
				self.$store.commit("pedidos/update_field", { field: "shipment", data: shipment });
				self.shipment_edit.use = false;
			});
		},
		async synErp() {
			const result = await this.$store.dispatch("pedidos/sync_erp", this.model.id);
			if(result.return_code == 0){
				alerts.alertTop("error", `Houve um erro ao sincronizar o pedido: ${result.data.message}`);
			}else{
				if(result.data.ok){
					alerts.alertTop("success", "O pedido foi sincronizado com o Viasoft");
				}else{
					alerts.alertTop("error", "Houve um erro ao sincronizar o pedido");
				}
			}
		},
		async syncAnymarket() {		
			var type = null;
			
			const self = this;
			let event_confirm = async () => {
				const result = await self.$store.dispatch("pedidos/sync_anymarket", {id: self.model.id, type: type});
				if(result.return_code == 0){
					alerts.alertTop("error", `Houve um erro ao sincronizar o pedido: ${result.data.message}`);
				}else{
					if(result.data.ok){
						alerts.alertTop("success", "O pedido foi sincronizado com o Anymarket");
					}else{
						alerts.alertTop("error", "Houve um erro ao sincronizar o pedido");
					}
				}
				return true;
			};
			let dados = [];
			const opcoes = [
				{
					id: "to",
					name: "Enviar para o Anymarket"
				},
				{
					id: "from",
					name: "Buscar no Anymarket"
				},
			];

			dados = {
				title: "Selecione a opção desejada:",
				body: [
					{
						type: "select",
						options: opcoes,
						event: (valor) => {
							type = valor;
						}
					},
				]
			};
			
			alerts.alertConfirm(dados,event_confirm);
		},
		async sendFraud() {
			const result = await this.$store.dispatch("pedidos/sync_fraud", this.model.id);
			if(result.return_code == 0){
				try{
					if(!result.data.message.why.expected){
						alerts.alertTop("error", `Houve um erro: ${result.data.message.where} - ${result.data.message.why.message}`);
					}else{
						alerts.alertTop("error", `Houve um erro: ${result.data.message.why.expected} - ${result.data.message.why.found}`);
					}
					
				}catch{
					alerts.alertTop("error", "Houve um erro ao sincronizar o pedido com o Antifraude");
				}				
			}else{				
				this.loadOrder();
				alerts.alertTop("success", "O pedido foi sincronizado com o Antifraude");
			}
		},
		loadOrder() {
			let self = this;
			this.$store.dispatch("pedidos/load", this.$route.params.id).then(response => {
				if(response.order_number){
					self.$store.dispatch("tickets/load_tickets_by_order_number", response.order_number).then(response => {
						self.tickets = response;
					});
				}				
				if(response.important_information){
					self.important_information = response.important_information;
				}
				if(response.extra_information){
					self.extra_information = response.extra_information;
				}
			});		
		},
		pegaAnalise() {
			let self = this;
			const days_30 = moment().subtract(30, "d").format("YYYY-MM-DD")+" 00:00:00";
			let startingPromises = [
				// pega o cliente
				this.$store.dispatch("clientes/load", this.model.customer_id).then(response => {
					if(response.return_code == 1 && response.data.id){
						self.internal_fraud.customer = response.data;
					}else{
						self.internal_fraud.customer = null;
					}
				}),
				// pega os pedidos 30 dias
				this.$store.dispatch("pedidos/load_orders_by_data", {customer_id: this.model.customer_id, created_at: days_30, ignore: this.model.order_number}).then(response => {
					self.internal_fraud.orders30 = response;
				}),
			];
			// pega os pedidos fraudados
			if(this.model.internal_fraud && this.model.internal_fraud.fraud_orders && this.model.internal_fraud.fraud_orders.length > 0){
				startingPromises.push(
					this.$store.dispatch("pedidos/load_orders_fraud",this.model.internal_fraud.fraud_orders).then(response => {
						self.internal_fraud.frauds = response;
					})
				);
			}


			Promise.all(startingPromises).then(() => {
				self.show_internal_fraud = true;
			});
		},
		liberaDocumentos() {
			const self = this;
			let event_confirm = () => {
				self.$store.dispatch("pedidos/atualizaPedido", {
					id: self.model.id,
					dispatch_without_document: true
				}).then(response => {
					if(response.return_code == 1 && response.data.ok){
						alerts.alertTop("success", "Pedido liberado com sucesso");
						self.$store.commit("pedidos/update_field", { field: "read_to_ship", data: true });
					}else{
						alerts.alertTop("error", "Houve um problema ao liberar esse pedido");
					}
				});
				return true;
			};
			alerts.alertConfirm("Tem certeza que deseja liberar esse pedido?",event_confirm);
		},
		actionButton: function(tipo){
			const self = this;
			switch(tipo){
			case "Alterar Custo":
				var event_confirm = () => {
					self.$store.dispatch("pedidos/atualizaPedido", {
						id: self.model.id,
						extra_costs: self.model.extra_costs
					}).then(response => {
						if(response.return_code == 1 && response.data.ok){
							alerts.alertTop("success", "Custo alterado com sucesso");
							self.$store.commit("pedidos/update_field", { field: "extra_costs", data: self.model.extra_costs });
						}else{
							alerts.alertTop("error", "Houve um problema ao alterar o custo");
						}
					});
					return true;
				};
				alerts.alertConfirm("Tem certeza que deseja alterar o custo?",event_confirm);
				break;
			}
		},
	},
	filters: Filters,
	provide: function () {
		return {
			alteraStatus: this.alteraStatus,
			actionButton: this.actionButton 
		};
	},
	created() {

		const self = this;
		this.$store.dispatch("pedidos/reset");
		if(this.permissoes) {
			this.permissao = this.permissoes[this.config.modulo_permissao];
		}
		this.$store.dispatch("pedidos/load_status_data");
		this.$store.dispatch("usuarios/load_users").then(response => {
			self.users = response;
		});
		this.$store.dispatch("pedidos/load_motivos_data");
		this.loadOrder();
		this.$store.dispatch("pedidos/load_notes", this.$route.params.id);						
		this.$store.dispatch("pedidos/emails_enviados", this.$route.params.id).then(response => {
			if(response.return_code == 1){
				self.emails = response.data.items;
			}else{
				self.emails = [];
			}
			
		});

		this.clearStatusUpdate();
	},
	watch: {
		$route(to) {
			if(to.params.id && to.params.id != this.model.id){				
				this.$nextTick().then(() => {
					location.reload();
				});				
			}
		}
	}
};
</script>

<style scoped lang="scss">	
.order-products{
	.titulo {
		.product_info_destaque {
			color: $color_red;
			font-size: 0.8em;
		}
	}
	s {
		font-size: 0.8em;
		color: $color_red;
	}
}
button.btn-edit{
	background: $color_red;
	color: #FFF;
	font-size: 0.8em;	
}
.internal-fraud {
	.card {
		&.card-view {
			.card-body {
				ul {
					li {
						a {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}	
}
	
</style>
